import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import { get, has } from 'lodash-es';
import * as Sentry from '@sentry/browser';
import * as momentTimezone from 'moment-timezone';
import { AtSign, Clock, Edit, MapPin, Phone, Shuffle, WifiOff, Zap } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge, Button, Card, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  DataLoading,
  DateFormat,
  ProductName,
  Battery,
  Tracking,
  Price,
  VariantPrice,
} from '@bottomless/common/components';
import { useConditionalDataEffect, useToggle } from '@bottomless/common/hooks';
import { daysUntilNextFullfilment, getNextVendorFulfillmentDate } from '@bottomless/common/utils';
import { CustomerNotes } from '../../../../../components/CustomerNotes/CustomerNotes';
import { SlowLocalBadge } from '../../../../../components/SlowLocalBadge';
import { UserCharge } from './UserCharge';
import { EmailEditor } from '../../../../../components/EmailEditor/EmailEditor';
import { GenerateOrder } from './GenerateOrder';
import { StickyNotes } from './StickyNotes';
import { TareModal } from './TareModal';
import { Graph } from './Graph';
import { OrdergenHistory } from './OrdergenHistory';
import { LazyTabs } from '../../../../../components/LazyTab/LazyTab';
import { OrderHistory } from '../../../../../components/OrderHistory/OrderHistory';
import { VendorFulfillmentDays } from '../../../../../components/VendorFulfillmentDays/VendorFulfillmentDays';
import { NeedsSupportForm } from '../../../../../components/NeedsSupport/NeedsSupport';
import { CustomList } from '../../../../../components/CustomList';
import { BatteryGraph } from './BatteryGraph';
import { CategoryBadge } from '../../../../../components/CategoryBadge/CategoryBadge';
import { LineItems } from './LineItems';
import { ShopifyNote } from '../../../../../components/ShopifyNote/ShopifyNote';
import { getUserNotesOrderGenAction, getUserRecordsAction } from '../../../../../store/admin/ordergen';

const STATUS_ACTIVE = 'active';
const USER_SIZE = 5;
const PstTimezone = 'America/Los_Angeles';

const ORDER_GEN_STATUS = {
  Idle: 'idle',
  IdleReview: 'idle_review',
  OptOut: 'opt_out',
};

const FULFILLMENT_HOURS = {
  east: 8,
  central: 9,
  mountain: 10,
  west: 11,
};

const UserComponent = ({
  user,
  onGenerateInstance,
  onCreateNote,
  onDeleteNote,
  getUserRecords,
  addToast,
  onDiscard,
  onSelect,
  setTare,
  isDetail,
  setNeedsSupport,
  getUserLastOrder,
  onNeedsSupport,
  userIndex,
  withDisconnected,
  setOrdergenStatus,
  onOrdergenStatusChange,
  hideOnGeneration,
  vendorDownTimes,
  getUserNotes,
}) => {
  const location = useLocation();
  const [records, setRecords] = useState(null);
  const [notes, setNotes] = useState(user.notes);

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [visible, setVisible] = useState(true);
  const [isSending, setSending] = useState(false);
  const [isTareOpen, setTareOpen] = useState(false);
  const [error, setError] = useState(null);
  const [tare, setTareValue] = useState(0);
  const [notifications, setNotifications] = useState(null);
  const [isEmailOpen, toggleEmail] = useToggle();
  const [triggerVisible, setTriggerVisible] = useState(true);
  const [isNeedSupportOpen, toggleNeedSupport, setNeedSupportOpen] = useToggle();
  const [emailContext, setEmailContext] = useState(null);
  const [isResolving, setResolving] = useState(false);

  const recordsRetries = useRef(0);

  const getUserRecordsWithRetries = useCallback(
    async (...data) => {
      do {
        try {
          const result = await getUserRecords(...data);

          if (result.error) {
            throw result?.payload instanceof Error
              ? result.payload
              : new Error(result?.payload?.message || 'Could not fetch records');
          }

          return result;
        } catch (e) {
          recordsRetries.current++;

          if (recordsRetries.current >= 3) {
            Sentry.captureException(e);
            throw e;
          }
        }
      } while (recordsRetries.current < 3);
    },
    [getUserRecords]
  );

  const { error: recordsError } = useConditionalDataEffect(
    !records,
    getUserRecordsWithRetries,
    data => {
      setRecords(data);
    },
    user._id
  );

  useConditionalDataEffect(
    !notes,
    getUserNotes,
    data => {
      setNotes(data);
    },
    user._id,
    null
  );

  const handleCreateNote = useCallback(
    async note => {
      const result = await onCreateNote(note);
      setNotes(notes => [note, ...(notes || [])]);
      return result;
    },
    [onCreateNote]
  );

  const handleDeleteNote = useCallback(
    async note => {
      const result = await onDeleteNote(note);
      setNotes(notes => (notes || []).filter(n => n._id !== note._id));
      return result;
    },
    [onDeleteNote]
  );

  const variant = user.product.product.variants.find(variant => variant._id === user.product.variant);
  const isSlowLocal = useMemo(() => Boolean(user.slow_local), [user]);
  const userCategory = useMemo(() => user.product.product.category, [user]);

  const availableVendors = useMemo(() => {
    if (isSlowLocal) {
      return [];
    }
    const todayVendors = [];
    if (records?.prediction?.selections?.today?.products?.length) {
      for (const product of records.prediction.selections.today.products) {
        const existingVendor = todayVendors.find(row => String(row._id) === String(product.vendor_id._id));
        if (!existingVendor) {
          todayVendors.push(product.vendor_id);
        }
      }
    }
    if (!selectedVendor && todayVendors.length) {
      setSelectedVendor(todayVendors[0]._id);
      user.selectedVendor = todayVendors[0]._id;
    }

    return todayVendors;
  }, [isSlowLocal, records, user, selectedVendor, setSelectedVendor]);

  const bestVendor = useMemo(
    () => user?.vendor_id?._id || records?.prediction?.selections?.today.products[0].vendor_id._id,
    [user, records]
  );

  const fulfillmentTimelines = useMemo(() => {
    const isCustomRotation = !!user.product.product.personalized_rotation;

    if (isCustomRotation && user.personalized?.vendor?.length === 1) {
      return user.personalized.vendor[0].fulfillment_timelines;
    } else if (isCustomRotation && user.personalized?.vendor?.length > 1) {
      return null;
    }

    return user.product.product.vendor_id?.fulfillment_timelines;
  }, [user]);

  const handleSending = (toast, createOrder, override, formValues) => () => {
    if (
      !createOrder ||
      !records.prediction.selections ||
      !(records.records.length > 0 || records.orders.length > 0) ||
      user.selectedVendor
    ) {
      setSending(true);
      setError(null);
      if (hideOnGeneration) {
        setVisible(formValues.sendNotification);
      }
      setNotifications(formValues);

      setTimeout(async () => {
        try {
          const selections = records.prediction.selections &&
            (records.records.length > 0 || records.orders.length > 0) && {
              ...records.prediction.selections,
              today: {
                ...records.prediction.selections.today,
                products: records.prediction.selections.today.products.map(product => product._id || product),
              },
              tomorrow: {
                ...records.prediction.selections.tomorrow,
                products: records.prediction.selections.tomorrow.products.map(product => product._id || product),
              },
              selectedVendor: user.selectedVendor,
            };

          const generatedAt = records && records.generatedAt;
          const { payload } = await onGenerateInstance({
            user: user._id,
            selections,
            createOrder,
            generatedAt,
            override,
            ...formValues,
          });

          if (formValues.sendNotification) {
            const { payload: lastOrder } = await getUserLastOrder(user._id);
            setEmailContext({ orderId: payload.order, lastOrderId: lastOrder && lastOrder._id });
            toggleEmail();
            setTriggerVisible(true);
          } else {
            onDiscard();
          }

          addToast(
            toast,
            createOrder ? 'success' : 'error',
            <Link to={`/admin/ordering_cockpit/${user._id}`} target="_blank">
              Go to user
            </Link>
          );

          setNotifications(null);
        } catch (e) {
          addToast(`Oops, something went wrong with ${user.first_name} ${user.last_name}.`);
          setSending(false);
          setVisible(true);
          setError(e);
        }
      }, 20);
    } else {
      addToast(`Vendor should be selected!`, 'error');
    }
  };

  const handleDiscard = () => {
    setError(null);
    onDiscard();
  };

  const toggleTare = () => setTareOpen(!isTareOpen);
  const openTare = value => {
    setTareValue(value);
    toggleTare();
  };

  const onTareSuccess = async () => {
    addToast('Tare has been successfully saved');
    setTareOpen(false);
    try {
      const { payload } = await getUserRecords(user._id);
      setRecords(payload);
    } catch (e) {
      setRecords(null);
    }
  };

  const onNotification = () => {
    toggleEmail();
    setEmailContext(null);
    if (hideOnGeneration && triggerVisible) {
      setVisible(false);
    }
    handleDiscard();
  };

  const onNeedsSupportSuccess = useCallback(
    data => {
      onNeedsSupport(data);
      addToast('Needs support updated!');
      setNeedSupportOpen(false);
    },
    [onNeedsSupport, addToast, setNeedSupportOpen]
  );

  const onSupportResolve = useCallback(async () => {
    setResolving(true);
    const { payload } = await setNeedsSupport({ needs_support: false });
    await onNeedsSupportSuccess(payload);
    setResolving(false);
  }, [setResolving, setNeedsSupport, onNeedsSupportSuccess]);

  const err = user.error || error;

  const hasActions = Boolean(onGenerateInstance);
  const { order } = user;

  const userNotes = useMemo(
    () => ({
      ...user,
      notes: notes
        ? notes.filter(note => note.type === 'note' || note.type === 'needs_support' || note.type === 'vendor_comment')
        : null,
    }),
    [user, notes]
  );

  const orderNotes = useMemo(() => ({ ...user, notes: notes ? notes.filter(note => note.order) : null }), [
    user,
    notes,
  ]);
  const customListNotes = useMemo(
    () => ({ ...user, notes: notes ? notes.filter(note => note.type === 'rotation_changes') : null }),
    [user, notes]
  );
  const stickyNotes = useMemo(() => ({ ...user, notes: notes ? notes.filter(note => note.type === 'sticky') : null }), [
    user,
    notes,
  ]);

  const disconnectedDays = useMemo(
    () =>
      user.scale_last_connected
        ? Math.floor((Date.now() - new Date(user.scale_last_connected).getTime()) / (1000 * 60 * 60 * 24))
        : null,
    [user.scale_last_connected]
  );

  const onMoveToIdleClick = useCallback(async () => {
    await setOrdergenStatus(user._id, { order_gen_status: ORDER_GEN_STATUS.Idle });
    addToast('Moved to the Idle!');
    onOrdergenStatusChange();
  }, [setOrdergenStatus, user, onOrdergenStatusChange, addToast]);

  const onMoveToOptOutClick = useCallback(async () => {
    await setOrdergenStatus(user._id, { order_gen_status: ORDER_GEN_STATUS.OptOut });
    addToast('Moved to the Opt Out!');
    onOrdergenStatusChange();
  }, [setOrdergenStatus, user, onOrdergenStatusChange, addToast]);

  const getAverageShipment = vendorId => {
    const index = records.prediction?.selections?.today?.products?.findIndex(e => e.vendor_id._id === vendorId);
    if (index !== -1) return records.prediction?.selections?.today?.pred_score[index]?.toFixed(2);
    return -1;
  };

  const showScaleBattery = get(records, 'scale_battery_level', -1) > 0;
  const showZero = get(records, 'zero', -1) > 0;
  const showBagOnScale = records && typeof records.bag_on_scale !== 'undefined';

  let bagStatusColor = '';
  if (showBagOnScale) {
    if (records.bag_on_scale === 'On Scale') {
      bagStatusColor = 'text-success';
    } else if (records.bag_on_scale === 'Maybe On Scale') {
      bagStatusColor = 'text-warning';
    } else if (records.bag_on_scale === 'Not on Scale') {
      bagStatusColor = 'text-danger';
    }
  }

  const renderDynamicPrice = user => {
    const { personalized, dynamicPricing, pricing_rule } = user || {};
    if (personalized && personalized.price_type && dynamicPricing) {
      return (
        <div className="text-secondary text-sm">
          <Price value={dynamicPricing.price} pricingRule={pricing_rule} />
        </div>
      );
    }
    return '';
  };

  const nextFulfillmentDate = useMemo(() => {
    const { rotating } = user.product.product;

    const tomorrow = momentTimezone.tz(new Date(), PstTimezone).add(1, 'days');
    const dayAfterTomorrow = tomorrow.clone().add(1, 'days');

    let vendor;
    if (records?.customList?.products?.length) {
      vendor = records?.customList.products[0].product.vendor_id || {};
    } else if (rotating) {
      vendor = records?.prediction?.selections?.tomorrow.products[0]?.vendor_id || {};
    } else {
      vendor = user.product.product.vendor_id || {};
    }
    const { fulfillment_timelines: fulfillmentTimelines, location } = vendor;
    const selectedVendorDownTimes = vendorDownTimes && vendorDownTimes[vendor?._id];

    if (!location) {
      return null;
    }

    const fulfillmentHour = FULFILLMENT_HOURS[location] || 8;

    const nextFulfillmentFromTomorrow = getNextVendorFulfillmentDate({
      startDate: momentTimezone.tz(tomorrow, PstTimezone),
      fulfillmentTimelines,
      vendorDownTimes: selectedVendorDownTimes,
    });

    const nextFulfillmentFromDayAfterTomorrow = getNextVendorFulfillmentDate({
      startDate: dayAfterTomorrow,
      fulfillmentTimelines,
      vendorDownTimes: selectedVendorDownTimes,
    });

    if (!nextFulfillmentFromTomorrow || !nextFulfillmentFromDayAfterTomorrow) {
      return null;
    }

    const isFulfillmentIn24 =
      nextFulfillmentFromTomorrow.toDate().getTime() - tomorrow.toDate().getTime() < 24 * 60 * 60 * 1000;
    const isFulfillmentIn24to36 =
      nextFulfillmentFromDayAfterTomorrow.toDate().getTime() - tomorrow.toDate().getTime() < 36 * 60 * 60 * 1000;

    const dateWithCorrectTime = new Date(
      nextFulfillmentFromDayAfterTomorrow.toDate().setUTCHours(fulfillmentHour, 0, 0, 0)
    );
    const todayIsThursday = momentTimezone.tz(new Date(), PstTimezone).day() === 4;

    return !todayIsThursday && isFulfillmentIn24 && !isFulfillmentIn24to36 ? dateWithCorrectTime : null;
  }, [user, records, vendorDownTimes]);

  const cardProperties = useMemo(() => {
    if (err) {
      return { color: 'danger', outline: true };
    }

    if (isSlowLocal) {
      return { color: 'warning', outline: true };
    }

    return {};
  }, [err, isSlowLocal]);

  return (
    <>
      {userIndex < USER_SIZE && (
        <div className={`ordergen-user mb-3${!visible || user.visible === false ? ' d-none' : ''}`}>
          <Card body {...cardProperties}>
            <div className="d-flex justify-content-between mb-1">
              <div>
                <h5 className="mb-0">
                  {user.first_name} {user.last_name}
                  {user.status && user.status !== STATUS_ACTIVE && (
                    <span className="text-danger text-capitalize"> - {user.status.replace(/_/g, ' ')}</span>
                  )}
                </h5>
                <div className="mb-2">
                  <Link to={`/admin/users?search=${user._id}`}>
                    <div className="text-secondary text-sm">{user._id}</div>
                  </Link>
                  {user.vendor_id && <Badge color="info">Vendor: {user.vendor_id.name}</Badge>}
                </div>
                <div
                  className={`d-flex align-items-center text-link text-${aggressionColor(user.ordering_aggression)}`}
                >
                  <Zap size={12} />
                  <span className="ml-1">{aggressionName(user.ordering_aggression)}</span>
                </div>
                <div>
                  <Link to={`/admin/ordering_cockpit/${user._id}`} className="text-info">
                    <Shuffle size="12" />
                    <span className="ml-1">Order generation details</span>
                  </Link>
                </div>
                <div className={`d-flex align-items-center text-link`}>
                  {user.name && <div>Scale: {user.name}</div>}
                </div>
                <div>
                  {user.trial && (
                    <Badge color="info" className="ml-1">
                      Trial
                    </Badge>
                  )}
                  {user.paused && (
                    <Badge color="warning" className="ml-1">
                      Paused
                    </Badge>
                  )}
                  {user.hibernate && (
                    <Badge color="danger" className="ml-1">
                      Hibernating
                    </Badge>
                  )}
                  {user.dumb_period && (
                    <Badge color="warning" className="ml-1">
                      dumb_period: {user.dumb_period}
                    </Badge>
                  )}
                  {user.support && user.support.order && user.support.order.needs_support && (
                    <Badge color="danger" className="ml-1">
                      Support needed
                    </Badge>
                  )}
                  {user.order_gen_status && (
                    <Badge
                      color={user.order_gen_status === ORDER_GEN_STATUS.IdleReview ? 'warning' : 'danger'}
                      className="ml-1"
                    >
                      {user.order_gen_status}
                    </Badge>
                  )}
                  {user.shopifySubscriptionContractId && (
                    <Badge className="ml-2" color="dark">
                      Simple Subscription
                    </Badge>
                  )}
                  {user.verifiedAddress.pickup && (
                    <Badge color="primary" className="ml-1">
                      Pickup
                    </Badge>
                  )}
                </div>
              </div>
              <div className="text-sm">
                <AtSign size={12} />
                <a
                  href={`https://app.frontapp.com/compose?mailto=${user.local.email}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-muted ml-1"
                >
                  {user.local.email}
                </a>
                {user.phone && (
                  <div className="d-flex align-items-center text-muted">
                    <Phone size={12} />
                    <a className="ml-1" href={`https://app.frontapp.com/compose?mailto=${user.phone}`}>
                      {user.phone}
                    </a>
                  </div>
                )}
                {user.verifiedAddress && (
                  <div className="d-flex align-items-center text-link">
                    <MapPin size={12} />
                    <span className="ml-1">
                      {user.verifiedAddress.city}, {user.verifiedAddress.state}
                    </span>
                  </div>
                )}
                {user.timezone && (
                  <div className="d-flex align-items-center text-link">
                    <Clock size={12} />
                    <span className="ml-1">{user.timezone}</span>
                  </div>
                )}
              </div>
              <div className={`text-${hasActions || order ? 'center' : 'right'} product-width`}>
                <div className="text-secondary text-sm">{user.product.product.vendor_name}</div>
                <ProductName
                  product={user.product}
                  personalized={user.personalized}
                  grind={user.grind?.name}
                  isSubproduct={true}
                  quantity={user.quantity ? user.quantity : undefined}
                  productVariant={user.product}
                />
                <div className="text-primary text-sm">
                  {variant.size}oz -{' '}
                  {user.product.product && user.product.product.rotating && has(user.personalized, 'price_type') ? (
                    get(user.personalized, 'price_type').replace(/^\w/, c => c.toUpperCase())
                  ) : (
                    <VariantPrice user={user} variant={variant} pricingRule={user.pricing_rule} />
                  )}
                </div>
                {user.product.product && user.product.product.rotating ? renderDynamicPrice(user) : ''}
                <LineItems user={user} />
                {fulfillmentTimelines && (
                  <div className="mt-2 text-sm">
                    <span>
                      Days until next fulfillment:{' '}
                      {daysUntilNextFullfilment({ fulfillment_timelines: fulfillmentTimelines })}
                    </span>
                    <VendorFulfillmentDays fulfillment_timelines={fulfillmentTimelines} />
                  </div>
                )}
                {isSlowLocal && <SlowLocalBadge className="mt-2" />}
                {userCategory && ['pet-food', 'byo'].includes(userCategory.slug) && (
                  <CategoryBadge className="mt-2" category={userCategory.name} />
                )}
              </div>
              {order && (
                <div className="text-right">
                  {order.subvendor_id && <div className="text-secondary text-sm">{order.subvendor_id.name}</div>}
                  {order.subproduct_name && <div className="mb-2">{order.subproduct_name}</div>}
                  <div className="text-sm">OrderId: {order._id}</div>
                  <div className="text-sm">
                    Override fulfillment date:{' '}
                    {order.override_fulfillment_date ? <DateFormat date={order.override_fulfillment_date} /> : '---'}
                  </div>
                  {order.tracking_number && (
                    <div className="text-sm">
                      <Tracking
                        number={order.tracking_number}
                        shippingService={order.shipping_service}
                        trackingUrl={order.tracking_url}
                      />
                    </div>
                  )}
                </div>
              )}

              <div>
                {hasActions && (
                  <div>
                    <GenerateOrder
                      onNoOrder={formValues =>
                        handleSending(
                          `No order has been generated for ${user.first_name} ${user.last_name}`,
                          false,
                          false,
                          formValues
                        )()
                      }
                      onOrder={formValues =>
                        handleSending(
                          `Order has been generated for ${user.first_name} ${user.last_name}`,
                          true,
                          false,
                          formValues
                        )()
                      }
                      isSending={isSending}
                      err={err}
                      toggleNeedSupport={toggleNeedSupport}
                    />
                  </div>
                )}
                {!hasActions && (
                  <div className="text-right">
                    <div>
                      <Button
                        size="sm"
                        color="danger"
                        outline
                        type="button"
                        onClick={toggleNeedSupport}
                        className="mb-1"
                      >
                        Needs support
                      </Button>
                    </div>
                    {user.support && user.support.order && user.support.order.needs_support && (
                      <div>
                        <Button
                          size="sm"
                          color="danger"
                          outline
                          type="button"
                          onClick={onSupportResolve}
                          className="mb-1"
                          disabled={isResolving}
                        >
                          {isResolving ? 'Saving...' : 'Resolve support'}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {user.order_gen_status === ORDER_GEN_STATUS.IdleReview && (
                  <div className="text-right">
                    <div>
                      <Button
                        size="sm"
                        color="danger"
                        outline
                        type="button"
                        onClick={onMoveToIdleClick}
                        className="mb-1"
                      >
                        Move to Idle
                      </Button>
                    </div>
                  </div>
                )}
                {(user.order_gen_status === ORDER_GEN_STATUS.IdleReview ||
                  user.order_gen_status === ORDER_GEN_STATUS.Idle) && (
                  <div className="text-right">
                    <div>
                      <Button
                        size="sm"
                        color="danger"
                        outline
                        type="button"
                        onClick={onMoveToOptOutClick}
                        className="mb-1"
                      >
                        Move to Opt out
                      </Button>
                    </div>
                  </div>
                )}
                {withDisconnected && (
                  <div className="text-right">
                    <Button
                      size="sm"
                      color="success"
                      outline
                      type="button"
                      onClick={() => {
                        setTriggerVisible(false);
                        toggleEmail();
                      }}
                      className="mb-1"
                    >
                      Send email
                    </Button>
                  </div>
                )}

                <StickyNotes {...stickyNotes} />
              </div>
            </div>

            <div className="text-right">
              {withDisconnected && (
                <div className="text-secondary d-flex align-items-center mb-2">
                  <WifiOff size="12" />
                  <span className="ml-2 text-sm">{disconnectedDays} days</span>
                  <Link to={`/admin/disconnected/${user._id}?back=${location.pathname}`} className="text-info ml-2">
                    <Edit size="20" />
                  </Link>
                </div>
              )}
              {user.pending_orders?.length > 0 && <div className="text-success d-flex mb-2">🚚 Order scheduled!</div>}
              {showBagOnScale && (
                <span className="mr-2 pull-left">
                  Bag on scale: <span className={bagStatusColor}>{String(records.bag_on_scale)}</span>
                </span>
              )}
              {showZero && <span className="mr-2 pull-left">Zero: {(records.zero * 100).toFixed(2)} </span>}
              {showScaleBattery && (
                <span className="mx-2">
                  Scale battery: {records.scale_battery_level.toFixed(2)}%{' '}
                  <Battery batteryLevel={records.scale_battery_level} />
                </span>
              )}
              {isDetail && (
                <Button onClick={toggleTare} size="sm" color="dark">
                  Tare
                </Button>
              )}
            </div>
            <div className="mb-4">
              <DataLoading count={records ? 1 : 0} isLoading={!records} />
              {recordsError && (
                <div className="text-danger text-center">{recordsError?.message || 'Could not fetch records'}</div>
              )}
              {records && (
                <>
                  {(records.tareRecords?.length > 0 || records.records?.length > 0 || records.orders?.length > 0) && (
                    <div className={`outline outline-${aggressionColor(user.ordering_aggression)}`}>
                      <Graph
                        stats={records.records}
                        tareRecords={records.tareRecords}
                        orders={records.orders}
                        predictions={records.prediction}
                        rolling={records.rolling}
                        slopeline={records.slopeline}
                        onLatestOrder={onSelect}
                        height={60}
                        onRecordClick={openTare}
                        selectedVendor={selectedVendor}
                        bestVendor={bestVendor}
                        nextFulfillmentDate={nextFulfillmentDate}
                      />
                      {availableVendors.map((vendor, index) => {
                        const defaultChecked = index === 0 ? true : false;
                        return (
                          <Row key={vendor._id}>
                            <Col xs="1" className="d-flex justify-content-end">
                              <Input
                                type="radio"
                                name={`${user._id}`}
                                value={`${vendor._id}`}
                                onChange={e => {
                                  setSelectedVendor(e.currentTarget.value);
                                  user.selectedVendor = e.currentTarget.value;
                                }}
                                defaultChecked={defaultChecked}
                              />
                            </Col>
                            <Col xs="5">
                              <h4>
                                {vendor.name} / {getAverageShipment(vendor._id)}
                              </h4>
                            </Col>
                            <Col xs="3">
                              {vendor.fulfillment_timelines && (
                                <VendorFulfillmentDays
                                  fulfillment_timelines={vendor.fulfillment_timelines}
                                  className="justify-content-start"
                                />
                              )}
                            </Col>
                            <Col xs="2" className="d-flex justify-content-center">
                              <h4>{vendor.verifiedAddress.state}</h4>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  )}
                  {!recordsError &&
                    !records.tareRecords?.length &&
                    !records.records?.length &&
                    !records.orders?.length && <div className="text-secondary text-center mb-4">No records data</div>}
                </>
              )}
            </div>
            {isDetail && (
              <div className="mb-4">
                <DataLoading count={records ? 1 : 0} isLoading={!records} />
                {records && (
                  <>
                    <h3>Scale Battery</h3>
                    {records.battery_perc?.length !== 0 && (records.records?.length > 0 || records.orders?.length > 0) && (
                      <div className={`outline outline-${aggressionColor(user.ordering_aggression)}`}>
                        <BatteryGraph stats={records.battery_perc} height={60} />
                      </div>
                    )}
                    {!recordsError && !records.records?.length && !records.orders?.length && (
                      <div className="text-secondary text-center mb-4">No battery data</div>
                    )}
                  </>
                )}
              </div>
            )}
            {records?.customList?.products?.length > 0 && (
              <div className="mb-4">
                <h3>Custom list</h3>
                <CustomList customList={records.customList} />
              </div>
            )}
            <div>
              <LazyTabs
                headers={[
                  { text: 'Notes', counter: (userNotes.notes || []).length },
                  user.vendor_id ? 'Shopify Note' : '',
                  'History',
                  { text: 'Order Notes', counter: (orderNotes.notes || []).length },
                  { text: 'Custom List Notes', counter: (customListNotes.notes || []).length },
                  { text: 'Sticky Notes', counter: (stickyNotes.notes || []).length },
                  { text: 'Charges' },
                  { text: 'Ordergen History' },
                ].filter(row => !!row)}
              >
                <CustomerNotes
                  user={userNotes}
                  onCreate={handleCreateNote}
                  onDelete={handleDeleteNote}
                  withHeader={false}
                />
                <ShopifyNote user={user} skipRender={!user.vendor_id} />
                <OrderHistory user={user} />
                <CustomerNotes
                  user={orderNotes}
                  onCreate={handleCreateNote}
                  onDelete={handleDeleteNote}
                  withHeader={false}
                />
                <CustomerNotes
                  user={customListNotes}
                  onCreate={handleCreateNote}
                  onDelete={handleDeleteNote}
                  withHeader={false}
                  type="rotation_changes"
                />
                <CustomerNotes
                  type="sticky"
                  user={stickyNotes}
                  onCreate={handleCreateNote}
                  onDelete={handleDeleteNote}
                  withHeader={false}
                />
                <UserCharge user={user} />
                <OrdergenHistory user={user} />
              </LazyTabs>
            </div>
            {err && (
              <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
                <div className="text-danger">
                  <strong>{err.message}:</strong> {(err.details || {}).user || (err.details || {}).generatedAt}
                </div>
                {onDiscard && (
                  <>
                    <Button color="link" onClick={handleDiscard}>
                      {err.details && err.details.generatedAt ? 'Cancel' : 'Keep old instance'}
                    </Button>
                    <Button
                      color="link"
                      onClick={() =>
                        handleSending(
                          `Ordergen instance has been overritten for ${user.first_name} ${user.last_name}`,
                          ((err.details || {}).body || {}).createOrder,
                          true,
                          notifications
                        )()
                      }
                    >
                      {err.details && err.details.generatedAt ? 'Process anyways' : 'Keep new instance'}
                    </Button>
                  </>
                )}
              </div>
            )}
          </Card>
          <TareModal
            isOpen={isTareOpen}
            toggle={toggleTare}
            defaultValue={tare}
            onSubmit={setTare}
            onSuccess={onTareSuccess}
          />

          {isEmailOpen && (
            <Modal isOpen={isEmailOpen} toggle={toggleEmail} size="lg">
              <ModalHeader toggle={toggleEmail}>Send notification</ModalHeader>
              <ModalBody>
                <EmailEditor context={{ ...emailContext, userId: user._id }} onSuccess={onNotification} />
              </ModalBody>
            </Modal>
          )}

          {isNeedSupportOpen && (
            <Modal isOpen={isNeedSupportOpen} toggle={toggleNeedSupport} size="lg">
              <ModalHeader toggle={toggleNeedSupport}>Needs support?</ModalHeader>
              <ModalBody>
                <NeedsSupportForm user={user} onSubmit={setNeedsSupport} onSubmitSuccess={onNeedsSupportSuccess} />
              </ModalBody>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

const aggressionName = aggression =>
  ({
    1: 'Never run out',
    2: 'Just right',
    3: 'As fresh as possible',
  }[aggression] ||
  aggression ||
  '---');

const aggressionColor = aggression =>
  ({
    1: 'danger',
    2: 'primary',
    3: 'success',
  }[aggression]);

UserComponent.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string,
    visible: PropTypes.bool,
    error: PropTypes.object,
    status: PropTypes.string,
    trial: PropTypes.bool,
    paused: PropTypes.bool,
    hibernate: PropTypes.bool,
    dumb_period: PropTypes.number,
    grind: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    pending_orders: PropTypes.array,
    vendor_id: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    scale_last_connected: PropTypes.string,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
        vendor_id: PropTypes.object,
        rotating: PropTypes.bool,
        category: PropTypes.shape({
          name: PropTypes.string,
        }),
        personalized_rotation: PropTypes.bool,
      }),
      variant: PropTypes.string.isRequired,
    }).isRequired,
    verifiedAddress: PropTypes.shape({
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      pickup: PropTypes.bool,
    }).isRequired,
    phone: PropTypes.string,
    timezone: PropTypes.string,
    ordering_aggression: PropTypes.number,
    order: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      tracking_number: PropTypes.string,
      subproduct_name: PropTypes.string,
      shipping_service: PropTypes.string,
      tracking_url: PropTypes.string,
      override_fulfillment_date: PropTypes.string,
      subvendor_id: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
    has_future_order: PropTypes.bool,
    notes: PropTypes.array,
    personalized: PropTypes.shape({
      price_type: PropTypes.string,
      vendor: PropTypes.arrayOf(
        PropTypes.shape({
          fulfillment_timelines: PropTypes.object,
        })
      ),
    }),
    dynamicPricing: PropTypes.shape({
      type: PropTypes.string,
      price: PropTypes.number,
    }),
    selectedVendor: PropTypes.string,
    support: PropTypes.shape({
      order: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
      scale: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
    }),
    order_gen_status: PropTypes.string,
    pricing_rule: PropTypes.object,
    slow_local: PropTypes.bool,
    shopifySubscriptionContractId: PropTypes.string,
    quantity: PropTypes.number,
  }).isRequired,
  onGenerateInstance: PropTypes.func,
  getUserRecords: PropTypes.func.isRequired,
  onCreateNote: PropTypes.func.isRequired,
  onDeleteNote: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  onDiscard: PropTypes.func,
  onSelect: PropTypes.func,
  setTare: PropTypes.func,
  isDetail: PropTypes.bool,
  userIndex: PropTypes.number,
  setNeedsSupport: PropTypes.func.isRequired,
  onNeedsSupport: PropTypes.func.isRequired,
  getUserLastOrder: PropTypes.func.isRequired,
  withDisconnected: PropTypes.bool,
  setOrdergenStatus: PropTypes.func,
  onOrdergenStatusChange: PropTypes.func,
  hideOnGeneration: PropTypes.bool,
  getUserCharges: PropTypes.func,
  vendorDownTimes: PropTypes.array,
  getUserNotes: PropTypes.func,
};

UserComponent.defaultProps = {
  addToast: () => {},
  onDiscard: () => {},
  userIndex: 0,
  hideOnGeneration: true,
  getUserCharges: () => {},
};

export const User = connect(null, dispatch => ({
  getUserNotes: id => dispatch(getUserNotesOrderGenAction(id)),
  getUserRecords: id => dispatch(getUserRecordsAction(id, false)),
}))(UserComponent);
