import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Field, DataLoading } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  content: Yup.object().nullable(),
});

export const InfluencerForm = ({ onSubmit, onSuccess }) => {
  const onChange = useCallback(({ submitForm, setFieldValue }) => e => {
    const reader = new FileReader();
    reader.onload = e => {
      setFieldValue('content', e.target.result);
      setTimeout(submitForm);
    };
    reader.readAsText(e.target.files[0]);
  }, []);

  return (
    <Form
      initialValues={{ content: '' }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      inline
    >
      {({ isSubmitting, setFieldValue, submitForm }) => (
        <>
          <Field
            disabled={isSubmitting}
            type="file"
            name="file"
            onChange={onChange({ setFieldValue, submitForm })}
            accept=".csv"
          />
          <Field name="content" type="hidden" />
          {isSubmitting && <DataLoading count={0} isLoading={true} />}
        </>
      )}
    </Form>
  );
};

InfluencerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};
