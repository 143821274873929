import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, SubmitButton } from '@bottomless/common/components';

export const StripeAccount = ({ onSubmit, onSuccess, vendor }) => {
  const handleSubmit = useCallback(() => onSubmit(vendor._id), [onSubmit, vendor]);

  return (
    <>
      {(!vendor.accounting || !vendor.accounting.status) && (
        <Form onSubmit={handleSubmit} onSuccess={onSuccess}>
          {({ isSubmitting }) => (
            <SubmitButton size="sm" color="info" isSubmitting={isSubmitting}>
              Create Stripe account
            </SubmitButton>
          )}
        </Form>
      )}
    </>
  );
};

StripeAccount.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    accounting: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
