export const createSelectOptions = (
  data,
  label,
  inputLabel = item => item.name
) =>
  !data
    ? {}
    : data.reduce(
        (all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }),
        label ? { null: label } : {}
      );

export const yesOrNoOptions = { null: "---", true: "Yes", false: "No" };
