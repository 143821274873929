import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Collapse, Table } from 'reactstrap';
import { isNil } from 'lodash-es';
import * as Yup from 'yup';
import { Form, Price, DateDiffFormat } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { OrderRow } from './OrderRow';
import './BatchShipment.scss';

const UpdatePayoutsSchema = Yup.object();
const DEFAULT_COMMISSION = 17;
const STATUS_PAID = 'paid';

export const BatchShipmentHistory = ({ batch, accounting }) => {
  const [isOpen, toggle] = useToggle(false);

  const findVariant = subproduct_id => subproduct_id.product.variants.find(v => v._id === subproduct_id.variant);

  const calculateCommission = (variant, order) =>
    (!isNil(order?.bl_commission)
      ? order.bl_commission / 100
      : ((order.amount_paid ? order.amount_paid / 100 : variant.price) *
          (accounting?.commission || DEFAULT_COMMISSION)) /
        100
    ).toFixed(2);

  const summary = useMemo(() => batch.orders.reduce((sum, order) => sum + (order.vendor_payout || 0), 0), [batch]);

  const initialValues = useMemo(
    () =>
      batch.orders.reduce(
        (all, order) => ({
          ...all,
          [order._id + 'payout']: (order.vendor_payout || 0) / 100,
          [order._id + 'cost']: findVariant(order.subproduct_id) ? findVariant(order.subproduct_id).cost : 0,
          [order._id + 'commission']: findVariant(order.subproduct_id)
            ? calculateCommission(findVariant(order.subproduct_id), order)
            : 0,
        }),
        {}
      ),
    [batch]
  );

  return (
    <Card className="mb-3">
      <CardBody className="clickable" onClick={toggle}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div>
              <DateDiffFormat date={batch.created_at} />
              <div>Batch: {batch.batch_id}</div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {batch.status === STATUS_PAID && <div className="text-success mr-4">Paid</div>}
            {batch.status && <div className="mr-4">Status: {batch.status}</div>}
            <Price value={summary} cents />
          </div>
        </div>
      </CardBody>
      {isOpen && (
        <Collapse isOpen={isOpen}>
          <CardBody className="batch-shipment-orders">
            <Form initialValues={initialValues} validationSchema={UpdatePayoutsSchema}>
              {({ setFieldValue }) => (
                <>
                  <Table size="sm" className="mt-3">
                    <thead>
                      <tr>
                        <th>Shipping</th>
                        <th>Subproduct</th>
                        <th>Size</th>
                        <th>A.Paid</th>
                        <th>Price</th>
                        <th>Shipping</th>
                        <th>Stripe%</th>
                        <th>Cost</th>
                        <th>BL%</th>
                        <th>Payout</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batch.orders.map(order => (
                        <OrderRow
                          key={order._id}
                          order={order}
                          disabled={true}
                          accounting={accounting}
                          calculateCommission={calculateCommission}
                          setPayoutValue={value => setFieldValue(order._id + 'payout', value)}
                        />
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </Form>
          </CardBody>
        </Collapse>
      )}
    </Card>
  );
};

BatchShipmentHistory.propTypes = {
  batch: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    batch_id: PropTypes.string,
    orders: PropTypes.array.isRequired,
    payout_id: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
  accounting: PropTypes.object,
};
