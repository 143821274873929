import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Col } from 'reactstrap';

export const CustomListProduct = ({ index, product, grind, variant: variantId, inRow, onClick }) => {
  const variant = useMemo(() => product.variants.find(v => v._id === variantId), [variantId, product]);

  const Wrapper = useMemo(
    () =>
      onClick
        ? ({ children }) => (
            <Button
              color="text"
              className="d-flex align-items-center admin-custom-list-button"
              onClick={() => onClick({ product: product._id, variant: variantId, grind: grind._id })}
            >
              {children}
            </Button>
          )
        : React.Fragment,
    [onClick, product, variantId]
  );

  return (
    <Col xs={12 / inRow} className="d-flex align-items-center mb-3">
      <Wrapper>
        <div className="mr-2 text-lg admin-custom-list-number">{index}</div>
        <div className="text-sm">
          <div className="text-secondary">{product.vendor_name}</div>
          <div>{product.name}</div>
          <div className="text-secondary">
            {variant?.size}oz{grind && ` - ${grind.name}`}
          </div>
        </div>
      </Wrapper>
    </Col>
  );
};

CustomListProduct.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  grind: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  variant: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  inRow: PropTypes.number,
  onClick: PropTypes.func,
};
