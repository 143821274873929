import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  content: Yup.string().required('This field is required'),
});

export const CreateNote = ({ onSubmit, onSuccess }) => {
  const handleSuccess = (data, { resetForm }) => {
    onSuccess(data);
    resetForm({ content: '' });
  };

  return (
    <div className="create-customer-note">
      <Form initialValues={{ content: '' }} validationSchema={Schema} onSubmit={onSubmit} onSuccess={handleSuccess}>
        {({ isSubmitting }) => (
          <>
            <Field name="content" type="textarea" label="Content" />
            <SubmitButton color="dark" isSubmitting={isSubmitting}>
              Create
            </SubmitButton>
          </>
        )}
      </Form>
    </div>
  );
};

CreateNote.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
