import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SlowLocalBadge.scss';

export const SlowLocalBadge = ({ className }) => (
  <div className={classNames(className, 'text-warning slow-local-badge')}>Slow local</div>
);

SlowLocalBadge.propTypes = {
  className: PropTypes.string,
};
