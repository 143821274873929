import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import {
  fulfillECommerceOrderAction,
  getECommercePaidOrdersAction,
  startECommerceOrderProcessingAction,
  updateProductAction,
} from '../../../../store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { Order } from './components/Order';
import { tabs } from '../Tabs';
import { addToastAction } from '@bottomless/common/store';
import './PaidOrder.scss';

const ECommercePaidOrderPageComponent = ({
  getOrders,
  fulfillOrder,
  addToast,
  startOrderProcessing,
  updateProduct,
}) => {
  const [orders, setOrders] = useState(null);

  useDataEffect(getOrders, setOrders);

  return (
    <PanelPage title="Paid Orders" tabs={tabs}>
      <DataLoading count={(orders || { length: 0 }).length} isLoading={orders === null}>
        <div className="text-center">
          <span className="d-block mb-3">No paid orders right now.</span>
        </div>
      </DataLoading>
      {orders && (
        <>
          {orders.map(order => (
            <Order
              order={order}
              key={order._id}
              fulfillOrder={fulfillOrder}
              addToast={addToast}
              startOrderProcessing={startOrderProcessing}
              updateProduct={updateProduct}
            />
          ))}
        </>
      )}
    </PanelPage>
  );
};

ECommercePaidOrderPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  fulfillOrder: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  startOrderProcessing: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
};

export const ECommercePaidOrderPage = connect(null, dispatch => ({
  getOrders: () => dispatch(getECommercePaidOrdersAction()),
  fulfillOrder: (id, data) => dispatch(fulfillECommerceOrderAction(id, data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  startOrderProcessing: id => dispatch(startECommerceOrderProcessingAction(id)),
  updateProduct: (id, data) => dispatch(updateProductAction(id, data)),
}))(ECommercePaidOrderPageComponent);
