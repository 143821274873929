import { useToggle } from '@bottomless/common/src/hooks';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard, Default } from '@bottomless/common/components';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReplaceOrderForm } from '../../../../components/Admin/Order';
import { Confirmation } from '../../../../components/Confirmation/Confirmation';

const OrderProblemReasons = {
  order_stuck: 'Order was not delivered',
  wrong_product: 'The product is not what I ordered',
  broken_product: 'There is something wrong with the product',
  wrong_status: 'Order marked as delivered but was not',
  charge_failure: 'Order charge failed 4 times',
};

export const OrderReport = ({ order, products, onReplaceOrder, resolveReported, addToast, user }) => {
  const [isOpen, toggle] = useToggle();
  const emitter = useMemo(() => new EventTarget(), []);

  const reason = useMemo(() => OrderProblemReasons[order.reportProblem.reason] || order.reportProblem.reason, [order]);

  const onButtonClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  const onResolveReported = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      return new Promise((promiseResolve, reject) => {
        const resolve = () => {
          const resolveWithToast = async () => {
            const res = await resolveReported(order._id);
            addToast('Order marked as resolved');
            return res;
          };
          return promiseResolve(resolveWithToast());
        };

        const event = new Event('open');
        event.promise = { resolve, reject };
        emitter.dispatchEvent(event);
      });
    },
    [resolveReported, order, addToast]
  );

  const onSubmit = useCallback(data => onReplaceOrder(order._id, data), [order, onReplaceOrder]);
  const onSuccess = useCallback(() => {
    addToast('Order replaced');
    toggle();
  }, [addToast, toggle]);

  return (
    <div className="d-flex justify-content-between">
      <div>
        <div>Reason: {reason}</div>
        <div>
          Comment: <Default>{order.reportProblem.comment}</Default>
        </div>
      </div>
      <div>
        <Button onClick={onResolveReported} className="mr-2">
          Resolve problem
        </Button>
        <Button onClick={onButtonClick} color="warning">
          Replace order
        </Button>
      </div>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <div>Order to be replaced</div>
          <div className="text-sm text-secondary">
            {order._id} <CopyToClipboard text={order._id} />
          </div>
        </ModalHeader>
        <ModalBody>
          <ReplaceOrderForm
            products={products}
            order={order}
            onSubmit={onSubmit}
            onSubmitSuccess={onSuccess}
            user={user}
          />
        </ModalBody>
      </Modal>
      <Confirmation openEmitter={emitter} text="Are you sure about resolving the problem?" />
    </div>
  );
};

OrderReport.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    reportProblem: PropTypes.shape({
      reason: PropTypes.string,
      comment: PropTypes.string,
    }),
  }).isRequired,
  products: PropTypes.array,
  onReplaceOrder: PropTypes.func.isRequired,
  resolveReported: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  user: PropTypes.object,
};
