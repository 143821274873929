import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetCategoriesTypes = createAction('Admin Categories', 'Get all');

export const getCategoriesAction = () => ({
  [RSAA]: {
    endpoint: 'admin/categories',
    method: 'GET',
    types: [GetCategoriesTypes.REQUEST, GetCategoriesTypes.SUCCESS, GetCategoriesTypes.FAILURE],
  },
});
