import { useMemo } from 'react';

export const useSelectTastingNotesOptions = tastingNotes => {
  return useMemo(() => {
    if (!tastingNotes) {
      return [];
    }

    return tastingNotes.reduce((all, tastingNote) => {
      if (tastingNote.items) {
        return [...all, ...tastingNote.items.map(item => ({ value: item._id, label: item.name }))];
      }

      return [...all, { value: tastingNote._id, label: tastingNote.name }];
    }, []);
  }, [tastingNotes]);
};
