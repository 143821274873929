import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import { DataLoading, SubmitButton } from '@bottomless/common/components';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { getBatchShipmentsAction, generateReceiptAction } from '../../../store/admin/batch-shipments';
import { BatchShipmentHistory } from './Payout/components/BatchShipmentHistory';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getVendorAction } from '../../../store/admin/vendor';
import { HeadingBack } from '../../../components/HeadingBack/HeadingBack';
import { Card, CardBody, Row } from 'reactstrap';
import { addToastAction } from '@bottomless/common/store';

const ReceiptSubmit = ({ vendor, payouts, generateReceipt, addToast, payoutId }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const regenerateReceipt = async () => {
    setSubmitting(true);
    try {
      const batchIds = payouts[payoutId].map(batch => batch._id);
      const {
        error,
        payload: { status },
      } = await generateReceipt({ batchIds, vendorId: vendor._id });
      if (!error && ![404, 401].includes(status)) {
        addToast('Receipt generated.');
      }
    } catch (e) {
      addToast('Unable to generate receipt.');
    }
    setSubmitting(false);
  };

  return (
    <SubmitButton onClick={regenerateReceipt} color="success" isSubmitting={isSubmitting} loadingText="regenerating">
      Regenerate Receipt{' '}
    </SubmitButton>
  );
};

ReceiptSubmit.propTypes = {
  generateReceipt: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
  payouts: PropTypes.object.isRequired,
  payoutId: PropTypes.string.isRequired,
};

export const PayoutHistoryPageComponent = ({
  match,
  getBatchShipments,
  getVendor,
  location,
  generateReceipt,
  addToast,
}) => {
  const [batchShipments, setBatchShipments] = useState();
  const query = qs.parse(location.search);
  const [vendor, setVendor] = useState(null);
  const Heading = HeadingBack({
    to: query.back ? query.back : `/admin/vendors/${match.params.id}`,
  });

  useConditionalDataEffect(match.params && match.params.id, getVendor, setVendor, match.params.id, null);

  const getBatch = useCallback(() => (match.params.id ? getBatchShipments(match.params.id, { status: 'paid' }) : {}), [
    getBatchShipments,
    vendor,
  ]);

  const { isFetching } = useDataEffect(getBatch, setBatchShipments);

  const payouts = useMemo(() => {
    if (batchShipments) {
      return batchShipments.data.reduce((all, current) => {
        if (current.payout_id) {
          if (all[current.payout_id]) {
            all[current.payout_id].push(current);
          } else {
            all[current.payout_id] = [current];
          }
        } else {
          if (all['others']) {
            all['others'].push(current);
          } else {
            all['others'] = [current];
          }
        }
        return { ...all };
      }, {});
    }
  }, [batchShipments]);

  return (
    <>
      <PanelPage title={vendor && vendor.name} heading={Heading}>
        <DataLoading count={payouts ? Object.keys(payouts).length : 0} isLoading={isFetching} />
        {payouts &&
          Object.keys(payouts).map(key => (
            <Card className="mb-3" key={key}>
              <CardBody>
                <Row className="d-flex justify-content-between align-items-center pl-3 pr-3">
                  <div>Payout ID: {key}</div>
                  <ReceiptSubmit
                    generateReceipt={generateReceipt}
                    addToast={addToast}
                    vendor={vendor}
                    payoutId={key}
                    payouts={payouts}
                  />
                </Row>
                {payouts[key].map(batch => (
                  <BatchShipmentHistory key={batch._id} batch={batch} accounting={batchShipments.vendor.accounting} />
                ))}
              </CardBody>
            </Card>
          ))}
      </PanelPage>
    </>
  );
};

PayoutHistoryPageComponent.propTypes = {
  getVendor: PropTypes.func.isRequired,
  getBatchShipments: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  generateReceipt: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const PayoutHistoryPage = connect(null, dispatch => ({
  getVendor: id => dispatch(getVendorAction(id)),
  getBatchShipments: (vendorId, params) => dispatch(getBatchShipmentsAction(vendorId, params)),
  generateReceipt: batches => dispatch(generateReceiptAction(batches)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(PayoutHistoryPageComponent);
