import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Form, SubmitButton } from '@bottomless/common/components';

const StripeInviteComponent = ({ onSubmit, onSuccess, vendor }) => {
  const handleSubmit = useCallback(() => onSubmit(vendor._id), [onSubmit, vendor]);

  return (
    <>
      <Form onSubmit={handleSubmit} onSuccess={onSuccess}>
        {({ isSubmitting }) => (
          <SubmitButton
            size="sm"
            color="info"
            isSubmitting={isSubmitting}
            disabled={vendor.accounting.resentStripeInvite}
          >
            Resend Stripe Invite
          </SubmitButton>
        )}
      </Form>
    </>
  );
};

StripeInviteComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    accounting: PropTypes.shape({
      status: PropTypes.string.isRequired,
      resentStripeInvite: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

export const StripeInvite = memo(StripeInviteComponent);
