import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { createSelectOptions } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  grind: Yup.string().required(),
});

export const GrindForm = ({ user, grinds, onSubmit, onSubmitSuccess }) => {
  const grindOptions = useMemo(() => createSelectOptions(grinds), [grinds]);

  return (
    <Form
      initialValues={{
        grind: user.grind._id,
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="grind" label="Grind" type="select" options={grindOptions} />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

GrindForm.propTypes = {
  user: PropTypes.shape({
    grind: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  grinds: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
