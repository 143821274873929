import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'react-feather';

export const HeadingBack = ({ to }) => (
  <Link to={to} className="d-flex align-items-center">
    <ChevronLeft size="14" />
    <span className="ml-1 mb-1">Back</span>
  </Link>
);

HeadingBack.propTypes = {
  to: PropTypes.string.isRequired,
};
