import PropTypes from 'prop-types';
import { debounce, pickBy } from 'lodash-es';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field } from '@bottomless/common/components';
import { createSelectOptions } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  vendor_id: Yup.string(),
  status: Yup.string(),
  search: Yup.string(),
});

export const Filters = ({ vendors }) => {
  const location = useLocation();
  const history = useHistory();

  const query = qs.parse(location.search);
  const pathname = location.pathname;

  const formRef = useRef();
  const [filters, setFilters] = useState({ status: 'active', hidden: false, ...query });

  useEffect(() => {
    history.push({ pathname, search: qs.stringify(pickBy(filters, v => v && v !== 'null')) });
  }, [filters, history, pathname]);

  const vendorOptions = createSelectOptions(vendors, '--- Select vendor ---', v => `${v.name} - ${v.status}`);

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  return (
    <Form
      inline
      innerRef={formRef}
      initialValues={{ ...filters }}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {() => (
        <>
          <Row className="w-100 align-items-center">
            <Col xs="4">
              <Field
                name="vendor_id"
                type="select"
                label="Vendor"
                options={vendorOptions}
                onChange={e => onFilterChange('vendor_id', e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Field
                name="status"
                label="status"
                type="select"
                options={{
                  null: '---',
                  active: 'Active',
                  archived: 'Archived',
                  draft: 'Draft',
                }}
                onChange={e => onFilterChange('status', e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Field
                name="hidden"
                type="select"
                label="Hidden"
                options={{ null: '---', true: 'Yes', false: 'No' }}
                onChange={e => onFilterChange('hidden', e.target.value)}
              />
            </Col>
            <Col className="pr-0">
              <Field
                className="w-100"
                name="search"
                label="Search"
                onChange={debounce(event => {
                  onFilterChange('search', event.target.value);
                }, 200)}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

Filters.propTypes = {
  vendors: PropTypes.array.isRequired,
};
