import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, SubmitButton, Field } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  vendor_id: Yup.string().required,
  down_date: Yup.date().required,
  reason: Yup.string().required,
});

export const AddDownDayForm = ({ vendors, onSubmit, toggleAddModal }) => {
  const createSelectOptions = (data, label, inputLabel = item => item.name) =>
    !data
      ? {}
      : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

  const vendorOptions = createSelectOptions(vendors ? vendors : null, '-- Choose vendor --');

  return (
    <Form initialValues={{}} validationSchema={Schema} onSubmit={onSubmit} onSuccess={() => toggleAddModal()}>
      {({ isSubmitting }) => (
        <>
          <Field type="select" name="vendor_id" label="Vendor" options={vendorOptions} />
          <Field type="date" name="down_date" label="Down Date" format="MM/DD/YYYY" />
          <Field type="textarea" name="reason" label="Reason" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

AddDownDayForm.propTypes = {
  vendors: PropTypes.array,
  onSubmit: PropTypes.func,
  toggleAddModal: PropTypes.func,
};
