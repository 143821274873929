import { Field } from '@bottomless/common/src/components/Form';
import { get } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

export const GenericAttributes = ({ manifest, product }) => {
  const fields = useMemo(() => manifest.attributes.sort((a, b) => a.dataType.localeCompare(b.dataType)), [manifest]);

  const getCorrectType = useCallback(attribute => {
    if (attribute.dataType === 'array') {
      return 'select-creatable';
    }

    return attribute.dataType;
  }, []);

  if (!fields.length) {
    return <div className="text-secondary">No category-specific settings for this category</div>;
  }

  return (
    <Row>
      {fields.map(attribute => (
        <Col key={attribute._id} xs="6">
          <Field
            options={[]}
            name={attribute.field}
            type={getCorrectType(attribute)}
            label={attribute.name}
            defaultValue={get(product, attribute.field, '')}
          />
        </Col>
      ))}
    </Row>
  );
};

GenericAttributes.propTypes = {
  manifest: PropTypes.shape({
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        dataType: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  product: PropTypes.object.isRequired,
};
