import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getRetentionDataAction } from '../../../store/admin/data';
import { tabs } from './Tabs';

const DatePicker = React.lazy(() => import('react-datepicker'));

const randomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

const Graph = ({ data }) => {
  const [ref, setRef] = useState(null);

  const datasets = data.map(({ data, label }) => {
    return {
      label,
      data: Object.keys(data).map(k => ({ x: k, y: data[k].total })),
      fill: false,
      borderColor: randomColor(),
    };
  });

  const options = {
    elements: { line: { tension: 0 } },
    scales: {
      yAxes: [
        {
          ticks: {
            callback(value) {
              return '$' + value;
            },
          },
        },
      ],
    },
  };

  return (
    <div className="retention-graph">
      <div className="btn-group mb-5">
        <button
          className="btn btn-sm btn-info"
          onClick={() => {
            ref.chartInstance.data.datasets.forEach(function(ds) {
              ds.hidden = true;
            });
            ref.chartInstance.update();
          }}
        >
          disable legends
        </button>
        <button
          className="btn btn-sm btn-info"
          onClick={() => {
            ref.chartInstance.data.datasets.forEach(function(ds) {
              ds.hidden = !ds.hidden;
            });
            ref.chartInstance.update();
          }}
        >
          toggle legends
        </button>
      </div>
      <Line
        ref={setRef}
        data={{
          datasets,
          labels: Object.values(data).map(({ label }) => label),
        }}
        options={options}
        height={150}
      />
    </div>
  );
};

Graph.propTypes = {
  data: PropTypes.array.isRequired,
};

const RetentionPageComponent = ({ getRetentionData, location, history }) => {
  const query = qs.parse(location.search);

  const [data, setData] = useState([]);
  const [filters, setFilter] = useState({ trim: 0, ...query });
  const [startDate, setStartDate] = useState(new Date('2016-10-01'));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({ ...query, ...filters }),
    });
  }, [filters]);

  useDataEffect(getRetentionData, setData);

  const pushFilter = data => setFilter({ ...filters, ...data });

  return (
    <PanelPage title="Retention" tabs={tabs}>
      <div className={`retention-graph-page`}>
        {!data && <span className="text-secondary">No data yet</span>}
        {data.length > 0 && (
          <>
            <Row className="mb-4">
              <Col xs="2">
                <label>Type:</label>
                <select
                  className="form-control"
                  value={filters.type}
                  defaultValue={'weekly'}
                  onChange={e => pushFilter({ type: e.target.value })}
                >
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </Col>
              {filters.type && filters.type === 'monthly' && (
                <Col xs="2">
                  <label>Graph type:</label>
                  <select
                    className="form-control"
                    value={filters.graphType}
                    onChange={e => pushFilter({ graphType: e.target.value })}
                  >
                    <option value="indexByMonth">indexByMonth</option>
                    <option value="indexByDate">indexByDate</option>
                  </select>
                </Col>
              )}

              <Col xs="2">
                <label>Value:</label>
                <select
                  className="form-control"
                  value={filters.value}
                  defaultValue={'sum'}
                  onChange={e => pushFilter({ value: e.target.value })}
                >
                  <option value="sum">Sum</option>
                  <option value="avg">Average</option>
                </select>
              </Col>
              <Col xs="4" className="d-flex flex-row justify-content-between">
                <div>
                  <label>From</label>
                  <div>
                    <DatePicker
                      selected={startDate}
                      onChange={date => {
                        setStartDate(date);
                        pushFilter({ from: date.toDateString() });
                      }}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="yyyy/MM/dd"
                      showMonthYearPicker
                    />
                  </div>
                </div>
                <div>
                  <label>To</label>
                  <div>
                    <DatePicker
                      selected={endDate}
                      onChange={date => {
                        setEndDate(date);
                        pushFilter({ to: date.toDateString() });
                      }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="yyyy/MM/dd"
                      showMonthYearPicker
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="4" className="d-flex flex-row justify-content-between">
                <div>
                  <label htmlFor="trim" className="mr-1">
                    Trim current month
                  </label>
                  <input
                    type="checkbox"
                    name="trim"
                    checked={filters.trim}
                    onChange={() => pushFilter({ trim: filters.trim === 0 ? 1 : 0 })}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <Graph data={data} />
              </Col>
            </Row>
          </>
        )}
      </div>
    </PanelPage>
  );
};

RetentionPageComponent.propTypes = {
  getRetentionData: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export const RetentionPage = connect(null, (dispatch, { location: { search } }) => ({
  getRetentionData: () => dispatch(getRetentionDataAction(qs.parse(search))),
}))(RetentionPageComponent);
