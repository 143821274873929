import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

export const RoasterAttachment = ({ onChange }) => {
  const [preview, setPreview] = useState();

  const toBase64 = useCallback(
    file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.replace(/^.*;base64,/, ''));
        reader.onerror = error => reject(error);
      }),
    []
  );

  const onInputChange = useCallback(
    async e => {
      const [file] = e.target.files;

      if (file) {
        const { name, type } = file;
        const data = await toBase64(file);
        setPreview(URL.createObjectURL(file));
        onChange({ data, name, type });
      }
    },
    [onChange, toBase64]
  );

  return (
    <div className="mb-3">
      <Input type="file" name="roaster-attachment" onChange={onInputChange} accept="image/*" />
      {preview && <img src={preview} alt="" className="replacement-attachment-preview mt-2" />}
    </div>
  );
};

RoasterAttachment.propTypes = {
  onChange: PropTypes.func.isRequired,
};
