/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Price, Tooltip, Tracking } from '@bottomless/common/components';
import { VendorPaymentTypes, DEFAULT_COMMISSION } from '../../constants';
import './OrderRow.scss';
import { Truck } from 'react-feather';

const OrderRowComponent = ({
  order,
  accounting,
  disabled,
  calculateCommission,
  setPayoutValue,
  setCommissionValue,
  onUpdateField,
  batchId,
}) => {
  const { payment_type: paymentType } = accounting || {};
  const variant = useMemo(() => order.subproduct_id.product.variants.find(v => v._id === order.subproduct_id.variant), [
    order,
  ]);

  const commission = useMemo(() => calculateCommission(variant, order), [order, accounting]);

  const payoutFieldRef = useRef();
  const commissionFieldRef = useRef();
  const commissionRef = useRef(commission);
  const costRef = useRef(variant.cost);
  const websitePricingRef = useRef(variant.website_pricing);

  const [currentPayout, setCurrentPayout] = useState();

  const moreThan10ShippingFeeAnd32oz = variant.size === 32 && order.actual_shipping_cost > 10;
  const freeShipping = !!order?.pricing_rule?.free_shipping;

  useEffect(() => {
    setCurrentPayout(Number(payoutFieldRef?.current.value).toFixed(2));
  }, [setCurrentPayout, payoutFieldRef.current?.value]);

  const stripe = useMemo(() => (order.transaction_cost ? Number(order.transaction_cost) : 0), [order]);

  const productPrice = useMemo(() => {
    switch (paymentType) {
      case VendorPaymentTypes.AMOUNT_PAID:
      case VendorPaymentTypes.POST_PURCHASE_SHOPIFY:
        return order.amount_paid ? order.amount_paid / 100 : variant.price;
      case VendorPaymentTypes.WEBSITE_PRICING:
        return variant.website_pricing || 0;
      case VendorPaymentTypes.WHOLESALE:
        return variant.cost || 0;
      default:
        return 0;
    }
  }, [paymentType, order, variant]);

  const expectedPrice = useMemo(() => {
    switch (paymentType) {
      case VendorPaymentTypes.AMOUNT_PAID:
      case VendorPaymentTypes.WEBSITE_PRICING:
      case VendorPaymentTypes.POST_PURCHASE_SHOPIFY: {
        const shippingFee = !order?.pricing_rule?.free_shipping
          ? (order.actual_shipping_cost || 0) - order.shipment_paid / 100
          : order.actual_shipping_cost || 0;

        return order.replacing?.fulfillmentErrors?.vendor?.error
          ? -order.actual_shipping_cost
          : Math.max(0, productPrice - Number(commissionFieldRef.current?.value || commission) - stripe - shippingFee);
      }
      case VendorPaymentTypes.WHOLESALE:
        return order.replacing?.fulfillmentErrors?.vendor?.error ? -order.actual_shipping_cost : variant.cost || 0;
      default:
        return 0;
    }
  }, [paymentType, order, variant, commissionFieldRef.current?.value, stripe]);

  const matches = expectedPrice.toFixed(2) === currentPayout;

  const onCommissionChange = useCallback(
    ({ target: { value } }) => {
      if (
        paymentType === VendorPaymentTypes.WEBSITE_PRICING ||
        paymentType === VendorPaymentTypes.AMOUNT_PAID ||
        paymentType === VendorPaymentTypes.POST_PURCHASE_SHOPIFY
      ) {
        const diff = value - commissionRef.current;
        commissionRef.current = value;
        const newPayoutValue = (Number(payoutFieldRef.current.value) - diff).toFixed(2);
        setPayoutValue(newPayoutValue);
        setCurrentPayout(newPayoutValue);
        const data = {
          newPayout: newPayoutValue,
          newCommission: value,
        };
        onUpdateField({ batchId, orderId: order._id, data });
      }
    },
    [commissionRef, payoutFieldRef, setPayoutValue, onUpdateField]
  );

  const onPayoutChange = useCallback(
    ({ target: { value } }) => {
      const data = {
        newPayout: value,
      };
      onUpdateField({ batchId, orderId: order._id, data });
    },
    [onUpdateField]
  );

  const onCostChange = useCallback(
    ({ target: { value } }) => {
      if (paymentType === VendorPaymentTypes.WHOLESALE) {
        costRef.current = value;
        const newPayoutValue = Number(value).toFixed(2);
        setPayoutValue(newPayoutValue);
        setCurrentPayout(newPayoutValue);
        const data = {
          newPayout: newPayoutValue,
        };
        onUpdateField({ batchId, orderId: order._id, data });
      }
    },
    [costRef, payoutFieldRef, setPayoutValue, onUpdateField]
  );

  const onWebsitePricingChange = useCallback(
    ({ target: { value } }) => {
      if (paymentType === VendorPaymentTypes.WEBSITE_PRICING) {
        const newCommissionValue = ((value * (accounting?.commission || DEFAULT_COMMISSION)) / 100).toFixed(2);

        let newPayoutValue;
        if (order.replacing?.fulfillmentErrors?.vendor?.error) {
          newPayoutValue = -order.actual_shipping_cost;
        } else {
          newPayoutValue = Math.max(0, value - newCommissionValue - stripe - order.actual_shipping_cost).toFixed(2);
        }
        websitePricingRef.current = value;
        setPayoutValue(newPayoutValue);
        setCurrentPayout(newPayoutValue);
        setCommissionValue(newCommissionValue);
        const data = {
          newPayout: newPayoutValue,
          newCommission: newCommissionValue,
        };
        onUpdateField({ batchId, orderId: order._id, data });
      }
    },
    [websitePricingRef, payoutFieldRef, setPayoutValue, onUpdateField, setCommissionValue]
  );

  return (
    <tr>
      <td>
        <div className={moreThan10ShippingFeeAnd32oz ? 'size-and-shipping' : ''}>
          {order.user_id.first_name} {order.user_id.last_name}
        </div>
        <Tracking
          number={order.tracking_number}
          shippingService={order.shipping_service}
          label={null}
          trackingUrl={order.tracking_url}
        />
        <div>{order.shipping_status}</div>
        <div className="my-1 shipping-free-paid">
          {freeShipping ? (
            <div className="text-success d-flex align-items-center">
              <Truck size={16} />
              <span className="ml-1">Free shipping</span>
            </div>
          ) : (
            <div className="text-danger d-flex align-items-center">
              <Truck size={16} />
              <span className="ml-1">Paid shipping</span>
            </div>
          )}
        </div>
      </td>
      <td>
        <div>{order.subproduct_id.product.name}</div>
        {order.grind && <div className="text-secondary">{order.grind.name}</div>}
      </td>
      <td className={moreThan10ShippingFeeAnd32oz ? 'size-and-shipping' : ''}>{variant.size}oz</td>
      <td>
        <Price value={order.amount_paid} cents />
      </td>
      <td>
        <Price value={variant.price} />
      </td>
      <td className={moreThan10ShippingFeeAnd32oz ? 'size-and-shipping' : ''}>
        <Price value={order.actual_shipping_cost} />
      </td>
      <td>
        <Price value={stripe} />
      </td>
      <td>
        <div className="d-flex align-items-center payout-input">
          <Field
            name={order._id + 'cost'}
            type="number"
            label="Cost"
            disabled={disabled}
            min={0}
            onChange={onCostChange}
          />
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center payout-input">
          <Field
            name={order._id + 'website_pricing'}
            type="number"
            label="W.Pricing"
            disabled={disabled}
            min={0}
            onChange={onWebsitePricingChange}
          />
        </div>
      </td>
      <td>
        {commission && (
          <div className="d-flex align-items-center payout-input">
            <Field
              innerRef={commissionFieldRef}
              name={order._id + 'commission'}
              type="number"
              label="BL%"
              disabled={disabled}
              min={0}
              onChange={onCommissionChange}
            />
          </div>
        )}
      </td>
      <td>
        <div className="d-flex payout-field">
          <Field
            innerRef={payoutFieldRef}
            name={order._id + 'payout'}
            type="number"
            label="Payout"
            disabled={disabled}
            min={0}
            onChange={onPayoutChange}
          />
          <span className="ml-2">
            <Tooltip content={<Price value={expectedPrice} />} id={order._id}>
              <span className="text-sm">{matches !== null && matches ? '🟢' : '🔴'}</span>
            </Tooltip>
          </span>
        </div>
      </td>
      <td>{order.status}</td>
    </tr>
  );
};

OrderRowComponent.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    subproduct_id: PropTypes.object.isRequired,
    user_id: PropTypes.object.isRequired,
    grind: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    vendor_payout: PropTypes.number.isRequired,
    bl_commission: PropTypes.number,
    actual_shipping_cost: PropTypes.number,
    amount_paid: PropTypes.number,
    tracking_number: PropTypes.string,
    shipping_status: PropTypes.string,
    shipping_service: PropTypes.string,
    tracking_url: PropTypes.string,
    transaction_cost: PropTypes.string,
    shipment_paid: PropTypes.number,
    replacing: PropTypes.shape({
      fulfillmentErrors: PropTypes.shape({
        vendor: PropTypes.shape({
          error: PropTypes.string,
        }),
      }),
    }),
    pricing_rule: PropTypes.shape({
      _id: PropTypes.string,
      free_shipping: PropTypes.bool,
    }),
  }).isRequired,
  accounting: PropTypes.shape({
    commission: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  calculateCommission: PropTypes.func,
  setPayoutValue: PropTypes.func.isRequired,
  setCommissionValue: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  batchId: PropTypes.string.isRequired,
};

export const OrderRow = memo(OrderRowComponent);
