import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { tabs } from './Tabs';
import { getLikedVendorsAction } from '../../../store/admin/vendor';
import { Vendor } from './components/Vendor';

const LikedVendorsPageComponent = ({ getLikedVendors }) => {
  const [vendors, setVendors] = useState(null);
  const [sort, setSort] = useState('like');
  useDataEffect(getLikedVendors, setVendors);

  const handleSort = type => e => {
    e.preventDefault();
    setSort(type);
  };

  const Heading = (
    <div className="d-flex justify-content-end align-items-center list-sorting">
      <strong className="mb-1 mr-2">Sort:</strong>
      <Button color="link" onClick={handleSort('like')} className="btn-no-transform" active={sort === 'like'}>
        Liked
      </Button>
      <Button color="link" onClick={handleSort('dislike')} className="btn-no-transform" active={sort === 'dislike'}>
        Disliked
      </Button>
      <Button color="link" onClick={handleSort('ratio')} className="btn-no-transform" active={sort === 'ratio'}>
        Like/Dislike
      </Button>
    </div>
  );

  return (
    <PanelPage title="Product feedback" tabs={tabs} heading={Heading}>
      <DataLoading count={(vendors || { length: 0 }).length} isLoading={vendors === null} />
      {vendors && vendors.sort(sortStategy(sort)).map((vendor, i) => <Vendor vendor={vendor} key={i} sort={sort} />)}
    </PanelPage>
  );
};

const sortStategy = sort => {
  switch (sort) {
    case 'like':
      return (a, b) => b.like - a.like;
    case 'dislike':
      return (a, b) => b.dislike - a.dislike;
    case 'ratio':
      return (a, b) => b.ratio - a.ratio;
    default:
      return () => 0;
  }
};

LikedVendorsPageComponent.propTypes = {
  getLikedVendors: PropTypes.func.isRequired,
};

export const LikedVendorsPage = connect(null, dispatch => ({
  getLikedVendors: () => dispatch(getLikedVendorsAction()),
}))(LikedVendorsPageComponent);
