import React, { useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { AlertCircle } from 'react-feather';
import { useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import './PermamentAlerts.scss';

export const withPermamentAlerts = Component => props => {
  const [alerts, addAlert] = useReducer((state, alert) => [alert, ...state], []);
  const dispatch = useDispatch();

  const onAddAlert = useCallback(
    (message, type = 'success', link) => {
      dispatch(addToastAction(message, type));
      addAlert({ message, type, link });
    },
    [dispatch, addAlert]
  );

  return (
    <>
      <PermamentAlerts alerts={alerts} />
      <Component {...props} addAlert={onAddAlert} />
    </>
  );
};

const getType = type => ({ error: 'danger' }[type] || type);

const PermamentAlerts = ({ alerts }) => {
  const [isOpen, toggle] = useToggle();

  return (
    <>
      <div className="perma-alerts-button" onClick={toggle}>
        <AlertCircle size="40" />
        {alerts.length > 0 && <div className="perma-alerts-badge">{alerts.length}</div>}
      </div>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Alerts</ModalHeader>
        <ModalBody>
          {alerts.length === 0 && <div>No alerts to show</div>}
          {alerts.map((alert, i) => (
            <Alert key={i} color={getType(alert.type)} className="mb-3">
              <div>{alert.message}</div>
              {alert.link}
            </Alert>
          ))}
        </ModalBody>
      </Modal>
    </>
  );
};

PermamentAlerts.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      type: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
};
