import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  status: Yup.string().required(),
});

export const ReOpenForm = ({ onSubmit, onSubmitSuccess }) => {
  const statusOptions = {
    active: 'Active',
    onboarding_scale_shipped: 'Onboarding Scale Shipped',
  };

  return (
    <Form
      initialValues={{
        status: 'active',
        scale_required: 'true',
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="status" label="Status" type="select" options={statusOptions} />
          <Field
            name="scale_required"
            label="Trigger scale shipment?"
            type="select"
            options={{ true: 'Yes', false: 'No' }}
          />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};
