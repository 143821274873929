import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { data as statesData } from 'usps-states';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox, CopyToClipboard, Image } from '@bottomless/common/components';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { createSelectOptions, yesOrNoOptions } from '@bottomless/common/utils';
import { addToastAction } from '@bottomless/common/store';
import { HeadingBack } from '../../../components/HeadingBack/HeadingBack';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getProductOptionsAction } from '../../../store/admin/product-options';
import {
  addVendorPackagingAction,
  createMagicLinkAction,
  createVendorAction,
  getVendorAction,
  updateVendorAction,
  createStripeAccountAction,
  getShipmentOptionsAction,
  updateShipmentOptionsAction,
  resendStripeInviteAction,
  blacklistVendorAction,
  createStripeMagicLinkAction,
  toggleShopifyWidgetAction,
} from '../../../store/admin/vendor';
import { getCategoriesAction } from '../../../store';
import { useSelectOptions } from '../Products/hooks';
import { StripeAccount } from './components/StripeAccount';
import { StripeInvite } from './components/StripeInvite';
import { Shipments } from './components/Shipments';
import { AddPackaging } from './components/AddPackagingModal';
import { ShopifyConfigurationModal } from './components/ShopifyConfigurationModal';
import { modalTypes, paymentTypes, accountingStatuses } from './constants';
import './Vendor.scss';

const Schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  description: Yup.string().required('This field is required'),
  location: Yup.string().required('This field is required'),
  verifiedAddress: Yup.object().shape({
    zip: Yup.string().required('This field is required'),
    state: Yup.string().required('This field is required'),
    street1: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
  }),
  pickup_note: Yup.string(),
  phone: Yup.string(),
  ordering_email: Yup.string()
    .email()
    .required('This field is required'),
  website: Yup.string().required('This field is required'),
  landing_page: Yup.string()
    .matches(/^\/\w+/, 'Landing page should start with /')
    .required(),
  showAsFeatured: Yup.boolean(),
  img_url: Yup.string().matches(/^https:/, 'Please use https://'),
  product_image_url: Yup.string().matches(/^https:/, 'Please use https://'),
  fulfillment_timelines: Yup.object().shape({
    sunday: Yup.boolean(),
    saturday: Yup.boolean(),
    friday: Yup.boolean(),
    thursday: Yup.boolean(),
    wednesday: Yup.boolean(),
    tuesday: Yup.boolean(),
    monday: Yup.boolean(),
  }),
  expected_times: Yup.object().shape({
    1: Yup.number().nullable(true),
    2: Yup.number().nullable(true),
    3: Yup.number().nullable(true),
    4: Yup.number().nullable(true),
    5: Yup.number().nullable(true),
  }),
  usesPackaging: Yup.object().shape({
    8: Yup.boolean(),
    10: Yup.boolean(),
    12: Yup.boolean(),
    14: Yup.boolean(),
    16: Yup.boolean(),
    24: Yup.boolean(),
    32: Yup.boolean(),
  }),
  packagingStock: Yup.object().shape({
    8: Yup.number().nullable(true),
    10: Yup.number().nullable(true),
    12: Yup.number().nullable(true),
    14: Yup.number().nullable(true),
    16: Yup.number().nullable(true),
    24: Yup.number().nullable(true),
    32: Yup.number().nullable(true),
  }),
  vendor_affiliate_payout: Yup.number(),
  fulfillment_partner: Yup.bool(),
  accounting: Yup.object().shape({
    commission: Yup.number().nullable(true),
    email: Yup.string().nullable(true),
    notes: Yup.string().nullable(true),
  }),
  subproductBoost: Yup.number(),
  main_category: Yup.string().required('This field is required'),
});

const FulfilmentSpeedComponent = ({ vendor, i }) => {
  const perc10 = get(vendor.fulfillment_times, `${i}.perc10`);
  const median = get(vendor.fulfillment_times, `${i}.median`);
  const perc90 = get(vendor.fulfillment_times, `${i}.perc90`);

  return (
    <>
      {perc10 ? perc10 : '-'} / {median ? median : '-'} / {perc90 ? perc90 : '-'}
    </>
  );
};

FulfilmentSpeedComponent.propTypes = {
  vendor: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
};

const states = statesData.reduce((states, { abbr }) => ({ ...states, [abbr]: abbr }), {});

export const VendorPageComponent = ({
  getVendor,
  updateVendor,
  createVendor,
  match,
  addVendorPackaging,
  addToast,
  location,
  history,
  createMagicLink,
  getProductsOptions,
  createStripeAccount,
  getShipmentOptions,
  updateShipmentOptions,
  resendStripeInvite,
  blacklistVendor,
  createStripeMagicLink,
  toggleShopifyWidget,
  getCategories,
}) => {
  const query = qs.parse(location.search);
  const formRef = useRef(null);
  const [vendor, setVendor] = useState(null);
  const [vendorPortalLink, setVendorPortalLink] = useState(false);
  const [stripeMagicLink, setStripeMagicLink] = useState(null);
  const [options, setOptions] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);
  const [togglingShopifyWidget, setTogglingShopifyWidget] = useState(false);

  const [venderUsesPackaging, setVenderUsesPackaging] = useState(false);

  const { data: categories } = useDataEffect(getCategories);

  const toggle = type => {
    setType(type);
    setOpen(!isOpen);
  };

  const isCreate = !(match.params && match.params.id);

  useConditionalDataEffect(match.params && match.params.id, getVendor, setVendor, match.params.id, null);
  useDataEffect(getProductsOptions, setOptions);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getVendorShipmentOptions = useCallback(() => (!isCreate ? getShipmentOptions(match.params.id) : null), [
    getShipmentOptions,
    isCreate,
    match.params.id,
  ]);

  const onShipmentsUpdate = useCallback(data => updateShipmentOptions(vendor._id, data), [
    vendor,
    updateShipmentOptions,
  ]);

  const { data: rawShipmentOptions } = useDataEffect(getVendorShipmentOptions);

  const [shipmentOptions, setShipmentOptions] = useState(rawShipmentOptions);
  useEffect(() => setShipmentOptions(rawShipmentOptions), [rawShipmentOptions, setShipmentOptions]);

  const onShipmentsSuccess = useCallback(
    ({ shipment_providers, vendorParcels, vendorPickups }) => {
      setVendor({ ...vendor, shipment_providers });
      setShipmentOptions({ ...rawShipmentOptions, vendorParcels, vendorPickups });
      addToast('Shipments updated');
    },
    [addToast, vendor, setVendor, rawShipmentOptions, setShipmentOptions]
  );

  useEffect(() => {
    if (vendor) {
      for (const [, value] of Object.entries(vendor.usesPackaging || {})) {
        if (value) {
          setVenderUsesPackaging(true);
          break;
        }
      }

      formRef.current.resetForm({ ...vendor });
    }
  }, [vendor, getVendor]);

  const handleSubmit = data => (isCreate ? createVendor(data) : updateVendor(vendor._id, data));

  const handleSuccess = (data, { resetForm }) => {
    addToast(`Vendor ${isCreate ? 'created' : 'updated'}`);

    if (isCreate && data._id) {
      history.push(`/admin/vendors/${data._id}`);
    }

    setVendor(data);
    resetForm(data);
  };

  const addPackaging = data => addVendorPackaging(vendor._id, data);
  const onPackagingSuccess = (data, { resetForm }) => {
    setVendor(data);
    addToast(data.message || 'Added packaging');
    resetForm();
    toggle();
  };

  const Heading = HeadingBack({
    to: query.back ? query.back : '/admin/vendors',
  });

  const handleLink = async () => {
    const { payload } = await createMagicLink(vendor._id);

    setVendorPortalLink(payload.url);
  };

  const getStripeMagicLink = async () => {
    try {
      const response = await createStripeMagicLink(vendor._id);
      if (response && response.payload && response.payload.url) return setStripeMagicLink(response.payload.url);
      addToast('Could not generate Stripe magic link', 'danger');
    } catch (error) {
      addToast('Could not generate Stripe magic link', 'danger');
    }
  };

  const parcels = createSelectOptions(options.parcels, '-- select parcel --');

  const onCreateStripeAccountSuccess = useCallback(
    vendor => {
      addToast('Stripe account created');
      setVendor(vendor);
    },
    [addToast, setVendor]
  );

  const onResendStripeInviteSuccess = useCallback(
    vendor => {
      addToast('Stripe invite resent');
      setVendor(vendor);
    },
    [addToast, setVendor]
  );

  const submitBlacklistVendor = async () => {
    try {
      const status = vendor.blacklist ? 'whitelist' : 'blacklist';
      const response = await blacklistVendor(vendor._id, { status });

      if (response && response.payload) {
        setVendor(response.payload);

        if (response.payload.blacklist) {
          return addToast('Vendor blacklisted successfully');
        } else {
          return addToast('Vendor whitelisted successfully');
        }
      }
      addToast('Could not whitelist/blacklist vendor');
    } catch (error) {
      addToast('An error occurred. Please try again later');
    }
  };

  const handleShopifyWidgetSubmit = useCallback(async () => {
    try {
      setTogglingShopifyWidget(true);
      const action = vendor.shopifyManifest?.widgetId ? 'disable' : 'enable';
      const response = await toggleShopifyWidget(vendor._id, action);

      if (response.payload) {
        setVendor(response.payload);
        if (response.payload.shopifyManifest?.widgetId) {
          addToast('Widget Enabled');
        } else {
          addToast('Widget Disabled');
        }
      }
      setTogglingShopifyWidget(false);
    } catch (err) {
      addToast('An error occurred. Please try again later');
      setTogglingShopifyWidget(false);
    }
  }, [addToast, toggleShopifyWidget, vendor?._id, vendor?.shopifyManifest?.widgetId]);

  const categoriesOptions = useSelectOptions(categories, '-- Choose category --');

  return (
    <>
      <PanelPage
        title={vendor && vendor.name ? vendor.name : 'Create new vendor'}
        heading={Heading}
        className="page-vendor"
      >
        {vendor && (
          <Row className="mb-4">
            <Col xs="3" md="2">
              {vendor.img_url && <Image src={vendor.img_url} alt={vendor.name} maxHeight="150" width="150" />}
            </Col>
            <Col xs="9" md="6" className="text-sm">
              <strong>{vendor.name}</strong>
              <p className="mb-0">Website: {vendor.website}</p>
              <p className="mb-0">Ordering email: {vendor.ordering_email}</p>
              {vendor.accounting && vendor.accounting.status && <p>Stripe account: {vendor.accounting.status}</p>}
              <p>
                Landing page:{' '}
                <Link to={vendor.landing_page} target="_blank" className="text-primary">
                  {vendor.landing_page}
                </Link>
              </p>
              <div>
                <Button size="sm" onClick={() => handleLink()}>
                  Create vendor portal magic link
                </Button>
                {vendorPortalLink && (
                  <div className="mt-2">
                    <CopyToClipboard className="d-block mt-2" text={vendorPortalLink} />
                    <span>{vendorPortalLink}</span>
                  </div>
                )}
              </div>
            </Col>
            <Col md="4" className="d-flex flex-column align-items-end justify-content-start">
              {venderUsesPackaging && (
                <Button onClick={() => toggle(modalTypes.packaging)} size="sm" className="mb-2">
                  Add packaging
                </Button>
              )}
              <div className="mb-2">
                <StripeAccount
                  vendor={vendor}
                  onSubmit={createStripeAccount}
                  onSuccess={onCreateStripeAccountSuccess}
                />
              </div>
              {vendor?.accounting?.status && vendor?.accounting?.status !== 'active' ? (
                <div className="mb-2">
                  <StripeInvite vendor={vendor} onSubmit={resendStripeInvite} onSuccess={onResendStripeInviteSuccess} />
                </div>
              ) : (
                ''
              )}
              <div>
                <Link
                  to={`/admin/vendors/payout/history/${vendor._id}`}
                  target="_blank"
                  className="btn btn-info d-flex btn-sm justify-content-center align-items-center mb-2"
                >
                  Payout History
                </Link>
              </div>
              {shipmentOptions && Object.keys(shipmentOptions)?.length && (
                <Shipments
                  onSubmit={onShipmentsUpdate}
                  onSuccess={onShipmentsSuccess}
                  vendor={vendor}
                  shipmentOptions={shipmentOptions}
                />
              )}
              <div className="d-flex flex-row">
                <Button
                  color="danger"
                  onClick={submitBlacklistVendor}
                  size="sm"
                  className="mb-2"
                  disabled={vendor.blacklist}
                >
                  {vendor.blacklist ? 'Blacklisted' : 'Blacklist'}
                </Button>
                <Button
                  color="success"
                  onClick={submitBlacklistVendor}
                  size="sm"
                  className="mb-2 ml-2"
                  disabled={!vendor.blacklist}
                >
                  {!vendor.blacklist ? 'Whitelisted' : 'Whitelist'}
                </Button>
              </div>
              <div className="d-flex flex-row">
                {vendor.scraper?.type === 'shopify' && vendor.scraper?.accessToken && (
                  <Button
                    color="warning"
                    onClick={handleShopifyWidgetSubmit}
                    size="sm"
                    className="mb-2 ml-2"
                    disabled={togglingShopifyWidget}
                  >
                    {!vendor.shopifyManifest?.widgetId ? 'Enable' : 'Disable'} Shopify Plugin
                  </Button>
                )}
              </div>
              {vendor.accounting && vendor.accounting.status === accountingStatuses.INVITED ? (
                <div className="d-flex flex-column justify-content-end align-items-end">
                  <Button className="btn btn-success btn-sm" onClick={getStripeMagicLink}>
                    Stripe Magic Link
                  </Button>
                  {stripeMagicLink && (
                    <div className="mt-2">
                      <CopyToClipboard className="d-block mt-2" text={stripeMagicLink} />
                      <span>{stripeMagicLink}</span>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              <div className="d-flex flex-row">
                {vendor.scraper?.type === 'shopify' && (
                  <Button
                    color="primary"
                    onClick={() => toggle(modalTypes.shopify_configuration)}
                    size="sm"
                    className="mb-2 ml-2"
                  >
                    Shopify Configuration
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        )}
        <Row className="mt-5">
          <Col xs="12">
            <Form
              innerRef={formRef}
              initialValues={{
                status: 'active',
                expected_times: { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null },
                fulfillment_timelines: {
                  monday: true,
                  tuesday: true,
                  wednesday: true,
                  thursday: true,
                  friday: true,
                  saturday: false,
                  sunday: false,
                },
                usesPackaging: {
                  8: false,
                  10: false,
                  12: false,
                  14: false,
                  16: false,
                  24: false,
                  32: false,
                },
                packagingStock: {
                  8: null,
                  10: null,
                  12: null,
                  14: null,
                  16: null,
                  24: null,
                  32: null,
                },
                vendorProvidesPackaging: 'No',
                showAsFeatured: false,
                accounting: {
                  payment_type: paymentTypes.amount_paid,
                },
                subproductBoost: 1,
                ...vendor,
              }}
              validationSchema={Schema}
              onSubmit={handleSubmit}
              onSuccess={handleSuccess}
            >
              {({ isSubmitting, values }) => (
                <>
                  <Field name="name" label="Name" />
                  <Field
                    name="status"
                    label="Status"
                    type="select"
                    options={{ active: 'Active', onboarding: 'Onboarding', inactive: 'inactive' }}
                  />
                  <Field
                    name="location"
                    label="Location"
                    type="select"
                    options={{ west: 'West', east: 'East', central: 'Central' }}
                  />
                  <Field
                    name="fulfillment_partner"
                    label="fulfillment_partner"
                    type="select"
                    options={yesOrNoOptions}
                  />
                  {values.fulfillment_partner && <Field name="parcel" label="parcel" type="select" options={parcels} />}
                  <Field name="website" label="Website" />
                  <Field name="vendor_affiliate_payout" label="Vendor affiliate payout (cents)" type="number" />
                  <Field name="img_url" label="Image url" />
                  <Field name="product_image_url" label="Default Product Image" />
                  <Field name="ordering_email" label="Ordering Email" />
                  <Field name="verifiedAddress.zip" label="Zip" />
                  <Field name="verifiedAddress.state" label="State" type="select" options={states} />
                  <Field name="verifiedAddress.city" label="City" />
                  <Field name="verifiedAddress.street1" label="Address" />
                  <Field type="phone" name="phone" label="Phone Number" />
                  <Field type="textarea" name="pickup_note" label="Pickup note" />
                  <Field name="landing_page" label="Landing Page" />
                  <Field name="description" type="textarea" label="Description" />
                  <Field name="main_category" type="select" label="Category" options={categoriesOptions} />
                  <Row>
                    <Col className="d-flex align-items-center">
                      <Checkbox name="showAsFeatured" label="Display logo on main page" />
                    </Col>
                    <Col>
                      {values && values.showAsFeatured && <Field name="priority" label="Priority" type="number" />}
                    </Col>
                  </Row>

                  {vendor && vendor.fulfillment_timelines && (
                    <>
                      <span className="d-block mb-2">Fulfillment Timelines</span>
                      <div className="">
                        <Table size="sm">
                          <thead>
                            <tr>
                              <th>Weekday</th>
                              <th>Vendor takes orders</th>
                              <th scope="col" className="text-center">
                                Record Number
                              </th>
                              <th scope="col" className="text-center">
                                Fulfillment speed (P10 / M / P90)
                              </th>
                              <th scope="col" className="text-center">
                                Expected speed <i className="fa fa-edit" aria-hidden="true" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map(
                              (day, i) => (
                                <tr key={i}>
                                  <td className="text-capitalize align-middle">{day}</td>
                                  <td className="text-center">
                                    <Checkbox
                                      label=""
                                      size="sm"
                                      name={`fulfillment_timelines[${day}]`}
                                      type="checkbox"
                                      value={vendor.fulfillment_timelines[day]}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    {get(vendor.fulfillment_times, `${i}.num`, '---')}
                                  </td>
                                  <td className="align-middle text-center">
                                    <FulfilmentSpeedComponent vendor={vendor} i={i} />
                                  </td>
                                  <td className="align-middle">
                                    <Field
                                      size="sm"
                                      name={`expected_times[${i}]`}
                                      type="number"
                                      placeholder="---"
                                      label=""
                                      value={get(vendor, `expected_times.${i}`)}
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-5 provides-packaging">
                        <h6>Provides Packaging:</h6>
                        {['8', '10', '12', '14', '16', '24', '32'].map((size, i) => (
                          <tr key={i}>
                            <td className="text-capitalize align-middle">{size}oz</td>
                            <td className="text-center">
                              <Checkbox
                                label=""
                                size="sm"
                                name={`usesPackaging[${size}]`}
                                type="checkbox"
                                value={(vendor.usesPackaging || {})[size]}
                              />
                            </td>
                          </tr>
                        ))}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h6>Packaging amount:</h6>
                        {['8', '10', '12', '14', '16', '24', '32'].map((size, i) => (
                          <tr key={i}>
                            {(vendor.usesPackaging || {})[size] ? (
                              <>
                                <td className="text-capitalize align-middle">{size}oz</td>
                                <td className="text-center">
                                  <Field size="sm" name={`packagingStock[${size}]`} type="number" label="" />
                                </td>
                              </>
                            ) : (
                              <></>
                            )}
                          </tr>
                        ))}
                      </div>
                    </>
                  )}
                  <div>
                    <h6>Subproduct Settings:</h6>
                    <Field name="subproductBoost" type="number" label="Subproduct Boost" required />
                  </div>
                  <div>
                    <h6>Payout settings:</h6>
                    <Row>
                      <Col xs="12" md="3">
                        <Field
                          name="accounting.payment_type"
                          options={paymentTypes}
                          type="select"
                          label="Payment Type"
                        />
                      </Col>
                      <Col xs="12" md="3">
                        <Field name="accounting.commission" label="Commission" type="number" required />
                      </Col>
                      <Col xs="12" md="6">
                        <Field name="accounting.email" label="E-mail" type="email" />
                      </Col>
                    </Row>
                    <Field name="accounting.notes" label="Notes" type="textarea" />
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <SubmitButton color="dark" isSubmitting={isSubmitting}>
                      Save
                    </SubmitButton>
                  </div>
                </>
              )}
            </Form>
          </Col>
        </Row>

        {modalType === modalTypes.packaging && (
          <>
            <AddPackaging
              toggle={toggle}
              isOpen={isOpen}
              vendor={vendor}
              addPackaging={addPackaging}
              onPackagingSuccess={onPackagingSuccess}
            />
          </>
        )}
        {modalType === modalTypes.shopify_configuration && (
          <>
            <ShopifyConfigurationModal
              toggle={toggle}
              isOpen={isOpen}
              vendor={vendor}
              updateVendor={updateVendor}
              addToast={addToast}
              setVendor={setVendor}
            />
          </>
        )}
      </PanelPage>
    </>
  );
};

VendorPageComponent.propTypes = {
  getVendor: PropTypes.func.isRequired,
  updateVendor: PropTypes.func.isRequired,
  createVendor: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  addVendorPackaging: PropTypes.func.isRequired,
  createMagicLink: PropTypes.func.isRequired,
  getProductsOptions: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  createStripeAccount: PropTypes.func.isRequired,
  getShipmentOptions: PropTypes.func.isRequired,
  updateShipmentOptions: PropTypes.func.isRequired,
  resendStripeInvite: PropTypes.func.isRequired,
  blacklistVendor: PropTypes.func.isRequired,
  createStripeMagicLink: PropTypes.func.isRequired,
  toggleShopifyWidget: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
};

export const VendorPage = connect(null, dispatch => ({
  getVendor: id => dispatch(getVendorAction(id)),
  updateVendor: (id, data) => dispatch(updateVendorAction(id, data)),
  createVendor: data => dispatch(createVendorAction(data)),
  addVendorPackaging: (id, data) => dispatch(addVendorPackagingAction(id, data)),
  addToast: (id, data) => dispatch(addToastAction(id, data)),
  createMagicLink: id => dispatch(createMagicLinkAction(id)),
  getProductsOptions: () => dispatch(getProductOptionsAction()),
  createStripeAccount: id => dispatch(createStripeAccountAction(id)),
  resendStripeInvite: id => dispatch(resendStripeInviteAction(id)),
  getShipmentOptions: id => dispatch(getShipmentOptionsAction(id)),
  updateShipmentOptions: (id, data) => dispatch(updateShipmentOptionsAction(id, data)),
  blacklistVendor: (id, data) => dispatch(blacklistVendorAction(id, data)),
  createStripeMagicLink: id => dispatch(createStripeMagicLinkAction(id)),
  toggleShopifyWidget: (id, action) => dispatch(toggleShopifyWidgetAction(id, action)),
  getCategories: () => dispatch(getCategoriesAction()),
}))(VendorPageComponent);
