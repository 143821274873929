import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const ParseGroceryProducts = createAction('Admin Grocery Products', 'Parse');

export const parseGroceryProductsAction = data => ({
  [RSAA]: {
    endpoint: `admin/grocery-products/parse`,
    method: 'POST',
    body: data,
    types: [ParseGroceryProducts.REQUEST, ParseGroceryProducts.SUCCESS, ParseGroceryProducts.FAILURE],
  },
});

export const UpsertGroceryProducts = createAction('Admin Grocery Products', 'Upsert');

export const upsertGroceryProductsAction = data => ({
  [RSAA]: {
    endpoint: `admin/grocery-products/upsert`,
    method: 'POST',
    body: data,
    types: [ParseGroceryProducts.REQUEST, ParseGroceryProducts.SUCCESS, ParseGroceryProducts.FAILURE],
  },
});
