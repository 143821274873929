import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { createSelectOptions } from '@bottomless/common/utils';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import {
  getProductsPricingAction,
  getProductStatsAction,
  updateProductPricingAction,
} from '../../../../store/admin/product';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import { getVendorsAction } from '../../../../store/admin/vendor';
import { Filters } from './componenets/Filters';
import { Product } from './componenets/Product';

const ProductsPageComponent = ({
  getVendors,
  getProducts,
  updateProduct,
  addToast,
  getOptions,
  location,
  getProductStats,
}) => {
  const query = qs.parse(location.search);
  const [products, setProducts] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [options, setOptions] = useState(null);

  useDataEffect(getVendors, setVendors);
  useDataEffect(getOptions, setOptions);
  useConditionalDataEffect(query.vendor_id, getProducts, setProducts, query);

  const onSuccess = product => {
    if (!product) {
      addToast('Product updated');
    }

    const index = products.findIndex(p => p._id === product._id);
    setProducts([...products.slice(0, index), product, ...products.slice(index + 1)]);

    addToast('Product updated');
  };

  return (
    <PanelPage
      title="Pricing"
      heading={
        <div className="w-100">
          <Filters vendors={vendors} />
        </div>
      }
    >
      {!query.vendor_id && <Alert>Select vendor</Alert>}
      {query.vendor_id && <DataLoading count={(products || { length: 0 }).length} isLoading={products === null} />}
      {products && (
        <>
          {products.map(product => (
            <Product
              product={product}
              key={product._id}
              back={`admin/products`}
              onUpdate={updateProduct}
              onUpdateSuccess={onSuccess}
              getStats={getProductStats}
            />
          ))}
        </>
      )}
    </PanelPage>
  );
};

ProductsPageComponent.propTypes = {
  getVendors: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  getProductStats: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const ProductsPricingPage = connect(null, (dispatch, { location: { search } }) => ({
  getVendors: () => dispatch(getVendorsAction()),
  getProducts: () => dispatch(getProductsPricingAction({ status: 'active', hidden: false, ...qs.parse(search) })),
  updateProduct: (id, data) => dispatch(updateProductPricingAction(id, data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getOptions: () => dispatch(getProductOptionsAction()),
  getProductStats: (product, variant) => dispatch(getProductStatsAction(product, variant)),
}))(ProductsPageComponent);
