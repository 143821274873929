import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { getVariantSelectLabel } from '@bottomless/common/utils';

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

const Schema = Yup.object().shape({
  product: Yup.string().required('This field is required'),
  variant: Yup.string().required('This field is required'),
});

export const SelectSubproductForm = ({ products, onSubmit }) => {
  const [selectedProduct, setProduct] = useState(null);

  const selectProduct = useCallback(
    ({ setFieldValue }) => id => {
      const product = products.find(p => p._id === id);
      setFieldValue('variant', product.variants[0]._id);
      setProduct(product);
    },
    [products, setProduct]
  );

  return (
    <>
      <Form
        initialValues={{
          product: '',
          variant: '',
        }}
        validationSchema={Schema}
        onSubmit={data => onSubmit(data)}
      >
        {({ isSubmitting, setFieldValue }) => (
          <>
            <>
              <Field
                name="product"
                type="select"
                label="Add product"
                options={createSelectOptions(
                  products,
                  '--- select product ---',
                  item => `${item.vendor_name} - ${item.name}`
                )}
                required
                onChange={event => selectProduct({ setFieldValue })(event.target.value)}
              />
              {selectedProduct && (
                <Field
                  name="variant"
                  type="select"
                  label="Select variant"
                  options={createSelectOptions(
                    selectedProduct.variants,
                    '--- select variant ---',
                    getVariantSelectLabel
                  )}
                  required
                />
              )}
              <SubmitButton color="dark" isSubmitting={isSubmitting}>
                Select
              </SubmitButton>
            </>
          </>
        )}
      </Form>
    </>
  );
};

SelectSubproductForm.propTypes = {
  products: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
