import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'reactstrap';
import { DateFormat } from '@bottomless/common/components';

// history contains maximum 20 orders
export const History = ({ history, historyTempUser }) => (
  <Table size="sm" className="text-sm" hover>
    <thead>
      <tr>
        <th>Product</th>
        <th>Date</th>
        <th>Like</th>
      </tr>
    </thead>
    <tbody>
      {history.map(historyOrder => (
        <tr key={historyOrder._id}>
          <td>
            <div className="text-secondary">{historyOrder.subproduct_id.product.vendor_name}</div>
            <div>{historyOrder.subproduct_id.product.name}</div>
          </td>
          <td>
            <DateFormat date={historyOrder.date_fulfilled} />
          </td>
          <td>
            {historyOrder.feedback && (
              <div className="text-danger">
                {historyOrder.feedback.like && <i className="fa fa-heart" />}
                {historyOrder.feedback.dislike && <i className="fa fa-ban" />}
              </div>
            )}
          </td>
        </tr>
      ))}
      {history.length < 20 && historyTempUser?._id && (
        <tr>
          <td>
            <div className="text-secondary">{historyTempUser.product.product.vendor_name}</div>
            <div>{historyTempUser.product.product.name}</div>
          </td>
          <td>
            <DateFormat date={historyTempUser.created_at} />
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

History.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      subproduct_id: PropTypes.shape({
        product: PropTypes.shape({
          name: PropTypes.string.isRequired,
          vendor_name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      date_fulfilled: PropTypes.string.isRequired,
      feedback: PropTypes.shape({
        like: PropTypes.bool.isRequired,
        dislike: PropTypes.bool.isRequired,
      }),
    })
  ).isRequired,
  historyTempUser: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    created_at: PropTypes.string.isRequired,
  }),
};
