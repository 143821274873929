import { useMemo } from 'react';
import { createSelectOptions } from './use-select-options.hook';

export const useSelectCategoryOptions = (data, label) => {
  return useMemo(
    () =>
      !data
        ? []
        : [
            ...(label ? [{ name: null, items: { null: label } }] : []),
            ...data.map(({ name, items }) => ({ name, items: createSelectOptions(items) })),
          ],
    [data, label]
  );
};
