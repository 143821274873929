import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

export const Confirmation = ({ text, openEmitter }) => {
  const [isOpen, setOpen] = useState(false);
  const [event, setEvent] = useState(null);

  openEmitter.addEventListener('open', e => {
    setEvent(e);
    setOpen(true);
  });

  const toggle = () => {
    event.promise.reject({});
    setOpen(!isOpen);
  };
  const onClick = () => {
    setOpen(false);
    event.promise.resolve();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm">
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody>
        <div className="mb-4">{text}</div>
        <div className="text-center">
          <Button color="success" outline onClick={onClick}>
            Confirm
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

Confirmation.propTypes = {
  text: PropTypes.string.isRequired,
  openEmitter: PropTypes.object.isRequired,
};
