import classNames from 'classnames';
import { get, has } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import * as qs from 'query-string';
import { ChevronDown, ChevronUp } from 'react-feather';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, Col, Collapse, Row, Button, ButtonGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Address, DateFormat, DateDiffFormat, Tracking as TrackingNumber } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { StaticTracking } from '../../../components/UspsTracking/StaticTracking';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import {
  getShipmentsTrackingAction,
  acknowledgeScaleShipmentAction,
  acknowledgeAllScaleShipmentAction,
  setShipmentToArrivedAction,
  replaceShipmentAction,
} from '../../../store/admin/scale-shipments';
import { tabs } from './Tabs';
import { Filters } from './components/Filters';
import { EmailEditor } from '../../../components/EmailEditor/EmailEditor';

const MODAL_EMAIL = 'modal_email';

const Shipment = ({ shipment, onAck, changeStatusToDelivered, replaceShipment }) => {
  const [isCollapsed, collapse] = useToggle(true);
  const [hidden, setHidden] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);

  const latestTracking = useMemo(
    () => (shipment.tracking_updates || []).sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).shift(),
    [shipment]
  );

  const toggle = type => {
    setType(type);
    setOpen(!isOpen);
  };

  return (
    <div className={classNames('text-sm p-2 border my-1', { 'd-none': hidden, 'border-bottom': !hidden })}>
      <Row className="mb-1">
        <Col className="text-secondary">
          {shipment._id} / {shipment.type}
          {has(shipment, 'vendor.name') && (
            <>
              {' / '}
              <Badge color="warning">Fulfilled By: {get(shipment, 'vendor.name')}</Badge>
            </>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col>
          <div>
            <Link to={`/admin/users?search=${shipment.user._id}`} className="text-primary">
              <span>
                {shipment.user.first_name} {shipment.user.last_name}
              </span>
            </Link>
            <Address address={shipment.user.verifiedAddress} />
          </div>
        </Col>
        <Col>
          <div>
            <span className="text-success">{shipment.user.status}</span>
          </div>
          {shipment.tracking_number && (
            <div>
              <p className="mb-0">
                <span>Est. </span>
                {latestTracking && <DateDiffFormat date={latestTracking.est_delivery_date} withColor fromZeroed />}
              </p>
            </div>
          )}
          <div>
            Shipped: <DateDiffFormat date={shipment.date_shipped} withColor />
          </div>
        </Col>
        <Col>
          <div>
            <TrackingNumber number={shipment.tracking_number} label={false} shippingService={'USPS'} />
          </div>
          <span className="text-primary">{shipment.status}</span>

          {shipment.usps_tracking && (
            <div className="usps-status cursor-pointer" onClick={collapse}>
              New USPS status:{' '}
              <Badge
                color={classNames({
                  danger: ['alert', 'return_to_sender'].includes(shipment.usps_tracking.status),
                  success: shipment.usps_tracking.status === 'delivered',
                  primary: ['accepted', 'in_transit'].includes(shipment.usps_tracking.status),
                  warning: shipment.usps_tracking.status === 'pre_transit',
                  secondary: shipment.usps_tracking.status === 'unknown',
                })}
              >
                {get(shipment, 'usps_tracking.status', '---')}
              </Badge>
              <span className="ml-1">{!isCollapsed ? <ChevronUp size="11" /> : <ChevronDown size="11" />}</span>
            </div>
          )}
        </Col>

        <Col className="ml-auto text-right" sm="2">
          <ButtonGroup vertical>
            <Button
              onClick={() => {
                onAck(shipment);
                setHidden(true);
              }}
              color="success"
              size="sm"
              className="mb-1"
            >
              Acknowledge
            </Button>
            <Button
              onClick={() => toggle(MODAL_EMAIL)}
              color="info"
              size="sm"
              className="mb-1 d-flex align-items-center justify-content-center"
            >
              Notification
            </Button>
            <Button
              onClick={() => {
                changeStatusToDelivered(shipment);
                setHidden(true);
              }}
              className="mb-1 d-flex align-items-center justify-content-center"
              color="danger"
              size="sm"
            >
              Change Status to Arrived
            </Button>
            <Button
              onClick={() => {
                replaceShipment(shipment._id);
                setHidden(true);
              }}
              color="danger"
              size="sm"
            >
              Replace
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse isOpen={!isCollapsed}>
            {shipment.usps_tracking && (
              <>
                <div>New tracking</div>
                <StaticTracking usps_tracking={shipment.usps_tracking} />
              </>
            )}
          </Collapse>
        </Col>
      </Row>
      {isOpen && (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
          {modalType === MODAL_EMAIL && (
            <>
              <ModalHeader toggle={toggle}>Send notification</ModalHeader>
              <ModalBody>
                <EmailEditor
                  isNotification={true}
                  context={{ shipmentId: shipment._id, userId: shipment.user_id }}
                  onSuccess={() => {
                    onAck(shipment);
                    setHidden(true);
                    toggle();
                  }}
                />
              </ModalBody>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};
Shipment.propTypes = {
  onAck: PropTypes.func.isRequired,
  shipment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    tracking_number: PropTypes.string.isRequired,
    tracking_updates: PropTypes.array,
    type: PropTypes.string.isRequired,
    usps_tracking: PropTypes.object,
    date_shipped: PropTypes.string,
    user_id: PropTypes.string,
    user: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      signupDate: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      verifiedAddress: PropTypes.object.isRequired,
    }),
  }).isRequired,
  changeStatusToDelivered: PropTypes.func.isRequired,
  replaceShipment: PropTypes.func.isRequired,
};
const Batch = ({ batch, onAck, changeStatusToDelivered, replaceShipment }) => {
  const [isOpen, toggle] = useToggle(true);

  return (
    <Card body>
      <div className="d-flex justify-content-between pb-2 cursor-pointer" onClick={toggle}>
        <div>
          <span className="d-block text-large">{batch._id}</span>
          <DateFormat date={batch.date} withTime />
        </div>
        <div>
          {batch.shipments.length} / {batch.total}
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        {batch.shipments.map((shipment, i) => (
          <Shipment
            key={i}
            shipment={shipment}
            onAck={onAck}
            changeStatusToDelivered={changeStatusToDelivered}
            replaceShipment={replaceShipment}
          />
        ))}
      </Collapse>
    </Card>
  );
};

Batch.propTypes = {
  batch: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    inTransit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    shipments: PropTypes.array.isRequired,
  }).isRequired,
  onAck: PropTypes.func.isRequired,
  changeStatusToDelivered: PropTypes.func.isRequired,
  replaceShipment: PropTypes.func.isRequired,
};

const ScaleShipmentTrackingComponent = ({
  getShipmentsTracking,
  acknowledgeScaleShipment,
  setShipmentToArrived,
  replaceShipment,
  acknowledgeAll,
  isLoading,
  location,
  batches,
  addToast,
}) => {
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    getShipmentsTracking();
  }, [query]);

  const onAck = async shipment => {
    await acknowledgeScaleShipment(shipment._id);
    addToast('Scale Shipment acknowledged', 'success');
  };

  const batchAck = async () => {
    await acknowledgeAll();
    getShipmentsTracking();
    addToast('Scale Shipment acknowledged', 'success');
  };

  const changeStatusToDelivered = async shipment => {
    await setShipmentToArrived(shipment._id);
    await acknowledgeScaleShipment(shipment._id);
    addToast('Scale Shipment status set to arrived', 'success');
  };

  const replaceScaleShipment = async id => {
    await replaceShipment(id);
    addToast('Scale Shipment replaced', 'success');
  };

  useEffect(() => {
    if (batches) {
      setHasData(batches.some(batch => batch.shipments.length > 0));
    }
  }, [batches]);

  return (
    <>
      <PanelPage className="page-scale-shipments" title="Scale shipments" tabs={tabs}>
        <div className="mb-4">
          <Filters isLoading={isLoading} hasData={batches && batches.length > 0} acknowledgeAll={batchAck} />
        </div>

        {!isLoading && batches && (
          <>
            {hasData ? (
              <>
                {batches.map(
                  batch =>
                    batch.shipments.length > 0 && (
                      <div key={batch._id} className="mb-2">
                        <Batch
                          batch={batch}
                          onAck={onAck}
                          changeStatusToDelivered={changeStatusToDelivered}
                          replaceShipment={replaceScaleShipment}
                        />
                      </div>
                    )
                )}
              </>
            ) : (
              <>No Data</>
            )}
          </>
        )}
      </PanelPage>
    </>
  );
};

ScaleShipmentTrackingComponent.propTypes = {
  getShipmentsTracking: PropTypes.func.isRequired,
  setShipmentToArrived: PropTypes.func.isRequired,
  replaceShipment: PropTypes.func.isRequired,
  batches: PropTypes.array,
  isLoading: PropTypes.bool,
  addToast: PropTypes.func.isRequired,
  acknowledgeScaleShipment: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  acknowledgeAll: PropTypes.func,
};

export const ScaleShipmentTrackingPage = connect(
  ({ adminScaleShipments }) => ({
    batches: adminScaleShipments.batches,
    isLoading: adminScaleShipments.isLoading,
  }),
  (dispatch, { location: { search }, match: { params } }) => ({
    addToast: message => dispatch(addToastAction(message)),
    getShipmentsTracking: () => dispatch(getShipmentsTrackingAction({ ...params, ...qs.parse(search) })),
    acknowledgeScaleShipment: id => dispatch(acknowledgeScaleShipmentAction(id)),
    setShipmentToArrived: id => dispatch(setShipmentToArrivedAction(id)),
    replaceShipment: id => dispatch(replaceShipmentAction(id)),
    acknowledgeAll: () => dispatch(acknowledgeAllScaleShipmentAction()),
  })
)(ScaleShipmentTrackingComponent);
