import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IsAdminAuth } from '../guards/admin.guard';
import { Admin } from '../layouts/Admin/Admin';
import { AttributesPage } from '../pages/Admin/Attributes/Attributes';
import { DisconnectedUserPage } from '../pages/Admin/Disconnected/Details/DisconnectUser';
import { DisconnectedPage } from '../pages/Admin/Disconnected/List/Disconnected';
import { PendingDisconnectedPage } from '../pages/Admin/Disconnected/List/PendingDisconnected';
import { ActiveEmailsPage } from '../pages/Admin/Emails/ActiveEmails';
import { ArchivedEmailsPage } from '../pages/Admin/Emails/ArchivedEmails';
import { EditEmailPage } from '../pages/Admin/Emails/EditEmail';
import { PaidFulfillmentPage } from '../pages/Admin/Fulfillment/PaidFulfillment';
import { SentToRoastersFulfillmentPage } from '../pages/Admin/Fulfillment/SentToRoastersFulfillment';
import { SlowLocalFulfillmentPage } from '../pages/Admin/Fulfillment/SlowLocalFulfillment';
import { SubproductFulfillmentPage } from '../pages/Admin/Fulfillment/SubproductFulfillment';
import { SummaryPage } from '../pages/Admin/Fulfillment/Summary';
import { UnprocessedFulfillmentPage } from '../pages/Admin/Fulfillment/UnprocessedFulfillment';
import { OrderGenDetailsPage } from '../pages/Admin/OrderGen/Details/OrderGenDetails';
import { DisconnectedOrderGenPage } from '../pages/Admin/OrderGen/List/DisconnectedOrderGen';
import { DoneOrderGenPage } from '../pages/Admin/OrderGen/List/DoneOrderGen';
import { NewUsersOrderGen } from '../pages/Admin/OrderGen/List/NewUsersOrderGen';
import { NoDataOrderGenPage } from '../pages/Admin/OrderGen/List/NoDataOrderGen';
import { BagNotOnScalePage } from '../pages/Admin/OrderGen/List/BagNotOnScaleGen';
import { AiDecisionOrderGenPage } from '../pages/Admin/OrderGen/List/AiDecisionsOrderGen';
import { OnTheWayOrderGenPage } from '../pages/Admin/OrderGen/List/OnTheWayOrderGen';
import { OptOutOrderGenPage } from '../pages/Admin/OrderGen/List/OptOutOrderGen';
import { RoasterDoesNotFulfill } from '../pages/Admin/OrderGen/List/RoasterDoesNotFullfill';
import { SlowLocalOrderGenPage } from '../pages/Admin/OrderGen/List/SlowLocalOrderGen';
import { StreamingOrderGen } from '../pages/Admin/OrderGen/List/StreamingOrderGen';
import { NeedSupportOrderGenPage } from '../pages/Admin/OrderGen/List/NeedSupportOrderGen';
import { IdleUsersOrderGenPage } from '../pages/Admin/OrderGen/List/IdleUsersOrderGen';
import { IdleReviewUsersOrderGenPage } from '../pages/Admin/OrderGen/List/IdleReviewUsersOrderGen';
import { TodoOrderGen } from '../pages/Admin/OrderGen/List/TodoOrderGen';
import { Ag1OrderGen } from '../pages/Admin/OrderGen/List/Ag1OrderGen';
import { OrderTrackingPage } from '../pages/Admin/OrderTracking-v2/OrderTracking';
import { OrderTrackingAllPage } from '../pages/Admin/OrderTracking-v2/OrderTrackingAll';
import { DislikedProductsPage } from '../pages/Admin/ProductFeedback/DislikedProductFeedback';
import { LikedProductsPage } from '../pages/Admin/ProductFeedback/LikedProductFeedback';
import { LikedVendorsPage } from '../pages/Admin/ProductFeedback/LikedVendorsFeedback';
import { CreateProductPage } from '../pages/Admin/Products/Create/CreateProduct';
import { EditProductPage } from '../pages/Admin/Products/Edit/EditProduct';
import { ProductsPage } from '../pages/Admin/Products/List/Products';
import { ProductsPricingPage } from '../pages/Admin/Products/Pricing/ProductsPricing';
import { ProposedProductPage } from '../pages/Admin/ProposedProducts/Details/ProposedProduct';
import { PendingProposedProductsPage } from '../pages/Admin/ProposedProducts/List/PendingProposedProducts';
import { ProposedProductsPage } from '../pages/Admin/ProposedProducts/List/ProposedProducts';
import { IgnoredProposedProductsPage } from '../pages/Admin/ProposedProducts/List/IgnoredProposedProducts';
import { RetentionPage } from '../pages/Admin/Retention/Retention';
import { EditRotationPage } from '../pages/Admin/Rotations/Edit/EditRotation';
import { RotationsPage } from '../pages/Admin/Rotations/List/Rotations';
import { ScaleShipmentsPage } from '../pages/Admin/ScaleShipments/ScaleShipments';
import { ScaleShipmentTrackingPage } from '../pages/Admin/ScaleShipments/Tracking';
import { ScaleShipmentsPagev2 } from '../pages/Admin/ScaleShipmentsv2/ScaleShipments';
import { SubproductDetailsPage } from '../pages/Admin/SubproductSelection/Details/SubproductSelectionDetails';
import { PaidSubproductSelectionPage } from '../pages/Admin/SubproductSelection/List/PaidSubproductSelection';
import { TodoSubproductSelectionPage } from '../pages/Admin/SubproductSelection/List/TodoSubproductSelection';
import { UsersPage } from '../pages/Admin/Users';
import { PackagingsPage } from '../pages/Admin/Vendors/Packaging/Packagings';
import { VendorPage } from '../pages/Admin/Vendors/Vendor';
import { VendorsPage } from '../pages/Admin/Vendors/Vendors';
import { VendorDownDaysPage } from '../pages/Admin/Vendors/DownDays/VendorDownDays';
import { PayoutPage } from '../pages/Admin/Vendors/Payout/Payout';
import { PayoutHistoryPage } from '../pages/Admin/Vendors/PayoutHistory';
import { InfluencersPage } from '../pages/Admin/Users/Influencers/Influencers';
import { RotationPricingPage } from '../pages/Admin/RotationsPricing/RotationPricing';
import { GroceryProductPage } from '../pages/Admin/GroceryProducts/GroceryProducts';
import { GifsPage } from '../pages/Admin/Gifs/Gifs';
import { OrderTrackingManifestPage } from '../pages/Admin/OrderTrackingManifest/OrderTrackingManifest';
import { VendorLeadPage } from '../pages/Admin/Vendors/Leads/VendorLead';
import { LeadForm } from '../pages/Admin/Vendors/Leads/components/LeadForm';
import { CreditsPage } from '../pages/Admin/Users/Credits/Credit';
import { CreditForm } from '../pages/Admin/Users/Credits/components/CreditForm';
import { VendorPackagingSummary } from '../pages/Admin/Vendors/Packaging/Summary';
import { OrderProblems } from '../pages/Admin/Problems/OrderProblems';
import { ScaleProblems } from '../pages/Admin/Problems/ScaleProblems';
import { GiftsPage } from '../pages/Admin/GiftPurchases/GiftPurchases';
import { GiftPurchaseDetailsPage } from '../pages/Admin/GiftPurchases/GiftPurchasesDetails';
import { PetFoodOrderGenPage } from '../pages/Admin/OrderGen/List/PetFoodOrderGen';
import { ByoOrderGenPage } from '../pages/Admin/OrderGen/List/ByoOrderGen';
import { ECommerceProductsPage } from '../pages/Admin/ECommerce/Products/Products';
import { ECommercePaidOrderPage } from '../pages/Admin/ECommerce/Paid/PaidOrder';
import { ECommerceFulfilledOrderPage } from '../pages/Admin/ECommerce/Fulfilled/FulfilledOrder';
import { ConnectedFixedPage } from '../pages/Admin/Disconnected/List/ConnectedFixed';

const AdminRoutes = props => {
  return (
    <IsAdminAuth {...props}>
      <Admin>
        <Switch>
          <Route path="/admin/users" exact component={UsersPage} />
          <Route path="/admin/users/influencers" exact component={InfluencersPage} />
          <Route path="/admin/users/credits" exact component={CreditsPage} />
          <Route path="/admin/users/credits/:id" exact component={CreditForm} />
          <Route path="/admin/users/:id" exact component={OrderGenDetailsPage} />

          <Route path="/admin/emails" exact component={ActiveEmailsPage} />
          <Route path="/admin/emails/archived" exact component={ArchivedEmailsPage} />
          <Route path="/admin/emails/create" exact component={EditEmailPage} />
          <Route path="/admin/emails/:id?" exact component={EditEmailPage} />

          <Route path="/admin/scale-shipments/tracking" exact component={ScaleShipmentTrackingPage} />
          <Route path="/admin/scale-shipments/:type" exact component={ScaleShipmentsPage} />

          <Route path="/admin/scale-shipmentsv2" exact component={ScaleShipmentsPagev2} />

          <Route path="/admin/products/" exact component={ProductsPage} />
          <Route path="/admin/products/create" exact component={CreateProductPage} />
          <Route path="/admin/products/:id" exact component={EditProductPage} />
          <Route path="/admin/attributes/:type" exact component={AttributesPage} />
          <Route path="/admin/products-pricing" exact component={ProductsPricingPage} />

          <Route path="/admin/vendors/" exact component={VendorsPage} />
          <Route path="/admin/vendors/down-days/:status" exact component={VendorDownDaysPage} />
          <Route path="/admin/vendors/leads/lead/edit/:id" exact component={LeadForm} />
          <Route path="/admin/vendors/leads/lead/create" exact component={LeadForm} />
          <Route path="/admin/vendors/leads/:status" exact component={VendorLeadPage} />
          <Route path="/admin/vendors/packagings" exact component={PackagingsPage} />
          <Route path="/admin/vendors/packaging-summary" exact component={VendorPackagingSummary} />
          <Route path="/admin/vendors/payout/history/:id" exact component={PayoutHistoryPage} />
          <Route path="/admin/vendors/payout" exact component={PayoutPage} />
          <Route path="/admin/vendors/create" exact component={VendorPage} />
          <Route path="/admin/vendors/:id" exact component={VendorPage} />

          <Route path="/admin/proposed_products/pending" exact component={PendingProposedProductsPage} />
          <Route path="/admin/proposed_products/all" exact component={ProposedProductsPage} />
          <Route path="/admin/proposed_products/ignore" exact component={IgnoredProposedProductsPage} />
          <Route path="/admin/proposed_products/:id" exact component={ProposedProductPage} />
          <Redirect from="/admin/proposed_products" to="/admin/proposed_products/pending" />

          <Route path="/admin/rotations" exact component={RotationsPage} />
          <Route path="/admin/rotations/:id" exact component={EditRotationPage} />

          <Route path="/admin/rotation_pricing" exact component={RotationPricingPage} />
          <Route path="/admin/grocery_products" exact component={GroceryProductPage} />

          <Route path="/admin/ordering_cockpit/todo" exact component={TodoOrderGen} />
          <Route path="/admin/ordering_cockpit/new-users" exact component={NewUsersOrderGen} />
          <Route path="/admin/ordering_cockpit/done" exact component={DoneOrderGenPage} />
          <Route path="/admin/ordering_cockpit/on-the-way" exact component={OnTheWayOrderGenPage} />
          <Route path="/admin/ordering_cockpit/roaster-does-not-fulfill" exact component={RoasterDoesNotFulfill} />
          <Route path="/admin/ordering_cockpit/streaming" exact component={StreamingOrderGen} />
          <Route path="/admin/ordering_cockpit/no-data" exact component={NoDataOrderGenPage} />
          <Route path="/admin/ordering_cockpit/bag-not-on-scale" exact component={BagNotOnScalePage} />
          <Route path="/admin/ordering_cockpit/ai-decision" exact component={AiDecisionOrderGenPage} />
          <Route path="/admin/ordering_cockpit/need-support" exact component={NeedSupportOrderGenPage} />
          <Route path="/admin/ordering_cockpit/idle" exact component={IdleUsersOrderGenPage} />
          <Route path="/admin/ordering_cockpit/idle_review" exact component={IdleReviewUsersOrderGenPage} />
          <Route path="/admin/ordering_cockpit/disconnected" exact component={DisconnectedOrderGenPage} />
          <Route path="/admin/ordering_cockpit/opt_out" exact component={OptOutOrderGenPage} />
          <Route path="/admin/ordering_cockpit/slow-local" exact component={SlowLocalOrderGenPage} />
          <Route path="/admin/ordering_cockpit/pet-food" exact component={PetFoodOrderGenPage} />
          <Route path="/admin/ordering_cockpit/byo" exact component={ByoOrderGenPage} />
          <Route path="/admin/ordering_cockpit/ag1" exact component={Ag1OrderGen} />
          <Route path="/admin/ordering_cockpit/:id" exact component={OrderGenDetailsPage} />
          <Redirect from="/admin/ordering_cockpit" to="/admin/ordering_cockpit/todo" />

          <Route path="/admin/products_feedback/liked" exact component={LikedProductsPage} />
          <Route path="/admin/products_feedback/disliked" exact component={DislikedProductsPage} />
          <Route path="/admin/products_feedback/vendors" exact component={LikedVendorsPage} />
          <Redirect from="/admin/products_feedback" to="/admin/products_feedback/liked" />

          <Route path="/admin/disconnected/pending" exact component={PendingDisconnectedPage} />
          <Route path="/admin/disconnected/all" exact component={DisconnectedPage} />
          <Route path="/admin/disconnected/fixed" exact component={ConnectedFixedPage} />
          <Route path="/admin/disconnected/:id" exact component={DisconnectedUserPage} />
          <Route path="/admin/retention" exact component={RetentionPage} />
          <Redirect from="/admin/disconnected" to="/admin/disconnected/pending" />

          <Route path="/admin/order_tracking/unscanned/:status" exact component={OrderTrackingAllPage} />

          <Route path="/admin/order_tracking/:id" exact component={OrderTrackingPage} />
          <Redirect from="/admin/order_tracking" exact to="/admin/order_tracking/unprocessed" />

          <Route path="/admin/fulfillment/unprocessed" exact component={UnprocessedFulfillmentPage} />
          <Route path="/admin/fulfillment/summary" exact component={SummaryPage} />
          <Route path="/admin/fulfillment/subproduct" exact component={SubproductFulfillmentPage} />
          <Route path="/admin/fulfillment/paid" exact component={PaidFulfillmentPage} />
          <Route path="/admin/fulfillment/sent-to-roaster" exact component={SentToRoastersFulfillmentPage} />
          <Route path="/admin/fulfillment/slow-local" exact component={SlowLocalFulfillmentPage} />
          <Redirect from="/admin/fulfillment" to="/admin/fulfillment/unprocessed" />

          <Route path="/admin/problems/order" exact component={OrderProblems} />
          <Route path="/admin/problems/scale" exact component={ScaleProblems} />
          <Redirect from="/admin/problems" to="/admin/problems/order" />

          <Route path="/admin/subproduct-selection/todo" exact component={TodoSubproductSelectionPage} />
          <Route path="/admin/subproduct-selection/paid" exact component={PaidSubproductSelectionPage} />
          <Route path="/admin/subproduct-selection/:id" exact component={SubproductDetailsPage} />
          <Redirect from="/admin/subproduct-selection" exact to="/admin/subproduct-selection/todo" />

          <Route path="/admin/e-commerce/products" exact component={ECommerceProductsPage} />
          <Route path="/admin/e-commerce/paid" exact component={ECommercePaidOrderPage} />
          <Route path="/admin/e-commerce/fulfilled" exact component={ECommerceFulfilledOrderPage} />
          <Redirect from="/admin/e-commerce" to="/admin/e-commerce/products" />

          <Route path="/admin/gifs" exact component={GifsPage} />
          <Route path="/admin/order-tracking-manifest" exact component={OrderTrackingManifestPage} />

          <Route path="/admin/gifts" exact component={GiftsPage} />
          <Route path="/admin/gifts/:id" exact component={GiftPurchaseDetailsPage} />

          <Redirect from="/admin" to="/admin/users" />
        </Switch>
      </Admin>
    </IsAdminAuth>
  );
};

AdminRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

export default AdminRoutes;
