import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToastAction } from '@bottomless/common/store';
import { useToggle } from '@bottomless/common/hooks';
import { createCustomerNoteAction, deleteCustomerNoteAction, createOrderNoteAction } from '../../store';
import { CreateNote } from './components/Create';
import { Collapse } from 'reactstrap';
import { Note } from './components/Note';
import './CustomerNotes.scss';
import { DataLoading } from '@bottomless/common/components';

const NOTE_TYPE_SCALE = 'scale_problem';
const NOTE_TYPE_ROTATION = 'rotation_changes';

const CustomerNotesComponent = ({
  user,
  order,
  addToast,
  createCustomerNote,
  onCreate,
  deleteCustomerNote,
  createOrderNote,
  onDelete,
  withHeader,
  type,
}) => {
  const [isCollapsed, setIsCollapsed] = useToggle(true);
  const [initialNotes, setInitialNotes] = useState([]);
  const [rest, setRest] = useState(null);

  const handleCreate = note => {
    if (onCreate) {
      onCreate(note);
    }

    const message = user
      ? `Note for user ${user.first_name} ${user.last_name} has been added.`
      : 'Note has been added.';
    addToast(message);
  };

  const handleDelete = async note => {
    const res = await deleteCustomerNote(note._id);

    // On deleting a sticky note, a normal note is created
    if (note.type === 'sticky') {
      onCreate(res.payload);
    }

    if (onDelete) {
      onDelete(note);
    }

    const message = user
      ? `Note for user ${user.first_name} ${user.last_name} has been deleted.`
      : 'Note has been deleted.';
    addToast(message);
  };

  const { notes } = user || order;
  const createNote = user ? createCustomerNote : createOrderNote;

  useEffect(() => {
    if (notes) {
      const allNotes = [...notes];
      const firstThreeNotes = allNotes.splice(0, 3);
      setInitialNotes(firstThreeNotes);

      if (allNotes.length) {
        setRest(allNotes);
      }
    }
  }, [notes]);

  if (!notes) {
    return <DataLoading count={0} isLoading withMarginTop={false} />;
  }

  return (
    <>
      {withHeader && <h5>Notes ({(notes || []).length})</h5>}
      {type !== NOTE_TYPE_SCALE && type !== NOTE_TYPE_ROTATION ? (
        <CreateNote onSubmit={data => createNote({ ...data, type })} onSuccess={handleCreate} />
      ) : (
        ''
      )}
      {initialNotes.map(note => (
        <Note key={note._id} note={note} order={order} onDelete={handleDelete} />
      ))}
      {rest && rest.length && (
        <>
          {!isCollapsed ? (
            <Collapse isOpen={!isCollapsed}>
              {rest.map(note => (
                <Note key={note._id} note={note} order={order} onDelete={handleDelete} />
              ))}
            </Collapse>
          ) : (
            undefined
          )}
          {isCollapsed ? (
            <div className="mb-auto">
              <a color="link" onClick={setIsCollapsed}>
                Show More
              </a>
            </div>
          ) : (
            <div className="mb-auto">
              <a color="link" onClick={setIsCollapsed}>
                Show Less
              </a>
            </div>
          )}
        </>
      )}
    </>
  );
};

CustomerNotesComponent.propTypes = {
  createCustomerNote: PropTypes.func.isRequired,
  deleteCustomerNote: PropTypes.func.isRequired,
  createOrderNote: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  addToast: PropTypes.func.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(PropTypes.object),
  }),
  order: PropTypes.shape({
    notes: PropTypes.arrayOf(PropTypes.object),
  }),
  withHeader: PropTypes.bool,
  type: PropTypes.string,
};

CustomerNotesComponent.defaultProps = {
  withHeader: true,
  type: 'note',
};

export const CustomerNotes = connect(null, (dispatch, { user, order }) => ({
  addToast: message => dispatch(addToastAction(message)),
  createCustomerNote: data => dispatch(createCustomerNoteAction({ user: user._id, ...data })),
  deleteCustomerNote: id => dispatch(deleteCustomerNoteAction(id)),
  createOrderNote: data => dispatch(createOrderNoteAction(order._id, data)),
}))(memo(CustomerNotesComponent));
