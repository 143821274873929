import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect, useConditionalDataEffect, useUnmount } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import {
  getIgnoredProposedProductsAction,
  getProposedProductVendorsAction,
  ignoreProposedProductAction,
  unlinkProposedProductAction,
} from '../../../../store/admin/proposed-product';
import { Product } from './components/Product';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import { Filters } from './components/Filters';
import { connectWithAbort } from '@bottomless/common/store';
import './ProposedProducts.scss';

const tabs = [
  { to: '/admin/proposed_products/pending', name: 'Pending' },
  { to: '/admin/proposed_products/all', name: 'All' },
  { to: '/admin/proposed_products/ignore', name: 'Ignore' },
];

const IgnoredProposedProductsPageComponent = ({
  getProposedProductVendors,
  getIgnoredProposedProducts,
  ignoreProposedProduct,
  getProductOptions,
  location: { search },
  abortRequests,
  unlinkProposedProduct,
  addToast,
}) => {
  const [products, setProducts] = useState(null);
  const [ignoreCounter, setIgnoreCounter] = useState(null);
  const [vendors, setVendors] = useState(null);
  const query = useMemo(() => qs.parse(search), [search]);

  const [productOptions, setProductOptions] = useState(null);

  useDataEffect(getProductOptions, setProductOptions);

  useUnmount(abortRequests);

  useConditionalDataEffect(!vendors, getProposedProductVendors, setVendors);

  useEffect(() => {
    (async () => {
      try {
        const { payload } = await getIgnoredProposedProducts(query);
        setProducts(payload);
      } catch (e) {
        setProducts([]);
      }
    })();
  }, [getIgnoredProposedProducts, query]);

  const onIgnore = async id => {
    await ignoreProposedProduct(id);
    setIgnoreCounter(ignoreCounter + 1);
  };

  const onUnlink = async id => {
    unlinkProposedProduct(id);
    addToast('Unlinked');
    setProducts(products.filter(curProduct => curProduct._id !== id));
    setIgnoreCounter(ignoreCounter + 1);
  };

  return (
    <PanelPage title="Proposed products" tabs={tabs}>
      <div className="mb-4">
        <Filters isLoading={!vendors} hasData={vendors && vendors.length > 0} vendors={vendors} />
      </div>
      <DataLoading count={(products || { length: 0 }).length} isLoading={!products} />
      {products &&
        products.map(product => (
          <Product ignore={onIgnore} product={product} key={product._id} options={productOptions} unlink={onUnlink} />
        ))}
    </PanelPage>
  );
};

IgnoredProposedProductsPageComponent.propTypes = {
  getProposedProductVendors: PropTypes.func.isRequired,
  getIgnoredProposedProducts: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  ignoreProposedProduct: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  abortRequests: PropTypes.func.isRequired,
  unlinkProposedProduct: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const IgnoredProposedProductsPage = connectWithAbort(null, dispatch => ({
  getProposedProductVendors: () => dispatch(getProposedProductVendorsAction()),
  getIgnoredProposedProducts: params => dispatch(getIgnoredProposedProductsAction(params)),
  ignoreProposedProduct: id => dispatch(ignoreProposedProductAction(id)),
  getProductOptions: () => dispatch(getProductOptionsAction()),
  unlinkProposedProduct: id => dispatch(unlinkProposedProductAction(id)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(IgnoredProposedProductsPageComponent);
