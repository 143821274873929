import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardTitle, Collapse, Row, Col, Button } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useToggle } from '@bottomless/common/hooks';
import { Errors } from './Errors';
import { MiniErrors } from './MiniErrors';
import { OrderReport } from './OrderReport';
import { SlowLocalOrder } from './SlowLocalOrder';

export const Order = ({
  order,
  withReport,
  products,
  onReplaceOrder,
  resolveReported,
  addToast,
  onCancel,
  isCancelling,
  isSubproductPage,
  isCancellingOrderId,
}) => {
  const [isOpen, toggle] = useToggle();

  const subproduct = order.subproduct_id && order.subproduct_id.product;
  const variant = subproduct && subproduct.variants.find(v => v._id === order.subproduct_id.variant);
  const user = order.user_id;

  const daysSinceInitiated = moment.duration(moment().diff(moment(order.date_initiated))).asDays();
  const daysSinceInitatedHumanFormat = moment(order.date_initiated).fromNow();

  const orderProcessed = status => ['sent_to_roaster', 'fulfilled'].includes(status);

  return (
    <Card body className={`flex-column mb-3 ${order.is_late ? 'order-late' : ''}`}>
      <CardTitle className="mb-0 cursor-pointer">
        <div className="d-flex flex-row justify-content-between">
          <Row className="flex-grow-1" onClick={toggle}>
            <Col xs="4">
              {order._id && (
                <div className="text-sm text-secondary">
                  <Link
                    to={`/admin/ordering_cockpit/${user._id}?orderId=${order._id}`}
                    target="_blank"
                    className="text-primary text-nowrap"
                  >
                    {order._id}
                  </Link>
                </div>
              )}
              <div>
                <Link to={`/admin/users?search=${user._id}`} target="_blank" className="text-primary">
                  {user.first_name} {user.last_name}{' '}
                </Link>
              </div>
              <div>{order.status}</div>
              {isSubproductPage ? (
                <div>
                  <span className="text-secondary">Days since initiated:</span>{' '}
                  {daysSinceInitiated ? daysSinceInitiated.toFixed(2) : 0} ({daysSinceInitatedHumanFormat})
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col xs="4">
              <span className="text-sm text-secondary d-block">Subproduct</span>
              {subproduct ? (
                <>
                  <Link to={`/admin/subproduct-selection/${order._id}`} target="_blank">
                    <div>{`${subproduct.name} (${variant?.size}oz)`}</div>
                    <div className="text-secondary text-sm">{`${subproduct.vendor_name}`}</div>
                  </Link>
                </>
              ) : (
                '---'
              )}
            </Col>
            <Col xs="4">
              <span className="text-sm text-secondary d-block">{order.source === 'user_one_off' ? 'One Off' : ''}</span>
              <span className="text-sm text-secondary d-block">
                {order.source !== 'user_one_off' && (
                  <>
                    {orderProcessed(order.status)
                      ? order.product_id && order.product_id.rotating
                        ? 'Rotating'
                        : ''
                      : order.user_id.product && order.user_id.product.product && order.user_id.product.product.rotating
                      ? 'Rotating'
                      : ''}
                  </>
                )}
              </span>
            </Col>
          </Row>
          <Col xs="4" onClick={toggle}>
            <MiniErrors order={order} />
          </Col>
          {isSubproductPage ? (
            <Col xs="1">
              <Button
                disabled={isCancelling && isCancellingOrderId === order._id}
                outline
                size="sm"
                type="button"
                onClick={onCancel ? () => onCancel(order._id) : undefined}
                color="warning"
              >
                Cancel
              </Button>
            </Col>
          ) : (
            ''
          )}
          <div className="flex-grow-0 ml-3" onClick={toggle}>
            {isOpen ? <ChevronUp size="15" /> : <ChevronDown size="15" />}
          </div>
        </div>
        {withReport && (
          <>
            <hr />
            <OrderReport
              order={order}
              products={products}
              onReplaceOrder={onReplaceOrder}
              resolveReported={resolveReported}
              addToast={addToast}
              user={user}
            />
          </>
        )}
        {order.slow_local_order && <SlowLocalOrder slowLocalOrder={order.slow_local_order} />}
      </CardTitle>
      <Collapse isOpen={isOpen}>
        <hr />
        <Errors order={order} />
      </Collapse>
    </Card>
  );
};

Order.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    product_id: PropTypes.object,
    status: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    is_late: PropTypes.bool,
    user_id: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      product: PropTypes.object,
    }).isRequired,
    subproduct_id: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            size: PropTypes.number.isRequired,
          })
        ).isRequired,
        vendor_name: PropTypes.string.isRequired,
      }).isRequired,
      variant: PropTypes.string.isRequired,
    }),
    reportProblem: PropTypes.object,
    date_initiated: PropTypes.string.isRequired,
    slow_local_order: PropTypes.object,
  }).isRequired,
  withReport: PropTypes.bool,
  products: PropTypes.array,
  onReplaceOrder: PropTypes.func,
  resolveReported: PropTypes.func,
  addToast: PropTypes.func,
  onCancel: PropTypes.func,
  isCancelling: PropTypes.bool,
  isSubproductPage: PropTypes.bool,
  isCancellingOrderId: PropTypes.string,
};

Order.defaultProps = {
  isCancelling: false,
  isSubproductPage: false,
  isCancellingOrderId: null,
};
