import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { DataLoading, DateFormat, ProductName } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { getOrdersHistoryAction } from '../../store/admin/order/order.actions';

const OrderHistoryComponent = ({ user, getHistory }) => {
  const [orders, setOrders] = useState(null);
  useDataEffect(getHistory, setOrders, user._id);

  return (
    <>
      <DataLoading count={orders ? orders.length : 0} isLoading={orders === null}>
        <div>History is empty</div>
      </DataLoading>
      {orders && orders.length > 0 && (
        <Table size="sm">
          <thead>
            <tr>
              <th>Date fulfilled</th>
              <th>Product</th>
              <th>Personalized</th>
              <th className="text-right">Like</th>
            </tr>
          </thead>
          {orders.map(order => (
            <tr key={order._id}>
              <td>{order.date_fulfilled ? <DateFormat date={order.date_fulfilled} /> : '---'}</td>
              <td>
                {order.subproduct_id.product && (
                  <>
                    {order.subproduct_id.product.name}
                    <span className="mx-1">-</span>
                    <span className="text-secondary">{order.subproduct_id.product.vendor_name}</span>
                  </>
                )}
                {!order.subproduct_id.product && '---'}
              </td>
              <td>
                {order.personalized && (
                  <ProductName
                    personalized={order.personalized}
                    grind={order.personalized?.size ? order.grind?.name : undefined}
                  />
                )}
              </td>
              <td className="text-right">
                {order.product_feedback && (
                  <>
                    {order.product_feedback.like && !order.product_feedback.dislike && (
                      <i className="text-danger fa fa-heart" aria-hidden="true" />
                    )}
                    {order.product_feedback.dislike && !order.product_feedback.like && (
                      <i className="text-danger fa fa-ban" aria-hidden="true" />
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </Table>
      )}
    </>
  );
};

OrderHistoryComponent.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  getHistory: PropTypes.func.isRequired,
};

export const OrderHistory = connect(null, dispatch => ({
  getHistory: userId => dispatch(getOrdersHistoryAction(userId)),
}))(OrderHistoryComponent);
