import React from 'react';
import PropTypes from 'prop-types';
import { connectWithAbort } from '@bottomless/common/store';
import { createOrderGenAction, getDisconnectedOrderGenAction } from '../../../../store/admin/ordergen';
import { PaginatedOrderGenPage } from './PaginatedOrderGen';

const DisconnectedOrderGenPageComponent = ({ getDisconnected, createOrderGen }) => (
  <PaginatedOrderGenPage
    title="Disconnected"
    getUsers={getDisconnected}
    onGenerateInstance={createOrderGen}
    withTabs={false}
    hideOnGeneration={false}
    withDisconnected
  />
);

DisconnectedOrderGenPageComponent.propTypes = {
  getDisconnected: PropTypes.func.isRequired,
  createOrderGen: PropTypes.func.isRequired,
};

export const DisconnectedOrderGenPage = connectWithAbort(null, dispatch => ({
  getDisconnected: params => dispatch(getDisconnectedOrderGenAction(params)),
  createOrderGen: data => dispatch(createOrderGenAction(data)),
}))(DisconnectedOrderGenPageComponent);
