import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  eCommerce: Yup.object().shape({
    name: Yup.string,
    productUrl: Yup.string,
    type: Yup.string,
  }),
  variantPrice: Yup.number,
});

export const EditForm = ({ product, variant, updateProduct, toggleModal, setProduct }) => {
  const handleSubmit = useCallback(
    async data => {
      const updatedProduct = {
        ...product,
        ...data,
        replace_product: [],
        variants: product.variants.map(row => {
          if (row._id !== variant._id) {
            return row;
          }
          return {
            ...row,
            price: data.variantPrice,
          };
        }),
      };
      const { payload } = await updateProduct(product._id, updatedProduct);
      setProduct(payload);
      toggleModal();
    },
    [product, setProduct, toggleModal, updateProduct, variant]
  );

  return (
    <Form
      initialValues={{
        ...product,
        variantPrice: variant.price,
      }}
      validationSchema={Schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <>
          <Field
            name="eCommerce.type"
            label="E-Commerce type"
            type="select"
            options={{
              '': '---',
              other: 'other',
              custom_amazon: 'custom_amazon',
            }}
          />
          <Field name="eCommerce.name" type="text" label="E-Commerce name" />
          <Field name="eCommerce.productUrl" type="text" label="E-Commerce Product Url" />
          <Field name="variantPrice" type="number" label="Price" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

EditForm.propTypes = {
  product: PropTypes.object,
  variant: PropTypes.object,
  updateProduct: PropTypes.func,
  toggleModal: PropTypes.func,
  setProduct: PropTypes.func,
};
