import React from 'react';
import PropTypes from 'prop-types';
import { Twitter, Instagram, UserCheck, Youtube, Facebook, Mic, Linkedin } from 'react-feather';

export const SocialLinks = ({ user }) => (
  <div className="mt-3">
    {user.social_media?.micro_influencer && (
      <span title="Micro-Influencer" className="text-warning mr-3">
        <UserCheck size="16" />
      </span>
    )}
    {user.social_media?.twitter && (
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="Twitter"
        className="text-primary mr-3"
        href={`https://twitter.com/${user.social_media.twitter}`}
      >
        <Twitter size="16" />
      </a>
    )}
    {user.social_media?.instagram && (
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="Instagram"
        className="text-primary mr-3"
        href={`https://instagram.com/${user.social_media.instagram}`}
      >
        <Instagram size="16" />
      </a>
    )}
    {user.social_media?.tiktok && (
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="Tiktok"
        className="text-primary mr-3"
        href={`https://tiktok.com/@${user.social_media.tiktok}`}
      >
        <Mic size="16" />
      </a>
    )}
    {user.social_media?.youtube && (
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="Youtube"
        className="text-primary mr-3"
        href={`https://youtube.com/${user.social_media.youtube}`}
      >
        <Youtube size="16" />
      </a>
    )}
    {user.social_media?.facebook && (
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="Facebook"
        className="text-primary mr-3"
        href={`https://facebook.com/${user.social_media.facebook}`}
      >
        <Facebook size="16" />
      </a>
    )}
    {user.social_media?.linkedin && (
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="Linkedin"
        className="text-primary mr-3"
        href={`https://linkedin.com/in/${user.social_media.linkedin}`}
      >
        <Linkedin size="16" />
      </a>
    )}
  </div>
);

SocialLinks.propTypes = {
  user: PropTypes.shape({
    social_media: PropTypes.shape({
      twitter: PropTypes.string,
      instagram: PropTypes.string,
      tiktok: PropTypes.string,
      youtube: PropTypes.string,
      linkedin: PropTypes.string,
      facebook: PropTypes.string,
      micro_influencer: PropTypes.bool,
    }),
  }).isRequired,
};
