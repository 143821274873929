import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Alerts } from '../../components/Alerts/Alerts';
import './PanelPage.scss';

export const PanelPage = ({ children, title, className, heading, tabs, filters }) => (
  <div className={'panel-wrapper' + (className ? ` ${className}` : '')}>
    <Container className="d-flex justify-content-between align-items-center">
      {title && <h1 className="mb-2 mt-1 text-nowrap panel-title">{title}</h1>}
      {heading && <div className="d-flex flex-gap-1">{heading}</div>}
    </Container>
    {tabs && (
      <Container>
        <div>
          {tabs.map(({ to, name }) => (
            <NavLink to={to} key={to} exact className="ml-2 btn btn-sm" activeClassName="btn-outline-secondary">
              {name}
            </NavLink>
          ))}
        </div>{' '}
      </Container>
    )}
    {filters && <Container>{filters}</Container>}
    <hr className="horizontal-line mb-2" />
    <div className="panel-content pt-3 pb-4" id="panel-content">
      <Container>
        <Alerts />
        <div>{children}</div>
      </Container>
    </div>
  </div>
);

PanelPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tabs: PropTypes.array,
  filters: PropTypes.node,
};
