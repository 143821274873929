import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Field } from '@bottomless/common/components';

const SearchSchema = Yup.object().shape({
  type: Yup.string(),
});

export const Search = ({ onSubmit }) => {
  const handleSubmit = useCallback(({ submitForm }) => () => setTimeout(submitForm), []);

  return (
    <div className="fulfillment-switch">
      <Form initialValues={{ type: '' }} validationSchema={SearchSchema} onSubmit={onSubmit}>
        {({ submitForm }) => (
          <Field
            name="type"
            type="select"
            label="Type"
            options={{ '': 'All', withError: 'With error' }}
            onChange={handleSubmit({ submitForm })}
          />
        )}
      </Form>
    </div>
  );
};

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
