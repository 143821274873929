import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import {
  createAttributeAction,
  getProductOptionsAction,
  updateAttributeAction,
} from '../../../store/admin/product-options';
import { tabs } from './Tabs';

const AttributeComponent = ({ attribute, onEdit, type, fields }) => {
  return (
    <Card className="mb-2" body>
      <Row>
        <Col xs="6" md="8">
          <div className="text-secondary">#{attribute._id}</div>
          {fields(type).map(({ name, type, options, label }, i) => (
            <p key={i} className="mb-0">
              <span className="text-capitalize">{label ? label : name}: </span>
              <span className="font-weight-bold" title={attribute[name]}>
                {type === 'select' ? options[attribute[name]] : attribute[name]}
              </span>
            </p>
          ))}
        </Col>
        <Col xs="6" md="4" className="d-flex align-items-center justify-content-end">
          <Button size="sm" type="info" onClick={() => onEdit({ attribute })}>
            Edit
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

AttributeComponent.propTypes = {
  attribute: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  fields: PropTypes.func.isRequired,
};

const fields = attributes => type => {
  const defaults = [{ name: 'name' }];

  if (type === 'origins') {
    return [...defaults, { name: 'code' }];
  }

  if (type === 'regions_raw') {
    return [
      ...defaults,
      {
        name: 'origin_id',
        label: 'origin',
        type: 'select',
        options: attributes.origins.reduce(
          (all, { _id, name }) => ({
            ...all,
            [_id]: name,
          }),
          {}
        ),
      },
    ];
  }

  if (type === 'roasts') {
    return [...defaults, { name: 'order', type: 'number' }];
  }

  if (type === 'tasting_notes_raw') {
    return [
      ...defaults,
      {
        name: 'category_id',
        type: 'select',
        label: 'category',
        options: attributes.tastingNoteCategories.reduce(
          (all, { _id, name }) => ({
            ...all,
            [_id]: name,
          }),
          {}
        ),
      },
    ];
  }

  return defaults;
};

const schema = type => {
  let shape = {
    name: Yup.string().required('This field is required'),
  };

  if (type === 'tasting_notes_raw') {
    shape = {
      ...shape,
      category_id: Yup.string()
        .length(24)
        .required('This field is required'),
    };
  }

  if (type === 'origins') {
    shape = {
      ...shape,
      code: Yup.string()
        .length(2)
        .required('This field is required'),
    };
  }

  if (type === 'regions_raw') {
    shape = {
      ...shape,
      origin_id: Yup.string().required('This field is required'),
    };
  }

  if (type === 'roasts') {
    shape = {
      ...shape,
      order: Yup.number()
        .positive()
        .required('This field is required'),
    };
  }

  return Yup.object().shape(shape);
};

const AttributesPageComponent = ({
  addToast,
  getAttributes,
  match: {
    params: { type },
  },
  updateAttribute,
  createAttribute,
}) => {
  const [attributes, setAttributes] = useState(null);
  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState(null);

  useDataEffect(getAttributes, setAttributes);

  const toggle = () => {
    setOpen(!open);

    if (resource) {
      setResource(null);
    }
  };

  const onEdit = ({ attribute }) => {
    setResource(attribute);
    toggle();
  };

  const onSubmit = async data => {
    const resources = attributes[type];

    if (data._id) {
      await updateAttribute({ id: data._id, type, data });

      const index = resources.findIndex(v => v._id === data._id);
      setAttributes({ ...attributes, [type]: [...resources.slice(0, index), data, ...resources.slice(index + 1)] });

      addToast(`Updated ${type}`);

      return toggle();
    }

    const { payload } = await createAttribute({ type, data });
    setAttributes({ ...attributes, [type]: [...resources, payload] });
    addToast(`Created ${type}`);

    return toggle();
  };

  const fieldFactory = fields(attributes);

  return (
    <>
      <PanelPage
        title="Attributes"
        withUpNext={false}
        className="page-attributes"
        tabs={tabs}
        heading={
          <div className="mb-4">
            <Button onClick={toggle} color="success">
              Add new
            </Button>
          </div>
        }
      >
        {attributes &&
          attributes[type] &&
          attributes[type].map(attribute => (
            <AttributeComponent
              key={attribute._id}
              attribute={attribute}
              onEdit={onEdit}
              type={type}
              fields={fieldFactory}
            />
          ))}
      </PanelPage>

      <Modal isOpen={open} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Edit {type}</ModalHeader>
        <ModalBody>
          <Form initialValues={{ ...resource }} validationSchema={schema(type)} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <div className="mb-3 shop-search">
                {fieldFactory(type).map((field, i) => (
                  <Field
                    key={i}
                    type={field.type || 'text'}
                    label={field.label ? field.label : field.name}
                    {...field}
                  />
                ))}
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </div>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

AttributesPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getAttributes: PropTypes.func.isRequired,
  createAttribute: PropTypes.func.isRequired,
  updateAttribute: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const AttributesPage = connect(null, dispatch => ({
  addToast: message => dispatch(addToastAction(message)),
  getAttributes: () => dispatch(getProductOptionsAction()),
  createAttribute: ({ type, data }) => dispatch(createAttributeAction({ type, data })),
  updateAttribute: ({ id, type, data }) => dispatch(updateAttributeAction({ type, id, data })),
}))(AttributesPageComponent);
