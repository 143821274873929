import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  phone: Yup.string().required(),
});

export const UpdatePhoneForm = ({ user, onSubmit, onSubmitSuccess }) => {
  return (
    <Form
      initialValues={{
        phone: user.phone,
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting, dirty }) => (
        <>
          <Field name="phone" type="phone" label="New Phone number" />

          <SubmitButton color="dark" isSubmitting={isSubmitting} disabled={!dirty}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

UpdatePhoneForm.propTypes = {
  user: PropTypes.shape({
    phone: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
