import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { DateFormat } from '@bottomless/common/components';

const fields = [
  { name: 'smoke', label: 'Smoke test' },
  { name: 'subproduct', label: 'Subproduct gen.' },
  { name: 'charge', label: 'Charge' },
  { name: 'shopify', label: 'Shopify' },
  { name: 'shipment', label: 'Shipment' },
];

export const Errors = ({ order }) => {
  const errors = order.fulfillmentErrors || {};

  return (
    <Row>
      {fields.map(({ name, label }) => (
        <Col key={name}>
          <div className="text-secondary">{label}</div>
          {errors[name] && errors[name].date && (
            <>
              <DateFormat date={errors[name].date} />
              <div>
                {errors[name].error ? (
                  <div className="text-danger">{errors[name].error}</div>
                ) : (
                  <div className="text-success">No error</div>
                )}
              </div>
            </>
          )}
          {(!errors[name] || !errors[name].date) && <div>---</div>}
        </Col>
      ))}
    </Row>
  );
};

const fulfillmentError = PropTypes.shape({
  date: PropTypes.string,
  error: PropTypes.string,
});

Errors.propTypes = {
  order: PropTypes.shape({
    fulfillmentErrors: PropTypes.shape({
      smoke: fulfillmentError,
      subproduct: fulfillmentError,
      charge: fulfillmentError,
      shopify: fulfillmentError,
    }),
  }).isRequired,
};
