import { createStore as createBottomlessStore } from '@bottomless/common/store';
import { adminEmailsReducer } from './admin/emails';
import { adminOrderGenReducer } from './admin/ordergen/ordergen.reducer';
import { adminScaleShipmentsReducer } from './admin/scale-shipments/scale-shipment.reducer';
import { adminUsersReducer } from './admin/user/user.reducer';
import { adminPricingRulesReducer } from './pricing_rules/pricing_rules.reducers';
import { adminVendorReducer } from './admin/vendor/vendor.reducer';
import { userReducer } from './user/user.reducer';
import { adminGiftsReducer } from './admin/gift/gift.reducer';

export const createStore = () =>
  createBottomlessStore(
    {
      user: userReducer,
      adminOrderGen: adminOrderGenReducer,
      adminVendor: adminVendorReducer,
      adminEmails: adminEmailsReducer,
      adminUsers: adminUsersReducer,
      adminPricingRules: adminPricingRulesReducer,
      adminScaleShipments: adminScaleShipmentsReducer,
      adminGifts: adminGiftsReducer,
    },
    ['adminOrderGen']
  );
