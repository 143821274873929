import { debounce, pickBy } from 'lodash-es';
import qs from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  status: Yup.string().nullable(true),
  search: Yup.string(),
});

const types = {
  null: 'All',
  onboarding: 'Onboarding',
  replacement: 'Replacement',
  offboarding: 'Offboarding',
};

const statuses = {
  null: 'All',
  requested: 'Requested',
  created: 'Created',
  shipped: 'Shipped',
  arrived: 'Arrived',
  failure: 'Failure',
  canceled: 'Canceled',
};

export const Filters = () => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const formRef = useRef();
  const [filters, setFilters] = useState(query);

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(pickBy(filters, v => v && v !== 'null')),
    });
  }, [filters]);

  return (
    <Form
      inline
      innerRef={formRef}
      initialValues={{ status: '', ...filters }}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {() => (
        <>
          <Row className="w-100 align-items-center">
            <Col xs="3">
              <Field
                name="type"
                label="type"
                type="select"
                options={types}
                onChange={e => onFilterChange('type', e.target.value)}
              />
            </Col>
            <Col xs="3">
              <Field
                name="status"
                label="status"
                type="select"
                options={statuses}
                onChange={e => onFilterChange('status', e.target.value)}
              />
            </Col>
            <Col className="pr-0">
              <Field
                className="w-100 up-label"
                name="search"
                label="Search"
                placeholder={`Search by ${['batch_id', 'tracking_number', 'user_id'].join(', ')}`}
                onChange={debounce(event => {
                  onFilterChange('search', event.target.value);
                }, 200)}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
