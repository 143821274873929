import { get } from 'lodash-es';
import React, { memo, useState } from 'react';
import { Server, User } from 'react-feather';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { DateFormat } from '@bottomless/common/components';
import { Nl2Br } from '../../Nl2Br/Nl2Br';
import { Link } from 'react-router-dom';

const NoteComponent = ({ note, order, onDelete }) => {
  const [isOpen, setOpen] = useState(true);

  const handleDelete = note => {
    if (note.type && !['note', 'sticky'].includes(note.type)) {
      return null;
    }

    return () => {
      setOpen(false);
      return onDelete(note);
    };
  };

  return (
    <Alert
      key={note._id}
      color="secondary"
      className="d-flex justify-content-between align-items-center text-sm customer-note"
      toggle={handleDelete(note)}
      isOpen={isOpen}
    >
      <div className="mr-3 note">
        {note.type && note.type !== 'note' && note.type !== 'rotation_changes' && (
          <strong className="text-capitalize">[{note.type.replace(/_/g, ' ')}] </strong>
        )}
        {note.content && <Nl2Br>{note.content}</Nl2Br>}
        {!order && note.order && (
          <div>
            For order <Link to={`/admin/ordering_cockpit/${note.user}?orderId=${note.order}`}>#{note.order}</Link>
          </div>
        )}
      </div>
      <div className="text-right text-sm">
        <div>{get(note, 'author.local.email') || get(note, 'vendor.name') || '---'}</div>

        <div className="d-flex justify-content-center mt-2">
          <div className="mr-2 d-flex align-items-center">
            <Server size="14" className="mr-1" />
            <DateFormat date={note.created_at} correctTimezone withTime />
          </div>
          <div className="d-flex align-items-center">
            <User size="14" className="mr-1 d-flex align-items-center" />
            <DateFormat date={note.created_at} withTime />
          </div>
        </div>
      </div>
    </Alert>
  );
};

NoteComponent.propTypes = {
  onDelete: PropTypes.func.isRequired,
  order: PropTypes.object,
  note: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    content: PropTypes.string,
    user: PropTypes.string,
    order: PropTypes.string,
    type: PropTypes.string,
    author: PropTypes.shape({
      local: PropTypes.shape({
        email: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

export const Note = memo(NoteComponent);
