import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { selectSubproductAction, getDetailsSubproductSelectionAction } from '../../../../store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getDisLikedProductsAction, getProductsAction } from '../../../../store/admin/product';
import { Order } from '../components/Order';
import { Categories } from '@bottomless/common/constants';

const SubproductDetailsPageComponent = ({ getOrder, getProducts, selectSubproduct, getDisLikedProducts, addToast }) => {
  const [products, setProducts] = useState(null);
  const [order, setOrder] = useState(null);

  useDataEffect(getOrder, setOrder);

  useDataEffect(getProducts, response => setProducts(get(response, 'docs', [])), {
    status: 'active',
    sort: 'name',
    dir: 1,
    category: Categories.Coffee,
  });

  const onChooseSubproduct = useCallback(
    async (order, subproduct) => {
      try {
        const { payload, error } = await selectSubproduct(order._id, subproduct);

        if (error) {
          addToast(
            `Failed for ${order.user_id.first_name} ${order.user_id.last_name} - ${payload.response.message}`,
            'error'
          );
        } else {
          addToast('Subproduct selected!', 'success');
        }
      } catch (err) {
        addToast(err.message);
      }
    },
    [selectSubproduct]
  );

  return (
    <PanelPage title="Subproduct selection">
      <DataLoading count={order ? 1 : 0} isLoading={order === null || products === null}>
        <div />
      </DataLoading>
      {products && order && Object.keys(order).length && (
        <Order
          order={order}
          products={products}
          onChooseSubproduct={onChooseSubproduct}
          getDisLikedProducts={getDisLikedProducts}
        />
      )}
    </PanelPage>
  );
};

SubproductDetailsPageComponent.propTypes = {
  getOrder: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  selectSubproduct: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  getDisLikedProducts: PropTypes.func.isRequired,
};

export const SubproductDetailsPage = connect(null, (dispatch, { match: { params: { id } } }) => ({
  selectSubproduct: (id, data) => dispatch(selectSubproductAction(id, data)),
  getProducts: params => dispatch(getProductsAction(params)),
  getOrder: () => dispatch(getDetailsSubproductSelectionAction(id)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getDisLikedProducts: params => dispatch(getDisLikedProductsAction(params)),
}))(SubproductDetailsPageComponent);
