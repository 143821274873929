import React, { useMemo, useState } from 'react';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import 'chartjs-plugin-annotation';
import pattern from 'patternomaly';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { Badge } from 'reactstrap';
import { OrderStatuses } from '@bottomless/common/constants';

const dayInSeconds = 86400000;

const SOURCE_USER_ONE_OFF = 'user_one_off';
const SOURCE_REPLACED = 'replaced';
const SOURCE_LOST = 'lost';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const PstTimezone = 'America/Los_Angeles';

export const Graph = ({
  stats,
  tareRecords,
  rolling,
  orders,
  predictions,
  onLatestOrder,
  onRecordClick,
  selectedVendor,
  bestVendor,
  slopeline,
  nextFulfillmentDate,
}) => {
  const now = new Date();
  const nextWeek = new Date();
  nextWeek.setDate(now.getDate() + 7);
  const threeMonthsAgo = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);

  const outlineMin = useMemo(() => -10, []);
  const outlineMax = useMemo(() => (orders?.length ? Math.max(...orders.map(getOrderSize)) * 2 : 85), [orders]);
  const predictionLineHeight = orders[0] ? getOrderSize(orders[0]) : 12;

  const statsData = useMemo(
    () =>
      stats
        .filter(record => record.adj_weight > outlineMin && record.adj_weight < outlineMax)
        .map(record => ({
          x: new Date(record.timestamp),
          y: record.adj_weight.toFixed(1),
        })),
    [outlineMax, outlineMin, stats]
  );

  const lastRead = (statsData[statsData.length - 1] || {}).y;

  const maxValue = useMemo(
    () =>
      Math.max(
        12,
        ...[
          ...stats
            .filter(record => record.adj_weight > outlineMin && record.adj_weight < outlineMax)
            .map(record => record.adj_weight),
          ...orders.map(getOrderSize),
        ]
      ),
    [stats, orders, outlineMin, outlineMax]
  );

  const filteredData = useMemo(
    () =>
      stats
        .filter(record => record.adj_weight < outlineMin || record.adj_weight > outlineMax)
        .map(record => ({
          data: [{ x: new Date(record.timestamp), y: record.adj_weight > 0 ? maxValue : -1, raw: record.adj_weight }],
          label: `#1 Filtered out ${record._id}`,
          pointStyle: 'triangle',
          borderColor: 'black',
          backgroundColor: 'rgba(0,0,0,0.4)',
          pointRadius: 6,
          rotation: record.adj_weight > 0 ? 180 : 0,
        })),
    [stats, outlineMin, outlineMax, maxValue]
  );

  const rollingData = useMemo(
    () =>
      rolling
        ? Object.entries(rolling).map(([key, value]) => {
            const days = Number(key.replace(/[^\d]+/g, ''));

            return {
              type: 'line',
              label: `Rolling sum - ${days} days`,
              backgroundColor: 'transparent',
              pointRadius: 1,
              data: [
                { x: now, y: lastRead },
                { x: nextWeek, y: lastRead - (value / days) * 7 },
              ],
            };
          })
        : [],
    [lastRead, nextWeek, now, rolling]
  );

  const slope = useMemo(
    () =>
      slopeline
        ? [
            {
              type: 'line',
              label: `Avg Consumption`,
              backgroundColor: 'transparent',
              borderColor: '#000000',
              pointRadius: 1,
              borderWidth: 1,
              data: [
                { x: new Date(slopeline.X0), y: slopeline.Y0 },
                { x: new Date(slopeline.X1), y: slopeline.Y1 },
              ],
            },
            slopeline.XR0 !== -1
              ? {
                  type: 'line',
                  label: `Recent Consumption`,
                  backgroundColor: 'transparent',
                  borderColor: '#696969',
                  pointRadius: 1,
                  borderWidth: 1,
                  data: [
                    { x: new Date(slopeline.XR0), y: slopeline.YR0 },
                    { x: new Date(slopeline.XR1), y: slopeline.YR1 },
                  ],
                }
              : {},
          ]
        : [],
    [slopeline]
  );

  const tareData = useMemo(
    () =>
      tareRecords.map(record => ({
        data: [{ x: new Date(record.timestamp), y: 0 }],
        tare: record.adj_weight?.toFixed(1),
        label: `Tare ${record._id}`,
        pointStyle: 'star',
        borderColor: 'red',
        pointRadius: 6,
      })),
    [tareRecords]
  );

  const [latestRead, setLatestRead] = useState(lastRead);
  const [latestOrder, setLatestOrder] = useState(null);

  const oneOffOrdersData = useMemo(
    () =>
      orders
        .filter(order => order.source && [SOURCE_USER_ONE_OFF, SOURCE_REPLACED, SOURCE_LOST].includes(order.source))
        .filter(order => order.status === 'fulfilled' && order.date_fulfilled)
        .map(order => ({
          data: [{ x: new Date(order.date_fulfilled), y: 12 }],
          label: `#3 ${order._id} ${order.source}`,
          pointStyle: 'star',
          borderColor: 'green',
          backgroundColor: 'green',
          pointRadius: 6,
        })),
    [orders]
  );

  const ordersData = useMemo(
    () =>
      orders
        .filter(order => order.date_arrived || ![OrderStatuses.Refunded].includes(order.status))
        .filter(order => order.source && ![SOURCE_USER_ONE_OFF, SOURCE_REPLACED, SOURCE_LOST].includes(order.source))
        .map(order => ({
          data: [
            { x: new Date(order.date_initiated), y: 0 },
            { x: new Date(order.date_initiated), y: getOrderSize(order) },
            ...(order.date_arrived
              ? [
                  { x: new Date(order.date_arrived), y: getOrderSize(order) },
                  { x: new Date(order.date_arrived), y: 0 },
                ]
              : []),
          ],
          label: order._id,
          vendorName: order.subvendor_id ? order.subvendor_id.name : null,
          dateInitiated: order.date_initiated,
          dateArrived: order.date_arrived,
          pointRadius: 1,
          borderWidth: 1,
          backgroundColor: patterns(order),
          borderColor: hexToRgb(order.status, order._id.toString(16).substr(-6)),
        })),
    [orders]
  );

  const predictionsStart = new Date((predictions || {}).timestamp);
  const nextPredictionsStart = new Date((predictions || {}).nextDay);
  predictionsStart.setUTCHours(0, 0, 0, 0);
  nextPredictionsStart.setUTCHours(0, 0, 0, 0);

  const todayPredictions = (
    ((selectedVendor ? predictions[selectedVendor] : predictions) || {})['today_predictions'] || []
  ).reduce((all, curr) => [...all, (Number(all[all.length - 1] || 0) + Number(curr)).toFixed(2)], []);
  const todaySum = todayPredictions[todayPredictions.length - 1];

  const todayPredictionsData = todayPredictions
    .map((pred, i) => ({
      pred,
      data: [
        { x: new Date(predictionsStart.getTime() + (i + 1) * dayInSeconds), y: predictionLineHeight / 2 },
        { x: new Date(predictionsStart.getTime() + (i + 1) * dayInSeconds), y: predictionLineHeight },
        { x: new Date(predictionsStart.getTime() + (i + 2) * dayInSeconds), y: predictionLineHeight },
        { x: new Date(predictionsStart.getTime() + (i + 2) * dayInSeconds), y: predictionLineHeight / 2 },
      ],
      label: `Today prediction for ${moment(new Date(predictionsStart.getTime() + i * (dayInSeconds + 1))).format(
        'MM/DD/YYYY'
      )} - ${Number(pred)}`,
      pointRadius: 1,
      borderWidth: 1,
      backgroundColor: getColor((Number(pred) / todaySum).toFixed(2)),
      borderColor: getColor((Number(pred) / todaySum).toFixed(2)),
    }))
    .filter(({ pred }) => pred > 0);

  const tomorrowPredictions = (
    ((bestVendor ? predictions[bestVendor] : predictions) || {})['tomorrow_predictions'] || []
  ).reduce((all, curr) => [...all, (Number(all[all.length - 1] || 0) + Number(curr)).toFixed(2)], []);
  const tomorrowSum = tomorrowPredictions[tomorrowPredictions.length - 1];
  const tomorrowPredictionsData = tomorrowPredictions
    .map((pred, i) => ({
      pred,
      data: [
        { x: new Date(nextPredictionsStart.getTime() + (i + 1) * dayInSeconds), y: 0 },
        { x: new Date(nextPredictionsStart.getTime() + (i + 1) * dayInSeconds), y: predictionLineHeight / 2 },
        { x: new Date(nextPredictionsStart.getTime() + (i + 2) * dayInSeconds), y: predictionLineHeight / 2 },
        { x: new Date(nextPredictionsStart.getTime() + (i + 2) * dayInSeconds), y: 0 },
      ],
      label: `Tomorrow Prediction for ${moment(
        new Date(nextPredictionsStart.getTime() + i * (dayInSeconds + 1) + 1)
      ).format('MM/DD/YYYY')} - ${Number(pred)}`,
      pointRadius: 1,
      borderWidth: 1,
      backgroundColor: getColor((Number(pred) / tomorrowSum).toFixed(2)),
      borderColor: getColor((Number(pred) / tomorrowSum).toFixed(2)),
    }))
    .filter(({ pred }) => pred > 0);

  const todayPredictionsFilter = tomorrowPredictions.map((pred, i) => ({
    data: [
      { x: new Date(predictionsStart.getTime() + (i + 1) * dayInSeconds), y: predictionLineHeight / 2 },
      { x: new Date(predictionsStart.getTime() + (i + 2) * dayInSeconds), y: predictionLineHeight / 2 },
    ],
    label: `Filter for ${moment(new Date(predictionsStart.getTime() + i * (dayInSeconds + 1))).format('MM/DD/YYYY')}`,
    pointRadius: 0,
    borderWidth: 0,
    backgroundColor: 'white',
    borderColor: 'white',
  }));

  const options = {
    legend: { display: false },
    onClick: (_, items) => {
      const item = items.find(item => item._datasetIndex === 0);

      if (item) {
        const value = stats[item._index].adj_weight.toFixed(1);
        onRecordClick(Number(value));
      }
    },
    animation: false,
    scales: {
      xAxes: [
        {
          type: 'time',
          time: { unit: 'day' },
          ticks: { max: nextWeek, min: threeMonthsAgo },
          gridLines: { display: false },
        },
      ],
      yAxes: [{ position: 'right', ticks: { callback: item => `${item} oz`, suggestedMax: 12, suggestedMin: 0 } }],
    },
    annotation: {
      annotations: [
        {
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: now,
          borderColor: 'black',
          borderWidth: 1,
        },
        ...(nextFulfillmentDate
          ? [
              {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                mode: 'vertical',
                scaleID: 'x-axis-0',
                value: nextFulfillmentDate,
                borderColor: '#ba48c1',
                borderWidth: 2,
              },
            ]
          : []),
      ],
    },
    tooltips: {
      intersect: false,
      callbacks: {
        label: ({ datasetIndex, value, index }, { datasets }) => {
          if (datasets[datasetIndex].label.includes('Avg Consumption')) {
            return `Avg Estimation: ${moment(datasets[datasetIndex]?.data[1]?.x).format('MM/DD/YYYY hh:mm a')}`;
          }
          if (datasets[datasetIndex].label.includes('Recent Consumption')) {
            return `Estimation: ${moment(datasets[datasetIndex]?.data[1]?.x).format('MM/DD/YYYY hh:mm a')}`;
          }
          if (datasets[datasetIndex].label.includes('Tare')) {
            return `Tare: ${datasets[datasetIndex].tare}`;
          }

          if (datasets[datasetIndex].label.includes('#1 Filtered')) {
            return `Filtered out: ${Number(datasets[datasetIndex].data[index].raw).toFixed(1)}`;
          }

          if (datasets[datasetIndex].label.includes('#3')) {
            return `One-off/Lost/Replaced order: ${datasets[datasetIndex].label}`;
          }

          if (datasets[datasetIndex].label.includes('Filter')) {
            return null;
          }

          if (datasetIndex > 1) {
            let date;
            if (index === 1) {
              date = datasets[datasetIndex].dateInitiated;
            } else if (index === 2) {
              date = datasets[datasetIndex].dateArrived;
            }

            const dateInPst = date ? momentTimezone.tz(date, PstTimezone) : null;
            const day = dateInPst ? daysOfWeek[dateInPst.day()] : null;
            const orderId = datasets[datasetIndex].label;
            const vendor = datasets[datasetIndex].vendorName;

            const tooltipText = [`Order: ${orderId}`];
            if (vendor) {
              tooltipText.push(`Vendor: ${vendor}`);
            }
            if (dateInPst) {
              const formattedDate = dateInPst.format('MM/DD/YYYY hh:mm a');
              if (index === 1) {
                tooltipText.push(`Initiated Date (PT): ${formattedDate}`);
              } else if (index === 2) {
                tooltipText.push(`Arrived Date (PT): ${formattedDate}`);
              }
            }
            if (day) {
              if (index === 1) {
                tooltipText.push(`Initiated Day (PT): ${day}`);
              } else if (index === 2) {
                tooltipText.push(`Arrived Day (PT): ${day}`);
              }
            }

            setLatestOrder(orderId);
            return tooltipText;
          }

          setLatestRead(value);
          return value;
        },
        beforeFooter: () => 'From today:',
        footer: ([{ datasetIndex, value }]) => {
          if (datasetIndex) {
            return null;
          }

          return `${(Number(value) - Number(lastRead)).toFixed(1)}oz`;
        },
        afterFooter: ([{ datasetIndex, index }], { datasets }) => {
          if (datasetIndex) {
            return null;
          }

          return (
            Math.round(moment.duration(moment(now).diff(moment(datasets[datasetIndex].data[index].x))).asDays()) +
            ' days'
          );
        },
      },
    },
    elements: { line: { tension: 0, backgroundColor: 'rgba(0, 149, 212, 0.2)' } }, // Bezier Curve parameter
  };

  return (
    <div className="pofile-graph">
      <div className="d-flex justify-content-between align-items-center mb-3 pt-1">
        <div>
          {latestOrder && (
            <div className="d-flex justify-content-end">
              {onLatestOrder && (
                <div className="d-flex align-items-center mr-3">
                  <button onClick={() => onLatestOrder(latestOrder)} className="btn btn-sm btn-dark">
                    filter
                  </button>
                </div>
              )}
              <div className="d-flex align-items-center mr-3 text-large">
                OrderId: <Badge className="ml-2">{latestOrder}</Badge>{' '}
              </div>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center text-large pr-1">
          Reading:<Badge className="ml-2">{latestRead}</Badge>
        </div>
      </div>
      <Line
        data={{
          datasets: [
            { data: statsData, label: 'Stats', pointRadius: 1 },
            ...ordersData,
            ...slope,
            ...tomorrowPredictionsData,
            ...todayPredictionsFilter,
            ...todayPredictionsData,
            ...tareData,
            ...filteredData,
            ...oneOffOrdersData,
            ...rollingData,
          ],
        }}
        options={options}
        height={75}
      />
    </div>
  );
};

const getOrderSize = order => {
  const defaultSize = 0;

  if (
    !order.subproduct_id ||
    !order.subproduct_id.product ||
    !order.subproduct_id.product.variants ||
    !order.subproduct_id.variant
  ) {
    return defaultSize;
  }

  const variant = order.subproduct_id.product.variants.find(variant => variant._id === order.subproduct_id.variant);
  return variant ? variant.size : defaultSize;
};

const patterns = order => {
  if (order.source === SOURCE_USER_ONE_OFF) {
    return pattern.draw('zigzag', hexToRgba(order.status, order._id.toString(16).substr(-6)));
  }

  if (order.override_fulfillment_date) {
    return pattern.draw('diagonal', hexToRgba(order.status, order._id.toString(16).substr(-6)));
  }

  return hexToRgba(order.status, order._id.toString(16).substr(-6));
};

const hexToRgb = (status, hex) => {
  if (status === 'cancelled') {
    return 'rgb(255, 0, 0)';
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgb(0, ${Math.round(parseInt(result[2], 16) * 0.6)}, ${Math.round(parseInt(result[3], 16) * 0.6)})`;
};

const hexToRgba = (status, hex, alpha = 0.6) => {
  if (status === 'cancelled') {
    return `rgba(255, 0, 0, 0.7)`;
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(0, ${Math.round(parseInt(result[2], 16) * 0.6)}, ${Math.round(
    parseInt(result[3], 16) * 0.6
  )}, ${alpha})`;
};

const getColor = (value, alpha = 1) => `hsla(${value * 100}, 50%, ${100 - value * 80}%, ${alpha})`;

Graph.propTypes = {
  stats: PropTypes.array.isRequired,
  tareRecords: PropTypes.array.isRequired,
  orders: PropTypes.array.isRequired,
  onLatestOrder: PropTypes.func,
  predictions: PropTypes.object,
  onRecordClick: PropTypes.func.isRequired,
  rolling: PropTypes.object,
  selectedVendor: PropTypes.string,
  bestVendor: PropTypes.string,
  slopeline: PropTypes.object,
  nextFulfillmentDate: PropTypes.instanceOf(Date),
};
