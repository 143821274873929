import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { LocationLine } from './components/LocationLine';
import { getSlowLocalFulfillment } from '../../../store';
import { tabs } from './Tabs';

const SlowLocalFulfillment = ({ getOrders }) => {
  const { data, isFetching } = useDataEffect(getOrders);

  const onCsvOrderClick = useCallback(
    ({ vendor, date }) => e => {
      e.preventDefault();
      e.stopPropagation();

      window.open(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/orders/fulfillment/slow-local/csv?date=${date}${
          vendor ? `&vendor_id=${vendor._id}` : ''
        }`
      );
    },
    []
  );

  const onCsvShoppingListClick = useCallback(
    ({ vendor, date }) => e => {
      e.preventDefault();
      e.stopPropagation();

      window.open(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/orders/fulfillment/slow-local/shopping-list?date=${date}${
          vendor ? `&vendor_id=${vendor._id}` : ''
        }`
      );
    },
    []
  );

  return (
    <PanelPage title="Fulfillment" className="page-fulfillment page-sent-to-roaster" tabs={tabs}>
      <DataLoading count={data?.length || 0} isLoading={isFetching} />
      {data &&
        data.map(({ _id, vendors }) => (
          <>
            <LocationLine
              key={_id}
              location={_id}
              vendors={vendors}
              onCsvOrderClick={onCsvOrderClick}
              onCsvShoppingListClick={onCsvShoppingListClick}
            />
          </>
        ))}
    </PanelPage>
  );
};

SlowLocalFulfillment.propTypes = {
  getOrders: PropTypes.func.isRequired,
};

export const SlowLocalFulfillmentPage = connect(null, dispatch => ({
  getOrders: () => dispatch(getSlowLocalFulfillment()),
}))(SlowLocalFulfillment);
