import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { getECommerceFulfilledOrdersAction, updateECommerceOrderAction } from '../../../../store/admin/e-commerce';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { Order } from './components/Order';
import { tabs } from '../Tabs';
import './FulfilledOrder.scss';
import { addToastAction } from '@bottomless/common/store';

const ECommerceFulfilledOrderPageComponent = ({ getOrders, updateOrder, addToast }) => {
  const [orders, setOrders] = useState(null);

  useDataEffect(getOrders, setOrders);

  return (
    <PanelPage title="Fulfilled Orders" tabs={tabs}>
      <DataLoading count={(orders || { length: 0 }).length} isLoading={orders === null}>
        <div className="text-center">
          <span className="d-block mb-3">No fulfilled orders right now.</span>
        </div>
      </DataLoading>
      {orders && (
        <>
          {orders.map(order => (
            <Order order={order} key={order._id} updateOrder={updateOrder} addToast={addToast} />
          ))}
        </>
      )}
    </PanelPage>
  );
};

ECommerceFulfilledOrderPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const ECommerceFulfilledOrderPage = connect(null, dispatch => ({
  getOrders: () => dispatch(getECommerceFulfilledOrdersAction()),
  updateOrder: (id, data) => dispatch(updateECommerceOrderAction(id, data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(ECommerceFulfilledOrderPageComponent);
