import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DataLoading } from '@bottomless/common/components';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { LocationLine } from './components/LocationLine';
import { tabs } from './Tabs';
import './Fulfillment.scss';

export const GroupedFulfillmentPage = ({ getOrders }) => {
  const [orders, setOrders] = useState(null);
  useConditionalDataEffect(!orders, getOrders, setOrders);

  return (
    <PanelPage title="Fulfillment" className="page-fulfillment page-sent-to-roaster" tabs={tabs}>
      <DataLoading count={1} isLoading={orders === null} />

      {orders && (
        <>
          {Object.keys(orders).map(id => (
            <LocationLine key={id} location={orders[id]._id} vendors={orders[id].vendors} />
          ))}
        </>
      )}
    </PanelPage>
  );
};

GroupedFulfillmentPage.propTypes = {
  getOrders: PropTypes.func.isRequired,
};
