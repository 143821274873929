import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge } from 'reactstrap';
import classnames from 'classnames';

export const LazyTabs = ({ headers, children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [initiated, setInitiated] = useReducer((state, index) => ({ ...state, [index]: true }), { 0: true });

  const toggle = tab => {
    if (activeTab !== tab) {
      setInitiated(tab);
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs>
        {headers.map((name, i) => (
          <NavItem key={i}>
            <NavLink className={classnames({ active: activeTab === i })} onClick={() => toggle(i)}>
              <Header header={name} />
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {children
          .filter(row => !row.props.skipRender)
          .map((child, i) => (
            <TabPane key={i} tabId={i}>
              <div className="pt-4">{initiated[i] && child}</div>
            </TabPane>
          ))}
      </TabContent>
    </>
  );
};

const PropHeader = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    text: PropTypes.string,
    counter: PropTypes.number,
  }),
]);

LazyTabs.propTypes = {
  headers: PropTypes.arrayOf(PropHeader).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

const Header = ({ header }) => {
  if (typeof header === 'string') {
    return <>{header}</>;
  }

  return (
    <>
      {header.text}
      <Badge pill color="light" className="ml-2">
        {header.counter}
      </Badge>
    </>
  );
};

Header.propTypes = {
  header: PropHeader,
};
