import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Radio, Field } from '@bottomless/common/components';
import { PickupDay } from './PickupDay';

export const Provider = ({ provider, vendorParcels, parcels }) => {
  const parcelOptions = useMemo(
    () => ({
      '': '-- Choose parcel --',
      ...parcels
        .filter(parcel => parcel.provider === provider._id)
        .reduce((all, parcel) => ({ ...all, [parcel._id]: parcel.name }), {}),
    }),

    [parcels]
  );

  return (
    <Card className="mb-4">
      <CardHeader>
        <Radio name="provider" type="radio" value={provider._id} label={provider.name} />
      </CardHeader>
      <CardBody>
        <Row>
          {vendorParcels.map((parcel, index) => (
            <Col key={index}>
              <Field
                type="select"
                options={parcelOptions}
                name={`parcels[${provider._id}][${index}].parcel`}
                label={`${parcel.size}oz`}
              />
            </Col>
          ))}
        </Row>
        <div className="mt-4 mb-4">
          <strong>Pick up times (in local vendor&apos;s time):</strong>
        </div>
        <PickupDay providerId={provider._id} day="sunday" label="Sunday" />
        <PickupDay providerId={provider._id} day="monday" label="Monday" />
        <PickupDay providerId={provider._id} day="tuesday" label="Tuesday" />
        <PickupDay providerId={provider._id} day="wednesday" label="Wednesday" />
        <PickupDay providerId={provider._id} day="thursday" label="Thursday" />
        <PickupDay providerId={provider._id} day="friday" label="Friday" />
        <PickupDay providerId={provider._id} day="saturday" label="Saturday" />
      </CardBody>
    </Card>
  );
};

Provider.propTypes = {
  provider: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  vendorParcels: PropTypes.arrayOf(PropTypes.shape({ size: PropTypes.number.isRequired })).isRequired,
  parcels: PropTypes.arrayOf(
    PropTypes.shape({
      weight: PropTypes.number,
      provider: PropTypes.string.isRequired,
    })
  ).isRequired,
};
