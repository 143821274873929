import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Input, FormGroup } from 'reactstrap';
import { Default, Image } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { parseGroceryProductsAction, upsertGroceryProductsAction } from '../../../store/admin/grocery-product';
import { Form } from './components/Form';
import { Upsert } from './components/Upsert';

import './GroceryProducts.scss';

const GroceryProductPageComponent = ({ parseGroceryProducts, upsertGroceryProducts, addToast }) => {
  const [products, setProducts] = useState([]);
  const [checked, setChecked] = useState([]);
  const [upsertResult, setUpsertResult] = useState([]);

  useEffect(() => setChecked(products.map((_, key) => key)), [products, setChecked]);

  const onChange = useCallback(
    e => {
      const { checked: check, value: rawValue } = e.target;
      const value = Number(rawValue);

      if (check && !checked.includes(value)) {
        setChecked([...checked, value]);
      }
      if (!check && checked.includes(value)) {
        setChecked(checked.filter(key => key !== value));
      }
    },
    [checked, setChecked]
  );

  const handleProductsUpsert = useCallback(
    data => {
      setUpsertResult([]);
      return upsertGroceryProducts(data);
    },
    [upsertGroceryProducts, setUpsertResult]
  );

  const onUpsertSuccess = useCallback(
    result => {
      const failedProductsCount = result.reduce((prev, curr) => (curr.error ? prev + 1 : prev), 0);
      if (!failedProductsCount) {
        addToast('All Products are upserted!');
      } else {
        addToast(`${failedProductsCount} failed out of ${result.length}`, 'error');
      }
      setUpsertResult(result);
    },
    [addToast, setUpsertResult]
  );

  return (
    <PanelPage title="Grocery Products" className="page-admin-grocery-products">
      <div className="mb-4 d-flex justify-content-between">
        <Form
          onSubmit={parseGroceryProducts}
          onSuccess={params => {
            setProducts(params);
            setUpsertResult([]);
          }}
        />
        <Upsert
          checked={checked}
          products={products}
          onSubmit={handleProductsUpsert}
          onSuccess={onUpsertSuccess}
          disabled={upsertResult.length}
        />
      </div>
      {products.length > 0 && (
        <Table size="sm">
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th>Category</th>
              <th>Sub-Category</th>
              <th>Name</th>
              <th className="description">Description</th>
              <th>Photos</th>
              <th>Local</th>
              <th>Local Store</th>
              <th>Price</th>
              <th>SKU</th>
              <th>Source</th>
              <th>Tier</th>
              <th>Status</th>
              <th>Reason</th>
              <th>Upsert Status</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, key) => (
              <tr key={key} className={upsertResult[key]?.error ? 'text-danger' : ''}>
                <td>
                  <FormGroup className="grocery-products-check pt-2 pb-2" check>
                    <Input
                      name={`user-${key}`}
                      value={key}
                      type="checkbox"
                      onChange={onChange}
                      checked={checked.includes(key)}
                      disabled={product.invalid}
                    />
                  </FormGroup>
                </td>
                <td>
                  <Default>{product._id}</Default>
                </td>
                <td>
                  <Default>{product.category}</Default>
                </td>
                <td>
                  <Default>{product.sub_category}</Default>
                </td>
                <td>
                  <Default>{product.name}</Default>
                </td>
                <td>
                  <Default>{product.description}</Default>
                </td>
                <td>
                  {product.item_photos &&
                    product.item_photos.map((photo, index) => (
                      <Image className="product-image" key={index} src={photo} />
                    ))}
                </td>
                <td>{product.local ? 'True' : 'False'}</td>
                <td>
                  <Default>
                    {product.local_store &&
                      product.local_store.map((store, index) => (
                        <div key={index}>
                          {store.name}:{store.aisle}
                        </div>
                      ))}
                  </Default>
                </td>
                <td>
                  <Default>{product.price}</Default>
                </td>
                <td>
                  <Default>{product.sku}</Default>
                </td>
                <td>
                  <Default>{product.source}</Default>
                </td>
                <td>
                  <Default>{product.tier}</Default>
                </td>
                <td>
                  <Default>{product.status}</Default>
                </td>
                <td>
                  <Default>{product.reason}</Default>
                </td>
                <td>
                  <Default>
                    {upsertResult.length ? (upsertResult[key].error ? 'Failed' : 'Success') : 'Pending'}
                  </Default>
                </td>
                <td>
                  <Default>{upsertResult[key]?.error}</Default>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </PanelPage>
  );
};

GroceryProductPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  parseGroceryProducts: PropTypes.func.isRequired,
  upsertGroceryProducts: PropTypes.func.isRequired,
};

export const GroceryProductPage = connect(null, dispatch => ({
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  parseGroceryProducts: data => dispatch(parseGroceryProductsAction(data)),
  upsertGroceryProducts: data => dispatch(upsertGroceryProductsAction(data)),
}))(GroceryProductPageComponent);
