import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Table } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, DateFormat } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  credits: Yup.number(),
  months: Yup.number(),
});

const MicroInfluencer = 'micro-influencer';

export const AttributeInfluencerForm = ({ user, onSubmit, onSubmitSuccess }) => {
  const credits = useMemo(() => user.credits.filter(c => c.reason === MicroInfluencer), [user]);

  return (
    <>
      <div className="mb-4">
        <Form
          initialValues={{
            credits: user.social_media?.credits || 0,
            months: user.social_media?.months || 0,
          }}
          validationSchema={Schema}
          onSubmit={onSubmit}
          onSuccess={onSubmitSuccess}
        >
          {({ isSubmitting }) => (
            <>
              <Field name="credits" label="Credits" type="number" />
              <Field name="months" label="Months" type="number" />
              <SubmitButton color="dark" isSubmitting={isSubmitting}>
                Attribute
              </SubmitButton>
            </>
          )}
        </Form>
      </div>
      {credits.length > 0 && (
        <div>
          Credits:
          <Table>
            <thead>
              <tr>
                <th>Source</th>
                <th>Status</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              {credits.map((credit, key) => (
                <tr key={key}>
                  <td>{credit.source}</td>
                  <td>{credit.status}</td>
                  <td>
                    <DateFormat date={credit.created_at} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {!credits.length && <div className="text-secondary">No credits yet</div>}
    </>
  );
};

AttributeInfluencerForm.propTypes = {
  user: PropTypes.shape({
    credits: PropTypes.object,
    social_media: PropTypes.shape({
      credits: PropTypes.number,
      months: PropTypes.number,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
