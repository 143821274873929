import { get, has } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { CheckSquare, ChevronDown, ChevronUp, Square } from 'react-feather';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Button, Collapse } from 'reactstrap';
import { DataLoading, DateFormat, ProductName, VariantAttributes } from '@bottomless/common/components';
import { useConditionalDataEffect, useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { OrderSources } from '@bottomless/common/src/constants';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import {
  fulfillOrdersAction,
  getSummaryAction,
  triggerShopifySmokeTestAction,
  triggerSmokeTestAction,
} from '../../../store/admin/order';
import './Fulfillment.scss';
import { tabs } from './Tabs';

const OrderRow = ({ order, onSelect }) => {
  const [checked, setChecked] = useState(false);

  const userProductVariant = useMemo(
    () =>
      has(order, 'user.product.product.variants')
        ? order.user.product.product.variants.find(variant => variant._id === order.user.product.variant)
        : null,
    [order]
  );

  const subProductVariant = useMemo(
    () =>
      has(order, 'subproduct_id.product.variants')
        ? order.subproduct_id.product.variants.find(variant => variant._id === order.subproduct_id.variant)
        : null,
    [order]
  );

  const productVariant = useMemo(
    () =>
      has(order, 'product_id.product.variants')
        ? order.product_id.product.variants.find(variant => variant._id === order.product_id.variant)
        : null,
    [order]
  );

  const onClick = () => {
    onSelect(order);
    setChecked(!checked);
  };

  const { user } = order;

  return (
    <tr>
      <td>
        <Link
          to={`/admin/ordering_cockpit/${order.user_id}?orderId=${order._id}`}
          className="text-info text-sm"
          target="_blank"
        >
          <div>
            {get(order, 'user.first_name')} {get(order, 'user.last_name')}
          </div>
          <div>source: {order.source}</div>
          <div>status: {order.status}</div>
        </Link>
      </td>
      <td>
        <Badge color="success">
          <VariantAttributes grind={user.grind} productVariant={user.product} hideSize />
        </Badge>
        {userProductVariant && (
          <div>
            <span>{order.user.product.product.name}</span>
            <span className="ml-1">{userProductVariant.size}oz</span>
          </div>
        )}
      </td>
      <td>
        <Badge color="success">
          <VariantAttributes grind={user.grind} productVariant={order.product_id} hideSize />
        </Badge>
        {productVariant && !order.product_id.product.personalized_rotation && (
          <div>
            <span>{order.product_id.product.name}</span>
            <span className="ml-1">{productVariant.size}oz</span>
          </div>
        )}
        {user.personalized && user.personalized.size && order.source !== OrderSources.USER_ONE_OFF && (
          <ProductName product={order.product_id.product} grind={user.grind?.name} personalized={user.personalized} />
        )}
      </td>
      <td>
        {subProductVariant && (
          <>
            <div>
              <span>{order.subproduct_id.product.name}</span>
              <span className="ml-1">{subProductVariant.size}oz</span>
            </div>
            <span className="text-secondary">{order.subproduct_id.product.vendor_name}</span>
          </>
        )}
      </td>
      <td>
        <div>Initiated: {order.date_initiated && <DateFormat date={order.date_initiated} />}</div>
        <div>Updated: {order.updated_at && <DateFormat date={order.updated_at} />}</div>
      </td>
      <td>
        <Button onClick={onClick} color="transparent" className="btn-sm">
          {checked ? <CheckSquare size="15" /> : <Square size="15" />}
        </Button>
      </td>
    </tr>
  );
};

OrderRow.propTypes = {
  onSelect: PropTypes.func.isRequired,
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    firstOrder: PropTypes.bool,
    source: PropTypes.string.isRequired,
    date_initiated: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    grind: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    user: PropTypes.shape({
      personalized: PropTypes.object,
      grind: PropTypes.object,
      product: PropTypes.shape({
        product: PropTypes.shape({
          name: PropTypes.string.isRequired,
          variants: PropTypes.array.isRequired,
        }),
        variant: PropTypes.string,
      }).isRequired,
    }).isRequired,
    product_id: PropTypes.shape({
      product: PropTypes.object,
      variant: PropTypes.string,
    }),
    subproduct_id: PropTypes.shape({
      product: PropTypes.object,
      variant: PropTypes.string,
    }),
  }),
};

const Orders = ({ orders, onSelect }) => {
  return (
    <div className="pt-1">
      <table className="table table-bordered table-hover table-sm m-0 text-sm border-bottom-0">
        <thead>
          <tr>
            <th>Id</th>
            <th>
              <div>User product</div>
              <small>user.product.product</small>
            </th>
            <th>
              <div>Product</div>
              <small>product_id.product</small>
            </th>
            <th>
              <div>Subproduct</div>
              <small>subproduct_id.product</small>
            </th>
            <th>Dates</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, i) => (
            <OrderRow key={i} order={order} onSelect={onSelect} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

Orders.propTypes = {
  orders: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const SummaryLine = ({ vendor, orders, totalOrders, onSelect }) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <div className="border border-bottom-0 w-100">
      <div className="d-flex justify-content-between cursor-pointer" onClick={toggle}>
        <span className="p-2">
          {isOpen ? <ChevronUp /> : <ChevronDown />}
          <span className="ml-2">{vendor.name}</span>
        </span>
        <span className="text-center p-2">{totalOrders}</span>
      </div>

      <Collapse isOpen={isOpen}>
        <Orders orders={orders} onSelect={onSelect} />
      </Collapse>
    </div>
  );
};

SummaryLine.propTypes = {
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  orders: PropTypes.array.isRequired,
  totalOrders: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export const SummaryPageComponent = ({
  getSummary,
  triggerSmokeTest,
  addToast,
  fulfillOrders,
  triggerShopifySmokeTest,
}) => {
  const [summary, setSummary] = useState(null);
  const [selected, setSelected] = useState([]);

  useConditionalDataEffect(!summary, getSummary, setSummary);

  const onSmokeTest = useCallback(() => {
    triggerSmokeTest();
    addToast('Smoke test triggered!', 'success');
  }, [triggerSmokeTest, addToast]);

  const onShopifySmokeTest = useCallback(() => {
    triggerShopifySmokeTest();
    addToast('Shopify Smoke test triggered!', 'success');
  }, [triggerShopifySmokeTest, addToast]);

  const onFulfillOrders = async () => {
    setSelected([]);
    await fulfillOrders({ orders: selected.map(({ _id }) => _id) });
    addToast(`Fulfillment triggered for ${selected.length}!`, 'success');
    setSummary(null);
  };

  const onSelect = order => {
    if (selected.find(s => s._id === order._id)) {
      setSelected(selected.filter(s => s._id !== order._id));
    } else {
      setSelected([...selected, order]);
    }
  };

  return (
    <PanelPage
      title="Fulfillment"
      className="page-fulfillment page-summary"
      tabs={tabs}
      heading={
        <>
          <Button disabled={selected.length === 0} onClick={onFulfillOrders} color="success">
            Fulfill orders ({selected.length})
          </Button>
          <Button onClick={onSmokeTest} color="success">
            Smoke test
          </Button>
          <Button onClick={onShopifySmokeTest} color="success">
            Shopify Smoke test
          </Button>
        </>
      }
    >
      <DataLoading count={1} isLoading={summary === null} />

      {summary && (
        <>
          {Object.keys(summary).map(vendorId => (
            <SummaryLine {...summary[vendorId]} key={vendorId} onSelect={onSelect} />
          ))}

          <div className="d-flex justify-content-between bg-success text-white">
            <span className="text-large p-1">Total</span>
            <span className="text-large p-1">
              {Object.values(summary).reduce((total, v) => total + v.totalOrders, 0)}
            </span>
          </div>
        </>
      )}
    </PanelPage>
  );
};

SummaryPageComponent.propTypes = {
  getSummary: PropTypes.func.isRequired,
  triggerSmokeTest: PropTypes.func.isRequired,
  triggerShopifySmokeTest: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  fulfillOrders: PropTypes.func.isRequired,
};

export const SummaryPage = connect(null, dispatch => ({
  getSummary: () => dispatch(getSummaryAction()),
  triggerSmokeTest: () => dispatch(triggerSmokeTestAction()),
  triggerShopifySmokeTest: () => dispatch(triggerShopifySmokeTestAction()),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  fulfillOrders: data => dispatch(fulfillOrdersAction(data)),
}))(SummaryPageComponent);
