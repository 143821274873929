import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getDisLikedProductsAction } from '../../../store/admin/product';
import { tabs } from './Tabs';
import { Product } from './components/Product';

const DislikedProductsPageComponent = ({ getdislikedProducts }) => {
  const [products, setProducts] = useState(null);
  useDataEffect(getdislikedProducts, setProducts);

  return (
    <PanelPage title="Product feedback" tabs={tabs}>
      <DataLoading count={(products || { length: 0 }).length} isLoading={products === null} />
      {products && products.map(product => <Product product={product} key={product._id} dislike />)}
    </PanelPage>
  );
};

DislikedProductsPageComponent.propTypes = {
  getdislikedProducts: PropTypes.func.isRequired,
};

export const DislikedProductsPage = connect(null, dispatch => ({
  getdislikedProducts: () => dispatch(getDisLikedProductsAction()),
}))(DislikedProductsPageComponent);
