import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getGiftPurchasesAction } from '../../../store/admin/gift';
import { Filters } from './components/Filters';
import { Gift } from './components/Gift';
import './GiftPurchases.scss';

const GiftsPageComponent = ({ getGiftPurchases, addToast, isLoading, giftPurchases, location: { search } }) => {
  useEffect(() => {
    getGiftPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <PanelPage title="Gift Purchases" className="page-admin-gifts">
      <Row className="mb-4">
        <Col>
          <Filters
            isLoading={isLoading}
            hasData={giftPurchases && giftPurchases.docs && giftPurchases.docs.length > 0}
          />
        </Col>
      </Row>

      {giftPurchases && (
        <Row>
          {giftPurchases.docs.map(purchase => (
            <Col xs="12" key={purchase._id}>
              <Gift purchase={purchase} addToast={addToast} />
            </Col>
          ))}
        </Row>
      )}
    </PanelPage>
  );
};

GiftsPageComponent.propTypes = {
  getGiftPurchases: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  giftPurchases: PropTypes.shape({
    docs: PropTypes.array.isRequired,
  }),
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export const GiftsPage = connect(
  ({ adminGifts }) => ({
    giftPurchases: adminGifts.data,
    isLoading: adminGifts.isLoading,
  }),
  (dispatch, { location: { search } }) => ({
    getGiftPurchases: params => dispatch(getGiftPurchasesAction({ ...params, ...qs.parse(search) })),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
  })
)(GiftsPageComponent);
