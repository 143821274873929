import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Editor from 'react-medium-editor';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, DataLoading, Checkbox, Price } from '@bottomless/common/components';
import { useConditionalDataEffect, useOnce } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { renderTemplateAction, getTemplatesAction, sendEmailAction } from '../../store/admin/emails';
import { getUserAction } from '../../store/admin/user';
import { LazyTabs } from '../LazyTab/LazyTab';

const Schema = Yup.object().shape({
  body: Yup.string().required('This field is required'),
  title: Yup.string().required(),
  addMonths: Yup.bool(),
  months: Yup.number(),
});

const fromOptions = {
  'support@bottomless.com': 'support@bottomless.com',
  'yesreply@bottomless.com': 'yesreply@bottomless.com',
  'orders@bottomless.com': 'orders@bottomless.com',
  'alerts@bottomless.com': 'alerts@bottomless.com',
  'operations@bottomless.com': 'operations@bottomless.com',
};

const DEFAULT_EMAIL = '5e943de55e3fcd1bc3ea8952';

const EmailEditorComponent = ({
  isBulk,
  isNotification,
  getTemplates,
  templates,
  getTemplate,
  template,
  onSuccess,
  sendEmail,
  context,
  addToast,
  source,
  getUser,
}) => {
  const [bulkTemplates, setbulkTemplates] = useState([]);
  const [templateLoaded, settemplateLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({
    ...template,
    from: Object.keys(fromOptions)[0],
    addMonths: false,
    months: 1,
  });

  const formRef = useRef();

  const templatesOptions = useMemo(() => templates.reduce((all, { _id, name }) => ({ ...all, [_id]: name }), {}), [
    templates,
  ]);

  const { data: user } = useConditionalDataEffect(!!context.userId, getUser, () => {}, context.userId);

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (template && templatesOptions) {
      if (isBulk) {
        var temp = bulkTemplates.find(el => el.orderId == template.orderId);
        if (!temp) {
          bulkTemplates.push(template);
          if (bulkTemplates.length === context.orderIds.length) {
            setInitialValues({
              ...template,
              email: template._id,
              body: template.content,
              from: Object.keys(fromOptions)[0],
              addMonths: false,
              months: 1,
            });
            settemplateLoaded(true);
          }
        } else {
          var index = bulkTemplates.indexOf(temp);
          bulkTemplates[index] = template;
        }
      } else {
        const initials = {
          ...template,
          email: template._id,
          body: template.content,
          from: Object.keys(fromOptions)[0],
          addMonths: false,
          months: 1,
        };
        if (formRef.current) {
          formRef.current.resetForm(initials);
        }
        setInitialValues(initials);
        settemplateLoaded(true);
      }
    }
  }, [bulkTemplates, context, isBulk, template, templatesOptions]);

  useOnce(async () => {
    const { userId, orderId, orderIds, shipmentId, lastOrderId } = context;
    settemplateLoaded(false);
    if (isBulk && orderIds) {
      setbulkTemplates([]);
      for (const el of context.orderIds) {
        await getTemplate(template ? template._id : DEFAULT_EMAIL, { orderId: el });
      }
    } else if (userId || orderId || shipmentId || lastOrderId) {
      await getTemplate(template ? template._id : DEFAULT_EMAIL, context);
    }
  }, [context]);

  const onTemplateChange = e => {
    if (isBulk) {
      for (const el of context.orderIds) {
        getTemplate(e.target.value, { orderId: el });
      }
      setbulkTemplates([]);
    } else {
      getTemplate(e.target.value, context);
    }
    settemplateLoaded(false);
  };

  const onSendSuccess = data => {
    addToast(`Email sent`, 'success');
    if (onSuccess) {
      onSuccess(data);
    }
  };

  const sendBulkEmail = async data => {
    var res;
    var temp;
    await context.orderIds.forEach((el, index) => {
      temp = bulkTemplates.find(el => el.to === context.userEmails[index]);
      res = sendEmail(
        { orderId: el },
        { ...data, to: temp.to, content: temp.content, body: temp.content, isNotification }
      );
    });
    return res;
  };

  return (
    <>
      <DataLoading count={0} isLoading={!templateLoaded} loadingText={'Loading templates'}>
        {' '}
      </DataLoading>
      {templateLoaded && templatesOptions && (
        <Form
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={({ addMonths, months, ...data }) =>
            isBulk
              ? sendBulkEmail({ ...data, ...(addMonths ? { months } : {}) })
              : sendEmail(context, { ...data, isNotification, ...(addMonths ? { months } : {}) }, source)
          }
          onSuccess={onSendSuccess}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <>
              <Field
                name="email"
                options={templatesOptions}
                type="select"
                onChange={onTemplateChange}
                label="Message"
              />
              <Field name="from" type="select" options={fromOptions} label="From" />
              {!isBulk && <Field name="to" label="To" />}
              <Field name="title" label="Title" />
              <Field name="preheader" label="Preheader" />
              {template && (
                <>
                  <div className="mb-2">
                    <LazyTabs headers={['Email', 'Raw']}>
                      <div className="border border-top-0 py-2 px-2">
                        <Editor
                          text={template.content}
                          onChange={text => setFieldValue('body', text)}
                          options={{
                            toolbar: {
                              buttons: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                                'anchor',
                                'image',
                                'justifyLeft',
                                'justifyCenter',
                                'justifyRight',
                                'justifyFull',
                                'orderedlist',
                                'unorderedlist',
                                'removeFormat',
                                'h2',
                                'h3',
                                'h4',
                              ],
                            },
                            buttonLabels: 'fontawesome',
                            anchor: {
                              targetCheckbox: true,
                            },
                          }}
                        />
                      </div>
                      <>
                        <textarea readOnly className="form-control">
                          {template.raw}
                        </textarea>
                      </>
                    </LazyTabs>
                    <Field name="body" type="hidden" />
                  </div>

                  <div className="mb-3">
                    <span className="d-block">Properties</span>
                    <div className="text-sm d-flex flex-wrap email-help">
                      <kbd>user.local.email</kbd>
                      <kbd>user.first_name</kbd>
                      <kbd>user.last_name</kbd>
                      <kbd>user.phone</kbd>
                      <kbd>user.grind.name</kbd>
                      <kbd>user.scale_status</kbd>
                      <kbd>user.scale_last_connected</kbd>
                      <kbd>user.scale_last_weight</kbd>
                    </div>
                    <span className="d-block">Formatters</span>
                    <div className="text-sm d-flex flex-wrap email-help">
                      <kbd>cents order.amount_paid</kbd>
                      <kbd>dollars order.amount_paid</kbd>
                      <kbd>dateFormat order.date_arrived</kbd>
                      <kbd>fullName user</kbd>
                      <kbd>address user</kbd>
                      <kbd>referral user</kbd>
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="mr-3 mb-3">
                      <Checkbox name="addMonths" label="Add months" />
                    </div>
                    <Field disabled={!values.addMonths} type="number" min={1} name="months" label="Amount" />
                    {user && (
                      <div className="mb-3 ml-3 text-secondary">
                        User&apos;s membership:{' '}
                        <Price value={user.pricing_rule.monthly_fee * user.pricing_rule.batch_size} cents />/
                        {user.pricing_rule.batch_size === 12 ? 'year' : 'month'}
                      </div>
                    )}
                  </div>

                  <SubmitButton color="success" isSubmitting={isSubmitting}>
                    Send
                  </SubmitButton>
                </>
              )}
            </>
          )}
        </Form>
      )}
    </>
  );
};

EmailEditorComponent.propTypes = {
  isBulk: PropTypes.bool,
  isNotification: PropTypes.bool,
  addToast: PropTypes.func.isRequired,
  getTemplates: PropTypes.func.isRequired,
  getTemplate: PropTypes.func.isRequired,
  templates: PropTypes.array,
  template: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    orderId: PropTypes.string,
    content: PropTypes.string.isRequired,
    raw: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  sendEmail: PropTypes.func.isRequired,
  context: PropTypes.shape({
    userId: PropTypes.string,
    shipmentId: PropTypes.string,
    orderId: PropTypes.string,
    lastOrderId: PropTypes.string,
    orderIds: PropTypes.arrayOf(PropTypes.string),
    userEmails: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onSuccess: PropTypes.func,
  source: PropTypes.string,
  getUser: PropTypes.func.isRequired,
};

EmailEditorComponent.defaultProps = {
  isBulk: false,
  isNotification: false,
};

export const EmailEditor = connect(
  ({ adminEmails }) => ({
    template: adminEmails.selectedTemplate,
    templates: adminEmails.templates,
  }),
  dispatch => ({
    getTemplates: () => dispatch(getTemplatesAction()),
    getTemplate: (type, orderId) => dispatch(renderTemplateAction(type, orderId)),
    sendEmail: (context, data, source) => dispatch(sendEmailAction(context, data, source)),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    getUser: id => dispatch(getUserAction(id)),
  })
)(EmailEditorComponent);
