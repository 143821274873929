import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetToDoSubproductSelectionTypes = createAction('Admin subproduct selection', 'Get todo');

export const getToDoSubproductSelectionAction = params => ({
  [RSAA]: {
    endpoint: `admin/subproduct-selection/todo${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetToDoSubproductSelectionTypes.REQUEST,
      GetToDoSubproductSelectionTypes.SUCCESS,
      GetToDoSubproductSelectionTypes.FAILURE,
    ],
  },
});

export const GetPaidSubproductSelectionTypes = createAction('Admin subproduct selection', 'Get paid');

export const getPaidSubproductSelectionAction = params => ({
  [RSAA]: {
    endpoint: `admin/subproduct-selection/paid${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetPaidSubproductSelectionTypes.REQUEST,
      GetPaidSubproductSelectionTypes.SUCCESS,
      GetPaidSubproductSelectionTypes.FAILURE,
    ],
  },
});

export const GetDetailsSubproductSelectionTypes = createAction('Admin subproduct selection', 'Get details');

export const getDetailsSubproductSelectionAction = id => ({
  [RSAA]: {
    endpoint: `admin/subproduct-selection/${id}`,
    method: 'GET',
    types: [
      GetPaidSubproductSelectionTypes.REQUEST,
      GetPaidSubproductSelectionTypes.SUCCESS,
      GetPaidSubproductSelectionTypes.FAILURE,
    ],
  },
});

export const SelectSubproductTypes = createAction('Admin subproduct selection', 'Select subproduct');

export const selectSubproductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/subproduct-selection/${id}`,
    method: 'POST',
    body: data,
    types: [SelectSubproductTypes.REQUEST, SelectSubproductTypes.SUCCESS, SelectSubproductTypes.FAILURE],
  },
});

export const TriggerSubproductSelectionTypes = createAction('Admin subproduct selection', 'Select subproduct');

export const triggerSubproductSelectionAction = (params, data) => ({
  [RSAA]: {
    endpoint: `admin/subproduct-selection/trigger${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'POST',
    body: data,
    types: [
      TriggerSubproductSelectionTypes.REQUEST,
      TriggerSubproductSelectionTypes.SUCCESS,
      TriggerSubproductSelectionTypes.FAILURE,
    ],
  },
});
