import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { getECommerceProductsAction, updateProductAction } from '../../../../store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { ProductVariant } from './components/Product';
import { tabs } from '../Tabs';
import './Products.scss';

const ECommerceProductsPageComponent = ({ getProducts, updateProduct }) => {
  const [productVariants, setProductVariants] = useState(null);

  useDataEffect(getProducts, setProductVariants);

  return (
    <PanelPage title="Products" tabs={tabs}>
      <DataLoading count={(productVariants || { length: 0 }).length} isLoading={productVariants === null}>
        <div className="text-center">
          <span className="d-block mb-3">No products right now.</span>
        </div>
      </DataLoading>
      {productVariants && (
        <>
          {productVariants.map(productVariant => (
            <ProductVariant productVariant={productVariant} key={productVariant._id} updateProduct={updateProduct} />
          ))}
        </>
      )}
    </PanelPage>
  );
};

ECommerceProductsPageComponent.propTypes = {
  getProducts: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
};

export const ECommerceProductsPage = connect(null, dispatch => ({
  getProducts: () => dispatch(getECommerceProductsAction()),
  updateProduct: (id, data) => dispatch(updateProductAction(id, data)),
}))(ECommerceProductsPageComponent);
