import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { debounce } from 'lodash-es';
import { Card, CardBody, Label } from 'reactstrap';
import { Form, Field, SubmitButton, Radio } from '@bottomless/common/components';
import { useConditionalDataEffect } from '@bottomless/common/hooks';

const Schema = Yup.object().shape({
  referrer: Yup.string().required(),
});

export const ReferralForm = ({ user, searchUser, onSubmit, onSuccess }) => {
  const [search, setSearch] = useState();
  const [users, setUsers] = useState({ docs: [] });

  useConditionalDataEffect(search, searchUser, setUsers, { search });

  const handleSubmit = useCallback(data => onSubmit(user._id, data), [onSubmit, user]);

  return (
    <Form
      initialValues={{ referrer: '' }}
      validationSchema={Schema}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values }) => (
        <>
          <div className="mb-3">This user is referred by:</div>
          <Field
            className="w-100 up-label"
            name="search"
            label="Referrer"
            placeholder="Search by id, email, first and last name"
            onChange={debounce(event => {
              setSearch(event.target.value);
            }, 1000)}
          />
          { users && users.docs && users.docs.map(referrer => (
            <Card key={referrer._id} className="mb-3" outline color={values.referrer === referrer._id ? 'success' : undefined}>
              <Label className="clickable">
                <CardBody className="referral-radio">
                  <div className="d-none">
                    <Radio name="referrer" type="radio" value={referrer._id} />
                  </div>
                  <div>{referrer.first_name} {referrer.last_name}</div>
                  <div>{referrer.local.email}</div>
                </CardBody>
              </Label>
            </Card>
          ))}
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

ReferralForm.propTypes = {
  searchUser: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
};
