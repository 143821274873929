import { get, has } from 'lodash-es';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { CheckSquare, ChevronDown, ChevronUp, Square } from 'react-feather';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Badge, Button, ButtonGroup, Card, Col, Collapse, Row } from 'reactstrap';
import { Address, Price, DateFormat, Form, Field, SubmitButton } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { createSelectOptions } from '@bottomless/common/src/utils';

const color = status =>
  ({
    canceled: 'danger',
    failure: 'danger',
    arrived: 'success',
    shipped: 'warning',
    created: 'info',
    requested: 'primary',
  }[status] || 'secondary');

const Schema = Yup.object().shape({
  scaleDeviceId: Yup.string().required('This field is required'),
});

export const ShipmentComponent = ({
  shipment,
  onEdit,
  syncStatus,
  purchaseScaleShipment,
  onSelect,
  onBatchClick,
  selected,
  setShipmentToUpdate,
  toggleUppdateTrackingModal,
  scaleDevices,
}) => {
  const [checked, setChecked] = useState(false);
  const [isOpen, toggle] = useToggle();

  const { user_id: user, firstOrder } = shipment;

  const scaleDeviceOptions = useMemo(
    () => ({ '': '--Choose scale device type--', ...createSelectOptions(scaleDevices) }),
    [scaleDevices]
  );

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  const onSubmit = useCallback(
    async data => {
      await purchaseScaleShipment(shipment._id, data);
    },
    [purchaseScaleShipment, shipment._id]
  );

  const onClick = e => {
    e.stopPropagation();
    setChecked(!checked);
    onSelect(shipment);
  };

  const firstOrderDate = has(firstOrder, 'override_fulfillment_date')
    ? get(firstOrder, 'override_fulfillment_date')
    : get(firstOrder, 'date_fulfilled');

  return (
    <Card className="mb-2" body>
      <div onClick={toggle} className="d-flex align-items-center cursor-pointer">
        <div className="d-flex flex-row w-100">
          <div>
            <div className="text-secondary">#{shipment._id}</div>
            <div className="text-sm text-secondary">
              <a
                href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${shipment.tracking_number}`}
                rel="noopener noreferrer"
                target="_blank"
                className="text-primary d-block"
              >
                {get(shipment, 'tracking_number', '---')}
              </a>
            </div>
          </div>
          <div className="ml-3">
            {has(shipment, 'vendor_id.name') && (
              <div className="ml-1">
                <Badge color="warning">Fulfilled By: {get(shipment, 'vendor_id.name')}</Badge>
              </div>
            )}
            {!user && <Badge color="danger">USER DELETED</Badge>}
            {user?.is_temp_user && <Badge color="danger">TEMP USER</Badge>}
            {user && (
              <>
                <span>
                  {user.is_temp_user && (
                    <div className="text-primary">
                      {user.first_name} {user.last_name}
                    </div>
                  )}
                  {!user.is_temp_user && (
                    <Link to={`/admin/ordering_cockpit/${user._id}`}>
                      <span className="text-primary">
                        {user.first_name} {user.last_name}
                      </span>
                    </Link>
                  )}
                </span>
              </>
            )}
          </div>
          <div className="ml-auto">
            <div className={classNames({ 'text-success': firstOrderDate })}>
              First Order: {firstOrderDate ? <DateFormat withTime date={firstOrderDate} correctTimezone /> : '---'}
            </div>
            <div>Updated at: {shipment.updated_at ? <DateFormat withTime date={shipment.updated_at} /> : '---'}</div>
          </div>
          <div className="ml-3">
            <p className="mb-0 d-inline-blo ck fill-200">Type: {shipment.type}</p>
            <p className="mb-0">
              Status: <span className={`font-weight-bold text-${color(shipment.status)}`}>{shipment.status}</span>
            </p>
            {shipment.usps_tracking && (
              <div className="usps-status cursor-pointer">
                New USPS status:{' '}
                <Badge
                  color={classNames({
                    danger: ['alert', 'return_to_sender'].includes(shipment.usps_tracking.status),
                    success: shipment.usps_tracking.status === 'delivered',
                    primary: ['accepted', 'in_transit'].includes(shipment.usps_tracking.status),
                    warning: shipment.usps_tracking.status === 'pre_transit',
                    secondary: shipment.usps_tracking.status === 'unknown',
                  })}
                >
                  {get(shipment, 'usps_tracking.status', '---')}
                </Badge>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-row ml-auto pl-4">
          <div>
            {['requested', 'created'].includes(shipment.status) ? (
              <Button onClick={onClick} color="transparent" className="btn-sm">
                {checked ? <CheckSquare size="15" /> : <Square size="15" />}
              </Button>
            ) : (
              <span className="btn btn-spacer" />
            )}
          </div>
          <div className="flex-grow-0 ml-3">{isOpen ? <ChevronUp size="15" /> : <ChevronDown size="15" />}</div>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <Row className="pt-4 border-top">
          <Col xs="6" md="4">
            <p className="mb-0">Type: {shipment.type}</p>
            {has(shipment, 'reason') && <p className="mb-0 text-info">Reason: {get(shipment, 'reason', '---')}</p>}
            <p className="mb-0">Status: {shipment.status}</p>
            <p className="mb-0">Shipping status: {get(shipment, 'shipping_status', '---')} </p>
            <p className="mb-0">
              Tracking:{' '}
              <a
                href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${shipment.tracking_number}`}
                rel="noopener noreferrer"
                target="_blank"
                className="text-primary"
              >
                {get(shipment, 'tracking_number', '---')}
              </a>
            </p>
            <p className="mb-0">
              Batch:{' '}
              <span onClick={() => shipment.batch_id && onBatchClick(shipment.batch_id)} className="text-primary">
                {get(shipment, 'batch_id', '---')}
              </span>
            </p>
            <p className="mb-0">Tracker id: {get(shipment, 'easypost_id', '---')}</p>
            {shipment.label_url && (
              <p className="mb-0">
                Shipping label:{' '}
                <a href={shipment.label_url} rel="noopener noreferrer" target="_blank" className="text-primary">
                  label
                </a>
              </p>
            )}
            <p className="mb-0">Service: {get(shipment, 'service', '---')}</p>
            <p className="mb-0">Cost: {shipment.shipping_cost ? <Price value={shipment.shipping_cost} /> : '---'}</p>
            <p className="mb-0">
              Created at: <DateFormat withTime date={shipment.timestamp} />
            </p>
            <p className="mb-0">
              Updated at: {shipment.updated_at ? <DateFormat withTime date={shipment.updated_at} /> : '---'}
            </p>
            <p className="mb-0">Scale Device: {get(shipment, 'scale_device_id.name', '---')}</p>
          </Col>
          <Col xs="6" md="4">
            {!user && <Badge color="danger">USER DELETED</Badge>}
            {user && (
              <>
                <div className="text-secondary">
                  <span className="d-block">
                    <Link to={`/admin/ordering_cockpit/${user._id}`}>#{user._id}</Link>
                  </span>
                  <span className="d-block text-primary mb-2">
                    <a
                      href={`https://app.frontapp.com/compose?mailto=mailto:${user?.local?.email}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-primary"
                    >
                      {user?.local?.email}
                    </a>
                  </span>
                </div>
                <div>
                  {user.first_name} {user.last_name}
                  {user.verifiedAddress && <Address address={user.verifiedAddress} />}
                </div>
              </>
            )}

            {firstOrder && user && (
              <div className="mt-3">
                <strong>First order</strong>
                <div>
                  <Link
                    to={`/admin/ordering_cockpit/${user._id}?orderId=${firstOrder._id}&back=admin/scale-shipmentsv2/`}
                    className="text-info text-sm"
                  >
                    {firstOrder._id} / {firstOrder.status}
                  </Link>
                </div>
                <div className="text-success">
                  {has(firstOrder, 'override_fulfillment_date') && (
                    <div>
                      <span>Override fulfillment date: </span>
                      <DateFormat date={get(firstOrder, 'override_fulfillment_date')} withTime />
                    </div>
                  )}

                  <div>
                    <span>Fullfiled: </span>
                    {firstOrder.date_fulfilled ? <DateFormat date={firstOrder.date_fulfilled} withTime /> : '---'}
                  </div>

                  {get(firstOrder, 'shipping_status', '---')}
                </div>
              </div>
            )}
          </Col>
          <Col xs="6" md="4">
            <ButtonGroup vertical>
              {['requested', 'created'].includes(shipment.status) && (
                <Button
                  size="sm"
                  className="mb-2"
                  color="danger"
                  onClick={() => onEdit({ ...shipment, status: 'canceled' })}
                >
                  Cancel shipment
                </Button>
              )}
              <Button
                size="sm"
                className="mb-2"
                color="success"
                onClick={async () => {
                  setShipmentToUpdate(shipment);
                  toggleUppdateTrackingModal();
                }}
              >
                Update Tracking
              </Button>

              {!['requested', 'canceled'].includes(shipment.status) && (
                <>
                  {shipment.status === 'created' && (
                    <Button
                      size="sm"
                      className="mb-2"
                      color="primary"
                      onClick={() => onEdit({ ...shipment, status: 'shipped' })}
                    >
                      Change status to shipped
                    </Button>
                  )}
                  {shipment.status === 'shipped' && (
                    <Button
                      size="sm"
                      className="mb-2"
                      color="success"
                      onClick={() => onEdit({ ...shipment, status: 'arrived' })}
                    >
                      Change status to arrived
                    </Button>
                  )}
                </>
              )}
              {shipment.tracking_number && (
                <Button size="sm" color="info" onClick={() => syncStatus(shipment.tracking_number)}>
                  Sync tracking status
                </Button>
              )}
              {shipment.status === 'requested' && (
                <Form
                  initialValues={{ scaleDeviceId: shipment.scale_device_id?._id }}
                  validationSchema={Schema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <>
                      <Field name="scaleDeviceId" type="select" label="Scale Device" options={scaleDeviceOptions} />
                      <SubmitButton size="sm" className="mb-2" color="warning" isSubmitting={isSubmitting}>
                        Buy shipping label
                      </SubmitButton>
                    </>
                  )}
                </Form>
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </Collapse>
    </Card>
  );
};

ShipmentComponent.defaultProps = {
  selected: false,
};

ShipmentComponent.propTypes = {
  shipment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    scale_device_id: PropTypes.string,
    batch_id: PropTypes.string,
    easypost_id: PropTypes.string,
    status: PropTypes.string,
    service: PropTypes.string,
    type: PropTypes.string,
    shipping_cost: PropTypes.number,
    label_url: PropTypes.string,
    shipping_status: PropTypes.string,
    tracking_number: PropTypes.string,
    timestamp: PropTypes.string,
    updated_at: PropTypes.string,
    scheduled_at: PropTypes.string,
    usps_tracking: PropTypes.object,
    firstOrder: PropTypes.shape({
      _id: PropTypes.string,
      date_fulfilled: PropTypes.string,
      status: PropTypes.string,
    }),
    user_id: PropTypes.shape({
      _id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      local: PropTypes.object,
      verifiedAddress: PropTypes.object,
      is_temp_user: PropTypes.bool,
    }),
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  syncStatus: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onBatchClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  purchaseScaleShipment: PropTypes.func.isRequired,
  setShipmentToUpdate: PropTypes.func.isRequired,
  toggleUppdateTrackingModal: PropTypes.func.isRequired,
  scaleDevices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
