import React, { memo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToastAction } from '@bottomless/common/store';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { updateUserShopifyNoteAction } from '../../store/admin/user';

const Schema = Yup.object().shape({
  shopifyNote: Yup.string().max(4000),
});

const ShopifyNoteComponent = ({ user, addToast, updateUserShopifyNote, onCreate }) => {
  const handleSuccess = note => {
    if (onCreate) {
      onCreate(note);
    }

    addToast(`Shopify Note for user ${user.first_name} ${user.last_name} has been updated.`);
  };

  return (
    <Form
      initialValues={{ shopifyNote: user.shopifyNote }}
      onSubmit={updateUserShopifyNote}
      onSuccess={handleSuccess}
      validationSchema={Schema}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="shopifyNote" type="textarea" label="Content" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

ShopifyNoteComponent.propTypes = {
  updateUserShopifyNote: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  addToast: PropTypes.func.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    shopifyNote: PropTypes.string,
  }),
};

export const ShopifyNote = connect(null, (dispatch, { user }) => ({
  addToast: message => dispatch(addToastAction(message)),
  updateUserShopifyNote: data => dispatch(updateUserShopifyNoteAction(user._id, data)),
}))(memo(ShopifyNoteComponent));
