import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CopyToClipboard as CopyToClipboardExternal } from 'react-copy-to-clipboard';
import { useInterval } from '../../hooks/use-interval';
import './CopyToClipboard.scss';

export const CopyToClipboard = ({ text, copyText = 'Copy', copiedText = 'Copied!', simple }) => {
  const [copied, setCopied] = useState(false);

  useInterval(() => setCopied(false), copied ? 1000 : null);

  return (
    <CopyToClipboardExternal text={text} onCopy={() => setCopied(true)}>
      {simple ? (
        <div className={`cursor-pointer text-underline${copied ? ' text-success' : ''}`}>
          {copied ? copiedText : copyText}
        </div>
      ) : (
        <button className={`btn btn-sm btn-${copied ? 'success' : 'dark'} btn-copy`}>
          {copied ? copiedText : copyText}
        </button>
      )}
    </CopyToClipboardExternal>
  );
};

CopyToClipboard.propTypes = {
  text: PropTypes.string,
  copyText: PropTypes.string,
  copiedText: PropTypes.string,
  simple: PropTypes.bool,
};
