import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  tracking_number: Yup.string(),
  shipping_service: Yup.string(),
  date_arrived: Yup.string(),
});

export const EditForm = ({ order, updateOrder, toggleModal, setIsArrived, addToast, setOrder }) => {
  const handleSubmit = useCallback(
    async data => {
      const { payload } = await updateOrder(order._id, data);
      toggleModal();
      setOrder(prevState => ({
        ...prevState,
        tracking_number: payload.tracking_number,
        shipping_service: payload.shipping_service,
        date_arrived: payload.date_arrived,
      }));
      if (payload.date_arrived) {
        setIsArrived(true);
      }
      addToast(`Order ${order._id} updated`);
    },
    [addToast, order, setIsArrived, setOrder, toggleModal, updateOrder]
  );

  return (
    <Form initialValues={{ ...order }} validationSchema={Schema} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <>
          <Field name="tracking_number" type="text" label="Tracking number" />
          <Field name="shipping_service" type="text" label="Shipping service" />
          <Field type="date" name="date_arrived" label="Date arrived" showTime={true} format="MM/DD/YYYY h:mm a" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Accept
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

EditForm.propTypes = {
  product: PropTypes.object,
  order: PropTypes.object,
  updateOrder: PropTypes.func,
  toggleModal: PropTypes.func,
  setIsArrived: PropTypes.func,
  addToast: PropTypes.func,
  setOrder: PropTypes.func,
};
