import * as moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from 'react-feather';
import { Col, Row, Button } from 'reactstrap';
import { getTrackingLink, isWholeNumber } from '@bottomless/common/utils';
import { DateFormat, Battery } from '@bottomless/common/components';

const ScaleConnected = status => (
  <>
    <i className="scale__circle" />
    {status.scale_status} {(status.scale_last_connected || status.scale_last_weight) && ' - '}
    {status.scale_last_connected && (
      <span>
        {' '}
        <DateFormat date={status.scale_last_connected} toSeattle />
      </span>
    )}
    {isWholeNumber(status.scale_last_weight) && (
      <> - Last weight reported {parseFloat(status.scale_last_weight).toFixed(2)}oz</>
    )}
  </>
);

const ScaleDisconnected = status => (
  <>
    <i className="scale__circle" />
    {status.scale_status} {(status.scale_last_connected || status.scale_last_weight) && ' - '}
    {status.scale_last_connected && (
      <span>
        {' '}
        since <DateFormat date={status.scale_last_connected} toSeattle />
      </span>
    )}
    {status.scale_last_weight && (
      <> with the last weight reporting at {parseFloat(status.scale_last_weight).toFixed(2)}oz</>
    )}
  </>
);

const ScalePending = () => (
  <div className="d-flex align-items-center">
    <i className="scale__circle" />
    <span className="mr-1">connected</span>
    <span>Your data is refreshing, it can take several hours</span>
    <span>
      <Loader size="16" className="spin ml-2" />
    </span>
  </div>
);

export const ScaleStatus = ({
  status,
  withHeader,
  userStatus,
  toggleModal,
  isScaleAssistanceRequestPending,
  hasRequestedScaleSupport,
}) => {
  return (
    <section className={`scale__status scale__status--${status.scale_status}`}>
      {withHeader && (
        <>
          <h4 className="mb-3">
            Scale status{' '}
            <Battery hide={status.scale_status !== 'connected'} batteryLevel={status.scale_battery_level} />{' '}
            <Button
              className={`request-assistance-btn ${hasRequestedScaleSupport ? ' disabled' : ''}`}
              color="info"
              onClick={toggleModal}
              disabled={hasRequestedScaleSupport}
            >
              {isScaleAssistanceRequestPending ? 'Requesting...' : 'Request Scale Assistance'}
            </Button>
          </h4>
          <p className="instruction-text">
            For FAQs and troubleshooting steps on scales, click{' '}
            <a href="https://support.bottomless.com/en/categories/236864" target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </p>
        </>
      )}
      {userStatus === 'scale_replaced' && status.lastReplacement && (
        <>
          <Row className="mb-3">
            <Col xs="12">
              <div>
                <b>Replaced on:</b> {moment(status?.lastReplacement?.created_at).format('MM/DD/YYYY')}
              </div>
              {status.lastReplacement.service && status.lastReplacement.tracking_number && (
                <div>
                  <b>Tracking link:</b>{' '}
                  <a
                    href={getTrackingLink(status.lastReplacement.service, status.lastReplacement.tracking_number)}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary"
                  >
                    {status.lastReplacement.tracking_number}
                  </a>
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
      <Row className="mb-3">
        <Col xs="12">
          {status.scale_status === 'pending' && ScalePending(status)}
          {status.scale_status === 'connected' && ScaleConnected(status)}
          {status.scale_status === 'disconnected' && ScaleDisconnected(status)}
        </Col>
      </Row>
    </section>
  );
};

ScaleStatus.propTypes = {
  userStatus: PropTypes.string,
  status: PropTypes.shape({
    scale_status: PropTypes.string.isRequired,
    scale_last_connected: PropTypes.string,
    scale_last_weight: PropTypes.number,
    scale_battery_level: PropTypes.number,
    lastReplacement: PropTypes.object,
  }).isRequired,
  withHeader: PropTypes.bool,
  toggleModal: PropTypes.func,
  isScaleAssistanceRequestPending: PropTypes.bool,
  hasRequestedScaleSupport: PropTypes.bool,
};

ScaleStatus.defaultProps = {
  withHeader: true,
  toggleModal: () => {},
  isScaleAssistanceRequestPending: false,
};
