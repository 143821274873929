import React from 'react';
import PropTypes from 'prop-types';
import { connectWithAbort } from '@bottomless/common/store';
import { getNeedSupportOrderGenAction } from '../../../../store/admin/ordergen';
import { PaginatedOrderGenPage } from './PaginatedOrderGen';

const NEEDS_SUPPORT_DATE_QUERY = 'support.order.date';

const NeedSupportOrderGenPageComponent = ({ getNeedSupport }) => (
  <PaginatedOrderGenPage getUsers={getNeedSupport} isNeedsSupportTab />
);

NeedSupportOrderGenPageComponent.propTypes = {
  getNeedSupport: PropTypes.func.isRequired,
};

export const NeedSupportOrderGenPage = connectWithAbort(null, dispatch => ({
  getNeedSupport: params => dispatch(getNeedSupportOrderGenAction({ sort: NEEDS_SUPPORT_DATE_QUERY, ...params })),
}))(NeedSupportOrderGenPageComponent);
