import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { connectWithAbort, addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { setNeedsSupportAction } from '../../../store/admin/user';
import { getCustomerNotesAction } from '../../../store';
import { tabs } from './Tabs';
import { Order } from '../Fulfillment/components/Order';
import { User } from './components/User';
import './Problems.scss';

const Problems = ({
  getOrders,
  withReport,
  products,
  onReplaceOrder: originalReplaceOrder,
  resolveProblem,
  addToast,
  isScaleProblemsTab,
  setNeedsSupport,
  getCustomerNotes,
  getNeedSupportForScale,
}) => {
  const [orders, setOrders] = useState(null);
  const [users, setUsers] = useState(null);

  useDataEffect(getOrders, setOrders);
  const { isFetching: isFetchingUsers } = useDataEffect(getNeedSupportForScale, setUsers);

  const onReplaceOrder = useCallback(
    async (id, data) => {
      const res = await originalReplaceOrder(id, data);
      setOrders(orders.filter(order => order._id !== id));
      return res;
    },
    [originalReplaceOrder, orders, setOrders]
  );

  const resolveReportedProblem = useCallback(
    async id => {
      const res = await resolveProblem(id);
      setOrders(orders.filter(order => order._id !== id));
      return res;
    },
    [resolveProblem, orders, setOrders]
  );

  const onNeedsSupport = useCallback(
    user => async ({ needs_support: needsSupport }) => {
      if ((!needsSupport && isScaleProblemsTab) || (needsSupport && !isScaleProblemsTab)) {
        setUsers({ ...users, docs: users.docs.filter(u => u._id !== user._id) });
        return;
      }

      try {
        const { payload: notes } = await getCustomerNotes(user._id);
        setUsers({
          ...users,
          docs: users.docs.map(u => (u._id !== user._id ? u : { ...u, notes, support: { scale: { needsSupport } } })),
        });
      } catch (e) {
        addToast(`Error during fetching customer notes: ${e.message}`);
      }
    },
    [isScaleProblemsTab, users, getCustomerNotes, addToast]
  );

  return (
    <PanelPage title="Problems" withUpNext={false} className="page-problems" tabs={tabs}>
      {!isScaleProblemsTab && (
        <>
          <DataLoading count={(orders || []).length} isLoading={orders === null} />
          {orders &&
            orders.map(order => (
              <Order
                key={order._id}
                order={order}
                withReport={withReport}
                products={products}
                onReplaceOrder={onReplaceOrder}
                resolveReported={resolveReportedProblem}
                addToast={addToast}
              />
            ))}
        </>
      )}
      {isScaleProblemsTab ? (
        <>
          <DataLoading count={((users && users.docs) || []).length} isLoading={Boolean(isFetchingUsers)} />
          {users && users.docs && (
            <>
              {users.docs.map(user => (
                <User
                  user={user}
                  key={user._id}
                  addToast={addToast}
                  setNeedsSupport={data => setNeedsSupport(user._id, data)}
                  onNeedsSupport={onNeedsSupport(user)}
                />
              ))}
            </>
          )}
        </>
      ) : (
        ''
      )}
    </PanelPage>
  );
};

Problems.propTypes = {
  getOrders: PropTypes.func,
  products: PropTypes.array,
  withReport: PropTypes.bool,
  onReplaceOrder: PropTypes.func,
  resolveProblem: PropTypes.func,
  addToast: PropTypes.func.isRequired,
  isScaleProblemsTab: PropTypes.bool,
  setNeedsSupport: PropTypes.func.isRequired,
  getCustomerNotes: PropTypes.func.isRequired,
  getNeedSupportForScale: PropTypes.func,
};

Problems.defaultProps = {
  getOrders: () => {},
  onReplaceOrder: () => {},
  resolveProblem: () => {},
  withReport: false,
  products: [],
  isScaleProblemsTab: false,
  getNeedSupportForScale: () => {},
};

export const ProblemsPage = connectWithAbort(null, dispatch => ({
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  setNeedsSupport: (id, data) => dispatch(setNeedsSupportAction(id, data)),
  getCustomerNotes: (id, data) => dispatch(getCustomerNotesAction(id, data)),
}))(Problems);
