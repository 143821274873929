import PropTypes from 'prop-types';
import React from 'react';
import { connectWithAbort } from '@bottomless/common/store';
import { prefetchAiDecisionOrderGenAction } from '../../../../store/admin/ordergen';
import { MainOrderGenPage } from './OrderGen';

const AiDecisionOrderGenComponent = props => (
  <MainOrderGenPage {...props} triggerGenerationCreateOrder={false} automationName="AiLuke" />
);

AiDecisionOrderGenComponent.propTypes = {
  prefetchUsers: PropTypes.func.isRequired,
};

export const AiDecisionOrderGenPage = connectWithAbort(null, dispatch => ({
  prefetchUsers: params => dispatch(prefetchAiDecisionOrderGenAction({ ...params, async: true })),
}))(AiDecisionOrderGenComponent);
