import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Button, Input } from 'reactstrap';
import { DataLoading, DateFormat, Price } from '@bottomless/common/components';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import {
  getUserChargesAction,
  refundOrderChargesAction,
  refundMembershipChargesAction,
} from '../../../../../store/admin/user/user.actions';
import { OrderRefundModal } from './OrderRefundModal';
import { MembershipRefundModal } from './MembershipRefundModal';

const MEMBERSHIP_STATUSES = {
  PAID: 'paid',
  REFUNDED: 'refunded',
};

const ORDER_STATUSES = {
  REFUNDED: 'refunded',
};

const formatCents = cents => `$${(cents / 100).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const UserChargeComponent = ({ user, getUserCharges, refundOrderCharges, refundMembershipCharges, addToast }) => {
  const [charges, setCharges] = useState(null);
  const [hasMembershipCharges, setHasMembershipCharges] = useState(false);
  const [selectedMembershipCharges, setSelectedMembershipCharges] = useState([]);
  const [isOrderRefundModalOpen, setIsOrderRefundModalOpen] = useState(false);
  const [isMembershipRefundModalOpen, setIsMembershipRefundModalOpen] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);

  useConditionalDataEffect(
    !charges,
    getUserCharges,
    data => {
      setCharges(data);
    },
    user._id
  );

  useEffect(() => {
    if (charges && charges.some(charge => charge.type === 'membership')) {
      setHasMembershipCharges(true);
    } else {
      setHasMembershipCharges(false);
    }
  }, [charges && charges.length]);

  const checkMembershipCharge = (e, charge) => {
    if (e.target.checked && !selectedMembershipCharges.some(c => c.id === charge.id)) {
      setSelectedMembershipCharges([...selectedMembershipCharges, charge]);
    } else if (selectedMembershipCharges.some(c => c.id === charge.id)) {
      const index = selectedMembershipCharges.indexOf(charge);
      selectedMembershipCharges.splice(index, 1);
      setSelectedMembershipCharges([...selectedMembershipCharges]);
    }
  };

  const toggleOrderRefundModal = charge => {
    if (charge) {
      setSelectedCharge(charge);
    } else {
      setSelectedCharge(null);
    }
    setIsOrderRefundModalOpen(!isOrderRefundModalOpen);
  };

  const toggleMembershipRefundModal = () => {
    setIsMembershipRefundModalOpen(!isMembershipRefundModalOpen);
  };

  const refundOrder = useCallback(data => refundOrderCharges(user._id, data.id, data), [user]);

  const onRefundOrderSuccess = useCallback(() => {
    addToast('Order refund successful');
    toggleOrderRefundModal();
    setCharges(null);
  }, [addToast, toggleOrderRefundModal]);

  const refundMembership = useCallback(data => refundMembershipCharges(user._id, data), [user]);

  const onRefundMembershipSuccess = useCallback(() => {
    addToast('Membership refund successful');
    toggleMembershipRefundModal();
    setSelectedMembershipCharges([]);
    setCharges(null);
  }, [addToast, toggleMembershipRefundModal]);

  const renderActionButton = charge => {
    if (charge.status === 'refunded') return <p className="refunded-text">Refunded</p>;
    if (charge.type === 'order') {
      return (
        <Button
          outline={charge.status !== ORDER_STATUSES.REFUNDED}
          size="sm"
          type="button"
          color="danger"
          disabled={charge.status === ORDER_STATUSES.REFUNDED}
          onClick={() => toggleOrderRefundModal(charge)}
        >
          {charge.status === ORDER_STATUSES.REFUNDED ? 'Refunded' : 'Refund'}
        </Button>
      );
    } else {
      return (
        <Input
          id={`${charge.id}-membership`}
          value={charge.id}
          type="checkbox"
          onChange={e => checkMembershipCharge(e, charge)}
        />
      );
    }
  };

  return (
    <>
      <DataLoading count={charges ? charges.length : 0} isLoading={charges === null}>
        <div>Charges is empty</div>
      </DataLoading>
      {hasMembershipCharges ? (
        <div className="d-flex align-items-center justify-content-end mb-1 membership-refund">
          <p>Select at least one membership record and click on button to refund membership charges</p>
          <Button
            outline
            size="sm"
            type="button"
            color="warning"
            onClick={toggleMembershipRefundModal}
            disabled={!selectedMembershipCharges || (selectedMembershipCharges && !selectedMembershipCharges.length)}
          >
            Refund Membership
          </Button>
        </div>
      ) : (
        ''
      )}
      {charges && charges.length > 0 && (
        <Table size="sm">
          <thead>
            <tr>
              <th>Charge Type</th>
              <th>Id</th>
              <th>Order Status</th>
              <th>Date Charged</th>
              <th>Total Paid</th>
              <th>Amount Paid</th>
              <th>Tax Paid</th>
              <th>Shipment Paid</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {charges.map(charge => (
              <tr key={charge.id}>
                <td>{charge.type}</td>
                <td>{charge.id}</td>
                <td>{charge.type === 'order' ? charge.status || '-' : charge.status || MEMBERSHIP_STATUSES.PAID}</td>
                <td>
                  <DateFormat date={charge.date_charged} />
                </td>
                <td>
                  <Price value={charge.total_paid} cents />
                </td>
                <td>{charge.type === 'order' ? <Price value={charge.amount_paid} cents /> : '-'}</td>
                <td>{charge.type === 'order' ? <Price value={charge.tax_paid} cents /> : '-'}</td>
                <td>{charge.type === 'order' ? <Price value={charge.shipment_paid} cents /> : '-'}</td>
                <td className={charge.type === 'membership' ? 'membership-action text-center' : 'text-center'}>
                  {renderActionButton(charge)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {isOrderRefundModalOpen && selectedCharge ? (
        <OrderRefundModal
          isOrderRefundModalOpen={isOrderRefundModalOpen}
          toggleOrderRefundModal={toggleOrderRefundModal}
          charge={selectedCharge}
          refundOrder={refundOrder}
          onRefundOrderSuccess={onRefundOrderSuccess}
          formatCents={formatCents}
        />
      ) : (
        ''
      )}
      {isMembershipRefundModalOpen && selectedMembershipCharges && selectedMembershipCharges.length ? (
        <MembershipRefundModal
          isMembershipRefundModalOpen={isMembershipRefundModalOpen}
          toggleMembershipRefundModal={toggleMembershipRefundModal}
          charges={selectedMembershipCharges}
          refundMembership={refundMembership}
          onRefundMembershipSuccess={onRefundMembershipSuccess}
          formatCents={formatCents}
        />
      ) : (
        ''
      )}
    </>
  );
};

UserChargeComponent.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  getUserCharges: PropTypes.func.isRequired,
  refundOrderCharges: PropTypes.func.isRequired,
  refundMembershipCharges: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const UserCharge = connect(null, dispatch => ({
  addToast: message => dispatch(addToastAction(message)),
  getUserCharges: userId => dispatch(getUserChargesAction(userId)),
  refundOrderCharges: (userId, orderId, data) => dispatch(refundOrderChargesAction(userId, orderId, data)),
  refundMembershipCharges: (userId, data) => dispatch(refundMembershipChargesAction(userId, data)),
}))(UserChargeComponent);
