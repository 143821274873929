import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataLoading } from '@bottomless/common/src/components';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getTemplatesAction } from '../../../store/admin/emails';
import { TemplateCard } from './components';
import { tabs } from './Tabs';

export const EmailsPageComponent = ({ getTemplates, templates, isLoading }) => {
  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      <PanelPage
        title="Emails"
        tabs={tabs}
        heading={
          <>
            <Link to="/admin/emails/create" className="btn btn-success">
              Add template
            </Link>
          </>
        }
      >
        <DataLoading count={templates?.length || 0} isLoading={isLoading} />
        <div>{templates && templates.map(template => <TemplateCard template={template} key={template._id} />)}</div>
      </PanelPage>
    </>
  );
};

EmailsPageComponent.propTypes = {
  getTemplates: PropTypes.func.isRequired,
  templates: PropTypes.array,
  enabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export const EmailsPage = connect(
  ({ adminEmails }) => ({
    templates: adminEmails.templates,
    isLoading: adminEmails.isLoading,
  }),
  (dispatch, { enabled }) => ({
    getTemplates: () => dispatch(getTemplatesAction(enabled)),
  })
)(EmailsPageComponent);
