import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { addToastAction } from '@bottomless/common/store';
import { tabs } from './components/Tabs';
import { useDataEffect } from '@bottomless/common/hooks';
import { DataLoading, SubmitButton } from '@bottomless/common/components';
import { getLeadsAction, updateLeadsAction } from '../../../../store/admin/vendor';
import { VendorFulfillmentDays } from '../../../../components/VendorFulfillmentDays/VendorFulfillmentDays';
import { ButtonGroup, Card, CardTitle, Col, CardBody, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import './VendorLead.scss';

const Lead = ({ lead, updateLeads, addToast, setUpdate, update }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const updateLead = async (_id, body) => {
    setSubmitting(true);
    try {
      await updateLeads(_id, body);
      addToast('Status of lead updated!');
    } catch (error) {
      addToast('Unable to update status!');
    }
    setUpdate(!update);
    setSubmitting(false);
  };

  const getUrl = website => {
    let url = website?.replace('https://', '').replace('http://', '');
    return `//${url}`;
  };

  return (
    <Card body className="mb-4 lead-card">
      <CardTitle className="d-flex justify-content-between border-bottom mb-1 p-2 font-weight-bold">
        <span>
          {lead.company} / {lead.contact_name} / {lead.type}
        </span>
        <ButtonGroup size="sm">
          <SubmitButton
            onClick={() => updateLead(lead._id, { newStatus: 'invited' })}
            color="success"
            className="mr-2 "
            isSubmitting={isSubmitting}
            loadingText="Updating"
          >
            Invite
          </SubmitButton>
          <SubmitButton
            onClick={() => updateLead(lead._id, { newStatus: 'rejected' })}
            color="danger"
            className="mr-2"
            isSubmitting={isSubmitting}
            loadingText="Updating"
          >
            Reject
          </SubmitButton>
          <NavLink className="ml-2 btn btn-sm btn-outline-secondary" to={`/admin/vendors/leads/lead/edit/${lead._id}`}>
            <span>Edit</span>
          </NavLink>
        </ButtonGroup>
      </CardTitle>
      <CardBody>
        <Row>
          <Col xs="4">
            <strong>Email:</strong> {lead.email} <br />
            <strong>Location:</strong> {lead.location}
          </Col>
          <Col xs="4">
            <strong>Website:</strong>{' '}
            <a href={getUrl(lead.website)} rel="noopener noreferrer" target="_blank" className="text-primary">
              {lead.website}
            </a>{' '}
            <br />
            <strong>Phone:</strong> {lead.phone}
          </Col>
          <Col xs="4">
            {lead.fulfillment_days && (
              <div className="d-flex justify-content-end">
                <strong> Fullfilment Days: </strong>
                <VendorFulfillmentDays fulfillment_timelines={lead.fulfillment_days} className="mt-0" />
              </div>
            )}
            {lead.roasting_days && (
              <div className="d-flex justify-content-end">
                <strong> Roasting Days: </strong>
                <VendorFulfillmentDays fulfillment_timelines={lead.roasting_days} className="mt-0" />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="comments">
            <br />
            <strong>Comments:</strong> {lead.notes}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

Lead.propTypes = {
  lead: PropTypes.shape({
    _id: PropTypes.string,
    company: PropTypes.string,
    contact_name: PropTypes.string,
    email: PropTypes.string,
    location: PropTypes.string,
    website: PropTypes.string,
    phone: PropTypes.string,
    fulfillment_days: PropTypes.object,
    roasting_days: PropTypes.object,
    notes: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  updateLeads: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  setUpdate: PropTypes.func.isRequired,
  update: PropTypes.bool,
};

const VendorLeadPageComponent = ({
  getLeads,
  addToast,
  updateLeads,
  match: {
    params: { status },
  },
}) => {
  const [leads, setLeads] = useState(null);
  const [update, setUpdate] = useState(false);
  const { isFetching } = useDataEffect(getLeads, setLeads, status, {}, [status, update]);

  return (
    <PanelPage
      title={'Vendor Leads'}
      tabs={tabs}
      heading={
        <NavLink className="ml-2 btn btn-sm btn-outline-secondary" to="/admin/vendors/leads/lead/create">
          <span>Create New</span>
        </NavLink>
      }
    >
      <DataLoading count={(leads && leads.length) || 0} isLoading={isFetching} />
      {leads &&
        leads.length > 0 &&
        leads.map(lead => (
          <Lead
            key={lead._id}
            updateLeads={updateLeads}
            lead={lead}
            addToast={addToast}
            setUpdate={setUpdate}
            update={update}
          />
        ))}
    </PanelPage>
  );
};

VendorLeadPageComponent.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
  addToast: PropTypes.func.isRequired,
  getLeads: PropTypes.func.isRequired,
  updateLeads: PropTypes.func.isRequired,
};

export const VendorLeadPage = connect(null, dispatch => ({
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getLeads: status => dispatch(getLeadsAction(status)),
  updateLeads: (id, data) => dispatch(updateLeadsAction(id, data)),
}))(VendorLeadPageComponent);
