import PropTypes from 'prop-types';
import React from 'react';
import { connectWithAbort } from '@bottomless/common/store';
import { prefetchStreamingOrderGenAction } from '../../../../store/admin/ordergen';
import { MainOrderGenPage } from './OrderGen';

const StreamingGenPageComponent = props => <MainOrderGenPage {...props} />;

StreamingGenPageComponent.propTypes = {
  prefetchUsers: PropTypes.func.isRequired,
};

export const StreamingOrderGen = connectWithAbort(null, dispatch => ({
  prefetchUsers: params => dispatch(prefetchStreamingOrderGenAction({ ...params, async: true })),
}))(StreamingGenPageComponent);
