import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

export const PriceBadges = ({ product }) => (
  <div>
    {Object.entries(product.rotation_price || {})
      .filter(([, value]) => Boolean(value))
      .map(([key]) => (
        <Badge key={key} className="mr-2">
          {key}
        </Badge>
      ))}
  </div>
);

PriceBadges.propTypes = {
  product: PropTypes.shape({
    rotation_price: PropTypes.object,
  }),
};
