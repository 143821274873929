import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { HeadingBack } from '../../../../components/HeadingBack/HeadingBack';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getCategoriesAction } from '../../../../store';
import {
  getProductAction,
  getProductsAction,
  updateProductAction,
  getQuizAnswersAction,
  getTaxCategoriesAction,
} from '../../../../store/admin/product';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import { getVendorsAction } from '../../../../store/admin/vendor';
import { ProductForm } from '../components/ProductForm';

const EditProductPageComponent = ({
  getProductOptions,
  getProduct,
  getProducts,
  getVendors,
  updateProduct,
  addToast,
  match,
  getQuizAnswers,
  getCategories,
  getTaxCategories,
}) => {
  const [product, setProduct] = useState(null);
  const [quizAnswers, setQuizAnswers] = useState([]);
  const [shopProducts, setShopProducts] = useState([]);

  const { id } = match.params;

  const Heading = HeadingBack({
    to: '/admin/products',
  });

  useDataEffect(
    getProducts,
    response => {
      setShopProducts(get(response, 'docs', []));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  useDataEffect(getProduct, setProduct, id);
  useDataEffect(getQuizAnswers, setQuizAnswers, id);

  const { data: vendors } = useDataEffect(getVendors);
  const { data: productOptions } = useDataEffect(getProductOptions);
  const { data: categories } = useDataEffect(getCategories);
  const { data: taxCategories } = useDataEffect(getTaxCategories, undefined, undefined, null);

  const onUpdateSuccess = product => {
    addToast('Product has been successfully saved');
    setProduct(product);
  };

  return (
    <PanelPage title={product ? `${product.name} - ${product.vendor_name}` : ''} heading={Heading}>
      <DataLoading count={product ? 1 : 0} isLoading={Boolean(product)} />
      {product && productOptions && (
        <>
          <Row>
            <Col xs="12">
              {product && taxCategories && (
                <ProductForm
                  onSubmit={data => updateProduct(product._id, data)}
                  onSuccess={onUpdateSuccess}
                  product={product}
                  productOptions={productOptions}
                  shopProducts={shopProducts}
                  vendors={vendors}
                  quizAnswers={quizAnswers}
                  categories={categories}
                  taxCategories={taxCategories}
                  addToast={addToast}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </PanelPage>
  );
};

EditProductPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getVendors: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  getQuizAnswers: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getTaxCategories: PropTypes.func.isRequired,
};

export const EditProductPage = connect(null, dispatch => ({
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getProduct: id => dispatch(getProductAction(id)),
  updateProduct: (id, data) => dispatch(updateProductAction(id, data)),
  getProductOptions: () => dispatch(getProductOptionsAction()),
  getProducts: params => dispatch(getProductsAction(params)),
  getVendors: () => dispatch(getVendorsAction()),
  getQuizAnswers: id => dispatch(getQuizAnswersAction(id)),
  getCategories: () => dispatch(getCategoriesAction()),
  getTaxCategories: () => dispatch(getTaxCategoriesAction()),
}))(EditProductPageComponent);
