import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { DataLoading, Field, Form } from '@bottomless/common/components';
import { useDataEffect, useConditionalDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import {
  getPendingProposedProductsAction,
  ignoreProposedProductAction,
  unlinkProposedProductAction,
  getProposedProductVendorsAction,
  triggerScraperAction,
} from '../../../../store/admin/proposed-product';
import { Product } from './components/Product';
import { triggerPopulateCacheAction, getProductsAction } from '../../../../store/admin/product';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import { Button, Row } from 'reactstrap';
import * as Yup from 'yup';
import { createSelectOptions } from '@bottomless/common/utils';

const tabs = [
  { to: '/admin/proposed_products/pending', name: 'Pending' },
  { to: '/admin/proposed_products/all', name: 'All' },
  { to: '/admin/proposed_products/ignore', name: 'Ignore' },
];

const Schema = Yup.object().shape({
  scrape_vendor: Yup.string().nullable(true),
});

const PendingProposedProductsPageComponent = ({
  getProposedProducts,
  ignoreProposedProduct,
  unlinkProposedProduct,
  getProposedProductVendors,
  triggerPopulateCache,
  getProductOptions,
  triggerScraper,
  addToast,
  getProducts,
}) => {
  const [products, setProducts] = useState(null);
  const [ignoreCounter, setIgnoreCounter] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [vendorToScrape, setVendorToScrape] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [shopProducts, setShopProducts] = useState([]);

  useDataEffect(getProductOptions, setProductOptions);
  useConditionalDataEffect(!vendors, getProposedProductVendors, setVendors);
  const vendorOptions = useMemo(() => ({ all: 'All', ...createSelectOptions(vendors) }), [vendors]);

  useDataEffect(
    getProducts,
    response => {
      setShopProducts(get(response, 'docs', []));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  const productProccessed = id => {
    setProducts(products.filter(curProduct => curProduct._id !== id));
  };

  const onPopulateCache = useCallback(() => {
    triggerPopulateCache();
    addToast('Populate Cache triggered!', 'success');
  }, [triggerPopulateCache, addToast]);

  const onIgnore = async id => {
    ignoreProposedProduct(id);
    addToast('ignored');
    setProducts(products.filter(curProduct => curProduct._id !== id));
    setIgnoreCounter(ignoreCounter + 1);
  };

  const onUnlink = async id => {
    unlinkProposedProduct(id);
    addToast('Unlinked');
    setProducts(products.filter(curProduct => curProduct._id !== id));
    setIgnoreCounter(ignoreCounter + 1);
  };

  const scrapeVendor = () => {
    triggerScraper({ vendor_id: vendorToScrape });
    addToast('Check for email to operations@bottomless.com when done scraping!');
  };

  useDataEffect(getProposedProducts, setProducts);

  return (
    <PanelPage
      title="Proposed products"
      tabs={tabs}
      heading={
        <Row className="justify-content-end align-items-center">
          <DataLoading count={1} isLoading={!vendors} />
          <div className="d-flex mb-2 vendor-scrape">
            <Form
              inline
              initialValues={{ scrape_vendor: '' }}
              validationSchema={Schema}
              onSubmit={() => {}}
              onSuccess={() => {}}
            >
              {() => (
                <>
                  <Field
                    name="scrape_vendor"
                    label="Vendor to scrape"
                    type="select"
                    className="vendor_scrape_dropdown"
                    options={vendorOptions}
                    onChange={e => setVendorToScrape(e.target.value)}
                  />
                </>
              )}
            </Form>
            <Button onClick={scrapeVendor} color="danger" size="sm" className="ml-2">
              Scrape
            </Button>
          </div>

          <Button onClick={onPopulateCache} color="success" size="sm">
            Populate Cache
          </Button>
        </Row>
      }
    >
      <DataLoading count={(products || { length: 0 }).length} isLoading={products === null} />
      {products &&
        products.map(product => (
          <Product
            product={product}
            key={product._id}
            back="/admin/proposed_products/pending"
            ignore={onIgnore}
            productProccessed={productProccessed}
            options={productOptions}
            unlink={onUnlink}
            shopProducts={shopProducts}
          />
        ))}
    </PanelPage>
  );
};

PendingProposedProductsPageComponent.propTypes = {
  getProposedProducts: PropTypes.func.isRequired,
  ignoreProposedProduct: PropTypes.func.isRequired,
  triggerPopulateCache: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  unlinkProposedProduct: PropTypes.func.isRequired,
  triggerScraper: PropTypes.func.isRequired,
  getProposedProductVendors: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
};

export const PendingProposedProductsPage = connect(null, dispatch => ({
  getProposedProducts: () => dispatch(getPendingProposedProductsAction()),
  ignoreProposedProduct: id => dispatch(ignoreProposedProductAction(id)),
  unlinkProposedProduct: id => dispatch(unlinkProposedProductAction(id)),
  triggerPopulateCache: () => dispatch(triggerPopulateCacheAction()),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getProductOptions: () => dispatch(getProductOptionsAction()),
  getProposedProductVendors: () => dispatch(getProposedProductVendorsAction()),
  triggerScraper: data => dispatch(triggerScraperAction(data)),
  getProducts: id => dispatch(getProductsAction(id)),
}))(PendingProposedProductsPageComponent);
