import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { tabs } from './Tabs';
import { Order } from './components/Order';
import { Search } from './components/Search';
import './Fulfillment.scss';

const fields = ['smoke', 'subproduct', 'charge', 'shopify', 'shipment'];

export const FulfillmentPage = ({
  getOrders,
  withReport,
  products,
  onReplaceOrder: originalReplaceOrder,
  resolveReported: originalResolveReported,
  replaceOrderWithCoffeeCredit,
  addToast,
  cancelOrder,
  isSubproductPage,
}) => {
  const [orders, setOrders] = useState(null);
  const [filter, setFilter] = useState({ type: '' });
  const [isCancelling, setIsCancelling] = useState(false);
  const [isCancellingOrderId, setIsCancellingOrderId] = useState(null);

  useDataEffect(getOrders, setOrders);

  const onCancel = async id => {
    setIsCancelling(true);
    setIsCancellingOrderId(id);

    await cancelOrder(id);

    setIsCancelling(false);
    setIsCancellingOrderId(null);

    addToast('Order has been cancelled');
    const { payload } = await getOrders();
    setOrders(payload);
  };

  const onReplaceOrder = useCallback(
    async (id, data) => {
      let res;
      const { isCoffeeCredit, source, discount_type, discount_comment } = data;

      if (isCoffeeCredit) {
        res = await replaceOrderWithCoffeeCredit(id, { source, discount_type, discount_comment });
      } else {
        res = await originalReplaceOrder(id, data);
      }
      setOrders(orders.filter(order => order._id !== id));
      return res;
    },
    [originalReplaceOrder, orders, setOrders, replaceOrderWithCoffeeCredit]
  );

  const resolveReported = useCallback(
    async id => {
      const res = await originalResolveReported(id);
      setOrders(orders.filter(order => order._id !== id));
      return res;
    },
    [originalResolveReported, orders, setOrders]
  );

  const applyFilter = useCallback(
    order => {
      if (filter.type === 'withError') {
        return (
          order.fulfillmentErrors &&
          fields.reduce(
            (any, field) => any || (order.fulfillmentErrors[field] && order.fulfillmentErrors[field].error),
            false
          )
        );
      }

      return true;
    },
    [filter]
  );

  return (
    <PanelPage
      title="Fulfillment"
      withUpNext={false}
      className="page-fulfillment"
      tabs={tabs}
      heading={<Search onSubmit={setFilter} />}
    >
      <DataLoading count={(orders || []).length} isLoading={orders === null} />
      {orders && (
        <>
          {orders.filter(applyFilter).map(order => (
            <Order
              key={order._id}
              order={order}
              withReport={withReport}
              products={products}
              onReplaceOrder={onReplaceOrder}
              resolveReported={resolveReported}
              addToast={addToast}
              onCancel={onCancel}
              isCancelling={isCancelling}
              isSubproductPage={isSubproductPage}
              isCancellingOrderId={isCancellingOrderId}
            />
          ))}
        </>
      )}
    </PanelPage>
  );
};

FulfillmentPage.propTypes = {
  getOrders: PropTypes.func.isRequired,
  products: PropTypes.array,
  withReport: PropTypes.bool,
  onReplaceOrder: PropTypes.func,
  resolveReported: PropTypes.func,
  addToast: PropTypes.func,
  replaceOrderWithCoffeeCredit: PropTypes.func,
  cancelOrder: PropTypes.func,
  isSubproductPage: PropTypes.bool,
};

FulfillmentPage.defaultProps = {
  isSubproductPage: false,
};
