import {
  ChangeScaleShipmentStatusTypes,
  GetScaleShipmentTypesv2,
  GetScaleShipmentsTrackingTypes,
  PurchaseScaleShipmentTypes,
  GetScaleShipmentTypes,
  UpdateTrackingNumberTypes,
} from './scale-shipment.actions';

const initialState = {
  data: {
    docs: [],
    total: 0,
    limit: 100,
    pages: 0,
    page: 0,
  },
  batches: [],
  isLoading: false,
  isV2data: false,
};

export const adminScaleShipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetScaleShipmentTypesv2.REQUEST:
      return { ...initialState, isLoading: true };

    case GetScaleShipmentsTrackingTypes.REQUEST:
      return { ...state, batches: [], isLoading: true };

    case GetScaleShipmentTypes.REQUEST:
      return { ...initialState, isV2data: false };

    case GetScaleShipmentTypes.SUCCESS:
      return {
        ...state,
        data: action.payload,
        isV2data: false,
      };

    case GetScaleShipmentsTrackingTypes.SUCCESS:
      return {
        ...state,
        batches: action.payload,
        isLoading: false,
      };

    case GetScaleShipmentTypesv2.SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isV2data: true,
      };

    case PurchaseScaleShipmentTypes.SUCCESS:
    case ChangeScaleShipmentStatusTypes.SUCCESS:
    case UpdateTrackingNumberTypes.SUCCESS: {
      const shipment = action.payload;

      const index = state.data.docs.findIndex(u => {
        if (u.shipments && u.shipments.length) {
          return u._id === shipment.user_id._id;
        }
        return u._id === shipment._id;
      });

      const payload = state.data.docs[index] || {};

      if (payload.shipments) {
        const shipmentIndex = payload.shipments.findIndex(s => s._id === shipment._id);

        const updated = {
          ...payload,
          shipments: [
            ...payload.shipments.slice(0, shipmentIndex),
            shipment,
            ...payload.shipments.slice(shipmentIndex + 1),
          ],
        };

        return {
          ...state,
          data: {
            ...state.data,
            docs: [...state.data.docs.slice(0, index), updated, ...state.data.docs.slice(index + 1)],
          },
        };
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            docs: [...state.data.docs.slice(0, index), shipment, ...state.data.docs.slice(index + 1)],
          },
        };
      }
    }

    default:
      return state;
  }
};
