import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';

export const OrderedProduct = ({ order, user }) => {
  const isRotation = String(order.product_id.product) === '5e477572154727000432370b';
  const product = order.subproduct_id?.product;
  const variant = product?.variants.find(v => v._id === order.subproduct_id?.variant);

  return (
    <div>
      <div className="text-secondary text-sm">{get(product, 'vendor_name', '---')}</div>
      <div>
        {get(product, 'name', '---')}{' '}
        {product?.name && user?.shopifySubscriptionContractId && <span>(x{order.quantity || 1})</span>}
      </div>
      <div className="text-primary text-sm">
        {variant && (
          <>
            {variant.size}oz - ${variant.price}
          </>
        )}
      </div>
      <div>{isRotation ? 'Rotation' : 'Not rotation'}</div>
      {order.lineItems?.length > 0 && <div className="text-secondary">+{order.lineItems.length} more</div>}
    </div>
  );
};

OrderedProduct.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    subproduct_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    quantity: PropTypes.array,
    lineItems: PropTypes.array,
    product_id: PropTypes.shape({
      product: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    shopifySubscriptionContractId: PropTypes.string,
  }),
};
