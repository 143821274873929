import { useMemo } from 'react';

const defaultInputLabel = item => item.name;

export const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

export const useSelectOptions = (data, label, inputLabel = defaultInputLabel) => {
  return useMemo(() => createSelectOptions(data, label, inputLabel), [data, label, inputLabel]);
};
