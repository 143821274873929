import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getProductsAction,
  getReportedOrders,
  replaceOrderAction,
  replaceOrderWithCoffeeCreditAction,
  resolveProblemAction,
} from '../../../store';
import { useDataEffect } from '@bottomless/common/src/hooks';
import { addToastAction } from '@bottomless/common/src/store/toast';
import { ProblemsPage } from './Problems';

const OrderProblemsPage = ({
  getOrders,
  getProducts,
  onReplaceOrder,
  resolveProblem,
  addToast,
  replaceOrderWithCoffeeCredit,
}) => {
  const { data: products } = useDataEffect(getProducts, () => {}, {
    status: 'active',
    sort: 'name',
    dir: 1,
  });

  return (
    <ProblemsPage
      getOrders={getOrders}
      products={products?.docs || []}
      withReport
      onReplaceOrder={onReplaceOrder}
      replaceOrderWithCoffeeCredit={replaceOrderWithCoffeeCredit}
      resolveProblem={resolveProblem}
      addToast={addToast}
    />
  );
};

OrderProblemsPage.propTypes = {
  getOrders: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  onReplaceOrder: PropTypes.func.isRequired,
  resolveProblem: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  replaceOrderWithCoffeeCredit: PropTypes.func.isRequired,
};

export const OrderProblems = connect(null, dispatch => ({
  getOrders: () => dispatch(getReportedOrders()),
  getProducts: params => dispatch(getProductsAction(params)),
  onReplaceOrder: (id, data) => dispatch(replaceOrderAction(id, data)),
  replaceOrderWithCoffeeCredit: (id, data) => dispatch(replaceOrderWithCoffeeCreditAction(id, data)),
  resolveProblem: id => dispatch(resolveProblemAction(id)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(OrderProblemsPage);
