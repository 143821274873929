import classNames from 'classnames';
import { isEqual, keyBy, pick } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Loader } from 'react-feather';
import { Link } from 'react-router-dom';
import { Badge, Button, Col, Collapse, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ButtonGroup from 'reactstrap/es/ButtonGroup';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox, Price, DateFormat } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';

const Schema = Yup.object().shape({
  variants: Yup.array().of(
    Yup.object({
      size: Yup.number().required(),
      cost: Yup.number(),
      price: Yup.number().required(),
      qualifiesForRotation: Yup.bool(),
    })
  ),
});

const Stats = ({ getStats, variant, shouldRefresh }) => {
  const [stats, setStats] = useState(null);
  const [isOpen, toggle] = useToggle();
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isLoaded || shouldRefresh) {
        const { payload } = await getStats(variant._id);
        setStats(payload);
        setLoaded(true);
      }
    })();
  }, [isLoaded, shouldRefresh]);

  return (
    <>
      {!stats && (
        <div className="d-flex justify-content-center align-items-center">
          <Loader size="18" className="spin mr-2" />
          <span>Loading stats data</span>
        </div>
      )}
      {stats && (
        <div>
          <div className="d-flex py-1">
            <div className="text-sm">
              <div className="d-flex">
                <div className="mr-2">
                  Total Amount paid: <Price value={stats.amount} cents /> in {stats.orders.length || 0} Orders.
                </div>
                <div>
                  Average price:{' '}
                  <span
                    className={classNames({
                      'text-success': stats.avg > variant.price,
                      'text-info': stats.avg === variant.price,
                      'text-danger': stats.avg < variant.price,
                    })}
                  >
                    <Price value={stats.avg} cents />
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div className="mr-2">
                  Total cost: <Price value={stats.totalCost} /> in {stats.orders.length || 0} Orders.
                </div>
                <div className="mr-2">
                  Average cost:{' '}
                  <span
                    className={classNames({
                      'text-success': stats.avgCost > variant.cost,
                      'text-info': stats.avgCost === variant.cost,
                      'text-danger': stats.avgCost < variant.cost,
                    })}
                  >
                    <Price value={stats.avgCost} />
                  </span>
                </div>
              </div>
            </div>

            <div className="ml-auto">
              <Button onClick={toggle} size="sm">
                Details
              </Button>
            </div>
          </div>
          {stats.orders && stats.orders.length > 0 && (
            <Collapse isOpen={isOpen}>
              <div className="pt-1">
                <table className="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>Pricing rule</th>
                      <th>Fulfilled</th>
                      <th>Cost</th>
                      <th>Amount paid</th>
                      <th>Shipping Cost</th>
                      <th>Transaction Cost</th>
                      <th>Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.orders.map((order, i) => (
                      <tr key={i}>
                        <td>
                          <Link
                            to={`/admin/ordering_cockpit/${order.user_id}?orderId=${order._id}`}
                            className="text-info text-sm"
                            target="_blank"
                          >
                            {order._id}
                          </Link>
                        </td>
                        <td>
                          <span className="text-sm">{order.pricing_rule._id}</span>
                        </td>
                        <td>
                          <DateFormat date={order.date_fulfilled} />
                        </td>
                        <td>
                          <Price value={order.cost} />
                        </td>
                        <td>
                          <Price value={order.amount_paid} cents />
                        </td>
                        <td>
                          <Price value={order.actual_shipping_cost} />
                        </td>
                        <td>
                          <Price value={order.transaction_cost} />
                        </td>
                        <td>{order.updated_at && <DateFormat value={order.updated_at} />}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Collapse>
          )}
        </div>
      )}
    </>
  );
};

Stats.propTypes = {
  shouldRefresh: PropTypes.number,
  getStats: PropTypes.func.isRequired,
  variant: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    cost: PropTypes.number,
  }),
};

const needsConfirmation = ['cost', 'price', 'transaction_cost', 'shipping_cost'];

const ApplyForm = ({ changeset, product, onApply, toggle }) => {
  if (!changeset) return null;

  const changesetVariants = keyBy(
    changeset.variants.map(v => pick(v, ['_id', ...needsConfirmation])),
    '_id'
  );

  const productsVariants = keyBy(
    product.variants.map(v => pick(v, ['_id', ...needsConfirmation])),
    '_id'
  );

  return (
    <>
      <span>{product.name}</span>
      <div className="d-flex">
        <ol>
          {Object.keys(changesetVariants).map((id, i) => (
            <div key={id}>
              {Object.keys(changesetVariants[id])
                .filter(prop => !isEqual(productsVariants[id][prop], changesetVariants[id][prop]))
                .map(prop => (
                  <li key={i}>
                    <strong>Variant {id}</strong> - <strong>{prop}</strong> from{' '}
                    <span className="text-warning">{productsVariants[id][prop]}</span> to{' '}
                    <span className="text-success">{changesetVariants[id][prop]}</span>
                  </li>
                ))}
            </div>
          ))}
        </ol>
      </div>
      <ButtonGroup className="justify-content-between align-items-center w-100">
        <Button onClick={toggle} className="mx-2">
          Cancel
        </Button>
        <Button color="success" onClick={() => onApply({ updateOrders: false })} className="mx-2">
          Apply to product
        </Button>
        <Button color="warning" onClick={() => onApply({ updateOrders: true })} className="mx-2">
          Apply to all orders
        </Button>
      </ButtonGroup>
    </>
  );
};

ApplyForm.propTypes = {
  changeset: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

const needConfirmation = ({ product, changeset }) =>
  isEqual(
    product.variants.map(v => pick(v, needsConfirmation)),
    changeset.variants.map(v => pick(v, needsConfirmation))
  ) === false;

const getNumber = val => +val || 0;

export const PricingForm = ({ product, onSubmit, onSuccess, getStats }) => {
  const [isOpen, toggle] = useToggle();
  const [changeset, setChangeset] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const formRef = useRef();

  const submit = data => {
    if (needConfirmation({ product, changeset: data })) {
      setChangeset(data);
      return toggle();
    }

    return onSubmit(data);
  };

  useEffect(() => {
    if (!product.rotation_price) {
      product.rotation_price = {
        basic: false,
        standard: false,
        premium: false,
      };
      formRef.current.setFieldValue(`rotation_price[basic]`, false);
      formRef.current.setFieldValue(`rotation_price[standard]`, false);
      formRef.current.setFieldValue(`rotation_price[premium]`, false);
    }
  }, [product]);

  const close = () => {
    setChangeset(null);
    toggle();
  };

  const onSuccessUpdate = data => {
    setRefresh(refresh + 1);
    return onSuccess(data);
  };

  const getVariant = (values, i) => values.variants[i];
  const format = value => parseFloat(value).toFixed(2);

  const totalCost = (values, i) => {
    const variant = getVariant(values, i);

    return format(getNumber(variant.cost) + getNumber(variant.shipping_cost) + getNumber(variant.transaction_cost));
  };

  const netMargin = (values, i) => {
    const variant = getVariant(values, i);
    const total = totalCost(values, i);

    return format(variant.price - total);
  };

  const percentage = (values, i) => {
    const variant = getVariant(values, i);
    const total = netMargin(values, i);

    return `${Math.round((total / variant.price) * 100)}%`;
  };

  const userCount = variantId => product.users.filter(user => user.product.variant === variantId).length;

  return (
    <>
      <Form
        innerRef={formRef}
        initialValues={{ ...product }}
        validationSchema={Schema}
        onSubmit={submit}
        onSuccess={() => {
          setRefresh(refresh + 1);
          return onSuccess();
        }}
      >
        {({ isSubmitting, values }) => (
          <>
            {product.variants &&
              product.variants.map((variant, i) => (
                <div className="border p-2 mb-2" key={i}>
                  <Row className="mb-2 align-items-end">
                    <Col xs="12">
                      <Badge color="info">Selected by {userCount(variant._id)} users</Badge>
                      <Badge color={variant.available ? 'success' : 'danger'}>
                        {variant.available ? 'available' : 'Not available'}
                      </Badge>
                    </Col>
                    <Col xs="12">
                      {/* <LazyLoad
                        once
                        scrollContainer={'#panel-content'}
                        placeholder={
                          <span>
                            <Loader size="15" className="spin ml-2" /> Loading
                          </span>
                        }
                      >
                        <Stats
                          getStats={variant => getStats(product._id, variant)}
                          variant={variant}
                          shouldRefresh={refresh}
                        />
                      </LazyLoad> */}
                    </Col>
                  </Row>
                  <Row className="w-100 align-items-center mb-0 justify-content-around" key={variant._id} noGutters>
                    <Col xs="1">
                      <Field name={`variants[${i}].size`} label="size" type="number" readOnly />
                    </Col>
                    <Col>
                      <Field name={`variants[${i}].price`} step="0.1" label="price" type="number" />
                    </Col>
                    <Col>
                      <Field name={`variants[${i}].cost`} step="0.1" label="cost" type="number" />
                    </Col>
                    <Col>
                      <Field name={`variants[${i}].shipping_cost`} step="0.1" label="shipping" type="number" />
                    </Col>
                    <Col>
                      <Field name={`variants[${i}].transaction_cost`} step="0.01" label="transaction" type="number" />
                    </Col>
                    <Col>
                      <Field
                        name="total"
                        label="total"
                        placeholder="total"
                        value={totalCost(values, i)}
                        type="number"
                        readOnly
                      />
                    </Col>
                    <Col>
                      <Field
                        name="netMargin"
                        label="Net margin"
                        placeholder="Net margin"
                        value={netMargin(values, i)}
                        type="number"
                        readOnly
                      />
                    </Col>
                    <Col>
                      <Field
                        name="percentage"
                        label="Percentage"
                        placeholder="Percentage"
                        value={percentage(values, i)}
                        readOnly
                      />
                    </Col>
                    <Col xs="2">
                      <Field
                        name={`variants[${i}].qualifiesForRotation`}
                        label="Qualifies for rotation"
                        type="select"
                        options={{ '': '---', true: 'Yes', false: 'No' }}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            {product.rotation_price && (
              <Row>
                {Object.keys(product.rotation_price).map((pr, i) => (
                  <Col>
                    <Checkbox name={`rotation_price[${pr}]`} label={pr} value={product.rotation_price[pr]} />
                  </Col>
                ))}
              </Row>
            )}
            <Row>
              <Col className="pr-0">
                <SubmitButton isSubmitting={isSubmitting} size="sm">
                  Save
                </SubmitButton>
              </Col>
            </Row>
          </>
        )}
      </Form>
      <Modal isOpen={isOpen} toggle={close} size="lg">
        <ModalHeader toggle={close}>Apply to all historical orders</ModalHeader>
        <ModalBody>
          {changeset && (
            <ApplyForm
              product={product}
              changeset={changeset}
              onApply={({ updateOrders }) => {
                onSubmit({ updateOrders, ...changeset });
                onSuccessUpdate(changeset);
                close();
              }}
              toggle={close}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

PricingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  getStats: PropTypes.func.isRequired,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        qualifiesForRotation: PropTypes.bool,
      })
    ).isRequired,
    users: PropTypes.array,
  }).isRequired,
};
