import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, SubmitButton, Price, Checkbox } from '@bottomless/common/components';

const ACCOUNTING_STATUS = {
  ACTIVE: 'active',
};

const PayComponent = ({ checkedIds, onSubmit, vendor, batchShipments, onSuccess }) => {
  const checkedBatchShipments = useMemo(
    () =>
      checkedIds && checkedIds.length
        ? batchShipments.filter(batch => batch.status !== 'paid' && checkedIds.includes(batch._id))
        : [],
    [batchShipments, checkedIds]
  );

  const handleSubmit = useCallback(
    ({ executePayment }) =>
      onSubmit({
        batchIds: checkedIds,
        batches: checkedBatchShipments,
        vendorId: vendor._id,
        executePayment,
      }),
    [onSubmit, checkedIds, vendor, batchShipments]
  );

  const summary = useMemo(
    () =>
      checkedBatchShipments.reduce(
        (all, batch) => all + batch.orders.reduce((sum, order) => sum + (order.vendor_payout || 0), 0),
        0
      ),
    [checkedBatchShipments]
  );

  const isActive = useMemo(() => vendor.accounting?.status === ACCOUNTING_STATUS.ACTIVE, [vendor]);

  return (
    <Form
      initialValues={{ executePayment: true }}
      validationSchema={Yup.object()}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, errors, values }) => (
        <div className="text-right">
          <SubmitButton
            color="success"
            disabled={
              !checkedBatchShipments.length ||
              (!isActive && values.executePayment) ||
              (values.executePayment && summary < 0)
            }
            isSubmitting={isSubmitting}
          >
            {values.executePayment ? 'Pay' : 'Mark as paid'} {summary > 0 && <Price value={summary} cents />}
          </SubmitButton>
          <Checkbox name="executePayment" label="Execute payment" value={true} />
          {errors?.message && <div className="text-danger">{errors.message}</div>}
        </div>
      )}
    </Form>
  );
};

PayComponent.propTypes = {
  checkedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  batchShipments: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    accounting: PropTypes.shape({
      status: PropTypes.string,
    }),
  }).isRequired,
};

export const Pay = memo(PayComponent);
