import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';
import { getVariantSelectLabel, utcDate } from '@bottomless/common/utils';
import { RoasterAttachment } from './components';
import { OrderedProduct } from './OrderedProduct';
import './ReplaceForm.scss';

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

const DiscountTypes = {
  Referral: 'referral',
  Operations: 'operations',
  MicroInfluencer: 'micro_influencer',
  RoasterReplacement: 'roaster_replacement',
  Other: 'other',
};

const Schema = Yup.object().shape({
  isCoffeeCredit: Yup.boolean(),
  source: Yup.string().required(),
  discount_type: Yup.string().required(),
  vendorNotification: Yup.object().shape({
    enabled: Yup.boolean(),
    reason: Yup.string().when('enabled', {
      is: true,
      then: Yup.string().required('This field is required'),
    }),
    request: Yup.string(),
    attachment: Yup.object()
      .shape({
        name: Yup.string(),
        type: Yup.string(),
        data: Yup.string(),
      })
      .nullable(),
  }),
  discount_comment: Yup.string().when('discount_type', {
    is: DiscountTypes.RoasterReplacement,
    then: Yup.string().required('This field is required'),
  }),
  status: Yup.string().when('isCoffeeCredit', {
    is: false,
    then: Yup.string().required(),
  }),
  date_fulfilled: Yup.string().when('isCoffeeCredit', {
    is: false,
    then: Yup.string().required(),
  }),
  date_arrived: Yup.string().when('isCoffeeCredit', {
    is: false,
    then: Yup.string().required(),
  }),
  product: Yup.string().when('isCoffeeCredit', {
    is: false,
    then: Yup.string().required('This field is required'),
  }),
  variant: Yup.string().when('isCoffeeCredit', {
    is: false,
    then: Yup.string().required('This field is required'),
  }),
});

export const ReplaceOrderForm = ({ order, products, onSubmit, onSubmitSuccess, user }) => {
  const [selectedProduct, setProduct] = useState(null);
  const [isCoffeeCredit, setCoffeeCredit] = useState(false);

  const formRef = useRef();
  const selectProduct = id => {
    const product = products.find(p => p._id === id);
    setProduct(product);
  };

  useEffect(() => {
    selectProduct(order.subproduct_id?.product._id);
  }, [order, products]);

  const discountTypes = useMemo(
    () =>
      createSelectOptions([
        { _id: '', name: '--choose discount type--' },
        ...Object.entries(DiscountTypes).reduce((all, [name, _id]) => [...all, { _id, name }], []),
      ]),
    []
  );

  const onIsCoffeeCreditChange = useCallback(
    e => {
      const { checked } = e.target;
      setCoffeeCredit(checked);

      if (formRef.current) {
        formRef.current.setFieldValue('vendorNotification.enabled', false);
      }
    },
    [setCoffeeCredit, formRef]
  );

  return (
    <>
      <div className="mb-4">
        <OrderedProduct products={products} order={order} user={user} />
      </div>
      <Form
        innerRef={formRef}
        initialValues={{
          isCoffeeCredit: false,
          status: order.status,
          shipping_status: order.shipping_status,
          ...(order.subproduct_id?.product.status === 'active'
            ? { product: order.subproduct_id?.product._id, variant: order.subproduct_id?.variant }
            : {}),
          date_fulfilled: order.date_fulfilled ? utcDate(order.date_fulfilled) : undefined,
          date_arrived: order.date_arrived ? utcDate(order.date_arrived) : undefined,
          override_fulfillment_date: order.override_fulfillment_date
            ? utcDate(order.override_fulfillment_date)
            : undefined,
          tracking_number: order.tracking_number,
          shipping_service: order.shipping_service,
          source: 'replaced',
          discount_type: order.discount_type || '',
          discount_comment: order.fulfillmentErrors?.vendor?.error || '',
          vendorNotification: { enabled: false, reason: '', request: '', attachment: null },
        }}
        validationSchema={Schema}
        onSubmit={onSubmit}
        onSuccess={onSubmitSuccess}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            <div className="mb-3">
              <Checkbox name="isCoffeeCredit" label="Replace with coffee credit" onChange={onIsCoffeeCreditChange} />
            </div>
            <Field name="discount_type" type="select" label="Discount type" options={discountTypes} required />
            {values.discount_type === DiscountTypes.RoasterReplacement && (
              <Field name="discount_comment" type="textarea" label="Discount comment" />
            )}
            <div className="mb-3">
              <Checkbox name="vendorNotification.enabled" label="Send vendor email" disabled={isCoffeeCredit} />
            </div>
            {!isCoffeeCredit && values.vendorNotification.enabled && (
              <>
                <Field name="vendorNotification.reason" type="textarea" label="Reason" />
                <Field name="vendorNotification.request" type="textarea" label="Request" />
                <RoasterAttachment onChange={data => setFieldValue('vendorNotification.attachment', data)} />
              </>
            )}
            <small className="form-text text-muted">Original source: {order.source}</small>
            <Field
              name="source"
              type="select"
              label="Source"
              options={{
                dumb_subscription: 'dumb_subscription',
                user: 'user',
                onboarding: 'onboarding',
                operations: 'operations',
                user_one_off: 'user_one_off',
                lost: 'lost',
                replaced: 'replaced',
              }}
              required
            />
            <hr className="bg-danger" />

            <Field
              name="status"
              label="Status"
              type="select"
              options={{
                initiated: 'Initiated',
                paid: 'Paid',
                cancelled: 'Cancelled',
                fulfilled: 'Fulfilled',
                offboarded: 'Offboarded',
                batch_sent: 'Batch sent',
                subproduct_generated: 'Subproduct generated',
              }}
              disabled={values.isCoffeeCredit}
            />

            <Field
              type="date"
              name="override_fulfillment_date"
              label="Override Fulfillment Date"
              format="MM/DD/YYYY"
              disabled={values.isCoffeeCredit}
            />

            <Field
              type="date"
              name="date_fulfilled"
              label="Date Fulfilled"
              min={new Date()}
              max={new Date(Date.now() + 365 * 86400000)}
              format="MM/DD/YYYY"
              disabled={values.isCoffeeCredit}
            />

            <Field
              name="shipping_status"
              label="Shipping status"
              type="select"
              options={{
                unknown: 'Unknown',
                pre_transit: 'Pre transit',
                accepted: 'Accepted',
                in_transit: 'In transit',
                return_to_sender: 'Return To Sender',
                delivered: 'Delivered',
              }}
              disabled={values.isCoffeeCredit}
            />

            <Field
              type="date"
              name="date_arrived"
              label="Date arrived"
              min={new Date(order.date_fulfilled ? order.date_fulfilled : Date.now())}
              max={new Date(Date.now() + 30 * 86400000)}
              showTime={true}
              format="MM/DD/YYYY h:mm a"
              disabled={values.isCoffeeCredit}
            />

            <Field
              name="product"
              type="select"
              label="Product"
              options={createSelectOptions(
                products,
                '--- select product ---',
                item => `${item.vendor_name} - ${item.name}`
              )}
              onChange={event => {
                selectProduct(event.target.value);
              }}
              disabled={values.isCoffeeCredit}
            />

            <Field
              name="variant"
              type="select"
              label="Variant"
              options={createSelectOptions(
                selectedProduct?.variants?.filter(v => v.available),
                '--- select variant ---',
                getVariantSelectLabel
              )}
              disabled={values.isCoffeeCredit}
            />

            <Field name="tracking_number" type="text" label="Tracking number" disabled={values.isCoffeeCredit} />
            <Field name="shipping_service" type="text" label="Shipping service" disabled={values.isCoffeeCredit} />

            <SubmitButton color="danger" isSubmitting={isSubmitting}>
              Replace
            </SubmitButton>
          </>
        )}
      </Form>
    </>
  );
};

ReplaceOrderForm.propTypes = {
  order: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  user: PropTypes.object,
};
