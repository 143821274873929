import PropTypes from 'prop-types';
import React from 'react';
import { connectWithAbort } from '@bottomless/common/store';
import { prefetchAg1OrderGenAction } from '../../../../store/admin/ordergen';
import { MainOrderGenPage } from './OrderGen';

const OrderGenPageComponent = props => <MainOrderGenPage {...props} />;

OrderGenPageComponent.propTypes = {
  prefetchUsers: PropTypes.func.isRequired,
};

export const Ag1OrderGen = connectWithAbort(null, dispatch => ({
  prefetchUsers: params => dispatch(prefetchAg1OrderGenAction({ ...params, async: true })),
}))(OrderGenPageComponent);
