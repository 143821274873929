import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DateFormat } from './DateFormat';

export const DateDiffFormat = ({ date, withColor, withHours, fromZeroed }) => {
  if (!date) {
    return '---';
  }

  const hoursDiff = withHours ? moment().diff(moment(date), 'hours') : undefined;
  const text = hoursDiff < 72 ? `${hoursDiff} hours ago` : moment(date).fromNow();
  const diff = moment(date).diff(moment());

  return (
    <>
      <span className={`${withColor ? (diff < 0 ? 'text-danger' : 'text-success') : ''}`}>
        <DateFormat date={date} fromZeroed={fromZeroed} /> ({text})
      </span>
    </>
  );
};

DateDiffFormat.defaultProps = {
  withColor: false,
  withHours: false,
};

DateDiffFormat.propTypes = {
  date: PropTypes.string,
  withColor: PropTypes.bool,
  withHours: PropTypes.bool,
  fromZeroed: PropTypes.bool,
};
