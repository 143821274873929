import { useToggle } from '@bottomless/common/src/hooks';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Row } from 'reactstrap';
import { CustomListProduct } from './components';
import './CustomList.scss';

export const CustomList = ({ customList, elementsInRow = 4, onClick }) => {
  const [isOpen, toggle] = useToggle();

  const [firstThreeProducts, restProducts] = useMemo(
    () => [customList.products.slice(0, elementsInRow), customList.products.slice(elementsInRow)],
    [customList, elementsInRow]
  );

  return (
    <div className="admin-custom-list">
      <Row>
        {firstThreeProducts.map((product, i) => (
          <CustomListProduct key={i} inRow={elementsInRow} index={i + 1} {...product} onClick={onClick} />
        ))}
      </Row>
      {restProducts.length > 0 && (
        <>
          <Collapse isOpen={isOpen}>
            <Row>
              {restProducts.map((product, i) => (
                <CustomListProduct
                  key={i}
                  inRow={elementsInRow}
                  index={i + elementsInRow + 1}
                  {...product}
                  onClick={onClick}
                />
              ))}
            </Row>
          </Collapse>
          <Button color="link" onClick={toggle}>
            {isOpen ? 'Show less' : 'Show more'}
          </Button>
        </>
      )}
    </div>
  );
};

CustomList.propTypes = {
  customList: PropTypes.shape({
    products: PropTypes.arrayOf({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      grind: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  elementsInRow: PropTypes.number,
  onClick: PropTypes.func,
};
