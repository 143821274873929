import React from 'react';
import PropTypes from 'prop-types';
import { AtSign, Home } from 'react-feather';
import { Card } from 'reactstrap';
import { Address } from '@bottomless/common/components';

export const UserInfo = ({ user }) => (
  <div className="mb-3">
    <Card body className="mb-2 flex-row align-items-center">
      <div className="text-secondary mr-4">
        <AtSign />
      </div>
      <a href={`mailto:${user.local.email}`}>{user.local.email}</a>
    </Card>
    <Card body className="flex-row align-items-center">
      <div className="text-secondary mr-4">
        <Home />
      </div>
      <Address address={user.verifiedAddress} />
    </Card>
  </div>
);

UserInfo.propTypes = {
  user: PropTypes.shape({
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    verifiedAddress: PropTypes.object,
  }).isRequired,
};
