import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  months: Yup.number().required(),
  reason: Yup.string(),
});

export const AddMonthsForm = ({ onSubmit, onSubmitSuccess }) => {
  return (
    <Form
      initialValues={{
        months: 0,
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="months" type="number" min="1" label="Amount" />
          <Field name="reason" label="Reason" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

AddMonthsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
