import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getPackagingsAction } from '../../../../store/admin/vendor';
import { tabs } from './Tabs';
import { VendorPackagingDetails } from '../components/VendorPackagingDetails';

const PackagingsPageComponent = ({ getPackagings }) => {
  const [packagings, setPackagings] = useState([]);

  useDataEffect(getPackagings, setPackagings, null, []);

  return (
    <PanelPage title="Packagings" tabs={tabs}>
      <Row>
        {packagings &&
          packagings.map(packaging => (
            <Col xs="12" className="mb-2" key={packaging._id}>
              <VendorPackagingDetails packaging={packaging} />
            </Col>
          ))}
      </Row>
    </PanelPage>
  );
};

PackagingsPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getPackagings: PropTypes.func.isRequired,
};

export const PackagingsPage = connect(null, dispatch => ({
  addToast: message => dispatch(addToastAction(message)),
  getPackagings: () => dispatch(getPackagingsAction()),
}))(PackagingsPageComponent);
