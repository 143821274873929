import { DataLoading } from '@bottomless/common/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Alert } from 'reactstrap';

export const StickyNotes = ({ notes }) => {
  const lastNote = useMemo(
    () => [...(notes || [])].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).shift(),
    [notes]
  );

  if (!notes) {
    return <DataLoading count={0} isLoading withMarginTop={false} />;
  }

  return (
    <>
      {lastNote && (
        <Alert color="warning" className="mb-0 border-1 p-1 my-1 text-sm" style={{ width: '100%', maxWidth: '320px' }}>
          {lastNote.content}
        </Alert>
      )}
    </>
  );
};

StickyNotes.propTypes = {
  notes: PropTypes.array.isRequired,
};
