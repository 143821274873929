import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetPricingRuleTypes = createAction('Pricing rules', 'Get rule');

export const getPricingRuleAction = path => ({
  [RSAA]: {
    endpoint: `pricing_rules?path=${path}`,
    method: 'GET',
    types: [GetPricingRuleTypes.REQUEST, GetPricingRuleTypes.SUCCESS, GetPricingRuleTypes.FAILURE],
  },
});

export const GetAllPricingRules = createAction('Pricing rules', 'Get all');

export const getAllPricingRuleAction = () => ({
  [RSAA]: {
    endpoint: `pricing_rules/all`,
    method: 'GET',
    types: [GetAllPricingRules.REQUEST, GetAllPricingRules.SUCCESS, GetAllPricingRules.FAILURE],
  },
});
