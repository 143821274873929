import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getOrderTrackingAction, editOrderTrackingAction } from '../../../store/admin/settings';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { Fragment } from 'react';

export const OrderTrackingManifestPageComponent = ({ getManifest, editManifest, addToast }) => {
  const [manifest, setManifest] = useState(null);
  const formRef = useRef();

  useEffect(() => {
    (async () => {
      const { payload } = await getManifest();
      if (payload) {
        const manifestStr = payload;
        for (const value of Object.values(manifestStr)) {
          for (const innerValue of Object.values(value)) {
            innerValue.query = JSON.stringify(innerValue.query);
          }
        }
        setManifest(manifestStr);
      }
    })();
  }, []);

  const preProcess = data => {
    for (const value of Object.values(data)) {
      for (const obj of Object.values(value)) {
        for (const propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
          }
          if (propName === 'query') {
            obj[propName] = JSON.parse(obj[propName].replace(/\./g, '\\\\u002e').replace(/\$/g, '\\\\u0024'));
          }
        }
      }
    }
    return data;
  };

  const onSubmit = useCallback(data => editManifest(preProcess(data)), [editManifest]);

  const onSuccess = (data, { resetForm }) => {
    addToast('Manifest updated', 'success');
    if (data) {
      const manifestStr = data;
      for (const value of Object.values(manifestStr)) {
        for (const innerValue of Object.values(value)) {
          innerValue.query = JSON.stringify(innerValue.query);
        }
      }
      setManifest(manifestStr);
      resetForm(manifestStr);
    }
  };
  return (
    <>
      <PanelPage title="Order Tracking Manifest">
        {manifest && (
          <Form
            initialValues={{ ...manifest }}
            onSubmit={data => onSubmit(data)}
            onSuccess={onSuccess}
            innerRef={formRef}
          >
            {({ isSubmitting }) => (
              <>
                <div>
                  {Object.keys(manifest).map((name, index) => (
                    <Card key={index} className="mb-3 flex-row align-items-stretch">
                      {name}
                      <CardBody>
                        {Object.keys(manifest[name]).map((value, index) => {
                          return (
                            <Fragment key={index}>
                              <Row>{value}</Row>
                              <Row>
                                <Col xs={12}>
                                  <Field name={`${name}.${value}.query`} label="Query"></Field>
                                </Col>
                                <Col xs={4}>
                                  <Field name={`${name}.${value}.notify`} label="Notification"></Field>
                                </Col>
                                <Col xs={2}>
                                  <Checkbox name={`${name}.${value}.ack`} label="Ack"></Checkbox>
                                </Col>
                                <Col xs={2}>
                                  <Checkbox name={`${name}.${value}.notifyAck`} label="NotifyAck"></Checkbox>
                                </Col>
                                <Col xs={2}>
                                  <Field name={`${name}.${value}.order`} label="Order"></Field>
                                </Col>
                              </Row>
                            </Fragment>
                          );
                        })}
                      </CardBody>
                    </Card>
                  ))}
                </div>
                <SubmitButton color="success" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </>
            )}
          </Form>
        )}
      </PanelPage>
    </>
  );
};

OrderTrackingManifestPageComponent.propTypes = {
  getManifest: PropTypes.func.isRequired,
  editManifest: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const OrderTrackingManifestPage = connect(null, dispatch => ({
  getManifest: () => dispatch(getOrderTrackingAction()),
  editManifest: data => dispatch(editOrderTrackingAction(data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(OrderTrackingManifestPageComponent);
