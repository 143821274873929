import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, CopyToClipboard } from '@bottomless/common/components';

const Schema = Yup.object()
  .shape({
    twitter: Yup.string(),
    instagram: Yup.string(),
    tiktok: Yup.string(),
    youtube: Yup.string(),
    facebook: Yup.string(),
    linkedin: Yup.string(),
    credits: Yup.number(),
    months: Yup.number(),
    expire_at: Yup.date()
      .max(new Date('9999'), 'Future date not allowed')
      .optional(),
  })
  .test({
    name: 'one-of-six',
    message: 'You must provide at least one social media',
    test: function(value) {
      let err = true;
      if (!(value.linkedin || value.twitter || value.instagram || value.tiktok || value.youtube || value.facebook)) {
        err = this.createError({ path: 'twitter', message: 'You must provide at least one social media' });
      }
      return err;
    },
  })
  .test({
    name: 'one-of-two',
    message: 'One of months or credits should be more than 0',
    test: function(value) {
      let err = true;
      if (!(value.months || value.credits)) {
        err = this.createError({ path: 'credits', message: 'One of months or credits should be more than 0 ' });
      }
      return err;
    },
  });

const now = new Date();
export const SocialMediaForm = ({ generateInfluencerLink, onSubmitSuccess }) => {
  const [url, setUrl] = useState(null);

  const onSubmit = async data => {
    const { payload } = await generateInfluencerLink(data);
    setUrl(payload.link);
  };

  return (
    <>
      <Form
        initialValues={{
          twitter: '',
          instagram: '',
          tiktok: '',
          youtube: '',
          facebook: '',
          linkedin: '',
          credits: 0,
          months: 0,
          expire_at: new Date(now.setDate(now.getDate() + 1)),
        }}
        validationSchema={Schema}
        onSubmit={onSubmit}
        onSuccess={onSubmitSuccess}
      >
        {({ isSubmitting }) => (
          <>
            <Field name="linkedin" label="Linkedin" />
            <Field name="twitter" label="Twitter" />
            <Field name="instagram" label="Instagram" />
            <Field name="tiktok" label="Tiktok" />
            <Field name="youtube" label="Youtube" />
            <Field name="facebook" label="Facebook" />
            <Field name="credits" label="Credits" type="number" />
            <Field name="months" label="Months" type="number" />
            <Field type="date" name="expire_at" label="Expiration Date" />
            <SubmitButton color="dark" isSubmitting={isSubmitting}>
              Generate
            </SubmitButton>
          </>
        )}
      </Form>
      {url && (
        <div className="d-flex flex-column text-sm mb-4">
          <div className="mb-2">
            <code>{url}</code>
          </div>
          <div>
            <CopyToClipboard text={url} />
          </div>
        </div>
      )}
    </>
  );
};

SocialMediaForm.propTypes = {
  generateInfluencerLink: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
