import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { createSelectOptions } from '@bottomless/common/utils';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getDisLikedProductsAction, selectSubproductAction, triggerSubproductSelectionAction } from '../../../../store';
import { getProductsAction } from '../../../../store/admin/product';
import { withPermamentAlerts } from '../../../../components/PermamentAlerts/PermamentAlerts';
import { Filters } from '../components/Filters';
import { Order } from '../components/Order';
import { tabs } from './Tabs';
import { Link } from 'react-router-dom';
import { TriggerSelection } from './components/TriggerSelection';
import { getTriggerCountsAction } from '../../../../store/admin/ordergen';
import { Categories } from '@bottomless/common/constants';

const INIT_DATA = 'init';
const SET_DATA = 'set';
const REMOVE_RECORD = 'remove';

const SubproductSelectionPageComponent = ({
  getOrders,
  getProducts,
  selectSubproduct,
  getDisLikedProducts,
  addAlert,
  location,
  addToast,
  getTriggerCounts,
  triggerSubproductSelection,
}) => {
  const search = useMemo(() => qs.parse(location.search), [location.search]);
  const [products, setProducts] = useState(null);
  const [emptyCounter, setEmptyCounter] = useState(0);
  const [orders, dispatchOrders] = useReducer((state, action) => {
    switch (action.type) {
      case INIT_DATA:
        return action.payload;

      case SET_DATA:
        if (!action.payload) {
          return action.payload;
        }

        return [...(state || []), ...action.payload];
      case REMOVE_RECORD:
        return state.filter(o => o._id !== action.payload._id);
    }
  }, null);

  useDataEffect(getOrders, payload => {
    setEmptyCounter(payload.length ? 0 : 2);
    dispatchOrders({ payload, type: INIT_DATA });
  });

  useDataEffect(getProducts, response => setProducts(get(response, 'docs', [])), {
    status: 'active',
    sort: 'name',
    dir: 1,
    category: Categories.Coffee,
    ...(search.disable !== undefined ? {} : { fulfilling: true }),
  });

  const onLoadMore = useCallback(async () => {
    dispatchOrders({ payload: null, type: SET_DATA });

    (async () => {
      const { payload, error } = await getOrders();

      if (!error) {
        setEmptyCounter(payload.length ? 0 : emptyCounter + 1);
        dispatchOrders({ payload, type: SET_DATA });
      }
      // eslint-disable-next-line no-console
    })().catch(console.error);
  }, [dispatchOrders, emptyCounter, getOrders]);

  const onChooseSubproduct = useCallback(
    async (order, subproduct) => {
      dispatchOrders({ payload: order, type: REMOVE_RECORD });
      try {
        const { payload, error } = await selectSubproduct(order._id, subproduct);

        if (error) {
          addAlert(
            `Failed for ${order.user_id.first_name} ${order.user_id.last_name} - ${payload.response.message}`,
            'error',
            <Link to={`/admin/subproduct-selection/${order._id}`} target="_blank">
              Go to subproduct selection
            </Link>
          );
        } else {
          addAlert(
            `Subproduct selected for ${order.user_id.first_name} ${order.user_id.last_name}`,
            'success',
            <Link to={`/admin/subproduct-selection/${order._id}`} target="_blank">
              Go to subproduct selection
            </Link>
          );
        }
      } catch (e) {
        addAlert(
          `Failed for ${order.user_id.first_name} ${order.user_id.last_name} - ${e.message}`,
          'error',
          <Link to={`/admin/subproduct-selection/${order._id}`} target="_blank">
            Go to subproduct selection
          </Link>
        );
      }
    },
    [dispatchOrders]
  );

  const rotationProductsOptions = useMemo(() => {
    return createSelectOptions(
      (products || []).filter(p => p.rotating),
      '-- Any Rotation --'
    );
  }, [products]);

  const onTriggerSelection = useCallback(data => triggerSubproductSelection(search, data), [
    triggerSubproductSelection,
    search,
  ]);

  return (
    <PanelPage
      title="Subproduct selection"
      tabs={tabs}
      heading={
        <TriggerSelection
          triggerSelection={onTriggerSelection}
          addToast={addToast}
          orders={orders}
          getTriggerCounts={getTriggerCounts}
        />
      }
      filters={
        rotationProductsOptions && (
          <div className="mt-2">
            <Filters rotationsOptions={rotationProductsOptions} />
          </div>
        )
      }
    >
      <DataLoading count={orders ? orders.length : 0} isLoading={orders === null || products === null}>
        <div />
      </DataLoading>
      {products && (
        <>
          {orders && orders.length > 0 && (
            <Order
              key={orders[0]._id}
              order={orders[0]}
              products={products}
              onChooseSubproduct={onChooseSubproduct}
              getDisLikedProducts={getDisLikedProducts}
            />
          )}
          {emptyCounter >= 2 && (
            <div className="text-center">
              <div className="d-block mb-3">Subproduct selection done 🎉</div>
            </div>
          )}
          {orders && emptyCounter < 2 && orders.length <= 0 && (
            <div className="text-center">
              <Button onClick={onLoadMore}>Load more orders</Button>
            </div>
          )}
        </>
      )}
    </PanelPage>
  );
};

SubproductSelectionPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  selectSubproduct: PropTypes.func.isRequired,
  getDisLikedProducts: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  addToast: PropTypes.func.isRequired,
  getTriggerCounts: PropTypes.func.isRequired,
  triggerSubproductSelection: PropTypes.func.isRequired,
};

export const SubproductSelectionPage = connect(null, dispatch => ({
  selectSubproduct: (id, data) => dispatch(selectSubproductAction(id, data)),
  getProducts: params => dispatch(getProductsAction(params)),
  getDisLikedProducts: params => dispatch(getDisLikedProductsAction(params)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getTriggerCounts: () => dispatch(getTriggerCountsAction()),
  triggerSubproductSelection: (params, data) => dispatch(triggerSubproductSelectionAction(params, data)),
}))(withPermamentAlerts(SubproductSelectionPageComponent));
