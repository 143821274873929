import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUnprocessedFulfillment } from '../../../store/admin/order';
import { FulfillmentPage } from './Fulfillment';

const UnprocessedFulfillmentPageComponent = ({ getOrders }) => <FulfillmentPage getOrders={getOrders} />;

UnprocessedFulfillmentPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
};

export const UnprocessedFulfillmentPage = connect(null, dispatch => ({
  getOrders: () => dispatch(getUnprocessedFulfillment()),
}))(UnprocessedFulfillmentPageComponent);
