import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, Field } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  tracking_number: Yup.string().required(),
});

export const UpdateTrackingModal = ({ isOpen, toggle, onSubmit, shipmentToUpdate }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm">
      <ModalHeader toggle={toggle}>Update Tracking</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{ tracking_number: shipmentToUpdate?.tracking_number || '' }}
          validationSchema={Schema}
          onSubmit={onSubmit}
          onSuccess={() => {}}
        >
          {() => (
            <>
              <Field name="tracking_number" type="text" label="Tracking number" />
              <Button color="success">
                <span className="mr-2">Update Tracking Number</span>
              </Button>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

UpdateTrackingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  shipmentToUpdate: PropTypes.object,
};
