import React from 'react';
import PropTypes from 'prop-types';
import { connectWithAbort } from '@bottomless/common/store';
import { getOnTheWayOrderGenAction } from '../../../../store/admin/ordergen';
import { PaginatedOrderGenPage } from './PaginatedOrderGen';

const OnTheWayOrderGenPageComponent = ({ getOnTheWay }) => <PaginatedOrderGenPage getUsers={getOnTheWay} />;

OnTheWayOrderGenPageComponent.propTypes = {
  getOnTheWay: PropTypes.func.isRequired,
};

export const OnTheWayOrderGenPage = connectWithAbort(null, dispatch => ({
  getOnTheWay: params => dispatch(getOnTheWayOrderGenAction(params)),
}))(OnTheWayOrderGenPageComponent);
