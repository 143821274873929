import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { OrderRow } from './OrderRow';

const OrdersTableComponent = ({
  orders,
  heading,
  disabled,
  accounting,
  calculateCommission,
  setFieldValue,
  onUpdateField,
  batchId,
}) => (
  <>
    <h4>{heading}</h4>
    <Table size="sm" className="mt-3">
      <thead>
        <tr>
          <th>Shipping</th>
          <th>Subproduct</th>
          <th>Size</th>
          <th>A.Paid</th>
          <th>Price</th>
          <th>Shipping</th>
          <th>Stripe%</th>
          <th>Cost</th>
          <th>W.Pricing</th>
          <th>BL%</th>
          <th>Payout</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(order => (
          <OrderRow
            key={order._id}
            order={order}
            disabled={disabled}
            accounting={accounting}
            calculateCommission={calculateCommission}
            setPayoutValue={value => setFieldValue(order._id + 'payout', value)}
            setCommissionValue={value => setFieldValue(order._id + 'commission', value)}
            onUpdateField={onUpdateField}
            batchId={batchId}
          />
        ))}
      </tbody>
    </Table>
  </>
);

OrdersTableComponent.propTypes = {
  orders: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  accounting: PropTypes.object.isRequired,
  calculateCommission: PropTypes.func,
  setFieldValue: PropTypes.func,
  onUpdateField: PropTypes.func.isRequired,
  batchId: PropTypes.string.isRequired,
};

export const OrdersTable = memo(OrdersTableComponent);
