import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetECommerceProductsTypes = createAction('Admin ECommerce', 'Get Products');

export const getECommerceProductsAction = () => ({
  [RSAA]: {
    endpoint: `admin/e-commerce/products`,
    method: 'GET',
    types: [GetECommerceProductsTypes.REQUEST, GetECommerceProductsTypes.SUCCESS, GetECommerceProductsTypes.FAILURE],
  },
});

export const GetECommercePaidOrderTypes = createAction('Admin ECommerce', 'Get Paid Orders');

export const getECommercePaidOrdersAction = () => ({
  [RSAA]: {
    endpoint: `admin/e-commerce/paid`,
    method: 'GET',
    types: [GetECommercePaidOrderTypes.REQUEST, GetECommercePaidOrderTypes.SUCCESS, GetECommercePaidOrderTypes.FAILURE],
  },
});

export const GetECommerceFulfilledOrderTypes = createAction('Admin ECommerce', 'Get Fulfilled Orders');

export const getECommerceFulfilledOrdersAction = () => ({
  [RSAA]: {
    endpoint: `admin/e-commerce/fulfilled`,
    method: 'GET',
    types: [
      GetECommerceFulfilledOrderTypes.REQUEST,
      GetECommerceFulfilledOrderTypes.SUCCESS,
      GetECommerceFulfilledOrderTypes.FAILURE,
    ],
  },
});

export const FulfillECommerceOrderTypes = createAction('Admin ECommerce', 'Fulfill Order');

export const fulfillECommerceOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/e-commerce/fulfill/${id}`,
    method: 'PATCH',
    body: data,
    types: [FulfillECommerceOrderTypes.REQUEST, FulfillECommerceOrderTypes.SUCCESS, FulfillECommerceOrderTypes.FAILURE],
  },
});

export const updateECommerceOrderTypes = createAction('Admin ECommerce', 'Update Order');

export const updateECommerceOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/e-commerce/order/${id}`,
    method: 'PATCH',
    body: data,
    types: [updateECommerceOrderTypes.REQUEST, updateECommerceOrderTypes.SUCCESS, updateECommerceOrderTypes.FAILURE],
  },
});

export const StartECommerceOrderProcessingTypes = createAction('Admin ECommerce', 'Fulfill Order');

export const startECommerceOrderProcessingAction = id => ({
  [RSAA]: {
    endpoint: `admin/e-commerce/process/${id}`,
    method: 'POST',
    types: [
      StartECommerceOrderProcessingTypes.REQUEST,
      StartECommerceOrderProcessingTypes.SUCCESS,
      StartECommerceOrderProcessingTypes.FAILURE,
    ],
  },
});
