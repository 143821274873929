import React from 'react';
import PropTypes from 'prop-types';

export const SlowLocalOrder = ({ slowLocalOrder }) => (
  <>
    <div className="font-weight-bold mt-2 mb-2">Slow local order:</div>
    {slowLocalOrder.products?.map((product, i) => (
      <div key={i}>
        {product.quantity}x {product.product.name}
      </div>
    ))}
  </>
);

SlowLocalOrder.propTypes = {
  slowLocalOrder: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number.isRequired,
        product: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
  }).isRequired,
};
