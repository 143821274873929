import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

export const Rotation = ({ rotation, orderVariant, onSubproductClick }) => {
  const getProduct = useCallback(id => rotation.fetched.find(f => f._id === id), [rotation.fetched]);
  const isVariantQualified = useCallback(
    variant => variant.available && variant.size <= orderVariant.size * 1.2 && variant.size >= orderVariant.size * 0.8,
    [orderVariant]
  );

  return (
    <Table size="sm">
      <thead>
        <tr className="row-clickable">
          <th>Name</th>
          <th>Vendor</th>
        </tr>
      </thead>
      <tbody>
        {rotation.products.map((rotationProduct, i) => {
          const product = getProduct(rotationProduct.product);
          let variant = product.variants.find(v => v._id === rotationProduct.variant);

          if (!isVariantQualified(variant)) {
            variant = product.variants.find(isVariantQualified) || {};
          }

          return (
            <tr
              key={i}
              onClick={onSubproductClick({ ...rotationProduct, variant: variant._id })}
              className="row-clickable"
            >
              <td>{product.name}</td>
              <td>{product.vendor_name}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

Rotation.propTypes = {
  onSubproductClick: PropTypes.func.isRequired,
  rotation: PropTypes.shape({
    fetched: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
      })
    ).isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.string.isRequired,
        variant: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  orderVariant: PropTypes.shape({
    size: PropTypes.number.isRequired,
  }),
};
