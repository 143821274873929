import classNames from 'classnames';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Loader } from 'react-feather';
import { Badge, Col, Collapse, Row } from 'reactstrap';
import { useConditionalDataEffect, useToggle } from '@bottomless/common/hooks';

export const Tracking = ({ tracking_number, onTracking, withDetails, opened }) => {
  const [tracking, setTracking] = useState(null);
  const [isOpen, toggle] = useToggle(opened);

  useEffect(() => {
    toggle();
  }, [opened]);

  useConditionalDataEffect(!tracking, onTracking, setTracking, tracking_number, null);

  if (!tracking_number) {
    return null;
  }

  const status = get(tracking, 'status');

  return (
    <div className="usps-tracking">
      {!tracking && (
        <div className="d-flex justify-content-center align-items-center">
          <Loader size="18" className="spin mr-2" />
          <span>Loading tracking info</span>
        </div>
      )}
      {tracking && (
        <Row>
          <Col>
            <div className="usps-status" onClick={toggle}>
              USPS status:{' '}
              <Badge
                color={classNames({
                  danger: ['return_to_sender', 'alert'].includes(status),
                  success: status === 'delivered',
                  primary: ['accepted', 'in_transit'].includes(status),
                  warning: status === 'pre_transit',
                  secondary: status === 'unknown',
                })}
              >
                {get(tracking, 'status', '---')}
              </Badge>
            </div>
            {withDetails && (
              <Collapse isOpen={isOpen}>
                <ol className="text-sm pt-2" reversed>
                  {(tracking.summary || []).map(({ status, id, description }) => (
                    <li key={id}>
                      <span
                        className={classNames('mr-1', {
                          'text-success': ['out_for_delivery', 'delivered'].includes(status),
                          'text-primary': ['accepted', 'in_transit'].includes(status),
                          'text-info': status === 'pre_transit',
                          'text-danger': ['return_to_sender', 'alert'].includes(status),
                        })}
                      >
                        {status}
                      </span>
                      <span>{description}</span>
                    </li>
                  ))}
                </ol>
              </Collapse>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

Tracking.defaultProps = {
  withDetails: true,
  opened: true,
};

Tracking.propTypes = {
  tracking_number: PropTypes.string.isRequired,
  onTracking: PropTypes.func.isRequired,
  withDetails: PropTypes.bool,
  opened: PropTypes.bool,
};
