import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import { RefreshCw } from 'react-feather';
import { Row, Col } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { HeadingBack } from '../../../../components/HeadingBack/HeadingBack';
import { getOrdersFrequencyAction, getUserAction, setDumbPeriodAction } from '../../../../store/admin/user';
import { Product } from './components/Product';
import { UserInfo } from './components/UserInfo';
import { Scale } from './components/Scale';
import { DumbSubscription } from './components/DumbSubscription';

const DisconnectedUserPageComponent = ({
  getUser,
  setDumbPeriod,
  getOrdersFrequency,
  addToast,
  match,
  location: { search },
}) => {
  const [user, setUser] = useState(null);

  const query = qs.parse(search);
  const { id } = match.params;
  const Heading = HeadingBack({
    to: query.back || '/admin/disconnected/all',
  });

  const { data: ordersFrequency } = useDataEffect(getOrdersFrequency);
  useDataEffect(getUser, setUser, id);

  const onSetDumbPeriod = () => addToast('Fixed Frequency Subscription has been updated');

  return (
    <PanelPage title={user ? `${user.first_name} ${user.last_name}` : ''} heading={Heading}>
      <DataLoading count={user ? 1 : 0} isLoading={Boolean(user)} />
      {user && (
        <>
          <Row>
            <Col xs="12" sm="5">
              <h4>Product</h4>
              <Product product={user.product} personalized={user.personalized} user={user} />
              <h4>User info</h4>
              <UserInfo user={user} />
            </Col>
            <Col xs="12" sm="7">
              <h4>Scale</h4>
              <Scale user={user} />
              <h4>
                Dumb subscribtion
                <span className="ml-2">
                  <RefreshCw size="18" />
                </span>
              </h4>
              <DumbSubscription user={user} onSubmit={setDumbPeriod} onSuccess={onSetDumbPeriod} />
              {ordersFrequency && (
                <>
                  {!!ordersFrequency.frequency && (
                    <div className="text-secondary mt-4">
                      <span className="text-success">{ordersFrequency.frequency}</span> - suggested value based on
                      orders frequency
                    </div>
                  )}
                  {!ordersFrequency.frequency && (
                    <div className="text-secondary mt-4">Couldn&apos;t suggested any value based on orders</div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </PanelPage>
  );
};

DisconnectedUserPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  getOrdersFrequency: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export const DisconnectedUserPage = connect(null, (dispatch, { match: { params } }) => ({
  addToast: message => dispatch(addToastAction(message)),
  getUser: id => dispatch(getUserAction(id)),
  setDumbPeriod: data => dispatch(setDumbPeriodAction(params.id, data)),
  getOrdersFrequency: () => dispatch(getOrdersFrequencyAction(params.id)),
}))(DisconnectedUserPageComponent);
