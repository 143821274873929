export const getVariantSelectLabel = variant => {
  const selectOptions = [];
  if (variant.size) {
    selectOptions.push(`Size: ${variant.size}`);
  }
  if (variant.website_pricing || variant.price) {
    selectOptions.push(`Price: $${variant.website_pricing || variant.price}`);
  }
  if (variant.options?.length) {
    const variantOptStr = variant.options
      .filter(row => row.name !== 'Title')
      .map(row => row.value)
      .filter(row => !!row)
      .join(', ');
    if (variantOptStr) {
      selectOptions.push(variantOptStr);
    }
  }

  return selectOptions.join(' - ');
};
