import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';

const fields = [
  { name: 'smoke', label: 'Smoke' },
  { name: 'subproduct', label: 'Subproduct' },
  { name: 'charge', label: 'Charge' },
  { name: 'shopify', label: 'Shopify' },
  { name: 'shipment', label: 'Shipment' },
];

export const MiniErrors = ({ order }) => {
  const errors = order.fulfillmentErrors || {};
  const getColor = useCallback(err => {
    if (!err || !err.date) {
      return 'secondary';
    }

    if (err.error) {
      return 'danger';
    }

    return 'success';
  }, []);

  return (
    <Row className="text-sm">
      {fields.map(({ name, label }) => (
        <Col xs="4" key={name} className={classNames(`text-${getColor(errors[name])}`)}>
          {label}
        </Col>
      ))}
    </Row>
  );
};

const fulfillmentError = PropTypes.shape({
  date: PropTypes.string,
  error: PropTypes.string,
});

MiniErrors.propTypes = {
  order: PropTypes.shape({
    fulfillmentErrors: PropTypes.shape({
      smoke: fulfillmentError,
      subproduct: fulfillmentError,
      charge: fulfillmentError,
      shopify: fulfillmentError,
    }),
  }).isRequired,
};
