import PropTypes from 'prop-types';
import React from 'react';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { DateFormat, Image } from '@bottomless/common/components';

export const Rotation = ({ rotation, back }) => {
  return (
    <Card className="mb-3 flex-row align-items-center admin-rotation-card">
      <div className="card-media">
        {rotation.background && (
          <Image src={rotation.background} alt={rotation.background} maxWidth="120" maxHeight="120" />
        )}
      </div>
      <CardBody className="d-flex justify-content-start">
        <div className="mr-4">
          <span className="text-secondary text-sm d-block">#{rotation._id}</span>
          <span>{rotation.slug}</span>
        </div>
        <div>
          {rotation.updated_at && (
            <span className="text-sm d-block">
              Updated: <DateFormat withTime date={rotation.updated_at} />
            </span>
          )}
        </div>
        <div className="ml-auto">
          <Link to={`/admin/rotations/${rotation._id}${back ? '?back=' + back : ''}`} className="text-info">
            <Edit size="20" />
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};

Rotation.propTypes = {
  rotation: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    updated_at: PropTypes.string,
  }).isRequired,
  back: PropTypes.string,
};
