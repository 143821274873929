import classNames from 'classnames';
import { get, has } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { CheckSquare, ChevronDown, ChevronUp, Loader, Square } from 'react-feather';
import LazyLoad, { forceCheck } from 'react-lazyload';
import * as Yup from 'yup';
import { Badge, Button, ButtonGroup, Card, Col, Collapse, Row } from 'reactstrap';
import { DateFormat, Form, Price, Field, SubmitButton } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { createSelectOptions } from '@bottomless/common/utils';
import { Tracking } from '../../../../components/UspsTracking/Tracking';

const color = status =>
  ({
    canceled: 'danger',
    failure: 'danger',
    arrived: 'success',
    shipped: 'warning',
    created: 'info',
    requested: 'primary',
  }[status] || 'secondary');

const Schema = Yup.object().shape({
  scaleDeviceId: Yup.string().required('This field is required'),
});

export const ShipmentComponent = ({
  shipment,
  onEdit,
  syncStatus,
  purchaseScaleShipment,
  onSelect,
  onBatchClick,
  selected,
  onTracking,
  toggleUppdateTrackingModal,
  setShipmentToUpdate,
  scaleDevices,
}) => {
  const [checked, setChecked] = useState(false);
  const [isOpen, toggle] = useToggle();

  const scaleDeviceOptions = useMemo(
    () => ({ '': '--Choose scale device type--', ...createSelectOptions(scaleDevices) }),
    [scaleDevices]
  );
  const selectedScaleDevice = useMemo(
    () => shipment.scale_device_id && scaleDevices?.find(row => row._id === shipment.scale_device_id),
    [scaleDevices, shipment]
  );

  const onSubmit = useCallback(
    async data => {
      await purchaseScaleShipment(shipment._id, data);
    },
    [purchaseScaleShipment, shipment._id]
  );

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  const onClick = e => {
    e.stopPropagation();
    setChecked(!checked);
    onSelect(shipment);
  };

  useEffect(() => {
    forceCheck();
  }, [isOpen]);

  return (
    <Card className="mb-2" body id="shipment-card">
      <div onClick={toggle} className="d-flex align-items-center cursor-pointer">
        <div className="d-flex flex-row w-100">
          <div>
            <div className="text-secondary">#{shipment._id}</div>
            <div className="text-sm text-secondary">
              <a
                href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${shipment.tracking_number}`}
                rel="noopener noreferrer"
                target="_blank"
                className="text-primary d-block"
              >
                {get(shipment, 'tracking_number', '---')}
              </a>
            </div>
            {has(shipment, 'vendor.name') && (
              <Badge color="warning">Fulfilled By: {get(shipment, 'vendor.name')}</Badge>
            )}
          </div>
          <div className="ml-auto">
            <div>
              Created at: <DateFormat withTime date={shipment.timestamp} />
            </div>
            <div>Updated at: {shipment.updated_at ? <DateFormat withTime date={shipment.updated_at} /> : '---'}</div>
          </div>
          <div className="ml-4">
            <p className="mb-0 d-inline-blo ck fill-200">Type: {shipment.type}</p>
            <p className="mb-0">
              Status: <span className={`font-weight-bold text-${color(shipment.status)}`}>{shipment.status}</span>
            </p>

            {shipment.usps_tracking && (
              <div className="usps-status cursor-pointer">
                New USPS status:{' '}
                <Badge
                  color={classNames({
                    danger: ['alert', 'return_to_sender'].includes(shipment.usps_tracking.status),
                    success: shipment.usps_tracking.status === 'delivered',
                    primary: ['accepted', 'in_transit'].includes(shipment.usps_tracking.status),
                    warning: shipment.usps_tracking.status === 'pre_transit',
                    secondary: shipment.usps_tracking.status === 'unknown',
                  })}
                >
                  {get(shipment, 'usps_tracking.status', '---')}
                </Badge>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-row ml-auto pl-4">
          <div>
            {['requested', 'created'].includes(shipment.status) ? (
              <Button onClick={onClick} color="transparent" className="btn-sm">
                {checked ? <CheckSquare size="15" /> : <Square size="15" />}
              </Button>
            ) : (
              <span className="btn btn-spacer" />
            )}
          </div>
          <div className="flex-grow-0 ml-3">{isOpen ? <ChevronUp size="15" /> : <ChevronDown size="15" />}</div>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <Row className="pt-4">
          <Col xs="6" md="4">
            {has(shipment, 'reason') && <p className="mb-0 text-info">Reason: {get(shipment, 'reason', '---')}</p>}
            <p className="mb-0">Shipping status: {get(shipment, 'shipping_status', '---')} </p>
            <p className="mb-0">Service: {get(shipment, 'service', '---')}</p>
            <p className="mb-0">Cost: {shipment.shipping_cost ? <Price value={shipment.shipping_cost} /> : '---'}</p>
            <p className="mb-0">Scale Device: {get(selectedScaleDevice, 'name', '---')}</p>
          </Col>
          <Col xs="6" md="5">
            <p className="mb-0">
              Tracking:{' '}
              <a
                href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${shipment.tracking_number}`}
                rel="noopener noreferrer"
                target="_blank"
                className="text-primary"
              >
                {get(shipment, 'tracking_number', '---')}
              </a>
            </p>
            <p className="mb-0">
              Batch:{' '}
              <span
                className="text-primary cursor-pointer"
                onClick={() => shipment.batch_id && onBatchClick(shipment.batch_id)}
              >
                {get(shipment, 'batch_id', '---')}
              </span>
            </p>
            <p className="mb-0">Tracker id: {get(shipment, 'easypost_id', '---')}</p>
            {shipment.label_url && (
              <p className="mb-0">
                Shipping label:{' '}
                <a href={shipment.label_url} rel="noopener noreferrer" target="_blank" className="text-primary">
                  label
                </a>
              </p>
            )}
          </Col>
          <Col xs="6" md="3">
            <div className="text-right">
              <ButtonGroup vertical>
                {['requested', 'created'].includes(shipment.status) && (
                  <Button
                    size="sm"
                    className="mb-2"
                    color="danger"
                    onClick={() => onEdit({ ...shipment, status: 'canceled' })}
                  >
                    Cancel shipment
                  </Button>
                )}
                <Button
                  size="sm"
                  className="mb-2"
                  color="success"
                  onClick={async () => {
                    setShipmentToUpdate(shipment);
                    toggleUppdateTrackingModal();
                  }}
                >
                  Update Tracking
                </Button>
                {!['requested', 'canceled'].includes(shipment.status) && (
                  <>
                    {shipment.status === 'created' && (
                      <Button
                        size="sm"
                        className="mb-2"
                        color="primary"
                        onClick={() => onEdit({ ...shipment, status: 'shipped' })}
                      >
                        Change status to shipped
                      </Button>
                    )}
                    {shipment.status === 'shipped' && (
                      <Button
                        size="sm"
                        className="mb-2"
                        color="success"
                        onClick={() => onEdit({ ...shipment, status: 'arrived' })}
                      >
                        Change status to arrived
                      </Button>
                    )}
                  </>
                )}
                {shipment.tracking_number && (
                  <Button size="sm" color="info" onClick={() => syncStatus(shipment.tracking_number)}>
                    Sync tracking status
                  </Button>
                )}
                {shipment.status === 'requested' && (
                  <Form
                    initialValues={{ scaleDeviceId: shipment.scale_device_id }}
                    validationSchema={Schema}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <>
                        <Field name="scaleDeviceId" type="select" label="Scale Device" options={scaleDeviceOptions} />
                        <SubmitButton size="sm" className="mb-2" color="warning" isSubmitting={isSubmitting}>
                          Buy shipping label
                        </SubmitButton>
                      </>
                    )}
                  </Form>
                )}
              </ButtonGroup>
            </div>
          </Col>
        </Row>

        {shipment.tracking_number && (
          <LazyLoad
            once
            height={200}
            scrollContainer={'#panel-content'}
            placeholder={
              <span>
                <Loader size="15" className="spin ml-2" /> Loading
              </span>
            }
          >
            <Tracking
              once
              tracking_number={shipment.tracking_number}
              onTracking={onTracking}
              shippingService={'USPS'}
            />
          </LazyLoad>
        )}
      </Collapse>
    </Card>
  );
};

ShipmentComponent.defaultProps = {
  selected: false,
};

ShipmentComponent.propTypes = {
  shipment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    batch_id: PropTypes.string,
    easypost_id: PropTypes.string,
    status: PropTypes.string,
    service: PropTypes.string,
    type: PropTypes.string,
    shipping_cost: PropTypes.number,
    label_url: PropTypes.string,
    shipping_status: PropTypes.string,
    tracking_number: PropTypes.string,
    timestamp: PropTypes.string,
    updated_at: PropTypes.string,
    scheduled_at: PropTypes.string,
    usps_tracking: PropTypes.object,
    scale_device_id: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  syncStatus: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onTracking: PropTypes.func.isRequired,
  onBatchClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  purchaseScaleShipment: PropTypes.func.isRequired,
  setShipmentToUpdate: PropTypes.func.isRequired,
  toggleUppdateTrackingModal: PropTypes.func.isRequired,
  scaleDevices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
