import React, { memo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';

const schema = Yup.object().shape({
  refundAmount: Yup.string().required('Refund amount is required'),
  reason: Yup.string().required('Reason is required'),
  id: Yup.string().required('An error occurred, please try again later'),
});

const OrderRefundModalComponent = ({
  isOrderRefundModalOpen,
  toggleOrderRefundModal,
  refundOrder,
  onRefundOrderSuccess,
  charge,
  formatCents,
}) => {
  return (
    <Modal isOpen={isOrderRefundModalOpen} toggle={toggleOrderRefundModal} size="lg" className="order-refund-modal">
      <ModalHeader toggle={toggleOrderRefundModal}>Refund Order</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{
            id: charge.id,
            shouldNotify: false,
            reason: 'Customer Requested',
            refundAmount: formatCents(charge.amount_paid + (charge.tax_paid || 0)),
          }}
          validationSchema={schema}
          onSubmit={refundOrder}
          onSuccess={onRefundOrderSuccess}
        >
          {({ isSubmitting }) => (
            <>
              <Field type="hidden" name="id" disabled />
              <Field name="refundAmount" label="Total Refund Amount" disabled />
              <Field name="reason" label="Reason" disabled />
              <Field name="details" type="textarea" label="Add more details about this refund" />
              <Checkbox name="shouldNotify" label="Notify Customer?" />
              <div className="mt-2 d-flex justify-content-end">
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Confirm
                </SubmitButton>
              </div>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

OrderRefundModalComponent.propTypes = {
  toggleOrderRefundModal: PropTypes.func.isRequired,
  formatCents: PropTypes.func.isRequired,
  refundOrder: PropTypes.func.isRequired,
  onRefundOrderSuccess: PropTypes.func.isRequired,
  charge: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    total_paid: PropTypes.number.isRequired,
    date_charged: PropTypes.string.isRequired,
    amount_paid: PropTypes.number,
    shipment_paid: PropTypes.number,
    tax_paid: PropTypes.number,
    status: PropTypes.string,
  }),
  isOrderRefundModalOpen: PropTypes.bool,
};

OrderRefundModalComponent.defaultProps = {
  charge: {
    amount_paid: 0,
    shipment_paid: 0,
    tax_paid: 0,
    status: '',
  },
  isOrderRefundModalOpen: false,
};

export const OrderRefundModal = memo(OrderRefundModalComponent);
