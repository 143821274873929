import { useEffect, useState } from "react";

let cache = [];

export const useStyle = src => {
  const [state, setState] = useState({
    loaded: false,
    error: false
  });

  useEffect(
    () => {
      if (cache.includes(src)) {
        setState({
          loaded: true,
          error: false
        });
      } else {
        cache.push(src);

        let link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = src;
        link.type = "text/css";

        // Script event listener callbacks for load and error
        const onLoad = () => {
          setState({
            loaded: true,
            error: false
          });
        };

        const onError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cache.indexOf(src);
          if (index >= 0) cache.splice(index, 1);
          link.remove();

          setState({
            loaded: true,
            error: true
          });
        };

        link.addEventListener("load", onLoad);
        link.addEventListener("error", onError);

        document.head.appendChild(link);

        // Remove event listeners on cleanup
        return () => {
          link.removeEventListener("load", onLoad);
          link.removeEventListener("error", onError);
        };
      }
    },
    [src] // Only re-run effect if script src changes
  );

  return [state.loaded, state.error];
};
