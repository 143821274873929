import React from 'react';
import PropTypes from 'prop-types';
import { connectWithAbort } from '@bottomless/common/store';
import { getDoneOrderGenAction } from '../../../../store/admin/ordergen';
import { PaginatedOrderGenPage } from './PaginatedOrderGen';

const DoneOrderGenPageComponent = ({ getDone }) => <PaginatedOrderGenPage getUsers={getDone} />;

DoneOrderGenPageComponent.propTypes = {
  getDone: PropTypes.func.isRequired,
};

export const DoneOrderGenPage = connectWithAbort(null, dispatch => ({
  getDone: params => dispatch(getDoneOrderGenAction(params)),
}))(DoneOrderGenPageComponent);
