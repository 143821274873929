import React, { memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Collapse, Input, FormGroup } from 'reactstrap';
import { isNil } from 'lodash-es';
import * as Yup from 'yup';
import { Form, Price, DateDiffFormat, SubmitButton } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { OrdersTable } from './OrdersTable';
import {
  VendorPaymentTypes,
  DEFAULT_COMMISSION,
  STATUS_PAID,
  DISCOUNT_ROASTER_REPLACEMENT,
  DEFAULT_SHOPIFY_COMMISSION,
} from '../../constants';
import './BatchShipment.scss';

const UpdateCostSchema = Yup.object();

const BatchShipmentComponent = ({ batch, accounting, onCheck, onUpdateField, onUpdateCostSuccess, updateCosts }) => {
  const [isOpen, toggle] = useToggle(false);

  const findVariant = subproduct_id => subproduct_id.product.variants.find(v => v._id === subproduct_id.variant);

  const calculateCommission = (variant, order) => {
    if (!isNil(order?.bl_commission)) {
      return (order.bl_commission / 100).toFixed(2);
    } else {
      if (accounting.payment_type === VendorPaymentTypes.AMOUNT_PAID) {
        return (
          ((order.amount_paid ? order.amount_paid / 100 : variant.price) *
            (accounting?.commission || DEFAULT_COMMISSION)) /
          100
        ).toFixed(2);
      }
      if (accounting.payment_type === VendorPaymentTypes.POST_PURCHASE_SHOPIFY) {
        return (
          ((order.amount_paid ? order.amount_paid / 100 : variant.price) *
            (accounting?.commission || DEFAULT_SHOPIFY_COMMISSION)) /
          100
        ).toFixed(2);
      }
      if (accounting.payment_type === VendorPaymentTypes.WEBSITE_PRICING) {
        return ((variant.website_pricing * (accounting?.commission || DEFAULT_COMMISSION)) / 100).toFixed(2);
      }
    }
  };

  const summary = useMemo(() => batch.orders.reduce((sum, order) => sum + (order.vendor_payout || 0), 0), [
    batch,
    batch.orders,
  ]);

  const hasIncorrectOrder = useMemo(
    () => !!batch.orders.find(order => Number(order.vendor_payout) === 0 || isNil(order.vendor_payout)),
    [batch]
  );

  const initialValues = useMemo(
    () =>
      batch.orders.reduce(
        (all, order) => ({
          ...all,
          [order._id + 'payout']: order.vendor_payout / 100 || 0,
          [order._id + 'cost']: findVariant(order.subproduct_id).cost,
          [order._id + 'website_pricing']: findVariant(order.subproduct_id).website_pricing,
          [order._id + 'commission']: calculateCommission(findVariant(order.subproduct_id), order),
        }),
        {}
      ),
    [batch]
  );

  useEffect(() => {
    if (batch.payout_id) {
      onCheck(batch._id, false);
    }
  }, [batch, onCheck]);

  const onClick = useCallback(e => e.stopPropagation(), []);
  const onChange = useCallback(e => onCheck(batch._id, e.target.checked), [batch._id, onCheck]);

  const successfulOrders = useMemo(
    () => batch.orders.filter(order => order.discount_type !== DISCOUNT_ROASTER_REPLACEMENT),
    [batch]
  );

  const roasterReplacementOrders = useMemo(
    () => batch.orders.filter(order => order.discount_type === DISCOUNT_ROASTER_REPLACEMENT),
    [batch]
  );

  const onSubmit = useCallback(
    data => {
      return updateCosts(
        batch._id,
        Object.entries(data).reduce((all, [_id, value]) => ({ ...all, [_id]: value }), {})
      );
    },
    [batch]
  );

  return (
    <Card className="mb-3" color={hasIncorrectOrder ? 'danger' : undefined} outline>
      <CardBody className="clickable" onClick={toggle}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="mr-4 batch-shipment-check">
              <FormGroup check>
                <Input
                  name={`batch-${batch._id}`}
                  value={batch._id}
                  type="checkbox"
                  onClick={onClick}
                  onChange={onChange}
                  disabled={batch.status === STATUS_PAID || hasIncorrectOrder}
                  checked={batch.payout_id ? false : undefined}
                />
              </FormGroup>
            </div>
            <div>
              <DateDiffFormat date={batch.created_at} />
              <div>Batch: {Array.isArray(batch.batch_id) ? batch.batch_id.join(', ') : batch.batch_id}</div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {batch.status === STATUS_PAID && <div className="text-success mr-4">Paid</div>}
            {batch.status !== STATUS_PAID && <div className="mr-4">Status: {batch.status || 'pending'}</div>}
            <Price value={summary} cents />
          </div>
        </div>
      </CardBody>
      <Collapse isOpen={isOpen}>
        <CardBody className="batch-shipment-orders">
          <Form
            initialValues={initialValues}
            validationSchema={UpdateCostSchema}
            onSubmit={onSubmit}
            onSuccess={onUpdateCostSuccess}
          >
            {({ setFieldValue, isSubmitting, dirty }) => (
              <>
                {successfulOrders.length > 0 && (
                  <OrdersTable
                    heading="Successful orders"
                    orders={successfulOrders}
                    accounting={accounting}
                    calculateCommission={calculateCommission}
                    disabled={!!batch.payout_id}
                    setFieldValue={setFieldValue}
                    onUpdateField={onUpdateField}
                    batchId={batch._id}
                  />
                )}
                {roasterReplacementOrders.length > 0 && (
                  <OrdersTable
                    heading="Roaster replacement orders"
                    orders={roasterReplacementOrders}
                    accounting={accounting}
                    calculateCommission={calculateCommission}
                    disabled={!!batch.payout_id}
                    setFieldValue={setFieldValue}
                    onUpdateField={onUpdateField}
                    batchId={batch._id}
                  />
                )}
                <div className="d-flex justify-content-end">
                  <SubmitButton color="dark" disabled={!dirty || !!batch.payout_id} isSubmitting={isSubmitting}>
                    Save Cost / Website Pricing
                  </SubmitButton>
                </div>
              </>
            )}
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  );
};

BatchShipmentComponent.propTypes = {
  batch: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    batch_id: PropTypes.array,
    orders: PropTypes.array.isRequired,
    payout_id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  accounting: PropTypes.object,
  onCheck: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateCostSuccess: PropTypes.func.isRequired,
  updateCosts: PropTypes.func.isRequired,
};

export const BatchShipment = memo(BatchShipmentComponent);
