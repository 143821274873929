import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Editor from 'react-medium-editor';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { LazyTabs } from '../../../components/LazyTab/LazyTab';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import {
  archiveTemplateAction,
  createTemplateAction,
  getTemplateAction,
  sendEmailAction,
  updateTemplateAction,
} from '../../../store/admin/emails';

const Schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  title: Yup.string().required('This field is required'),
  from: Yup.string().email(),
  body: Yup.string().required('This field is required'),
});

const previewSchema = Yup.object().shape({
  to: Yup.string()
    .email()
    .required('This field is required'),
});

const fromOptions = {
  'yesreply@bottomless.com': 'yesreply@bottomless.com',
  'support@bottomless.com': 'support@bottomless.com',
  'orders@bottomless.com': 'orders@bottomless.com',
  'alerts@bottomless.com': 'alerts@bottomless.com',
  'operations@bottomless.com': 'operations@bottomless.com',
};

export const EditEmailPageComponent = ({
  me,
  template,
  getTemplate,
  createTemplate,
  updateTemplate,
  sendEmail,
  addToast,
  match: {
    params: { id },
  },
  history,
  archiveTemplate,
}) => {
  const formRef = useRef();
  const [isOpen, toggle] = useToggle();
  const [preview, setPreview] = useState();
  const [isArchiving, setArchiving] = useState(false);

  const initialValues = {
    from: 'yesreply@bottomless.com',
    body: 'Message',
  };

  useEffect(() => {
    if (id) {
      getTemplate();
    }
  }, [id]);

  useEffect(() => {
    if (template) {
      formRef.current.resetForm({ ...initialValues, ...template });
    }
  }, [template]);

  const onSubmit = id ? updateTemplate : createTemplate;
  const onSuccess = id
    ? () => addToast('Template updated', 'success')
    : newTemplate => history.push(`/admin/emails/${newTemplate._id}`);

  const onArchive = useCallback(() => {
    (async () => {
      setArchiving(true);

      const enabled = !template.enabled;
      await archiveTemplate({ enabled });

      addToast(`Tamplate has been ${enabled ? 'archived' : 'unarchived'}`);
    })().finally(() => setArchiving(false));
  }, [setArchiving, archiveTemplate, template]);

  return (
    <>
      <PanelPage title={`${id ? 'Edit' : 'Create'} Template`}>
        <Form
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={data => onSubmit(data)}
          onSuccess={onSuccess}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <>
              <Field name="name" label="Template name" />
              <Field name="from" type="select" options={fromOptions} label="From" />
              <Field name="title" label="title" />
              <LazyTabs headers={['Email', 'Raw']}>
                <div className="border bg-white py-2 px-2 mb-2 mh-200">
                  <Editor
                    text={get(template, 'body', 'New message')}
                    onChange={text => setFieldValue('body', text)}
                    options={{
                      toolbar: {
                        buttons: [
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                          'anchor',
                          'image',
                          'justifyLeft',
                          'justifyCenter',
                          'justifyRight',
                          'justifyFull',
                          'orderedlist',
                          'unorderedlist',
                          'removeFormat',
                          'h2',
                          'h3',
                          'h4',
                        ],
                      },
                      buttonLabels: 'fontawesome',
                      anchor: {
                        targetCheckbox: true,
                      },
                    }}
                  />
                </div>
                <>
                  <textarea readOnly className="form-control" value={values.body} />
                </>
              </LazyTabs>
              <Field type="hidden" name="body" label="title" />

              <div className="mb-3">
                <span className="d-block">Properties</span>
                <div className="text-sm d-flex flex-wrap email-help">
                  <kbd>{`{{user.local.email}}`}</kbd>
                  <kbd>{`{{user.first_name}}`}</kbd>
                  <kbd>{`{{user.last_name}}`}</kbd>
                  <kbd>{`{{user.phone}}`}</kbd>
                  <kbd>{`{{user.grind.name}}`}</kbd>
                  <kbd>{`{{user.scale_status}}`}</kbd>
                  <kbd>{`{{user.scale_last_connected}}`}</kbd>
                  <kbd>{`{{user.scale_last_weight}}`}</kbd>
                </div>
                <span className="d-block">Formatters</span>
                <div className="text-sm d-flex flex-wrap email-help">
                  <kbd>{`{{{magicLink}}}`}</kbd>
                  <kbd>{`{{cents order.amount_paid}}`}</kbd>
                  <kbd>{`{{dollars order.amount_paid}}`}</kbd>
                  <kbd>{`{{dateFormat order.date_arrived}}`}</kbd>
                  <kbd>{`{{fullName user}}`}</kbd>
                  <kbd>{`{{address user}}`}</kbd>
                  <kbd>{`{{referral user}}`}</kbd>
                </div>
              </div>

              <div className="w-100 d-flex align-items-center justify-content-center">
                <Button onClick={onArchive} color="danger" disabled={isArchiving} className="mr-2">
                  {isArchiving && 'Saving...'}
                  {!isArchiving && (template?.enabled ? 'Archive' : 'Unarchive')}
                </Button>
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={() => {
                    setPreview(values);
                    toggle();
                  }}
                >
                  Preview
                </Button>

                <SubmitButton color="success" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </div>
            </>
          )}
        </Form>

        {me && preview && (
          <Modal isOpen={isOpen} toggle={toggle} size="sm">
            <ModalHeader toggle={toggle}>Send preview</ModalHeader>
            <ModalBody>
              <Form
                initialValues={{ ...preview, to: me.local.email }}
                validationSchema={previewSchema}
                onSubmit={data =>
                  sendEmail(
                    {
                      userId: me._id,
                      preview: true,
                    },
                    data
                  )
                }
                onSuccess={() => {
                  toggle();
                  addToast(`Preview sent`, 'success');
                }}
              >
                {() => (
                  <>
                    <Field name="to" label="to" />
                    <Button color="success">Send</Button>
                  </>
                )}
              </Form>
            </ModalBody>
          </Modal>
        )}
      </PanelPage>
    </>
  );
};

EditEmailPageComponent.propTypes = {
  me: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getTemplate: PropTypes.func.isRequired,
  createTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
  }),
  addToast: PropTypes.func.isRequired,
  archiveTemplate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export const EditEmailPage = connect(
  (
    { adminEmails, user },
    {
      match: {
        params: { id },
      },
    }
  ) => ({
    template: id ? adminEmails.template : null,
    me: user.me,
  }),
  (
    dispatch,
    {
      match: {
        params: { id },
      },
    }
  ) => ({
    getTemplate: () => dispatch(getTemplateAction(id)),
    createTemplate: data => dispatch(createTemplateAction(data)),
    updateTemplate: data => dispatch(updateTemplateAction(id, data)),
    sendEmail: (context, data) => dispatch(sendEmailAction(context, data)),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    archiveTemplate: data => dispatch(archiveTemplateAction(id, data)),
  })
)(EditEmailPageComponent);
