import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, SubmitButton } from '@bottomless/common/components';

export const Upsert = ({ checked, products, onSubmit, onSuccess, disabled }) => {
  const handleSubmit = useCallback(
    () =>
      onSubmit({
        products: products
          .filter((_, key) => checked.includes(key))
          .map(({ sensorizable, ...product }) => ({
            ...product,
            sensorizable: sensorizable && String(sensorizable).toLowerCase() === 'true',
          })),
      }),
    [onSubmit, checked, products]
  );

  return (
    <Form initialValues={{}} validationSchema={Yup.object()} onSubmit={handleSubmit} onSuccess={onSuccess}>
      {({ isSubmitting, errors }) => (
        <>
          <SubmitButton color="success" disabled={!checked.length || disabled} isSubmitting={isSubmitting}>
            Upsert
          </SubmitButton>
          {errors?.message && <div className="text-danger">{errors.message}</div>}
        </>
      )}
    </Form>
  );
};

Upsert.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};
