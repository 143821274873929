import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Printer } from 'react-feather';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, DataLoading, Pagination } from '@bottomless/common/components';
import { useDataEffect, useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import {
  changeShipmentStatusAction,
  createBatchAction,
  createScanFormAction,
  getBatchAction,
  getShipmentsActionv2,
  purchaseScaleShipmentAction,
  syncShipmentStatusAction,
  updateTrackingNumberAction,
} from '../../../store/admin/scale-shipments';
import { getTrackingAction } from '../../../store/admin/tracking/tracking.actions';
import { BatchModal } from './components/BatchModal';
import { ShipmentsComponent } from './components/Shipments';
import { Heading } from './components/Heading';
import { UpdateTrackingModal } from './components/UpdateTrackingModal';
import { getScaleDeviceAction } from '../../../store';
import { ScaleDeviceSelectModal } from './components/ScaleDeviceSelectModal';

const backStatuses = ['replacement_back', 'offboarding_back'];

const Schema = Yup.object().shape({
  status: Yup.string().nullable(true),
  search: Yup.string(),
});

const ScaleShipmentsPageComponent = ({
  addToast,
  getShipments,
  shipments,
  isLoading,
  syncStatus,
  changeStatus,
  purchaseScaleShipment,
  createBatch,
  createScanForm,
  getBatch,
  getTracking,
  location,
  isV2data,
  updateTrackingNumber,
  getScaleDevice,
}) => {
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const [selected, setSelected] = useState([]);
  const [printable, setPrintable] = useState([]);
  const [withoutLabel, setWithoutLabel] = useState([]);
  const [batch, setBatch] = useState([]);
  const [batchId, setBatchId] = useState(null);
  const [pendingBatch, setPendingBatch] = useState(false);
  const [isOpen, toggle] = useToggle();
  const [isBatchDetailsOpened, toggleBatchDetails] = useToggle();
  const [isUpdateTrackingModalOpen, toggleUppdateTrackingModal] = useToggle();
  const [shipmentToUpdate, setShipmentToUpdate] = useState();
  const [isScaleDeviceSelectModalOpen, toggleScaleDeviceSelectModal] = useToggle();
  const [scaleDevices, setScaleDevices] = useState([]);

  useDataEffect(getScaleDevice, setScaleDevices);

  useEffect(() => {
    getShipments();
    setSelected([]);
  }, [query]);

  const onEdit = async shipment => {
    await changeStatus(shipment);
    addToast(`Shipment status changed to ${shipment.status}`);
  };

  const syncStatusHandler = async id => {
    try {
      await syncStatus(id);
      addToast('Status fetched from EasyPost');
    } catch (e) {
      addToast('Failed to sync status');
    }
  };

  const purchaseShipmentHandler = async (id, data) => {
    try {
      await purchaseScaleShipment(id, data);
      addToast('Label purchased');
    } catch (e) {
      addToast('Failed to purchase label');
    }
  };

  const updateTracking = async data => {
    try {
      await updateTrackingNumber(shipmentToUpdate?._id, data);
      addToast('Traking number updated');
      setShipmentToUpdate();
      toggleUppdateTrackingModal();
    } catch (e) {
      addToast('Failed to update tracking number');
    }
  };

  const onSelect = shipment => {
    if (selected.find(s => s._id === shipment._id)) {
      setSelected(selected.filter(s => s._id !== shipment._id));
    } else {
      setSelected([...selected, shipment]);
    }
  };

  const purchaseBatch = async data => {
    setPendingBatch(true);
    await Promise.all(withoutLabel.map(({ _id }) => purchaseScaleShipment(_id, data).catch(e => addToast(e.message))));
    setBatch(batch.map(s => flatShipments.find(c => c._id === s._id)));
    toggleBatchDetails();
    toggleScaleDeviceSelectModal();
    setPendingBatch(false);
  };

  const onSubmit = data => {
    const filename = `${moment().format('m-d')}-labels-${data.layout}`;

    const labels = printable.length > 0 ? `${printable.map(({ _id }) => `shipments=${_id}`).join('&')}` : '';

    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/scale-shipments/labels/${filename}?${labels}&layout=${data.layout}`
    );

    setSelected([]);
    toggle();
  };

  useEffect(() => {
    setPrintable(selected.filter(shipment => shipment.label_url));
    setWithoutLabel(selected.filter(shipment => !shipment.tracking_number));
    setBatch(selected.filter(shipment => !shipment.batch_id));
  }, [selected]);

  const flatShipments = useMemo(
    () =>
      isV2data &&
      shipments?.docs &&
      shipments.docs
        .map(
          data =>
            data.shipments &&
            data.shipments.map(shipment => ({
              ...shipment,
              user_id: data.user,
            }))
        )
        .flat(),
    [shipments, isV2data]
  );

  const HeadingWithActions = (
    <Heading collection={shipments} onPageChange={getShipments}>
      <Row className="mb-2 justify-content-end align-items-center">
        <Col xs="12" className="text-right">
          {printable.length > 0 && (
            <Button onClick={() => toggle()} color="info">
              <span className="mr-2">Print labels ({printable.length})</span>
              <Printer size="14" />
            </Button>
          )}
          {withoutLabel.length > 0 && (
            <Button
              onClick={toggleScaleDeviceSelectModal}
              disabled={pendingBatch}
              color="success"
              className="btn btn-outline"
            >
              Buy shipment ({withoutLabel.length})
            </Button>
          )}

          {flatShipments && isV2data && (
            <Button
              onClick={() =>
                setSelected(
                  flatShipments.filter(
                    s => ['requested', 'created'].includes(s.status) && !backStatuses.includes(s.type)
                  )
                )
              }
              color="transparent"
              className="ml-2"
            >
              Select all (
              {
                flatShipments.filter(s => ['requested', 'created'].includes(s.status) && !backStatuses.includes(s.type))
                  .length
              }
              )
            </Button>
          )}
        </Col>
      </Row>
    </Heading>
  );

  return (
    <>
      <PanelPage className="page-scale-shipments" heading={HeadingWithActions}>
        <DataLoading count={0} isLoading={isLoading || !isV2data}>
          <span />
        </DataLoading>

        {shipments && shipments.docs.length > 0 && isV2data && (
          <>
            {shipments.docs.map(data => (
              <ShipmentsComponent
                key={data._id}
                shipmentsData={data}
                onEdit={onEdit}
                syncStatus={syncStatusHandler}
                purchaseScaleShipment={purchaseShipmentHandler}
                onSelect={onSelect}
                onBatchClick={batchId => {
                  setBatchId(batchId);
                  toggleBatchDetails();
                }}
                selected={selected}
                onTracking={getTracking}
                setShipmentToUpdate={setShipmentToUpdate}
                toggleUppdateTrackingModal={toggleUppdateTrackingModal}
                scaleDevices={scaleDevices}
              />
            ))}
            <div className="mt-2">
              <Pagination collection={shipments} onPageChange={getShipments} />
            </div>
            <Modal isOpen={isOpen} toggle={toggle} size="sm">
              <ModalHeader toggle={toggle}>Select format</ModalHeader>
              <ModalBody>
                <Form
                  initialValues={{ layout: 'labelPrinter' }}
                  onSubmit={onSubmit}
                  onSuccess={() => {}}
                  validationSchema={Schema}
                >
                  {() => (
                    <>
                      <Field
                        type="select"
                        name="layout"
                        label="Format"
                        options={{ labelPrinter: 'Label Printer (4x6)', fullPage: 'Full page sheet' }}
                      />
                      <Button color="success">
                        <span className="mr-2">Print labels</span>
                        <Printer size="14" />
                      </Button>
                    </>
                  )}
                </Form>
              </ModalBody>
            </Modal>

            <BatchModal
              opened={isBatchDetailsOpened}
              createBatch={createBatch}
              getBatch={getBatch}
              createScanForm={createScanForm}
              batchId={batchId}
              shipments={batch}
              onClose={() => {
                setSelected([]);
              }}
            />

            <UpdateTrackingModal
              isOpen={isUpdateTrackingModalOpen}
              toggle={toggleUppdateTrackingModal}
              onSubmit={updateTracking}
              shipmentToUpdate={shipmentToUpdate}
            />

            <ScaleDeviceSelectModal
              isOpen={isScaleDeviceSelectModalOpen}
              toggle={toggleScaleDeviceSelectModal}
              scaleDevices={scaleDevices}
              onSubmit={purchaseBatch}
            />
          </>
        )}
      </PanelPage>
    </>
  );
};

ScaleShipmentsPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getShipments: PropTypes.func.isRequired,
  shipments: PropTypes.shape({
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.object.isRequired,
        shipments: PropTypes.array.isRequired,
      }).isRequired
    ).isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
  isV2data: PropTypes.bool,
  syncStatus: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  purchaseScaleShipment: PropTypes.func.isRequired,
  createBatch: PropTypes.func.isRequired,
  createScanForm: PropTypes.func.isRequired,
  getBatch: PropTypes.func.isRequired,
  getTracking: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  updateTrackingNumber: PropTypes.func.isRequired,
  getScaleDevice: PropTypes.func.isRequired,
};

export const ScaleShipmentsPagev2 = connect(
  ({ adminScaleShipments }) => ({
    shipments: adminScaleShipments.data,
    isLoading: adminScaleShipments.isLoading,
    isV2data: adminScaleShipments.isV2data,
  }),
  (dispatch, { location: { search } }) => ({
    addToast: message => dispatch(addToastAction(message)),
    getShipments: () => dispatch(getShipmentsActionv2(qs.parse(search))),
    syncStatus: trackingNumber => dispatch(syncShipmentStatusAction(trackingNumber)),
    changeStatus: data => dispatch(changeShipmentStatusAction(data)),
    createBatch: data => dispatch(createBatchAction(data)),
    createScanForm: id => dispatch(createScanFormAction(id)),
    getBatch: id => dispatch(getBatchAction(id)),
    purchaseScaleShipment: (id, data) => dispatch(purchaseScaleShipmentAction(id, data)),
    getTracking: tracking_number => dispatch(getTrackingAction(tracking_number)),
    updateTrackingNumber: (id, data) => dispatch(updateTrackingNumberAction(id, data)),
    getScaleDevice: () => dispatch(getScaleDeviceAction()),
  })
)(ScaleShipmentsPageComponent);
