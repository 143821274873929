import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  tracking_number: Yup.mixed().required(),
  shipping_service: Yup.string().required(),
});

export const TrackingForm = ({ order, onSubmit, onSubmitSuccess }) => {
  return (
    <Form
      initialValues={{
        tracking_number: order.tracking_number,
        shipping_service: order.shipping_service,
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="tracking_number" type="text" label="Tracking number" />
          <Field name="shipping_service" type="text" label="Shipping service" />

          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

TrackingForm.propTypes = {
  order: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
