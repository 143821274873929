import React, { useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FieldArray } from 'formik';
import { Button } from 'reactstrap';
import { Field, Form, SubmitButton } from '@bottomless/common/components';
import { createSelectOptions } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  products: Yup.array().required(),
});

export const QuizForm = ({ quiz, onSubmit, availableProducts }) => {
  const formRef = useRef();
  const products = useMemo(() => quiz?.products.map(p => p._id) || [''], [quiz]);

  const options = useMemo(
    () =>
      createSelectOptions(
        [...availableProducts, ...(quiz?.products || [])],
        '-- Choose product --',
        product => `${product.vendor_name} - ${product.name}`
      ),
    [quiz, availableProducts]
  );

  const onSubmitClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      formRef.current?.submitForm();
    },
    [formRef]
  );

  return (
    <>
      <div className="mb-2">Quiz:</div>
      <div>
        <Form initialValues={{ products }} validationSchema={Schema} onSubmit={onSubmit} innerRef={formRef}>
          {({ isSubmitting, values }) => (
            <>
              <FieldArray
                name="products"
                render={arrayHelpers => (
                  <>
                    {values.products.map((product, index) => (
                      <div key={index} className="d-flex align-items-center">
                        <Field name={`products[${index}]`} type="select" label="Product" options={options} required />
                        {index > 0 && (
                          <Button onClick={() => arrayHelpers.remove(index)} type="button" className="ml-3 mb-3">
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                    <div>
                      <Button onClick={() => arrayHelpers.push('')} type="button" color="link" className="mb-3">
                        + Add product
                      </Button>
                    </div>
                  </>
                )}
              />
              <SubmitButton color="success" className="mr-3" isSubmitting={isSubmitting} onClick={onSubmitClick}>
                Save
              </SubmitButton>
            </>
          )}
        </Form>
      </div>
    </>
  );
};

QuizForm.propTypes = {
  quiz: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  availableProducts: PropTypes.array.isRequired,
};
