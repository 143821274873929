import React, { memo, useCallback } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { isNull, isUndefined } from 'lodash-es';
import { Form, Field, SubmitButton, Checkbox, Switch, Radio } from '@bottomless/common/components';
import './ShopifyConfigurationModal.scss';

const schema = Yup.object().shape({
  shopifyManifest: Yup.object().shape({
    handleFulfillmentWebhook: Yup.boolean(),
    widgetPricingRule: Yup.string(),
    widgetRotationPricingRule: Yup.string(),
    postPurchasePricingRule: Yup.string(),
    sendCustomerData: Yup.boolean(),
    storefrontUrl: Yup.string(),
    sendOrderData: Yup.object(),
    useProductAccountWidget: Yup.boolean(),
    useEmbedAccountPicker: Yup.boolean(),
    useQueueAccountWidget: Yup.boolean(),
    useOneOffAccountWidget: Yup.boolean(),
    useFacebookPixel: Yup.boolean(),
    sendCheckoutData: Yup.boolean()
      .nullable()
      .when('abandonedCheckout', {
        is: true,
        then: Yup.boolean().required('This field is required'),
      }),
    abandonedCheckout: Yup.boolean(),
    useGifs: Yup.boolean(),
    simpleSubscription: Yup.boolean(),
  }),
  scraper: Yup.object().shape({
    options: Yup.object(),
    credentials: Yup.object(),
  }),
});

const ShopifyConfigurationModalComponent = ({ toggle, isOpen, vendor, updateVendor, addToast, setVendor }) => {
  const onSubmit = useCallback(
    ({ shopifyManifest, ...data }) =>
      updateVendor(vendor._id, {
        ...data,
        shopifyManifest: { ...shopifyManifest, useGifs: !!shopifyManifest.useGifs },
      }),
    [updateVendor, vendor]
  );

  const onSuccess = (data, { resetForm }) => {
    addToast('Shopify Configuration Updated');

    setVendor(data);
    resetForm(data);
    toggle();
  };

  return (
    <Modal className="shopify-configuration-modal" toggle={toggle} isOpen={isOpen} size="xl">
      <ModalHeader toggle={toggle}>Shopify Configuration</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{
            ...vendor,
            accounting: {
              ...(vendor.accounting || {}),
              payment_type: 'amount_paid',
            },
            shopifyManifest: {
              handleFulfillmentWebhook: false,
              sendCustomerData: false,
              useProductAccountWidget: false,
              useQueueAccountWidget: false,
              useFacebookPixel: false,
              useOneOffAccountWidget: false,
              simpleSubscription: false,
              ...(vendor.shopifyManifest || {}),
              sendOrderData: {
                shopify: vendor.shopifyManifest?.sendOrderData?.shopify || false,
                marketplace: vendor.shopifyManifest?.sendOrderData?.marketplace || false,
              },
              abandonedCheckout: !(
                isNull(vendor.shopifyManifest?.sendCheckoutData) ||
                isUndefined(vendor.shopifyManifest?.sendCheckoutData)
              ),
              sendCheckoutData: vendor.shopifyManifest?.sendCheckoutData || false,
              useGifs: !!vendor.shopifyManifest?.useGifs,
              useShopifyCheckout: !!vendor.shopifyManifest?.useShopifyCheckout,
              useEmbedAccountPicker: !!vendor.shopifyManifest?.useEmbedAccountPicker,
            },
          }}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
          validationSchema={schema}
        >
          {({ isSubmitting, values }) => (
            <>
              <Row>
                <Col>
                  <Field name="scraper.credentials.key" label="API Key" />
                </Col>
                <Col>
                  <Field name="scraper.credentials.secret" label="API Secret" />
                </Col>
              </Row>
              <hr />

              <Row className="row-margin-reset">
                <Col>
                  <Field name="scraper.url" label="Scraper URL(scraper.url)" />
                </Col>
                <Col>
                  <Field name="scraper.accessToken" label="Access Token(scraper.accessToken)" disabled />
                </Col>
              </Row>
              <hr />

              <Field name="scraper.options.collections" label="Collections to Scrape" type="select-creatable" />
              <hr />

              <Row>
                <Col>
                  <Field name="shopifyManifest.widgetPricingRule" label="Widget Pricing Rule" />
                </Col>
                <Col>
                  <Field name="shopifyManifest.widgetRotationPricingRule" label="Widget Rotation Pricing Rule" />
                </Col>
                <Col>
                  <Field name="shopifyManifest.postPurchasePricingRule" label="Post Purchase Pricing Rule" />
                </Col>
              </Row>
              <hr />

              <Row className="row-margin-reset">
                <Field
                  name="shopifyManifest.theme.buttonColor"
                  type="color"
                  label="Theme Button Color(theme.buttonColor)"
                />
              </Row>
              <hr />

              <Row className="row-margin-reset">
                <Checkbox
                  name="shopifyManifest.sendCustomerData"
                  label="This syncs user data upon signup to Shopify (sendCustomerData)"
                />
              </Row>
              <Row className="row-margin-reset">
                <Checkbox
                  name="shopifyManifest.handleFulfillmentWebhook"
                  label="Shopify Vendor Fulfillment (handleFulfillmentWebhook) This syncs fulfillment order tracking details from Shopify; it gets triggered when the order in Shopify gets marked as fulfilled. This also hides Shopify orders from the vendor portal."
                />
              </Row>
              <Row className="row-margin-reset">
                <Checkbox name="shopifyManifest.useGifs" label="Send GIFs in text messages." />
              </Row>
              <hr />

              <Row>
                <Col>
                  <Field name="shopifyManifest.storefrontUrl" label="Store Front URL (storefrontUrl)" />
                </Col>
              </Row>
              <hr />

              <label>Send data to Shopify(sendOrderData)</label>
              <Row>
                <Col>
                  <Checkbox name="shopifyManifest.sendOrderData.shopify" label="Shopify Users(sendOrderData.shopify)" />
                </Col>
                <Col>
                  <Checkbox
                    name="shopifyManifest.sendOrderData.marketplace"
                    label="Marketplace Users(sendOrderData.marketplace)"
                  />
                </Col>
              </Row>

              <hr />
              <Switch name="shopifyManifest.abandonedCheckout" label="Abandoned Shopify Checkouts" />
              <Row>
                <Col>
                  <Radio
                    disabled={!values.shopifyManifest.abandonedCheckout}
                    name="shopifyManifest.sendCheckoutData"
                    type="radio"
                    label="Marketing Campaign"
                    value={false}
                  />
                </Col>
                <Col>
                  <Radio
                    disabled={!values.shopifyManifest.abandonedCheckout}
                    name="shopifyManifest.sendCheckoutData"
                    type="radio"
                    label="Send data to Shopify"
                    value={true}
                  />
                </Col>
              </Row>

              <hr />
              <h5 className="text-danger">CAUTION: FOLLOWING TO BE UPDATED BY DEVELOPMENT ONLY</h5>
              <Row className="row-margin-reset">
                <Checkbox
                  name="shopifyManifest.useProductAccountWidget"
                  label="Use Product Account Widget (useProductAccountWidget)"
                />
              </Row>
              <Row className="row-margin-reset">
                <Checkbox
                  name="shopifyManifest.useQueueAccountWidget"
                  label="Use Queue Account Widget (useQueueAccountWidget)"
                />
              </Row>
              <Row className="row-margin-reset">
                <Checkbox
                  name="shopifyManifest.useOneOffAccountWidget"
                  label="Use One Off Account Widget (useOneOffAccountWidget)"
                />
              </Row>
              <Row className="row-margin-reset">
                <Checkbox name="shopifyManifest.useFacebookPixel" label="Use Facebook Pixel (useFacebookPixel)" />
              </Row>
              <Row className="row-margin-reset">
                <Checkbox
                  name="shopifyManifest.simpleSubscription"
                  label="Show simple subscription option in Shopify"
                />
              </Row>
              <Row className="row-margin-reset">
                <Checkbox name="shopifyManifest.useShopifyCheckout" label="Use Shopify Checkout" />
              </Row>
              <Row className="row-margin-reset">
                <Checkbox name="shopifyManifest.useEmbedAccountPicker" label="Use Embed Account Picker" />
              </Row>
              <div className="d-flex justify-content-end">
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </div>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

ShopifyConfigurationModalComponent.propTypes = {
  vendor: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  updateVendor: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  setVendor: PropTypes.func.isRequired,
};

export const ShopifyConfigurationModal = memo(ShopifyConfigurationModalComponent);
