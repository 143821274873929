import { useDataEffect } from '@bottomless/common/src/hooks';
import { addToastAction } from '@bottomless/common/src/store/toast';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProductsAction, getReportedScalesAction } from '../../../store';
import { ProblemsPage } from './Problems';

const SCALE_NEEDS_SUPPORT_DATE_QUERY = 'support.scale.date';

const ScaleProblemsPage = ({ getNeedSupportForScale, getProducts, addToast }) => {
  const { data: products } = useDataEffect(getProducts, () => {}, {
    status: 'active',
    sort: 'name',
    dir: 1,
  });

  return (
    <ProblemsPage
      getNeedSupportForScale={getNeedSupportForScale}
      products={products?.docs || []}
      addToast={addToast}
      isScaleProblemsTab
    />
  );
};

ScaleProblemsPage.propTypes = {
  getProducts: PropTypes.func.isRequired,
  getNeedSupportForScale: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const ScaleProblems = connect(null, dispatch => ({
  getNeedSupportForScale: params =>
    dispatch(getReportedScalesAction({ sort: SCALE_NEEDS_SUPPORT_DATE_QUERY, ...params })),
  getProducts: params => dispatch(getProductsAction(params)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(ScaleProblemsPage);
