import { Field } from '@bottomless/common/src/components/Form';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useSelectCategoryOptions, useSelectOptions, useSelectTastingNotesOptions } from '../hooks';

export const CoffeeAttributes = ({ product, productOptions }) => {
  const roasts = useSelectOptions(productOptions?.roasts, '-- Choose roast --');
  const origins = useSelectOptions(productOptions?.origins, '-- Choose origin --');
  const regions = useSelectCategoryOptions(productOptions?.regions, '-- Choose region --');
  const processes = useSelectOptions(productOptions?.processes, '-- Choose process --');
  const tags = useSelectOptions(productOptions?.tags);
  const varietalsOptions = useSelectOptions(productOptions?.varietals);
  const tastingNotes = useSelectTastingNotesOptions(productOptions?.tasting_notes);

  const defaultTastingNotes = useMemo(() => {
    if (!product.tasting_notes) {
      return undefined;
    }

    return product.tasting_notes.map(note => tastingNotes.find(item => item.value === note));
  }, [tastingNotes, product]);

  return (
    <>
      <Row>
        <Col xs="6">
          <Field name="altitude_min" type="number" min="0" label="Altitude min." />
        </Col>
        <Col xs="6">
          <Field name="altitude_max" type="number" min="0" label="Altitude max." />
        </Col>

        <Col xs="6">
          <Field name="origin" type="select" label="Origin" options={origins} />
        </Col>
        <Col xs="6">
          <Field name="region" type="select" label="Region" options={regions} />
        </Col>
        <Col xs="6">
          <Field name="roast" type="select" label="Roast type" options={roasts} />
        </Col>
        <Col xs="6">
          <Field name="process" type="select" label="Process" options={processes} />
        </Col>
      </Row>
      <div className="tasting-notes-field">
        <Field
          defaultValue={defaultTastingNotes}
          name="tasting_notes"
          type="select-t"
          options={tastingNotes}
          select_label="Tasting notes"
          isMulti={true}
        />
      </div>
      <Row>
        <Col>
          <Field name="tags" type="select" label="Tags" options={tags} multiple />
        </Col>
        <Col>
          <Field name="varietal" type="select" label="Varietals options" options={varietalsOptions} multiple />
        </Col>
      </Row>
    </>
  );
};

CoffeeAttributes.propTypes = {
  product: PropTypes.shape({
    tasting_notes: PropTypes.arrayOf(PropTypes.string),
  }),
  productOptions: PropTypes.shape({
    roasts: PropTypes.array,
    origins: PropTypes.array,
    regions: PropTypes.array,
    processes: PropTypes.array,
    tags: PropTypes.array,
    varietals: PropTypes.array,
    tasting_notes: PropTypes.array,
  }),
};
