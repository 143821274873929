import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { Field as FormikField } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash-es';
import { Option } from './Option';

const styles = {
  menu: provided => ({ ...provided, zIndex: 100 }),
  valueContainer: provided => ({ ...provided, marginTop: '7px' }),
};

const TaxCategoryInputComponent = ({ taxCategories = [], form, field }) => {
  const invalid = Boolean(get(form.errors, field.name) && (get(form.touched, field.name) || form.submitCount));
  const error = get(form.errors, field.name);

  const options = useMemo(
    () =>
      taxCategories.map(t => ({
        value: t.product_tax_code,
        label: t.name,
        description: t.description,
      })),
    [taxCategories]
  );

  const onChange = useCallback(
    ({ value }) => {
      field.onChange({ target: { name: field.name, value } });
    },
    [field]
  );

  const defaultValue = useMemo(() => (field.value ? options.find(option => option.value === field.value) : undefined), [
    options,
    field,
  ]);

  return (
    <FormGroup>
      <Label className="up-label">Tax Category</Label>
      <Select
        defaultValue={defaultValue}
        options={options}
        components={{ Option }}
        styles={styles}
        onChange={onChange}
      />
      <FormFeedback className={classNames('text-left', { 'd-block': invalid })}>{error}</FormFeedback>
    </FormGroup>
  );
};

TaxCategoryInputComponent.propTypes = {
  taxCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      product_tax_code: PropTypes.string.isRequired,
    })
  ).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.object,
};

export const TaxCategoryInput = props => <FormikField {...props} component={TaxCategoryInputComponent} />;
