import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

export const DownDaysTable = ({ vendorDownTimes }) => {
  return (
    <Table size="sm" className="text-sm" hover>
      <thead className="down-table-head">
        <tr>
          <th>Down Date</th>
          <th>Roaster</th>
          <th>Reason</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
        {vendorDownTimes.map((downTime, i) => (
          <tr key={i}>
            <td>{moment(downTime.down_date).format('MM/DD/YYYY')}</td>
            <td>{downTime.vendor_name}</td>
            <td>{downTime.reason}</td>
            <td>{downTime.edit}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

DownDaysTable.propTypes = {
  vendorDownTimes: PropTypes.array,
};
