import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { get } from 'lodash-es';
import { DataLoading } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { useDataEffect, useConditionalDataEffect, useUnmount } from '@bottomless/common/hooks';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import {
  getProposedProductsAction,
  getProposedProductVendorsAction,
  ignoreProposedProductAction,
  unlinkProposedProductAction,
} from '../../../../store/admin/proposed-product';
import { Product } from './components/Product';
import { Filters } from './components/Filters';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import { connectWithAbort } from '@bottomless/common/store';
import { getProductsAction } from '../../../../store/admin/product';

import './ProposedProducts.scss';

const tabs = [
  { to: '/admin/proposed_products/pending', name: 'Pending' },
  { to: '/admin/proposed_products/all', name: 'All' },
  { to: '/admin/proposed_products/ignore', name: 'Ignore' },
];

const ProposedProductsPageComponent = ({
  getProposedProductVendors,
  getProposedProducts,
  ignoreProposedProduct,
  getProductOptions,
  location: { search },
  abortRequests,
  getProducts,
  unlinkProposedProduct,
  addToast,
}) => {
  const [products, setProducts] = useState(null);
  const [ignoreCounter, setIgnoreCounter] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [shopProducts, setShopProducts] = useState([]);
  const query = useMemo(() => qs.parse(search), [search]);

  useUnmount(abortRequests);

  useDataEffect(getProductOptions, setProductOptions);

  useConditionalDataEffect(!vendors, getProposedProductVendors, setVendors);

  useDataEffect(
    getProducts,
    response => {
      setShopProducts(get(response, 'docs', []));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  useEffect(() => {
    (async () => {
      try {
        const { payload } = await getProposedProducts(query);
        setProducts(payload);
      } catch (e) {
        setProducts([]);
      }
    })();
  }, [query, getProposedProducts]);

  const onIgnore = async id => {
    await ignoreProposedProduct(id);
    setIgnoreCounter(ignoreCounter + 1);
  };

  const onUnlink = async id => {
    unlinkProposedProduct(id);
    addToast('Unlinked');
    setProducts(products.filter(curProduct => curProduct._id !== id));
    setIgnoreCounter(ignoreCounter + 1);
  };

  return (
    <PanelPage title="Proposed products" tabs={tabs}>
      <div className="mb-4">
        <Filters isLoading={!vendors} hasData={vendors && vendors.length > 0} vendors={vendors} />
      </div>
      <DataLoading count={(products || { length: 0 }).length} isLoading={!products} />
      {products &&
        products.map(product => (
          <Product
            ignore={onIgnore}
            product={product}
            key={product._id}
            options={productOptions}
            shopProducts={shopProducts}
            unlink={onUnlink}
          />
        ))}
    </PanelPage>
  );
};

ProposedProductsPageComponent.propTypes = {
  getProposedProductVendors: PropTypes.func.isRequired,
  getProposedProducts: PropTypes.func.isRequired,
  ignoreProposedProduct: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  getProductOptions: PropTypes.func.isRequired,
  abortRequests: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  unlinkProposedProduct: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const ProposedProductsPage = connectWithAbort(null, dispatch => ({
  getProposedProductVendors: () => dispatch(getProposedProductVendorsAction()),
  getProposedProducts: vendorId => dispatch(getProposedProductsAction(vendorId)),
  ignoreProposedProduct: id => dispatch(ignoreProposedProductAction(id)),
  getProductOptions: () => dispatch(getProductOptionsAction()),
  getProducts: id => dispatch(getProductsAction(id)),
  unlinkProposedProduct: id => dispatch(unlinkProposedProductAction(id)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(ProposedProductsPageComponent);
