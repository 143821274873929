import React from 'react';
import PropTypes from 'prop-types';
import { paymentTypes } from '../../constants';

export const Accounting = ({ accounting }) => (
  <>
    {accounting.commission && (
      <div>
        <strong>Commision:</strong> {accounting.commission}%
      </div>
    )}
    {accounting.email && (
      <div>
        <strong>E-mail:</strong> <a href={`mailto:${accounting.email}`}>{accounting.email}</a>
      </div>
    )}
    {accounting.notes && (
      <div>
        <strong>Notes:</strong> {accounting.notes}
      </div>
    )}
    {accounting.status && (
      <div>
        <strong>Status:</strong> {accounting.status}
      </div>
    )}
    {accounting.payment_type ? (
      <div>
        <strong>Payment Type:</strong> {paymentTypes[accounting.payment_type]}
      </div>
    ) : (
      ''
    )}
  </>
);

Accounting.propTypes = {
  accounting: PropTypes.shape({
    commission: PropTypes.number,
    email: PropTypes.string,
    notes: PropTypes.string,
    status: PropTypes.string,
    payment_type: PropTypes.string,
  }).isRequired,
};
