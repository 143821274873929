import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './CategoryBadge.scss';

export const CategoryBadge = ({ className, category }) => (
  <div className={classNames(className, 'text-info category-badge')}>{category}</div>
);

CategoryBadge.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string.isRequired,
};
