import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Button, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, Checkbox, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  product: Yup.string().required(),
  changeOriginalId: Yup.bool(),
});

export const MapProductForm = ({ product, onSubmit, onSuccess, shopProducts, onCreateNewProduct }) => {
  const formRef = useRef();
  useEffect(() => {
    formRef.current.resetForm({ product: product.product, changeOriginalId: false });
  }, [product]);
  const handleSubmit = data => onSubmit(product._id, data);

  const handleSuccess = (data, { resetForm }) => {
    onSuccess(data);
    resetForm(data);
  };

  const products = createSelectOptions(shopProducts, '-- Choose shop product --');

  return (
    <Form
      innerRef={formRef}
      initialValues={{
        changeOriginalId: false,
        product: product.product,
      }}
      validationSchema={Schema}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      inline
    >
      {({ isSubmitting }) => (
        <>
          <div className="d-flex justify-content-between">
            {!isEmpty(products) && (
              <div>
                <Field name="product" type="select" label="Map to shop product" options={products} />
                <Row className="m-1">
                  <Checkbox name="changeOriginalId" label="Change Original Id" />
                </Row>

                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </div>
            )}

            {!product.product && (
              <>
                <span className="mb-3 ml-3 mt-3">or</span>
                <div className="mb-3 ml-3">
                  <Button size="sm" type="button" color="dark" outline onClick={onCreateNewProduct}>
                    Create new shop product
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Form>
  );
};

const createSelectOptions = (data, label) =>
  !data
    ? {}
    : data.reduce(
        (all, { _id, name, status }) => ({ ...all, [_id]: `${name} - ${status}` }),
        label ? { null: label } : {}
      );

MapProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCreateNewProduct: PropTypes.func.isRequired,
  shopProducts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};
