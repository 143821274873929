import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';
import { utcDate, createSelectOptions } from '@bottomless/common/utils';

const Schema = isGrindRequired =>
  Yup.object().shape({
    status: Yup.string().required(),
    tracking_number: Yup.string(),
    shipping_service: Yup.string(),
    grind: isGrindRequired ? Yup.string().required() : Yup.string(),
    source: Yup.string().required(),
    date_arrived: Yup.string(),
    date_fulfilled: Yup.string(),
  });

const orderSources = {
  cancelled: 'cancelled',
  dumb_subscription: 'dumb_subscription',
  gift: 'gift',
  lost: 'lost',
  onboarding: 'onboarding',
  operations: 'operations',
  replaced: 'replaced',
  replacement: 'replacement',
  user: 'user',
  user_one_off: 'user_one_off',
};

export const EditOrderForm = ({ order, onSubmit, onSubmitSuccess, options, withGrind }) => {
  const [orderStatus, setOrderStatus] = useState(order.status);
  const [isRefund, setIsRefund] = useState(!!order.easypostRefund);
  const grindOptions = useMemo(() => createSelectOptions(options.grind), [options]);
  const handleSubmit = useCallback(
    data => {
      const override_fulfillment_date = data.override_fulfillment_date
        ? moment(data.override_fulfillment_date).format('YYYY-MM-DD')
        : null;

      return onSubmit({ ...data, override_fulfillment_date });
    },
    [onSubmit]
  );
  return (
    <Form
      initialValues={{
        ...order,
        grind: order.grind?._id,
        date_fulfilled: order.date_fulfilled ? utcDate(order.date_fulfilled) : undefined,
        date_arrived: order.date_arrived ? utcDate(order.date_arrived) : undefined,
        override_fulfillment_date: order.override_fulfillment_date
          ? utcDate(order.override_fulfillment_date)
          : undefined,
      }}
      validationSchema={Schema(withGrind)}
      onSubmit={handleSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field
            name="status"
            label="Status"
            type="select"
            options={{
              initiated: 'Initiated',
              paid: 'Paid',
              cancelled: 'Cancelled',
              fulfilled: 'Fulfilled',
              offboarded: 'Offboarded',
              batch_sent: 'Batch sent',
              subproduct_generated: 'Subproduct generated',
            }}
            onChange={event => {
              setOrderStatus(event.target.value);
            }}
          />
          {orderStatus === 'cancelled' && (
            <Checkbox
              name="easypostRefund"
              label="Easypost Refund"
              type="checkbox"
              value={isRefund}
              onChange={() => {
                setIsRefund(!isRefund);
              }}
            />
          )}
          <Field name="source" label="Source" type="select" options={orderSources} />
          {withGrind && <Field name="grind" label="Grind" type="select" options={grindOptions} />}
          <Field type="date" name="override_fulfillment_date" label="Override Fulfillment Date" format="MM/DD/YYYY" />
          <Field type="date" name="date_fulfilled" label="Date Fulfilled" format="MM/DD/YYYY" />

          <Field
            name="shipping_status"
            label="Shipping status"
            type="select"
            options={{
              unknown: 'Unknown',
              pre_transit: 'Pre transit',
              accepted: 'Accepted',
              in_transit: 'In transit',
              return_to_sender: 'Return To Sender',
              delivered: 'Delivered',
            }}
          />
          <Field
            type="date"
            name="date_arrived"
            label="Date arrived"
            min={new Date(order.date_fulfilled ? order.date_fulfilled : Date.now())}
            showTime={true}
            format="MM/DD/YYYY h:mm a"
          />
          <Field name="tracking_number" type="text" label="Tracking number" />
          <Field name="shipping_service" type="text" label="Shipping service" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

EditOrderForm.propTypes = {
  options: PropTypes.shape({
    grind: PropTypes.object.isRequired,
  }).isRequired,
  order: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  withGrind: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
};
