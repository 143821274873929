import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { Pagination } from '@bottomless/common/components';
import { Filters } from './Filters';

export const Heading = ({ collection, onPageChange, children }) => {
  return (
    <div className="d-flex flex-column w-100">
      <Row className="align-items-center mb-2" noGutters>
        <Col xs="7">
          <Filters />
        </Col>
        <Col xs="5">{children}</Col>
      </Row>
      {collection && onPageChange && (
        <Row>
          <Col xs="12">
            <Pagination collection={collection} onPageChange={onPageChange} size="sm" />
          </Col>
        </Row>
      )}
    </div>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  collection: PropTypes.any,
  onPageChange: PropTypes.func,
};
