import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  tare: Yup.number(),
});

export const TareModal = ({ isOpen, toggle, onSubmit, onSuccess, defaultValue }) => {
  const form = useRef();

  useEffect(() => {
    if (form.current) {
      form.current.setFieldValue('tare', defaultValue);
    }
  }, [defaultValue]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Set tare</ModalHeader>
      <ModalBody>
        <Form
          innerRef={form}
          initialValues={{ tare: defaultValue, sendNotification: true }}
          validationSchema={Schema}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
        >
          {({ isSubmitting }) => (
            <>
              <div className="mb-2">
                <Field name="tare" type="number" label="Tare [oz]" />
                <Checkbox name="sendNotification" label="Send Email" />
              </div>

              <SubmitButton isSubmitting={isSubmitting} color="dark">
                Save
              </SubmitButton>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

TareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  defaultValue: PropTypes.number.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
