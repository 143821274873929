import {
  RenderTemplateTypes,
  GetTemplatesTypes,
  SendEmailTypes,
  GetTemplateTypes,
  ArchiveTemplateTypes,
} from './emails.actions';

const initialState = {
  template: null,
  selectedTemplate: null,
  templates: [],
  isLoading: false,
  isSending: false,
};

export const adminEmailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RenderTemplateTypes.REQUEST:
      return { ...state, isLoading: true, isSending: false };

    case RenderTemplateTypes.SUCCESS:
      return {
        ...state,
        selectedTemplate: action.payload,
        isLoading: false,
      };

    case GetTemplatesTypes.SUCCESS:
      return {
        ...state,
        templates: action.payload,
        isLoading: false,
      };

    case GetTemplateTypes.REQUEST: {
      return {
        ...state,
        template: null,
      };
    }

    case ArchiveTemplateTypes.SUCCESS:
    case GetTemplateTypes.SUCCESS:
      return {
        ...state,
        template: action.payload,
      };

    case SendEmailTypes.REQUEST:
      return { ...state, isSending: true };

    case SendEmailTypes.FAILURE:
    case SendEmailTypes.SUCCESS:
      return {
        ...state,
        isSending: false,
      };

    case GetTemplatesTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
        templates: [],
      };

    default:
      return state;
  }
};
