import { pickBy } from 'lodash-es';
import qs from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  status: Yup.string().nullable(true),
  search: Yup.string(),
});

const statuses = {
  active: 'active',
  onboarding: 'onboarding',
  inactive: 'inactive',
};

export const Filters = () => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const formRef = useRef();
  const [filters, setFilters] = useState(query);

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(pickBy(filters, v => v && v !== 'null')),
    });
  }, [filters]);

  return (
    <Form
      inline
      innerRef={formRef}
      initialValues={{ status: 'active', ...filters }}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {() => (
        <>
          <Row className="w-100 align-items-center pl-3">
            <Col xs="3">
              <Field
                name="status"
                label="status"
                type="select"
                options={statuses}
                onChange={e => onFilterChange('status', e.target.value)}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
