import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  name: Yup.string(),
  orderTrackingLink: Yup.string(),
  accountEmail: Yup.string(),
});

export const FulfillForm = ({ product, order, fulfillOrder, toggleModal, setFulfilled, addToast }) => {
  const handleSubmit = useCallback(
    async data => {
      await fulfillOrder(order._id, data);
    },
    [order, fulfillOrder]
  );

  return (
    <Form
      initialValues={{ name: product.eCommerce?.name }}
      validationSchema={Schema}
      onSubmit={handleSubmit}
      onSuccess={() => {
        toggleModal();
        setFulfilled(true);
        addToast(`Fulfilled ${order._id}`);
      }}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="name" type="text" label="E-Commerce name" />
          <Field name="orderTrackingLink" type="text" label="E-Commerce order tracking link" />
          <Field name="accountEmail" type="text" label="E-Commerce account email" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Accept
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

FulfillForm.propTypes = {
  product: PropTypes.object,
  order: PropTypes.object,
  fulfillOrder: PropTypes.func,
  toggleModal: PropTypes.func,
  setFulfilled: PropTypes.func,
  addToast: PropTypes.func,
};
