import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { getVariantSelectLabel } from '@bottomless/common/src/utils';
import './ReplaceVariantForm.scss';

const Schema = Yup.object().shape({
  variants: Yup.array().of(
    Yup.object().shape({
      size: Yup.number().required('This field is required'),
      price: Yup.number().required('This field is required'),
      cost: Yup.number(),
      available: Yup.bool().required('This field is required'),
      skipShipment: Yup.bool(),
    })
  ),
});

const ReplaceVariantFormComponent = ({
  product,
  variant,
  shopProducts,
  onSubmitReplacementVariant,
  onSuccessReplacementVariant,
}) => {
  const [selectedVariants, setSelectedVariants] = useState(
    (product.variants || []).map(variant => variant._id).reduce((variants, id) => ({ ...variants, [id]: [] }), {})
  );

  const createSelectOptions = (data, label, inputLabel = item => item.name) =>
    !data
      ? {}
      : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

  const products = (size, tags) =>
    createSelectOptions(
      shopProducts &&
        shopProducts.filter(
          product =>
            (product.tags.includes('5bcecdeef325036b0f66bb08')
              ? tags.includes('5bcecdeef325036b0f66bb08')
              : !tags.includes('5bcecdeef325036b0f66bb08')) && product.variants.filter(v => v.size === size).length > 0
        ),
      '-- Choose product --',
      product => `${product.vendor_name} - ${product.name}`
    );

  const variants = size =>
    Object.keys(selectedVariants)
      .map(id => ({
        id,
        options: createSelectOptions(
          selectedVariants[id].filter(v => v.size === size),
          '-- Choose variant --',
          getVariantSelectLabel
        ),
      }))
      .reduce((all, { id, options }) => ({ ...all, [id]: options }), {});

  const onReplacementChange = id => e => {
    const product = shopProducts && shopProducts.find(product => product._id === e.target.value);
    setSelectedVariants({
      ...selectedVariants,
      [id]: (product || { variants: [] }).variants.filter(variant => variant.available !== false),
    });
  };

  const handleSubmit = async data => {
    const { replace_product } = data;

    return onSubmitReplacementVariant({
      ...data,
      replace_product: Object.keys(replace_product || []).map(variantId => ({
        ...replace_product[variantId],
        _id: variantId,
      })),
    });
  };

  const handleSuccess = (data, { resetForm }) => {
    onSuccessReplacementVariant(data);
    resetForm(data);
  };

  return (
    <div className="mt-2">
      <Form validationSchema={Schema} onSubmit={handleSubmit} onSuccess={handleSuccess} initialValues={{}}>
        {({ isSubmitting }) => (
          <>
            <Row md="12">
              <Col xs="12" sm="12" md="3" className="replace-col">
                <Field
                  name={`replace_product[${variant._id && variant._id.toString()}]._id`}
                  value={variant._id}
                  disabled
                />
              </Col>
              <Col xs="12" sm="12" md="5" className="replace-col">
                <Field
                  name={`replace_product[${variant._id && variant._id.toString()}].product`}
                  type="select"
                  label="Replacement product"
                  options={products(variant.size, product.tags)}
                  onChange={onReplacementChange(variant._id)}
                  required
                />
              </Col>
              <Col xs="12" sm="12" md="3" className="replace-col">
                <Field
                  name={`replace_product[${variant._id && variant._id.toString()}].variant`}
                  type="select"
                  label="Replacement variant"
                  options={variants(variant.size)[variant._id]}
                  required
                />
              </Col>
              <Col xs="12" sm="4" md="1">
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
};

ReplaceVariantFormComponent.propTypes = {
  product: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  shopProducts: PropTypes.array.isRequired,
  onSubmitReplacementVariant: PropTypes.func.isRequired,
  onSuccessReplacementVariant: PropTypes.func.isRequired,
};

export const ReplaceVariantForm = memo(ReplaceVariantFormComponent);
