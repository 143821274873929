import React, { useMemo } from 'react';
import { useToggle } from '@bottomless/common/src/hooks';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Button, Collapse } from 'reactstrap';
import { VendorLine } from './VendorLine';

const NO_VENDOR = 'no-vendor';

export const LocationLine = ({ location, vendors, onCsvOrderClick, onCsvShoppingListClick }) => {
  const [isOpen, toggle] = useToggle(true);

  const hasAtLeastOneOrder = useMemo(() => !!vendors.find(vendor => vendor._id._id !== NO_VENDOR), [vendors]);

  const csvOrdersOnClick = useMemo(() => (onCsvOrderClick ? onCsvOrderClick({ date: location }) : null), [
    onCsvOrderClick,
    location,
  ]);

  const csvShoppingListOnClick = useMemo(
    () =>
      onCsvShoppingListClick
        ? onCsvShoppingListClick({ date: location, ...(!hasAtLeastOneOrder ? { vendor: { _id: NO_VENDOR } } : {}) })
        : null,
    [onCsvShoppingListClick, location, hasAtLeastOneOrder]
  );

  return (
    <div className="border border-bottom-0 w-100">
      <div className="d-flex justify-content-between align-items-center cursor-pointer" onClick={toggle}>
        <span className="p-2">
          {isOpen ? <ChevronUp /> : <ChevronDown />}
          <span className="ml-2">
            <b>{location}</b>
          </span>
        </span>
        <div>
          {csvOrdersOnClick && (
            <Button color="success" outline className="mx-2" onClick={csvOrdersOnClick}>
              Orders
            </Button>
          )}
          {csvShoppingListOnClick && (
            <Button color="success" outline className="ml-2" onClick={csvShoppingListOnClick}>
              Shopping
            </Button>
          )}
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        {vendors.map(vendor => (
          <VendorLine
            location={location}
            key={vendor._id}
            vendor={vendor._id}
            orders={vendor.orders}
            onCsvOrderClick={onCsvOrderClick}
            onCsvShoppingListClick={onCsvShoppingListClick}
          />
        ))}
      </Collapse>
    </div>
  );
};

LocationLine.propTypes = {
  location: PropTypes.string.isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      orders: PropTypes.array,
    })
  ),
  onCsvOrderClick: PropTypes.func,
  onCsvShoppingListClick: PropTypes.func,
};
