import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  micro_influencer: Yup.bool(),
  send_email: Yup.bool(),
  twitter: Yup.string(),
  instagram: Yup.string(),
  linkedin: Yup.string(),
  credits: Yup.number(),
  months: Yup.number(),
  registration_date: Yup.date().optional(),
});

export const UpdateSocialMediaForm = ({ user, onSubmit, onSubmitSuccess }) => {
  const isInfluencerSet = values => values.micro_influencer;

  return (
    <Form
      initialValues={{
        micro_influencer: user.social_media?.micro_influencer || false,
        send_email: false,
        twitter: user.social_media?.twitter || '',
        instagram: user.social_media?.instagram || '',
        tiktok: user.social_media?.tiktok || '',
        youtube: user.social_media?.youtube || '',
        facebook: user.social_media?.facebook || '',
        linkedin: user.social_media?.linkedin || '',
        credits: user.social_media?.credits || 0,
        months: user.social_media?.months || 0,
        registration_date: user.social_media?.registration_date
          ? new Date(user.social_media?.registration_date)
          : new Date(),
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting, values }) => (
        <>
          <Field name="twitter" label="Twitter" />
          <Field name="instagram" label="Instagram" />
          <Field name="tiktok" label="Tiktok" />
          <Field name="youtube" label="Youtube" />
          <Field name="linkedin" label="Linkedin" />
          <Field name="facebook" label="Facebook" />
          <div className="mb-3">
            <Checkbox name="micro_influencer" label="Is Micro-Influencer" />
          </div>
          {isInfluencerSet(values) && (
            <div className="mb-3">
              <Checkbox name="send_email" label="Send E-mail" />
            </div>
          )}
          <Field name="credits" label="Credits" type="number" />
          <Field name="months" label="Months" type="number" />
          <Field name="registration_date" label="Registration Date" type="date" format="MM/DD/YYYY" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

UpdateSocialMediaForm.propTypes = {
  user: PropTypes.shape({
    social_media: PropTypes.shape({
      micro_influencer: PropTypes.bool,
      send_email: PropTypes.bool,
      twitter: PropTypes.string,
      instagram: PropTypes.string,
      tiktok: PropTypes.string,
      linkedin: PropTypes.string,
      youtube: PropTypes.string,
      facebook: PropTypes.string,
      credits: PropTypes.number,
      months: PropTypes.number,
      registration_date: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
