import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DateFormat } from '@bottomless/common/components';

export const StaticTracking = ({ usps_tracking }) => {
  return (
    <div>
      <ol className="text-sm pt-2" reversed>
        {(usps_tracking.summary || []).map(({ status, id, description, date }) => (
          <li key={id}>
            <div>
              <span
                className={classNames('mr-1', {
                  'text-success': ['out_for_delivery', 'delivered'].includes(status),
                  'text-primary': ['accepted', 'in_transit'].includes(status),
                  'text-info': status === 'pre_transit',
                  'text-danger': ['return_to_sender', 'alert'].includes(status),
                })}
              >
                {status}
              </span>
              <span>{description}</span>
            </div>

            {date && (
              <div className="text-secondary">
                <DateFormat date={date} withTime />
              </div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

StaticTracking.propTypes = {
  usps_tracking: PropTypes.shape({
    status: PropTypes.string.isRequired,
    summary: PropTypes.array.isRequired,
  }),
};
