import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import React, { useEffect, useState, useRef, useCallback, useMemo, Fragment } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import {
  getRotationPricingAction,
  updateRotationPricingAction,
  getQuizAction,
  updateQuizAction,
  createQuizAction,
} from '../../../store/admin/rotation';
import { Card, CardBody, CardHeader, Input, Label, Row } from 'reactstrap';
import { Form, Field, Price, SubmitButton } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { useDataEffect } from '@bottomless/common/hooks';
import { getProductsAction } from '../../../store/admin/product';
import { QuizForm } from './components/QuizForm';

import './RotationPricing.scss';

const roasts = { light: 'Light', medium: 'Medium', dark: 'Dark', espresso: 'Espresso' };
const sizes = { 12: '12oz', 32: '32oz', 80: '80oz' };

const getPersonalizedString = personalized =>
  JSON.stringify(
    Object.entries({ ...personalized, grind: personalized.grind === 'Whole Bean' ? personalized.grind : 'Ground' })
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => a.key.localeCompare(b.key))
      .reduce((all, { key, value }) => ({ ...all, [key]: value }), {})
  );

const PricingCard = ({ values, index, getQuiz, products, addToast, updateQuiz, createQuiz }) => {
  const [roast, setRoast] = useState('light');
  const [size, setSize] = useState('12');
  const [organic, setOrganic] = useState(false);
  const [single, setSingle] = useState(false);
  const [bean, setBean] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const [personalized, setPersonalized] = useState({
    grind: '',
    origin: [],
    price_type: values[index].type,
    roast: [],
    single_origins: [],
    size: '',
    tag: [],
  });
  const availableProducts = useMemo(
    () => products.filter(p => p.variants.filter(v => Number(v.size) === Number(size)).length),
    [size, products]
  );

  useEffect(() => {
    (async () => {
      personalized.grind = bean ? 'Whole Bean' : 'Ground';
      personalized.roast = [roast];
      personalized.size = Number(size);
      const { payload } = await getQuiz({ answers: getPersonalizedString(personalized) });
      setQuiz(payload.quiz);
      setPersonalized(personalized);
    })();
  }, [roast, size, bean]);

  const price = useMemo(() => {
    const roastPrice = values[index].roasts[roast];
    const tagPrice = organic ? values[index].tags['organic'] : 0;
    const originsPrice = single ? values[index].origins['single'] : 0;

    let rawPrice = roastPrice + tagPrice + originsPrice;

    const sizeModifier = values[index].sizes[size];

    if (sizeModifier) {
      rawPrice = rawPrice * (Number(size) / 12) * sizeModifier;
    }
    return rawPrice;
  }, [roast, size, organic, single, values]);

  const update = useCallback(
    quiz => async data => {
      const { payload } = await updateQuiz({ id: quiz._id, ...data });
      if (payload.quiz) {
        setQuiz([payload.quiz]);
        addToast('Quiz updated succesfully', 'success');
      } else {
        addToast('Failed to update quiz', 'error');
      }
    },
    [updateQuiz, setQuiz, addToast]
  );

  const create = async data => {
    const { payload } = await createQuiz({
      ...data,
      answers: getPersonalizedString(personalized),
    });
    if (payload.quiz) {
      setQuiz([payload.quiz]);
      addToast('Quiz create succesfully', 'success');
    } else {
      addToast('Failed to create quiz', 'error');
    }
  };

  return (
    <Card className="mb-3 flex-row align-items-stretch">
      <CardHeader className="pricing-header">{values[index].type}</CardHeader>
      <CardBody>
        <Row>
          <Field
            name={`roast${index}`}
            type="select"
            label="Roasts"
            options={roasts}
            onChange={e => setRoast(e.target.value)}
          />
          <Field className="rotation-field" name={`${index}.roasts.${roast}`} type="number" />
          <Field
            name={`size${index}`}
            type="select"
            label="Sizes"
            options={sizes}
            onChange={e => setSize(e.target.value)}
          />
          {size !== '12' && <Field className="rotation-field" name={`${index}.sizes.${size}`} type="number" />}
          <Label className={classnames({ 'rotating-label': size === '12' })}>
            <Input id={`organic${index}`} type="checkbox" onChange={e => setOrganic(e.target.checked)} />
            Organic
          </Label>
          <Field className="rotation-field" name={`${index}.tags.organic`} type="number" required />
          <Label>
            <Input id={`origins${index}`} type="checkbox" onChange={e => setSingle(e.target.checked)} />
            Single Origin
          </Label>
          <Field className="rotation-field" name={`${index}.origins.single`} type="number" required />
          Total Amount: <Price value={price} />
        </Row>
        <Row>
          <Label className="rotating-label">
            <Input id={`beans${index}`} type="checkbox" onChange={e => setBean(e.target.checked)} />
            Whole Bean
          </Label>
        </Row>
        {personalized && (
          <pre id="json" className="my-4">
            {getPersonalizedString(personalized)}
          </pre>
        )}
        {quiz && quiz.length && availableProducts ? (
          quiz.map(q => <QuizForm key={q._id} quiz={q} availableProducts={availableProducts} onSubmit={update(q)} />)
        ) : (
          <>
            <div className="text-warning mb-3">*No matched quiz for these selection*</div>
            <QuizForm availableProducts={availableProducts} onSubmit={create} />
          </>
        )}
      </CardBody>
    </Card>
  );
};

PricingCard.propTypes = {
  values: PropTypes.object,
  index: PropTypes.number,
  getQuiz: PropTypes.func,
  updateQuiz: PropTypes.func,
  createQuiz: PropTypes.func,
  products: PropTypes.array,
  addToast: PropTypes.func.isRequired,
};

export const RotationPricingPageComponent = ({
  getPricing,
  updatePricing,
  addToast,
  getQuiz,
  getProducts,
  updateQuiz,
  createQuiz,
}) => {
  const [pricing, setPricing] = useState(null);
  const [products, setProducts] = useState([]);
  const formRef = useRef();

  useDataEffect(
    getProducts,
    response => {
      setProducts(get(response, 'docs', []));
    },
    { status: 'active', hidden: false, sort: 'name', dir: 1 }
  );

  useEffect(() => {
    (async () => {
      const { payload: pricing } = await getPricing();
      if (pricing) {
        setPricing({ ...pricing.dynamic_pricing });
      } else {
        addToast('Unable to get manifest', 'error');
      }
    })();
  }, []);

  const onSubmit = useCallback(data => updatePricing(removeFields(data)), [updatePricing]);

  const removeFields = data => {
    const rest = [data[0], data[1], data[2]];
    return rest;
  };

  const onSuccess = () => addToast('Pricing updated', 'success');

  return (
    <>
      <PanelPage title="Rotation Pricing">
        {pricing && (
          <Form
            initialValues={{ ...pricing }}
            onSubmit={data => onSubmit(data)}
            onSuccess={onSuccess}
            innerRef={formRef}
          >
            {({ isSubmitting, values }) => (
              <>
                <h3>Formula: (Roast + Organic + Single)*(Size/12)*Size Modifier</h3>
                <div>
                  {Object.keys(pricing).map(index => (
                    <PricingCard
                      key={index}
                      values={values}
                      index={index}
                      formRef={formRef}
                      setPricing={setPricing}
                      getQuiz={getQuiz}
                      products={products}
                      addToast={addToast}
                      updateQuiz={updateQuiz}
                      createQuiz={createQuiz}
                    />
                  ))}
                </div>
                <SubmitButton color="success" isSubmitting={isSubmitting}>
                  Update Manifest
                </SubmitButton>
              </>
            )}
          </Form>
        )}
      </PanelPage>
    </>
  );
};

RotationPricingPageComponent.propTypes = {
  getPricing: PropTypes.func.isRequired,
  getQuiz: PropTypes.func.isRequired,
  updatePricing: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  updateQuiz: PropTypes.func.isRequired,
  createQuiz: PropTypes.func.isRequired,
};

export const RotationPricingPage = connect(null, dispatch => ({
  getPricing: () => dispatch(getRotationPricingAction()),
  getQuiz: data => dispatch(getQuizAction(data)),
  getProducts: id => dispatch(getProductsAction(id)),
  updatePricing: data => dispatch(updateRotationPricingAction(data)),
  updateQuiz: data => dispatch(updateQuizAction(data)),
  createQuiz: data => dispatch(createQuizAction(data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(RotationPricingPageComponent);
