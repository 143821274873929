import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getLikedProductsAction, getVendorsAction } from '../../../store';
import { ProductFilter } from './components/ProductFilter';
import { tabs } from './Tabs';
import { Product } from './components/Product';

const LikedProductsPageComponent = ({ getLikedProducts, getVendors }) => {
  const [products, setProducts] = useState(null);
  useDataEffect(getLikedProducts, setProducts);
  const { data: vendors } = useDataEffect(getVendors);

  const onSubmit = useCallback(
    params => {
      (async () => {
        setProducts(null);
        const { payload: products } = await getLikedProducts(params);
        setProducts(products);
      })();
    },
    [getLikedProducts, setProducts]
  );

  return (
    <PanelPage
      title="Product feedback"
      tabs={tabs}
      heading={<ProductFilter onSubmit={onSubmit} onSuccess={() => {}} vendors={vendors} />}
    >
      <DataLoading count={(products || { length: 0 }).length} isLoading={products === null} />
      {products && products.map(product => <Product product={product} key={product._id} />)}
    </PanelPage>
  );
};

LikedProductsPageComponent.propTypes = {
  getLikedProducts: PropTypes.func.isRequired,
  getVendors: PropTypes.func.isRequired,
};

export const LikedProductsPage = connect(null, dispatch => ({
  getLikedProducts: params => dispatch(getLikedProductsAction(params)),
  getVendors: () => dispatch(getVendorsAction()),
}))(LikedProductsPageComponent);
