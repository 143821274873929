import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AlertTriangle, Bell, BellOff, Edit, Lock, Trash2, Briefcase, Link2 } from 'react-feather';
import { Link } from 'react-router-dom';
import { Card, CardBody, Collapse, Container, Row, Col, Button } from 'reactstrap';
import { DateFormat } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import {
  applyProposedProductChangesAction,
  acceptProposedProductVendorChangesAction,
  deleteProposedProductVendorChangesAction,
  proposedProductNotDeletedAction,
  ignoreProposedProductVendorChangesAction,
} from '../../../../../store/admin/proposed-product';
import { replaceProductVariantAction } from '../../../../../store/admin/product';
import { connect } from 'react-redux';
import { ProductChangeSet } from '../../../../../components/ProductChangeSet/ProductChangeSet';

const StatusArchived = 'archived';

const ProductComponent = ({
  product,
  back,
  ignore,
  addToast,
  applyChanges,
  acceptVendorChangeSet,
  deleteVendorChangeSet,
  ignoreVendorChangeSet,
  productProccessed,
  productNotDeleted,
  options,
  unlink,
  replaceProductVariant,
  shopProducts,
}) => {
  const color = getColor(product);
  const [isCollapsed, collapse] = useToggle();
  const [shopProduct, setShopProduct] = useState([]);

  const onIgnore = e => {
    e.preventDefault();
    return ignore(product._id);
  };

  const unLink = e => {
    e.preventDefault();
    if (unlink) {
      return unlink(product._id);
    }
    addToast('Something went wrong when unlinking the product', 'danger');
  };

  const onApplyChanges = async () => {
    const { payload } = await applyChanges(product._id);
    if (!payload.change_set && !payload.vendor_change_set) {
      productProccessed(product._id);
    }
    addToast('Changes have been successfully applied');
  };

  const onProductNotDeleted = async () => {
    productNotDeleted(product._id);
    productProccessed(product._id);
    addToast('Propsed product deleted removed');
  };

  const onAcceptVendorChangeSet = async data => {
    const { payload } = await acceptVendorChangeSet(product._id, data);
    if (!payload.change_set && !payload.vendor_change_set) {
      productProccessed(product._id);
    }

    setShopProduct({ ...shopProduct, ...payload });
    addToast('Vendor changes have been accepted');
  };

  const onDeleteVendorChangeSet = async data => {
    const { payload } = await deleteVendorChangeSet(product._id, data);
    if (!payload.change_set && !payload.vendor_change_set) {
      productProccessed(product._id);
    }
    addToast('Vendor changes have been deleted');
  };

  const onIgnoreVendorChangeSet = async data => {
    const { payload } = await ignoreVendorChangeSet(product._id, data);
    if (!payload.change_set && !payload.vendor_change_set) {
      productProccessed(product._id);
    }
    addToast('Vendor changes have been ignored');
  };

  const onReplaceProductVariantSuccess = () => {
    addToast('Product variant has been successfully replaced');
  };

  return (
    <>
      <Card
        className="mb-3 flex-row align-items-stretch proposed-product cursor-pointer"
        color={color}
        outline={Boolean(color)}
      >
        <div
          className="card-media"
          style={{ backgroundImage: `url(${product.original_image || '/images/no-image.png'})` }}
        />
        <CardBody>
          <Row>
            <Col xs="10" onClick={collapse}>
              {product.name} - {product.vendor_name}
              {product.product && product.product.status === StatusArchived && (
                <span className="text-danger"> - archived</span>
              )}
              {product.updated_at && (
                <>
                  <span className="text-sm d-block">
                    Updated at: <DateFormat date={product.updated_at} withTime />
                  </span>
                </>
              )}
              {product.deleted_at && (
                <>
                  <span className="text-sm d-block">
                    Deleted at: <DateFormat date={product.deleted_at} withTime />
                  </span>
                </>
              )}
            </Col>
            <Col xs="2" className="d-flex justify-content-end align-items-center">
              {product.product && (
                <a href="#" onClick={unLink} className={`mr-2 text-danger`}>
                  <Link2 size="20" />
                </a>
              )}

              {product.ignored && (
                <span className="text-danger mr-2" title="Ignored">
                  <BellOff size="20" />
                </span>
              )}
              {!product.ignored && product.change_set && (
                <span className="text-warning mr-2" title="Changes detected">
                  <AlertTriangle size="20" />
                </span>
              )}
              {product.product && (
                <span className="text-success mr-2" title="Connected to shop product">
                  <Lock size="20" />
                </span>
              )}
              {product.deleted && (
                <span className="text-danger mr-2" title="Deleted">
                  <Trash2 size="20" />
                </span>
              )}
              {product.vendor_change_set && (
                <span className="text-primary mr-2" title="Vendor change">
                  <Briefcase size="20" />
                </span>
              )}
              <span className="mr-2 divider text-secondary">|</span>
              <a
                href="#"
                onClick={onIgnore}
                className={`mr-2 text-${product.ignored ? 'success' : 'danger'}`}
                title={product.ignored ? 'Unignore' : 'Ignore'}
              >
                {product.ignored ? <Bell size="20" /> : <BellOff size="20" />}
              </a>
              <Link to={`/admin/proposed_products/${product._id}${back ? '?back=' + back : ''}`} className="text-info">
                <Edit size="20" />
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Collapse isOpen={isCollapsed} className="mb-2">
        <Container>
          <Row>
            {product.change_set && (
              <Col>
                <>
                  PRODUCT CHANGE SET: <pre id="json">{JSON.stringify(product.change_set, undefined, 2)}</pre>
                  <Button color="success" outline onClick={onApplyChanges} size="sm" className="mr-3">
                    Apply product changes
                  </Button>
                </>
              </Col>
            )}
            {product && product.vendor_change_set && options && (
              <Col>
                <div className="mt-4">
                  <h2>Vendor change request:</h2>
                  <ProductChangeSet
                    onSubmit={data => replaceProductVariant(product.product._id, data)}
                    onSuccess={onReplaceProductVariantSuccess}
                    onReject={onDeleteVendorChangeSet}
                    onAccept={onAcceptVendorChangeSet}
                    onIgnore={onIgnoreVendorChangeSet}
                    changeSet={product.vendor_change_set}
                    product={product.product}
                    shopProducts={shopProducts}
                    productOptions={options}
                    withButtons
                  />
                </div>
              </Col>
            )}
            {product.deleted && (
              <Col>
                <>{product.deleted ? <>Product Deleted</> : <></>}</>

                <Button color="danger" onClick={onProductNotDeleted} size="sm" className="ml-3">
                  Not Deleted
                </Button>
              </Col>
            )}
            {!product.deleted && !product.change_set && !product.vendor_change_set && <Col>New Product Created</Col>}
          </Row>
        </Container>
      </Collapse>
    </>
  );
};

const getColor = product => {
  if (product.vendor_change_set) {
    return 'primary';
  }

  if (product.ignored) {
    return undefined;
  }

  if (product.deleted) {
    return 'danger';
  }

  if (product.change_set) {
    return 'warning';
  }

  if (!product.product) {
    return 'success';
  }
};

ProductComponent.propTypes = {
  ignore: PropTypes.func.isRequired,
  unlink: PropTypes.func,
  options: PropTypes.object,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    original_image: PropTypes.string,
    change_set: PropTypes.object,
    ignored: PropTypes.bool,
    deleted: PropTypes.bool,
    updated_at: PropTypes.string,
    deleted_at: PropTypes.string,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      status: PropTypes.string,
    }),
    vendor_change_set: PropTypes.object,
    vendor_name: PropTypes.string,
  }).isRequired,
  back: PropTypes.string,
  addToast: PropTypes.func.isRequired,
  applyChanges: PropTypes.func.isRequired,
  acceptVendorChangeSet: PropTypes.func.isRequired,
  deleteVendorChangeSet: PropTypes.func.isRequired,
  ignoreVendorChangeSet: PropTypes.func.isRequired,
  productProccessed: PropTypes.func,
  productNotDeleted: PropTypes.func.isRequired,
  replaceProductVariant: PropTypes.func.isRequired,
  shopProducts: PropTypes.array.isRequired,
};

export const Product = connect(null, dispatch => ({
  addToast: message => dispatch(addToastAction(message)),
  applyChanges: id => dispatch(applyProposedProductChangesAction(id)),
  acceptVendorChangeSet: (id, data) => dispatch(acceptProposedProductVendorChangesAction(id, data)),
  deleteVendorChangeSet: (id, data) => dispatch(deleteProposedProductVendorChangesAction(id, data)),
  ignoreVendorChangeSet: (id, data) => dispatch(ignoreProposedProductVendorChangesAction(id, data)),
  productNotDeleted: id => dispatch(proposedProductNotDeletedAction(id)),
  replaceProductVariant: (id, data) => dispatch(replaceProductVariantAction(id, data)),
}))(ProductComponent);
