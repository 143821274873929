import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { getVariantSelectLabel } from '@bottomless/common/utils';

const GRIND_WHOLE_BEAN = '5eaa7fc066c17ce02128fa3a';

const Schema = Yup.object().shape({
  product: Yup.string().required('This field is required'),
  variant: Yup.string().required('This field is required'),
});

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

export const UpdateProductForm = ({ user, products, onSubmit, onSubmitSuccess }) => {
  const [selectedProduct, setSelectedProduct] = useState();

  const selectProduct = id => {
    const product = products.find(p => p._id === id);
    setSelectedProduct(product);
  };

  return (
    <Form
      initialValues={{ product: null, variant: '', grind: user.grind?._id }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field
            name="product"
            type="select"
            label="Select product"
            options={createSelectOptions(
              products,
              '--- select product ---',
              item => `${item.vendor_name} - ${item.name}`
            )}
            required
            onChange={event => {
              selectProduct(event.target.value);
            }}
          />
          {selectedProduct && (
            <>
              <Field
                name="variant"
                type="select"
                label="Select variant"
                options={createSelectOptions(
                  selectedProduct.variants.filter(v => v.available),
                  '--- select variant ---',
                  getVariantSelectLabel
                )}
                required
              />
              {user.grind && user.grind?._id !== GRIND_WHOLE_BEAN && !selectedProduct.available_ground && (
                <div className="text-warning text-sm">
                  Heads up: User has ground coffee, however the coffee you chose doesn&apos;t have grind available, so
                  user will be switched to Whole Beans
                </div>
              )}
            </>
          )}
          <Field name="grind" hidden />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Change Product
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

UpdateProductForm.propTypes = {
  user: PropTypes.shape({
    grind: PropTypes.object,
    product: PropTypes.shape({
      product: PropTypes.shape({
        grind: PropTypes.shape({
          _id: PropTypes.string,
        }),
      }),
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  products: PropTypes.array,
};

UpdateProductForm.defaultProps = {
  products: [],
};
