import PropTypes from 'prop-types';
import React from 'react';
import { Edit, RefreshCw, Shuffle, WifiOff } from 'react-feather';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { DumbSubscription } from '../../Details/components/DumbSubscription';

export const User = ({ user, back, setDumbPeriod, addToast, updateDumbSubscription, updateUser }) => {
  const days = Math.floor((Date.now() - new Date(user.scale_last_connected).getTime()) / (1000 * 60 * 60 * 24));
  const onSetDumbPeriod = () => addToast('Fixed Frequency Subscription has been updated');

  return (
    <Card className="mb-3">
      <CardBody>
        <Row className="justify-content-center align-items-center">
          <Col xs="4" className="mb-3 mb-sm-0">
            <div>
              {user.first_name} {user.last_name}
            </div>
            <div className="text-sm">
              <a href={`mailto:${user.local.email}`}>{user.local.email}</a>
            </div>
          </Col>
          <Col xs="2">
            <div className="text-secondary d-flex align-items-center">
              <WifiOff size="12" />
              <span className="ml-2 text-sm">{days} days</span>
            </div>
          </Col>
          <Col xs="2" className="mb-4 mb-sm-0">
            {user.dumb_period && (
              <div className="text-secondary d-flex align-items-center">
                <RefreshCw size="12" />
                <span className="ml-2 text-sm">{user.dumb_period} days</span>
              </div>
            )}

            <Link to={`/admin/disconnected/${user._id}${back ? '?back=' + back : ''}`} className="text-info mr-4">
              <Edit size="20" />
            </Link>
            <Link to={`/admin/ordering_cockpit/${user._id}${back ? '?back=' + back : ''}`} className="text-info mr-4">
              <Shuffle size="20" />
            </Link>
          </Col>
          <Col xs="4">
            <DumbSubscription
              user={user}
              onSubmit={setDumbPeriod}
              onSuccess={onSetDumbPeriod}
              updateDumbSubscription={updateDumbSubscription}
              updateUser={updateUser}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    scale_last_connected: PropTypes.string.isRequired,
    dumb_period: PropTypes.number,
  }).isRequired,
  back: PropTypes.string,
  setDumbPeriod: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  updateDumbSubscription: PropTypes.func,
  updateUser: PropTypes.func,
};
