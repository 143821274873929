import PropTypes from 'prop-types';
import { debounce, pickBy } from 'lodash-es';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, DataLoading } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  status: Yup.string().nullable(true),
  search: Yup.string(),
});

const sources = {
  null: 'All',
  onboarding: 'onboarding',
  gift: 'gift',
  operations: 'operations',
  referral: 'referral',
  legacy: 'legacy',
  replacement: 'replacement',
};

const statuses = {
  null: 'All',
  granted: 'granted',
  redeemed: 'redeemed',
};

const reasons = {
  null: 'All',
  'micro-influencer': 'micro-influencer',
};

const FiltersComponent = ({ history, location, hasData, isLoading }) => {
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const [filters, setFilters] = useState(query);

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(pickBy(filters, v => v && v !== 'null')),
    });
  }, [filters]);

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  const initialValues = { ...filters };

  return (
    <Form inline initialValues={initialValues} validationSchema={Schema} onSubmit={() => {}} onSuccess={() => {}}>
      {({ resetForm }) => (
        <>
          <Row className="w-100 align-items-center">
            <Col xs="2">
              <Field
                name="status"
                label="status"
                type="select"
                options={statuses}
                onChange={e => onFilterChange('status', e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Field
                name="souce"
                label="source"
                type="select"
                options={sources}
                onChange={e => onFilterChange('source', e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Field
                name="reason"
                label="reason"
                type="select"
                options={reasons}
                onChange={e => onFilterChange('reason', e.target.value)}
              />
            </Col>
            <Col className="pr-0">
              <Field
                className="w-100 up-label"
                name="search"
                label="Search"
                placeholder={`Search by ${['_id'].join(', ')}`}
                onChange={debounce(event => {
                  onFilterChange('search', event.target.value);
                }, 1000)}
              />
            </Col>
          </Row>
          {(isLoading || !hasData) && (
            <Row className="w-100 py-4">
              <Col>
                <DataLoading count={hasData ? 1 : 0} isLoading={isLoading}>
                  <div className="text-center py-2">
                    <span className="d-block mb-3">No data matching selected filters.</span>
                    <Button
                      size="sm"
                      onClick={() => {
                        setFilters({});
                        resetForm({ ...initialValues });
                      }}
                    >
                      Clear filters
                    </Button>
                  </div>
                </DataLoading>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
};

FiltersComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  hasData: PropTypes.bool,
  isLoading: PropTypes.bool,
};
export const Filters = withRouter(FiltersComponent);
