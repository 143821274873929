import React, { useMemo } from 'react';
import { useToggle } from '@bottomless/common/src/hooks';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Button, Collapse } from 'reactstrap';
import { VendorFulfillmentDays } from '../../../../components/VendorFulfillmentDays/VendorFulfillmentDays';
import { Order } from './Order';

export const VendorLine = ({ vendor, orders, location, onCsvOrderClick, onCsvShoppingListClick }) => {
  const [isOpen, toggle] = useToggle(false);

  const csvOrdersOnClick = useMemo(() => (onCsvOrderClick ? onCsvOrderClick({ vendor, date: location }) : null), [
    onCsvOrderClick,
    vendor,
    location,
  ]);

  const csvShoppingListOnClick = useMemo(
    () => (onCsvShoppingListClick ? onCsvShoppingListClick({ vendor, date: location }) : null),
    [onCsvShoppingListClick, vendor, location]
  );

  return (
    <div className="border border-bottom-0 w-100 vendor-group">
      <div className="d-flex justify-content-between cursor-pointer ml-3 p-2" onClick={toggle}>
        <span className="p-2">
          {isOpen ? <ChevronUp /> : <ChevronDown />}
          <span className="ml-2">{vendor['name']}</span>
        </span>
        <span className="p-2">{vendor.phone}</span>
        <span className="p-2">{vendor.ordering_email}</span>

        <span className="p-2">
          {vendor.fulfillment_timelines && (
            <VendorFulfillmentDays
              fulfillment_timelines={vendor.fulfillment_timelines}
              className="justify-content-start"
            />
          )}
        </span>
        <div className="num-orders d-flex align-items-center">
          {csvOrdersOnClick && (
            <Button color="success" className="mx-2" onClick={csvOrdersOnClick}>
              Orders
            </Button>
          )}
          {csvShoppingListOnClick && (
            <Button color="success" className="mx-2" onClick={csvShoppingListOnClick}>
              Shopping
            </Button>
          )}
          <span className="p-2"> #{orders.length} </span>
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        {orders.map(order => (
          <Order key={order._id} order={order} />
        ))}
      </Collapse>
    </div>
  );
};

VendorLine.propTypes = {
  vendor: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    ordering_email: PropTypes.string.isRequired,
    fulfillment_timelines: PropTypes.object,
  }).isRequired,
  orders: PropTypes.array.isRequired,
  location: PropTypes.string,
  onCsvOrderClick: PropTypes.func,
  onCsvShoppingListClick: PropTypes.func,
};
