import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { DataHandler, Toasts } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import {
  getConnectedFixedUsersAction,
  setDumbPeriodAction,
  updateUserDumbPeriodAction,
} from '../../../../store/admin/user';
import { User } from './components/User';
import { tabs } from './components/Tabs';

const ConnectedFixedPageComponent = ({ getConnectedFixedUsers, setDumbPeriod, addToast, updateDumbSubscription }) => {
  const [users, setUsers] = useState(null);

  const { isFetching, error } = useDataEffect(getConnectedFixedUsers, setUsers);

  const updateUser = useCallback(
    user => {
      const updatedUsers = users.map(row => {
        if (row._id === user._id) {
          return user;
        }
        return row;
      });

      setUsers(updatedUsers);
    },
    [users]
  );

  return (
    <PanelPage title="Connected fixed users" tabs={tabs}>
      <DataHandler data={users} isLoading={isFetching} error={error} />
      {users &&
        users.map(user => (
          <User
            user={user}
            key={user._id}
            setDumbPeriod={data => setDumbPeriod(user._id, data)}
            addToast={addToast}
            back="/admin/disconnected/fixed"
            updateDumbSubscription={updateDumbSubscription}
            updateUser={updateUser}
          />
        ))}
      <Toasts />
    </PanelPage>
  );
};

ConnectedFixedPageComponent.propTypes = {
  getConnectedFixedUsers: PropTypes.func.isRequired,
  updateDumbSubscription: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const ConnectedFixedPage = connect(null, dispatch => ({
  getConnectedFixedUsers: () => dispatch(getConnectedFixedUsersAction()),
  setDumbPeriod: (id, data) => dispatch(setDumbPeriodAction(id, data)),
  updateDumbSubscription: (id, data) => dispatch(updateUserDumbPeriodAction(id, data)),
  addToast: data => dispatch(addToastAction(data)),
}))(ConnectedFixedPageComponent);
