import { ChangeScaleTypes, LogoutTypes } from '@bottomless/common/store';
import { GetMeTypes } from './user.actions';

const initialState = {
  me: null,
  isLoading: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetMeTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetMeTypes.SUCCESS:
      return {
        ...state,
        me: action.payload,
        isLoading: false,
      };

    case GetMeTypes.FAILURE:
      return {
        ...state,
        me: null,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
      return initialState;

    case ChangeScaleTypes.SUCCESS:
      return {
        ...initialState,
        me: state.me,
      };

    default:
      return state;
  }
};
