import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader } from '@bottomless/common/components';
import { getMeAction } from '../store';

const IsAdminAuthComponent = ({ children, me, getMe, isLoading, history, location }) => {
  const [startedLoading, setStartedLoading] = useState(false);

  useEffect(() => {
    getMe();
    setStartedLoading(true);
  }, []);

  if (isLoading || !startedLoading) {
    return <Loader />;
  }

  if (!me) {
    history.push(`/login?path=${location.pathname}`);
    return null;
  }

  if (!/@bottomless.com$/.test(me.local.email) || /^ag1(\+[\d]+)?@bottomless.com$/i.test(me.local.email)) {
    window.location.replace(`${process.env.REACT_APP_CUSTOMER_APP_URL}`);
    return null;
  }

  return children;
};

IsAdminAuthComponent.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  me: PropTypes.object,
  getMe: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const IsAdminAuth = connect(
  ({ user: { me, isLoading } }) => ({ me, isLoading }),
  dispatch => ({
    getMe: () => dispatch(getMeAction()),
  })
)(IsAdminAuthComponent);
