import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';

const CHUNK_SIZE = 50;
const TIMEOUT = 5000;

export const TriggerSelection = ({ triggerSelection, orders, addToast, getTriggerCounts }) => {
  const history = useHistory();
  const [counts, setCounts] = useState();
  const [triggered, setTriggered] = useState(false);
  const failCount = useRef(0);
  const actualCounts = useRef({});

  const onTriggerOrderGeneration = useCallback(
    async data => {
      setTriggered(true);

      await triggerSelection(data);
      addToast('Automatic generation triggered!');
    },
    [triggerSelection, addToast, setTriggered]
  );

  useEffect(() => {
    let timeout;

    const execute = async () => {
      const { payload: counts } = await getTriggerCounts();

      setCounts(counts);

      const unprocessed = counts.active + counts.waiting;

      if (!unprocessed) {
        failCount.current += 1;
      } else {
        failCount.current = 0;
      }

      if (actualCounts.current > 0 && !unprocessed) {
        history.go(0);
      }

      actualCounts.current = unprocessed;

      if (failCount.current < 10 || unprocessed > 0) {
        timeout = setTimeout(execute, TIMEOUT);
      }
    };

    timeout = setTimeout(execute, TIMEOUT);

    return () => clearTimeout(timeout);
  }, [triggered, getTriggerCounts, failCount, actualCounts, history]);

  return (
    <div className="d-flex flex-grow-1 justify-content-between align-items-center mb-4 flex-gap-1">
      <DataLoading count={counts === undefined ? 0 : 1} isLoading={counts === undefined} withMarginTop={false} />
      {counts && (
        <div className="d-flex align-items-center">
          <div className="text-sm">
            <div className="text-success">
              Active: {counts.active > 0 && '~'}
              {counts.active * CHUNK_SIZE}
            </div>
            <div className="text-warning">
              Waiting: {counts.waiting > 0 && '~'}
              {counts.waiting * CHUNK_SIZE}
            </div>
          </div>
          {(triggered || counts.active + counts.waiting > 0) && <Loader size="20" className="spin ml-3" />}
        </div>
      )}
      <Button color="success" onClick={() => onTriggerOrderGeneration()} disabled={!orders?.length || triggered}>
        Trigger automatic
      </Button>
      <Button
        color="success"
        onClick={() => onTriggerOrderGeneration({ opts: { subproductSelectionCollection: 'subproductselections2' } })}
        disabled={!orders?.length || triggered}
      >
        Trigger automatic 2
      </Button>
    </div>
  );
};

TriggerSelection.propTypes = {
  orders: PropTypes.array.isRequired,
  triggerSelection: PropTypes.func.isRequired,
  getTriggerCounts: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};
