import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { createSelectOptions } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  type: Yup.string().required(),
  reason: Yup.string().required(),
  scaleDeviceId: Yup.string().required(),
  other: Yup.string().when('reason', {
    is: reason => reason === 'Other',
    then: Yup.string().required(),
  }),
});

const reasonOptions = [
  'Noisy data',
  'Drifting data',
  'Runs out of battery quickly',
  'Customer request',
  'Physically broken',
  'Stopped working electronically',
  'Repeat zero reading',
  'Onboarding BUG',
  'Other',
].reduce(
  (all, opt) => ({
    ...all,
    [opt]: opt,
  }),
  {}
);

const typeOptions = ['onboarding', 'both', 'replacement_there', 'replacement_back'].reduce(
  (all, opt) => ({
    ...all,
    [opt]: opt,
  }),
  {}
);

export const CreateScaleShipmentForm = ({ onSubmit, onSubmitSuccess, scaleDevices, user }) => {
  const scaleDeviceOptions = useMemo(
    () => ({ '': '--Choose scale device type--', ...createSelectOptions(scaleDevices) }),
    [scaleDevices]
  );
  return (
    <Form
      initialValues={{
        type: 'onboarding',
        reason: '',
        scaleDeviceId: user.scale_device_id,
      }}
      validationSchema={Schema}
      onSubmit={({ type, reason, other, scaleDeviceId }) =>
        onSubmit({
          type,
          scaleDeviceId,
          reason: other || reason,
        })
      }
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting, values }) => (
        <>
          <Field name="type" type="select" options={typeOptions} label="Type" />
          <Field type="select" name="scaleDeviceId" label="Scale Device" options={scaleDeviceOptions} />
          <Field name="reason" type="select" options={reasonOptions} label="Reason" />
          {values.reason && values.reason === 'Other' && <Field name="other" label="Other description" />}

          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Replace
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

CreateScaleShipmentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  scaleDevices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  user: PropTypes.object,
};
