import React, { useMemo } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { createSelectOptions } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  scaleDeviceId: Yup.string().required('This field is required'),
});

export const ScaleDeviceSelectModal = ({ isOpen, toggle, onSubmit, scaleDevices }) => {
  const scaleDeviceOptions = useMemo(
    () => ({ '': '--Choose scale device type--', ...createSelectOptions(scaleDevices) }),
    [scaleDevices]
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm">
      <ModalHeader toggle={toggle}>Select Scale Device</ModalHeader>
      <ModalBody>
        <Form validationSchema={Schema} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <>
              <Field type="select" name="scaleDeviceId" label="Scale Device" options={scaleDeviceOptions} />
              <SubmitButton color="primary" isSubmitting={isSubmitting}>
                <span className="mr-2">Submit</span>
              </SubmitButton>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

ScaleDeviceSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  scaleDevices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
