import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Field, DataLoading } from '@bottomless/common/components';

const VendorFilterSchema = Yup.object().shape({
  vendor: Yup.string(),
});

export const VendorFilter = ({ onSubmit, vendors, defaultValue }) => {
  const onChange = useCallback(({ submitForm }) => () => setTimeout(submitForm), []);
  const options = useMemo(
    () =>
      vendors && Array.isArray(vendors) && vendors.reduce((all, vendor) => ({ ...all, [vendor._id]: vendor.name }), {}),
    [vendors]
  );

  if (!vendors) {
    return <DataLoading count={0} isLoading={true} loadingText="Loading vendors" />;
  }

  return (
    <Form initialValues={{ vendor: defaultValue }} validationSchema={VendorFilterSchema} onSubmit={onSubmit}>
      {({ submitForm }) => (
        <Field name="vendor" type="select" label="Vendor" options={options} onChange={onChange({ submitForm })} />
      )}
    </Form>
  );
};

VendorFilter.defaultProps = {
  defaultValue: '',
};

VendorFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};
