import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetProductOptionsTypes = createAction('Product options', 'Get all');

export const getProductOptionsAction = vendorId => ({
  [RSAA]: {
    endpoint: `admin/product_options${vendorId ? `?vendor_id=${vendorId}` : ''}`,
    method: 'GET',
    types: [GetProductOptionsTypes.REQUEST, GetProductOptionsTypes.SUCCESS, GetProductOptionsTypes.FAILURE],
  },
});

export const UpdateProductAttributeAction = createAction('Product options', 'Update attribute');

export const updateAttributeAction = ({ type, id, data }) => ({
  [RSAA]: {
    endpoint: `admin/product_options/${id}`,
    method: 'PATCH',
    body: { type, data },
    types: [
      UpdateProductAttributeAction.REQUEST,
      UpdateProductAttributeAction.SUCCESS,
      UpdateProductAttributeAction.FAILURE,
    ],
  },
});

export const CreateProductAttributeAction = createAction('Product options', 'Create attribute');

export const createAttributeAction = ({ type, data }) => ({
  [RSAA]: {
    endpoint: `admin/product_options`,
    method: 'POST',
    body: { type, data },
    types: [
      CreateProductAttributeAction.REQUEST,
      CreateProductAttributeAction.SUCCESS,
      CreateProductAttributeAction.FAILURE,
    ],
  },
});
