import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  ArrowLeft,
  Briefcase,
  ChevronDown,
  ChevronUp,
  Coffee,
  Heart,
  Package,
  PieChart,
  Settings,
  ShoppingCart,
  Shuffle,
  User,
  WifiOff,
  Gift,
} from 'react-feather';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, Toast, ToastBody } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { getManifestAction } from '@bottomless/common/store';

const NavCollapse = withRouter(({ location: { pathname }, title, children, paths }) => {
  const [isOpen, toggle, setOpen] = useToggle();

  useEffect(() => {
    if (new RegExp(paths).test(pathname)) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, paths]);

  return (
    <div className="menu-item-collapse">
      <a onClick={() => toggle()} className="d-flex flex-column text-decoration-none">
        {title}
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </a>
      <Collapse isOpen={isOpen}>
        <Nav vertical className="border-bottom">
          {children}
        </Nav>
      </Collapse>
    </div>
  );
});

NavCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  paths: PropTypes.string.isRequired,
};

const AdminComponent = ({ children, toasts, getManifest }) => {
  useEffect(() => {
    getManifest();
  }, [getManifest]);

  return (
    <div className="panel-layout panel-admin">
      <div className="panel-nav">
        <NavLink to="/profile" className="text-dark">
          <img src="/images/infinity-black.png" alt="∞" height="20" />
          <ArrowLeft size="20" />
        </NavLink>

        <NavCollapse
          title={
            <>
              <User size="26" />
              <span>Users</span>
            </>
          }
          paths="^/admin/users"
        >
          <NavLink to="/admin/users">
            <span>Users</span>
          </NavLink>
          <NavLink to="/admin/users/influencers">
            <span>Influencers</span>
          </NavLink>
          <NavLink to="/admin/users/credits">
            <span>Credits</span>
          </NavLink>
        </NavCollapse>

        <NavCollapse
          title={
            <>
              <Coffee size="26" />
              <span>Products</span>
            </>
          }
          paths="^/admin/products|^/admin/products-pricing|^/admin/attributes|^/admin/proposed_products|^/admin/rotations"
        >
          <NavLink to="/admin/products">
            <span>Products</span>
          </NavLink>
          <NavLink to="/admin/products-pricing">
            <span>Pricing</span>
          </NavLink>
          <NavLink
            to="/admin/attributes/tasting_notes_raw"
            isActive={(match, location) => /^\/admin\/attributes/.test(location.pathname)}
          >
            <span>Attributes</span>
          </NavLink>
          <NavLink to="/admin/proposed_products">
            <span>Proposed</span>
          </NavLink>
          <NavLink to="/admin/rotations">
            <span>Rotations</span>
          </NavLink>
          <NavLink to="/admin/rotation_pricing">
            <span>Rotation Pricing</span>
          </NavLink>
          <NavLink to="/admin/grocery_products">
            <span>Grocery Products</span>
          </NavLink>
        </NavCollapse>

        <NavCollapse
          title={
            <>
              <Briefcase size="26" />
              <span>Roasters</span>
            </>
          }
          paths="^/admin/vendors"
        >
          <NavLink to="/admin/vendors">
            <span>Roasters</span>
          </NavLink>
          <NavLink to="/admin/vendors/down-days/upcoming">
            <span>Down Days</span>
          </NavLink>
          <NavLink to="/admin/order_tracking/unscanned/sent_to_roaster">
            <span>Tracking</span>
          </NavLink>
          <NavLink to="/admin/order_tracking/unscanned/tracking_pre_transit">
            <span>Super Tracking</span>
          </NavLink>
          <NavLink to="/admin/vendors/packagings">
            <span>Packaging</span>
          </NavLink>
          <NavLink to="/admin/vendors/payout">
            <span>Payout</span>
          </NavLink>
          <NavLink to="/admin/vendors/leads/lead">
            <span>Leads</span>
          </NavLink>
        </NavCollapse>

        <NavLink to="/admin/disconnected">
          <WifiOff size="26" />
          <span>Disconnected</span>
        </NavLink>

        <NavCollapse
          title={
            <>
              <Shuffle size="26" />
              <span>Order Gen</span>
            </>
          }
          paths="^/admin/ordering_cockpit"
        >
          <NavLink to="/admin/ordering_cockpit/todo">Order Gen</NavLink>
          <NavLink to="/admin/ordering_cockpit/disconnected">Disconnected</NavLink>
          <NavLink to="/admin/ordering_cockpit/idle_review">Idle review</NavLink>
          <NavLink to="/admin/ordering_cockpit/idle">Idle</NavLink>
          <NavLink to="/admin/ordering_cockpit/opt_out">Opt out</NavLink>
        </NavCollapse>

        <NavCollapse
          title={
            <>
              <ShoppingCart size="26" />
              <span>Orders</span>
            </>
          }
          paths="^/admin/order_tracking|^/admin/fulfillment|^/admin/problems"
        >
          <NavLink to="/admin/fulfillment">
            <span>Fulfillment</span>
          </NavLink>
          <NavLink to="/admin/problems">
            <span>Problems</span>
          </NavLink>
          <NavLink to="/admin/subproduct-selection">
            <span>Subproduct</span>
          </NavLink>
          <NavLink to="/admin/e-commerce">
            <span>E-Commerce</span>
          </NavLink>
        </NavCollapse>

        <NavLink to="/admin/scale-shipments/tracking">
          <Package size="26" />
          <span>Scale shipments</span>
        </NavLink>

        <NavLink to="/admin/products_feedback">
          <Heart size="26" />
          <span>Feedback</span>
        </NavLink>

        <NavLink to="/admin/retention">
          <PieChart size="26" />
          <span>Retention</span>
        </NavLink>

        <NavLink to="/admin/gifts">
          <Gift size="26" />
          <span>Gifts</span>
        </NavLink>

        <NavCollapse
          title={
            <>
              <Settings size="26" />
              <span>Settings</span>
            </>
          }
          paths="^/admin/emails"
        >
          <NavLink to="/admin/emails">
            <span>Emails</span>
          </NavLink>
          <NavLink to="/admin/gifs">
            <span>Gifs</span>
          </NavLink>
          <NavLink to="/admin/order-tracking-manifest">
            <span>Order Tracking</span>
          </NavLink>
        </NavCollapse>
      </div>
      {children}
      <div className="toasts">
        {toasts.map(toast => (
          <Toast key={toast.id} isOpen={toast.isOpen} className={`toast-${toast.type}`}>
            <ToastBody>{toast.message}</ToastBody>
          </Toast>
        ))}
      </div>
    </div>
  );
};

AdminComponent.propTypes = {
  children: PropTypes.node.isRequired,
  toasts: PropTypes.array.isRequired,
  getManifest: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const Admin = connect(
  ({ toast }) => ({ toasts: toast.data }),
  dispatch => ({
    getManifest: () => dispatch(getManifestAction()),
  })
)(withRouter(AdminComponent));
