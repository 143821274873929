import {
  AddCreditsTypes,
  AddMonthsTypes,
  CloseUserAccountTypes,
  HibernateUserAccountTypes,
  CreateScaleShipmentTypes,
  GetUsersTypes,
  UpdateGrindTypes,
  UpdatePricingRuleType,
  UpdateEmailTypes,
  PauseUserAccountTypes,
  SetUserReferrerTypes,
  UpdateSocialMediaTypes,
  AttributeInfluencerTypes,
  UpdateUserDumbPeriodTypes,
  ReOpenUserAccountTypes,
  SetNeedsSupportTypes,
  GetCreditsTypes,
  UpdateAddressTypes,
  UpdateUserProductTypes,
  DisableScaleNotificationsTypes,
} from './user.actions';

const initialState = {
  data: {
    docs: [],
    total: 0,
    limit: 100,
    pages: 0,
    page: 0,
  },
  isLoading: false,
};

export const adminUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetUsersTypes.REQUEST:
    case GetCreditsTypes.REQUEST:
      return { ...initialState, isLoading: true };

    case GetCreditsTypes.SUCCESS:
    case GetUsersTypes.SUCCESS:
      return {
        data: action.payload,
        isLoading: false,
      };

    case UpdatePricingRuleType.SUCCESS:
    case CreateScaleShipmentTypes.SUCCESS:
    case AddMonthsTypes.SUCCESS:
    case CloseUserAccountTypes.SUCCESS:
    case HibernateUserAccountTypes.SUCCESS:
    case UpdateGrindTypes.SUCCESS:
    case UpdateEmailTypes.SUCCESS:
    case PauseUserAccountTypes.SUCCESS:
    case SetUserReferrerTypes.SUCCESS:
    case UpdateSocialMediaTypes.SUCCESS:
    case AttributeInfluencerTypes.SUCCESS:
    case UpdateUserDumbPeriodTypes.SUCCESS:
    case ReOpenUserAccountTypes.SUCCESS:
    case SetNeedsSupportTypes.SUCCESS:
    case AddCreditsTypes.SUCCESS:
    case UpdateAddressTypes.SUCCESS:
    case DisableScaleNotificationsTypes.SUCCESS:
    case UpdateUserProductTypes.SUCCESS: {
      const user = action.payload;
      const index = state.data.docs.findIndex(u => u._id === user._id);

      return {
        ...state,
        data: {
          ...state.data,
          docs: [...state.data.docs.slice(0, index), user, ...state.data.docs.slice(index + 1)],
        },
      };
    }
    default:
      return state;
  }
};
