import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const CreateCustomerNoteTypes = createAction('Customer notes', 'Create');

export const createCustomerNoteAction = data => ({
  [RSAA]: {
    endpoint: 'admin/customer_notes',
    method: 'POST',
    body: data,
    types: [CreateCustomerNoteTypes.REQUEST, CreateCustomerNoteTypes.SUCCESS, CreateCustomerNoteTypes.FAILURE],
  },
});

export const GetCustomerNotesTypes = createAction('Customer notes', 'Get all');

export const getCustomerNotesAction = id => ({
  [RSAA]: {
    endpoint: `admin/customer_notes/${id}`,
    method: 'GET',
    types: [GetCustomerNotesTypes.REQUEST, GetCustomerNotesTypes.SUCCESS, GetCustomerNotesTypes.FAILURE],
  },
});

export const DeleteCustomerNoteTypes = createAction('Customer notes', 'Delete');

export const deleteCustomerNoteAction = id => ({
  [RSAA]: {
    endpoint: `admin/customer_notes/${id}`,
    method: 'DELETE',
    types: [DeleteCustomerNoteTypes.REQUEST, DeleteCustomerNoteTypes.SUCCESS, DeleteCustomerNoteTypes.FAILURE],
  },
});
