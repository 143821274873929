import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const CancelOrderTypes = createAction('Admin Orders', 'Cancel order');

export const cancelOrderAdminAction = id => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/change-status`,
    method: 'PATCH',
    body: { status: 'cancelled' },
    types: [CancelOrderTypes.REQUEST, CancelOrderTypes.SUCCESS, CancelOrderTypes.FAILURE],
  },
});

export const VendorReplaceTypes = createAction('Admin Orders', 'Vendor replace');

export const vendorReplaceAction = id => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/vendor-replace`,
    method: 'POST',
    types: [VendorReplaceTypes.REQUEST, VendorReplaceTypes.SUCCESS, VendorReplaceTypes.FAILURE],
  },
});

export const BottomlessReplaceTypes = createAction('Admin Orders', 'Bottomless replace');

export const bottomlessReplaceAction = id => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/bottomless-replace`,
    method: 'POST',
    types: [BottomlessReplaceTypes.REQUEST, BottomlessReplaceTypes.SUCCESS, BottomlessReplaceTypes.FAILURE],
  },
});

export const CreateOrderNoteTypes = createAction('Admin Orders', 'Create note');

export const createOrderNoteAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/note`,
    method: 'POST',
    body: data,
    types: [CreateOrderNoteTypes.REQUEST, CreateOrderNoteTypes.SUCCESS, CreateOrderNoteTypes.FAILURE],
  },
});

export const GetOrdersTypes = createAction('Admin Orders', 'Get orders');

export const getOrdersAction = params => ({
  [RSAA]: {
    endpoint: `admin/orders${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetOrdersTypes.REQUEST, GetOrdersTypes.SUCCESS, GetOrdersTypes.FAILURE],
  },
});

export const ChargeOrderTypes = createAction('Admin Orders', 'Charge order');

export const chargeOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/charge`,
    method: 'POST',
    body: data,
    types: [ChargeOrderTypes.REQUEST, ChargeOrderTypes.SUCCESS, ChargeOrderTypes.FAILURE],
  },
});

export const GetOrdersHistoryTypes = createAction('Admin Orders', 'Get orders history');

export const getOrdersHistoryAction = userId => ({
  [RSAA]: {
    endpoint: `admin/orders/history/${userId}`,
    method: 'GET',
    types: [GetOrdersHistoryTypes.REQUEST, GetOrdersHistoryTypes.SUCCESS, GetOrdersHistoryTypes.FAILURE],
  },
});

export const UpdateOrderTypes = createAction('Admin Orders', 'Charge order');

export const updateOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateOrderTypes.REQUEST, UpdateOrderTypes.SUCCESS, UpdateOrderTypes.FAILURE],
  },
});

export const SyncOrderTrackingTypes = createAction('Admin Orders', 'Sync tracking');

export const syncOrderTrackingAction = id => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/tracking/sync`,
    method: 'POST',
    types: [SyncOrderTrackingTypes.REQUEST, SyncOrderTrackingTypes.SUCCESS, SyncOrderTrackingTypes.FAILURE],
  },
});

export const SyncOrderBottomlessTrackingTypes = createAction('Admin Orders', 'Sync tracking');

export const syncOrderBottomlessTrackingAction = id => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/tracking/bottomless`,
    method: 'POST',
    types: [
      SyncOrderBottomlessTrackingTypes.REQUEST,
      SyncOrderBottomlessTrackingTypes.SUCCESS,
      SyncOrderBottomlessTrackingTypes.FAILURE,
    ],
  },
});

export const FetchOrderTrackingTypes = createAction('Admin Orders', 'Fetch tracking');

export const fetchOrderTrackingAction = id => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/tracking/`,
    method: 'GET',
    types: [FetchOrderTrackingTypes.REQUEST, FetchOrderTrackingTypes.SUCCESS, FetchOrderTrackingTypes.FAILURE],
  },
});

export const SendOrderNotificationTypes = createAction('Admin Orders', 'Send notification');

export const sendOrderNotificationAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/notification`,
    method: 'POST',
    body: data,
    types: [SendOrderNotificationTypes.REQUEST, SendOrderNotificationTypes.SUCCESS, SendOrderNotificationTypes.FAILURE],
  },
});

export const ReplaceOrderSubproductTypes = createAction('Admin Orders', 'Replace subproduct');

export const replaceOrderSubproductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/replace`,
    method: 'POST',
    body: data,
    types: [
      ReplaceOrderSubproductTypes.REQUEST,
      ReplaceOrderSubproductTypes.SUCCESS,
      ReplaceOrderSubproductTypes.FAILURE,
    ],
  },
});

export const AcknowledgeOrderTypes = createAction('Admin Orders', 'Acknowledge order');

export const acknowledgeOrderAction = id => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/acknowledge`,
    method: 'POST',
    types: [AcknowledgeOrderTypes.REQUEST, AcknowledgeOrderTypes.SUCCESS, AcknowledgeOrderTypes.FAILURE],
  },
});

export const ReplaceOrderTypes = createAction('Admin Orders', 'Replace order');

export const replaceOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/replace-order`,
    method: 'POST',
    body: data,
    types: [ReplaceOrderTypes.REQUEST, ReplaceOrderTypes.SUCCESS, ReplaceOrderTypes.FAILURE],
  },
});

export const ReplaceOrderWithCoffeeCreditTypes = createAction('Admin Orders', 'Replace order with coffee credit');

export const replaceOrderWithCoffeeCreditAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/replace-order-coffee-credit`,
    method: 'POST',
    body: data,
    types: [
      ReplaceOrderWithCoffeeCreditTypes.REQUEST,
      ReplaceOrderWithCoffeeCreditTypes.SUCCESS,
      ReplaceOrderWithCoffeeCreditTypes.FAILURE,
    ],
  },
});

export const ResolveProblemTypes = createAction('Admin Orders', 'Resolve reported problem');

export const resolveProblemAction = id => ({
  [RSAA]: {
    endpoint: `admin/orders/${id}/resolve-problem`,
    method: 'PATCH',
    types: [ResolveProblemTypes.REQUEST, ResolveProblemTypes.SUCCESS, ResolveProblemTypes.FAILURE],
  },
});

export const GetUnprocessedFulfillmentTypes = createAction('Admin Orders', 'Get unprocessed fulfillment');

export const getUnprocessedFulfillment = () => ({
  [RSAA]: {
    endpoint: `admin/orders/fulfillment/unprocessed`,
    method: 'GET',
    types: [
      GetUnprocessedFulfillmentTypes.REQUEST,
      GetUnprocessedFulfillmentTypes.SUCCESS,
      GetUnprocessedFulfillmentTypes.FAILURE,
    ],
  },
});

export const GetSubproductFulfillmentTypes = createAction('Admin Orders', 'Get subproduct fulfillment');

export const getSubproductFulfillment = () => ({
  [RSAA]: {
    endpoint: `admin/orders/fulfillment/subproduct`,
    method: 'GET',
    types: [
      GetSubproductFulfillmentTypes.REQUEST,
      GetSubproductFulfillmentTypes.SUCCESS,
      GetSubproductFulfillmentTypes.FAILURE,
    ],
  },
});

export const GetPaidFulfillmentTypes = createAction('Admin Orders', 'Get paid fulfillment');

export const getPaidFulfillment = () => ({
  [RSAA]: {
    endpoint: `admin/orders/fulfillment/paid`,
    method: 'GET',
    types: [GetPaidFulfillmentTypes.REQUEST, GetPaidFulfillmentTypes.SUCCESS, GetPaidFulfillmentTypes.FAILURE],
  },
});

export const GetSentToRoastersFulfillmentTypes = createAction('Admin Orders', 'Get sent to roasters fulfillment');

export const getSentToRoastersFulfillment = () => ({
  [RSAA]: {
    endpoint: `admin/orders/fulfillment/sent-to-roaster`,
    method: 'GET',
    types: [
      GetSentToRoastersFulfillmentTypes.REQUEST,
      GetSentToRoastersFulfillmentTypes.SUCCESS,
      GetSentToRoastersFulfillmentTypes.FAILURE,
    ],
  },
});

export const GetSlowLocalFulfillmentTypes = createAction('Admin Orders', 'Get slow local fulfillment');

export const getSlowLocalFulfillment = () => ({
  [RSAA]: {
    endpoint: `admin/orders/fulfillment/slow-local`,
    method: 'GET',
    types: [
      GetSlowLocalFulfillmentTypes.REQUEST,
      GetSlowLocalFulfillmentTypes.SUCCESS,
      GetSlowLocalFulfillmentTypes.FAILURE,
    ],
  },
});

export const GetReportedOrdersTypes = createAction('Admin Orders', 'Get reported orders');

export const getReportedOrders = () => ({
  [RSAA]: {
    endpoint: `admin/orders/problems`,
    method: 'GET',
    types: [GetReportedOrdersTypes.REQUEST, GetReportedOrdersTypes.SUCCESS, GetReportedOrdersTypes.FAILURE],
  },
});

export const GetReportedScaleTypes = createAction('OrderGen Instance', 'Get need support');

export const getReportedScalesAction = params => ({
  [RSAA]: {
    endpoint: `admin/orders/scale-problems${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetReportedScaleTypes.REQUEST, GetReportedScaleTypes.SUCCESS, GetReportedScaleTypes.FAILURE],
  },
});

export const GetSummaryTypes = createAction('Admin Orders', 'Get fulfillment summary');

export const getSummaryAction = () => ({
  [RSAA]: {
    endpoint: `admin/orders/fulfillment/summary`,
    method: 'GET',
    types: [GetSummaryTypes.REQUEST, GetSummaryTypes.SUCCESS, GetSummaryTypes.FAILURE],
  },
});

export const TriggerSmokeTestTypes = createAction('Admin Orders', 'Trigger smoke test');

export const triggerSmokeTestAction = () => ({
  [RSAA]: {
    endpoint: 'admin/orders/fulfillment/smoke',
    method: 'POST',
    types: [TriggerSmokeTestTypes.REQUEST, TriggerSmokeTestTypes.SUCCESS, TriggerSmokeTestTypes.FAILURE],
  },
});

export const TriggerShopifySmokeTestTypes = createAction('Admin Orders', 'Trigger shopify smoke test');

export const triggerShopifySmokeTestAction = () => ({
  [RSAA]: {
    endpoint: 'admin/orders/fulfillment/smoke/shopify',
    method: 'POST',
    types: [
      TriggerShopifySmokeTestTypes.REQUEST,
      TriggerShopifySmokeTestTypes.SUCCESS,
      TriggerShopifySmokeTestTypes.FAILURE,
    ],
  },
});

export const FulfillOrdersTypes = createAction('Admin Orders', 'Fulfill orders');

export const fulfillOrdersAction = data => ({
  [RSAA]: {
    endpoint: 'admin/orders/fulfillment/fulfill',
    method: 'POST',
    body: data,
    types: [FulfillOrdersTypes.REQUEST, FulfillOrdersTypes.SUCCESS, FulfillOrdersTypes.FAILURE],
  },
});
