import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { Address, DateFormat } from '@bottomless/common/components';
import { ShipmentComponent } from './Shipment';

export const ShipmentsComponent = ({
  shipmentsData,
  onEdit,
  syncStatus,
  purchaseScaleShipment,
  onSelect,
  onBatchClick,
  onTracking,
  selected,
  setShipmentToUpdate,
  toggleUppdateTrackingModal,
  scaleDevices,
}) => {
  const { user, shipments, firstOrder } = shipmentsData;

  return (
    <Card className="mb-2" body>
      <Row className="pb-3">
        <Col>
          <div>
            {user.first_name} {user.last_name}
            {user.verifiedAddress && <Address address={user.verifiedAddress} />}
          </div>
        </Col>
        <Col>
          <div className="text-secondary">
            <span className="d-block">
              <Link to={`/manage_customers?search=${user._id}`}>#{user._id}</Link>
            </span>
            <span className="d-block text-primary mb-2">
              <a
                href={`https://app.frontapp.com/compose?mailto=mailto:${user.local.email}`}
                rel="noopener noreferrer"
                target="_blank"
                className="text-primary"
              >
                {user.local.email}
              </a>
            </span>
          </div>
        </Col>
        <Col className="text-sm">
          <div>
            <Link
              to={`/admin/ordering_cockpit/${user._id}?orderId=${
                firstOrder ? firstOrder._id : ''
              }&back=admin/scale-shipmentsv2/`}
              className="text-info text-sm"
            >
              {firstOrder ? firstOrder._id : ''} / {firstOrder ? firstOrder.status : ''}
            </Link>
          </div>
          <div className="text-success">
            {firstOrder ? (
              firstOrder.date_fulfilled ? (
                <DateFormat date={firstOrder.date_fulfilled} withTime />
              ) : (
                '---'
              )
            ) : (
              ''
            )}{' '}
            / {get(firstOrder, 'shipping_status', '---')}
          </div>
        </Col>
      </Row>

      {shipments.map(shipment => (
        <ShipmentComponent
          shipment={shipment}
          key={shipment._id}
          onBatchClick={onBatchClick}
          onEdit={onEdit}
          onSelect={onSelect}
          purchaseScaleShipment={purchaseScaleShipment}
          selected={selected.some(s => s._id === shipment._id)}
          syncStatus={syncStatus}
          onTracking={onTracking}
          setShipmentToUpdate={setShipmentToUpdate}
          toggleUppdateTrackingModal={toggleUppdateTrackingModal}
          scaleDevices={scaleDevices}
        />
      ))}
    </Card>
  );
};

ShipmentsComponent.defaultProps = {
  selected: false,
};

ShipmentsComponent.propTypes = {
  shipmentsData: PropTypes.shape({
    firstOrder: PropTypes.shape({
      _id: PropTypes.string,
      date_fulfilled: PropTypes.string,
      status: PropTypes.string,
      shipping_status: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape({
      _id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      local: PropTypes.object,
      verifiedAddress: PropTypes.object,
    }).isRequired,
    shipments: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        batch_id: PropTypes.string,
        easypost_id: PropTypes.string,
        status: PropTypes.string,
        service: PropTypes.string,
        type: PropTypes.string,
        shipping_cost: PropTypes.number,
        label_url: PropTypes.string,
        shipping_status: PropTypes.string,
        tracking_number: PropTypes.string,
        timestamp: PropTypes.string,
        updated_at: PropTypes.string,
        scheduled_at: PropTypes.string,
      })
    ).isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  syncStatus: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onBatchClick: PropTypes.func.isRequired,
  onTracking: PropTypes.func.isRequired,
  selected: PropTypes.array,
  purchaseScaleShipment: PropTypes.func.isRequired,
  setShipmentToUpdate: PropTypes.func.isRequired,
  toggleUppdateTrackingModal: PropTypes.func.isRequired,
  scaleDevices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
