import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DataLoading, Pagination } from '@bottomless/common/components';
import { useUnmount, useDataEffect } from '@bottomless/common/hooks';
import { connectWithAbort, addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { createOrderGenAction } from '../../../../store/admin/ordergen';
import { User } from './components/User';
import { tabs } from './Tabs';
import './OrderGen.scss';
import { setNeedsSupportAction, setOrdergenStatusAction, setTareAction } from '../../../../store/admin/user';
import { getCustomerNotesAction } from '../../../../store';

const PaginatedOrderGenPageComponent = ({
  getUsers: rawGetUsers,
  setTare,
  addToast,
  abortRequests,
  setNeedsSupport,
  getCustomerNotes,
  setOrdergenStatus,
  isNeedsSupportTab,
  onGenerateInstance,
  withTabs,
  withDisconnected,
  title,
  hideOnGeneration,
}) => {
  useUnmount(abortRequests);

  const [users, setUsers] = useState({ isLoading: true });
  const getUsers = useCallback((...data) => rawGetUsers(...data), []);
  useDataEffect(getUsers, setUsers);

  const onCreateNote = userId => note => {
    setUsers({
      ...users,
      docs: users.docs.map(user => (user._id !== userId ? user : { ...user, notes: [note, ...user.notes] })),
    });
  };

  const onDeleteNote = userId => note => {
    setUsers({
      ...users,
      docs: users.docs.map(user =>
        user._id !== userId ? user : { ...user, notes: user.notes.filter(({ _id }) => note._id !== _id) }
      ),
    });
  };

  const onOrdergenStatusChange = useCallback(
    user => () => {
      setUsers({ ...users, docs: users.docs.filter(u => u._id !== user._id) });
    },
    [users, setUsers]
  );

  const onNeedsSupport = useCallback(
    user => async ({ needs_support: needsSupport }) => {
      if ((!needsSupport && isNeedsSupportTab) || (needsSupport && !isNeedsSupportTab)) {
        setUsers({ ...users, docs: users.docs.filter(u => u._id !== user._id) });
        return;
      }

      try {
        const { payload: notes } = await getCustomerNotes(user._id);
        setUsers({
          ...users,
          docs: users.docs.map(u => (u._id !== user._id ? u : { ...u, notes, support: { order: { needsSupport } } })),
        });
      } catch (e) {
        addToast(`Error during fetching customer notes: ${e.message}`);
      }
    },
    [users, setUsers, getCustomerNotes, addToast]
  );

  return (
    <PanelPage title={title} className="page-ordergen" tabs={withTabs ? tabs : undefined}>
      <DataLoading count={(users.docs || []).length} isLoading={Boolean(users.isLoading)} />
      {users.docs && (
        <>
          {users.docs.map(user => (
            <User
              user={user}
              key={user._id}
              onCreateNote={onCreateNote(user._id)}
              onDeleteNote={onDeleteNote(user._id)}
              setTare={data => setTare(user._id, data)}
              addToast={addToast}
              setNeedsSupport={data => setNeedsSupport(user._id, data)}
              onNeedsSupport={onNeedsSupport(user)}
              onGenerateInstance={onGenerateInstance}
              withDisconnected={withDisconnected}
              onOrdergenStatusChange={onOrdergenStatusChange(user)}
              setOrdergenStatus={setOrdergenStatus}
              hideOnGeneration={hideOnGeneration}
            />
          ))}
          <div className="mt-2">
            <Pagination collection={users} onPageChange={getUsers} setter={setUsers} />
          </div>
        </>
      )}
    </PanelPage>
  );
};

PaginatedOrderGenPageComponent.propTypes = {
  getUsers: PropTypes.func.isRequired,
  createOrderGen: PropTypes.func.isRequired,
  setTare: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  abortRequests: PropTypes.func.isRequired,
  setNeedsSupport: PropTypes.func.isRequired,
  getCustomerNotes: PropTypes.func.isRequired,
  onGenerateInstance: PropTypes.func.isRequired,
  setOrdergenStatus: PropTypes.func.isRequired,
  isNeedsSupportTab: PropTypes.bool,
  withTabs: PropTypes.bool,
  withDisconnected: PropTypes.bool,
  title: PropTypes.string,
  hideOnGeneration: PropTypes.bool,
};

PaginatedOrderGenPageComponent.defaultProps = {
  withTabs: true,
  title: 'Order Gen',
};

export const PaginatedOrderGenPage = connectWithAbort(null, dispatch => ({
  createOrderGen: data => dispatch(createOrderGenAction(data)),
  setTare: (userId, data) => dispatch(setTareAction(userId, data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  setNeedsSupport: (id, data) => dispatch(setNeedsSupportAction(id, data)),
  getCustomerNotes: (id, data) => dispatch(getCustomerNotesAction(id, data)),
  setOrdergenStatus: (id, data) => dispatch(setOrdergenStatusAction(id, data)),
}))(PaginatedOrderGenPageComponent);
