import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Pagination, DateFormat } from '@bottomless/common/components';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getCreditsAction } from '../../../../store/admin/user';
import { Filters } from './components/Filters';
import { NavLink } from 'react-router-dom';

const UsersPageComponent = ({ credits, getCredits, isLoading, location: { search } }) => {
  useEffect(() => {
    getCredits();
  }, [search]);

  return (
    <PanelPage title="Credits" className="page-admin-users">
      <Row className="mb-4">
        <Col>
          <Filters isLoading={isLoading} hasData={credits.docs.length > 0} />
        </Col>
      </Row>

      {credits && (
        <>
          <div className="text-secondary text-sm text-right">
            {credits.total} ({credits.page}/{credits.pages})
          </div>
          <Row>
            {credits.docs.map(credit => (
              <Col xs="12" key={credit._id}>
                <Card body className="mb-4 lead-card">
                  <CardTitle className="d-flex justify-content-between border-bottom mb-1 p-2 font-weight-bold">
                    <span>
                      {credit._id} / {credit.user?.first_name} {credit.user?.last_name}
                    </span>

                    <NavLink
                      className="ml-2 btn btn-sm btn-outline-secondary"
                      to={`/admin/users/credits/${credit._id}`}
                    >
                      <span>Edit</span>
                    </NavLink>
                  </CardTitle>
                  <CardBody>
                    <Row>
                      <Col xs="4">
                        <strong>User Id:</strong> {credit.user?._id} <br />
                        <strong>Source:</strong> {credit.source}
                      </Col>
                      <Col xs="4">
                        <strong>Status:</strong> {credit.status}
                        <br />
                        <strong>Reason:</strong> {credit.reason}
                      </Col>
                      <Col xs="4">
                        <strong>Redeemed Order:</strong> {credit.redeemed_order}
                        <br />
                        Redeemed At:
                        <DateFormat date={credit.redeemed_at} withTime />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="comments">
                        <strong>Shipping Price:</strong> {credit.shipping_price}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination collection={credits} onPageChange={getCredits} />
        </>
      )}
    </PanelPage>
  );
};

UsersPageComponent.propTypes = {
  getCredits: PropTypes.func.isRequired,

  credits: PropTypes.shape({
    docs: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
  }),
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export const CreditsPage = connect(
  ({ adminUsers }) => ({
    credits: adminUsers.data,
    isLoading: adminUsers.isLoading,
  }),
  (dispatch, { location: { search } }) => ({
    getCredits: params => dispatch(getCreditsAction({ ...params, ...qs.parse(search) })),
  })
)(UsersPageComponent);
