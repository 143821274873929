import 'chartjs-plugin-annotation';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';

export const BatteryGraph = ({ stats, datasets, options }) => {
  const height = window.innerWidth > 772 ? 80 : 130;

  const data = stats
    .filter(record => record.battery_perc)
    .map(record => ({
      x: new Date(record.timestamp),
      y: record.battery_perc,
    }));

  const defaults = {
    legend: { display: false },
    scales: {
      xAxes: [{ type: 'time', time: { unit: 'day' }, gridLines: { display: false } }],
      yAxes: [{ ticks: { callback: battery => `${battery} %`, suggestedMax: 100, suggestedMin: 0 } }],
    },
    tooltips: { callbacks: { title: () => null } },
    elements: { line: { tension: 0 } }, // Bezier Curve parameter,
  };

  return (
    <div className="pofile-graph">
      <Line
        data={{
          datasets: [
            {
              data,
              pointRadius: 1,
              backgroundColor: 'rgba(0, 149, 212, 0.2)',
              ...(datasets.length > 0
                ? {
                    label: 'Weight',
                  }
                : {}),
            },
            ...datasets,
          ],
        }}
        options={{ ...defaults, ...options }}
        height={height}
      />
    </div>
  );
};

BatteryGraph.defaultProps = {
  datasets: [],
  options: {},
};

BatteryGraph.propTypes = {
  stats: PropTypes.array.isRequired,
  datasets: PropTypes.array,
  options: PropTypes.object,
};
