import PropTypes from 'prop-types';
import React, { useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox, DataLoading } from '@bottomless/common/components';
import { week } from '@bottomless/common/utils';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { getLeadAction, createLeadsAction, updateLeadsAction } from '../../../../../store/admin/vendor';
import { PanelPage } from '../../../../../layouts/PanelPage/PanelPage';

const Schema = Yup.object().shape({
  company: Yup.string().required(),
  location: Yup.string(),
  website: Yup.string(),
  contact_name: Yup.string(),
  email: Yup.string().email(),
  phone: Yup.string(),
  roasting_days: Yup.object({
    monday: Yup.bool(),
    tuesday: Yup.bool(),
    wednesday: Yup.bool(),
    thursday: Yup.bool(),
    friday: Yup.bool(),
    saturday: Yup.bool(),
    sunday: Yup.bool(),
  }),
  fulfillment_days: Yup.object().shape({
    monday: Yup.bool(),
    tuesday: Yup.bool(),
    wednesday: Yup.bool(),
    thursday: Yup.bool(),
    friday: Yup.bool(),
    saturday: Yup.bool(),
    sunday: Yup.bool(),
  }),
  notes: Yup.string(),
  status: Yup.string(),
});

const FieldWithStyle = ({ name: fieldName, label, type }) => (
  <>
    <div>
      <span> {label} </span>
    </div>
    <Field type={type} placeholder={label} name={fieldName} className="custom-field" />
  </>
);

FieldWithStyle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
const statuses = [
  { value: 'lead', label: 'Lead' },
  { value: 'invited', label: 'Invited' },
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
];

const LeadFormComponent = ({
  submitLead,
  getLead,
  updateLead,
  addToast,
  history,
  match: {
    params: { id },
  },
}) => {
  const [lead, setLead] = useState(null);
  const { isFetching } = useConditionalDataEffect(id, getLead, setLead, id, {}, [id]);

  const formRef = useRef();
  const handleSubmit = async data => {
    try {
      if (id && lead) {
        await updateLead(id, data);
      } else {
        await submitLead(data);
      }
      addToast('Lead created/updated succesfully');
      history.push('/admin/vendors/leads/lead');
    } catch (error) {
      addToast('Unable to add/edit lead');
    }
  };

  const isLead = useMemo(() => {
    return Boolean((id && lead) || !id);
  }, [id, lead]);

  return (
    <PanelPage title={'Vendor Leads'}>
      {id && <DataLoading count={(lead && 1) || 0} isLoading={isFetching} />}
      {isLead && (
        <Row className="justify-content-center mb-4">
          <Col xs="12" sm="10" md="8" className={'pb-4'}>
            <Form
              innerRef={formRef}
              initialValues={{
                fulfillment_days: {
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: false,
                  sunday: false,
                },
                roasting_days: {
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: false,
                  sunday: false,
                },
                ...lead,
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Schema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values }) => (
                <>
                  <FieldWithStyle name="company" label="Company name" />
                  {id && (
                    <Field
                      defaultValue={statuses.find(el => el.value === values.status)}
                      name="status"
                      type="select-t"
                      options={statuses}
                      select_label={'Status'}
                      isMulti={false}
                      shouldDelete={true}
                    />
                  )}
                  <FieldWithStyle name="location" label="Roastery location" />
                  <FieldWithStyle name="website" label="Website" />
                  <FieldWithStyle name="contact_name" label="Contact Name" />
                  <FieldWithStyle name="email" type="email" label="E-mail" />
                  <FieldWithStyle name="phone" type="phone" label="Phone number" />
                  <div className="d-flex flex-column mb-3">
                    <span className="mb-1">Roasting Days</span>
                    <div className="d-flex justify-content-between">
                      {week.map((day, i) => (
                        <div className="mr-1" key={i}>
                          <Checkbox name={`roasting_days[${day}]`} label={day} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <span className="mb-1">Shipping fulfillment Schedule</span>
                    <div className="d-flex justify-content-between">
                      {week.map((day, i) => (
                        <div className="mr-1" key={i}>
                          <Checkbox name={`fulfillment_days[${day}]`} label={day} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <FieldWithStyle type="textarea" name="notes" label="Comments" />
                  <SubmitButton color="dark" isSubmitting={isSubmitting} loadingText="Creating">
                    Submit <i className="fa fa-long-arrow-right ml-1" />
                  </SubmitButton>
                </>
              )}
            </Form>
          </Col>
        </Row>
      )}
    </PanelPage>
  );
};

LeadFormComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  submitLead: PropTypes.func.isRequired,
  getLead: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const LeadForm = connect(null, dispatch => ({
  submitLead: data => dispatch(createLeadsAction(data)),
  getLead: id => dispatch(getLeadAction(id)),
  updateLead: (id, data) => dispatch(updateLeadsAction(id, data)),
  addToast: data => dispatch(addToastAction(data)),
}))(LeadFormComponent);
