import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, SubmitButton, Checkbox, Field } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  needs_support: Yup.bool(),
  note: Yup.string(),
});

export const NeedsSupportForm = ({ user, onSubmit, onSubmitSuccess }) => {
  const doesUserNeedSupport = user && user.support && user.support.order && user.support.order.needs_support;

  return (
    <Form
      initialValues={{ needs_support: !!doesUserNeedSupport }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <div className="mb-4">
            <Checkbox name="needs_support" label="Needs support" />
          </div>
          <Field name="note" type="textarea" label="Note" />
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

NeedsSupportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  user: PropTypes.shape({
    support: PropTypes.shape({
      order: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
      scale: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
    }),
  }),
};

NeedsSupportForm.defaultProps = {
  user: {
    support: {
      order: {},
      scale: {},
    },
  },
};
