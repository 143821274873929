import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetTodoOrderGenTypes = createAction('OrderGen Instance', 'Get todo');

const makeUrlWithQueryParams = (url, params) => `${url}${params ? `?${qs.stringify(params)}` : ''}`;

export const getTodoOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/todo${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetTodoOrderGenTypes.REQUEST, GetTodoOrderGenTypes.SUCCESS, GetTodoOrderGenTypes.FAILURE],
  },
});

export const GetDoneOrderGenTypes = createAction('OrderGen Instance', 'Get done');

export const getDoneOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/done${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetDoneOrderGenTypes.REQUEST, GetDoneOrderGenTypes.SUCCESS, GetDoneOrderGenTypes.FAILURE],
  },
});

export const GetNoDataOrderGenTypes = createAction('OrderGen Instance', 'Get no data');

export const getNoDataOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/no-data${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetNoDataOrderGenTypes.REQUEST, GetNoDataOrderGenTypes.SUCCESS, GetNoDataOrderGenTypes.FAILURE],
  },
});

export const GetDisconnectedOrderGenTypes = createAction('OrderGen Instance', 'Get disconnected');

export const getDisconnectedOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/disconnected${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetDisconnectedOrderGenTypes.REQUEST,
      GetDisconnectedOrderGenTypes.SUCCESS,
      GetDisconnectedOrderGenTypes.FAILURE,
    ],
  },
});

export const GetNeedSupportOrderGenTypes = createAction('OrderGen Instance', 'Get need support');

export const getNeedSupportOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/needs-support${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetNeedSupportOrderGenTypes.REQUEST,
      GetNeedSupportOrderGenTypes.SUCCESS,
      GetNeedSupportOrderGenTypes.FAILURE,
    ],
  },
});

export const SlowLocalOrderGenTypes = createAction('OrderGen Instance', 'Get slow local');

export const getSlowLocalOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/slow-local${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [SlowLocalOrderGenTypes.REQUEST, SlowLocalOrderGenTypes.SUCCESS, SlowLocalOrderGenTypes.FAILURE],
  },
});

export const GetIdleUsersOrderGenTypes = createAction('OrderGen Instance', 'Get idle users');

export const getIdleUsersOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/idle?order_gen_status=idle${params ? `&${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetIdleUsersOrderGenTypes.REQUEST, GetIdleUsersOrderGenTypes.SUCCESS, GetIdleUsersOrderGenTypes.FAILURE],
  },
});

export const GetOptOutOrderGenTypes = createAction('OrderGen Instance', 'Get opt out users');

export const getOptOutOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/idle?order_gen_status=opt_out${params ? `&${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetOptOutOrderGenTypes.REQUEST, GetOptOutOrderGenTypes.SUCCESS, GetOptOutOrderGenTypes.FAILURE],
  },
});

export const GetIdleReviewUsersOrderGenTypes = createAction('OrderGen Instance', 'Get idle users');

export const getIdleReviewUsersOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/idle?order_gen_status=idle_review${params ? `&${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetIdleReviewUsersOrderGenTypes.REQUEST,
      GetIdleReviewUsersOrderGenTypes.SUCCESS,
      GetIdleReviewUsersOrderGenTypes.FAILURE,
    ],
  },
});
export const GetOnTheWayOrderGenTypes = createAction('OrderGen Instance', 'Get on the way');

export const getOnTheWayOrderGenAction = params => ({
  [RSAA]: {
    endpoint: `admin/ordergen/on-the-way${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetOnTheWayOrderGenTypes.REQUEST, GetOnTheWayOrderGenTypes.SUCCESS, GetOnTheWayOrderGenTypes.FAILURE],
  },
});

export const GetUserRecordsTypes = createAction('OrderGen Instance', 'Get user records');

export const getUserRecordsAction = (id, is_details = true) => ({
  [RSAA]: {
    endpoint: `admin/ordergen/${id}/records/${is_details}`,
    method: 'GET',
    types: [GetUserRecordsTypes.REQUEST, GetUserRecordsTypes.SUCCESS, GetUserRecordsTypes.FAILURE],
  },
});

export const GetSubproductPredictionsTypes = createAction('OrderGen Instance', 'Get subproduct predictions');

export const getSubproductPredictionsAction = (id, subproduct) => ({
  [RSAA]: {
    endpoint: `admin/ordergen/${id}/subproduct-predictions?subproduct=${subproduct}`,
    method: 'GET',
    types: [
      GetSubproductPredictionsTypes.REQUEST,
      GetSubproductPredictionsTypes.SUCCESS,
      GetSubproductPredictionsTypes.FAILURE,
    ],
  },
});

export const CreateOrderGenTypes = createAction('OrderGen Instance', 'Create');

export const createOrderGenAction = (data, params) => ({
  [RSAA]: {
    endpoint: `admin/ordergen${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'POST',
    body: { source: window.location.href, ...data },
    types: [CreateOrderGenTypes.REQUEST, CreateOrderGenTypes.SUCCESS, CreateOrderGenTypes.FAILURE],
  },
});

export const GetUserOrderGenTypes = createAction('OrderGen Instance', 'Get single ser');

export const getUserOrderGenAction = id => ({
  [RSAA]: {
    endpoint: `admin/ordergen/${id}`,
    method: 'GET',
    types: [GetNoDataOrderGenTypes.REQUEST, GetNoDataOrderGenTypes.SUCCESS, GetNoDataOrderGenTypes.FAILURE],
  },
});

export const GetUserNotesOrderGenTypes = createAction('OrderGen Instance', 'Get single user notes');

export const getUserNotesOrderGenAction = id => ({
  [RSAA]: {
    endpoint: `admin/ordergen/${id}/notes`,
    method: 'GET',
    types: [GetUserNotesOrderGenTypes.REQUEST, GetUserNotesOrderGenTypes.SUCCESS, GetUserNotesOrderGenTypes.FAILURE],
  },
});

export const PrefetchTodoOrderGenTypes = createAction('OrderGen Instance', 'Prefetch todo');

export const prefetchTodoOrderGenAction = params => ({
  [RSAA]: {
    endpoint: makeUrlWithQueryParams('admin/ordergen/prefetch-todo', params),
    method: 'GET',
    types: [PrefetchTodoOrderGenTypes.REQUEST, PrefetchTodoOrderGenTypes.SUCCESS, PrefetchTodoOrderGenTypes.FAILURE],
  },
});

export const PrefetchTodoNotInFulfilmentWindowOrderGenTypes = createAction(
  'OrderGen Instance',
  'Prefetch todo not in fulfilment window'
);

export const prefetchTodoNotInFulfillmentWindowOrderGenAction = () => ({
  [RSAA]: {
    endpoint: 'admin/ordergen/prefetch-todo-not-in-roaster-fulfilment',
    method: 'GET',
    types: [
      PrefetchTodoNotInFulfilmentWindowOrderGenTypes.REQUEST,
      PrefetchTodoNotInFulfilmentWindowOrderGenTypes.SUCCESS,
      PrefetchTodoNotInFulfilmentWindowOrderGenTypes.FAILURE,
    ],
  },
});

export const PrefetchStreamingOrderGenTypes = createAction('OrderGen Instance', 'Prefetch streaming');

export const prefetchStreamingOrderGenAction = params => ({
  [RSAA]: {
    endpoint: makeUrlWithQueryParams('admin/ordergen/prefetch-todo', { after11: true, ...params }),
    method: 'GET',
    types: [
      PrefetchStreamingOrderGenTypes.REQUEST,
      PrefetchStreamingOrderGenTypes.SUCCESS,
      PrefetchStreamingOrderGenTypes.FAILURE,
    ],
  },
});

export const PrefetchNotOnScaleOrderGenTypes = createAction('OrderGen Instance', 'Prefetch Not On Scale');

export const prefetchNotOnScaleOrderGenAction = params => ({
  [RSAA]: {
    endpoint: makeUrlWithQueryParams('admin/ordergen/prefetch-todo', { bagNotOnScale: true, ...params }),
    method: 'GET',
    types: [
      PrefetchNotOnScaleOrderGenTypes.REQUEST,
      PrefetchNotOnScaleOrderGenTypes.SUCCESS,
      PrefetchNotOnScaleOrderGenTypes.FAILURE,
    ],
  },
});

export const PrefetchAiDecisionOrderGenTypes = createAction('OrderGen Instance', 'Prefetch Ai Decision');

export const prefetchAiDecisionOrderGenAction = params => ({
  [RSAA]: {
    endpoint: makeUrlWithQueryParams('admin/ordergen/prefetch-todo', { lukeAI: true, ...params }),
    method: 'GET',
    types: [
      PrefetchAiDecisionOrderGenTypes.REQUEST,
      PrefetchAiDecisionOrderGenTypes.SUCCESS,
      PrefetchAiDecisionOrderGenTypes.FAILURE,
    ],
  },
});

export const PrefetchAG1OrderGenTypes = createAction('OrderGen Instance', 'Prefetch ag1');

export const prefetchAg1OrderGenAction = params => ({
  [RSAA]: {
    endpoint: makeUrlWithQueryParams('admin/ordergen/prefetch-todo', { ag1: true, ...params }),
    method: 'GET',
    types: [PrefetchAG1OrderGenTypes.REQUEST, PrefetchAG1OrderGenTypes.SUCCESS, PrefetchAG1OrderGenTypes.FAILURE],
  },
});

export const PrefetchSlowLocalOrderGenTypes = createAction('OrderGen Instance', 'Prefetch Slow Local');

export const prefetchSlowLocalOrderGenAction = () => ({
  [RSAA]: {
    endpoint: 'admin/ordergen/prefetch-slow-local',
    method: 'GET',
    types: [
      PrefetchSlowLocalOrderGenTypes.REQUEST,
      PrefetchSlowLocalOrderGenTypes.SUCCESS,
      PrefetchSlowLocalOrderGenTypes.FAILURE,
    ],
  },
});

export const PrefetchByCategoryOrderGenTypes = createAction('OrderGen Instance', 'Prefetch By Category');

export const prefetchCategoryOrderGenAction = category => ({
  [RSAA]: {
    endpoint: `admin/ordergen/prefetch/${category}`,
    method: 'GET',
    types: [
      PrefetchByCategoryOrderGenTypes.REQUEST,
      PrefetchByCategoryOrderGenTypes.SUCCESS,
      PrefetchByCategoryOrderGenTypes.FAILURE,
    ],
  },
});

export const ORDERGEN_PUSH = 'Ordergen/Push';

export const pushOrderGenAction = data => ({
  type: ORDERGEN_PUSH,
  payload: data,
});

export const ORDERGEN_UPDATE = 'Ordergen/Update';

export const updateOrderGenAction = (id, data) => ({
  type: ORDERGEN_UPDATE,
  payload: { id, data },
});

export const ORDERGEN_DELETE = 'Ordergen/Delete';

export const deleteOrderGenAction = id => ({
  type: ORDERGEN_DELETE,
  payload: { id },
});

export const ORDERGEN_ADD_NOTE = 'Ordergen/Add note';

export const addOrderGenNoteAction = (userId, note) => ({
  type: ORDERGEN_ADD_NOTE,
  payload: { userId, note },
});

export const ORDERGEN_DELETE_NOTE = 'Ordergen/Delete note';

export const deleteOrderGenNoteAction = (userId, noteId) => ({
  type: ORDERGEN_DELETE_NOTE,
  payload: { userId, noteId },
});

export const PrefetchNewUsersOrderGenTypes = createAction('OrderGen Instance', 'Prefetch new users');

export const prefetchNewUsersOrderGenAction = params => ({
  [RSAA]: {
    endpoint: makeUrlWithQueryParams('admin/ordergen/prefetch-todo/new-users', params),
    method: 'GET',
    types: [
      PrefetchNewUsersOrderGenTypes.REQUEST,
      PrefetchNewUsersOrderGenTypes.SUCCESS,
      PrefetchNewUsersOrderGenTypes.FAILURE,
    ],
  },
});

export const TriggerOrdergenGenerationTypes = createAction('OrderGen Instance', 'Trigger ordergen generation');

export const triggerOrdergenGenerationAction = data => ({
  [RSAA]: {
    endpoint: 'admin/ordergen/trigger-generation',
    method: 'POST',
    body: { source: window.location.href, ...data },
    types: [
      TriggerOrdergenGenerationTypes.REQUEST,
      TriggerOrdergenGenerationTypes.SUCCESS,
      TriggerOrdergenGenerationTypes.FAILURE,
    ],
  },
});

export const GetTriggerCountsTypes = createAction('OrderGen Instance', 'Get trigger counts');

export const getTriggerCountsAction = () => ({
  [RSAA]: {
    endpoint: 'admin/ordergen/trigger-counts',
    method: 'GET',
    types: [GetTriggerCountsTypes.REQUEST, GetTriggerCountsTypes.SUCCESS, GetTriggerCountsTypes.FAILURE],
  },
});

export const GetOrdergenHistoryTypes = createAction('OrderGen Instance', 'Get history');

export const getOrdergenHistoryAction = id => ({
  [RSAA]: {
    endpoint: `admin/ordergen/${id}/history`,
    method: 'GET',
    types: [GetOrdergenHistoryTypes.REQUEST, GetOrdergenHistoryTypes.SUCCESS, GetOrdergenHistoryTypes.FAILURE],
  },
});

export const TriggerOrdergenSmokeTestTypes = createAction('OrderGen Instance', 'Trigger ordergen smoke test');

export const triggerOrdergenSmokeTestAction = () => ({
  [RSAA]: {
    endpoint: 'admin/ordergen/smoke',
    method: 'POST',
    types: [
      TriggerOrdergenSmokeTestTypes.REQUEST,
      TriggerOrdergenSmokeTestTypes.SUCCESS,
      TriggerOrdergenSmokeTestTypes.FAILURE,
    ],
  },
});
