import { RSAA } from 'redux-api-middleware';
import qs from 'query-string';
import { createAction } from '@bottomless/common/store';

export const GetBatchShipmentsTypes = createAction('Admin Batch Shipments', 'Get all');

export const getBatchShipmentsAction = (vendorId, params) => ({
  [RSAA]: {
    endpoint: `admin/batch-shipments?vendor=${vendorId}${params ? `&${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetBatchShipmentsTypes.REQUEST, GetBatchShipmentsTypes.SUCCESS, GetBatchShipmentsTypes.FAILURE],
  },
});

export const VendorPayoutTypes = createAction('Admin Batch Shipments', 'Vendor payout');

export const vendorPayoutAction = data => ({
  [RSAA]: {
    endpoint: `admin/batch-shipments/vendor-payout`,
    method: 'POST',
    body: data,
    types: [VendorPayoutTypes.REQUEST, VendorPayoutTypes.SUCCESS, VendorPayoutTypes.FAILURE],
  },
});

export const UpdateCostsTypes = createAction('Admin Batch Shipments', 'Update costs');

export const updateCostsAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/batch-shipments/${id}/update-costs`,
    method: 'PATCH',
    body: data,
    types: [UpdateCostsTypes.REQUEST, UpdateCostsTypes.SUCCESS, UpdateCostsTypes.FAILURE],
  },
});

export const GenerateReceiptTypes = createAction('Admin Batch Shipments', 'Generate receipt');

export const generateReceiptAction = data => ({
  [RSAA]: {
    endpoint: `admin/batch-shipments/generate-receipt`,
    method: 'POST',
    body: data,
    types: [GenerateReceiptTypes.REQUEST, GenerateReceiptTypes.SUCCESS, GenerateReceiptTypes.FAILURE],
  },
});
