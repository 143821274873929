import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetScaleDeviceActionType = createAction('Admin scale devices', 'Get');

export const getScaleDeviceAction = () => ({
  [RSAA]: {
    endpoint: `admin/scale-device`,
    method: 'GET',
    types: [GetScaleDeviceActionType.REQUEST, GetScaleDeviceActionType.SUCCESS, GetScaleDeviceActionType.FAILURE],
  },
});
