import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader, Col, Row } from 'reactstrap';
import { ArrowDown, ArrowUp } from 'react-feather';
import { Form, Checkbox, Radio, DataLoading } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { getDynamicPricesAction } from '../../store/index.js';
import { useCallback } from 'react';
import { Rotation } from './Rotation.js';

import './RotationBuilderModal.scss';

const ROASTS = ['light', 'medium', 'dark'];
const PRICE_TYPES = ['basic', 'standard', 'premium'];
const TAGS = ['decaf', 'organic', 'cold_brew', 'espresso'];

const toPersonalized = values => {
  const toArray = values =>
    Object.entries(values)
      .filter(([, value]) => value)
      .reduce((all, [key]) => [...all, key], []);

  return {
    ...values,
    size: Number(values.size),
    roast: toArray(values.roast),
    tag: toArray(values.tag),
    origin: toArray(values.origin),
    vendor: toArray(values.vendor),
    single_origins: toArray(values.single_origins),
    tasting_note_category: toArray(values.tasting_note_category),
  };
};

const RotationBuilderModalComponent = ({
  isOpen,
  toggle,
  heading,
  getDynamicPrices,
  product,
  productOptions,
  variant,
  onSubmit,
  showRoasters,
  selectedPersonalized,
  allVendors,
}) => {
  const formRef = useRef();
  const [vendors, setVendors] = useState(null);
  const [isFetchingDynamicPrices, setFetchingDynamicPrices] = useState(false);
  const [currRotation, setCurrRotation] = useState({});
  const [areRoastersCollapsed, toggleRoasterCollapsed, setRoastersCollapsed] = useToggle(true);
  const [error, setError] = useState();
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    if (!selectedPersonalized?.vendor?.length) {
      setRoastersCollapsed(true);
    }
  }, [selectedPersonalized, setRoastersCollapsed]);

  const resetValues = useMemo(() => {
    return {
      roast: ROASTS.reduce(
        (all, curr) => ({
          ...all,
          [curr]: false,
        }),
        {}
      ),
      size: variant.size,
      price_type: PRICE_TYPES.find(row => product.rotation_price[row]),
      tag: TAGS.reduce(
        (all, curr) => ({
          ...all,
          [curr]: false,
        }),
        {}
      ),
      origin: {
        single: false,
        blend: false,
      },
      single_origins: productOptions.origins.reduce(
        (all, curr) => ({
          ...all,
          [curr._id]: false,
        }),
        {}
      ),
      tasting_note_category: productOptions.tastingNoteCategories.reduce(
        (all, curr) => ({
          ...all,
          [curr._id]: false,
        }),
        {}
      ),
      vendor: (vendors || []).reduce(
        (all, curr) => ({
          ...all,
          [curr._id]: !showRoasters ? product.vendor_id === curr._id : false,
        }),
        {}
      ),
    };
  }, [variant, productOptions, vendors, product, showRoasters]);

  const previousValues = useMemo(() => {
    if (!selectedPersonalized) {
      return;
    }
    return {
      roast: {
        ...ROASTS.reduce(
          (all, curr) => ({
            ...all,
            [curr]: false,
          }),
          {}
        ),
        ...selectedPersonalized.roast.reduce(
          (all, curr) => ({
            ...all,
            [curr]: true,
          }),
          {}
        ),
      },
      size: selectedPersonalized.size,
      price_type: selectedPersonalized.price_type,
      tag: {
        ...TAGS.reduce(
          (all, curr) => ({
            ...all,
            [curr]: false,
          }),
          {}
        ),
        ...selectedPersonalized.tag.reduce(
          (all, curr) => ({
            ...all,
            [curr]: true,
          }),
          {}
        ),
      },
      origin: {
        blend: false,
        single: false,
        ...selectedPersonalized.origin.reduce(
          (all, curr) => ({
            ...all,
            [curr]: true,
          }),
          {}
        ),
      },
      single_origins: {
        ...productOptions.origins.reduce(
          (all, curr) => ({
            ...all,
            [curr._id]: false,
          }),
          {}
        ),
        ...selectedPersonalized.single_origins.reduce(
          (all, curr) => ({
            ...all,
            [curr]: true,
          }),
          {}
        ),
      },
      tasting_note_category: {
        ...productOptions.tastingNoteCategories.reduce(
          (all, curr) => ({
            ...all,
            [curr._id]: false,
          }),
          {}
        ),
        ...selectedPersonalized.tasting_note_category.reduce(
          (all, curr) => ({
            ...all,
            [curr]: true,
          }),
          {}
        ),
      },
      vendor: {
        ...(vendors || []).reduce(
          (all, curr) => ({
            ...all,
            [curr._id]: false,
          }),
          {}
        ),
        ...selectedPersonalized.vendor.reduce(
          (all, curr) => ({
            ...all,
            [curr]: true,
          }),
          {}
        ),
      },
    };
  }, [productOptions, selectedPersonalized, vendors]);

  useEffect(() => {
    if (allVendors) {
      const cleanedVendors = Object.entries(
        allVendors.reduce(
          (all, { name, _id }) => ({
            ...all,
            [_id]: name,
          }),
          {}
        )
      ).map(([_id, name]) => ({ _id, name }));

      setVendors(cleanedVendors);
    }
  }, [allVendors]);

  const handlePersonalizedChange = useCallback(
    async values => {
      setFetchingDynamicPrices(true);
      try {
        const updatedPersonalized = toPersonalized(values);
        const { payload } = await getDynamicPrices({
          personalized: updatedPersonalized,
          currentProduct: product,
        });
        if (updatedPersonalized.vendor?.length) {
          setRoastersCollapsed(false);
        }
        setCurrRotation(payload.find(row => row.type === values.price_type) || payload[0] || {});
        setCanSubmit(true);
      } catch (err) {
        setError('Something went wrong, try again!');
      }
      setFetchingDynamicPrices(false);
    },
    [getDynamicPrices, product, setRoastersCollapsed]
  );

  const handleReset = useCallback(
    resetForm => {
      resetForm(resetValues);
      setCurrRotation({});
    },
    [resetValues]
  );

  const handleSubmit = useCallback(
    values => {
      const updatedPersonalized = toPersonalized(values);
      onSubmit(updatedPersonalized);
      toggle();
    },
    [onSubmit, toggle]
  );

  const handleOnChange = useCallback(values => {
    const updatedPersonalized = toPersonalized(values);
    if (!updatedPersonalized.roast.length) {
      setError('At least one roast is required');
      return;
    }
    if (!updatedPersonalized.price_type) {
      setError('Price level is required');
      return;
    }
    setError(null);
    setCanSubmit(false);
  }, []);

  return (
    <Modal className="rotation-builder" isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>{heading}</ModalHeader>
      <ModalBody>
        <DataLoading count={0} isLoading={!(product && allVendors)}>
          <Form
            innerRef={formRef}
            initialValues={previousValues || resetValues}
            onSubmit={() => {}}
            onChange={handleOnChange}
          >
            {({ values, resetForm, isSubmitting }) => (
              <Row>
                <Col className="summary" lg="4">
                  <Rotation
                    {...currRotation}
                    variant={variant}
                    isLoading={isFetchingDynamicPrices}
                    vendors={allVendors}
                    error={error}
                  />
                  <Row className="justify-content-center mt-2 mb-2">
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => handlePersonalizedChange(values)}
                      disabled={isSubmitting || Boolean(error) || isFetchingDynamicPrices}
                    >
                      Build Rotation
                    </Button>
                  </Row>
                  <Row className="justify-content-center mt-2 mb-2">
                    <Button
                      size="sm"
                      color="success"
                      disabled={!canSubmit || isSubmitting || Boolean(error) || isFetchingDynamicPrices}
                      onClick={() => handleSubmit(values)}
                    >
                      Done
                    </Button>
                  </Row>
                  <Row className="justify-content-center mt-5 mb-2">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleReset(resetForm)}
                      disabled={isSubmitting || isFetchingDynamicPrices}
                    >
                      Reset
                    </Button>
                  </Row>
                </Col>
                <Col className="text-capitalize" lg="8">
                  <Row>
                    <h6>Roasts</h6>
                  </Row>
                  <Row className="mb-3">
                    {ROASTS.map(roast => (
                      <Col key={roast} lg="3">
                        <Checkbox name={`roast.${roast}`} label={roast} />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <h6>Price</h6>
                  </Row>
                  <Row className="mb-3">
                    {PRICE_TYPES.map(type => (
                      <Col key={type} lg="3">
                        <Radio name="price_type" type="radio" label={type} value={type} />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <h6>Tags</h6>
                  </Row>
                  <Row className="mb-3">
                    {TAGS.map(tag => (
                      <Col key={tag} lg="3">
                        <Checkbox name={`tag.${tag}`} label={tag.replace('_', ' ')} />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <h6>Origins</h6>
                  </Row>
                  <Row className="mb-3">
                    <Col lg="3">
                      <Checkbox name="origin.blend" label="Blend" />
                    </Col>
                    <Col lg="3">
                      <Checkbox name="origin.single" label="Single Origin" />
                    </Col>
                  </Row>
                  {values.origin?.single && (
                    <Row>
                      <h6>Specific Origin</h6>
                    </Row>
                  )}
                  {values.origin?.single && (
                    <Row className="mb-3">
                      {productOptions.origins.map(origin => (
                        <Col lg="3" key={origin._id}>
                          <Checkbox name={`single_origins.${origin._id}`} label={origin.name} />
                        </Col>
                      ))}
                    </Row>
                  )}
                  {showRoasters && vendors && (
                    <Row onClick={toggleRoasterCollapsed} className="roasters">
                      <h6>Roasters</h6>
                      {areRoastersCollapsed ? <ArrowDown /> : <ArrowUp />}
                    </Row>
                  )}
                  {showRoasters && !areRoastersCollapsed && vendors && (
                    <Row className="mb-3">
                      {vendors.map(vendor => (
                        <Col key={vendor._id} lg="3">
                          <Checkbox name={`vendor.${vendor._id}`} label={vendor.name} />
                        </Col>
                      ))}
                    </Row>
                  )}
                  <Row>
                    <h6>Tasting Notes</h6>
                  </Row>
                  <Row className="mb-3">
                    {productOptions.tastingNoteCategories.map(note => (
                      <Col key={note._id} lg="3">
                        <Checkbox name={`tasting_note_category.${note._id}`} label={note.name} />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
          </Form>
        </DataLoading>
      </ModalBody>
    </Modal>
  );
};

RotationBuilderModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  vendors: PropTypes.array,
  getVendors: PropTypes.func.isRequired,
  getDynamicPrices: PropTypes.func.isRequired,
  variant: PropTypes.shape({ size: PropTypes.number.isRequired }).isRequired,
  product: PropTypes.shape({
    tasting_notes: PropTypes.array.isRequired,
    roast: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    rotation_price: PropTypes.object.isRequired,
    vendor_id: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
  }).isRequired,
  productOptions: PropTypes.shape({
    roasts: PropTypes.array.isRequired,
    origins: PropTypes.array.isRequired,
    tasting_notes_raw: PropTypes.array.isRequired,
    tastingNoteCategories: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  showRoasters: PropTypes.bool,
  selectedPersonalized: PropTypes.shape({
    origin: PropTypes.array.isRequired,
    price_type: PropTypes.string.isRequired,
    roast: PropTypes.array.isRequired,
    single_origins: PropTypes.array.isRequired,
    size: PropTypes.string.isRequired,
    tag: PropTypes.array.isRequired,
    tasting_note_category: PropTypes.array.isRequired,
    vendor: PropTypes.array.isRequired,
  }),
  allVendors: PropTypes.array.isRequired,
};

export const RotationBuilderModal = connect(null, dispatch => ({
  getDynamicPrices: data => dispatch(getDynamicPricesAction(data)),
}))(RotationBuilderModalComponent);
