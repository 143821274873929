import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Field, Form } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  status: Yup.string(),
  vendor: Yup.string(),
});

const STATUSES = {
  '': 'Any',
  active: 'Active',
  archived: 'Archived',
};

export const ProductFilter = ({ onSubmit, onSuccess, vendors }) => {
  const vendorOptions = useMemo(
    () => (vendors || []).reduce((all, vendor) => ({ ...all, [vendor._id]: vendor.name }), { '': 'Any' }),
    [vendors]
  );

  const submit = useCallback(submitForm => () => setTimeout(submitForm), []);

  return (
    <Form
      initialValues={{ status: '', vendor: '' }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      inline
    >
      {({ submitForm }) => (
        <>
          <div className="mr-3">
            <Field name="status" type="select" label="Status" options={STATUSES} onChange={submit(submitForm)} />
          </div>
          <Field name="vendor" type="select" label="Vendor" options={vendorOptions} onChange={submit(submitForm)} />
        </>
      )}
    </Form>
  );
};

ProductFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};
