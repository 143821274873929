import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './RotationSummary.css';
import { ProductName } from '@bottomless/common/components';

export const RotationSummary = ({ personalized, productOptions }) => {
  const personalizedPopulated = useMemo(
    () => ({
      ...personalized,
      single_origins: personalized.single_origins.map(row => ({
        _id: row,
        name: productOptions?.origins?.find(origin => origin._id === row)?.name,
      })),
      tasting_note_category: personalized.tasting_note_category.map(row => ({
        _id: row,
        name: productOptions?.tastingNoteCategories?.find(note => note._id === row)?.name,
      })),
    }),
    [personalized, productOptions]
  );
  return (
    <div className="rotation-summary" lg="4">
      <ProductName personalized={personalizedPopulated} product={{ rotating: true }} />
    </div>
  );
};

RotationSummary.propTypes = {
  personalized: PropTypes.object,
  productOptions: ProductName.object,
};
