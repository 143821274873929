import PropTypes from 'prop-types';
import React from 'react';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

export const TemplateCard = ({ template }) => {
  return (
    <Card className="mb-3 flex-row align-items-stretch">
      <CardBody>
        <Row>
          <Col xs="3" md="10">
            <span className="d-block text-large">{template.name}</span>
            <div className="text-secondary">
              <div>Title: {template.title}</div>
              <div>From: {template.from}</div>
            </div>
          </Col>
          <Col xs="12" md="2">
            <Link
              to={`/admin/emails/${template._id}`}
              className={'btn btn-info d-flex btn-sm justify-content-center align-items-center mb-3'}
            >
              <Edit size="12" className="mr-2" />
              <span>Edit</span>
            </Link>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

TemplateCard.propTypes = {
  template: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    from: PropTypes.string,
    created_at: PropTypes.string,
  }),
};
