import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  date_arrived: Yup.date().required(),
});

export const DeliveredForm = ({ order, onSubmit, onSubmitSuccess }) => {
  return (
    <Form
      initialValues={{
        date_arrived: order.date_arrived ? utcDate(order.date_arrived) : undefined,
      }}
      validationSchema={Schema}
      onSubmit={data => onSubmit({ ...data, shipping_status: 'delivered' })}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field
            type="date"
            name="date_arrived"
            label="Date arrived"
            min={new Date(order.date_fulfilled ? order.date_fulfilled : Date.now())}
            max={new Date(Date.now() + 30 * 86400000)}
            showTime={true}
            format="MM/DD/YYYY h:mm a"
          />

          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

DeliveredForm.propTypes = {
  order: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
