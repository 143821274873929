import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const VendorFulfillmentDays = ({ fulfillment_timelines, className }) => {
  const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const today = new Date().getDay();

  return (
    <div className={`d-flex ${className ? className : 'justify-content-center mt-1'}`}>
      {week.map((weekDay, i) => (
        <div
          className={classNames({
            'mx-1': true,
            'px-1': true,
            'text-link': !fulfillment_timelines[weekDay],
            'text-success': fulfillment_timelines[weekDay],
            'font-weight-bold': fulfillment_timelines[weekDay],
            border: i === today,
          })}
          key={weekDay}
        >
          {weekDay[0].toUpperCase()}
        </div>
      ))}
    </div>
  );
};

VendorFulfillmentDays.propTypes = {
  className: PropTypes.string,
  fulfillment_timelines: PropTypes.shape({
    sunday: PropTypes.bool.isRequired,
    monday: PropTypes.bool.isRequired,
    tuesday: PropTypes.bool.isRequired,
    wednesday: PropTypes.bool.isRequired,
    thursday: PropTypes.bool.isRequired,
    friday: PropTypes.bool.isRequired,
    saturday: PropTypes.bool.isRequired,
  }).isRequired,
};
