import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';
import qs from 'query-string';

export const GetProposedProductsTypes = createAction('Proposed products', 'Get all');

export const getProposedProductsAction = params => ({
  [RSAA]: {
    endpoint: `admin/proposed_products${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetProposedProductsTypes.REQUEST, GetProposedProductsTypes.SUCCESS, GetProposedProductsTypes.FAILURE],
  },
});

export const GetIgnoredProposedProductsTypes = createAction('Ignored products', 'Get all');

export const getIgnoredProposedProductsAction = params => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/ignored${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetProposedProductsTypes.REQUEST, GetProposedProductsTypes.SUCCESS, GetProposedProductsTypes.FAILURE],
  },
});

export const GetPendingProposedProductsTypes = createAction('Proposed products', 'Get pending');

export const getPendingProposedProductsAction = () => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/pending`,
    method: 'GET',
    types: [
      GetPendingProposedProductsTypes.REQUEST,
      GetPendingProposedProductsTypes.SUCCESS,
      GetPendingProposedProductsTypes.FAILURE,
    ],
  },
});

export const IgnoreProposedProductTypes = createAction('Proposed products', 'Ignore');

export const ignoreProposedProductAction = id => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}/ignore`,
    method: 'PATCH',
    types: [IgnoreProposedProductTypes.REQUEST, IgnoreProposedProductTypes.SUCCESS, IgnoreProposedProductTypes.FAILURE],
  },
});

export const UnlinkProposedProductTypes = createAction('Proposed products', 'Unlink');

export const unlinkProposedProductAction = id => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}/unlink`,
    method: 'PATCH',
    types: [UnlinkProposedProductTypes.REQUEST, UnlinkProposedProductTypes.SUCCESS, UnlinkProposedProductTypes.FAILURE],
  },
});

export const GetProposedProductTypes = createAction('Proposed products', 'Get');

export const getProposedProductAction = id => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}`,
    method: 'GET',
    types: [GetProposedProductTypes.REQUEST, GetProposedProductTypes.SUCCESS, GetProposedProductTypes.FAILURE],
  },
});

export const UpdateProposedProductTypes = createAction('Proposed products', 'Update');

export const updateProposedProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateProposedProductTypes.REQUEST, UpdateProposedProductTypes.SUCCESS, UpdateProposedProductTypes.FAILURE],
  },
});

export const GetProposedProductVendorsTypes = createAction('Proposed products', 'Get vendors');

export const getProposedProductVendorsAction = () => ({
  [RSAA]: {
    endpoint: `admin/vendors?scraper`,
    method: 'GET',
    types: [
      GetProposedProductVendorsTypes.REQUEST,
      GetProposedProductVendorsTypes.SUCCESS,
      GetProposedProductVendorsTypes.FAILURE,
    ],
  },
});

export const MapProposedProductTypes = createAction('Proposed products', 'Map to shop');

export const mapProposedProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}/map_to_shop`,
    method: 'PATCH',
    body: data,
    types: [MapProposedProductTypes.REQUEST, MapProposedProductTypes.SUCCESS, MapProposedProductTypes.FAILURE],
  },
});

export const ApplyProposedProductChangesTypes = createAction('Proposed products', 'Apply changes');

export const applyProposedProductChangesAction = id => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}/apply_changes`,
    method: 'PATCH',
    types: [
      ApplyProposedProductChangesTypes.REQUEST,
      ApplyProposedProductChangesTypes.SUCCESS,
      ApplyProposedProductChangesTypes.FAILURE,
    ],
  },
});

export const DeleteProposedProductVendorChangesTypes = createAction('Proposed products', 'Delete vendor changes');

export const deleteProposedProductVendorChangesAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}/delete_vendor_changes`,
    method: 'PATCH',
    body: data,
    types: [
      ApplyProposedProductChangesTypes.REQUEST,
      ApplyProposedProductChangesTypes.SUCCESS,
      ApplyProposedProductChangesTypes.FAILURE,
    ],
  },
});

export const IgnoreProposedProductVendorChangesTypes = createAction('Proposed products', 'Ignore vendor changes');

export const ignoreProposedProductVendorChangesAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}/ignore_vendor_changes`,
    method: 'PATCH',
    body: data,
    types: [
      ApplyProposedProductChangesTypes.REQUEST,
      ApplyProposedProductChangesTypes.SUCCESS,
      ApplyProposedProductChangesTypes.FAILURE,
    ],
  },
});

export const ProposedProductNotDeletedTypes = createAction('Proposed products', 'Not deleted');

export const proposedProductNotDeletedAction = id => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}/not-deleted`,
    method: 'PATCH',
    types: [
      ProposedProductNotDeletedTypes.REQUEST,
      ProposedProductNotDeletedTypes.SUCCESS,
      ProposedProductNotDeletedTypes.FAILURE,
    ],
  },
});

export const TriggerScraperTypes = createAction('Admin products', 'Trigger Populate Cache');

export const triggerScraperAction = data => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/scraper`,
    body: data,
    method: 'POST',
    types: [TriggerScraperTypes.REQUEST, TriggerScraperTypes.SUCCESS, TriggerScraperTypes.FAILURE],
  },
});

export const AcceptProposedProductVendorChangesTypes = createAction('Proposed products', 'Accept vendor changes');

export const acceptProposedProductVendorChangesAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/proposed_products/${id}/accept_vendor_changes`,
    method: 'PATCH',
    body: data,
    types: [
      ApplyProposedProductChangesTypes.REQUEST,
      ApplyProposedProductChangesTypes.SUCCESS,
      ApplyProposedProductChangesTypes.FAILURE,
    ],
  },
});
