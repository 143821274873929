import React from 'react';
import PropTypes from 'prop-types';
import { connectWithAbort } from '@bottomless/common/store';
import { createOrderGenAction, getIdleUsersOrderGenAction } from '../../../../store/admin/ordergen';
import { PaginatedOrderGenPage } from './PaginatedOrderGen';

const IdleUsersOrderGenPageComponent = ({ getIdleUsers, createOrderGen }) => (
  <PaginatedOrderGenPage
    title="Idle"
    getUsers={getIdleUsers}
    onGenerateInstance={createOrderGen}
    withTabs={false}
    hideOnGeneration={false}
  />
);

IdleUsersOrderGenPageComponent.propTypes = {
  createOrderGen: PropTypes.func.isRequired,
  getIdleUsers: PropTypes.func.isRequired,
};

export const IdleUsersOrderGenPage = connectWithAbort(null, dispatch => ({
  getIdleUsers: params => dispatch(getIdleUsersOrderGenAction(params)),
  createOrderGen: data => dispatch(createOrderGenAction(data)),
}))(IdleUsersOrderGenPageComponent);
