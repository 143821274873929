import PropTypes from 'prop-types';
import React from 'react';
import { connectWithAbort } from '@bottomless/common/store';
import { prefetchNotOnScaleOrderGenAction } from '../../../../store/admin/ordergen';
import { MainOrderGenPage } from './OrderGen';

const BagNotOnScaleGenComponent = props => <MainOrderGenPage {...props} triggerGenerationCreateOrder={false} />;

BagNotOnScaleGenComponent.propTypes = {
  prefetchUsers: PropTypes.func.isRequired,
};

export const BagNotOnScalePage = connectWithAbort(null, dispatch => ({
  prefetchUsers: params => dispatch(prefetchNotOnScaleOrderGenAction({ ...params, async: true })),
}))(BagNotOnScaleGenComponent);
