import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToastAction } from '@bottomless/common/store';
import { getSubproductFulfillment, cancelOrderAdminAction } from '../../../store/admin/order';
import { FulfillmentPage } from './Fulfillment';

const SubproductFulfillmentPageComponent = ({ getOrders, cancelOrder, addToast }) => (
  <FulfillmentPage getOrders={getOrders} cancelOrder={cancelOrder} isSubproductPage addToast={addToast} />
);

SubproductFulfillmentPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const SubproductFulfillmentPage = connect(null, dispatch => ({
  getOrders: () => dispatch(getSubproductFulfillment()),
  cancelOrder: id => dispatch(cancelOrderAdminAction(id)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(SubproductFulfillmentPageComponent);
