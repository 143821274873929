import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { Button } from 'reactstrap';

const Schema = Yup.object().shape({
  dumb_period: Yup.number().required(),
});

export const DumbPeriodForm = ({ user, onSubmit, onSubmitSuccess, updateDumbSubscription }) => {
  const form = useRef();

  return (
    <Form
      initialValues={{
        dumb_period: user.dumb_period,
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
      innerRef={form}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="dumb_period" label="Dumb Period" type="field" />
          {user.dumb_period && (
            <div>
              <Button
                color="danger"
                onClick={() => {
                  updateDumbSubscription(user.id, { toDel: true });
                  form.current && form.current.resetForm({ dumb_period: '' });
                }}
                size="sm"
                className="mb-2"
                isSubmitting={isSubmitting}
              >
                Remove Dumb Period from User
              </Button>
            </div>
          )}
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

DumbPeriodForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  updateDumbSubscription: PropTypes.func,
};
