import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetLikedVendorsTypes = createAction('Admin vendors', 'Get Liked');

export const getLikedVendorsAction = () => ({
  [RSAA]: {
    endpoint: `admin/vendors/liked`,
    method: 'GET',
    types: [GetLikedVendorsTypes.REQUEST, GetLikedVendorsTypes.SUCCESS, GetLikedVendorsTypes.FAILURE],
  },
});

export const GetVendorsTypes = createAction('Admin vendors', 'Get vendors');

export const getVendorsAction = params => ({
  [RSAA]: {
    endpoint: `admin/vendors${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetVendorsTypes.REQUEST, GetVendorsTypes.SUCCESS, GetVendorsTypes.FAILURE],
  },
});

export const UpdateOrdersTypes = createAction('Admin vendors', 'Update Tracking');
export const updateOrdersAction = () => ({
  [RSAA]: {
    endpoint: 'admin/vendors/update-tracking',
    method: 'GET',
    types: [UpdateOrdersTypes.REQUEST, UpdateOrdersTypes.SUCCESS, UpdateOrdersTypes.FAILURE],
  },
});

export const GetVendorTypes = createAction('Admin vendors', 'Get vendor');

export const getVendorAction = id => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}`,
    method: 'GET',
    types: [GetVendorTypes.REQUEST, GetVendorTypes.SUCCESS, GetVendorTypes.FAILURE],
  },
});

export const updateVendorTypes = createAction('Admin vendors', 'Update vendor');

export const updateVendorAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}`,
    method: 'PATCH',
    body: data,
    types: [updateVendorTypes.REQUEST, updateVendorTypes.SUCCESS, updateVendorTypes.FAILURE],
  },
});

export const addVendorPackagingTypes = createAction('Admin vendors', 'Add vendor packaging');

export const addVendorPackagingAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/packaging`,
    method: 'POST',
    body: data,
    types: [addVendorPackagingTypes.REQUEST, addVendorPackagingTypes.SUCCESS, addVendorPackagingTypes.FAILURE],
  },
});

export const createVendorTypes = createAction('Admin vendors', 'Create vendor');

export const createVendorAction = data => ({
  [RSAA]: {
    endpoint: `admin/vendors`,
    method: 'POST',
    body: data,
    types: [createVendorTypes.REQUEST, createVendorTypes.SUCCESS, createVendorTypes.FAILURE],
  },
});

export const createMagicLinkTypes = createAction('Admin vendors', 'Magic link');

export const createMagicLinkAction = id => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/magic-link`,
    method: 'POST',
    types: [createMagicLinkTypes.REQUEST, createMagicLinkTypes.SUCCESS, createMagicLinkTypes.FAILURE],
  },
});

export const getPackagingsTypes = createAction('Admin vendors', 'Get packagings');

export const getPackagingsAction = () => ({
  [RSAA]: {
    endpoint: `admin/vendors/packagings`,
    method: 'GET',
    types: [getPackagingsTypes.REQUEST, getPackagingsTypes.SUCCESS, getPackagingsTypes.FAILURE],
  },
});

export const GetPackagingSummaryTypes = createAction('Admin vendors', 'Get packaging summary');

export const getPackagingSummaryAction = () => ({
  [RSAA]: {
    endpoint: `admin/vendors/packaging-summary`,
    method: 'GET',
    types: [GetPackagingSummaryTypes.REQUEST, GetPackagingSummaryTypes.SUCCESS, GetPackagingSummaryTypes.FAILURE],
  },
});

export const GetUnscannedOrdersTypes = createAction('Admin vendors', 'Get unscanned');

export const getUnscannedOrdersAction = (id, params) => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/orders/unscanned${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetUnscannedOrdersTypes.REQUEST, GetUnscannedOrdersTypes.SUCCESS, GetUnscannedOrdersTypes.FAILURE],
  },
});

export const GetAllUnscannedOrdersTypes = createAction('Admin vendors', 'Get all unscanned');

export const getAllUnscannedOrdersAction = (status, params) => ({
  [RSAA]: {
    endpoint: `admin/vendors/orders/unscanned/${status}${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetAllUnscannedOrdersTypes.REQUEST, GetAllUnscannedOrdersTypes.SUCCESS, GetAllUnscannedOrdersTypes.FAILURE],
  },
});

export const SendVendorNotificationTypes = createAction('Admin vendors', 'Send notification');

export const sendVendorNotificationAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/notification`,
    method: 'POST',
    body: data,
    types: [
      SendVendorNotificationTypes.REQUEST,
      SendVendorNotificationTypes.SUCCESS,
      SendVendorNotificationTypes.FAILURE,
    ],
  },
});

export const GetPackagingCount = createAction('Admin vendors', 'Get Packaging');

export const getPackagingCountAction = id => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/packaging`,
    method: 'GET',
    types: [GetPackagingCount.REQUEST, GetPackagingCount.SUCCESS, GetPackagingCount.FAILURE],
  },
});

export const CreateStripeAccountType = createAction('Admin vendors', 'Create stripe account');

export const createStripeAccountAction = id => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/stripe-account`,
    method: 'PATCH',
    types: [CreateStripeAccountType.REQUEST, CreateStripeAccountType.SUCCESS, CreateStripeAccountType.FAILURE],
  },
});

export const ResendStripeInviteType = createAction('Admin vendors', 'Resend stripe invite');

export const resendStripeInviteAction = id => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/resend-stripe-invite`,
    method: 'PATCH',
    types: [ResendStripeInviteType.REQUEST, ResendStripeInviteType.SUCCESS, ResendStripeInviteType.FAILURE],
  },
});

export const GetLeadsTypes = createAction('Admin vendors', 'Get leads');

export const getLeadsAction = status => ({
  [RSAA]: {
    endpoint: `admin/vendors/leads/${status}`,
    method: 'GET',
    types: [GetLeadsTypes.REQUEST, GetLeadsTypes.SUCCESS, GetLeadsTypes.FAILURE],
  },
});

export const GetLeadTypes = createAction('Admin vendors', 'Get lead');

export const getLeadAction = id => ({
  [RSAA]: {
    endpoint: `admin/vendors/leads/lead/${id}`,
    method: 'GET',
    types: [GetLeadTypes.REQUEST, GetLeadTypes.SUCCESS, GetLeadTypes.FAILURE],
  },
});

export const UpdateLeadsTypes = createAction('Admin vendors', 'Update leads');

export const updateLeadsAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/vendors/leads/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateLeadsTypes.REQUEST, UpdateLeadsTypes.SUCCESS, UpdateLeadsTypes.FAILURE],
  },
});

export const CreateLeadsTypes = createAction('Admin vendors', 'Create leads');

export const createLeadsAction = data => ({
  [RSAA]: {
    endpoint: `admin/vendors/leads`,
    method: 'POST',
    body: data,
    types: [CreateLeadsTypes.REQUEST, CreateLeadsTypes.SUCCESS, CreateLeadsTypes.FAILURE],
  },
});

export const GetShipmentOptionsTypes = createAction('Vendor Portal', 'Get shipment options');

export const getShipmentOptionsAction = id => ({
  [RSAA]: {
    endpoint: `/admin/vendors/${id}/shipment-options`,
    method: 'GET',
    types: [GetShipmentOptionsTypes.REQUEST, GetShipmentOptionsTypes.SUCCESS, GetShipmentOptionsTypes.FAILURE],
  },
});

export const GetVendorsDownTimesTypes = createAction('Vendor Portal', 'Get vendors down times');

export const getVendorsDownTimeAction = status => ({
  [RSAA]: {
    endpoint: `/admin/vendors/down-days/${status}`,
    method: 'GET',
    types: [GetVendorsDownTimesTypes.REQUEST, GetVendorsDownTimesTypes.SUCCESS, GetVendorsDownTimesTypes.FAILURE],
  },
});

export const AddVendorDownTimeTypes = createAction('Admin vendors', 'Add vendor down time');

export const addVendorDownTimeAction = data => ({
  [RSAA]: {
    endpoint: `/admin/vendors/down-days`,
    method: 'POST',
    body: data,
    types: [AddVendorDownTimeTypes.REQUEST, AddVendorDownTimeTypes.SUCCESS, AddVendorDownTimeTypes.FAILURE],
  },
});

export const UpdateShipmentOptionsTypes = createAction('Vendor Portal', 'Update shipment options');

export const updateShipmentOptionsAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/admin/vendors/${id}/shipments`,
    method: 'PATCH',
    body: data,
    types: [UpdateShipmentOptionsTypes.REQUEST, UpdateShipmentOptionsTypes.SUCCESS, UpdateShipmentOptionsTypes.FAILURE],
  },
});

export const UpdateOnHandInventoryTypes = createAction('Admin vendors', 'Update on-hand inventory summary');

export const updateOnHandInventoryAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/packaging`,
    method: 'PATCH',
    body: data,
    types: [UpdateOnHandInventoryTypes.REQUEST, UpdateOnHandInventoryTypes.SUCCESS, UpdateOnHandInventoryTypes.FAILURE],
  },
});

export const UpdateInventoryRequestTypes = createAction('Admin vendors', 'Update inventory request');

export const updateInventoryRequestAction = data => ({
  [RSAA]: {
    endpoint: `admin/vendors/packaging-request`,
    method: 'PATCH',
    body: data,
    types: [
      UpdateInventoryRequestTypes.REQUEST,
      UpdateInventoryRequestTypes.SUCCESS,
      UpdateInventoryRequestTypes.FAILURE,
    ],
  },
});

export const BlacklistVendorTypes = createAction('Admin vendors', 'Blacklist vendor');

export const blacklistVendorAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/blacklist`,
    method: 'PATCH',
    body: data,
    types: [BlacklistVendorTypes.REQUEST, BlacklistVendorTypes.SUCCESS, BlacklistVendorTypes.FAILURE],
  },
});

export const createStripeMagicLinkTypes = createAction('Admin vendors', 'Create Stripe Magic link');

export const createStripeMagicLinkAction = id => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/stripe-magic-link`,
    method: 'POST',
    types: [createStripeMagicLinkTypes.REQUEST, createStripeMagicLinkTypes.SUCCESS, createStripeMagicLinkTypes.FAILURE],
  },
});

export const GetOrderNotesTypes = createAction('Admin vendors', 'Get order notes');

export const getOrderNotesAction = ids => ({
  [RSAA]: {
    endpoint: 'admin/vendors/orders-notes',
    method: 'POST',
    body: { ids },
    types: [GetOrderNotesTypes.REQUEST, GetOrderNotesTypes.SUCCESS, GetOrderNotesTypes.FAILURE],
  },
});

export const ToggleShopifyWidgetTypes = createAction('Admin vendors', 'Toggle Shopify Widget');

export const toggleShopifyWidgetAction = (id, action) => ({
  [RSAA]: {
    endpoint: `admin/vendors/${id}/widget/toggle/${action}`,
    method: 'POST',
    types: [BlacklistVendorTypes.REQUEST, BlacklistVendorTypes.SUCCESS, BlacklistVendorTypes.FAILURE],
  },
});
