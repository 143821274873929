import PropTypes from 'prop-types';
import React from 'react';
import { connectWithAbort } from '@bottomless/common/store';
import { prefetchTodoNotInFulfillmentWindowOrderGenAction } from '../../../../store/admin/ordergen';
import { MainOrderGenPage } from './OrderGen';

const RoasterDoesNotFulfillComponent = props => <MainOrderGenPage {...props} />;

RoasterDoesNotFulfillComponent.propTypes = {
  prefetchUsers: PropTypes.func.isRequired,
};

export const RoasterDoesNotFulfill = connectWithAbort(null, dispatch => ({
  prefetchUsers: () => dispatch(prefetchTodoNotInFulfillmentWindowOrderGenAction()),
}))(RoasterDoesNotFulfillComponent);
