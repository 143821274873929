import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetProductsTypes = createAction('Admin products', 'Get all');

export const getProductsAction = params => ({
  [RSAA]: {
    endpoint: `admin/products${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetProductsTypes.REQUEST, GetProductsTypes.SUCCESS, GetProductsTypes.FAILURE],
  },
});

export const GetProductTypes = createAction('Admin products', 'Get product');

export const getProductAction = id => ({
  [RSAA]: {
    endpoint: `admin/products/${id}`,
    method: 'GET',
    types: [GetProductTypes.REQUEST, GetProductTypes.SUCCESS, GetProductTypes.FAILURE],
  },
});

export const GetQuizAnswersTypes = createAction('Admin products', 'Get QuizAnswers');

export const getQuizAnswersAction = id => ({
  [RSAA]: {
    endpoint: `admin/products/get_quiz_answers/${id}`,
    method: 'GET',
    types: [GetQuizAnswersTypes.REQUEST, GetQuizAnswersTypes.SUCCESS, GetQuizAnswersTypes.FAILURE],
  },
});

export const CreateProductTypes = createAction('Admin products', 'Create product');

export const createProductAction = data => ({
  [RSAA]: {
    endpoint: `admin/products/`,
    method: 'POST',
    body: data,
    types: [CreateProductTypes.REQUEST, CreateProductTypes.SUCCESS, CreateProductTypes.FAILURE],
  },
});

export const CreateProductFromProposedTypes = createAction('Admin products', 'Create from proposed');

export const createProductFromProposedAction = proposedId => ({
  [RSAA]: {
    endpoint: `admin/products/from_proposed/${proposedId}`,
    method: 'POST',
    types: [
      CreateProductFromProposedTypes.REQUEST,
      CreateProductFromProposedTypes.SUCCESS,
      CreateProductFromProposedTypes.FAILURE,
    ],
  },
});

export const UpdateProductTypes = createAction('Admin products', 'Update');

export const updateProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/products/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateProductTypes.REQUEST, UpdateProductTypes.SUCCESS, UpdateProductTypes.FAILURE],
  },
});

export const ReplaceProductVariantTypes = createAction('Admin products', 'Replace Variant');

export const replaceProductVariantAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/products/${id}/replace-variant`,
    method: 'PATCH',
    body: data,
    types: [UpdateProductTypes.REQUEST, UpdateProductTypes.SUCCESS, UpdateProductTypes.FAILURE],
  },
});

export const GetLikedProductsTypes = createAction('Admin products', 'Get Liked');

export const getLikedProductsAction = params => ({
  [RSAA]: {
    endpoint: `admin/products/liked${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetLikedProductsTypes.REQUEST, GetLikedProductsTypes.SUCCESS, GetLikedProductsTypes.FAILURE],
  },
});

export const GetDisLikedProductsTypes = createAction('Admin products', 'Get Disliked');

export const getDisLikedProductsAction = params => ({
  [RSAA]: {
    endpoint: `admin/products/liked?dislike=true${params ? `&${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetDisLikedProductsTypes.REQUEST, GetDisLikedProductsTypes.SUCCESS, GetDisLikedProductsTypes.FAILURE],
  },
});

export const UpdateProductPricingTypes = createAction('Admin products', 'Update pricing');

export const updateProductPricingAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/products/${id}/pricing`,
    method: 'PATCH',
    body: data,
    types: [UpdateProductPricingTypes.REQUEST, UpdateProductPricingTypes.SUCCESS, UpdateProductPricingTypes.FAILURE],
  },
});

export const GetProductsPricingTypes = createAction('Admin products', 'Get products pricing');

export const getProductsPricingAction = params => ({
  [RSAA]: {
    endpoint: `admin/products/pricing${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetProductsPricingTypes.REQUEST, GetProductsPricingTypes.SUCCESS, GetProductsPricingTypes.FAILURE],
  },
});

export const GetProductStatsTypes = createAction('Admin products', 'Get products stats');

export const getProductStatsAction = (productId, variantId) => ({
  [RSAA]: {
    endpoint: `admin/products/${productId}/stats${variantId ? `?variant_id=${variantId}` : ''}`,
    method: 'GET',
    types: [GetProductStatsTypes.REQUEST, GetProductStatsTypes.SUCCESS, GetProductStatsTypes.FAILURE],
  },
});

export const UploadProductImageTypes = createAction('Admin products', 'Upload image');

export const uploadProductImageAction = data => ({
  [RSAA]: {
    endpoint: `admin/products/upload_image`,
    method: 'POST',
    headers: { upload: true },
    body: data,
    types: [UploadProductImageTypes.REQUEST, UploadProductImageTypes.SUCCESS, UploadProductImageTypes.FAILURE],
  },
});

export const TriggerPopulateCacheTypes = createAction('Admin products', 'Trigger Populate Cache');

export const triggerPopulateCacheAction = () => ({
  [RSAA]: {
    endpoint: `admin/products/populate_cache`,
    method: 'POST',
    types: [TriggerPopulateCacheTypes.REQUEST, TriggerPopulateCacheTypes.SUCCESS, TriggerPopulateCacheTypes.FAILURE],
  },
});

export const GetTaxCategoriesTypes = createAction('Admin products', 'Get tax categories');

export const getTaxCategoriesAction = () => ({
  [RSAA]: {
    endpoint: 'admin/products/tax-categories',
    method: 'GET',
    types: [GetTaxCategoriesTypes.REQUEST, GetTaxCategoriesTypes.SUCCESS, GetTaxCategoriesTypes.FAILURE],
  },
});

export const GetDynamicPrices = createAction('Products', 'Get dynamic prices');

export const getDynamicPricesAction = data => ({
  [RSAA]: {
    endpoint: 'admin/products/dynamic-pricing',
    method: 'POST',
    body: data,
    types: [GetDynamicPrices.REQUEST, GetDynamicPrices.SUCCESS, GetDynamicPrices.FAILURE],
  },
});
