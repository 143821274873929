import React from 'react';
import PropTypes from 'prop-types';
import { connectWithAbort } from '@bottomless/common/store';
import { createOrderGenAction, getIdleReviewUsersOrderGenAction } from '../../../../store/admin/ordergen';
import { PaginatedOrderGenPage } from './PaginatedOrderGen';

const IdleReviewUsersOrderGenPageComponent = ({ getIdleUsers, createOrderGen }) => (
  <PaginatedOrderGenPage
    title="Idle Review"
    getUsers={getIdleUsers}
    onGenerateInstance={createOrderGen}
    hideOnGeneration={false}
    withTabs={false}
  />
);

IdleReviewUsersOrderGenPageComponent.propTypes = {
  createOrderGen: PropTypes.func.isRequired,
  getIdleUsers: PropTypes.func.isRequired,
};

export const IdleReviewUsersOrderGenPage = connectWithAbort(null, dispatch => ({
  getIdleUsers: params => dispatch(getIdleReviewUsersOrderGenAction(params)),
  createOrderGen: data => dispatch(createOrderGenAction(data)),
}))(IdleReviewUsersOrderGenPageComponent);
