import { debounce } from 'lodash-es';
import { get } from 'lodash-es';
import { pickBy } from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, DataLoading, Pagination } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getRotationsAction } from '../../../../store/admin/rotation';
import { Rotation } from './componenets/Rotation';

const Schema = Yup.object().shape({
  search: Yup.string(),
});

const RotationsPageComponent = ({ getRotations, history, location: { pathname, search } }) => {
  const query = qs.parse(search);
  const formRef = useRef();

  const [rotations, setRotations] = useState(null);
  const [filters, setFilters] = useState(query);

  useDataEffect(getRotations, setRotations, filters);

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  useEffect(() => {
    history.push({ pathname, search: qs.stringify(pickBy(filters, v => v && v !== 'null')) });
  }, [filters, history, pathname]);

  const onClearClick = () => {
    setFilters({});
    formRef.current.resetForm({ search: '' });
  };

  const Filters = (
    <Form
      inline
      innerRef={formRef}
      initialValues={{ ...filters }}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {() => (
        <>
          <Row className="w-100 align-items-center">
            <Col className="pr-0">
              <Field
                className="w-100"
                name="search"
                label="Search"
                onChange={debounce(event => {
                  onFilterChange('search', event.target.value);
                }, 200)}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );

  return (
    <PanelPage title="Rotations">
      <Row className="mb-4">
        <Col xs="12">{Filters}</Col>
      </Row>
      <DataLoading count={(get(rotations, 'docs') || { length: 0 }).length} isLoading={rotations === null}>
        <div className="text-center">
          <span className="d-block mb-3">No data matching selected filters.</span>
          <Button size="sm" onClick={onClearClick}>
            Clear filters
          </Button>
        </div>
      </DataLoading>
      {rotations && (
        <>
          {rotations.docs.map(rotation => (
            <Rotation rotation={rotation} key={rotation._id} back={`admin/rotations`} />
          ))}
          <div className="mt-2">
            <Pagination collection={rotations} onPageChange={getRotations} setter={setRotations} />
          </div>
        </>
      )}
    </PanelPage>
  );
};

RotationsPageComponent.propTypes = {
  getRotations: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const RotationsPage = connect(null, (dispatch, { location: { search } }) => ({
  getRotations: () => dispatch(getRotationsAction(qs.parse(search))),
}))(RotationsPageComponent);
