import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  email: Yup.string().required(),
  paidUntil: Yup.date()
    .max(new Date('9999'), 'Future date not allowed')
    .optional(),
});

export const UpdateEmailForm = ({ user, onSubmit, onSubmitSuccess }) => {
  return (
    <Form
      initialValues={{
        email: user.local.email,
        base_id: user.base_id,
        paidUntil: user.paidUntil ? utcDate(user.paidUntil) : undefined,
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="email" label="New Email" type="text" />
          <Field name="paidUntil" label="Paid Until" type="date" format="MM/DD/YYYY" />

          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

UpdateEmailForm.propTypes = {
  user: PropTypes.shape({
    base_id: PropTypes.string.isRequired,
    paidUntil: PropTypes.instanceOf(Date),
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
