import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { DateFormat, Image } from '@bottomless/common/components';
import { PricingForm } from './Form';

export const Product = ({ product, onUpdate, onUpdateSuccess, getStats }) => {
  return (
    <Card className="mb-3 flex-row align-items-center admin-product-card">
      <CardBody>
        <Row>
          <div className="card-media">
            {product.small_image_src && (
              <Image src={product.small_image_src} alt={product.small_image_src} maxWidth="120" maxHeight="120" />
            )}
            {!product.small_image_src && product.image_src && (
              <Image src={product.image_src} alt={product.image_src} maxWidth="120" maxHeight="120" />
            )}
          </div>
          <div className="d-flex mb-3">
            <div className="mr-3">
              <span className="text-secondary text-sm d-block">#{product._id}</span>
              <span className="text-secondary text-sm d-block">{product.vendor_name}</span>
              <span>{product.name}</span>
            </div>
            <div className="mr-3">
              {product.status && (
                <div className="text-sm">
                  Status: <span className={`status--${product.status}`}> {product.status}</span>
                </div>
              )}
              <div className="text-sm">
                Hidden:{' '}
                <span className={`${product.hidden ? `text-danger` : 'text-success'}`}>
                  {product.hidden ? 'Yes' : 'No'}
                </span>
              </div>
            </div>
            <div className="mr-3">
              {product.date_added && (
                <span className="text-sm d-block">
                  Added: <DateFormat withTime date={product.date_added} />
                </span>
              )}
              {product.updated_at && (
                <span className="text-sm d-block">
                  Updated: <DateFormat withTime date={product.updated_at} />
                </span>
              )}
            </div>
          </div>
        </Row>
        <Row>
          <Col xs="12">
            <PricingForm
              product={product}
              onSubmit={data => onUpdate(product._id, data)}
              onSuccess={onUpdateSuccess}
              getStats={getStats}
            />
          </Col>
        </Row>{' '}
      </CardBody>
    </Card>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    image_src: PropTypes.string,
    small_image_src: PropTypes.string,
    status: PropTypes.string,
    active: PropTypes.bool,
    hidden: PropTypes.bool,
    date_added: PropTypes.string,
    updated_at: PropTypes.string,
    users: PropTypes.array,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  getStats: PropTypes.func.isRequired,
};
