import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, DataLoading } from '@bottomless/common/components';
import { Col, Row } from 'reactstrap';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../../layouts/PanelPage/PanelPage';
import { getCreditAction, updateCreditAction } from '../../../../../store/admin/user';
import { utcDate } from '@bottomless/common/utils';

const Sources = {
  null: 'Select Source',
  onboarding: 'onboarding',
  gift: 'gift',
  operations: 'operations',
  referral: 'referral',
  legacy: 'legacy',
  replacement: 'replacement',
};

const Statuses = {
  null: 'Select Status',
  granted: 'granted',
  redeemed: 'redeemed',
};

const Reasons = {
  null: 'Select Reason',
  'micro-influencer': 'micro-influencer',
};

const Schema = Yup.object().shape({
  source: Yup.string(),
  status: Yup.string(),
  reason: Yup.string(),
  redeemed_order: Yup.string(),
  redeemed_at: Yup.date(),
  shipping_price: Yup.string(),
});

export const CreditFormComponent = ({
  getCredit,
  updateCredit,
  addToast,
  match: {
    params: { id },
  },
}) => {
  const [credit, setCredit] = useState(null);
  const { isFetching } = useConditionalDataEffect(id, getCredit, setCredit, id, {}, [id]);

  const formRef = useRef();
  const handleSubmit = async data => {
    try {
      const { payload } = await updateCredit(id, data);
      setCredit(payload);
      addToast('Credit updated succesfully');
    } catch (error) {
      console.log(error);
      addToast('Unable to update credit');
    }
  };

  return (
    <PanelPage title={'Credits'}>
      {id && <DataLoading count={(credit && 1) || 0} isLoading={isFetching} />}
      {credit && credit.credit && (
        <Row className="justify-content-center mb-4">
          <Col xs="12" sm="10" md="8" className={'pb-4'}>
            <Form
              innerRef={formRef}
              initialValues={{
                ...credit.credit,
                redeemed_at: credit.credit?.redeemed_at ? utcDate(credit.credit?.redeemed_at) : undefined,
              }}
              validationSchema={Schema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <>
                  <div className="mb-3">
                    <span>{`User : ${credit.credit.user?._id}/ ${credit.credit.user?.first_name} ${credit.credit.user?.last_name}`}</span>
                  </div>
                  <Field type="select" name="source" label="Source" options={Sources} />
                  <Field type="select" name="status" label="Status" options={Statuses} />
                  <Field type="select" name="reason" label="Reason" options={Reasons} />
                  <Field name="redeemed_order" label="Redeemed Order" />
                  <Field type="date" name="redeemed_at" label="Redeemed At" format="MM/DD/YYYY" />
                  <Field name="shipping_price" label="Shipping Price" />

                  <div className="d-flex justify-content-between mt-3">
                    <SubmitButton color="dark" isSubmitting={isSubmitting}>
                      Save
                    </SubmitButton>
                  </div>
                </>
              )}
            </Form>
          </Col>
        </Row>
      )}
    </PanelPage>
  );
};

CreditFormComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  getCredit: PropTypes.func.isRequired,
  updateCredit: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const CreditForm = connect(null, dispatch => ({
  getCredit: id => dispatch(getCreditAction(id)),
  updateCredit: (id, data) => dispatch(updateCreditAction(id, data)),
  addToast: data => dispatch(addToastAction(data)),
}))(CreditFormComponent);
