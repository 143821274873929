import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AddDownDayForm } from './AddDownDayForm';

export const AddDownDayModal = ({ addModalOpen, toggleAddModal, vendors, addVendorDownTime }) => {
  return (
    <Modal isOpen={addModalOpen} size="lg">
      <ModalHeader toggle={() => toggleAddModal()}>Add Down Date</ModalHeader>
      <ModalBody>
        {vendors && <AddDownDayForm vendors={vendors} onSubmit={addVendorDownTime} toggleAddModal={toggleAddModal} />}
      </ModalBody>
    </Modal>
  );
};

AddDownDayModal.propTypes = {
  addModalOpen: PropTypes.bool,
  toggleAddModal: PropTypes.func,
  vendors: PropTypes.array,
  addVendorDownTime: PropTypes.func,
};
