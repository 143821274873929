import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { HeadingBack } from '../../../../components/HeadingBack/HeadingBack';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getCategoriesAction, getTaxCategoriesAction } from '../../../../store';
import { createProductAction, getProductAction, getProductsAction } from '../../../../store/admin/product';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import { getVendorsAction } from '../../../../store/admin/vendor';
import { ProductForm } from '../components/ProductForm';

const CreateProductPageComponent = ({
  getProductOptions,
  getProducts,
  getVendors,
  createProduct,
  addToast,
  history,
  getCategories,
  getTaxCategories,
}) => {
  const [shopProducts, setShopProducts] = useState([]);

  const Heading = HeadingBack({
    to: `/admin/products`,
  });

  useDataEffect(
    getProducts,
    response => {
      setShopProducts(get(response, 'docs', []));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  const { data: vendors } = useDataEffect(getVendors);
  const { data: productOptions } = useDataEffect(getProductOptions);
  const { data: categories } = useDataEffect(getCategories);
  const { data: taxCategories } = useDataEffect(getTaxCategories, undefined, undefined, null);

  const onSuccess = product => {
    addToast('Product has been successfully created');
    history.push(`/admin/products/${product._id}`);
  };

  const onSubmit = data => createProduct(data);

  return (
    <PanelPage title="Create product" heading={Heading}>
      <Row>
        <Col xs="12">
          {productOptions && taxCategories && (
            <ProductForm
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              productOptions={productOptions}
              shopProducts={shopProducts}
              vendors={vendors || []}
              categories={categories || []}
              taxCategories={taxCategories}
            />
          )}
        </Col>
      </Row>
    </PanelPage>
  );
};

CreateProductPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getVendors: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getTaxCategories: PropTypes.func.isRequired,
};

export const CreateProductPage = connect(null, dispatch => ({
  addToast: message => dispatch(addToastAction(message)),
  getProduct: id => dispatch(getProductAction(id)),
  createProduct: data => dispatch(createProductAction(data)),
  getProductOptions: () => dispatch(getProductOptionsAction()),
  getProducts: params => dispatch(getProductsAction(params)),
  getVendors: () => dispatch(getVendorsAction()),
  getCategories: () => dispatch(getCategoriesAction()),
  getTaxCategories: () => dispatch(getTaxCategoriesAction()),
}))(CreateProductPageComponent);
