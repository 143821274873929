import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { VendorFulfillmentDays } from '../../../../components/VendorFulfillmentDays/VendorFulfillmentDays';
import { VendorSelect } from './VendorSelect';

export const Heading = ({ vendor, vendors, isAll }) => (
  <Row className="justify-content-between w-100 pt-2">
    {vendor && vendor._id && (
      <>
        <Col xs="4">
          <h4>
            <Link className="text-primary" to={`/admin/vendors/${vendor._id}?back=/admin/order_tracking/${vendor._id}`}>
              {vendor.name}
            </Link>
          </h4>
          <a
            href={`https://app.frontapp.com/compose?mailto=mailto:${vendor.ordering_email}`}
            rel="noopener noreferrer"
            target="_blank"
            className="text-sm"
          >
            {vendor.ordering_email}
          </a>
        </Col>
        <Col xs="4">
          <div className="d-flex flex-column justify-content-between align-items-center">
            {/* {vendor && !isAll && <PackagingCount vendor={vendor} />} */}
            {vendor.fulfillment_timelines && (
              <VendorFulfillmentDays
                fulfillment_timelines={vendor.fulfillment_timelines}
                className="justify-content-start"
              />
            )}
          </div>
        </Col>
        {vendors && (
          <Col xs="4" className="d-flex justify-content-end">
            <VendorSelect vendors={vendors} vendor={vendor} />
          </Col>
        )}
      </>
    )}
  </Row>
);

Heading.propTypes = {
  vendor: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    ordering_email: PropTypes.string,
    fulfillment_timelines: PropTypes.object,
  }),
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      ordering_email: PropTypes.string,
      fulfillment_timelines: PropTypes.object,
    })
  ),
  isAll: PropTypes.bool,
};
