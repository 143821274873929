import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import classNames from 'classnames';

export const Vendor = ({ vendor, sort }) => {
  const image = !vendor.img_url
    ? null
    : vendor.img_url.match(/^http/)
    ? vendor.img_url
    : process.env.REACT_APP_BACKEND_URL + vendor.img_url;

  return (
    <Card className="mb-3 flex-row align-items-stretch">
      <div
        className="card-media card-media-contain"
        style={{ backgroundImage: `url(${image || '/images/no-image.png'})` }}
      />
      <div className="d-flex justify-content-between align-items-center card-body">
        <div>
          <div>{vendor.name}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className={classNames('text-secondary', { 'text-danger': sort === 'like' })}>
            <i className="fa fa-heart" />
            <span className="ml-2">{vendor.like}</span>
          </div>
          <div className="mx-2" />
          <div className={classNames('text-secondary', { 'text-danger': sort === 'dislike' })}>
            <i className="fa fa-ban" />
            <span className="ml-2">{vendor.dislike}</span>
          </div>
          <div className="mx-2" />
          <div className={classNames('text-secondary', { 'text-danger': sort === 'ratio' })}>
            <i className="fa fa-calculator" />
            <span className="ml-2">{Number(vendor.ratio.toFixed(2))}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

Vendor.propTypes = {
  sort: PropTypes.string,
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    img_url: PropTypes.string.isRequired,
    like: PropTypes.number.isRequired,
    dislike: PropTypes.number.isRequired,
    ratio: PropTypes.number.isRequired,
  }),
};
