import classNames from 'classnames';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Edit } from 'react-feather';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';
import { DataLoading, DateFormat, Image } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getVendorsAction, updateOrdersAction } from '../../../store/admin/vendor';
import LazyLoad from 'react-lazyload';
import { Filters } from './components/Filters';
import { VendorFulfillmentDays } from '../../../components/VendorFulfillmentDays/VendorFulfillmentDays';

const VendorCard = ({ vendor }) => {
  return (
    <Card className="mb-3 flex-row align-items-stretch">
      <CardBody>
        <Row>
          <Col xs="3" md="2" className="d-flex justify-content-center align-items-center">
            {vendor.img_url && <Image src={vendor.img_url} alt={vendor.name} maxHeight="115" width="115" />}
          </Col>
          <Col xs="9" md="8" className="text-sm">
            <div className="text-medium mb-2 d-flex align-items-center">
              <strong className="mr-2">{vendor.name}</strong>
              <Badge
                color={classNames({
                  success: vendor.status === 'active',
                  info: vendor.status === 'onboarding',
                  muted: vendor.status === 'inactive',
                })}
              >
                {vendor.status}
              </Badge>
            </div>
            <p className="mb-0">Website: {vendor.website}</p>
            <p className="mb-0">Ordering email: {vendor.ordering_email}</p>
            {vendor.landing_page && (
              <p className="mb-0">
                Landing page:{' '}
                <Link to={vendor.landing_page} target="_blank" className="text-primary">
                  {vendor.landing_page}
                </Link>
              </p>
            )}

            {vendor.created_at && (
              <div className="mt-2 d-flex flex-row  text-sm text-secondary">
                <div className="mr-1">
                  <span className="mr-1">Created:</span>
                  <DateFormat date={vendor.created_at} withTime={true} />
                </div>
                <div>
                  <span className="mr-1">Updated:</span>
                  <DateFormat date={vendor.updated_at} withTime={true} />
                </div>
              </div>
            )}
            <div className="d-flex flex-column justify-content-start align-items-start mt-1">
              {vendor.fulfillment_timelines && (
                <VendorFulfillmentDays
                  fulfillment_timelines={vendor.fulfillment_timelines}
                  className="justify-content-start"
                />
              )}
            </div>
          </Col>

          <Col xs="12" md="2">
            <Link
              to={`/admin/vendors/${vendor._id}`}
              className={'btn btn-info d-flex btn-sm justify-content-center align-items-center mb-3'}
            >
              <Edit size="12" className="mr-2" />
              <span>Edit</span>
            </Link>

            <Button href={`/admin/order_tracking/${vendor._id}`} outline size="sm" block>
              Order tracking
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

VendorCard.propTypes = {
  vendor: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    img_url: PropTypes.string,
    ordering_email: PropTypes.string,
    website: PropTypes.string,
    landing_page: PropTypes.string,
    packagingCount: PropTypes.object,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    fulfillment_timelines: PropTypes.object,
  }),
};

export const VendorsPageComponent = ({ getVendors, updateTracking, addToast }) => {
  const location = useLocation();
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const [vendors, setVendors] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [disabled, toggleDisabled] = useState(false);

  useEffect(() => {
    (async () => {
      if (isLoading) {
        return;
      }

      setLoading(true);
      const { payload } = await getVendors();
      setVendors(payload);
      setLoading(false);
    })();
  }, [query]);

  const acknowledgeInTime = React.useCallback(async () => {
    toggleDisabled(true);
    try {
      await updateTracking();
      const { payload } = await getVendors();
      setVendors(payload);
      addToast(`Orders in time have been automatically acknowledged`, 'success');
    } catch (err) {
      addToast('Error updating the orders', 'error');
    } finally {
      toggleDisabled(false);
    }
  }, []);

  return (
    <>
      <PanelPage
        title="Vendors"
        heading={
          <>
            <Button className="mx-2" id="verify-button" disabled={disabled} onClick={acknowledgeInTime}>
              Acknowledge on time
            </Button>
            <Link to="/admin/vendors/create" className="btn btn-success mx-2">
              Add vendor
            </Link>
          </>
        }
        filters={<Filters />}
      >
        <div>
          <DataLoading count={vendors?.length || 0} isLoading={isLoading} />
          {vendors &&
            vendors.map(v => (
              <LazyLoad key={v._id} once height={300} offset={350} scrollContainer={'#panel-content'}>
                <VendorCard vendor={v} />
              </LazyLoad>
            ))}
        </div>
      </PanelPage>
    </>
  );
};

VendorsPageComponent.propTypes = {
  getVendors: PropTypes.func.isRequired,
  updateTracking: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const VendorsPage = connect(null, (dispatch, { location: { search } }) => ({
  getVendors: () => dispatch(getVendorsAction({ status: 'active', ...qs.parse(search) })),
  updateTracking: () => dispatch(updateOrdersAction()),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(VendorsPageComponent);
