export const tabs = [
  { to: '/admin/order_tracking/unscanned/sent_to_roaster', name: 'Sent To Roaster' },
  { to: '/admin/order_tracking/unscanned/pre_transit', name: 'Pre Transit and Accepted' },
  { to: '/admin/order_tracking/unscanned/in_transit', name: 'In Transit' },
  { to: '/admin/order_tracking/unscanned/delivery', name: 'Out For Delivery and Delivered' },
  { to: '/admin/order_tracking/unscanned/alert', name: 'Alert' },
  { to: '/admin/order_tracking/unscanned/return_to_sender', name: 'Return To Sender' },
  { to: '/admin/order_tracking/unscanned/rest', name: 'Rest' },
  { to: '/admin/order_tracking/unscanned/v2', name: 'v2' },
  { to: '/admin/order_tracking/unscanned/support', name: 'Support' },
];

export const superTabs = [
  { to: '/admin/order_tracking/unscanned/tracking_pre_transit', name: 'pre_transit' },
  { to: '/admin/order_tracking/unscanned/tracking_pre_transit_1', name: 'pre_transit_1' },
  { to: '/admin/order_tracking/unscanned/tracking_pre_transit_2', name: 'pre_transit_2' },
  { to: '/admin/order_tracking/unscanned/tracking_accepted', name: 'accepted' },
  { to: '/admin/order_tracking/unscanned/tracking_accepted_1', name: 'accepted_1' },
  { to: '/admin/order_tracking/unscanned/tracking_accepted_2', name: 'accepted_2' },
  { to: '/admin/order_tracking/unscanned/tracking_in_transit', name: 'in_transit' },
  { to: '/admin/order_tracking/unscanned/tracking_in_transit_1', name: 'in_transit_1' },
  { to: '/admin/order_tracking/unscanned/tracking_in_transit_2', name: 'in_transit_2' },
  { to: '/admin/order_tracking/unscanned/tracking_in_transit_exceptions', name: 'in_transit_exceptions' },
  { to: '/admin/order_tracking/unscanned/tracking_out_for_delivery', name: 'out_for_delivery' },
  { to: '/admin/order_tracking/unscanned/tracking_out_for_delivery_1', name: 'out_for_delivery_1' },
  { to: '/admin/order_tracking/unscanned/tracking_out_for_delivery_2', name: 'out_for_delivery_2' },
  { to: '/admin/order_tracking/unscanned/tracking_alert', name: 'alert' },
  { to: '/admin/order_tracking/unscanned/tracking_alert_1', name: 'alert_1' },
  { to: '/admin/order_tracking/unscanned/tracking_return_to_sender', name: 'return_to_sender' },
  { to: '/admin/order_tracking/unscanned/tracking_replacement', name: 'replacement' },
];
