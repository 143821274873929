import { GiftStatuses } from '@bottomless/common/constants';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'react-feather';
import { Button } from 'reactstrap';
import { Confirmation } from '../../../../components/Confirmation/Confirmation';

export const Actions = ({ giftPurchase, cancelGift }) => {
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState();
  const emitter = useMemo(() => new EventTarget(), []);

  const onCancel = useCallback(
    () =>
      new Promise((promiseResolve, reject) => {
        setError(undefined);
        const resolve = async () => {
          try {
            setLoading(true);
            const result = await cancelGift(giftPurchase._id);
            setLoading(false);
            return result;
          } catch (e) {
            setLoading(false);
            setError(e.message);
          }
        };

        const event = new Event('open');
        event.promise = { resolve, reject };
        emitter.dispatchEvent(event);
      }),
    [emitter, cancelGift, giftPurchase, setError]
  );

  return (
    <>
      {giftPurchase.status !== GiftStatuses.Cancelled && (
        <Button color="danger" onClick={onCancel} disabled={isLoading}>
          {isLoading && (
            <>
              Saving...
              <Loader size="13" className="spin ml-2" />
            </>
          )}
          {!isLoading && <>Cancel & Refund</>}
        </Button>
      )}
      {error && <div className="text-sm text-danger mt-3">{error}</div>}
      <Confirmation
        openEmitter={emitter}
        text={
          <div className="text-center">
            <div>Are you sure you want to cancel this gift?</div>
            {giftPurchase.recipient.user_id && <div className="text-danger">This gift has been already redeemed.</div>}
          </div>
        }
      />
    </>
  );
};

Actions.propTypes = {
  giftPurchase: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    recipient: PropTypes.shape({
      user_id: PropTypes.string,
    }).isRequired,
  }),
  cancelGift: PropTypes.func.isRequired,
};
