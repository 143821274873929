export const modalTypes = {
  packaging: 'PACKAGING',
  shopify_configuration: 'SHOPIFY_CONFIGURATION',
};

export const paymentTypes = {
  amount_paid: 'Amount Paid',
  website_pricing: 'Website Pricing',
  wholesale: 'Wholesale',
  post_purchase_shopify: 'Post Purchase Shopify',
};

export const VendorPaymentTypes = {
  AMOUNT_PAID: 'amount_paid',
  WEBSITE_PRICING: 'website_pricing',
  WHOLESALE: 'wholesale',
  POST_PURCHASE_SHOPIFY: 'post_purchase_shopify',
};

export const DEFAULT_COMMISSION = 17;
export const DEFAULT_SHOPIFY_COMMISSION = 15;

export const STATUS_PAID = 'paid';

export const DISCOUNT_ROASTER_REPLACEMENT = 'roaster_replacement';

export const accountingStatuses = {
  INVITED: 'invited',
  PENDING: 'pending',
  ACTIVE: 'active',
};
