import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { useDataEffect, useQueryString } from '@bottomless/common/hooks';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getBatchShipmentsAction, vendorPayoutAction, updateCostsAction } from '../../../../store';
import { getVendorsAction } from '../../../../store/admin/vendor';
import { VendorFilter } from './components/VendorFilter';
import { BatchShipment } from './components/BatchShipment';
import { Accounting } from './components/Accounting';
import { Pay } from './components/Pay';

export const PayoutPageComponent = ({ getVendors, getBatchShipments, vendorPayout, addToast, updateCosts }) => {
  const { data: vendors, isFetching: areVendorsFetching } = useDataEffect(getVendors);
  const queryString = useQueryString();
  const { vendor } = useMemo(() => queryString.params, [queryString.params]);

  const getBatch = useCallback(() => (vendor ? getBatchShipments(vendor, { status: 'pending' }) : {}), [
    getBatchShipments,
    vendor,
  ]);
  const [batchShipments, setBatchShipments] = useState();
  const [checkedIds, setCheckedIds] = useState([]);

  const { vendor: batchShipmentsVendor } = batchShipments || {};

  const { isFetching } = useDataEffect(getBatch, setBatchShipments);

  useEffect(() => {
    if (!vendor && vendors) {
      queryString.update({ vendor: vendors[0]?._id });
    }
    setCheckedIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor, vendors, queryString.update]);

  const onUpdateField = useCallback(
    ({ batchId, orderId, data: { newPayout, newCommission } }) => {
      setBatchShipments(prevBatchShipments => {
        const batchShipmentData = [...prevBatchShipments.data];
        for (let shipmentIndex = 0; shipmentIndex < batchShipmentData.length; shipmentIndex++) {
          const batchShipment = { ...batchShipmentData[shipmentIndex] };
          if (batchShipment._id === batchId) {
            for (let orderIndex = 0; orderIndex < batchShipment.orders.length; orderIndex++) {
              const order = { ...batchShipment.orders[orderIndex] };
              if (order._id === orderId) {
                if (newPayout) {
                  order.vendor_payout = Number(newPayout) * 100;
                }
                if (newCommission) {
                  order.bl_commission = Number(newCommission) * 100;
                }
                batchShipment.orders[orderIndex] = order;
                batchShipmentData[shipmentIndex] = batchShipment;
                return { ...prevBatchShipments, data: batchShipmentData };
              }
            }
          }
        }
        return prevBatchShipments;
      });
    },
    [setBatchShipments]
  );

  const onCheck = useCallback(
    (batchId, check) => {
      setCheckedIds(previousCheckedIds => {
        if (check && !previousCheckedIds.includes(batchId)) {
          return [...previousCheckedIds, batchId];
        }
        if (!check && previousCheckedIds.includes(batchId)) {
          return previousCheckedIds.filter(id => id !== batchId);
        }
        return previousCheckedIds;
      });
    },
    [setCheckedIds]
  );

  const onPaySuccess = useCallback(
    ({ batchIds, payoutId, status, ...rest }) => {
      if (status === 404) {
        addToast(rest?.response?.message, 'error');
      } else {
        addToast('Vendor has been paid.');
        setBatchShipments(prevBatchShipments => {
          return {
            ...prevBatchShipments,
            data: prevBatchShipments.data.map(batch =>
              batchIds.includes(batch._id) ? { ...batch, payout_id: payoutId, status } : batch
            ),
          };
        });
        setCheckedIds([]);
      }
    },
    [addToast, setCheckedIds, setBatchShipments]
  );

  const onUpdateCostSuccess = useCallback(() => {
    addToast('Cost/Website Pricing have been updated.');
  }, [addToast]);

  return (
    <>
      <PanelPage
        title={`Vendors Payout${batchShipments ? ` - ${batchShipments.vendor.name}` : ''}`}
        heading={<VendorFilter vendors={vendors} onSubmit={queryString.update} defaultValue={vendor} />}
      >
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            {batchShipments && batchShipments.vendor.accounting && (
              <Accounting accounting={batchShipmentsVendor.accounting} />
            )}
          </div>
          <div>
            {vendor && batchShipments && (
              <Pay
                checkedIds={checkedIds}
                vendor={batchShipments.vendor}
                batchShipments={batchShipments.data}
                onSubmit={vendorPayout}
                onSuccess={onPaySuccess}
              />
            )}
          </div>
        </div>
        <DataLoading count={Number(batchShipments?.data.length)} isLoading={isFetching || areVendorsFetching} />
        {batchShipments &&
          batchShipments.data.map(batch => (
            <BatchShipment
              key={batch._id}
              batch={batch}
              accounting={batchShipments.vendor.accounting}
              onCheck={onCheck}
              onUpdateField={onUpdateField}
              onUpdateCostSuccess={onUpdateCostSuccess}
              updateCosts={updateCosts}
            />
          ))}
      </PanelPage>
    </>
  );
};

PayoutPageComponent.propTypes = {
  getVendors: PropTypes.func.isRequired,
  getBatchShipments: PropTypes.func.isRequired,
  vendorPayout: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  updateCosts: PropTypes.func.isRequired,
};

export const PayoutPage = connect(null, dispatch => ({
  getVendors: () => dispatch(getVendorsAction({ status: 'active' })),
  getBatchShipments: (vendorId, params) => dispatch(getBatchShipmentsAction(vendorId, params)),
  vendorPayout: data => dispatch(vendorPayoutAction(data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  updateCosts: (id, data) => dispatch(updateCostsAction(id, data)),
}))(PayoutPageComponent);
