import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { DateFormat } from '@bottomless/common/components';

const PackagingDetails = ({ packaging }) => {
  return (
    <Card body>
      <Row className="mb-3">
        <Col xs="12" md="4">
          <div className="text-secondary">#{packaging._id}</div>
          <div>
            <Link className="text-primary" to={`/admin/vendors/${packaging.vendor_id._id}`}>
              {packaging.vendor_id.name}
            </Link>
          </div>
        </Col>
        <Col xs="12" md="4">
          {packaging.shipment && (
            <>
              <div>Service: {packaging.shipment.service}</div>
              <div>
                Tracking:{' '}
                <a
                  href={packaging.shipment.tracker_url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-primary"
                >
                  {packaging.shipment.tracking_number}
                </a>
              </div>
            </>
          )}
        </Col>
        <Col xs="12" md="4">
          {packaging.amount && (
            <ul>
              {Object.keys(packaging.amount).map(size => (
                <li key={size}>
                  Size: {size}: {packaging.amount[size]}
                </li>
              ))}
            </ul>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div>{packaging.description}</div>
          <div className="mt-2">
            <DateFormat date={packaging.created_at} withTime />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

PackagingDetails.propTypes = {
  packaging: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    amount: PropTypes.object.isRequired,
    vendor_id: PropTypes.shape({
      name: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
    shipment: PropTypes.shape({
      service: PropTypes.string.isRequired,
      tracking_number: PropTypes.string.isRequired,
      tracker_url: PropTypes.string.isRequired,
    }),
    description: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
};

export const VendorPackagingDetails = memo(PackagingDetails);
