import { get, groupBy, has } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Loader } from 'react-feather';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Address } from '@bottomless/common/components';
import { useConditionalDataEffect, useInterval, useToggle } from '@bottomless/common/hooks';

const Batch = ({ batch, getBatch, createBatch, createScanForm }) => {
  const [batchDetails, setBatchDetails] = useState(null);
  const [requested, setRequested] = useState(false);
  const [shipments, setShipments] = useState([]);

  const id = batch.id;

  useEffect(() => {
    setShipments((batchDetails || batch).shipments);

    const state = get(batchDetails, 'state');
    if (state && state !== 'purchased') {
      setRequested(true);
      return;
    }

    const scanFormStatus = get(batchDetails, 'scan_form.status');

    if (scanFormStatus && !['created', 'failed'].includes(scanFormStatus)) {
      setRequested(true);
      return;
    }

    setRequested(false);
  }, [batch, batchDetails]);

  useConditionalDataEffect(id !== 'none', getBatch, setBatchDetails, id);

  const onBatch = async () => {
    try {
      const { payload } = await createBatch({ shipments: shipments.map(s => s._id) });
      setBatchDetails(payload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useInterval(
    async () => {
      try {
        if (batchDetails) {
          const { payload } = await getBatch(batchDetails.id);
          setBatchDetails(payload);
          setShipments(payload.shipments || []);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    requested ? 1000 : null
  );

  const onPrint = useCallback(() => {
    const filename = `${moment().format('m-d')}-labels-labelPrinter`;

    const labels = shipments.length > 0 ? `${shipments.map(({ _id }) => `shipments=${_id}`).join('&')}` : '';

    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/scale-shipments/labels/${filename}?${labels}&layout=fullPage`
    );
  }, [shipments]);

  const onBatchReady = () => window.open(get(batchDetails, 'scan_form.form_url'));

  const scanFormStatus = get(batchDetails, 'scan_form.status');
  const canCreateScanForm = get(batchDetails, 'state') === 'purchased' && !has(batchDetails, 'scan_form.form_url');

  const pending = useMemo(
    () => get(batchDetails, 'num_shipments', 0) - get(batchDetails, 'status.postage_purchased', 0),
    [batchDetails]
  );

  return (
    <Card className="mb-2">
      <CardHeader>
        {get(batchDetails, 'id', 'none')} ({get(batchDetails, 'num_shipments', 0)})
      </CardHeader>
      <CardBody>
        {requested && pending > 0 && (
          <div className="d-flex align-items-center justify-content-center mt-2">
            <div className="mb-2">
              <span>
                Creating Batch <Loader size="20" className="spin mr-2" />
              </span>
              Purchased {get(batchDetails, 'status.postage_purchased', 0)} of {get(batchDetails, 'num_shipments', 0)}
            </div>
          </div>
        )}
        {scanFormStatus === 'failed' && (
          <Alert color="danger">
            <div>{batchDetails.scan_form.message}</div>
            <Button onClick={onPrint} color="success">
              Labels
            </Button>
          </Alert>
        )}
        {scanFormStatus === 'created' && (
          <Alert color="success" className="mb-0">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Button onClick={onBatchReady} color="success" className="mr-2">
                Scan form
              </Button>
              <Button onClick={onPrint} color="success">
                Labels
              </Button>
            </div>
          </Alert>
        )}

        <Row className="justify-content-between">
          {shipments &&
            shipments.length > 0 &&
            shipments.map(shipment => (
              <Col xs="6" key={shipment._id} className="my-2">
                <div className="border py-2 mh-100 text-sm d-flex flex-row justify-content-center">
                  <div className="col-6 d-flex flex-column justify-content-center">
                    <span className="d-block">
                      {shipment.user_id.first_name} {shipment.user_id.last_name}
                    </span>
                    <span className="text-info">{get(shipment, 'payload.batch_status', '---')}</span>
                  </div>
                  <div className="col-6 d-flex flex-column justify-content-center">
                    <Address address={shipment.user_id.verifiedAddress} />
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </CardBody>
      {!batchDetails && (
        <CardFooter>
          <Button onClick={onBatch} color="success" disabled={requested}>
            Create batch
          </Button>
        </CardFooter>
      )}
      {canCreateScanForm && (
        <CardFooter>
          <Button
            onClick={() => {
              createScanForm(batchDetails.id);
              setRequested(true);
            }}
            color="success"
            disabled={requested}
          >
            Create scan form
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

Batch.propTypes = {
  getBatch: PropTypes.func.isRequired,
  createBatch: PropTypes.func.isRequired,
  createScanForm: PropTypes.func.isRequired,
  batch: PropTypes.shape({
    id: PropTypes.string.isRequired,
    shipments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }),
};

export const BatchModal = ({ opened, shipments, createBatch, getBatch, createScanForm, batchId, onClose }) => {
  const [isOpen, toggle] = useToggle(opened);

  useEffect(() => {
    if (opened) {
      toggle();
    }
  }, [opened]);

  const batches = useMemo(() => {
    const byBatch = groupBy(shipments, shipment => get(shipment, 'batch_id', 'none'));

    return Object.keys(byBatch).map(id => ({
      id,
      shipments: byBatch[id],
    }));
  }, [shipments]);

  const onToggle = () => {
    if (onClose) {
      onClose();
    }
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle} size="lg">
      {batchId && (
        <>
          <ModalHeader toggle={onToggle}>Batch details</ModalHeader>
          <ModalBody>
            <Batch
              getBatch={getBatch}
              createBatch={createBatch}
              createScanForm={createScanForm}
              batch={{ id: batchId, shipments: [] }}
            />
          </ModalBody>
        </>
      )}
      {batches && batches.length > 0 && (
        <>
          <ModalHeader toggle={onToggle}>Create batches</ModalHeader>
          <ModalBody>
            {batches.map(batch => (
              <Batch
                key={batch.id}
                batch={batch}
                getBatch={getBatch}
                createScanForm={createScanForm}
                createBatch={createBatch}
              />
            ))}
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

BatchModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  createBatch: PropTypes.func.isRequired,
  getBatch: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  createScanForm: PropTypes.func.isRequired,
  batchId: PropTypes.string,
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      batch_id: PropTypes.string,
    })
  ),
};
