import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Table, Input, FormGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Default } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import {
  parseInfluencersAction,
  attributeInfluencersAction,
  generateInfluencerLinkAction,
} from '../../../../store/admin/user';
import { InfluencerForm } from './components/InfluencerForm';
import { Attribute } from './components/Attribute';
import { SocialMediaForm } from './components/SocialMediaForm';

import './Influencers.scss';

const FORM_SOCIAL_MEDIA = 'social_media';

const InfluencersPageComponent = ({ parseInfluencers, attributeInfluencers, addToast, generateInfluencerLink }) => {
  const [isOpen, toggle] = useToggle();
  const [type, setType] = useState();
  const [influencers, setInfluencers] = useState([]);
  const [checked, setChecked] = useState([]);
  const [errors, setErrors] = useState([]);

  const openForm = type => {
    setType(type);
    toggle();
  };

  useEffect(() => setChecked(influencers.map((_, key) => key)), [influencers, setChecked]);

  const onChange = useCallback(
    e => {
      const { checked: check, value: rawValue } = e.target;
      const value = Number(rawValue);

      if (check && !checked.includes(value)) {
        setChecked([...checked, value]);
      }
      if (!check && checked.includes(value)) {
        setChecked(checked.filter(key => key !== value));
      }
    },
    [checked, setChecked]
  );

  const handleAttributeInfluencers = useCallback(
    data => {
      setErrors([]);
      return attributeInfluencers(data);
    },
    [attributeInfluencers, setErrors]
  );

  const onAttributeSuccess = useCallback(
    errors => {
      if (!errors.length) {
        addToast('All Influencers are attributed!');
      } else {
        addToast('Not all of the influencers have been attributed', 'error');
        setErrors(errors);
      }
    },
    [addToast, setErrors]
  );

  return (
    <PanelPage title="Influencers" className="page-admin-influencers">
      <div className="mb-4">
        <Button onClick={() => openForm(FORM_SOCIAL_MEDIA)} size="sm" color="info">
          Generete Influencer Link
        </Button>
      </div>
      <div className="mb-4 d-flex justify-content-between">
        <InfluencerForm onSubmit={parseInfluencers} onSuccess={setInfluencers} />
        <Attribute
          checked={checked}
          influencers={influencers}
          onSubmit={handleAttributeInfluencers}
          onSuccess={onAttributeSuccess}
        />
      </div>
      {errors.length > 0 &&
        errors.map((error, key) => (
          <div key={key} className="text-danger mb-2">
            {error.influencer.email}: {error.message}
          </div>
        ))}
      {influencers.length > 0 && (
        <Table size="sm">
          <thead>
            <tr>
              <th></th>
              <th>E-mail</th>
              <th>Name</th>
              <th>Attributed</th>
              <th>Months</th>
              <th>Credits</th>
              <th>Created_at</th>
              <th>Instagram</th>
              <th>Twitter</th>
              <th>Tiktok</th>
              <th>Youtube</th>
              <th>Facebook</th>
              <th>Linkedin</th>
              <th>User exists</th>
            </tr>
          </thead>
          <tbody>
            {influencers.map((influencer, key) => (
              <tr key={key}>
                <td>
                  <FormGroup className="influencer-check pt-2 pb-2" check>
                    <Input
                      name={`user-${key}`}
                      value={key}
                      type="checkbox"
                      onChange={onChange}
                      checked={checked.includes(key)}
                      disabled={!influencer.isValid}
                    />
                  </FormGroup>
                </td>
                <td>
                  <Default>{influencer.email}</Default>
                </td>
                <td>
                  <Default>{influencer.name}</Default>
                </td>
                <td>{influencer.attributed ? 'True' : 'False'}</td>
                <td>
                  <Default>{influencer.months}</Default>
                </td>
                <td>
                  <Default>{influencer.credits}</Default>
                </td>
                <td>
                  <Default>{influencer.created_at}</Default>
                </td>
                <td>
                  <Default>{influencer.instagram}</Default>
                </td>
                <td>
                  <Default>{influencer.twitter}</Default>
                </td>
                <td>
                  <Default>{influencer.tiktok}</Default>
                </td>
                <td>
                  <Default>{influencer.youtube}</Default>
                </td>
                <td>
                  <Default>{influencer.facebook}</Default>
                </td>
                <td>
                  <Default>{influencer.linkedin}</Default>
                </td>
                <td>{influencer.user ? '🟢' : '🔴'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {isOpen && (
        <Modal isOpen={isOpen}>
          {type === FORM_SOCIAL_MEDIA && (
            <>
              <ModalHeader toggle={toggle}>Influencer Sign Up Link</ModalHeader>
              <ModalBody>
                <SocialMediaForm
                  generateInfluencerLink={generateInfluencerLink}
                  onSubmitSuccess={() => {
                    addToast('Sign up link generated!', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}
        </Modal>
      )}
    </PanelPage>
  );
};

InfluencersPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  parseInfluencers: PropTypes.func.isRequired,
  attributeInfluencers: PropTypes.func.isRequired,
  generateInfluencerLink: PropTypes.func.isRequired,
};

export const InfluencersPage = connect(null, dispatch => ({
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  parseInfluencers: (message, type) => dispatch(parseInfluencersAction(message, type)),
  attributeInfluencers: (message, type) => dispatch(attributeInfluencersAction(message, type)),
  generateInfluencerLink: data => dispatch(generateInfluencerLinkAction(data)),
}))(InfluencersPageComponent);
