import { get, has } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, CreditCard, Edit, Package } from 'react-feather';
import {
  Form,
  Field,
  SubmitButton,
  CopyToClipboard,
  DateFormat,
  Price,
  VariantPrice,
  VariantAttributes,
} from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { getTrackingLink, getVariantSelectLabel } from '@bottomless/common/utils';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Alert,
} from 'reactstrap';
import * as Yup from 'yup';
import { ReplaceOrderForm } from '../../../../../components/Admin/Order';
import { EmailEditor } from '../../../../../components/EmailEditor/EmailEditor';
import { StaticTracking } from '../../../../../components/UspsTracking/StaticTracking';
import { EditOrderForm } from './EditOrderForm';
import { Link } from 'react-router-dom';
import { LineItems } from '../../List/components/LineItems';

const Schema = Yup.object().shape({
  product: Yup.string().required('This field is required'),
  variant: Yup.string().required('This field is required'),
});

const ChargeSchema = Yup.object().shape({
  subproduct_id: Yup.object()
    .shape({
      product: Yup.string().required('This field is required'),
      variant: Yup.string().required('This field is required'),
    })
    .required(),
  ordered_product_id: Yup.object()
    .shape({
      product: Yup.string().required('This field is required'),
      variant: Yup.string().required('This field is required'),
    })
    .required(),
});

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

const MODAL_CHARGE = 'CHARGE';
const MODAL_EDIT = 'EDIT';
const MODAL_FETCH_EASYPOST = 'EASYPOST';
const MODAL_REPLACE_SUBPRODUCT = 'REPLACE_SUBPRODUCT';
const MODAL_REPLACE = 'REPLACE';
const MODAL_EMAIL = 'EMAIL';

const ProductVariant = ({ productVariantSchema, user, quantity }) => {
  const variant = has(productVariantSchema, 'product.variants')
    ? productVariantSchema.product.variants.find(v => v._id === productVariantSchema.variant)
    : null;

  const vendorId = get(productVariantSchema, 'product.vendor_id');
  const vendorName = vendorId ? (
    <Link to={`/admin/order_tracking/${vendorId}`} target="_blank">
      {get(productVariantSchema, 'product.vendor_name', '---')}
    </Link>
  ) : (
    get(productVariantSchema, 'product.vendor_name', '---')
  );

  return (
    <>
      <div className="text-secondary text-sm">{vendorName}</div>
      {productVariantSchema?.product?._id ? (
        <Link to={`/admin/products?search=${productVariantSchema.product._id}`} target="_blank">
          {get(productVariantSchema, 'product.name', '---')}{' '}
          {get(productVariantSchema, 'product.name') && quantity && <span>(x{quantity})</span>}
        </Link>
      ) : (
        <div>
          {get(productVariantSchema, 'product.name', '---')}{' '}
          {get(productVariantSchema, 'product.name') && quantity && <span>(x{quantity})</span>}
        </div>
      )}
      <div className="text-primary text-sm">
        {variant && (
          <>
            {variant.size}oz - <VariantPrice user={user} variant={variant} />
          </>
        )}
      </div>
    </>
  );
};

ProductVariant.propTypes = {
  productVariantSchema: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      variants: PropTypes.array.isRequired,
    }).isRequired,
  }),
  user: PropTypes.object,
  quantity: PropTypes.number,
};

export const Order = ({
  order,
  user,
  chargeOrder,
  products,
  onUpdate,
  syncTracking,
  fetchOrderTracking,
  replaceProduct,
  onReplaceOrder,
  options,
  addToast,
  hideFooter,
}) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedSubproduct, setSubproduct] = useState();
  const [tracker, setTracker] = useState(null);
  const [isCollapsed, collapse] = useToggle(true);

  const product = get(order, 'product_id');

  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);

  const isFulfilledOrder = order && order.status === 'fulfilled';
  const address = isFulfilledOrder ? order.address : undefined;
  const isPickup = useMemo(() => (address ? address.pickup : user.verifiedAddress.pickup), [address, user]);

  const userProductShouldHaveGrind = useMemo(
    () => user.product.product?.category?.manifest?.attributes?.includes('grind'),
    [user]
  );

  const shopifyOrderLink = useMemo(() => {
    if (!order.external_id) {
      return;
    }
    const vendor = order.subvendor_id;
    if (!vendor?.scraper?.url) {
      return;
    }
    let baseUrl = vendor.scraper.url;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1);
    }
    return `${baseUrl}/admin/orders/${order.external_id.split('/').pop()}`;
  }, [order]);

  const toggle = type => {
    setType(type);
    setOpen(!isOpen);
  };

  const selectProduct = (id, type) => {
    const product = products.find(p => p._id === id);
    const setter = type === 'subproduct' ? setSubproduct : setSelectedProduct;

    setter(product);
  };

  const onFetch = async order => {
    const { payload } = await fetchOrderTracking(order._id);
    setTracker(payload);
    toggle(MODAL_FETCH_EASYPOST);
  };

  const markAsSupport = async (_id, needSupport) => {
    if (needSupport) {
      await onUpdate(_id, { need_support: false });
      addToast('Support resolved');
    } else {
      await onUpdate(_id, { need_support: true });
      addToast('Order marked as support');
    }
  };

  return (
    <div className="mb-4">
      <Card>
        <CardBody>
          <Row>
            <Col xs="6" md="3">
              <div>order_id: {order._id}</div>
              {shopifyOrderLink && (
                <div>
                  Shopify order:{' '}
                  <a href={shopifyOrderLink} rel="noopener noreferrer" target="_blank" className="text-primary">
                    {order.shopify_name}
                  </a>
                </div>
              )}
              <div>
                Status: <span className="text-capitalized text-info">{order.status}</span>
              </div>
              <div>
                Source: <span className="text-capitalized text-info">{order.source}</span>
              </div>
              <div>
                Label:{' '}
                {order.label_url && (
                  <a href={order.label_url} rel="noopener noreferrer" target="_blank" className="text-primary">
                    shipping label
                  </a>
                )}
                {order?.shipping_label?.label_url && (
                  <a
                    href={order.shipping_label.label_url}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary"
                  >
                    shipping label
                  </a>
                )}
              </div>
              <div>
                Tracking link:{' '}
                <a
                  href={getTrackingLink(order.shipping_service, order.tracking_number, order.tracking_url)}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-primary"
                >
                  {order.tracking_number}
                </a>
              </div>
              <div>Tracker: {order.easypost_id}</div>
              <div>
                Easypost link:{' '}
                <a href={order.tracking_url} rel="noopener noreferrer" target="_blank" className="text-primary">
                  {order.tracking_number}
                </a>
              </div>
              <div>
                Shipping status:{' '}
                <span className="text-capitalized text-info">
                  {order.shipping_status ? order.shipping_status : '---'}
                </span>
              </div>
              {isPickup && <div>Pickup at {address?.title || user.verifiedAddress.title}</div>}
              {!isPickup && address && (
                <div>
                  City: {address.city}, {address.state}, {address.zip}
                </div>
              )}
            </Col>
            <Col xs="6" md="3">
              <div>
                Date initiated:{' '}
                <span className="text-secondary">
                  <DateFormat date={order.date_initiated || order.created_at} />
                </span>
              </div>
              <div>
                Date Updated:{' '}
                <span className="text-secondary">
                  <DateFormat date={order.updated_at} withTime />
                </span>
              </div>
              <div>
                Override fulfillment:{' '}
                <span className="text-secondary">
                  {order.override_fulfillment_date ? (
                    <DateFormat date={order.override_fulfillment_date} correctTimezone={true} />
                  ) : (
                    '---'
                  )}
                </span>
              </div>
              <div>
                Date fulfilled:{' '}
                <span className="text-secondary">
                  <FulifillmentDate dateFulfilled={order.date_fulfilled} />
                </span>
              </div>
              <div>
                Vendor fulfillment times:{' '}
                <span className="text-secondary">
                  <VendorFulfillmentTime subvendor={order.subvendor_id} dateFulfilled={order.date_fulfilled} />
                </span>
              </div>
              <div>
                Date arrived :{' '}
                <span className="text-secondary">
                  <FulifillmentDate dateFulfilled={order.date_arrived} />
                </span>
              </div>
            </Col>
            <Col xs="6" md="2" className="text-center">
              <div className="mb-2">
                <strong>Product:</strong>
              </div>
              <ProductVariant productVariantSchema={order.product_id || order.product} user={user} />
              <div className="mt-2">
                <Badge className="text-wrap" color="success">
                  <VariantAttributes productVariant={order.product_id || order.product} grind={order.grind} hideSize />
                </Badge>
                {!order.grind?.name && userProductShouldHaveGrind && <Badge color="danger">Missing grind</Badge>}
              </div>
            </Col>
            <Col xs="6" md="2" className="text-center">
              <div className="mb-2">
                <strong>Subproduct:</strong>
              </div>
              <ProductVariant
                productVariantSchema={order.subproduct_id}
                user={user}
                quantity={order.quantity ? order.quantity : undefined}
              />
              {order.charge_subproduct && (
                <div className="font-weight-bolder mt-3 text-danger text-sm">Custom Rotation List</div>
              )}
              <LineItems user={{ ...order, product: order.subproduct_id }} />
            </Col>
            <Col xs="6" md="2" className="text-center">
              <div className="mb-2">
                <strong>Payment:</strong>
              </div>
              <div>Free bag: {order.free_bag ? 'Yes' : 'No'}</div>
              <div>
                {order.amount_paid !== undefined && (
                  <>
                    Amount paid: <Price value={order.amount_paid} cents />
                  </>
                )}
              </div>
              <div className="mb-1">
                Tax paid: <Price value={order.tax_paid || 0} cents />
              </div>

              {order.discountCode && (
                <div className="mb-2">
                  <div>Discount Code:</div>
                  <Badge color="primary">{order.discountCode.title}</Badge>
                </div>
              )}

              {has(order, 'ordered_product_id.product') && (
                <ProductVariant productVariantSchema={order.ordered_product_id} />
              )}
            </Col>
          </Row>
          {order.usps_tracking && (
            <Row>
              <Col>
                <div onClick={collapse} className="cursor-pointer">
                  <span className="mr-1">Tracking Details</span>
                  {isCollapsed ? <ChevronDown size="15" /> : <ChevronUp size="15" />}
                </div>
                <Collapse isOpen={!isCollapsed}>
                  <StaticTracking usps_tracking={order.usps_tracking} />
                </Collapse>
              </Col>
            </Row>
          )}
        </CardBody>

        {!hideFooter && (
          <CardFooter className="d-flex flex-row align-items-center">
            <Button
              className="d-flex align-items-center mr-2"
              size="sm"
              color="info"
              onClick={() => toggle(MODAL_EDIT)}
            >
              <span className="mr-2">Edit</span>
              <Edit size="13" />
            </Button>

            <Button
              className="d-flex align-items-center mr-2"
              size="sm"
              color="warning"
              onClick={() => toggle(MODAL_REPLACE_SUBPRODUCT)}
            >
              <span className="mr-2">Replace subproduct</span>
              <Edit size="13" />
            </Button>

            {order.status === 'fulfilled' ? (
              <Button
                className="d-flex align-items-center mr-2 disabled-btn"
                size="sm"
                color="warning"
                disabled={order.status === 'fulfilled'}
              >
                <span className="mr-2">Subproduct selection</span>
                <Edit size="13" />
              </Button>
            ) : (
              <Link
                to={`/admin/subproduct-selection/${order._id}`}
                className="btn btn-sm btn-warning align-items-center mr-2"
              >
                <span className="mr-2">Subproduct selection</span>
                <Edit size="13" />
              </Link>
            )}

            {order.tracking_number && !['delivered'].includes(order.shipping_status) && (
              <>
                <Button className="d-flex align-items-center mr-2" size="sm" onClick={() => syncTracking(order._id)}>
                  <span className="mr-2">Sync</span>
                  <Package size="13" />
                </Button>
                <Button className="d-flex align-items-center mr-2" size="sm" onClick={() => onFetch(order)}>
                  <span className="mr-2">Fetch</span>
                  <Package size="13" />
                </Button>
                <Button
                  onClick={() => markAsSupport(order._id, order.need_support)}
                  color="danger"
                  size="sm"
                  className="mr-2"
                >
                  {order.need_support ? 'Resolve' : 'Support'}
                </Button>
              </>
            )}
            {order.status === 'initiated' && !user.shopifySubscriptionContractId && (
              <Button
                className="d-flex align-items-center mr-2"
                size="sm"
                color="success"
                onClick={() => toggle(MODAL_CHARGE)}
              >
                <span className="mr-2">Charge order</span>
                <CreditCard size="13" />
              </Button>
            )}

            <Button
              className="d-flex align-items-center mr-2"
              size="sm"
              color="success"
              onClick={() => toggle(MODAL_EMAIL)}
            >
              <span className="mr-2">Send email</span>
              <Edit size="13" />
            </Button>

            <Button onClick={() => toggle(MODAL_REPLACE)} color="danger" size="sm" className="mr-1">
              Replace order
            </Button>
          </CardFooter>
        )}
      </Card>

      {isOpen && products && product && (
        <>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            size={[MODAL_FETCH_EASYPOST, MODAL_EMAIL, MODAL_REPLACE].includes(modalType) ? 'lg' : 'sm'}
          >
            {modalType === MODAL_REPLACE && (
              <>
                <ModalHeader toggle={() => toggle(MODAL_REPLACE)}>
                  <div>Order to be replaced</div>
                  <div className="text-sm text-secondary">
                    {order._id} <CopyToClipboard text={order._id} />
                  </div>
                </ModalHeader>
                <ModalBody>
                  <ReplaceOrderForm
                    products={products}
                    order={order}
                    onSubmit={data => onReplaceOrder(order._id, data)}
                    onSubmitSuccess={() => toggle()}
                  />
                </ModalBody>
              </>
            )}

            {modalType === MODAL_EMAIL && (
              <>
                <ModalHeader toggle={() => toggle(MODAL_EMAIL)}>Send notification</ModalHeader>
                <ModalBody>
                  <EmailEditor
                    isNotification={true}
                    context={{ orderId: order._id, userId: order.user_id._id }}
                    onSuccess={() => toggle()}
                  />
                </ModalBody>
              </>
            )}
            {modalType === MODAL_REPLACE_SUBPRODUCT && (
              <>
                <ModalHeader toggle={toggle}>Replace subproduct</ModalHeader>
                <ModalBody>
                  <Form
                    initialValues={{ product: null, variant: null }}
                    validationSchema={Schema}
                    onSubmit={data => replaceProduct(order._id, { subproduct_id: data })}
                    onSuccess={() => toggle()}
                  >
                    {({ isSubmitting }) => (
                      <>
                        {isFulfilledOrder ? (
                          <Alert
                            color="danger"
                            className="mb-3 border-1 p-1 text-sm"
                            style={{ width: '100%', maxWidth: '320px' }}
                          >
                            This order is already fulfilled. Replacing the subproduct will affect billing.
                          </Alert>
                        ) : (
                          undefined
                        )}
                        <Field
                          name="product"
                          type="select"
                          label="Add product"
                          options={createSelectOptions(
                            products,
                            '--- select product ---',
                            item => `${item.vendor_name} - ${item.name}`
                          )}
                          required
                          onChange={event => {
                            selectProduct(event.target.value);
                          }}
                        />
                        {selectedProduct && (
                          <Field
                            name="variant"
                            type="select"
                            label="Select variant"
                            options={createSelectOptions(
                              selectedProduct.variants.filter(v => v.available),
                              '--- select variant ---',
                              getVariantSelectLabel
                            )}
                            required
                          />
                        )}
                        <SubmitButton color="dark" isSubmitting={isSubmitting}>
                          Replace
                        </SubmitButton>
                      </>
                    )}
                  </Form>
                </ModalBody>
              </>
            )}

            {modalType === MODAL_EDIT && (
              <>
                <ModalHeader toggle={toggle}>Edit Order</ModalHeader>
                <ModalBody>
                  <EditOrderForm
                    onSubmit={data => onUpdate(order._id, data)}
                    order={order}
                    withGrind={userProductShouldHaveGrind}
                    options={options}
                    onSubmitSuccess={() => toggle()}
                  />
                </ModalBody>
              </>
            )}

            {modalType === MODAL_CHARGE && (
              <>
                <ModalHeader toggle={toggle}>Charge</ModalHeader>
                <ModalBody>
                  <Form
                    initialValues={{
                      ordered_product_id: { product: product.product._id, variant: product.variant },
                      subproduct_id: { product: null, variant: null },
                    }}
                    validationSchema={ChargeSchema}
                    onSubmit={data => chargeOrder(order._id, data)}
                    onSuccess={() => toggle()}
                  >
                    {({ isSubmitting }) => (
                      <>
                        <fieldset className="form-group">
                          <legend className="text-sm">Select product to charge</legend>
                          <Field
                            name="ordered_product_id.product"
                            type="select"
                            label="Add product"
                            options={createSelectOptions(
                              products,
                              '--- select product ---',
                              item => `${item.vendor_name} - ${item.name}`
                            )}
                            required
                            onChange={event => {
                              selectProduct(event.target.value);
                            }}
                          />
                          {product && (
                            <Field
                              name="ordered_product_id.variant"
                              type="select"
                              label="Select variant"
                              options={createSelectOptions(
                                (selectedProduct || product.product).variants.filter(v => v.available),
                                '--- select variant ---',
                                getVariantSelectLabel
                              )}
                              required
                            />
                          )}
                        </fieldset>

                        <fieldset className="form-group">
                          <legend className="text-sm">Select Subproduct to charge</legend>
                          <Field
                            name="subproduct_id.product"
                            type="select"
                            label="Add product"
                            options={createSelectOptions(
                              products.filter(p => !p.rotating),
                              '--- select product ---',
                              item => `${item.vendor_name} - ${item.name}`
                            )}
                            required
                            onChange={event => {
                              selectProduct(event.target.value, 'subproduct');
                            }}
                          />
                          {selectedSubproduct && (
                            <Field
                              name="subproduct_id.variant"
                              type="select"
                              label="Select variant"
                              options={createSelectOptions(
                                selectedSubproduct.variants.filter(v => v.available),
                                '--- select variant ---',
                                getVariantSelectLabel
                              )}
                              required
                            />
                          )}
                        </fieldset>

                        <SubmitButton color="dark" isSubmitting={isSubmitting}>
                          Charge
                        </SubmitButton>
                      </>
                    )}
                  </Form>
                </ModalBody>
              </>
            )}
            {modalType === MODAL_FETCH_EASYPOST && tracker && (
              <>
                <ModalHeader toggle={toggle}>Tracking</ModalHeader>
                <ModalBody>
                  <pre>{JSON.stringify(tracker, null, 2)}</pre>
                </ModalBody>
              </>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

Order.propTypes = {
  chargeOrder: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  fetchOrderTracking: PropTypes.func.isRequired,
  syncTracking: PropTypes.func.isRequired,
  replaceProduct: PropTypes.func.isRequired,
  onReplaceOrder: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  products: PropTypes.array,
  options: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  order: PropTypes.shape({
    shopify_name: PropTypes.string,
    _id: PropTypes.string.isRequired,
    status: PropTypes.string,
    discountCode: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
    easypost_id: PropTypes.string,
    shipping_status: PropTypes.string,
    source: PropTypes.string,
    date_arrived: PropTypes.string,
    usps_tracking: PropTypes.object,
    label_url: PropTypes.string,
    shipping_label: PropTypes.shape({
      label_url: PropTypes.string,
    }),
    amount_paid: PropTypes.number,
    tax_paid: PropTypes.number,
    free_bag: PropTypes.bool,
    updated_at: PropTypes.string,
    tracking_number: PropTypes.string,
    tracking_url: PropTypes.string,
    grind: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    user_id: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      verifiedAddress: PropTypes.object.isRequired,
    }).isRequired,
    product_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    product: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    ordered_product_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    subproduct_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    tracking_updates: PropTypes.array,
    subvendor_id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    address: PropTypes.object,
    date_initiated: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    override_fulfillment_date: PropTypes.string,
    date_fulfilled: PropTypes.string,
    shipping_service: PropTypes.string,
    predictions: PropTypes.array,
    need_support: PropTypes.bool,
    charge_subproduct: PropTypes.bool,
    quantity: PropTypes.number,
    external_id: PropTypes.string,
  }).isRequired,
  hideFooter: PropTypes.bool,
};

Order.defaultProps = {
  products: [],
  hideFooter: false,
};

const FulifillmentDate = ({ dateFulfilled }) => {
  if (!dateFulfilled) {
    return '---';
  }

  const diff = moment.duration(moment().diff(moment(dateFulfilled))).humanize();

  return (
    <>
      <DateFormat date={dateFulfilled} /> ({diff} ago)
    </>
  );
};

FulifillmentDate.propTypes = {
  dateFulfilled: PropTypes.string,
};

const VendorFulfillmentTime = ({ subvendor, dateFulfilled }) => {
  if (!subvendor || !subvendor.fulfillment_times || !dateFulfilled) {
    return '---';
  }

  const date = new Date(dateFulfilled);
  const fulfillDay = subvendor.fulfillment_times[date.getDay()];

  return `${fulfillDay.perc10} / ${fulfillDay.median} / ${fulfillDay.perc90}`;
};

VendorFulfillmentTime.propTypes = {
  subvendor: PropTypes.shape({
    fulfillment_times: PropTypes.object,
  }),
};
