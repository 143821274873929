import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetGifsTypes = createAction('Admin gifs', 'Get gifs');

export const getGifsAction = () => ({
  [RSAA]: {
    endpoint: `admin/settings/gifs`,
    method: 'GET',
    types: [GetGifsTypes.REQUEST, GetGifsTypes.SUCCESS, GetGifsTypes.FAILURE],
  },
});

export const editGifsTypes = createAction('Admin gifs', 'Edit gifs');

export const editGifsAction = data => ({
  [RSAA]: {
    endpoint: `admin/settings/gifs`,
    method: 'POST',
    body: data,
    types: [editGifsTypes.REQUEST, editGifsTypes.SUCCESS, editGifsTypes.FAILURE],
  },
});

export const GetOrderTrackingTypes = createAction('Admin order tracking manifest', 'Get order tracking manifest');

export const getOrderTrackingAction = () => ({
  [RSAA]: {
    endpoint: `admin/settings/order-tracking`,
    method: 'GET',
    types: [GetOrderTrackingTypes.REQUEST, GetOrderTrackingTypes.SUCCESS, GetOrderTrackingTypes.FAILURE],
  },
});

export const editOrderTrackingTypes = createAction('Admin order tracking manifest', 'Edit order tracking manifest');

export const editOrderTrackingAction = data => ({
  [RSAA]: {
    endpoint: `admin/settings/order-tracking`,
    method: 'POST',
    body: data,
    types: [editOrderTrackingTypes.REQUEST, editOrderTrackingTypes.SUCCESS, editOrderTrackingTypes.FAILURE],
  },
});

export const removeGifTypes = createAction('Admin gifs', 'Remove gifs');

export const removeGifAction = data => ({
  [RSAA]: {
    endpoint: `admin/settings/gifs/remove`,
    method: 'POST',
    body: data,
    types: [removeGifTypes.REQUEST, removeGifTypes.SUCCESS, removeGifTypes.FAILURE],
  },
});
