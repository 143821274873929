import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateFormat, Price } from '@bottomless/common/components';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Col, Row } from 'reactstrap';

export const Gift = ({ purchase }) => {
  const [giftPurchase, setGiftPurchase] = useState(purchase);

  useEffect(() => {
    setGiftPurchase(purchase);
  }, [purchase, setGiftPurchase]);

  return (
    <Card className="mb-2 p-4" body>
      <div className="d-flex flex-column w-100">
        <div className="gift-badges">
          <div className="badges">
            {giftPurchase.recipient.user_id ? <Badge color="success">Redeemed</Badge> : ''}
            {giftPurchase.refund?.date ? <Badge color="danger">Refunded</Badge> : ''}
            {giftPurchase.shouldShipToBuyer ? <Badge color="warning">Ship Scale to Buyer</Badge> : ''}
            {giftPurchase.shouldGiftWrap ? <Badge color="info">Gift Wrap</Badge> : ''}
          </div>
          <Link to={`/admin/gifts/${giftPurchase._id}`} className="edit">
            <Button color="danger">Edit</Button>
          </Link>
        </div>
        <p className="title">
          {giftPurchase.giftId.name} (x
          {giftPurchase.quantity || 1}) <span className="text-secondary">({giftPurchase._id})</span>
        </p>
        <Row className="mb-2">
          <Col md="6">
            <div>
              <span className="text-info">Send At Date:</span> <DateFormat date={giftPurchase.sendAt} fromZeroed />
            </div>
            <div>
              <span className="text-info">Status:</span> {giftPurchase.status}
            </div>
            {giftPurchase.refund?.date && (
              <div>
                <span className="text-info">Refunded:</span> <Price value={giftPurchase.refund.amount} cents />{' '}
                <span className="text-secondary">
                  on <DateFormat date={giftPurchase.refund.date} />
                </span>
              </div>
            )}
          </Col>
          <Col md="6">
            <div>
              <span className="text-info">Code:</span> {giftPurchase.code}
            </div>
            {giftPurchase.info && (
              <div className="gift-message">
                <span className="text-info">Message:</span> {giftPurchase.info}
              </div>
            )}
          </Col>
        </Row>
        <Row md="12" className="user-info-row">
          <Col md="6">
            <div>
              <span className="text-success">Buyer: </span>
              {giftPurchase.buyer.first_name} {giftPurchase.buyer.last_name}
            </div>
            <div className="d-block text-primary">{giftPurchase.buyer.email}</div>
            <div className="d-block mb-2 text-primary">{giftPurchase.buyer.phone}</div>
            {giftPurchase.buyer.verifiedAddress ? (
              <div>
                {`${giftPurchase.buyer.verifiedAddress.street1}, ${
                  giftPurchase.buyer.verifiedAddress.street2 ? `#${giftPurchase.buyer.verifiedAddress.street2},` : ''
                } ${giftPurchase.buyer.verifiedAddress.city}, ${giftPurchase.buyer.verifiedAddress.state}, ${
                  giftPurchase.buyer.verifiedAddress.zip
                }`}
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col md="6">
            <div>
              <span className="text-success">Recipient: </span>
              {giftPurchase.recipient.first_name} {giftPurchase.recipient.last_name}
            </div>
            <div className="d-block text-primary">{giftPurchase.recipient.email}</div>
            <div className="d-block text-primary">{giftPurchase.recipient.phone}</div>
            {giftPurchase.recipient.user_id ? <div>User Id: {giftPurchase.recipient.user_id}</div> : ''}
            {giftPurchase.recipient.verifiedAddress ? (
              <div className="mt-2">
                {`${giftPurchase.recipient.verifiedAddress.street1}, ${
                  giftPurchase.recipient.verifiedAddress.street2
                    ? `#${giftPurchase.recipient.verifiedAddress.street2},`
                    : ''
                } ${giftPurchase.recipient.verifiedAddress.city}, ${giftPurchase.recipient.verifiedAddress.state}, ${
                  giftPurchase.recipient.verifiedAddress.zip
                }`}
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

Gift.propTypes = {
  purchase: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    buyer: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      phone: PropTypes.string,
      email: PropTypes.string.isRequired,
      verifiedAddress: PropTypes.object,
    }),
    recipient: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      phone: PropTypes.string,
      email: PropTypes.string.isRequired,
      verifiedAddress: PropTypes.object,
      user_id: PropTypes.string,
    }),
    code: PropTypes.string.isRequired,
    giftId: PropTypes.object.isRequired,
    info: PropTypes.string,
    sendAt: PropTypes.string.isRequired,
    refund: PropTypes.shape({
      date: PropTypes.string,
      amount: PropTypes.string,
    }),
  }),
};
