import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Address, Image, Price } from '@bottomless/common/components';
import { Button, Card, CardBody, CardFooter, Col, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { convertOzToLbAndOz } from '@bottomless/common/src/utils';
import { EditForm } from '../../components/EditForm';
import { FulfillForm } from './FulfillForm';

const Product = ({ product, variant }) => {
  return (
    <>
      <div>{product.name}</div>
      <div className="text-primary text-sm">Size: {convertOzToLbAndOz(variant.size)}</div>
    </>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  variant: PropTypes.shape({
    size: PropTypes.number,
  }),
};

const ModalTypes = {
  FULFILL_ORDER: 'fulfill_order',
  EDIT_PRODUCT: 'edit_product',
};

export const Order = ({ order, fulfillOrder, addToast, startOrderProcessing, updateProduct }) => {
  const [product, setProduct] = useState(order?.productData || {});
  const [isFulfilled, setFulfilled] = useState(false);
  const [isProcessed, setProcessed] = useState(false);
  const [isModalOpen, toggleModal] = useToggle();
  const [type, setType] = useState();

  const variant = useMemo(() => product.variants.find(v => v._id === order.subproduct_id.variant), [order, product]);

  const openModal = useCallback(
    type => {
      setType(type);
      toggleModal();
    },
    [toggleModal, setType]
  );

  const handleProcess = useCallback(async () => {
    setProcessed(true);
    await startOrderProcessing(order._id);
  }, [order, startOrderProcessing]);

  const amazonData = useMemo(() => {
    return {
      url: product.eCommerce?.productUrl,
      fullName: (order.user_id.first_name || '') + (order.user_id.last_name ? ` ${order.user_id.last_name}` : ''),
      phone: order.user_id.phone,
      addressLine1: order.address?.street1 || order.user_id.verifiedAddress?.street1,
      addressLine2: order.address?.street2 || order.user_id.verifiedAddress?.street2,
      city: order.address?.city || order.user_id.verifiedAddress?.city,
      state: order.address?.state || order.user_id.verifiedAddress?.state,
      zip:
        (order.address?.zip || order.user_id.verifiedAddress?.zip) +
        (order.user_id.verifiedAddress?.zip4 ? `-${order.user_id.verifiedAddress.zip4}` : ''),
    };
  }, [order, product]);

  if (isFulfilled) {
    return <></>;
  }

  return (
    <>
      <Card className="mb-4">
        <CardBody>
          <Row>
            <Col xs="4">
              <a
                href={product.image_src || product.small_image_src}
                className="card-media"
                target="_blank"
                rel="noopener noreferrer"
              >
                {product.small_image_src && (
                  <Image src={product.small_image_src} alt={product.small_image_src} maxWidth="120" maxHeight="120" />
                )}
                {!product.small_image_src && product.image_src && (
                  <Image src={product.image_src} alt={product.image_src} maxWidth="120" maxHeight="120" />
                )}
              </a>
            </Col>
            <Col xs="4">
              <div>
                <Link to={`/admin/users/${order.user_id._id}?orderId=${order._id}`}>
                  <span className="text-primary">{order._id}</span>
                </Link>
              </div>
              <div>
                <Link to={`/admin/users/${order.user_id._id}`}>
                  {order.user_id.first_name} {order.user_id.last_name}
                </Link>
              </div>
              <Address address={order.address || order.user_id.verifiedAddress} />
              <div>
                {product.eCommerce?.productUrl && (
                  <a
                    className="text-success"
                    href={product.eCommerce.productUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Product URL
                  </a>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div>
                <Product product={product} variant={variant} />
              </div>
              <div>
                <span className="text-success">
                  Amount Paid: <Price value={order.amount_paid} cents />
                </span>
              </div>
              <div className="text-danger">E-Commerce type: {product?.eCommerce?.type || '---'}</div>
            </Col>
          </Row>
        </CardBody>

        <CardFooter className="d-flex flex-row align-items-center">
          <Button onClick={() => openModal(ModalTypes.FULFILL_ORDER)} size="sm" className="mr-3" color="warning">
            Fulfill
          </Button>
          <Button onClick={() => openModal(ModalTypes.EDIT_PRODUCT)} size="sm" className="mr-3" color="warning">
            Edit Product
          </Button>
          {product.eCommerce?.type === 'custom_amazon' && product.eCommerce?.productUrl && (
            <Button
              className="custom_amazon mr-3"
              data={JSON.stringify(amazonData)}
              size="sm"
              color="danger"
              disabled={isProcessed}
              onClick={handleProcess}
            >
              Process
            </Button>
          )}
        </CardFooter>
      </Card>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          {type === ModalTypes.FULFILL_ORDER && (
            <>
              <ModalHeader toggle={toggleModal}>
                <div className="text-sm text-secondary">{order._id}</div>
              </ModalHeader>
              <ModalBody>
                <FulfillForm
                  product={product}
                  order={order}
                  fulfillOrder={fulfillOrder}
                  toggleModal={toggleModal}
                  setFulfilled={setFulfilled}
                  addToast={addToast}
                />
              </ModalBody>
            </>
          )}
          {type === ModalTypes.EDIT_PRODUCT && (
            <>
              <ModalHeader toggle={toggleModal}>
                <div className="text-sm text-secondary">{product._id}</div>
                <div className="text-sm text-secondary">{product.name}</div>
              </ModalHeader>
              <ModalBody>
                <EditForm
                  product={product}
                  variant={variant}
                  updateProduct={updateProduct}
                  toggleModal={toggleModal}
                  setProduct={productData =>
                    setProduct(prevState => ({
                      ...prevState,
                      productData,
                    }))
                  }
                />
              </ModalBody>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

Order.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string,
    amount_paid: PropTypes.number,
    user_id: PropTypes.shape({
      _id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      phone: PropTypes.string,
      verifiedAddress: PropTypes.object,
    }),
    productData: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      image_src: PropTypes.string,
      small_image_src: PropTypes.string,
      status: PropTypes.string,
      active: PropTypes.bool,
      hidden: PropTypes.bool,
      date_added: PropTypes.string,
      updated_at: PropTypes.string,
      eCommerce: PropTypes.object,
    }).isRequired,
    address: PropTypes.object,
    subproduct_id: PropTypes.object,
  }),
  fulfillOrder: PropTypes.func,
  addToast: PropTypes.func,
  startOrderProcessing: PropTypes.func,
  updateProduct: PropTypes.func.isRequired,
};
