import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, SubmitButton } from '@bottomless/common/components';

export const Attribute = ({ checked, influencers, onSubmit, onSuccess }) => {
  const handleSubmit = useCallback(
    () => onSubmit({ influencers: influencers.filter((_, key) => checked.includes(key)) } ),
    [onSubmit, checked, influencers]
  );

  return (
    <Form initialValues={{}} validationSchema={Yup.object()} onSubmit={handleSubmit} onSuccess={onSuccess}>
      {({ isSubmitting, errors }) => (
        <>
          <SubmitButton color="success" disabled={!checked.length} isSubmitting={isSubmitting}>
            Attribute
          </SubmitButton>
          {errors?.message && <div className="text-danger">{errors.message}</div>}
        </>
      )}
    </Form>
  );
};

Attribute.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  influencers: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
  })).isRequired,
};
