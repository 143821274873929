import { stringify } from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetTemplatesTypes = createAction('Admin emails', 'Get templates');

export const getTemplatesAction = (enabled = true) => ({
  [RSAA]: {
    endpoint: `admin/emails?enabled=${enabled ? 'true' : 'false'}`,
    method: 'GET',
    types: [GetTemplatesTypes.REQUEST, GetTemplatesTypes.SUCCESS, GetTemplatesTypes.FAILURE],
  },
});

export const GetTemplateTypes = createAction('Admin emails', 'Get template');

export const getTemplateAction = id => ({
  [RSAA]: {
    endpoint: `admin/emails/${id}`,
    method: 'GET',
    types: [GetTemplateTypes.REQUEST, GetTemplateTypes.SUCCESS, GetTemplateTypes.FAILURE],
  },
});

export const RenderTemplateTypes = createAction('Admin emails', 'Render template');

export const renderTemplateAction = (type, params) => ({
  [RSAA]: {
    endpoint: `admin/emails/${type}/render?${stringify(params)}`,
    method: 'GET',
    types: [RenderTemplateTypes.REQUEST, RenderTemplateTypes.SUCCESS, RenderTemplateTypes.FAILURE],
  },
});

export const SendEmailTypes = createAction('Admin emails', 'Send email');

export const sendEmailAction = (context, data, source) => ({
  [RSAA]: {
    endpoint: `admin/emails/send`,
    method: 'POST',
    body: { ...data, context, source },
    types: [SendEmailTypes.REQUEST, SendEmailTypes.SUCCESS, SendEmailTypes.FAILURE],
  },
});

export const CreateTemplateTypes = createAction('Admin emails', 'Create template');

export const createTemplateAction = data => ({
  [RSAA]: {
    endpoint: `admin/emails`,
    method: 'POST',
    body: data,
    types: [CreateTemplateTypes.REQUEST, CreateTemplateTypes.SUCCESS, CreateTemplateTypes.FAILURE],
  },
});

export const UpdateTemplateTypes = createAction('Admin emails', 'Update template');

export const updateTemplateAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/emails/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateTemplateTypes.REQUEST, UpdateTemplateTypes.SUCCESS, UpdateTemplateTypes.FAILURE],
  },
});

export const ArchiveTemplateTypes = createAction('Admin emails', 'Archive template');

export const archiveTemplateAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/emails/${id}/archive`,
    method: 'PATCH',
    body: data,
    types: [ArchiveTemplateTypes.REQUEST, ArchiveTemplateTypes.SUCCESS, ArchiveTemplateTypes.FAILURE],
  },
});
