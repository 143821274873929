import React, { memo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const INITIAL_PACKAGING_STOCK = {
  8: 0,
  10: 0,
  12: 0,
  14: 0,
  16: 0,
  24: 0,
  32: 0,
};

const packagingSchema = Yup.object().shape({
  packagingCount: Yup.object()
    .shape({
      '12': Yup.number()
        .min(0)
        .integer(),
      '32': Yup.number()
        .min(0)
        .integer(),
    })
    .required('This field is required'),
  external_id: Yup.string().required(),
  description: Yup.string(),
  tracking_number: Yup.string().required(),
  shipping_service: Yup.string().required(),
});

const AddPackagingModal = ({ toggle, isOpen, vendor, addPackaging, onPackagingSuccess }) => {
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Add packaging</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{ packagingCount: INITIAL_PACKAGING_STOCK }}
          onSubmit={addPackaging}
          onSuccess={onPackagingSuccess}
          validationSchema={packagingSchema}
        >
          {({ isSubmitting }) => (
            <>
              <Row>
                {Object.keys(vendor.usesPackaging).map((size, i) =>
                  vendor.usesPackaging[size] ? (
                    <Col xs="6" key={i}>
                      <Field name={`packagingCount[${size}]`} type="number" label={`Size ${size} amount`} />
                    </Col>
                  ) : (
                    ''
                  )
                )}
              </Row>
              <Field name="external_id" label="Order id" />
              <Field name="tracking_number" label="Tracking number" />
              <Field name="shipping_service" label="Tracking service" />
              <Field name="description" label="Description" type="textarea" />
              <div className="d-flex justify-content-end">
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </div>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

AddPackagingModal.propTypes = {
  vendor: PropTypes.shape({
    _id: PropTypes.string,
    usesPackaging: PropTypes.object.isRequired,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addPackaging: PropTypes.func.isRequired,
  onPackagingSuccess: PropTypes.func.isRequired,
};

export const AddPackaging = memo(AddPackagingModal);
