export const tabs = [
  { to: '/admin/attributes/tasting_notes_raw', name: 'Tasting Notes' },
  { to: '/admin/attributes/tastingNoteCategories', name: 'Tasting Notes Categories' },
  { to: '/admin/attributes/tags', name: 'Tags' },
  { to: '/admin/attributes/regions_raw', name: 'Regions' },
  { to: '/admin/attributes/origins', name: 'Origins' },
  { to: '/admin/attributes/roasts', name: 'Roasts' },
  { to: '/admin/attributes/processes', name: 'Processes' },
  { to: '/admin/attributes/varietals', name: 'Varietals' },
  { to: '/admin/attributes/grind', name: 'grind' },
];
