import React from 'react';
import PropTypes from 'prop-types';
import { connectWithAbort } from '@bottomless/common/store';
import { getNoDataOrderGenAction } from '../../../../store/admin/ordergen';
import { PaginatedOrderGenPage } from './PaginatedOrderGen';

const NoDataOrderGenPageComponent = ({ getNoData }) => <PaginatedOrderGenPage getUsers={getNoData} />;

NoDataOrderGenPageComponent.propTypes = {
  getNoData: PropTypes.func.isRequired,
};

export const NoDataOrderGenPage = connectWithAbort(null, dispatch => ({
  getNoData: params => dispatch(getNoDataOrderGenAction(params)),
}))(NoDataOrderGenPageComponent);
