import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetWhitelistVendorsTypes = createAction('Vendor', 'Get Vendors');

export const getWhitelistVendorsAction = category => ({
  [RSAA]: {
    endpoint: `vendors/whitelist${category ? `?category=${category}` : ''}`,
    method: 'GET',
    types: [GetWhitelistVendorsTypes.REQUEST, GetWhitelistVendorsTypes.SUCCESS, GetWhitelistVendorsTypes.FAILURE],
  },
});
