import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetRetentionDataTypes = createAction('Admin data', 'Get retention');

export const getRetentionDataAction = params => {
  return {
    [RSAA]: {
      endpoint: `admin/data/retention?${qs.stringify(params)}`,
      method: 'GET',
      types: [GetRetentionDataTypes.REQUEST, GetRetentionDataTypes.SUCCESS, GetRetentionDataTypes.FAILURE],
    },
  };
};

export const GetRetentionByLandingDataTypes = createAction('Admin data', 'Get RetentionByLanding');

export const getRetentionByLandingDataAction = params => {
  return {
    [RSAA]: {
      endpoint: `admin/data/retention-by-landing?${qs.stringify(params)}`,
      method: 'GET',
      types: [
        GetRetentionByLandingDataTypes.REQUEST,
        GetRetentionByLandingDataTypes.SUCCESS,
        GetRetentionByLandingDataTypes.FAILURE,
      ],
    },
  };
};
