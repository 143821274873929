import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from '@bottomless/common/components';
import * as Yup from 'yup';
import { Button } from 'reactstrap';
import { ArrowLeft, ArrowRight } from 'react-feather';

const Schema = Yup.object().shape({
  sendNotification: Yup.bool(),
  sendApologyNotification: Yup.bool(),
  productSwitch: Yup.bool(),
  noEmail: Yup.bool(),
});

export const GenerateOrder = ({ onOrder, onNoOrder, isSending, err, toggleNeedSupport }) => {
  const [isOrder, setOrder] = useState(false);

  const onSubmit = values => {
    isOrder ? onOrder(values) : onNoOrder(values);
  };

  const handleNoOrder = submitForm => () => {
    setOrder(false);
    submitForm();
  };

  const handleOrder = submitForm => () => {
    setOrder(true);
    submitForm();
  };

  return (
    <Form
      initialValues={{
        sendNotification: false,
        sendApologyNotification: false,
        productSwitch: false,
        noEmail: false,
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <div className="d-flex flex-row">
          <div className="text-sm">
            <Checkbox name="productSwitch" label="Send variant switch" value={true} />
            <Checkbox name="sendApologyNotification" label="Send apology notification" value={true} />
            <Checkbox name="sendNotification" label="Send custom notification" value={true} />
            <Checkbox name="noEmail" label="No ordering email" value={true} />
          </div>
          <div className="ml-auto pl-3">
            <Button
              size="sm"
              color="dark"
              disabled={Boolean(isSending || err)}
              block
              outline
              type="button"
              onClick={handleNoOrder(submitForm)}
              className="d-flex align-items-center"
            >
              <ArrowLeft size="14" />
              <span className="ml-1">No order</span>
            </Button>
            <Button
              size="sm"
              color="success"
              disabled={Boolean(isSending || err)}
              block
              type="button"
              onClick={handleOrder(submitForm)}
              className="d-flex align-items-center mb-3"
            >
              <span className="mr-1">Generate order</span>
              <ArrowRight size="14" />
            </Button>
            <Button size="sm" color="danger" block outline type="button" onClick={toggleNeedSupport} className="mb-1">
              Needs support
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};

GenerateOrder.propTypes = {
  onOrder: PropTypes.func.isRequired,
  onNoOrder: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  toggleNeedSupport: PropTypes.func.isRequired,
  err: PropTypes.object,
};
