import React, { memo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const inventorySchema = Yup.object().shape({
  packagingCount: Yup.object()
    .shape({
      '12': Yup.number()
        .min(0)
        .integer(),
      '32': Yup.number()
        .min(0)
        .integer(),
    })
    .required('This field is required'),
});

const InventoryAdjustmentModal = ({ toggle, isOpen, vendor, adjustInventory, onAdjustInventorySuccess }) => {
  const { packagingCount } = vendor || {};

  const INITIAL_ON_HAND_INVENTORY = packagingCount;

  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Inventory Adjustment</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{ packagingCount: INITIAL_ON_HAND_INVENTORY }}
          onSubmit={adjustInventory}
          onSuccess={onAdjustInventorySuccess}
          validationSchema={inventorySchema}
        >
          {({ isSubmitting }) => (
            <>
              <Row>
                {Object.keys(packagingCount).map((size, i) =>
                  packagingCount[size] ? (
                    <Col xs="6" key={i}>
                      <Field name={`packagingCount[${size}]`} type="number" label={`Size ${size} amount`} />
                    </Col>
                  ) : (
                    ''
                  )
                )}
              </Row>
              <div className="d-flex justify-content-end">
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </div>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

InventoryAdjustmentModal.propTypes = {
  vendor: PropTypes.shape({
    _id: PropTypes.string,
    packagingCount: PropTypes.object.isRequired,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  adjustInventory: PropTypes.func.isRequired,
  onAdjustInventorySuccess: PropTypes.func.isRequired,
};

export const InventoryAdjustment = memo(InventoryAdjustmentModal);
