import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowDown, ArrowUp, Trash } from 'react-feather';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Col, Row, Table } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, DataLoading, Image, Price } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { HeadingBack } from '../../../../components/HeadingBack/HeadingBack';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getProductsAction } from '../../../../store/admin/product';
import { getRotationAction, updateRotationAction } from '../../../../store/admin/rotation';
import { getVariantSelectLabel } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  slug: Yup.string().required('This field is required'),
  background: Yup.string()
    .matches(/^https:/, 'Please use https://')
    .required(),
  products: Yup.array().min(1),
});

const EditRotationPageComponent = ({ getRotation, getProducts, updateRotation, addToast, match }) => {
  const formRef = useRef(null);
  const [rotation, setRotation] = useState(null);
  const [rotationCopy, setRotationCopy] = useState(null);
  const [shopProducts, setShopProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({ product: null, variant: null });

  const { id } = match.params;

  const Heading = HeadingBack({
    to: '/admin/rotations',
  });

  useDataEffect(
    getRotation,
    data => {
      setRotation(data);
      setRotationCopy(data);
    },
    id
  );

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.resetForm({ ...rotationCopy });
    }
  }, [rotationCopy]);

  useDataEffect(
    getProducts,
    response => {
      setShopProducts(get(response, 'docs', []).filter(p => !p.rotating));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  const handleSubmit = params => {
    const { products, ...data } = params;

    return updateRotation(rotation._id, {
      ...data,
      products: products.map(({ product, variant }) => ({
        product: product._id,
        variant,
      })),
    });
  };

  const handleSuccess = data => {
    setRotationCopy({ ...data });
    addToast('Rotation Saved');
  };

  const remove = useCallback(
    index => {
      const { products } = rotationCopy;
      products.splice(index, 1);
      setRotationCopy({
        ...rotationCopy,
        products,
      });
    },
    [rotationCopy]
  );

  const sort = useCallback(
    (index, dir) => {
      const toIndex = dir === 'up' ? index - 1 : index + 1;

      if (toIndex <= 0 || toIndex === rotationCopy.products.length - 1) {
        return;
      }

      const { products } = rotationCopy;

      products.splice(toIndex, 0, ...products.splice(index, 1));

      setRotationCopy({
        ...rotationCopy,
        products,
      });
    },
    [rotationCopy]
  );

  const selectProduct = useCallback(
    (id, variant = null) => {
      const newProduct = shopProducts.find(p => p._id === id);
      const updatedProduct = { ...newProduct, _id: newProduct };
      setSelectedProduct({ product: updatedProduct, variant });
    },
    [shopProducts]
  );

  const addNewProduct = useCallback(() => {
    setRotationCopy({
      ...rotationCopy,
      products: [...rotationCopy.products, selectedProduct],
    });

    setSelectedProduct({ product: null, variant: null });
  }, [rotationCopy, selectedProduct]);

  const getVariant = (product, variant) => product.variants.find(v => v._id === variant);

  const createSelectOptions = (data, label, inputLabel = item => item.name) =>
    !data
      ? {}
      : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

  return (
    <PanelPage title={rotation ? rotation.slug : ''} heading={Heading}>
      <DataLoading count={rotation ? 1 : 0} isLoading={Boolean(rotation)} />
      {rotation && (
        <>
          <Row>
            <Col xs="12" className="mb-4">
              {rotation.background && (
                <Image src={rotation.background} alt={rotation.slug} maxWidth="300" maxHeight="300" />
              )}
            </Col>
            <Col xs="12">
              <Form
                innerRef={formRef}
                initialValues={rotation}
                validationSchema={Schema}
                onSubmit={handleSubmit}
                onSuccess={handleSuccess}
              >
                {({ isSubmitting, values }) => (
                  <>
                    <Field name="slug" label="slug" />
                    <Field name="background" label="background" />

                    <Table>
                      <tbody>
                        {values &&
                          values.products &&
                          values.products.map(({ product, variant }, i) => (
                            <tr key={i}>
                              <td className="text-sm">
                                <span className="text-secondary d-block">#{product._id._id}</span>
                                <span className="d-block">{product.name}</span>
                              </td>
                              <td className="text-sm">
                                <span>size: {(getVariant(product, variant) || { size: '---' }).size} oz</span>
                                <span> / </span>
                                <span>
                                  price: <Price value={(getVariant(product, variant) || { price: '---' }).price} />
                                </span>
                              </td>
                              <td className="text-right">
                                <ButtonGroup>
                                  <Button size="sm" type="button" color="text" onClick={() => sort(i, 'up')}>
                                    <ArrowUp size="14" />
                                  </Button>
                                  <Button size="sm" type="button" color="text" onClick={() => sort(i, 'down')}>
                                    <ArrowDown size="14" />
                                  </Button>
                                  <Button
                                    size="sm"
                                    disabled={values.products.length === 1}
                                    type="button"
                                    color="danger"
                                    onClick={() => remove(i)}
                                  >
                                    <Trash size="14" />
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>

                    <Row>
                      {shopProducts && (
                        <>
                          <Col xs="6">
                            <Field
                              name="addProduct"
                              type="select"
                              label="Add product"
                              options={createSelectOptions(
                                shopProducts,
                                '--- select product ---',
                                item => `${item.vendor_name} - ${item.name}`
                              )}
                              required
                              onChange={event => {
                                selectProduct(event.target.value);
                              }}
                            />
                          </Col>
                          {selectedProduct && selectedProduct.product && (
                            <Col>
                              <Field
                                name="addVariant"
                                type="select"
                                label="Select variant"
                                options={createSelectOptions(
                                  selectedProduct.product.variants.filter(v => v.available),
                                  '--- select variant ---',
                                  getVariantSelectLabel
                                )}
                                required
                                onChange={event => {
                                  setSelectedProduct({
                                    ...selectedProduct,
                                    variant: event.target.value,
                                  });
                                }}
                              />
                            </Col>
                          )}

                          <Col>
                            <Button
                              type="button"
                              disabled={selectedProduct.product === null || selectedProduct.variant === null}
                              onClick={addNewProduct}
                            >
                              Add product
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                    <div className="d-flex justify-content-between">
                      <SubmitButton color="dark" isSubmitting={isSubmitting}>
                        Save
                      </SubmitButton>
                    </div>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </>
      )}
    </PanelPage>
  );
};

EditRotationPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getRotation: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  updateRotation: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const EditRotationPage = connect(null, dispatch => ({
  addToast: message => dispatch(addToastAction(message)),
  getRotation: id => dispatch(getRotationAction(id)),
  getProducts: params => dispatch(getProductsAction(params)),
  updateRotation: (id, params) => dispatch(updateRotationAction(id, params)),
}))(EditRotationPageComponent);
