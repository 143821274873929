import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Loader } from '@bottomless/common/components';
import { useMemo } from 'react';

export const Rotation = ({ firstProduct, price, productAmount, variant, isLoading, vendors, error }) => {
  const currVendor = useMemo(() => vendors?.find(row => row._id === firstProduct?.vendor_id), [firstProduct, vendors]);

  if (isLoading) {
    return (
      <Col>
        <Row className="justify-content-center mb-2">
          <h3>Your Rotation</h3>
        </Row>
        <Row className="justify-content-center mb-3">
          <Loader />
        </Row>
      </Col>
    );
  }
  return (
    <Col>
      <Row className="justify-content-center mb-2">
        <h3>Your Rotation</h3>
      </Row>
      {firstProduct && (
        <Col className="mb-2">
          <Row className="justify-content-center">
            <h5>Example Product</h5>
          </Row>
          <Row className="justify-content-center">
            <img src={firstProduct.small_image_src} width={100} />
          </Row>
          <Row className="justify-content-center text-center font-weight-bold">{firstProduct.name}</Row>
          <Row className="justify-content-center text-center">{currVendor?.name}</Row>
        </Col>
      )}
      {!firstProduct && <h6 className="justify-content-center text-center">No Product Available</h6>}
      <Row className="justify-content-center mb-3">
        {variant.size}oz-${price}
      </Row>
      <Row className="justify-content-center mb-2 font-italic">{productAmount || 0} product(s) in this rotation</Row>
      {error && <Row className="justify-content-center mb-2 text-danger">{error}</Row>}
    </Col>
  );
};

Rotation.propTypes = {
  firstProduct: PropTypes.shape({
    small_image_src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_id: PropTypes.string.isRequired,
  }),
  price: PropTypes.number,
  productAmount: PropTypes.number,
  variant: PropTypes.shape({
    size: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool,
  vendors: PropTypes.array.isRequired,
  error: PropTypes.string,
};
