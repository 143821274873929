import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button } from 'reactstrap';
import { addToastAction } from '@bottomless/common/store';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect, useToggle, useConditionalDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getVendorsDownTimeAction, getVendorsAction, addVendorDownTimeAction } from '../../../../store/admin/vendor';
import { tabs } from './components/Tabs';
import { AddDownDayModal } from './components/AddDownDayModal';
import { DownDaysTable } from './components/DownDaysTable';

import './VendorDownDays.scss';

const VendorDownDaysPageComponent = ({
  getVendorsDownTime,
  addToast,
  getVendors,
  addVendorDownTime,
  match: {
    params: { status },
  },
}) => {
  const [vendorDownTimes, setVendorDownTimes] = useState(null);
  const [vendors, setVendors] = useState();
  // const { isFetching } = useDataEffect(getVendorsDownTime, setVendorDownTimes, status);
  useDataEffect(getVendors, setVendors);
  const { isFetching } = useConditionalDataEffect(!vendorDownTimes, getVendorsDownTime, setVendorDownTimes, status);
  const [addModalOpen, toggleAddModal] = useToggle();

  const addVendorDown = async data => {
    try {
      const formattedDate = moment(data.down_date)
        .utcOffset(0, true)
        .format();
      await addVendorDownTime({ ...data, down_date: formattedDate });
      setVendorDownTimes(null);
      await addToast('Successfully added down time');
      toggleAddModal();
    } catch (e) {
      await addToast('Oops something went wrong.');
    }
  };

  return (
    <>
      <PanelPage
        title="Down Days"
        tabs={tabs}
        heading={
          <Button className="" onClick={toggleAddModal}>
            <span>Create New</span>
          </Button>
        }
      >
        <DataLoading count={(vendorDownTimes && vendorDownTimes.length) || 0} isLoading={isFetching} />
        {vendors && (
          <AddDownDayModal
            toggleAddModal={toggleAddModal}
            addModalOpen={addModalOpen}
            vendors={vendors}
            addVendorDownTime={addVendorDown}
          />
        )}
        {vendorDownTimes && <DownDaysTable vendorDownTimes={vendorDownTimes} />}
      </PanelPage>
    </>
  );
};

VendorDownDaysPageComponent.propTypes = {
  getVendorsDownTime: PropTypes.func,
  addToast: PropTypes.func,
  getVendors: PropTypes.func,
  addVendorDownTime: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export const VendorDownDaysPage = connect(null, dispatch => ({
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getVendorsDownTime: status => dispatch(getVendorsDownTimeAction(status)),
  addVendorDownTime: data => dispatch(addVendorDownTimeAction(data)),
  getVendors: () => dispatch(getVendorsAction({ status: 'active' })),
}))(VendorDownDaysPageComponent);
