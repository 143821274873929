import { LogoutTypes } from '@bottomless/common/store';
import {
  ORDERGEN_ADD_NOTE,
  ORDERGEN_DELETE,
  ORDERGEN_DELETE_NOTE,
  ORDERGEN_PUSH,
  ORDERGEN_UPDATE,
  PrefetchTodoNotInFulfilmentWindowOrderGenTypes,
  PrefetchTodoOrderGenTypes,
  PrefetchStreamingOrderGenTypes,
  PrefetchNewUsersOrderGenTypes,
  PrefetchAiDecisionOrderGenTypes,
  PrefetchNotOnScaleOrderGenTypes,
  PrefetchSlowLocalOrderGenTypes,
  PrefetchByCategoryOrderGenTypes,
  PrefetchAG1OrderGenTypes,
} from './ordergen.actions';

const initialState = {
  data: [],
  userIds: [],
  isPrefetching: true,
};

export const adminOrderGenReducer = (state = initialState, action) => {
  switch (action.type) {
    case PrefetchTodoOrderGenTypes.REQUEST:
    case PrefetchTodoNotInFulfilmentWindowOrderGenTypes.REQUEST:
    case PrefetchNewUsersOrderGenTypes.REQUEST:
    case PrefetchStreamingOrderGenTypes.REQUEST:
    case PrefetchAiDecisionOrderGenTypes.REQUEST:
    case PrefetchNotOnScaleOrderGenTypes.REQUEST:
    case PrefetchSlowLocalOrderGenTypes.REQUEST:
    case PrefetchByCategoryOrderGenTypes.REQUEST:
    case PrefetchAG1OrderGenTypes.REQUEST:
      return {
        data: [],
        userIds: [],
        isPrefetching: true,
      };

    case PrefetchTodoOrderGenTypes.SUCCESS:
    case PrefetchTodoNotInFulfilmentWindowOrderGenTypes.SUCCESS:
    case PrefetchNewUsersOrderGenTypes.SUCCESS:
    case PrefetchStreamingOrderGenTypes.SUCCESS:
    case PrefetchAiDecisionOrderGenTypes.SUCCESS:
    case PrefetchNotOnScaleOrderGenTypes.SUCCESS:
    case PrefetchSlowLocalOrderGenTypes.SUCCESS:
    case PrefetchByCategoryOrderGenTypes.SUCCESS:
    case PrefetchAG1OrderGenTypes.SUCCESS:
      if (action.payload.async) {
        switch (action.payload.status) {
          case 'preparing':
            return {
              ...state,
              isPrefetching: true,
            };
          case 'ready':
            return {
              ...state,
              userIds: chunkUsers(action.payload.userIds),
              isPrefetching: false,
            };
        }
      }

      return {
        ...state,
        userIds: chunkUsers(action.payload.userIds),
        isPrefetching: false,
      };

    case PrefetchTodoOrderGenTypes.FAILURE:
    case PrefetchTodoNotInFulfilmentWindowOrderGenTypes.FAILURE:
    case PrefetchNewUsersOrderGenTypes.FAILURE:
    case PrefetchStreamingOrderGenTypes.FAILURE:
    case PrefetchAiDecisionOrderGenTypes.FAILURE:
    case PrefetchNotOnScaleOrderGenTypes.FAILURE:
    case PrefetchSlowLocalOrderGenTypes.FAILURE:
    case PrefetchByCategoryOrderGenTypes.FAILURE:
    case PrefetchAG1OrderGenTypes.FAILURE:
      return {
        ...state,
        isPrefetching: false,
      };

    case ORDERGEN_PUSH:
      return {
        ...state,
        data: [...state.data, ...action.payload.filter(newUser => !state.data.find(user => user._id === newUser._id))],
      };

    case ORDERGEN_UPDATE:
      return {
        ...state,
        data: state.data.map(user => (user._id === action.payload.id ? { ...user, ...action.payload.data } : user)),
      };

    case ORDERGEN_DELETE:
      return {
        ...state,
        data: state.data.filter(user => user._id !== action.payload.id),
        isLoading: false,
      };

    case ORDERGEN_ADD_NOTE:
      return {
        ...state,
        data: state.data.map(user =>
          user._id !== action.payload.userId ? user : { ...user, notes: [action.payload.note, ...(user.notes || [])] }
        ),
      };

    case ORDERGEN_DELETE_NOTE: {
      return {
        ...state,
        data: state.data.map(user =>
          user._id !== action.payload.useId
            ? user
            : { ...user, notes: (user.notes || []).filter(({ _id }) => action.payload.noteId !== _id) }
        ),
      };
    }

    case LogoutTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};

const chunkUsers = users => {
  const chunkSize = 20;
  const chunks = [];

  for (var i = 0; i < users.length; i += chunkSize) {
    chunks.push(users.slice(i, i + chunkSize));
  }

  return chunks;
};
