import React from 'react';
import PropTypes from 'prop-types';
import { connectWithAbort } from '@bottomless/common/store';
import { createOrderGenAction, getOptOutOrderGenAction } from '../../../../store/admin/ordergen';
import { PaginatedOrderGenPage } from './PaginatedOrderGen';

const OptOutOrderGenPageComponent = ({ getOptOutUsers, createOrderGen }) => (
  <PaginatedOrderGenPage
    title="Opt out"
    getUsers={getOptOutUsers}
    onGenerateInstance={createOrderGen}
    hideOnGeneration={false}
    withTabs={false}
  />
);

OptOutOrderGenPageComponent.propTypes = {
  getOptOutUsers: PropTypes.func.isRequired,
  createOrderGen: PropTypes.func.isRequired,
};

export const OptOutOrderGenPage = connectWithAbort(null, dispatch => ({
  getOptOutUsers: params => dispatch(getOptOutOrderGenAction(params)),
  createOrderGen: data => dispatch(createOrderGenAction(data)),
}))(OptOutOrderGenPageComponent);
