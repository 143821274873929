import { DataLoading } from '@bottomless/common/src/components/DataLoading';
import { useDataEffect } from '@bottomless/common/src/hooks';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import { DateFormat, Default } from '@bottomless/common/src/components';
import { getOrdergenHistoryAction } from '../../../../../store/admin/ordergen';

const Source = ({ ordergen }) => {
  const source = useMemo(() => {
    if (!ordergen.source) {
      return;
    }
    const routes = ordergen.source.split('/');
    const [second, first] = [routes.pop(), routes.pop()];

    return `${first}/${second}`;
  }, [ordergen]);
  return <Default>{source}</Default>;
};

Source.propTypes = {
  ordergen: PropTypes.shape({
    source: PropTypes.string,
  }).isRequired,
};

const OrdergenHistoryComponent = ({ user, getOrdergenHistory }) => {
  const { data, isFetching } = useDataEffect(getOrdergenHistory, undefined, user._id);

  return (
    <>
      <DataLoading count={data?.length || 0} isLoading={isFetching}>
        <div>Charges is empty</div>
      </DataLoading>
      {data?.length > 0 && (
        <Table size="sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Author</th>
              <th>Order</th>
              <th>Feedback</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            {data.map(ordergen => (
              <tr key={ordergen._id}>
                <td>
                  <DateFormat date={ordergen.timestamp} format="MM/DD/YYYY (ddd)" />
                </td>
                <td>
                  <Default>{ordergen.author}</Default>
                </td>
                <td
                  className={classNames({
                    'text-success': ordergen.order,
                    'font-weight-bold': ordergen.order,
                    'text-secondary': !ordergen.order,
                  })}
                >
                  {ordergen.order ? 'Yes' : 'No'}
                </td>
                <td>
                  <Default>{ordergen.order?.rate?.timing}</Default>
                </td>
                <td>
                  <Source ordergen={ordergen} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

OrdergenHistoryComponent.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  getOrdergenHistory: PropTypes.func.isRequired,
};

export const OrdergenHistory = connect(null, dispatch => ({
  getOrdergenHistory: id => dispatch(getOrdergenHistoryAction(id)),
}))(OrdergenHistoryComponent);
