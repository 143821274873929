import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetRotationsTypes = createAction('Admin Rotations', 'Get rotations');

export const getRotationsAction = params => ({
  [RSAA]: {
    endpoint: `admin/rotations${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetRotationsTypes.REQUEST, GetRotationsTypes.SUCCESS, GetRotationsTypes.FAILURE],
  },
});

export const GetRotationTypes = createAction('Admin Rotations', 'Get rotation');

export const getRotationAction = id => ({
  [RSAA]: {
    endpoint: `admin/rotations/${id}`,
    method: 'GET',
    types: [GetRotationTypes.REQUEST, GetRotationTypes.SUCCESS, GetRotationTypes.FAILURE],
  },
});

export const UpdateRotationTypes = createAction('Admin Rotations', 'Update rotation');

export const updateRotationAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/rotations/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateRotationTypes.REQUEST, UpdateRotationTypes.SUCCESS, UpdateRotationTypes.FAILURE],
  },
});

export const GetRotationPricingTypes = createAction('Admin Rotations', 'Get rotation pricing');

export const getRotationPricingAction = () => ({
  [RSAA]: {
    endpoint: `admin/rotations/pricing`,
    method: 'GET',
    types: [GetRotationPricingTypes.REQUEST, GetRotationPricingTypes.SUCCESS, GetRotationPricingTypes.FAILURE],
  },
});

export const UpdateRotationPricingTypes = createAction('Admin Rotations', 'Update rotation pricing');

export const updateRotationPricingAction = data => ({
  [RSAA]: {
    endpoint: `admin/rotations/pricing`,
    method: 'PATCH',
    body: data,
    types: [UpdateRotationPricingTypes.REQUEST, UpdateRotationPricingTypes.SUCCESS, UpdateRotationPricingTypes.FAILURE],
  },
});

export const GetQuizTypes = createAction('Admin Rotations', 'Get quiz');

export const getQuizAction = data => ({
  [RSAA]: {
    endpoint: `admin/quiz`,
    method: 'POST',
    body: data,
    types: [GetQuizTypes.REQUEST, GetQuizTypes.SUCCESS, GetQuizTypes.FAILURE],
  },
});

export const UpdateQuizTypes = createAction('Admin Rotations', 'Update quiz');

export const updateQuizAction = data => ({
  [RSAA]: {
    endpoint: `admin/quiz/update`,
    method: 'POST',
    body: data,
    types: [UpdateQuizTypes.REQUEST, UpdateQuizTypes.SUCCESS, UpdateQuizTypes.FAILURE],
  },
});

export const CreateQuizTypes = createAction('Admin Rotations', 'Create quiz');

export const createQuizAction = data => ({
  [RSAA]: {
    endpoint: `admin/quiz/create`,
    method: 'POST',
    body: data,
    types: [CreateQuizTypes.REQUEST, CreateQuizTypes.SUCCESS, CreateQuizTypes.FAILURE],
  },
});
