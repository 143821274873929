import { useDataEffect } from '@bottomless/common/src/hooks';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { DataLoading, Default } from '@bottomless/common/components';
import { Button } from 'reactstrap';

const STATUS_DISCONNECTED = 'disconnected';
const STATUS_NOT_CONFIRMED = 'not_confirmed';

export const ManageScaleForm = ({ user, onSubmit, getScale, onSubmitSuccess, disableScaleNotifications, addToast }) => {
  const [isLoading, setLoading] = useState(false);
  const { data: scale, isFetching } = useDataEffect(getScale, undefined, user.base_id);
  const isDisconnected = useMemo(
    () => user.scale_status === STATUS_DISCONNECTED && (!scale?.status || scale?.status === STATUS_NOT_CONFIRMED),
    [user, scale]
  );

  const onClick = useCallback(() => {
    setLoading(true);

    return onSubmit('true')
      .then(({ payload }) => onSubmitSuccess(payload))
      .finally(() => setLoading(false));
  }, [setLoading, onSubmit, onSubmitSuccess]);

  const handleDisableScaleNotifications = useCallback(async () => {
    try {
      setLoading(true);
      await disableScaleNotifications(user._id);
      setLoading(false);
      addToast('Scale notifications disabled', 'success');
    } catch (error) {
      addToast(error.message, 'error');
    }
  }, [setLoading, disableScaleNotifications, user, addToast]);

  if (isFetching) {
    return <DataLoading count={0} isLoading={isFetching} />;
  }

  return (
    <>
      <div>
        Scale status (user): <Default>{user.scale_status}</Default>
      </div>
      {scale && (
        <div>
          Scale status (scale): <Default>{scale?.status}</Default>
        </div>
      )}
      <div>
        Base id: <Default>{user.base_id}</Default>
      </div>

      <Button size="sm" color="success" className="mt-3" onClick={onClick} disabled={isDisconnected || isLoading}>
        {isLoading && !isDisconnected ? 'Saving...' : 'Disconnect'}
      </Button>

      {user.scale_status === STATUS_DISCONNECTED && (
        <Button
          size="sm"
          color="danger"
          className="ml-2 mt-3"
          onClick={handleDisableScaleNotifications}
          disabled={user.scale_status_notifications >= 5 || isLoading}
        >
          {isLoading ? 'Saving...' : 'Disable scale notifications'}
        </Button>
      )}
    </>
  );
};

ManageScaleForm.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    scale_status: PropTypes.string,
    base_id: PropTypes.string,
    scale_status_notifications: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  getScale: PropTypes.func.isRequired,
  disableScaleNotifications: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};
