export const tabs = [
  { to: '/admin/ordering_cockpit/todo', name: 'Todo' },
  { to: '/admin/ordering_cockpit/new-users', name: 'New users' },
  { to: '/admin/ordering_cockpit/slow-local', name: 'Slow Local' },
  { to: '/admin/ordering_cockpit/pet-food', name: 'Pets' },
  { to: '/admin/ordering_cockpit/byo', name: 'BYO' },
  { to: '/admin/ordering_cockpit/need-support', name: 'Need support' },
  { to: '/admin/ordering_cockpit/roaster-does-not-fulfill', name: 'Roaster does not fulfill' },
  { to: '/admin/ordering_cockpit/streaming', name: 'Streaming' },
  { to: '/admin/ordering_cockpit/no-data', name: 'No data' },
  { to: '/admin/ordering_cockpit/done', name: 'Done' },
  { to: '/admin/ordering_cockpit/on-the-way', name: 'On the way' },
  { to: '/admin/ordering_cockpit/bag-not-on-scale', name: 'Bag not on scale' },
  { to: '/admin/ordering_cockpit/ai-decision', name: 'AI Luke (0.95)' },
  { to: '/admin/ordering_cockpit/ag1', name: 'AG1' },
];
