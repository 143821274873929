import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetTrackingTypes = createAction('Shipments', 'Get tracking');

export const getTrackingAction = tracking_number => ({
  [RSAA]: {
    endpoint: `/admin/tracking/${tracking_number}`,
    method: 'GET',
    types: [GetTrackingTypes.REQUEST, GetTrackingTypes.SUCCESS, GetTrackingTypes.FAILURE],
  },
});
