import { debounce, pickBy, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, DataLoading } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  status: Yup.string().nullable(true),
  search: Yup.string(),
});

const statuses = {
  0: 'all',
  '-1': 'no',
  1: 'yes',
};

const notificationStatuses = {
  0: 'all',
  '-1': 'no',
  1: 'yes (1)',
  2: 'yes (>1)',
};

const trackingStatuses = {
  null: 'all',
  pre_transit: 'pre_transit',
  pre_transit_1: 'pre_transit_1',
  pre_transit_2: 'pre_transit_2',
  accepted: 'accepted',
  accepted_1: 'accepted_1',
  accepted_2: 'accepted_2',
  in_transit: 'in_transit',
  in_transit_1: 'in_transit_1',
  in_transit_2: 'in_transit_2',
  in_transit_exceptions: 'in_transit_exceptions',
  out_for_delivery: 'out_for_delivery',
  out_for_delivery_1: 'out_for_delivery_1',
  out_for_delivery_2: 'out_for_delivery_2',
  delivered: 'delivered',
  alert: 'alert',
  alert_1: 'alert_1',
  return_to_sender: 'return_to_sender',
};

const FiltersComponent = ({ history, location, hasData, isLoading, isAll }) => {
  const query = useMemo(() => qs.parse(location.search), [location.search, location.pathname]);

  const [filters, setFilters] = useState(query);
  const formRef = useRef();

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(pickBy(filters, v => v && v !== 'null')),
    });
  }, [filters]);

  useEffect(() => {
    if (isEmpty(query)) {
      setFilters({});
      formRef.current.resetForm({ trackingStatus: 'all', ack: 0, notification: 0, status: '', search: '' });
    }
  }, [query]);

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  const initialValues = { trackingStatus: 'all', ack: 0, notification: 0, shipping_status: '', ...filters };

  return (
    <Form
      inline
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {({ resetForm }) => (
        <>
          <Row className="w-100 align-items-center">
            <Col xs="2">
              <Field
                name="ack"
                label="Acknowledged"
                type="select"
                options={statuses}
                onChange={e => onFilterChange('ack', e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Field
                name="notification"
                label="Notification Sent"
                type="select"
                options={notificationStatuses}
                onChange={e => onFilterChange('notification', e.target.value)}
              />
            </Col>
            {!isAll && (
              <Col xs="2">
                <Field
                  name="status"
                  label="Status"
                  type="select"
                  options={{
                    null: 'All',
                    pre_transit: 'pre_transit',
                    accepted: 'accepted',
                    in_transit: 'in_transit',
                    return_to_sender: 'return_to_sender',
                    out_for_delivery: 'out_for_delivery',
                    delivered: 'delivered',
                    alert: 'alert',
                  }}
                  onChange={e => onFilterChange('status', e.target.value)}
                />
              </Col>
            )}
            {isAll && (
              <Col xs="2">
                <Field
                  name="trackingStatus"
                  label="Status"
                  type="select"
                  options={trackingStatuses}
                  onChange={e => onFilterChange('trackingStatus', e.target.value)}
                />
              </Col>
            )}
            <Col xs="2">
              <Field
                name="show_all"
                label="Date fulfilled"
                type="select"
                options={{
                  null: '24 hours ago',
                  all: 'All',
                }}
                onChange={e => onFilterChange('show_all', e.target.value)}
              />
            </Col>
            <Col className="pr-0">
              <Field
                className="w-100 up-label"
                name="search"
                label="Search"
                placeholder={`Search by ${['_id', 'user_id', 'tracking_number'].join(', ')}`}
                onChange={debounce(event => {
                  onFilterChange('search', event.target.value);
                }, 250)}
              />
            </Col>
          </Row>
          {(isLoading || !hasData) && (
            <Row className="w-100 py-4">
              <Col>
                <DataLoading count={hasData ? 1 : 0} isLoading={isLoading}>
                  <div className="text-center py-2">
                    <div className="text-center mb-4">Order tracking is finished 🎉</div>

                    {Object.keys(filters).length > 0 && (
                      <Button
                        size="sm"
                        onClick={() => {
                          setFilters({});
                          resetForm({ ...initialValues });
                        }}
                      >
                        Clear filters
                      </Button>
                    )}
                  </div>
                </DataLoading>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
};

FiltersComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  hasData: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAll: PropTypes.bool,
};
export const Filters = withRouter(FiltersComponent);
