import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Col, Row } from 'reactstrap';
import {
  Form,
  Field,
  SubmitButton,
  AddressInput,
  GoogleMapsLoaded,
  GoogleMapsLoader,
} from '@bottomless/common/components';

const AddressSchema = Yup.object().shape({
  first_name: Yup.string().required('This field is required'),
  last_name: Yup.string().required('This field is required'),
  verifiedAddress: Yup.object()
    .shape({
      street1: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      zip: Yup.string().required('This field is required'),
      state: Yup.string().required('This field is required'),
      street2: Yup.string(),
    })
    .required(),
});

export const UpdateAddressForm = ({ user, onSubmit, onSubmitSuccess }) => {
  return (
    <GoogleMapsLoader>
      <Form
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          verifiedAddress: { state: 'AL', ...(user.verifiedAddress || {}) },
          specialInstructions: user.address_special_instructions,
        }}
        validationSchema={AddressSchema}
        onSubmit={onSubmit}
        onSuccess={onSubmitSuccess}
      >
        {({ isSubmitting, dirty, values, setFieldValue }) => {
          return (
            <>
              <Row>
                <Col xs="12" sm="6">
                  <Field name="first_name" label="First name" disabled />
                </Col>
                <Col xs="12" sm="6">
                  <Field name="last_name" label="Last name" disabled />
                </Col>
              </Row>
              <GoogleMapsLoaded>
                <AddressInput {...{ setFieldValue, values }} />
              </GoogleMapsLoaded>
              <Row>
                <Col xs="12" sm="12">
                  <Field name="specialInstructions" type="textarea" label="Add special instructions" />
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <SubmitButton disabled={!dirty} size="sm" color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </div>
            </>
          );
        }}
      </Form>
    </GoogleMapsLoader>
  );
};

UpdateAddressForm.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    address_special_instructions: PropTypes.string,
    base_id: PropTypes.string.isRequired,
    paidUntil: PropTypes.instanceOf(Date),
    verifiedAddress: PropTypes.object,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
