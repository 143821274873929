import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Option = props => {
  const { children, className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps, data } = props;

  return (
    <div
      style={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      <div>{children}</div>
      <div
        className={classNames('text-sm', {
          'text-secondary': !isSelected,
        })}
      >
        {data.description}
      </div>
    </div>
  );
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.any,
  cx: PropTypes.func.isRequired,
  getStyles: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerRef: PropTypes.func,
  innerProps: PropTypes.any,
  data: PropTypes.object,
};
