import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, has } from 'lodash-es';
import { AtSign, Clock, MapPin, Phone, Zap } from 'react-feather';
import { Link } from 'react-router-dom';
import { Badge, Button, Card } from 'reactstrap';
import { DateFormat, ProductName, Tracking, VariantPrice } from '@bottomless/common/components';
import { datesService } from '@bottomless/common/src/utils';
import { CustomerNotes } from '../../../../components/CustomerNotes/CustomerNotes';

const STATUS_ACTIVE = 'active';
const NOTE_TYPE_SCALE = 'scale_problem';
const PROBLEM_TYPE_SCALE = 'scale';

export const User = ({ user, addToast, setNeedsSupport, onNeedsSupport }) => {
  const [isResolving, setResolving] = useState(false);

  const variant = user.product.product.variants.find(variant => variant._id === user.product.variant);

  const onNeedsSupportSuccess = useCallback(
    data => {
      onNeedsSupport(data);
      addToast('Scale needs support resolved!', 'success');
    },
    [onNeedsSupport, addToast]
  );

  const onSupportResolve = useCallback(async () => {
    setResolving(true);
    const { payload } = await setNeedsSupport({ needs_support: false, type: PROBLEM_TYPE_SCALE });
    await onNeedsSupportSuccess(payload);
    setResolving(false);
  }, [setResolving, setNeedsSupport, onNeedsSupportSuccess]);

  const { order } = user;

  const scaleNotes = useMemo(() => ({ ...user, notes: user.notes }), [user]);

  return (
    <>
      <div className="scale-needs-support-user mb-3">
        <Card body>
          <div className="d-flex justify-content-between mb-1">
            <div>
              <h5 className="mb-0">
                {user.first_name} {user.last_name}
                {user.status && user.status !== STATUS_ACTIVE && (
                  <span className="text-danger text-capitalize"> - {user.status.replace(/_/g, ' ')}</span>
                )}
              </h5>
              <div className="mb-2">
                <Link to={`/admin/users?search=${user._id}`}>
                  <div className="text-secondary text-sm">{user._id}</div>
                </Link>
                {user.vendor_id && <Badge color="info">Vendor: {user.vendor_id.name}</Badge>}
              </div>
              <div className={`d-flex align-items-center text-link text-${aggressionColor(user.ordering_aggression)}`}>
                <Zap size={12} />
                <span className="ml-1">{aggressionName(user.ordering_aggression)}</span>
              </div>
              <div className={`d-flex align-items-center scale-name`}>{user.name && <div>Scale: {user.name}</div>}</div>
              <div className={`d-flex align-items-center`}>
                {user.scale_status && <div>Scale Status: {user.scale_status}</div>}
              </div>
              {user.scale_last_connected && (
                <span className="text-secondary text-sm">
                  Scale last connected on: {datesService.formatDateInSeattle(user.scale_last_connected)}
                </span>
              )}
              <div>
                {user.trial && (
                  <Badge color="info" className="ml-1">
                    Trial
                  </Badge>
                )}
                {user.paused && (
                  <Badge color="warning" className="ml-1">
                    Paused
                  </Badge>
                )}
                {user.dumb_period && (
                  <Badge color="warning" className="ml-1">
                    dumb_period: {user.dumb_period}
                  </Badge>
                )}
                {user.support && user.support.scale.needs_support && (
                  <Badge color="danger" className="ml-1">
                    Scale support needed
                  </Badge>
                )}
              </div>
            </div>
            <div className="text-sm">
              <AtSign size={12} />
              <a
                href={`https://app.frontapp.com/compose?mailto=${user.local.email}`}
                rel="noopener noreferrer"
                target="_blank"
                className="text-muted ml-1"
              >
                {user.local.email}
              </a>
              {user.phone && (
                <div className="d-flex align-items-center text-muted">
                  <Phone size={12} />
                  <a className="ml-1" href={`https://app.frontapp.com/compose?mailto=${user.phone}`}>
                    {user.phone}
                  </a>
                </div>
              )}
              {user.verifiedAddress && (
                <div className="d-flex align-items-center text-link">
                  <MapPin size={12} />
                  <span className="ml-1">
                    {user.verifiedAddress.city}, {user.verifiedAddress.state}
                  </span>
                </div>
              )}
              {user.timezone && (
                <div className="d-flex align-items-center text-link">
                  <Clock size={12} />
                  <span className="ml-1">{user.timezone}</span>
                </div>
              )}
              {user.support?.scale?.reason ? (
                <p className="scale-problem-details">
                  Reason: <span className="title">{user.support.scale.reason}</span>
                </p>
              ) : (
                undefined
              )}
              {user.support?.scale?.comment ? (
                <p className="scale-problem-details comment">
                  Comment: <span className="title">{user.support.scale.comment}</span>
                </p>
              ) : (
                undefined
              )}
            </div>
            <div className={`text-${order ? 'center' : 'right'} product-width`}>
              <div className="text-secondary text-sm">{user.product.product.vendor_name}</div>
              {user.grind && (
                <ProductName
                  product={user.product}
                  personalized={user.personalized}
                  grind={user.grind?.name}
                  isSubproduct={true}
                  productVariant={user.product}
                />
              )}
              <div className="text-primary text-sm">
                {variant.size}oz -{' '}
                {has(user.personalized, 'price_type') ? (
                  get(user.personalized, 'price_type').replace(/^\w/, c => c.toUpperCase())
                ) : (
                  <VariantPrice user={user} variant={variant} />
                )}
              </div>
            </div>
            {order && (
              <div className="text-right">
                {order.subvendor_id && <div className="text-secondary text-sm">{order.subvendor_id.name}</div>}
                {order.subproduct_name && <div className="mb-2">{order.subproduct_name}</div>}
                <div className="text-sm">OrderId: {order._id}</div>
                <div className="text-sm">
                  Override fulfillment date:{' '}
                  {order.override_fulfillment_date ? <DateFormat date={order.override_fulfillment_date} /> : '---'}
                </div>
                {order.tracking_number && (
                  <div className="text-sm">
                    <Tracking
                      number={order.tracking_number}
                      shippingService={order.shipping_service}
                      trackingUrl={order.tracking_url}
                    />
                  </div>
                )}
              </div>
            )}

            <div>
              <div className="text-right">
                {user.support && user.support.scale && user.support.scale.needs_support && (
                  <div>
                    <Button
                      size="sm"
                      color="success"
                      outline
                      type="button"
                      onClick={onSupportResolve}
                      className="mb-1"
                      disabled={isResolving}
                    >
                      {isResolving ? 'Saving...' : 'Resolve support'}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3">
            <CustomerNotes user={scaleNotes} withHeader={false} type={NOTE_TYPE_SCALE} />
          </div>
        </Card>
      </div>
    </>
  );
};

const aggressionName = aggression =>
  ({
    1: 'Never run out',
    2: 'Just right',
    3: 'As fresh as possible',
  }[aggression] ||
  aggression ||
  '---');

const aggressionColor = aggression =>
  ({
    1: 'danger',
    2: 'primary',
    3: 'success',
  }[aggression]);

User.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string,
    visible: PropTypes.bool,
    error: PropTypes.object,
    status: PropTypes.string,
    trial: PropTypes.bool,
    paused: PropTypes.bool,
    dumb_period: PropTypes.number,
    grind: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    pending_orders: PropTypes.array,
    vendor_id: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    scale_last_connected: PropTypes.string,
    scale_status: PropTypes.string,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
        vendor_id: PropTypes.object,
      }),
      variant: PropTypes.string.isRequired,
    }).isRequired,
    verifiedAddress: PropTypes.shape({
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }).isRequired,
    phone: PropTypes.string,
    timezone: PropTypes.string,
    ordering_aggression: PropTypes.number,
    order: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      tracking_number: PropTypes.string,
      subproduct_name: PropTypes.string,
      shipping_service: PropTypes.string,
      tracking_url: PropTypes.string,
      override_fulfillment_date: PropTypes.string,
      subvendor_id: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
    has_future_order: PropTypes.bool,
    notes: PropTypes.array,
    personalized: PropTypes.shape({
      price_type: PropTypes.string,
    }),
    selectedVendor: PropTypes.string,
    support: PropTypes.shape({
      order: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
      scale: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
        reason: PropTypes.string,
        comment: PropTypes.string,
      }),
    }),
    order_gen_status: PropTypes.string,
  }).isRequired,
  addToast: PropTypes.func.isRequired,
  setNeedsSupport: PropTypes.func.isRequired,
  onNeedsSupport: PropTypes.func.isRequired,
};

User.defaultProps = {
  addToast: () => {},
  user: {
    name: '',
    support: {
      order: {},
      scale: {},
    },
  },
};
