import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetScaleShipmentTypes = createAction('Admin scale shipment', 'Get shipments');

export const getShipmentsAction = params => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetScaleShipmentTypes.REQUEST, GetScaleShipmentTypes.SUCCESS, GetScaleShipmentTypes.FAILURE],
  },
});

export const SyncScaleShipmentStatusTypes = createAction('Admin scale shipment', 'Sync status');

export const syncShipmentStatusAction = trackingNumber => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/sync/${trackingNumber}`,
    method: 'POST',
    types: [
      SyncScaleShipmentStatusTypes.REQUEST,
      SyncScaleShipmentStatusTypes.SUCCESS,
      SyncScaleShipmentStatusTypes.FAILURE,
    ],
  },
});

export const ChangeScaleShipmentStatusTypes = createAction('Admin scale shipment', 'Change status');

export const changeShipmentStatusAction = ({ _id, status }) => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/${_id}`,
    method: 'PATCH',
    body: {
      status,
    },
    types: [
      ChangeScaleShipmentStatusTypes.REQUEST,
      ChangeScaleShipmentStatusTypes.SUCCESS,
      ChangeScaleShipmentStatusTypes.FAILURE,
    ],
  },
});

export const PurchaseScaleShipmentTypes = createAction('Admin scale shipment', 'Purchase label');

export const purchaseScaleShipmentAction = (_id, data) => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/${_id}/purchase`,
    method: 'POST',
    body: data,
    types: [PurchaseScaleShipmentTypes.REQUEST, PurchaseScaleShipmentTypes.SUCCESS, PurchaseScaleShipmentTypes.FAILURE],
  },
});

export const UpdateTrackingNumberTypes = createAction('Admin scale shipment', 'Update tracking number');

export const updateTrackingNumberAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/${id}/tracking`,
    method: 'PATCH',
    body: data,
    types: [UpdateTrackingNumberTypes.REQUEST, UpdateTrackingNumberTypes.SUCCESS, UpdateTrackingNumberTypes.FAILURE],
  },
});

export const CreateBatchTypes = createAction('Admin scale shipment', 'Create batch');

export const createBatchAction = data => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/batch`,
    method: 'POST',
    body: data,
    types: [CreateBatchTypes.REQUEST, CreateBatchTypes.SUCCESS, CreateBatchTypes.FAILURE],
  },
});

export const GetBatchTypes = createAction('Admin scale shipment', 'Get shipping batch');

export const getBatchAction = id => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/batch/${id}`,
    method: 'GET',
    types: [GetBatchTypes.REQUEST, GetBatchTypes.SUCCESS, GetBatchTypes.FAILURE],
  },
});

export const CreateScanFormTypes = createAction('Admin scale shipment', 'Create scan form');

export const createScanFormAction = id => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/batch/${id}/scan-form`,
    method: 'POST',
    types: [CreateScanFormTypes.REQUEST, CreateScanFormTypes.SUCCESS, CreateScanFormTypes.FAILURE],
  },
});

export const GetScaleShipmentTypesv2 = createAction('Admin scale shipment', 'Get shipments v2');

export const getShipmentsActionv2 = params => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/v2/${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetScaleShipmentTypesv2.REQUEST, GetScaleShipmentTypesv2.SUCCESS, GetScaleShipmentTypesv2.FAILURE],
  },
});

export const GetScaleShipmentsTrackingTypes = createAction('Admin scale shipment', 'Get shipments tracking');

export const getShipmentsTrackingAction = params => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/tracking/${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetScaleShipmentsTrackingTypes.REQUEST,
      GetScaleShipmentsTrackingTypes.SUCCESS,
      GetScaleShipmentsTrackingTypes.FAILURE,
    ],
  },
});

export const AcknowledgeScaleShipmentTypes = createAction('Admin scale shipment', 'Acknowledge Scale Shipment');

export const acknowledgeScaleShipmentAction = id => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/${id}/acknowledge`,
    method: 'POST',
    types: [
      AcknowledgeScaleShipmentTypes.REQUEST,
      AcknowledgeScaleShipmentTypes.SUCCESS,
      AcknowledgeScaleShipmentTypes.FAILURE,
    ],
  },
});

export const SetShipmentToArrivedActionTypes = createAction('Admin scale shipment', 'Set Scale Shipment to arrived');

export const setShipmentToArrivedAction = id => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/${id}/arrived`,
    method: 'POST',
    types: [
      SetShipmentToArrivedActionTypes.REQUEST,
      SetShipmentToArrivedActionTypes.SUCCESS,
      SetShipmentToArrivedActionTypes.FAILURE,
    ],
  },
});

export const ReplaceShipmentActionTypes = createAction('Admin scale shipment', 'Replace Shipment');

export const replaceShipmentAction = id => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/${id}/replace`,
    method: 'POST',
    types: [ReplaceShipmentActionTypes.REQUEST, ReplaceShipmentActionTypes.SUCCESS, ReplaceShipmentActionTypes.FAILURE],
  },
});

export const AcknowledgeAllScaleShipmentTypes = createAction('Admin scale shipment', 'Acknowledge All Scale Shipment');

export const acknowledgeAllScaleShipmentAction = () => ({
  [RSAA]: {
    endpoint: `admin/scale-shipments/acknowledge`,
    method: 'POST',
    types: [
      AcknowledgeAllScaleShipmentTypes.REQUEST,
      AcknowledgeAllScaleShipmentTypes.SUCCESS,
      AcknowledgeAllScaleShipmentTypes.FAILURE,
    ],
  },
});
