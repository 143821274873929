import * as qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetDisconnectedUsersTypes = createAction('Admin users', 'Get all disconnected');

export const getDisconnectedUsersAction = () => ({
  [RSAA]: {
    endpoint: 'admin/users/disconnected',
    method: 'GET',
    types: [GetDisconnectedUsersTypes.REQUEST, GetDisconnectedUsersTypes.SUCCESS, GetDisconnectedUsersTypes.FAILURE],
  },
});

export const GetPendingDisconnectedUsersTypes = createAction('Admin users', 'Get pending disconnected');

export const getPendingDisconnectedUsersAction = () => ({
  [RSAA]: {
    endpoint: 'admin/users/disconnected?pending=true',
    method: 'GET',
    types: [
      GetPendingDisconnectedUsersTypes.REQUEST,
      GetPendingDisconnectedUsersTypes.SUCCESS,
      GetPendingDisconnectedUsersTypes.FAILURE,
    ],
  },
});

export const GetConnectedFixedUsersTypes = createAction('Admin users', 'Get all connected fixed');

export const getConnectedFixedUsersAction = () => ({
  [RSAA]: {
    endpoint: 'admin/users/connected/fixed',
    method: 'GET',
    types: [
      GetConnectedFixedUsersTypes.REQUEST,
      GetConnectedFixedUsersTypes.SUCCESS,
      GetConnectedFixedUsersTypes.FAILURE,
    ],
  },
});

export const GetUserTypes = createAction('Admin users', 'Get');

export const getUserAction = id => ({
  [RSAA]: {
    endpoint: `admin/users/${id}`,
    method: 'GET',
    types: [GetUserTypes.REQUEST, GetUserTypes.SUCCESS, GetUserTypes.FAILURE],
  },
});

export const SetDumbPeriodTypes = createAction('Admin users', 'Set dumb period');

export const setDumbPeriodAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/dumb-period`,
    method: 'PATCH',
    body: data,
    types: [SetDumbPeriodTypes.REQUEST, SetDumbPeriodTypes.SUCCESS, SetDumbPeriodTypes.FAILURE],
  },
});

export const SetTareTypes = createAction('Admin users', 'Set tare');

export const setTareAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/tare`,
    method: 'PATCH',
    body: data,
    types: [SetTareTypes.REQUEST, SetTareTypes.SUCCESS, SetTareTypes.FAILURE],
  },
});

export const GetUsersTypes = createAction('Admin users', 'Get users');

export const getUsersAction = params => ({
  [RSAA]: {
    endpoint: `admin/users${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetUsersTypes.REQUEST, GetUsersTypes.SUCCESS, GetUsersTypes.FAILURE],
  },
});

export const SearchUsersTypes = createAction('Admin users', 'Search users');

export const searchUsersAction = params => ({
  [RSAA]: {
    endpoint: `admin/users${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [SearchUsersTypes.REQUEST, SearchUsersTypes.SUCCESS, SearchUsersTypes.FAILURE],
  },
});

export const AddCreditsTypes = createAction('Admin users', 'Add credits');

export const addCreditsAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/credits`,
    method: 'POST',
    body: data,
    types: [AddCreditsTypes.REQUEST, AddCreditsTypes.SUCCESS, AddCreditsTypes.FAILURE],
  },
});

export const CreateScaleShipmentTypes = createAction('Admin users', 'Create scale shipment');

export const createScaleShipmentAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/scale-shipments`,
    method: 'POST',
    body: data,
    types: [CreateScaleShipmentTypes.REQUEST, CreateScaleShipmentTypes.SUCCESS, CreateScaleShipmentTypes.FAILURE],
  },
});

export const CreateMagicLinkTypes = createAction('Admin users', 'Create magic link');

export const createMagicLinkAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/magic-link`,
    method: 'POST',
    body: data,
    types: [CreateMagicLinkTypes.REQUEST, CreateMagicLinkTypes.SUCCESS, CreateMagicLinkTypes.FAILURE],
  },
});

export const AddMonthsTypes = createAction('Admin users', 'Add months');

export const addMonthsAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/months`,
    method: 'POST',
    body: data,
    types: [AddMonthsTypes.REQUEST, AddMonthsTypes.SUCCESS, AddMonthsTypes.FAILURE],
  },
});

export const UpdateGrindTypes = createAction('Admin users', 'Update grind');

export const updateGrindAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/grind`,
    method: 'POST',
    body: data,
    types: [UpdateGrindTypes.REQUEST, UpdateGrindTypes.SUCCESS, UpdateGrindTypes.FAILURE],
  },
});

export const UpdatePricingRuleType = createAction('Admin users', 'Update pricing rule');

export const updatePricingRuleAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/pricing-rule`,
    method: 'POST',
    body: data,
    types: [UpdatePricingRuleType.REQUEST, UpdatePricingRuleType.SUCCESS, UpdatePricingRuleType.FAILURE],
  },
});

export const ReOpenUserAccountTypes = createAction('Admin Users', 'Re Open account');

export const reOpenAccountAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/re-open-account`,
    method: 'POST',
    body: data,
    types: [ReOpenUserAccountTypes.REQUEST, ReOpenUserAccountTypes.SUCCESS, ReOpenUserAccountTypes.FAILURE],
  },
});

export const CloseUserAccountTypes = createAction('Admin Users', 'Close account');

export const closeAccountAction = id => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/close-account`,
    method: 'POST',
    types: [CloseUserAccountTypes.REQUEST, CloseUserAccountTypes.SUCCESS, CloseUserAccountTypes.FAILURE],
  },
});

export const HibernateUserAccountTypes = createAction('Admin Users', 'Hibernate account');

export const hibernateAccountAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/hibernate-account`,
    body: data,
    method: 'POST',
    types: [HibernateUserAccountTypes.REQUEST, HibernateUserAccountTypes.SUCCESS, HibernateUserAccountTypes.FAILURE],
  },
});

export const GiftOrderEmailTypes = createAction('Admin Users', 'Gift recipient email');

export const giftOrderEmailAction = ({ userId }) => ({
  [RSAA]: {
    endpoint: `admin/gifts/notification`,
    method: 'POST',
    body: {
      user_id: userId,
    },
    types: [GiftOrderEmailTypes.REQUEST, GiftOrderEmailTypes.SUCCESS, GiftOrderEmailTypes.FAILURE],
  },
});

export const UpdateEmailTypes = createAction('Admin Users', 'Update Email');

export const updateEmailAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/update-email`,
    method: 'POST',
    body: data,
    types: [UpdateEmailTypes.REQUEST, UpdateEmailTypes.SUCCESS, UpdateEmailTypes.FAILURE],
  },
});

export const PauseUserAccountTypes = createAction('Admin Users', 'Pause user account');

export const pauseUserAccountAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/pause-account`,
    method: 'PATCH',
    body: data,
    types: [PauseUserAccountTypes.REQUEST, PauseUserAccountTypes.SUCCESS, PauseUserAccountTypes.FAILURE],
  },
});

export const SetUserReferrerTypes = createAction('Admin Users', 'Set user referrer');

export const setUserReferrerAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/set-referral`,
    method: 'PATCH',
    body: data,
    types: [SetUserReferrerTypes.REQUEST, SetUserReferrerTypes.SUCCESS, SetUserReferrerTypes.FAILURE],
  },
});

export const UpdateUserDumbPeriodTypes = createAction('Admin Users', 'User Dumb Period Updated');

export const updateUserDumbPeriodAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/update-dumb-period`,
    method: 'PATCH',
    body: data,
    types: [UpdateUserDumbPeriodTypes.REQUEST, UpdateUserDumbPeriodTypes.SUCCESS, UpdateUserDumbPeriodTypes.FAILURE],
  },
});

export const UpdateSocialMediaTypes = createAction('Admin Users', 'Update Socia media');

export const updateSocialMediaAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/social-media`,
    method: 'PATCH',
    body: data,
    types: [UpdateSocialMediaTypes.REQUEST, UpdateSocialMediaTypes.SUCCESS, UpdateSocialMediaTypes.FAILURE],
  },
});

export const AttributeInfluencerTypes = createAction('Admin Users', 'Attribute Influencer');

export const attributeInfluencerAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/attribute-influencer`,
    method: 'PATCH',
    body: data,
    types: [AttributeInfluencerTypes.REQUEST, AttributeInfluencerTypes.SUCCESS, AttributeInfluencerTypes.FAILURE],
  },
});

export const ParseInfluencersTypes = createAction('Admin Users', 'Parse influencers');

export const parseInfluencersAction = data => ({
  [RSAA]: {
    endpoint: `admin/users/parse-influencers`,
    method: 'POST',
    body: data,
    types: [ParseInfluencersTypes.REQUEST, ParseInfluencersTypes.SUCCESS, ParseInfluencersTypes.FAILURE],
  },
});

export const AttributeInfluencersTypes = createAction('Admin Users', 'Attribute Influencers');

export const attributeInfluencersAction = data => ({
  [RSAA]: {
    endpoint: `admin/users/attribute-influencers`,
    method: 'POST',
    body: data,
    types: [AttributeInfluencersTypes.REQUEST, AttributeInfluencersTypes.SUCCESS, AttributeInfluencersTypes.FAILURE],
  },
});

export const GenerateInfluencerLinkTypes = createAction('Admin Users', 'Generate Influencer Link');

export const generateInfluencerLinkAction = data => ({
  [RSAA]: {
    endpoint: `admin/users/influencer`,
    method: 'POST',
    body: data,
    types: [
      GenerateInfluencerLinkTypes.REQUEST,
      GenerateInfluencerLinkTypes.SUCCESS,
      GenerateInfluencerLinkTypes.FAILURE,
    ],
  },
});

export const SetNeedsSupportTypes = createAction('Admin Users', 'Set needs support');

export const setNeedsSupportAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/needs-support`,
    method: 'PATCH',
    body: data,
    types: [SetNeedsSupportTypes.REQUEST, SetNeedsSupportTypes.SUCCESS, SetNeedsSupportTypes.FAILURE],
  },
});

export const GetCreditsTypes = createAction('Admin Users', 'Get credits');

export const getCreditsAction = params => ({
  [RSAA]: {
    endpoint: `admin/credits${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetCreditsTypes.REQUEST, GetCreditsTypes.SUCCESS, GetCreditsTypes.FAILURE],
  },
});

export const GetCreditypes = createAction('Admin Users', 'Get credit');

export const getCreditAction = id => ({
  [RSAA]: {
    endpoint: `admin/credits/${id}`,
    method: 'GET',
    types: [GetCreditypes.REQUEST, GetCreditypes.SUCCESS, GetCreditypes.FAILURE],
  },
});

export const UpdateCreditTypes = createAction('Admin Users', 'Update credit');

export const updateCreditAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/credits/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateCreditTypes.REQUEST, UpdateCreditTypes.SUCCESS, UpdateCreditTypes.FAILURE],
  },
});

export const GetUserLastOrderTypes = createAction('Admin Users', 'Get User Last Order');

export const getUserLastOrderAction = id => ({
  [RSAA]: {
    endpoint: `admin/users/last_order/${id}`,
    method: 'GET',
    types: [GetUserLastOrderTypes.REQUEST, GetUserLastOrderTypes.SUCCESS, GetUserLastOrderTypes.FAILURE],
  },
});

export const GetUserChargesTypes = createAction('Admin Users', 'Get User Charges');

export const getUserChargesAction = id => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/charges`,
    method: 'GET',
    types: [GetUserChargesTypes.REQUEST, GetUserChargesTypes.SUCCESS, GetUserChargesTypes.FAILURE],
  },
});

export const GetOrdersFrequencyTypes = createAction('Admin Users', 'Get orders frequency');

export const getOrdersFrequencyAction = id => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/orders-frequency`,
    method: 'GET',
    types: [GetOrdersFrequencyTypes.REQUEST, GetOrdersFrequencyTypes.SUCCESS, GetOrdersFrequencyTypes.FAILURE],
  },
});

export const SetOrdergenStatusTypes = createAction('Admin Users', 'Set ordergen status');

export const setOrdergenStatusAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/ordergen-status`,
    method: 'PATCH',
    body: data,
    types: [SetOrdergenStatusTypes.REQUEST, SetOrdergenStatusTypes.SUCCESS, SetOrdergenStatusTypes.FAILURE],
  },
});

export const RefundOrderChargesTypes = createAction('Admin Users', 'Refund User Order Charges');

export const refundOrderChargesAction = (userId, orderId, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${userId}/orders/${orderId}/refund`,
    method: 'POST',
    body: data,
    types: [RefundOrderChargesTypes.REQUEST, RefundOrderChargesTypes.SUCCESS, RefundOrderChargesTypes.FAILURE],
  },
});

export const RefundMembershipChargesTypes = createAction('Admin Users', 'Refund User Membership Charges');

export const refundMembershipChargesAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/membership-charges/refund`,
    method: 'POST',
    body: data,
    types: [
      RefundMembershipChargesTypes.REQUEST,
      RefundMembershipChargesTypes.SUCCESS,
      RefundMembershipChargesTypes.FAILURE,
    ],
  },
});

export const DisconnectScaleTypes = createAction('Admin Users', 'Disconnect scale');

export const disconnectScaleAction = (id, action) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/disconnect-scale/${action}`,
    method: 'PATCH',
    types: [DisconnectScaleTypes.REQUEST, DisconnectScaleTypes.SUCCESS, DisconnectScaleTypes.FAILURE],
  },
});

export const DisableScaleNotificationsTypes = createAction('Admin Users', 'Disable scale notifications');

export const disableScaleNotificationsAction = id => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/disable-scale-notifications`,
    method: 'PATCH',
    types: [
      DisableScaleNotificationsTypes.REQUEST,
      DisableScaleNotificationsTypes.SUCCESS,
      DisableScaleNotificationsTypes.FAILURE,
    ],
  },
});

export const UpdateAddressTypes = createAction('Admin Users', 'Update Address');

export const updateAddressAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/update-address`,
    method: 'POST',
    body: data,
    types: [UpdateAddressTypes.REQUEST, UpdateAddressTypes.SUCCESS, UpdateAddressTypes.FAILURE],
  },
});

export const UpdateUserProductTypes = createAction('Admin Users', 'Update Address');

export const updateUserProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/update-user-product`,
    method: 'POST',
    body: data,
    types: [UpdateUserProductTypes.REQUEST, UpdateUserProductTypes.SUCCESS, UpdateUserProductTypes.FAILURE],
  },
});

export const UpdatePhoneTypes = createAction('Admin Users', 'Update Phone');

export const updatePhoneAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/update-phone`,
    method: 'POST',
    body: data,
    types: [UpdatePhoneTypes.REQUEST, UpdatePhoneTypes.SUCCESS, UpdatePhoneTypes.FAILURE],
  },
});

export const UpdateUserShopifyNoteTypes = createAction('Admin Users', 'User Shopify Note Updated');

export const updateUserShopifyNoteAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/users/${id}/shopify-note`,
    method: 'PATCH',
    body: data,
    types: [UpdateUserShopifyNoteTypes.REQUEST, UpdateUserShopifyNoteTypes.SUCCESS, UpdateUserShopifyNoteTypes.FAILURE],
  },
});
