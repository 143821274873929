import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DataLoading, Toasts } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getDisconnectedUsersAction, setDumbPeriodAction } from '../../../../store/admin/user';
import { User } from './components/User';
import { tabs } from './components/Tabs';

const DisconnectedPageComponent = ({ getDisconnectedUsers, setDumbPeriod, addToast }) => {
  const [users, setUsers] = useState(null);

  useDataEffect(getDisconnectedUsers, setUsers);

  return (
    <PanelPage title="Disconnected users" tabs={tabs}>
      <DataLoading count={(users || { length: 0 }).length} isLoading={users === null} />
      {users &&
        users.map(user => (
          <User user={user} key={user._id} setDumbPeriod={data => setDumbPeriod(user._id, data)} addToast={addToast} />
        ))}
      <Toasts />
    </PanelPage>
  );
};

DisconnectedPageComponent.propTypes = {
  getDisconnectedUsers: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const DisconnectedPage = connect(null, dispatch => ({
  getDisconnectedUsers: () => dispatch(getDisconnectedUsersAction()),
  setDumbPeriod: (id, data) => dispatch(setDumbPeriodAction(id, data)),
  addToast: data => dispatch(addToastAction(data)),
}))(DisconnectedPageComponent);
