import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetScaleByBaseIdTypes = createAction('Admin scales', 'Get by base id');

export const getScaleByBaseIdAction = baseId => ({
  [RSAA]: {
    endpoint: `admin/scales/${baseId}/base-id`,
    method: 'GET',
    types: [GetScaleByBaseIdTypes.REQUEST, GetScaleByBaseIdTypes.SUCCESS, GetScaleByBaseIdTypes.FAILURE],
  },
});
