import React from 'react';
import PropTypes from 'prop-types';
import { Field, OneLineAddress } from '@bottomless/common/components';

export const BuyerInformation = ({ setFieldValue, values, errors, shouldShipScaleToBuyer, address }) => (
  <div className="mb-3 get-started-item">
    <div className="d-flex align-items-end justify-content-between">
      <div className="d-flex align-items-center mb-3">
        <h2>Buyer information</h2>
      </div>
    </div>

    <div>
      <div className="buyer-name">
        <Field name="buyer.first_name" label="Your First Name" />
        <Field name="buyer.last_name" label="Your Last Name" />
      </div>
      <Field name="buyer.email" type="email" label="E-mail Address" />
      <Field name="buyer.phone" type="phone" label="Phone (Optional)" />
      {(address && address.current) || shouldShipScaleToBuyer ? (
        <div className="buyer-address">
          <OneLineAddress {...{ setFieldValue, values, errors }} fieldPrefix="buyer." />
        </div>
      ) : (
        ''
      )}
    </div>
  </div>
);

BuyerInformation.propTypes = {
  shouldShipScaleToBuyer: PropTypes.bool,
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  address: PropTypes.object,
};

BuyerInformation.defaultProps = {
  shouldShipScaleToBuyer: false,
  address: null,
};
