import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetMeTypes = createAction('Users', 'Get me');

export const getMeAction = () => ({
  [RSAA]: {
    endpoint: 'users/me',
    method: 'GET',
    types: [GetMeTypes.REQUEST, GetMeTypes.SUCCESS, GetMeTypes.FAILURE],
  },
});
