import React, { memo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';

const schema = Yup.object().shape({
  refundAmount: Yup.string().required('Refund amount is required'),
  reason: Yup.string().required('Reason is required'),
  paidUntil: Yup.string().required('Paid until date is required'),
  ids: Yup.array().required('An error occurred, please try again later'),
});

const MembershipRefundModalComponent = ({
  isMembershipRefundModalOpen,
  toggleMembershipRefundModal,
  charges,
  refundMembership,
  onRefundMembershipSuccess,
  formatCents,
}) => {
  const chargeIds = charges && charges.map(c => c.id);
  const totalRefundAmount = charges && charges.reduce((acc, currentValue) => acc + currentValue.total_paid, 0);

  return (
    <Modal
      isOpen={isMembershipRefundModalOpen}
      toggle={toggleMembershipRefundModal}
      size="lg"
      className="membership-refund-modal"
    >
      <ModalHeader toggle={toggleMembershipRefundModal}>Refund Membership</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{
            ids: chargeIds,
            shouldNotify: false,
            reason: 'Customer Requested',
            refundAmount: formatCents(totalRefundAmount),
            shouldSwitchToLite: false,
          }}
          validationSchema={schema}
          onSubmit={refundMembership}
          onSuccess={onRefundMembershipSuccess}
        >
          {({ isSubmitting }) => (
            <>
              <Field type="hidden" name="ids" disabled />
              <Field name="refundAmount" label="Total Refund Amount" disabled />
              <Field name="paidUntil" type="date" label="Updated Paid Until Date" />
              <Field name="reason" label="Reason" disabled />
              <Field name="details" type="textarea" label="Add more details about this refund" />
              <Checkbox name="shouldNotify" label="Notify customer?" />
              <Checkbox name="shouldSwitchToLite" label="Switch customer to Lite at end of paid period?" />
              <div className="mt-2 d-flex justify-content-end">
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Confirm
                </SubmitButton>
              </div>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

MembershipRefundModalComponent.propTypes = {
  toggleMembershipRefundModal: PropTypes.func.isRequired,
  formatCents: PropTypes.func.isRequired,
  refundMembership: PropTypes.func.isRequired,
  onRefundMembershipSuccess: PropTypes.func.isRequired,
  charges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      total_paid: PropTypes.number.isRequired,
      date_charged: PropTypes.string.isRequired,
      status: PropTypes.string,
    })
  ),
  isMembershipRefundModalOpen: PropTypes.bool,
};

MembershipRefundModalComponent.defaultProps = {
  charges: {
    status: '',
  },
  isMembershipRefundModalOpen: false,
};

export const MembershipRefundModal = memo(MembershipRefundModalComponent);
