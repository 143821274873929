import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPaidFulfillment } from '../../../store/admin/order';
import { FulfillmentPage } from './Fulfillment';

const PaidFulfillmentPageComponent = ({ getOrders }) => <FulfillmentPage getOrders={getOrders} />;

PaidFulfillmentPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
};

export const PaidFulfillmentPage = connect(null, dispatch => ({
  getOrders: () => dispatch(getPaidFulfillment()),
}))(PaidFulfillmentPageComponent);
