import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Edit } from 'react-feather';
import { Card, CardBody, CardFooter, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Address, DateFormat, Image, VariantPrice } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { EditForm } from '../../components/EditForm';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { convertOzToLbAndOz } from '@bottomless/common/src/utils';

export const ProductVariant = ({ productVariant: rawProductVariant, updateProduct }) => {
  const [productVariant, setProductVariant] = useState(rawProductVariant);
  const [isModalOpen, toggleModal] = useToggle();
  const variant = useMemo(() => productVariant.productData.variants.find(v => v._id === productVariant._id), [
    productVariant,
  ]);

  return (
    <>
      <Card className="mb-5">
        <CardBody>
          <Row>
            <Col xs={6}>
              <a
                href={productVariant.productData.image_src || productVariant.productData.small_image_src}
                className="card-media"
                target="_blank"
                rel="noopener noreferrer"
              >
                {productVariant.productData.small_image_src && (
                  <Image
                    src={productVariant.productData.small_image_src}
                    alt={productVariant.productData.small_image_src}
                    maxWidth="120"
                    maxHeight="120"
                  />
                )}
                {!productVariant.productData.small_image_src && productVariant.productData.image_src && (
                  <Image
                    src={productVariant.productData.image_src}
                    alt={productVariant.productData.image_src}
                    maxWidth="120"
                    maxHeight="120"
                  />
                )}
              </a>
            </Col>
            <Col xs={6}>
              <div className="d-flex justify-content-end">
                <div className="mr-auto">
                  <div>{productVariant.productData.name}</div>
                  <div className="text-primary text-sm">
                    <span>Size: {convertOzToLbAndOz(variant.size)} -</span> <VariantPrice variant={variant} />
                  </div>
                  <div>
                    {productVariant.productData.eCommerce?.productUrl && (
                      <a
                        className="text-success"
                        href={productVariant.productData.eCommerce.productUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Product URL
                      </a>
                    )}
                  </div>
                  <div>
                    E-Commerce Name:{' '}
                    <span className="text-primary">{productVariant.productData.eCommerce?.name || '---'}</span>
                  </div>
                  <div>
                    E-Commerce Type:{' '}
                    <span className="text-primary">{productVariant.productData.eCommerce?.type || '---'}</span>
                  </div>
                </div>
                <div></div>
                <div className="ml-3"></div>
                <div className="ml-4">
                  <Edit size="20" onClick={toggleModal} />
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <h6>Orders</h6>
          {productVariant.orders.map(order => (
            <div key={order._id}>
              <hr />
              <Row>
                <Col xs={4}>
                  <div>{order._id}</div>
                  <Link to={`/admin/ordering_cockpit/${order.user_id._id}`}>
                    <span className="text-primary">
                      {order.user_id.first_name} {order.user_id.last_name}
                    </span>
                  </Link>
                </Col>
                <Col xs={4} className="text-center">
                  <Address address={order.user_id.verifiedAddress} inline />
                </Col>
                <Col xs={4} className="text-right">
                  <div>
                    Date initiated:{' '}
                    <span className="text-secondary">
                      <DateFormat date={order.date_initiated} />
                    </span>
                  </div>
                  <div>
                    Date fulfilled:{' '}
                    <span className="text-secondary">
                      {order.date_fulfilled ? <DateFormat date={order.date_fulfilled} /> : '---'}
                    </span>
                  </div>
                  <div>
                    Override fulfillment:{' '}
                    <span className="text-secondary">
                      {order.override_fulfillment_date ? (
                        <DateFormat date={order.override_fulfillment_date} correctTimezone />
                      ) : (
                        '---'
                      )}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </CardFooter>
      </Card>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          <div className="text-sm text-secondary">{productVariant.productData._id}</div>
          <div className="text-sm text-secondary">{productVariant.productData.name}</div>
        </ModalHeader>
        <ModalBody>
          <EditForm
            product={productVariant.productData}
            variant={variant}
            updateProduct={updateProduct}
            toggleModal={toggleModal}
            setProduct={productData =>
              setProductVariant(prevState => ({
                ...prevState,
                productData,
              }))
            }
          />
        </ModalBody>
      </Modal>
    </>
  );
};

ProductVariant.propTypes = {
  productVariant: PropTypes.shape({
    orders: PropTypes.arrayOf({
      user_id: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        verifiedAddress: PropTypes.object,
        _id: PropTypes.string,
      }),
      _id: PropTypes.string,
      date_fulfilled: PropTypes.string,
      date_initiated: PropTypes.string,
    }),
    _id: PropTypes.string,
    productData: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      image_src: PropTypes.string,
      small_image_src: PropTypes.string,
      status: PropTypes.string,
      active: PropTypes.bool,
      hidden: PropTypes.bool,
      date_added: PropTypes.string,
      updated_at: PropTypes.string,
      variants: PropTypes.array,
      eCommerce: PropTypes.shape({
        productUrl: PropTypes.string,
      }),
    }),
  }).isRequired,
  updateProduct: PropTypes.func.isRequired,
};
