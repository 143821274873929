import { debounce, get, pickBy } from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, DataLoading, Pagination } from '@bottomless/common/components';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getProductsAction } from '../../../../store/admin/product';
import { getVendorsAction } from '../../../../store/admin/vendor';
import { Product } from './componenets/Product';
import './Products.scss';

const PRICE_POINTS = { '': '---', basic: 'Basic', standard: 'Standard', premium: 'Premium' };

const Schema = Yup.object().shape({
  vendor_id: Yup.string(),
  status: Yup.string(),
  rotationPrice: Yup.string(),
  search: Yup.string(),
});

const ProductsPageComponent = ({ getVendors, getProducts, history, location: { pathname, search } }) => {
  const query = qs.parse(search);
  const formRef = useRef();
  const [products, setProducts] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [filters, setFilters] = useState(query);

  useConditionalDataEffect(!vendors, getVendors, setVendors);

  useDataEffect(getProducts, setProducts, filters);

  const vendorOptions =
    !vendors || !vendors.length
      ? {}
      : vendors.reduce((all, { _id, name }) => ({ ...all, [_id]: name }), { null: '---' });

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  useEffect(() => {
    history.push({ pathname, search: qs.stringify(pickBy(filters, v => v && v !== 'null')) });
  }, [filters, history, pathname]);

  const onClearClick = () => {
    setFilters({});
    formRef.current.resetForm({ rotationPrice: '', status: '', search: '', vendor: '', hidden: '' });
  };

  const Filters = (
    <Form
      inline
      innerRef={formRef}
      initialValues={{ ...filters }}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {() => (
        <>
          <Row className="w-100 align-items-center">
            <Col xs="2">
              <Field
                name="status"
                label="Status"
                type="select"
                options={{
                  null: '---',
                  active: 'Active',
                  archived: 'Archived',
                  draft: 'Draft',
                }}
                onChange={e => onFilterChange('status', e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Field
                name="vendor"
                type="select"
                label="Vendor"
                options={vendorOptions}
                onChange={e => onFilterChange('vendor_id', e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Field
                name="rotationPrice"
                type="select"
                label="Price point"
                options={PRICE_POINTS}
                onChange={e => onFilterChange('rotationPrice', e.target.value)}
              />
            </Col>
            <Col xs="2">
              <Field
                name="hidden"
                type="select"
                label="Hidden"
                options={{ null: '---', true: 'Yes', false: 'No' }}
                onChange={e => onFilterChange('hidden', e.target.value)}
              />
            </Col>
            <Col className="pr-0">
              <Field
                className="w-100"
                name="search"
                label="Search"
                onChange={debounce(event => {
                  onFilterChange('search', event.target.value);
                }, 200)}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );

  return (
    <PanelPage
      title="Products"
      heading={
        <>
          <Link className="btn btn-success" to="/admin/products/create">
            Create new
          </Link>
        </>
      }
    >
      <Row className="mb-4">
        <Col xs="12">{Filters}</Col>
      </Row>
      <DataLoading count={(get(products, 'docs') || { length: 0 }).length} isLoading={products === null}>
        <div className="text-center">
          <span className="d-block mb-3">No data matching selected filters.</span>
          <Button size="sm" onClick={onClearClick}>
            Clear filters
          </Button>
        </div>
      </DataLoading>
      {products && (
        <>
          {products.docs.map(product => (
            <Product product={product} key={product._id} back={`admin/products`} />
          ))}
          <div className="mt-2">
            <Pagination collection={products} onPageChange={getProducts} setter={setProducts} />
          </div>
        </>
      )}
    </PanelPage>
  );
};

ProductsPageComponent.propTypes = {
  getVendors: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const ProductsPage = connect(null, (dispatch, { location: { search } }) => ({
  getVendors: () => dispatch(getVendorsAction()),
  getProducts: () => dispatch(getProductsAction({ ...qs.parse(search), limit: 200 })),
}))(ProductsPageComponent);
