import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const SendPilotInviteTypes = createAction('Admin Users', 'Hibernate account');

export const sendPilotInviteAction = (id, data) => ({
  [RSAA]: {
    endpoint: `admin/pilot/${id}/invite`,
    body: data,
    method: 'POST',
    types: [SendPilotInviteTypes.REQUEST, SendPilotInviteTypes.SUCCESS, SendPilotInviteTypes.FAILURE],
  },
});
