import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useUnmount, useConditionalDataEffect } from '@bottomless/common/hooks';
import { addToastAction, connectWithAbort } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import {
  addOrderGenNoteAction,
  createOrderGenAction,
  deleteOrderGenAction,
  deleteOrderGenNoteAction,
  getTodoOrderGenAction,
  getTriggerCountsAction,
  pushOrderGenAction,
  triggerOrdergenGenerationAction,
  triggerOrdergenSmokeTestAction,
  updateOrderGenAction,
} from '../../../../store/admin/ordergen';
import { setNeedsSupportAction, setTareAction, getUserLastOrderAction } from './../../../../store/admin/user';
import { getVendorsDownTimeAction } from '../../../../store/admin/vendor';
import { TriggerGeneration } from './components/TriggerGeneration';
import { User } from './components/User';
import './OrderGen.scss';
import { tabs } from './Tabs';
import { withPermamentAlerts } from '../../../../components/PermamentAlerts/PermamentAlerts';
import { getCustomerNotesAction } from '../../../../store';

const PAGE_SIZE = 20;

const OrderGenPageComponent = ({
  users,
  getTodo,
  getUserLastOrder,
  createOrderGen,
  pushOrderGen,
  deleteOrderGen,
  updateOrderGen,
  addOrderGenNote,
  deleteOrderGenNote,
  prefetchUsers,
  userIds,
  isPrefetching,
  setTare,
  addAlert,
  addToast,
  abortRequests,
  setNeedsSupport,
  getCustomerNotes,
  triggerGenerationCreateOrder,
  triggerOrdergenGeneration,
  getTriggerCounts,
  getVendorDownTimes,
  automationName,
  triggerOrdergenSmokeTest,
}) => {
  useUnmount(abortRequests);
  const allUserIds = useMemo(() => userIds.reduce((all, curr) => [...all, ...curr], []), [userIds]);

  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isFinished, setFinished] = useState(false);
  const [preloaded, setPreloaded] = useState(false);
  const [vendorDownTimes, setVendorDownTimes] = useState(null);

  useEffect(() => {
    let timeout;

    const fetch = async jobId => {
      const res = await prefetchUsers({ jobId });

      if (res?.payload?.async) {
        if (res?.payload?.status === 'preparing') {
          timeout = setTimeout(() => fetch(res?.payload?.jobId), 5000);
        } else {
          setPreloaded(true);
        }
      } else {
        setPreloaded(true);
      }
    };

    fetch();

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [prefetchUsers]);

  useEffect(() => {
    let loading = false;

    const downloadData = async () => {
      if (loading || isLoading || isFinished || isPrefetching || !preloaded) {
        return;
      }

      loading = true;
      setLoading(true);

      try {
        const { payload: newUsers } = await getTodo({ userIds: userIds[page] });
        if (!userIds[page + 1]) {
          setFinished(true);
        }
        pushOrderGen(newUsers.data);
        setPage(page + 1);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (users.filter(user => user.visible !== false).length < PAGE_SIZE) {
      // eslint-disable-next-line no-console
      downloadData().catch(console.error);
    }
  }, [users, userIds, page, isLoading, isFinished, isPrefetching, preloaded]);

  useConditionalDataEffect(!vendorDownTimes, getVendorDownTimes, setVendorDownTimes, 'upcoming');

  const onGenerateInstance = ({ _id }) => async data => {
    updateOrderGen(_id, { visible: true, error: null });

    try {
      data.source = window.location.href;
      return await createOrderGen(data);
    } catch (error) {
      updateOrderGen(_id, { visible: true, error });
      throw error;
    }
  };

  const onCreateNote = userId => note => addOrderGenNote(userId, note);
  const onDeleteNote = userId => note => deleteOrderGenNote(userId, note._id);

  const onNeedsSupport = useCallback(
    user => async ({ needs_support: needsSupport }) => {
      if (needsSupport) {
        updateOrderGen(user._id, { visible: false, error: null });
        return;
      }

      try {
        const { payload: notes } = await getCustomerNotes(user._id);
        updateOrderGen(user._id, { notes, support: { order: { needs_support: needsSupport } } });
      } catch (e) {
        addToast(`Error during fetching customer notes: ${e.message}`);
      }
    },
    [updateOrderGen, getCustomerNotes, addToast]
  );

  const onSmokeTest = useCallback(() => {
    triggerOrdergenSmokeTest();
    addToast('Smoke test triggered!', 'success');
  }, [triggerOrdergenSmokeTest, addToast]);

  return (
    <PanelPage
      title="Order Gen"
      withUpNext={false}
      className="page-ordergen"
      tabs={tabs}
      heading={
        <>
          <Button onClick={onSmokeTest} color="success">
            Smoke test
          </Button>
        </>
      }
    >
      <TriggerGeneration
        triggerGenerationCreateOrder={triggerGenerationCreateOrder}
        triggerOrdergenGeneration={triggerOrdergenGeneration}
        addToast={addToast}
        allUserIds={allUserIds}
        getTriggerCounts={getTriggerCounts}
        automationName={automationName}
      />
      {isPrefetching && (
        <DataLoading count={0} isLoading={true} loadingText="Preparing users...">
          <span />
        </DataLoading>
      )}
      {isFinished && !users.length && <div className="text-center">Order generation is finished 🎉</div>}
      {users.map((user, index) => {
        if (index < PAGE_SIZE / 2) {
          return (
            <User
              userIndex={index}
              user={user}
              key={user._id}
              onGenerateInstance={onGenerateInstance(user)}
              onCreateNote={onCreateNote(user._id)}
              onDeleteNote={onDeleteNote(user._id)}
              addToast={addAlert}
              onDiscard={() => deleteOrderGen(user._id)}
              setTare={data => setTare(user._id, data)}
              setNeedsSupport={data => setNeedsSupport(user._id, data)}
              getUserLastOrder={getUserLastOrder}
              onNeedsSupport={onNeedsSupport(user)}
              vendorDownTimes={vendorDownTimes}
            />
          );
        }
      })}
      <DataLoading count={0} isLoading={isLoading}>
        <span />
      </DataLoading>
    </PanelPage>
  );
};

OrderGenPageComponent.propTypes = {
  getTodo: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  userIds: PropTypes.array,
  isPrefetching: PropTypes.bool.isRequired,
  createOrderGen: PropTypes.func.isRequired,
  pushOrderGen: PropTypes.func.isRequired,
  updateOrderGen: PropTypes.func.isRequired,
  deleteOrderGen: PropTypes.func.isRequired,
  addOrderGenNote: PropTypes.func.isRequired,
  deleteOrderGenNote: PropTypes.func.isRequired,
  prefetchUsers: PropTypes.func.isRequired,
  setTare: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  abortRequests: PropTypes.func.isRequired,
  setNeedsSupport: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  getCustomerNotes: PropTypes.func.isRequired,
  getUserLastOrder: PropTypes.func.isRequired,
  triggerGenerationCreateOrder: PropTypes.bool,
  triggerOrdergenGeneration: PropTypes.func.isRequired,
  getTriggerCounts: PropTypes.func.isRequired,
  getVendorDownTimes: PropTypes.func.isRequired,
  automationName: PropTypes.string,
  triggerOrdergenSmokeTest: PropTypes.func.isRequired,
};

export const MainOrderGenPage = connectWithAbort(
  ({ adminOrderGen }) => ({
    users: adminOrderGen.data || [],
    userIds: adminOrderGen.userIds,
    isPrefetching: adminOrderGen.isPrefetching,
  }),
  dispatch => ({
    getTodo: id => dispatch(getTodoOrderGenAction(id)),
    createOrderGen: data => dispatch(createOrderGenAction(data, { returnEarly: true })),
    pushOrderGen: data => dispatch(pushOrderGenAction(data)),
    updateOrderGen: (id, data) => dispatch(updateOrderGenAction(id, data)),
    deleteOrderGen: id => dispatch(deleteOrderGenAction(id)),
    addOrderGenNote: (userId, note) => dispatch(addOrderGenNoteAction(userId, note)),
    deleteOrderGenNote: (userId, noteId) => dispatch(deleteOrderGenNoteAction(userId, noteId)),
    setTare: (userId, data) => dispatch(setTareAction(userId, data)),
    setNeedsSupport: (id, data) => dispatch(setNeedsSupportAction(id, data)),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    getCustomerNotes: (id, data) => dispatch(getCustomerNotesAction(id, data)),
    getUserLastOrder: id => dispatch(getUserLastOrderAction(id)),
    triggerOrdergenGeneration: data => dispatch(triggerOrdergenGenerationAction(data)),
    getTriggerCounts: () => dispatch(getTriggerCountsAction()),
    getVendorDownTimes: status => dispatch(getVendorsDownTimeAction(status)),
    triggerOrdergenSmokeTest: () => dispatch(triggerOrdergenSmokeTestAction()),
  })
)(withPermamentAlerts(OrderGenPageComponent));
