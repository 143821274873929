import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from '@bottomless/common/components';

export const VendorSelect = ({ vendors, vendor }) => {
  const history = useHistory();
  const formRef = useRef();

  useEffect(() => {
    if (!isEmpty(vendor) && !isEmpty(vendors)) {
      formRef.current.resetForm({ vendor: vendor._id });
    }
  }, [vendor, vendors]);

  const onVendorChange = e => {
    history.push({
      pathname: `/admin/order_tracking/${e.target.value}`,
      search: '',
    });
  };

  const vendorOptions =
    !vendors || !vendors.length ? {} : vendors.reduce((all, { _id, name }) => ({ ...all, [_id]: name }), {});

  return (
    <>
      {!isEmpty(vendor) && !isEmpty(vendors) && (
        <>
          <Form
            innerRef={formRef}
            inline
            initialValues={{ vendor: vendor._id }}
            validationSchema={() => {}}
            onSubmit={() => {}}
            onSuccess={() => {}}
          >
            {() => (
              <Field name="vendor" type="select" label="Vendor" options={vendorOptions} onChange={onVendorChange} />
            )}
          </Form>
        </>
      )}
    </>
  );
};

VendorSelect.propTypes = {
  vendors: PropTypes.array,
  vendor: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
};
