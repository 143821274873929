import { pickBy } from 'lodash-es';
import qs from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Field } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  rotation_id: Yup.string().nullable(true),
});

export const Filters = ({ rotationsOptions }) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const formRef = useRef();
  const [filters, setFilters] = useState(query);

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(pickBy(filters, v => v && v !== 'null')),
    });
  }, [filters]);

  return (
    <Form
      inline
      innerRef={formRef}
      initialValues={{ status: '', ...filters }}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {() => (
        <>
          <div className="ml-2">
            <Field
              name="rotation_id"
              label="type"
              type="select"
              options={rotationsOptions}
              onChange={e => onFilterChange('rotation_id', e.target.value)}
            />
          </div>
        </>
      )}
    </Form>
  );
};

Filters.propTypes = {
  rotationsOptions: PropTypes.object.isRequired,
};
