import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const VendorFulfillmentTime = ({ vendor, date }) => {
  const today = new Date(date).getDay();
  const curFulfillmentTime = vendor.fulfillment_times[today];
  const [days] = useState(Math.floor(curFulfillmentTime.median / 24));
  const [hours] = useState(curFulfillmentTime.median % 24);

  const timeText = () => {
    let text = '';
    if (days !== 0) {
      text += days + ' days ';
      if (hours !== 0) {
        text += ' and ';
      }
    }
    if (hours !== 0) {
      text += hours + ' hours ';
    }
    return text;
  };

  return <>Avg fulfillment time: {timeText()}</>;
};

VendorFulfillmentTime.propTypes = {
  vendor: PropTypes.object,
  date: PropTypes.string,
};
