import React, { useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { set } from 'lodash-es';
import { Table, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, Checkbox, Default } from '@bottomless/common/components';
import { Nl2Br } from '../../components/Nl2Br/Nl2Br';
import { ReplaceVariantForm } from '../ReplaceVariantForm';
import './ProductChangeSet.scss';

const Schema = Yup.object().shape({
  keys: Yup.object().required('This field is required'),
  reason: Yup.string().optional(),
});

export const ProductChangeSet = ({
  changeSet,
  productOptions,
  withButtons,
  onReject,
  onAccept,
  onIgnore,
  product,
  shopProducts,
  onSubmit: onSubmitReplacementVariant,
  onSuccess: onSuccessReplacementVariant,
}) => {
  const form = useRef();
  const { variants: variantsChangeSet } = Object.entries(changeSet).reduce(
    (all, [key, value]) => set(all, key, value),
    {}
  );
  const changedVariants = variantsChangeSet && variantsChangeSet.map((v, key) => ({ ...v, key }));

  const findName = useCallback(
    (key, value) => {
      const dict = productOptions[key];

      return (dict.find(o => o._id === value) || { name: value }).name;
    },
    [productOptions]
  );

  const mapSingleProperty = useCallback(
    (key, { oldValue, newValue }) => ({ oldValue: findName(key, oldValue), newValue: findName(key, newValue) }),
    [findName]
  );

  const mapArrayProperty = useCallback(
    (key, { oldValue, newValue }) => ({
      oldValue: oldValue.map(v => findName(key, v)).join(', '),
      newValue: newValue.map(v => findName(key, v)).join(', '),
    }),
    [findName]
  );

  const mapVariants = useCallback(variants => {
    const { oldValue, newValue, variantSize } =
      variants &&
      variants
        .map((v, key) => ({ ...v, key }))
        .reduce(
          ({ oldValue, newValue, variantSize }, { key, ...value }) => {
            const values = Object.entries(value).reduce(
              (all, [field, fieldValue]) => ({
                oldValue: [...all.oldValue, `${field}: ${Default({ children: fieldValue.oldValue })}`],
                newValue: [...all.newValue, `${field}: ${Default({ children: fieldValue.newValue })}`],
              }),
              { oldValue: [], newValue: [] }
            );
            return {
              oldValue: [...oldValue, `${key + 1}: ${values.oldValue.join(', ')}`],
              newValue: [...newValue, `${key + 1}: ${values.newValue.join(', ')}`],
              variantSize: product
                ? [
                    ...variantSize,
                    product.product && typeof product.product === 'object'
                      ? product.product.variants && product.product.variants[key] && product.product.variants[key].size
                      : product.variants && product.variants[key] && product.variants[key].size,
                  ]
                : [...variantSize],
            };
          },
          { oldValue: [], newValue: [], variantSize: [] }
        );

    return {
      oldValue: <Nl2Br>{oldValue.join('\n')}</Nl2Br>,
      newValue: <Nl2Br>{newValue.join('\n')}</Nl2Br>,
      variantSize: <Nl2Br>{variantSize.join('\n')}</Nl2Br>,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changes = useMemo(() => {
    const { roast, origin, region, process, tasting_notes, tags, varietal, variants, ...rest } = Object.entries(
      changeSet
    ).reduce((all, [key, value]) => set(all, key, value), {});

    return {
      ...rest,
      ...(roast ? { roast: mapSingleProperty('roasts', roast) } : {}),
      ...(origin ? { origin: mapSingleProperty('origins', origin) } : {}),
      ...(region ? { region: mapSingleProperty('regions_raw', region) } : {}),
      ...(process ? { process: mapSingleProperty('processes', process) } : {}),
      ...(tasting_notes ? { tasting_notes: mapArrayProperty('tasting_notes_raw', tasting_notes) } : {}),
      ...(tags ? { tags: mapArrayProperty('tags', tags) } : {}),
      ...(varietal ? { varietal: mapArrayProperty('varietals', varietal) } : {}),
      ...(variants ? { variants: mapVariants(variants) } : {}),
    };
  }, [changeSet, mapArrayProperty, mapSingleProperty, mapVariants]);

  const onSubmit = useCallback(
    data =>
      onAccept({
        ...data,
        keys: Object.entries(data.keys)
          .filter(([, value]) => value)
          .map(([key]) => key),
      }),
    [onAccept]
  );

  const handleReject = useCallback(() => onReject({ reason: form.current.state.values.reason }), [onReject, form]);

  const handleIgnore = useCallback(() => onIgnore({ reason: form.current.state.values.reason }), [onIgnore, form]);

  const renderReplacementForm = changedVariant => {
    let variantSize;
    let variantId;
    if (product) {
      variantSize =
        product.product && typeof product.product === 'object'
          ? product.product.variants &&
            product.product.variants[changedVariant.key] &&
            product.product.variants[changedVariant.key].size
          : product.variants && product.variants[changedVariant.key] && product.variants[changedVariant.key].size;

      variantId =
        product.product && typeof product.product === 'object'
          ? product.product.variants &&
            product.product.variants[changedVariant.key] &&
            product.product.variants[changedVariant.key]._id
          : product.variants && product.variants[changedVariant.key] && product.variants[changedVariant.key]._id;
    }

    const updatedVariant = {
      _id: variantId,
      size: variantSize,
      oldValue: changedVariant.available.oldValue,
      newValue: changedVariant.available.newValue,
    };

    if (variantSize && variantId) {
      return (
        <>
          <div className="mt-4">
            <strong>Variant Replacement Form for {variantSize}oz</strong>
          </div>
          <ReplaceVariantForm
            product={product}
            variant={updatedVariant}
            shopProducts={shopProducts}
            onSubmitReplacementVariant={onSubmitReplacementVariant}
            onSuccessReplacementVariant={onSuccessReplacementVariant}
          />
        </>
      );
    }

    return <></>;
  };

  const renderTableRow = (field, oldValue, newValue, variantSize) => {
    const emptyOldValue = oldValue === null || oldValue === '';
    const emptyNewValue = newValue === null || newValue === '';
    if (emptyOldValue && emptyNewValue) return null;

    return (
      <tr key={field}>
        {withButtons && (
          <td>
            <Checkbox name={`keys.${field}`} value={true} label="" />
          </td>
        )}
        <td>{field}</td>
        <td>
          <Default>{variantSize}</Default>
        </td>
        <td>
          {field !== 'description' && <Default>{oldValue}</Default>}
          {field === 'description' && <div dangerouslySetInnerHTML={{ __html: oldValue }} />}
        </td>
        <td>
          {field !== 'description' && <Default>{newValue}</Default>}
          {field === 'description' && <div dangerouslySetInnerHTML={{ __html: newValue }} />}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Form
        innerRef={form}
        initialValues={{ reason: '', keys: Object.keys(changes).reduce((all, key) => ({ ...all, [key]: false }), {}) }}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <div className="product-changeset">
            <Table>
              <thead>
                <tr>
                  {withButtons && <th>Apply</th>}
                  <th>Field</th>
                  <th>Size (oz)</th>
                  <th>Old value</th>
                  <th>New value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(changes).map(([field, { oldValue, newValue, variantSize }]) =>
                  renderTableRow(field, oldValue, newValue, variantSize)
                )}
              </tbody>
            </Table>
            {withButtons && (
              <>
                <Field type="hidden" name="error" />
                <Field type="textarea" name="reason" label="Reason" />
                <Button disabled={isSubmitting} outline size="sm" color="success" className="mr-2">
                  Accept change request
                </Button>
                <Button
                  disabled={isSubmitting}
                  outline
                  size="sm"
                  type="button"
                  onClick={handleReject}
                  color="danger"
                  className="mr-2"
                >
                  Reject change request
                </Button>
                <Button disabled={isSubmitting} outline size="sm" type="button" onClick={handleIgnore} color="warning">
                  Ignore change request
                </Button>
              </>
            )}
          </div>
        )}
      </Form>
      {changedVariants && changedVariants.length && changedVariants.some(v => v.available.newValue === false) ? (
        <>
          {changedVariants.map(
            (changedVariant, i) =>
              changedVariant.available.newValue === false && <div key={i}>{renderReplacementForm(changedVariant)}</div>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

const OptionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })
).isRequired;

ProductChangeSet.propTypes = {
  changeSet: PropTypes.object.isRequired,
  productOptions: PropTypes.shape({
    tasting_notes: OptionsProps,
    roasts: OptionsProps,
    origins: OptionsProps,
    tags: OptionsProps,
    processes: OptionsProps,
    regions: OptionsProps,
    varietals: OptionsProps,
  }),
  withButtons: PropTypes.bool,
  onReject: PropTypes.func,
  onAccept: PropTypes.func,
  onIgnore: PropTypes.func.isRequired,
  product: PropTypes.object,
  shopProducts: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
