import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useState } from 'react';
import { Bell, BellOff, Check, X } from 'react-feather';
import { connect } from 'react-redux';
import { Button, Col, Row, Table } from 'reactstrap';
import { DataLoading, Price } from '@bottomless/common/components';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { HeadingBack } from '../../../../components/HeadingBack/HeadingBack';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getCategoriesAction } from '../../../../store';
import {
  createProductFromProposedAction,
  getProductAction,
  getProductsAction,
  updateProductAction,
  replaceProductVariantAction,
  getTaxCategoriesAction,
} from '../../../../store/admin/product';
import { getVendorAction, getVendorsAction } from '../../../../store/admin/vendor';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import {
  applyProposedProductChangesAction,
  getProposedProductAction,
  ignoreProposedProductAction,
  mapProposedProductAction,
  deleteProposedProductVendorChangesAction,
  acceptProposedProductVendorChangesAction,
  ignoreProposedProductVendorChangesAction,
} from '../../../../store/admin/proposed-product';
import { getQuizAnswersAction } from '../../../../store/admin/product';
import { MapProductForm } from './components/MapProductForm';
import { UpdateForm } from './components/UpdateForm';
import { ProductChangeSet } from '../../../../components/ProductChangeSet/ProductChangeSet';

const ProposedProductPageComponent = ({
  getProposedProduct,
  getProductOptions,
  getProducts,
  getProduct,
  createProduct,
  updateProduct,
  mapToShop,
  ignoreProposedProduct,
  applyChanges,
  deleteVendorChangeSet,
  acceptVendorChangeSet,
  ignoreVendorChangeSet,
  getQuizAnswers,
  addToast,
  getVendor,
  match,
  location: { search },
  replaceProductVariant,
  getCategories,
  getTaxCategories,
  getVendors,
}) => {
  const [product, setProduct] = useState(null);
  const [shopProducts, setShopProducts] = useState([]);
  const [vendorShopProducts, setVendorShopProducts] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [shopProduct, setShopProduct] = useState(null);
  const [vendor, setVendor] = useState(null);

  const query = qs.parse(search);
  const { id } = match.params;
  const Heading = HeadingBack({
    to: query.back || `/admin/proposed_products/all?vendor_id=${product ? product.vendor_id : ''}`,
  });

  const { data: categories } = useDataEffect(getCategories);
  useDataEffect(getProposedProduct, setProduct, id, null);
  useConditionalDataEffect(product, getProductOptions, setProductOptions, product?.vendor_id);
  useDataEffect(
    getProducts,
    response => {
      setShopProducts(get(response, 'docs', []));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );
  const { data: taxCategories } = useDataEffect(getTaxCategories, undefined, undefined, null);

  useConditionalDataEffect(product && product.vendor_id, getVendor, setVendor, (product || {}).vendor_id);

  useConditionalDataEffect(
    product,
    getProducts,
    data => {
      setVendorShopProducts(get(data, 'docs', []));
    },
    product ? { vendor_id: product.vendor_id, sort: 'name', dir: 1 } : null
  );
  useConditionalDataEffect(product && product.product, getProduct, setShopProduct, (product || {}).product);

  const onUpdateSuccess = updated => {
    addToast('Product has been successfully saved');
    setShopProduct({ ...shopProduct, ...updated });
  };

  const onCreateNewProduct = async () => {
    const { payload } = await createProduct(id);
    setProduct({ ...product, product: payload._id });
    addToast('New shop product has been successfully created');
  };

  const onIgnoreProduct = async () => {
    await ignoreProposedProduct(id);
    setProduct({ ...product, ignored: !product.ignored });
    addToast('Product has been ignored');
  };

  const onMapProductSuccess = updated => {
    setProduct({ ...product, ...updated });
    addToast('Shop product has been mapped');
  };

  const onApplyChanges = async () => {
    const { payload } = await applyChanges(product._id);
    setProduct({ ...product, ...payload, change_set: undefined });
    addToast('Changes have been successfully applied');
  };

  const onDeleteVendorChangeSet = async data => {
    const { payload } = await deleteVendorChangeSet(id, data);
    setProduct({ ...product, ...payload, vendor_change_set: undefined });
    addToast('Vendor changes have been deleted');
  };

  const onAcceptVendorChangeSet = async data => {
    const { payload } = await acceptVendorChangeSet(id, data);
    setProduct({ ...product, vendor_change_set: undefined });
    setShopProduct({ ...shopProduct, ...payload });
    addToast('Vendor changes have been accepted');
  };

  const onIgnoreVendorChangeSet = async data => {
    const { payload } = await ignoreVendorChangeSet(id, data);
    setProduct({ ...product, ...payload, vendor_change_set: undefined });
    addToast('Vendor changes have been ignored');
  };

  return (
    <PanelPage title={product ? `${product.name} - ${product.vendor_name}` : ''} heading={Heading}>
      <DataLoading count={product ? 1 : 0} isLoading={Boolean(product)} />
      {product && productOptions && taxCategories && (
        <>
          <Row>
            <Col xs="12" sm="4">
              {/http:/.test(product.original_id) && (
                <div className="mb-2">
                  <a href={product.original_id} target="_blank" rel="noopener noreferrer">
                    View on vendor shop
                  </a>
                </div>
              )}
              <p>{product.description}</p>
              <h6>Variants:</h6>
              <Table responsive className="small">
                <thead>
                  <tr>
                    <th>Variant</th>
                    <th>Attributes</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {product.variants?.map((variant, i) => (
                    <tr key={i}>
                      <td>
                        <div>{variant.original_id}</div>
                        <div className="font-weight-bold">
                          <Price value={variant.price} />
                        </div>
                      </td>
                      <td>
                        {variant.option1 && <div>{variant.option1}</div>}
                        {variant.option2 && <div>{variant.option2}</div>}
                        {variant.option3 && <div>{variant.option3}</div>}
                      </td>
                      <td className={`text-right text-${variant.available ? 'success' : 'danger'}`}>
                        {variant.available ? <Check size="20" /> : <X size="20" />}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {product.original_image && (
                <a href={product.original_image} target="_blank" rel="noopener noreferrer">
                  <img src={product.original_image} alt="Missing product image" className="img-fluid" />
                </a>
              )}
              <div className="mt-2 text-center">
                <a href={product.reference_url} title="See vendor's page" target="_blank" rel="noopener noreferrer">
                  Reference url
                </a>
              </div>
              {product.change_set && (
                <>
                  <div className="mt-4 mb-2 d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Changes:</h5>
                    <Button color="success" outline onClick={onApplyChanges} size="sm" className="mr-3">
                      Apply changes
                    </Button>
                  </div>
                  <pre id="json">{JSON.stringify(product.change_set, undefined, 2)}</pre>
                </>
              )}
              <h5 className="mt-4">Actions:</h5>
              {!product.ignored && (
                <Button color="danger" size="sm" outline className="btn-no-transform" onClick={onIgnoreProduct}>
                  <BellOff size="12" /> <span className="ml-1">Ignore</span>
                </Button>
              )}
              {product.ignored && (
                <Button color="success" size="sm" outline className="btn-no-transform" onClick={onIgnoreProduct}>
                  <Bell size="12" /> <span className="ml-1">Unignore</span>
                </Button>
              )}
            </Col>
            <Col xs="12" sm="8">
              <MapProductForm
                onSubmit={mapToShop}
                product={product}
                onCreateNewProduct={onCreateNewProduct}
                onSuccess={onMapProductSuccess}
                shopProducts={vendorShopProducts}
              />

              {shopProduct && (
                <UpdateForm
                  onSubmit={data => updateProduct(shopProduct._id, data)}
                  onSuccess={onUpdateSuccess}
                  product={shopProduct}
                  proposedProduct={product}
                  productOptions={productOptions}
                  shopProducts={shopProducts}
                  getQuizAnswers={getQuizAnswers}
                  vendor={vendor}
                  categories={categories || []}
                  taxCategories={taxCategories}
                  addToast={addToast}
                  getVendors={getVendors}
                />
              )}
            </Col>
          </Row>
          {product && product.vendor_change_set && shopProduct && (
            <div className="mt-4">
              <h2>Vendor change request:</h2>
              <ProductChangeSet
                onSubmit={data => replaceProductVariant(shopProduct._id, data)}
                onSuccess={onUpdateSuccess}
                onReject={onDeleteVendorChangeSet}
                onAccept={onAcceptVendorChangeSet}
                onIgnore={onIgnoreVendorChangeSet}
                changeSet={product.vendor_change_set}
                productOptions={productOptions}
                product={shopProduct}
                shopProducts={shopProducts}
                withButtons
              />
            </div>
          )}
        </>
      )}
    </PanelPage>
  );
};

ProposedProductPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getProposedProduct: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  mapToShop: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  ignoreProposedProduct: PropTypes.func.isRequired,
  applyChanges: PropTypes.func.isRequired,
  deleteVendorChangeSet: PropTypes.func.isRequired,
  acceptVendorChangeSet: PropTypes.func.isRequired,
  ignoreVendorChangeSet: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  getQuizAnswers: PropTypes.func,
  getVendor: PropTypes.func,
  replaceProductVariant: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getTaxCategories: PropTypes.func.isRequired,
  getVendors: PropTypes.func.isRequired,
};

export const ProposedProductPage = connect(null, dispatch => ({
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getProposedProduct: id => dispatch(getProposedProductAction(id)),
  getProductOptions: vendorId => dispatch(getProductOptionsAction(vendorId)),
  getProducts: id => dispatch(getProductsAction(id)),
  getProduct: id => dispatch(getProductAction(id)),
  mapToShop: (id, data) => dispatch(mapProposedProductAction(id, data)),
  createProduct: id => dispatch(createProductFromProposedAction(id)),
  updateProduct: (id, data) => dispatch(updateProductAction(id, data)),
  replaceProductVariant: (id, data) => dispatch(replaceProductVariantAction(id, data)),
  ignoreProposedProduct: id => dispatch(ignoreProposedProductAction(id)),
  applyChanges: id => dispatch(applyProposedProductChangesAction(id)),
  deleteVendorChangeSet: (id, data) => dispatch(deleteProposedProductVendorChangesAction(id, data)),
  acceptVendorChangeSet: (id, data) => dispatch(acceptProposedProductVendorChangesAction(id, data)),
  ignoreVendorChangeSet: (id, data) => dispatch(ignoreProposedProductVendorChangesAction(id, data)),
  getVendor: id => dispatch(getVendorAction(id)),
  getQuizAnswers: id => dispatch(getQuizAnswersAction(id)),
  getCategories: () => dispatch(getCategoriesAction()),
  getTaxCategories: () => dispatch(getTaxCategoriesAction()),
  getVendors: () => dispatch(getVendorsAction()),
}))(ProposedProductPageComponent);
