import * as Sentry from '@sentry/browser';
import React, { Component, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';
import { Loader } from '@bottomless/common/components';
import { LogoutTypes } from '@bottomless/common/store';
import { browserMatches } from '@bottomless/common/utils';
import { FallbackRouter } from './router/FallbackRouter';
import { createStore } from './store';
import AdminRoutes from './router/Admin';
import './layouts/Panel/Panel.scss';

export class App extends Component {
  constructor(props) {
    super(props);

    const { store, persistor } = createStore();
    this.store = store;
    this.persistor = persistor;
  }

  componentDidCatch(error, errorInfo) {
    const errorAlreadyExists = window.location.href.match(/error/);
    const isChunkLoadError = error.name === 'ChunkLoadError';
    const isFirstChunkError = isChunkLoadError && !errorAlreadyExists;

    if (process.env.NODE_ENV === 'production' && !isFirstChunkError) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);

        Sentry.captureException(error);
      });
    }

    if (!errorAlreadyExists) {
      if (!isChunkLoadError) {
        this.store.dispatch({ type: LogoutTypes.SUCCESS });
      }

      const sufix = window.location.href.match(/\?/) ? '&error' : '?error';
      window.location.replace(`${window.location.href}${sufix}`);
    } else if (!browserMatches()) {
      window.location.replace(`/update-browser.html`);
    }
  }

  render() {
    const { store, persistor } = this;

    return (
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<Loader />}>
              <Router>
                <Switch>
                  <Redirect from="/" exact to="/admin" />
                  <Route path="/admin" component={AdminRoutes} />
                  <Route path="/:slug" component={FallbackRouter} />
                </Switch>
              </Router>
            </Suspense>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    );
  }
}
