import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { Form, SubmitButton } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { Provider } from './Provider';
import { useCallback } from 'react';

export const Shipments = ({ vendor, shipmentOptions, onSubmit, onSuccess }) => {
  const [isOpen, toggle, setOpen] = useToggle();

  const handleSubmit = useCallback(
    ({ provider, parcels, pickups, ...data }) =>
      onSubmit({
        shipment_providers: [provider],
        parcels: Object.values(parcels)
          .reduce((all, current) => [...all, ...current], [])
          .filter(({ parcel }) => parcel),
        pickups: Object.values(pickups),
        ...data,
      }),
    [onSubmit]
  );

  const handleSuccess = useCallback(
    data => {
      setOpen(false);
      return onSuccess(data);
    },
    [setOpen, onSuccess]
  );

  const getInitialValues = useCallback(
    ({ providers, vendorParcels, vendorPickups, sizes }) => ({
      parcels: providers?.reduce((all, provider) => {
        const filtered = vendorParcels.filter(parcel => parcel.provider === provider._id);

        const providerParcels = filtered.length
          ? [
              ...filtered,
              ...sizes
                .filter(size => !filtered.find(f => f.size === size))
                .map(weight => ({
                  size: weight,
                  provider: provider._id,
                  parcel: '',
                })),
            ]
          : sizes.map(weight => ({
              size: weight,
              provider: provider._id,
              parcel: '',
            }));

        return { ...all, [provider._id]: providerParcels.sort((a, b) => a.size - b.size) };
      }, {}),
      pickups: providers.reduce((all, provider) => {
        const pickup = vendorPickups.find(pickup => pickup.provider === provider._id) || {
          provider: provider._id,
          monday: { from: '', to: '' },
          tuesday: { from: '', to: '' },
          wednesday: { from: '', to: '' },
          thursday: { from: '', to: '' },
          friday: { from: '', to: '' },
          saturday: { from: '', to: '' },
          sunday: { from: '', to: '' },
        };

        return { ...all, [provider._id]: pickup };
      }, {}),
      provider: vendor.shipment_providers[0],
    }),
    [vendor]
  );

  const getSchema = useCallback(
    ({ providers }) =>
      Yup.object().shape({
        provider: Yup.string(),
        parcels: Yup.lazy((value, { parent }) =>
          Yup.object().shape(
            providers.reduce(
              (all, provider) => ({
                ...all,
                [provider._id]: Yup.array().of(
                  Yup.object().shape({
                    parcel:
                      parent.provider === provider._id
                        ? Yup.string()
                            .required()
                            .label('Parcel')
                        : Yup.string(),
                  })
                ),
              }),
              {}
            )
          )
        ),
        pickups: Yup.object().shape(
          providers.reduce(
            (all, provider) => ({
              ...all,
              [provider._id]: Yup.object().shape({
                monday: Yup.object().shape({ from: Yup.string(), to: Yup.string() }),
                tuesday: Yup.object().shape({ from: Yup.string(), to: Yup.string() }),
                wednesday: Yup.object().shape({ from: Yup.string(), to: Yup.string() }),
                thursday: Yup.object().shape({ from: Yup.string(), to: Yup.string() }),
                friday: Yup.object().shape({ from: Yup.string(), to: Yup.string() }),
                saturday: Yup.object().shape({ from: Yup.string(), to: Yup.string() }),
                sunday: Yup.object().shape({ from: Yup.string(), to: Yup.string() }),
              }),
            }),
            {}
          )
        ),
      }),
    []
  );

  return (
    <>
      <Button onClick={toggle} size="sm" className="mb-2">
        Parcels
      </Button>
      <Modal toggle={toggle} isOpen={isOpen} size="xl">
        <ModalHeader toggle={toggle}>Parcels</ModalHeader>
        <ModalBody>
          <Form
            initialValues={getInitialValues(shipmentOptions)}
            validationSchema={getSchema(shipmentOptions)}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
          >
            {({ isSubmitting, values }) => (
              <>
                {shipmentOptions.providers.map(provider => (
                  <Provider
                    key={provider._id}
                    provider={provider}
                    parcels={shipmentOptions.parcels}
                    vendorParcels={values.parcels[provider._id]}
                  />
                ))}
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

Shipments.propTypes = {
  vendor: PropTypes.shape({
    shipment_providers: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  shipmentOptions: PropTypes.shape({
    providers: PropTypes.array.isRequired,
    parcels: PropTypes.array.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
