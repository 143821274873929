import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { getGifsAction, editGifsAction, removeGifAction } from '../../../store/admin/settings';
import { X } from 'react-feather';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { Plus } from 'react-feather';
import { addToastAction } from '@bottomless/common/store';

const GifsCard = ({ name, values, index, formRef, setGifs, onRemoveGif }) => {
  const addNewGif = () => {
    formRef.current.setFieldValue(`${name}[${values[name].length}]`, '');

    setGifs({
      ...values,
      [name]: [...values[name], ''],
    });
  };

  const onKeyChange = e => {
    if (values.names[index] !== e.target.value) {
      Object.defineProperty(values, e.target.value, Object.getOwnPropertyDescriptor(values, values.names[index]));
      delete values[values.names[index]];
      setGifs({ ...values });
    }
  };

  return (
    <Card className="mb-3 flex-row align-items-stretch">
      <CardBody>
        <Row>
          <Field name={`names[${index}]`} label="Key" onChange={onKeyChange}></Field>
          <Button color="link" className="d-flex align-items-center" onClick={addNewGif}>
            <Plus size="12" /> <span className="ml-2">Add new gif</span>
          </Button>
        </Row>
        {values[name].map((value, index) => (
          <Row key={index}>
            <Col xs={6}>
              <Field name={`${name}[${index}]`} label="Url"></Field>
            </Col>
            <Col xs={3}>
              <img src={value} alt={value} height="60" width="60" />
            </Col>
            <Col xs="1">
              <Button
                color="link"
                className="d-flex align-items-center"
                onClick={() => onRemoveGif(name, value, index)}
              >
                <X size="20" />
              </Button>
            </Col>
          </Row>
        ))}
      </CardBody>
    </Card>
  );
};

GifsCard.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  formRef: PropTypes.object,
  setGifs: PropTypes.func,
  index: PropTypes.number,
  onRemoveGif: PropTypes.func,
};

export const GifsPageComponent = ({ getGifs, editGifs, addToast, removeGif }) => {
  const [gifs, setGifs] = useState(null);
  const formRef = useRef();

  const addNewKey = () => {
    formRef.current.setFieldValue(`names[${gifs.names.length}]`, 'newKey');
    formRef.current.setFieldValue(`newKey[0]`, '');

    setGifs({
      ...gifs,
      names: [...gifs.names, 'newKey'],
      newKey: [''],
    });
  };

  const removeCurGif = async (name, url, index) => {
    let curGifs = gifs;
    curGifs[name].splice(index, 1);
    setGifs({
      ...curGifs,
    });
    await removeGif({ name, url });
    addToast('Gifs removed', 'success');
  };

  useEffect(() => {
    (async () => {
      const { payload: gifs } = await getGifs();
      setGifs({ ...gifs[0].value, names: Object.keys(gifs[0].value) });
    })();
  }, []);

  const onSubmit = useCallback(data => editGifs(removeNames(data)), [editGifs]);

  const removeNames = gifs => {
    const { names, ...rest } = gifs;
    return rest;
  };

  const onSuccess = () => addToast('Gifs updated', 'success');

  return (
    <>
      <PanelPage
        title="Gifs"
        heading={
          <>
            <Button color="link" className="d-flex align-items-center" onClick={addNewKey}>
              <Plus size="12" /> <span className="ml-2">Add new key</span>
            </Button>
          </>
        }
      >
        <Row>
          <Col xs="10">
            <div className="text-danger">
              Please use <u>/giphy-downsized.gif</u> at the end of url to not exceed size limit.If you saw next gif in
              the preview next to the url it is okay to use <u>/giphy.gif</u>.
            </div>
          </Col>
          <Col xs="2">
            <img
              src={'https://media.giphy.com/media/l49JJPDo0EbkITxWE/source.gif'}
              alt={'https://media.giphy.com/media/l49JJPDo0EbkITxWE/source.gif'}
              height="100"
              width="100"
            />
          </Col>
        </Row>
        {gifs && (
          <Form initialValues={{ ...gifs }} onSubmit={data => onSubmit(data)} onSuccess={onSuccess} innerRef={formRef}>
            {({ isSubmitting, values }) => (
              <>
                <div>
                  {Object.keys(removeNames(gifs)).map(
                    (name, index) =>
                      name !== 'names' && (
                        <GifsCard
                          key={index}
                          name={name}
                          values={values}
                          index={index}
                          formRef={formRef}
                          setGifs={setGifs}
                          onRemoveGif={removeCurGif}
                        />
                      )
                  )}
                </div>
                <SubmitButton color="success" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </>
            )}
          </Form>
        )}
      </PanelPage>
    </>
  );
};

GifsPageComponent.propTypes = {
  getGifs: PropTypes.func.isRequired,
  editGifs: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const GifsPage = connect(null, dispatch => ({
  getGifs: () => dispatch(getGifsAction()),
  editGifs: data => dispatch(editGifsAction(data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  removeGif: data => dispatch(removeGifAction(data)),
}))(GifsPageComponent);
