import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Plus, X } from 'react-feather';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { groupBy } from 'lodash-es';
import { Form, Field, SubmitButton, Checkbox, Radio, InfoTooltip } from '@bottomless/common/components';
import { getVariantSelectLabel } from '@bottomless/common/src/utils';
import { Fragment } from 'react';
import { get } from 'lodash-es';
import { Confirmation } from '../../../../../components/Confirmation/Confirmation';
import { useDataEffect } from '@bottomless/common/hooks';
import { CoffeeAttributes } from '../../../Products/components/CoffeeAttributes';
import { GenericAttributes } from '../../../Products/components/GenericAttributes';
import { ImageUploader } from '../../../Products/components/ImageUploader';
import { useHasAttribute, useSelectOptions } from '../../../Products/hooks';
import { TaxCategoryInput } from '../../../../../components/TaxCategoryInput';
import { RotationBuilderModal } from '../../../../../components/RotationBuilderModal';
import { RotationSummary } from '../../../../../components/RotationBuilderModal/RotationSummary';
import has from 'lodash-es/has';

const Statuses = {
  draft: 'Draft',
  active: 'Active',
  archived: 'Archived',
};

const CoffeeSchema = {
  roast: Yup.string().required('This field is required'),
  origin: Yup.string().required('This field is required'),
  region: Yup.string().nullable(),
  process: Yup.string().nullable(),
  altitude_min: Yup.number()
    .min(0)
    .nullable(),
  altitude_max: Yup.number()
    .min(0)
    .nullable(),
  tasting_notes: Yup.array().of(Yup.string()),
  tags: Yup.array().of(Yup.string()),
};

const createSchema = manifest =>
  Yup.object().shape({
    name: Yup.string().required('This field is required'),
    category: Yup.string().required('This field is required'),
    tax_category: Yup.string().required('This field is required'),
    description: Yup.string().required('This field is required'),
    gtin: Yup.string(),

    small_image_src: Yup.string().matches(/^https:/, 'Please use https://'),
    image_src: Yup.string().matches(/^https:/, 'Please use https://'),
    widget_image_src: Yup.string().matches(/^https:/, 'Please use https://'),
    status: Yup.mixed().oneOf(Object.keys(Statuses)),

    rotation_price: Yup.object(),
    send_product_back: Yup.bool(),
    variants: Yup.array().of(
      Yup.object().shape({
        size: Yup.number().required('This field is required'),
        price: Yup.number().required('This field is required'),
        cost: Yup.number(),
        available: Yup.bool().required('This field is required'),
        skipShipment: Yup.bool(),
        original_id_mapping: Yup.object()
          .default(null)
          .nullable()
          .when({
            is: data => !Object.values(data).some(Boolean),
            then: schema => schema.shape({ default: Yup.string().nullable() }),
            otherwise: schema =>
              schema.shape({
                default: Yup.string()
                  .nullable()
                  .required('This field is required'),
              }),
          }),
      })
    ),
    shopifyCollectionExclude: Yup.object({
      subscriptionByUsage: Yup.bool(),
    }),
    ...Object.entries(CoffeeSchema)
      .filter(([key]) => manifest?.attributes.find(a => a.field === key))
      .reduce((all, [key, value]) => ({ ...all, [key]: value }), {}),
  });

export const UpdateForm = ({
  product,
  shopProducts,
  onSubmit,
  onSuccess,
  productOptions,
  proposedProduct,
  getQuizAnswers,
  vendor,
  taxCategories,
  categories,
  getVendors,
}) => {
  const [imageUrls, setImageUrls] = useState(null);
  const [productCopy, setProductCopy] = useState(product);
  const [archived, setArchived] = useState(false);
  const [category, setCategory] = useState();
  const [sendProductBackEmail, setSendProductBackEmail] = useState(false);
  const [showReplacementForm, setShowReplacementForm] = useState(false);
  const [eligibleForReplacement, addToReplacement] = useState([]);
  const [quizAnswers, setQuizAnswers] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState(
    (product.variants || []).map(variant => variant._id).reduce((variants, id) => ({ ...variants, [id]: [] }), {})
  );
  const [isRotationBuilderModalOpen, setRotationBuilderModalOpen] = useState({});
  const [allVendors, setAllVendors] = useState();
  const hasAttribute = useHasAttribute(category?.manifest || {});

  const isSimpleSubscriptionVendor = useMemo(() => !!vendor?.shopifyManifest?.simpleSubscription, [vendor]);

  const toggleRotationBuilderModal = useCallback(key => {
    setRotationBuilderModalOpen(prev => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  }, []);

  const formRef = useRef();

  const schema = useMemo(() => createSchema(category?.manifest), [category]);

  useEffect(() => setCategory(categories?.find(c => c._id === product.category)), [categories, product]);

  useEffect(() => {
    setProductCopy({ variants: [], ...product });
    formRef.current.resetForm({
      send_product_back: false,
      variants: [],
      ...product,
      shopifyCollectionExclude: {
        ...(product.shopifyCollectionExclude || {}),
        subscriptionByUsage: !!product.shopifyCollectionExclude?.subscriptionByUsage,
      },
    });

    if (!productCopy.rotation_price) {
      setProductCopy({
        ...productCopy,
        rotation_price: {
          basic: false,
          standard: false,
          premium: false,
          general: false,
        },
      });

      formRef.current.setFieldValue(`rotation_price[basic]`, false);
      formRef.current.setFieldValue(`rotation_price[standard]`, false);
      formRef.current.setFieldValue(`rotation_price[premium]`, false);
      formRef.current.setFieldValue(`rotation_price[general]`, false);
    } else if (!has(productCopy.rotation_price, 'general')) {
      setProductCopy({
        ...productCopy,
        rotation_price: {
          ...productCopy.rotation_price,
          general: false,
        },
      });

      formRef.current.setFieldValue(`rotation_price[general]`, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (imageUrls) {
      formRef.current.setFieldValue(`image_src`, imageUrls.img_src);
      formRef.current.setFieldValue(`small_image_src`, imageUrls.small_img_src);
    }
  }, [imageUrls]);

  useDataEffect(getQuizAnswers, setQuizAnswers, product._id);
  useDataEffect(getVendors, setAllVendors);

  const createVariantWebsitePricingOptions = (data, label) => {
    if (!data) return {};
    const options = {};
    options[0] = label;
    data.map(v => (options[v.price] = `${v.size} oz - $${v.price}`));
    return options;
  };

  const variantsOptions = createVariantOptions(proposedProduct.variants, '-- Choose variant --');
  const variantWebsitePricingOptions = createVariantWebsitePricingOptions(proposedProduct.variants, 'Choose variant');

  const categoriesOptions = useSelectOptions(categories, '-- Choose category --');

  const products = (size, tags) =>
    createSelectOptions(
      shopProducts.filter(
        product =>
          (product.tags.includes('5bcecdeef325036b0f66bb08')
            ? tags.includes('5bcecdeef325036b0f66bb08')
            : !tags.includes('5bcecdeef325036b0f66bb08')) && product.variants.filter(v => v.size === size).length > 0
      ),
      '-- Choose product --',
      product => `${product.vendor_name} - ${product.name}`
    );

  const variants = size =>
    Object.keys(selectedVariants)
      .map(id => ({
        id,
        options: createSelectOptions(
          selectedVariants[id].filter(v => v.size === size),
          '-- Choose variant --',
          getVariantSelectLabel
        ),
      }))
      .reduce((all, { id, options }) => ({ ...all, [id]: options }), {});

  const handleSubmit = async data => {
    const { replace_product, variants } = data;

    return onSubmit({
      ...data,
      replace_product: Object.keys(replace_product || []).map(variantId => ({
        ...replace_product[variantId],
        _id: variantId,
      })),
      variants: variants.map(variant => ({
        ...variant,
        ...(variant.original_id_mapping
          ? {
              original_id_mapping: Object.entries(variant.original_id_mapping)
                .filter(([key, value]) => key === 'default' || Boolean(value))
                .map(([key, value]) => (value === '' ? [key, null] : [key, value]))
                .reduce((all, [key, value]) => ({ ...all, [key]: value }), {}),
            }
          : {}),
      })),
    });
  };

  const handleSuccess = (data, { resetForm }) => {
    onSuccess(data);
    resetForm(data);
  };

  const addNewVariant = () => {
    formRef.current.setFieldValue(`variants[${productCopy.variants.length}].available`, true);
    formRef.current.setFieldValue(`variants[${productCopy.variants.length}].skipShipment`, false);

    setProductCopy({
      ...productCopy,
      variants: [
        ...productCopy.variants,
        {
          notSaved: true,
          available: true,
          skipShipment: false,
        },
      ],
    });
  };

  const onRemoveVariant = index => () => {
    return new Promise((promiseResolve, reject) => {
      const resolve = () =>
        promiseResolve(
          (() => {
            const newProduct = {
              ...productCopy,
              variants: [...productCopy.variants.slice(0, index), ...productCopy.variants.slice(index + 1)],
            };
            setProductCopy(newProduct);
            formRef.current.resetForm({ ...newProduct });
          })()
        );
      const event = new Event('open');
      event.promise = { resolve, reject };
      emitter.dispatchEvent(event);
    });
  };

  const setDefaultImg = () => {
    if (!vendor) {
      return;
    }

    if (!vendor.product_image_url) {
      return;
    }

    formRef.current.setFieldValue(`image_src`, `${vendor.product_image_url}?auto=compress`);
    formRef.current.setFieldValue(`small_image_src`, `${vendor.product_image_url}?auto=compress&w=300`);
  };

  const onAvailabilityChange = (variant, e) => {
    if (variant.notSaved) {
      return;
    }

    addToReplacement(
      e.nativeEvent.target.checked
        ? [...eligibleForReplacement.filter(id => id !== variant._id)]
        : [...eligibleForReplacement, variant._id]
    );
  };

  useEffect(() => {
    setShowReplacementForm(eligibleForReplacement.length > 0);

    const currentState = formRef.current.state.values.replace_product;

    formRef.current.setFieldValue('replace_product', undefined);

    if (currentState) {
      eligibleForReplacement.map(id =>
        formRef.current.setFieldValue(`replace_product[${id}]`, { replacement_type: 'product', ...currentState.id })
      );
    } else {
      eligibleForReplacement.map(id =>
        formRef.current.setFieldValue(`replace_product[${id}]`, { replacement_type: 'product' })
      );
    }
  }, [eligibleForReplacement]);

  const [emitter] = useState(new EventTarget());

  const onStatusChange = e => {
    setSendProductBackEmail(product.status === 'archived' && e.target.value === 'active');
    setArchived(e.target.value === 'archived' && product.status !== 'archived');

    if (e.target.value === 'archived' && product.status !== 'archived') {
      const currentState = formRef.current.state.values.replace_product;

      if (currentState) {
        product.variants.map(variant =>
          formRef.current.setFieldValue(`replace_product[${variant._id}]`, {
            replacement_type: 'product',
            ...currentState[variant._id],
          })
        );
      } else {
        product.variants.map(variant =>
          formRef.current.setFieldValue(`replace_product[${variant._id}]`, { replacement_type: 'product' })
        );
      }
    }
  };

  const onReplacementChange = id => e => {
    const product = shopProducts.find(product => product._id === e.target.value);
    setSelectedVariants({
      ...selectedVariants,
      [id]: (product || { variants: [] }).variants.filter(variant => variant.available !== false),
    });
  };

  const onCategoryChange = useCallback(e => setCategory(categories.find(c => c._id === e.target.value)), [
    categories,
    setCategory,
  ]);

  return (
    <div className="update-proposed-product">
      <Form
        innerRef={formRef}
        initialValues={{
          send_product_back: false,
          ...product,
          shopifyCollectionExclude: {
            ...(product.shopifyCollectionExclude || {}),
            subscriptionByUsage: !!product.shopifyCollectionExclude?.subscriptionByUsage,
          },
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            <hr />
            <h4>Shop product</h4>
            {quizAnswers && (
              <>
                {quizAnswers.length === 0 ? (
                  <></>
                ) : (
                  <>
                    <h3 style={{ color: 'red' }}> IN QUIZ </h3>
                    {quizAnswers.map((answer, index) => (
                      <div key={index}>{answer?.answers[0]}</div>
                    ))}
                  </>
                )}
              </>
            )}
            <Field name="name" label="Name" />
            <TaxCategoryInput taxCategories={taxCategories} name="tax_category" />
            <Field name="description" type="editor" label="Description" />
            <Field name="gtin" label="GTIN" />
            <Row className="align-items-center">
              <Col xs="6">
                <ImageUploader setImageUrls={setImageUrls} />
              </Col>
              <Col xs="6">
                <Button color="link" onClick={setDefaultImg}>
                  Set Vendor&apos;s default
                </Button>
              </Col>
            </Row>
            <Button color="link" onClick={setDefaultImg}>
              Set default
            </Button>
            <Field name="image_src" label="Image url" />
            <Field name="small_image_src" label="Small image url" />
            <Field name="widget_image_src" label="Widget image url" />
            <div className="d-flex align-items-center justify-content-between">
              <h6>Variants:</h6>
              <Button color="link" className="d-flex align-items-center" onClick={addNewVariant}>
                <Plus size="12" /> <span className="ml-2">Add new variant</span>
              </Button>
            </div>
            {productCopy.variants &&
              productCopy.variants.map((variant, i) => (
                <Fragment key={variant._id}>
                  <Row>
                    <Col lg="auto" xl="auto" md="auto">
                      <span># {variant.numUsers}</span>
                    </Col>
                    <Col>
                      <Field name={`variants[${i}].size`} type="number" label="Size" />
                    </Col>
                    <Col>
                      <Field name={`variants[${i}].price`} type="number" label="Price" />
                    </Col>
                    <Col>
                      <Field name={`variants[${i}].cost`} type="number" label="Cost" />
                    </Col>
                    <Col>
                      <Field
                        name={`variants[${i}].website_pricing`}
                        type="select"
                        label="Website Pricing"
                        options={variantWebsitePricingOptions}
                      />
                    </Col>
                    <Col xs="1">
                      <Button color="link" className="text-danger" onClick={onRemoveVariant(i)}>
                        <X size="20" />
                      </Button>
                    </Col>
                  </Row>
                  <Row xs="3">
                    {productOptions.grind &&
                      [{ _id: 'default', name: 'Default' }, ...productOptions.grind].map(grind => (
                        <Col key={grind._id}>
                          <Field
                            name={`variants[${i}].original_id_mapping[${grind._id}]`}
                            type="select"
                            label={grind.name}
                            options={variantsOptions}
                          />
                        </Col>
                      ))}
                  </Row>
                  <div className="d-flex pb-3 variant-checkboxes">
                    <div className="mr-3 text-sm">
                      <Checkbox
                        name={`variants[${i}].available`}
                        label="Available"
                        value={variant.available}
                        onChange={e => onAvailabilityChange(variant, e)}
                      />
                    </div>
                    <div className="mr-3 text-sm">
                      <Checkbox
                        name={`variants[${i}].skipShipment`}
                        label="Skip Shipment"
                        value={variant.skipShipment}
                      />
                    </div>
                    <div className="mr-3 text-sm">
                      <Checkbox
                        name={`variants[${i}].hidden.subscribeByUsage`}
                        label="Hidden From SBU"
                        value={variant.hidden?.subscribeByUsage}
                      />
                    </div>
                  </div>
                  <hr />
                </Fragment>
              ))}
            <Field name="hidden" type="select" label="Hidden" options={{ true: 'Yes', false: 'No' }} />
            <div className="mb-2">
              <Checkbox
                name="shopifyCollectionExclude.subscriptionByUsage"
                label="Exclude from Shopify's Subscription by Usage collection"
              />
            </div>
            <Field name="status" type="select" label="Status" onChange={onStatusChange} options={Statuses} />
            {sendProductBackEmail && (
              <Checkbox name={`send_product_back`} label="Notify users product is back" value={true} />
            )}

            {productCopy.rotation_price && (
              <Row className="mb-3">
                {Object.keys(productCopy.rotation_price).map((pr, i) => (
                  <Col key={i}>
                    <Checkbox name={`rotation_price[${pr}]`} label={pr} value={productCopy.rotation_price[pr]} />
                  </Col>
                ))}
              </Row>
            )}

            <Field
              name="category"
              type="select"
              label="Category"
              onChange={onCategoryChange}
              options={categoriesOptions}
            />

            {category && (
              <>
                <h4>{category.name} specific settings</h4>
                {hasAttribute('grind') && (
                  <CoffeeAttributes product={product} productOptions={productOptions} manifest={category.manifest} />
                )}
                {!hasAttribute('grind') && <GenericAttributes product={product} manifest={category.manifest} />}
              </>
            )}

            {(archived || showReplacementForm) &&
              productCopy.variants
                .filter(v => (archived || eligibleForReplacement.includes(v._id)) && v._id)
                .map((variant, i) => (
                  <div key={i}>
                    <h6>
                      Replacement for variant{' '}
                      <strong>
                        {variant.size}oz - ${variant.price}
                      </strong>
                    </h6>
                    <Row className="mb-1 ">
                      <Col>
                        <Radio
                          name={`replace_product[${variant._id.toString()}].replacement_type`}
                          label="Product"
                          value="product"
                          type="radio"
                        />
                      </Col>
                      <Col>
                        <Radio
                          name={`replace_product[${variant._id.toString()}].replacement_type`}
                          label={
                            <div className="d-flex align-items-center">
                              Rotation
                              {isSimpleSubscriptionVendor && (
                                <>
                                  <InfoTooltip size="sm" className="ml-1">
                                    Simple Subscription vendors can&apos;t replace products with rotations.
                                  </InfoTooltip>
                                </>
                              )}
                            </div>
                          }
                          value="rotation"
                          type="radio"
                          disabled={isSimpleSubscriptionVendor}
                        />
                      </Col>
                    </Row>
                    <Field name={`replace_product[${variant._id.toString()}].replacement_type`} type="hidden" />
                    {!(
                      values.replace_product &&
                      values.replace_product[variant._id.toString()]?.replacement_type === 'rotation'
                    ) && (
                      <Row>
                        <Col xs="12" sm="6">
                          <Field
                            name={`replace_product[${variant._id.toString()}].product`}
                            type="select"
                            label="Replacement product"
                            options={products(variant.size, product.tags)}
                            onChange={onReplacementChange(variant._id)}
                            required
                          />
                        </Col>
                        <Col xs="12" sm="6">
                          <Field
                            name={`replace_product[${variant._id.toString()}].variant`}
                            type="select"
                            label="Replacement variant"
                            options={variants(variant.size)[variant._id]}
                            required
                          />
                        </Col>
                      </Row>
                    )}
                    {values.replace_product &&
                      values.replace_product[variant._id.toString()]?.replacement_type === 'rotation' && (
                        <Row className="mb-3">
                          <Col>
                            <Row className="align-items-center">
                              <Col lg="6">
                                <Button
                                  size="sm"
                                  color="success"
                                  onClick={() =>
                                    toggleRotationBuilderModal(`${variant._id?.toString()}.non_vendor_locked`)
                                  }
                                >
                                  Non-Vendor Locked
                                </Button>
                                <Field name={`replace_product[${variant._id.toString()}].personalized`} type="hidden" />
                              </Col>
                              {values.replace_product &&
                                values.replace_product[variant._id.toString()].personalized && (
                                  <RotationSummary
                                    personalized={values.replace_product[variant._id.toString()].personalized}
                                    productOptions={productOptions}
                                  />
                                )}
                            </Row>
                          </Col>
                          <Col>
                            <Row className="align-items-center">
                              <Col lg="6">
                                <Button
                                  size="sm"
                                  color="warning"
                                  onClick={() => toggleRotationBuilderModal(`${variant._id?.toString()}.vendor_locked`)}
                                >
                                  Vendor Locked
                                </Button>
                                <Field
                                  name={`replace_product[${variant._id.toString()}].personalized_vendor_locked`}
                                  type="hidden"
                                />
                              </Col>
                              {values.replace_product &&
                                values.replace_product[variant._id.toString()].personalized_vendor_locked && (
                                  <RotationSummary
                                    personalized={
                                      values.replace_product[variant._id.toString()].personalized_vendor_locked
                                    }
                                    productOptions={productOptions}
                                  />
                                )}
                            </Row>
                          </Col>
                        </Row>
                      )}
                    <RotationBuilderModal
                      heading="Non-Vendor Locked Rotation Builder"
                      isOpen={isRotationBuilderModalOpen[`${variant._id?.toString()}.non_vendor_locked`]}
                      toggle={() => toggleRotationBuilderModal(`${variant._id?.toString()}.non_vendor_locked`)}
                      product={values}
                      productOptions={productOptions}
                      variant={variant}
                      onSubmit={val => setFieldValue(`replace_product[${variant._id.toString()}].personalized`, val)}
                      showRoasters
                      selectedPersonalized={get(values, `replace_product[${variant._id.toString()}].personalized`)}
                      allVendors={allVendors}
                    />
                    <RotationBuilderModal
                      heading="Vendor Locked Rotation Builder"
                      isOpen={isRotationBuilderModalOpen[`${variant._id?.toString()}.vendor_locked`]}
                      toggle={() => toggleRotationBuilderModal(`${variant._id?.toString()}.vendor_locked`)}
                      product={values}
                      productOptions={productOptions}
                      variant={variant}
                      onSubmit={val =>
                        setFieldValue(`replace_product[${variant._id.toString()}].personalized_vendor_locked`, val)
                      }
                      selectedPersonalized={get(
                        values,
                        `replace_product[${variant._id.toString()}].personalized_vendor_locked`
                      )}
                      allVendors={allVendors}
                    />
                  </div>
                ))}
            <div className="d-flex justify-content-between mt-4">
              <SubmitButton color="primary" isSubmitting={isSubmitting}>
                Save
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
      <Confirmation openEmitter={emitter} text={`Are you sure about removing the variant?`} />
    </div>
  );
};

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

const createVariantOptions = (data, label) => {
  if (!data) {
    return {};
  }

  return [
    ...(label ? [{ name: '', items: { '': label } }] : []),
    ...Object.entries(groupBy(data, 'size'))
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([name, items]) => ({
        name,
        items: items
          .map(({ original_id, ...item }) => ({
            key: original_id,
            value: [
              ...(item.price ? [`$${item.price}`] : []),
              ...(item.option1 ? [item.option1] : []),
              ...(item.option2 ? [item.option2] : []),
              ...(item.option3 ? [item.option3] : []),
              original_id,
              item.available ? '✔' : '⨯',
            ].join(' - '),
          }))
          .sort((a, b) => a.value.localeCompare(b.value))
          .reduce((all, { key, value }) => ({ ...all, [key]: value }), {}),
      })),
  ];
};

const OptionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })
).isRequired;

UpdateForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  shopProducts: PropTypes.array.isRequired,
  productOptions: PropTypes.shape({
    tasting_notes: OptionsProps,
    roasts: OptionsProps,
    origins: OptionsProps,
    tags: OptionsProps,
    processes: OptionsProps,
    regions: OptionsProps,
    varietals: OptionsProps,
    grind: OptionsProps,
  }),
  proposedProduct: PropTypes.shape({
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        original_id: PropTypes.string.isRequired,
        size: PropTypes.number,
        price: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
  getQuizAnswers: PropTypes.func,
  vendor: PropTypes.object,
  categories: PropTypes.array.isRequired,
  taxCategories: PropTypes.array,
  addToast: PropTypes.func.isRequired,
  getVendors: PropTypes.func.isRequired,
};
