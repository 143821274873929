import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Table } from 'reactstrap';

export const SuggestedProducts = ({
  subproducts: rawSubproducts,
  products,
  onSubproductClick,
  selectedVendor,
  persGround,
}) => {
  const subproducts = useMemo(
    () => ({
      ...rawSubproducts.today,
      variants: rawSubproducts.today.variants.map((_, i) => {
        const product = products.find(p => p._id === rawSubproducts.today.products[i]?._id);
        return product ? product.variants[0]?._id : null;
      }),
    }),
    [rawSubproducts, products]
  );

  return (
    <Table size="sm" className="text-sm" hover>
      <thead>
        <tr className="row-clickable">
          <th>Name</th>
          <th>Vendor</th>
          <th>Like</th>
          <th>Quality score</th>
          <th>Rec score</th>
          <th>Today score</th>
          <th>Today timing</th>
          <th># orders</th>
          <th>Order since vendor</th>
          <th>Order since product</th>
          <th>Distance</th>
          <th>vendor score</th>
          <th>prod score</th>
        </tr>
      </thead>
      <tbody>
        {subproducts.products.map((_, i) => {
          const variant = subproducts.variants[i];

          const isDisliked = subproducts.products[i].feedback && subproducts.products[i].feedback.dislike;

          return (
            <tr
              key={i}
              onClick={
                variant && !isDisliked && (!persGround || subproducts.products[i].available_ground === persGround)
                  ? onSubproductClick({ product: subproducts.products[i]._id, variant })
                  : undefined
              }
              className={classNames([
                'row-clickable',
                {
                  'row-strikethrough': !variant || isDisliked,
                  selected: subproducts.products[i].vendor_id === selectedVendor,
                },
                persGround && subproducts.products[i].available_ground !== persGround ? 'grey-out' : '',
              ])}
            >
              <td>{subproducts.products[i].name}</td>
              <td>{subproducts.products[i].vendor_name}</td>
              <td className="text-center">
                {subproducts.products[i].feedback && (
                  <div className="text-danger">
                    {subproducts.products[i].feedback.like && <i className="fa fa-heart" />}
                    {subproducts.products[i].feedback.dislike && <i className="fa fa-ban" />}
                  </div>
                )}
              </td>
              <td className="text-center">{subproducts.quality_score[i].toFixed(2)}</td>
              <td className="text-center">{subproducts.rec_score[i]?.toFixed(2)}</td>
              <td className="text-center">{subproducts.compound_score[i]?.toFixed(2)}</td>
              <td className="text-center">{subproducts.pred_score[i].toFixed(2)}</td>
              <td className="text-center">{subproducts.product_order_count[i]}</td>
              <td className="text-center">{subproducts.vendor_last_order[i]}</td>
              <td className="text-center">{subproducts.product_last_order[i]}</td>
              {subproducts.distance[i] && <td className="text-center">{subproducts.distance[i].toFixed(2)}</td>}
              {subproducts.score_vendor && subproducts.score_vendor[i] && (
                <td className="text-center">{String(subproducts.score_vendor[i].toFixed(3))}</td>
              )}

              {subproducts.prod_score && subproducts.prod_score[i] && (
                <td className="text-center">{String(subproducts.prod_score[i].toFixed(3))}</td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

SuggestedProducts.propTypes = {
  onSubproductClick: PropTypes.func.isRequired,
  subproducts: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.string),
    variants: PropTypes.arrayOf(PropTypes.string),
    quality_score: PropTypes.arrayOf(PropTypes.number),
    rec_score: PropTypes.arrayOf(PropTypes.number),
    compound_score: PropTypes.arrayOf(PropTypes.number),
    pred_score: PropTypes.arrayOf(PropTypes.number),
    distance: PropTypes.arrayOf(PropTypes.number),
    product_order_count: PropTypes.arrayOf(PropTypes.number),
    vendor_last_order: PropTypes.arrayOf(PropTypes.number),
    product_last_order: PropTypes.arrayOf(PropTypes.number),
    nob: PropTypes.arrayOf(PropTypes.number),
    prod_score: PropTypes.arrayOf(PropTypes.number),
    like_score: PropTypes.arrayOf(PropTypes.number),
    prob_score: PropTypes.arrayOf(PropTypes.number),
    score_vendor: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  products: PropTypes.array.isRequired,
  selectedVendor: PropTypes.string,
  price: PropTypes.string,
  persGround: PropTypes.bool,
};
