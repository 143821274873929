import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'reactstrap';

export const Product = ({ product, dislike }) => {
  const image = !product.small_image_src
    ? null
    : product.small_image_src.match(/^http/)
    ? product.small_image_src
    : process.env.REACT_APP_BACKEND_URL + product.small_image_src;

  return (
    <Card className="mb-3 flex-row align-items-stretch">
      <div className="card-media" style={{ backgroundImage: `url(${image || '/images/no-image.png'})` }} />
      <div className="d-flex justify-content-between align-items-center card-body">
        <div>
          <div className="text-sm text-secondary">{product.vendor_name}</div>
          <div>{product.name}</div>
          <div className="text-sm text-secondary font-weight-bold">{product.status}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className={dislike ? 'text-secondary' : 'text-danger'}>
            <i className="fa fa-heart" />
            <span className="ml-2">{product.like}</span>
          </div>
          <div className="mx-2" />
          <div className={dislike ? 'text-danger' : 'text-secondary'}>
            <i className="fa fa-ban" />
            <span className="ml-2">{product.dislike}</span>
          </div>
          <div className="mx-2" />
          <div className="text-secondary">
            <i className="fa fa-calculator" />
            <span className="ml-2">{Number(product.ratio.toFixed(2))}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

Product.propTypes = {
  dislike: PropTypes.bool,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    like: PropTypes.number.isRequired,
    dislike: PropTypes.number.isRequired,
    ratio: PropTypes.number.isRequired,
    small_image_src: PropTypes.string,
  }),
};
