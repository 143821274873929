import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Button } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  dumb_period: Yup.number()
    .positive()
    .integer()
    .min(0)
    .lessThan(46)
    .required('This field is required'),
});

export const DumbSubscription = ({ user, onSuccess, onSubmit, updateDumbSubscription, updateUser }) => {
  const formRef = useRef();
  const [isRemoving, setRemoving] = useState();
  const handleSuccess = (data, { resetForm }) => {
    onSuccess(data);
    resetForm(data);
  };

  const handleSubmit = data => onSubmit({ ...data, dumb_period: data.dumb_period === '' ? null : data.dumb_period });

  const handleFixedPeriodRemoval = useCallback(async () => {
    setRemoving(true);
    const { payload } = await updateDumbSubscription(user._id, { toDel: true });
    if (formRef.current) {
      formRef.current.setFieldValue('dumb_period', '');
    }
    updateUser(payload);
    setRemoving(false);
  }, [updateDumbSubscription, updateUser, user]);

  return (
    <Form
      innerRef={formRef}
      initialValues={{ dumb_period: user.dumb_period }}
      validationSchema={Schema}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <div>
            <Field name="dumb_period" type="number" label="Period" />
            {updateDumbSubscription && user.dumb_period && (
              <div>
                <Button
                  color="danger"
                  onClick={handleFixedPeriodRemoval}
                  size="sm"
                  className="mb-2"
                  disabled={isSubmitting || isRemoving}
                >
                  Remove Dumb Period from User
                </Button>
              </div>
            )}
            <SubmitButton color="dark" isSubmitting={isSubmitting}>
              Save
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};

DumbSubscription.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    dumb_period: PropTypes.number,
  }).isRequired,
  updateDumbSubscription: PropTypes.func,
  updateUser: PropTypes.func,
};
