import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { EditForm } from './EditForm';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getTrackingLink } from '@bottomless/common/utils';
import { DateFormat } from '@bottomless/common/components';

export const Order = ({ order: rawOrder, updateOrder, addToast }) => {
  const [order, setOrder] = useState(rawOrder);
  const [isModalOpen, toggleModal] = useToggle();
  const [isArrived, setIsArrived] = useState(false);
  const [isCompleting, setCompleting] = useState(false);

  const handleComplete = useCallback(async () => {
    setCompleting(true);
    await updateOrder(order._id, {
      date_arrived: new Date(),
    });
    setCompleting(false);
    setIsArrived(true);
    addToast(`Order ${order._id} completed`);
  }, [order, updateOrder, addToast]);

  if (isArrived) {
    return <></>;
  }

  return (
    <>
      <Card className="mb-4">
        <CardBody>
          <Row>
            <Col xs="6">
              <div>
                <Link to={`/admin/users/${order.user_id._id}?orderId=${order._id}`}>
                  <span className="text-primary">{order._id}</span>
                </Link>
              </div>
              <div>E-Commerce name: {order.eCommerceDetails?.name || '---'}</div>
              <div>
                E-Commerce order tracking link:{' '}
                {order.eCommerceDetails?.orderTrackingLink ? (
                  <a href={order.eCommerceDetails.orderTrackingLink} target="_blank" rel="noopener noreferrer">
                    {order.eCommerceDetails.orderTrackingLink}
                  </a>
                ) : (
                  '---'
                )}
              </div>
            </Col>
            <Col xs="6">
              <div>
                <Link to={`/admin/ordering_cockpit/${order.user_id._id}`}>
                  <span className="text-success">
                    {order.user_id.first_name} {order.user_id.last_name}
                  </span>
                </Link>
              </div>
              <div>
                Tracking Number:{' '}
                {(
                  <a
                    href={getTrackingLink(order.shipping_service, order.tracking_number, order.tracking_url)}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary"
                  >
                    {order.tracking_number}
                  </a>
                ) || '---'}
              </div>
              <div>Tracking Service: {order.shipping_service || '---'}</div>
              <div>
                Date Fulfilled: {order.date_fulfilled ? <DateFormat date={order.date_fulfilled} withTime /> : '---'}
              </div>
            </Col>
          </Row>
        </CardBody>

        <CardFooter className="d-flex flex-row align-items-center">
          <Button onClick={toggleModal} size="sm" className="mr-3" color="warning" disabled={isCompleting}>
            Update
          </Button>
          <Button onClick={handleComplete} size="sm" className="mr-3" color="danger" disabled={isCompleting}>
            Complete
          </Button>
        </CardFooter>
      </Card>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          <div className="text-sm text-secondary">{order._id}</div>
        </ModalHeader>
        <ModalBody>
          <EditForm
            product={order.productData}
            order={order}
            updateOrder={updateOrder}
            toggleModal={toggleModal}
            setIsArrived={setIsArrived}
            addToast={addToast}
            setOrder={setOrder}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

Order.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string,
    date_fulfilled: PropTypes.string,
    amount_paid: PropTypes.number,
    user_id: PropTypes.string,
    eCommerceDetails: PropTypes.object,
    tracking_number: PropTypes.string,
    tracking_url: PropTypes.string,
    shipping_service: PropTypes.string,
    productData: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      image_src: PropTypes.string,
      small_image_src: PropTypes.string,
      status: PropTypes.string,
      active: PropTypes.bool,
      hidden: PropTypes.bool,
      date_added: PropTypes.string,
      updated_at: PropTypes.string,
    }).isRequired,
    address: PropTypes.object,
  }),
  updateOrder: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};
