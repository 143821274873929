import {
    GetAllPricingRules
} from './pricing_rules.actions';
  
  const initialState = {
    data: [],
    isLoading: false,
  };
  
  export const adminPricingRulesReducer = (state = initialState, action) => {
    switch(action.type) {
      case GetAllPricingRules.REQUEST:
        return { ...initialState, isLoading: true };
      case GetAllPricingRules.SUCCESS:
        return {
            data: action.payload,
            isLoading: false,
        };
      default:
        return state;
    }
  };
  