import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Row } from 'reactstrap';
import { connectWithAbort } from '@bottomless/common/store';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { AddPackaging } from '../components/AddPackagingModal';
import { InventoryAdjustment } from '../components/InventoryAdjustmentModal';
import {
  getPackagingSummaryAction,
  addVendorPackagingAction,
  updateOnHandInventoryAction,
  updateInventoryRequestAction,
} from '../../../../store/admin/vendor';
import { tabs } from './Tabs';
import './Packaging.scss';

const modalTypes = {
  PACKAGING: 'Packaging Modal',
  INVENTORY_ADJUSTMENT: 'Inventory Adjustment Modal',
};

const Summary = ({
  getPackagingSummary,
  addVendorPackaging,
  addToast,
  updateOnHandInventory,
  updateInventoryRequest,
}) => {
  const [packagingSummary, setPackagingSummary] = useState([]);
  const [vendorsForInventoryRequest, setVendorsForInventoryRequest] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);

  useDataEffect(getPackagingSummary, setPackagingSummary, null, []);

  const toggleModal = (type, vendor) => {
    setType(type);
    setOpen(!isOpen);

    if (vendor) {
      setSelectedVendor(vendor);
    }
  };

  const addPackaging = data => addVendorPackaging(selectedVendor._id, data);
  const onPackagingSuccess = (data, { resetForm }) => {
    addToast('Added packaging');
    resetForm();
    toggleModal();
    setSelectedVendor(null);
  };

  const adjustInventory = data => updateOnHandInventory(selectedVendor._id, data);
  const onAdjustInventorySuccess = (data, { resetForm }) => {
    addToast('Updated on-hand inventory');

    const indexOfSelectedVendor = packagingSummary.findIndex(vendor => vendor._id === selectedVendor._id);
    const modifiedPackagingSummary = [...packagingSummary];
    packagingSummary[indexOfSelectedVendor].packagingCount = data.packagingCount;
    setPackagingSummary(modifiedPackagingSummary);

    resetForm();
    toggleModal();
  };

  const handleCheckBoxClick = e => {
    let selectedVendors = vendorsForInventoryRequest;
    if (e.target.checked) {
      selectedVendors = [...selectedVendors, e.target.value];
    } else {
      selectedVendors = selectedVendors.filter(vendorId => e.target.value !== vendorId);
    }
    setVendorsForInventoryRequest(selectedVendors);
  };

  const onClickSendInventoryRequest = useCallback(() => {
    (async () => {
      try {
        if (vendorsForInventoryRequest.length) {
          await updateInventoryRequest({ vendorIds: vendorsForInventoryRequest });
          addToast('Inventory request sent', 'success');
        } else {
          addToast('Please select at least one vendor', 'danger');
        }
      } catch (e) {
        addToast('Sorry, inventory request could not be sent', 'danger');
      }
    })();
  }, [vendorsForInventoryRequest]);

  const renderPackageSizes = vendor => {
    if (vendor && vendor.packagingCount) {
      const sizes = Object.keys(vendor.packagingCount);
      return sizes.map(size => <Row key={size}>{size}</Row>);
    }
  };

  const renderOnHandInventory = vendor => {
    if (vendor && vendor.packagingCount) {
      const onHand = Object.values(vendor.packagingCount);
      return onHand.map((inventoryPerSize, index) => <Row key={index}>{inventoryPerSize}</Row>);
    }
  };

  const renderTableData = () => {
    return (
      packagingSummary &&
      packagingSummary.map(vendor => {
        const { _id: vendorId } = vendor;
        return (
          <tr key={vendorId}>
            <td>
              <input
                type="checkbox"
                name="inventory-request"
                value={vendorId}
                checked={vendorsForInventoryRequest.includes(vendorId)}
                onChange={handleCheckBoxClick}
              />
            </td>
            <td>{vendor.name}</td>
            <td className="size">{renderPackageSizes(vendor)}</td>
            <td className="size">{renderOnHandInventory(vendor)}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="button-cell">
              <Button onClick={() => toggleModal(modalTypes.PACKAGING, vendor)} size="sm" color="warning">
                Add Packaging
              </Button>
              <Button onClick={() => toggleModal(modalTypes.INVENTORY_ADJUSTMENT, vendor)} size="sm" color="success">
                Adjust Inventory
              </Button>
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <PanelPage title="Packagings" tabs={tabs} className="packaging-summary-tab">
      <div className="button-row">
        <Button onClick={onClickSendInventoryRequest} size="sm" color="info">
          Send Inventory Request
        </Button>
      </div>
      <div>
        <Table hover responsive className="text-center summary-table">
          <thead>
            <tr>
              <th>Inventory Request</th>
              <th>Vendor Name</th>
              <th>Package Size</th>
              <th>On-hand Inventory</th>
              <th>In-transit Inventory</th>
              <th>Total Inventory</th>
              <th>Weekly Usage</th>
              <th>Weeks of On-hand Coverage</th>
              <th>Weeks of Total Coverage</th>
              <th>Action Buttons</th>
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
      </div>

      {modalType === modalTypes.PACKAGING && (
        <>
          <AddPackaging
            toggle={toggleModal}
            isOpen={isOpen}
            vendor={selectedVendor}
            addPackaging={addPackaging}
            onPackagingSuccess={onPackagingSuccess}
          />
        </>
      )}
      {modalType === modalTypes.INVENTORY_ADJUSTMENT && (
        <>
          <InventoryAdjustment
            toggle={toggleModal}
            isOpen={isOpen}
            vendor={selectedVendor}
            adjustInventory={adjustInventory}
            onAdjustInventorySuccess={onAdjustInventorySuccess}
          />
        </>
      )}
    </PanelPage>
  );
};

Summary.propTypes = {
  getPackagingSummary: PropTypes.func.isRequired,
  addVendorPackaging: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  updateOnHandInventory: PropTypes.func.isRequired,
  updateInventoryRequest: PropTypes.func.isRequired,
};

export const VendorPackagingSummary = connectWithAbort(null, dispatch => ({
  getPackagingSummary: () => dispatch(getPackagingSummaryAction()),
  addVendorPackaging: (id, data) => dispatch(addVendorPackagingAction(id, data)),
  updateOnHandInventory: (id, data) => dispatch(updateOnHandInventoryAction(id, data)),
  addToast: (id, data) => dispatch(addToastAction(id, data)),
  updateInventoryRequest: data => dispatch(updateInventoryRequestAction(data)),
}))(memo(Summary));
