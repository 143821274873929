import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Checkbox, Radio } from '@bottomless/common/components';

export const ScaleShipment = ({
  shouldGiftWrapScale,
  giftWrapCharge,
  onChangeGiftWrapScale,
  onChangeScaleShipment,
  innerRef,
}) => {
  return (
    <div className="mb-3 mt-4">
      <h2 ref={innerRef} className="mb-4">
        Scale shipment information
      </h2>
      <Row>
        <Col xs="12" md="6" className="mb-2">
          <Checkbox
            name="should_gift_wrap"
            label={<strong>Gift Wrap the Scale (Extra Charge: ${giftWrapCharge / 100})</strong>}
            value={shouldGiftWrapScale}
            onChange={onChangeGiftWrapScale}
          />
        </Col>
        <Col xs="12" md="6" className="mb-2">
          <div>
            <strong>The scale should be shipped to:</strong>
            <Radio
              name="scale_shipment"
              label="Recipient"
              value="should_ship_to_recipient"
              type="radio"
              onChange={onChangeScaleShipment}
            />
            <Radio
              name="scale_shipment"
              label="Buyer"
              value="should_ship_to_buyer"
              type="radio"
              onChange={onChangeScaleShipment}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

ScaleShipment.propTypes = {
  innerRef: PropTypes.object,
  shouldGiftWrapScale: PropTypes.bool,
  onChangeGiftWrapScale: PropTypes.func.isRequired,
  giftWrapCharge: PropTypes.number.isRequired,
  onChangeScaleShipment: PropTypes.func.isRequired,
};
