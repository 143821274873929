import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Input, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { uploadProductImageAction } from '../../../../store/admin/product';

export const ImageUploaderComponent = ({ uploadImage, setImageUrls, addToast }) => {
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [noPreview, setNoPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectedFile = e => {
    setFile(e.target.files[0]);
    try {
      setImage(URL.createObjectURL(e.target.files[0]));
      setNoPreview(false);
    } catch (error) {
      setNoPreview(true);
    }
  };

  const uploadFile = async () => {
    try {
      const data = new FormData();
      data.append('file', file);
      setIsLoading(true);
      const { payload } = await uploadImage(data);
      setImage(null);
      setNoPreview(false);
      setIsLoading(false);
      setImageUrls(payload);
      addToast('Image has been successfully uploaded.', 'success');
    } catch (error) {
      addToast(`Image upload failed ${error.message}`, 'error');
    }
  };

  return (
    <>
      <h4>Image Uploader</h4>
      <Row className="mb-3">
        <Col>
          <Input
            className="d-flex align-items-center text-nowrap mb-1"
            color="success"
            type="file"
            name="file"
            onChange={e => handleSelectedFile(e)}
          />
          {file && (
            <DataLoading count={0} isLoading={isLoading} loadingText={'Uploading Image...'}>
              <Button
                color="success"
                type="button"
                onClick={uploadFile}
                className="d-flex align-items-center text-nowrap"
              >
                Upload
              </Button>
            </DataLoading>
          )}
        </Col>
        {image && (
          <Col>
            <img className="img-fluid" src={image} alt="Uploaded image" />
          </Col>
        )}
        {noPreview && <Col className="text-warning">No preview availale</Col>}
      </Row>
    </>
  );
};

ImageUploaderComponent.propTypes = {
  setImageUrls: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const ImageUploader = connect(null, dispatch => ({
  addToast: message => dispatch(addToastAction(message)),
  uploadImage: data => dispatch(uploadProductImageAction(data)),
}))(ImageUploaderComponent);
