import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  path: Yup.string(),
  withShort: Yup.bool(),
  forceBottomless: Yup.bool(),
  skipAccountSelection: Yup.bool(),
});

export const MagicLinkForm = ({ onSubmit, onSubmitSuccess, showForceBottomless }) => (
  <Form
    initialValues={{
      path: '/profile',
      withShort: false,
      forceBottomless: false,
      skipAccountSelection: false,
    }}
    validationSchema={Schema}
    onSubmit={onSubmit}
    onSuccess={onSubmitSuccess}
  >
    {({ isSubmitting }) => (
      <>
        <Field name="path" label="Path" />
        <Checkbox name="withShort" label="Short link" />
        {showForceBottomless && <Checkbox name="forceBottomless" label="Force Bottomless link for Shopify Customer" />}
        <Checkbox name="skipAccountSelection" label="Skip account selection" />

        <div className="mt-3">
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Create
          </SubmitButton>
        </div>
      </>
    )}
  </Form>
);

MagicLinkForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  showForceBottomless: PropTypes.bool,
};
