import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Field } from '@bottomless/common/components';

export const PickupDay = ({ providerId, label, day }) => (
  <Row>
    <Col className="text-right" xs="4" md="3">
      {label}
    </Col>
    <Col xs="4" md="3">
      <Field name={`pickups[${providerId}].${day}.from`} label="From" />
    </Col>
    <Col xs="4" md="3">
      <Field name={`pickups[${providerId}].${day}.to`} label="To" />
    </Col>
  </Row>
);

PickupDay.propTypes = {
  providerId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
};
