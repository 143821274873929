import { get, groupBy } from 'lodash-es';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { DataLoading, Pagination } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import {
  chargeOrderAction,
  fetchOrderTrackingAction,
  getOrdersAction,
  replaceOrderAction,
  replaceOrderSubproductAction,
  syncOrderTrackingAction,
  updateOrderAction,
  replaceOrderWithCoffeeCreditAction,
} from '../../../../store/admin/order';
import {
  createOrderGenAction,
  getSubproductPredictionsAction,
  getUserOrderGenAction,
  getUserRecordsAction,
} from '../../../../store/admin/ordergen';
import { getProductsAction } from '../../../../store/admin/product';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import {
  setNeedsSupportAction,
  setTareAction,
  getUserLastOrderAction,
  getUserChargesAction,
} from '../../../../store/admin/user';
import { getCustomerNotesAction, getVendorsDownTimeAction, getTempUserByUserIdAction } from '../../../../store';
import { User } from '../List/components/User';
import { Order } from './components/Order';

const OrderGenDetailsPageComponent = ({
  getUser,
  getUserRecords,
  createOrderGen,
  addToast,
  getOrders,
  match,
  location,
  history,
  setTare,
  getSubproductPredictions,
  chargeOrder,
  updateOrder,
  syncTracking,
  fetchOrderTracking,
  replaceOrderSubproduct,
  getProducts,
  replaceOrder,
  getProductsOptions,
  setNeedsSupport,
  getCustomerNotes,
  getUserCharges,
  getUserLastOrder,
  replaceOrderWithCoffeeCredit,
  getVendorsDownTime,
  getTempUserByUserId,
}) => {
  const query = qs.parse(location.search);
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState(null);
  const [order, setOrder] = useState(query.orderId);
  const [tempUser, setTempUser] = useState(null);
  const [products, setProducts] = useState(null);
  const [options, setOptions] = useState(null);
  const [vendorDownTimes, setVendorDownTimes] = useState({});

  const bottomRef = useRef();

  const { error: setUserError } = useDataEffect(getUser, setUser);
  useDataEffect(getProductsOptions, setOptions);
  useDataEffect(getOrders, setOrders, { user_id: match.params.id }, {}, [user]);
  useDataEffect(getTempUserByUserId, setTempUser, match.params.id, null);
  useDataEffect(
    getProducts,
    response => {
      setProducts(get(response, 'docs', []));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  useEffect(() => {
    setOrder(query.orderId);
    if (query.orderId) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [query]);

  useDataEffect(getVendorsDownTime, data => {
    setVendorDownTimes(groupBy(data, 'vendor_id'));
  });

  const callApi = async (fn, message) => {
    try {
      const { payload: order, error } = await fn;

      if (error) {
        return addToast(error);
      }

      updateOrders(order);

      return addToast(message || 'Updated');
    } catch (e) {
      addToast(e.message);
    }
  };

  const selectOrder = orderId => {
    history.push({ pathname: location.pathname, search: orderId ? qs.stringify({ orderId }) : undefined });
  };

  const onGenerateInstance = async data => {
    data.source = window.location.href;
    const res = await createOrderGen(data);
    const { payload } = await getUser();
    setUser(payload);
    return res;
  };
  const onCreateNote = note => setUser({ ...user, notes: [note, ...user.notes] });
  const onDeleteNote = note => setUser({ ...user, notes: user.notes.filter(({ _id }) => note._id !== _id) });

  const updateOrders = order => {
    const index = orders.docs.findIndex(o => o._id === order._id);

    setOrders({
      ...orders,
      docs: [...orders.docs.slice(0, index), order, ...orders.docs.slice(index + 1)],
    });
  };

  const onNeedsSupport = useCallback(
    user => async ({ support }) => {
      try {
        const { payload: notes } = await getCustomerNotes(user._id);
        setUser({ ...user, notes, support });
      } catch (e) {
        addToast(`Error during fetching customer notes: ${e.message}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, setUser, getCustomerNotes, addToast]
  );

  const handleCharge = async (id, data) => callApi(chargeOrder(id, data), 'orderCharged');
  const onSync = async id => callApi(syncTracking(id), 'Order synced');
  const onUpdate = async (id, data) => callApi(updateOrder(id, data));
  const onReplace = async (id, data) => callApi(replaceOrderSubproduct(id, data), 'Subproduct changed');
  const onReplaceOrder = async (id, data) => {
    const { isCoffeeCredit, source, discount_type, discount_comment, vendorNotification } = data;
    if (isCoffeeCredit) {
      return callApi(
        replaceOrderWithCoffeeCredit(id, { source, discount_type, discount_comment, vendorNotification }),
        'Order replaced with coffee credit'
      );
    }
    return callApi(replaceOrder(id, data), 'Order replaced');
  };

  if (setUserError) {
    return (
      <PanelPage title="Order generation" className="page-ordergen page-ordergen-details">
        <div className="text-danger text-large">
          {setUserError?.response?.message || setUserError?.message || 'Something went wrong'}
        </div>
        <div>
          <Link to="/admin/users">All users</Link>
        </div>
      </PanelPage>
    );
  }

  return (
    <PanelPage title="Order generation" className="page-ordergen page-ordergen-details">
      <DataLoading count={user ? 1 : 0} isLoading={user === null} />
      {user && (
        <User
          user={user}
          userIndex={0}
          key={user._id}
          getUserCharges={getUserCharges}
          getUserRecords={getUserRecords}
          onGenerateInstance={onGenerateInstance}
          onCreateNote={onCreateNote}
          onDeleteNote={onDeleteNote}
          addToast={addToast}
          onDiscard={() => {}}
          getOrders={getOrders}
          onSelect={selectOrder}
          setTare={data => setTare(user._id, data)}
          getSubproductPredictions={getSubproductPredictions}
          getUserLastOrder={getUserLastOrder}
          isDetail={true}
          setNeedsSupport={data => setNeedsSupport(user._id, data)}
          onNeedsSupport={onNeedsSupport(user)}
          vendorDownTimes={vendorDownTimes}
        />
      )}

      {user && orders && (
        <>
          <h1 className="mb-4 mt-4">User Orders</h1>
          {orders.docs.length > 0 && (
            <>
              {order && (
                <Button onClick={() => selectOrder()} size="sm" color="dark" className="mb-2">
                  Clear filter
                </Button>
              )}
              {orders.docs
                .filter(o => !order || (order && order === o._id))
                .map(order => (
                  <Order
                    order={order}
                    user={user}
                    key={order._id}
                    chargeOrder={handleCharge}
                    onUpdate={onUpdate}
                    products={products}
                    syncTracking={onSync}
                    fetchOrderTracking={fetchOrderTracking}
                    replaceProduct={onReplace}
                    onReplaceOrder={onReplaceOrder}
                    options={options}
                    addToast={addToast}
                  />
                ))}
            </>
          )}
          {orders.page === orders.pages && !orders.isLoading && tempUser && (
            <Order
              order={{ ...tempUser, _id: '---', source: '*TEMP USER*' }}
              user={user}
              chargeOrder={handleCharge}
              onUpdate={onUpdate}
              products={products}
              syncTracking={onSync}
              fetchOrderTracking={fetchOrderTracking}
              replaceProduct={onReplace}
              onReplaceOrder={onReplaceOrder}
              options={options}
              addToast={addToast}
              hideFooter
            />
          )}
          <div className="mt-2">
            <Pagination collection={orders} onPageChange={getOrders} setter={setOrders} />
          </div>
          {orders.docs.length === 0 && !tempUser && <>User does not have any orders.</>}
        </>
      )}
      <div ref={bottomRef} />
    </PanelPage>
  );
};

OrderGenDetailsPageComponent.propTypes = {
  getUser: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  getUserRecords: PropTypes.func.isRequired,
  createOrderGen: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  setTare: PropTypes.func.isRequired,
  chargeOrder: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  syncTracking: PropTypes.func.isRequired,
  fetchOrderTracking: PropTypes.func.isRequired,
  replaceOrderSubproduct: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getSubproductPredictions: PropTypes.func.isRequired,
  replaceOrder: PropTypes.func.isRequired,
  getProductsOptions: PropTypes.func.isRequired,
  setNeedsSupport: PropTypes.func.isRequired,
  getCustomerNotes: PropTypes.func.isRequired,
  getUserCharges: PropTypes.func.isRequired,
  getUserLastOrder: PropTypes.func.isRequired,
  replaceOrderWithCoffeeCredit: PropTypes.func.isRequired,
  getVendorsDownTime: PropTypes.func.isRequired,
  getTempUserByUserId: PropTypes.func.isRequired,
};

export const OrderGenDetailsPage = connect(null, (dispatch, { match, location: { search } }) => ({
  addToast: (message, type) => dispatch(addToastAction(message, type)),
  getUser: () => dispatch(getUserOrderGenAction(match.params.id)),
  getUserRecords: id => dispatch(getUserRecordsAction(id)),
  getUserCharges: id => dispatch(getUserChargesAction(id)),
  createOrderGen: data => dispatch(createOrderGenAction(data)),
  updateOrder: (id, data) => dispatch(updateOrderAction(id, data)),
  syncTracking: id => dispatch(syncOrderTrackingAction(id)),
  fetchOrderTracking: id => dispatch(fetchOrderTrackingAction(id)),
  getOrders: data => dispatch(getOrdersAction({ ...data, ...qs.parse(search), user_id: match.params.id, limit: 50 })),
  setTare: (userId, data) => dispatch(setTareAction(userId, data)),
  getSubproductPredictions: productIndex => dispatch(getSubproductPredictionsAction(match.params.id, productIndex)),
  chargeOrder: (id, data) => dispatch(chargeOrderAction(id, data)),
  getProducts: params => dispatch(getProductsAction(params)),
  replaceOrderSubproduct: (id, data) => dispatch(replaceOrderSubproductAction(id, data)),
  replaceOrder: (id, data) => dispatch(replaceOrderAction(id, data)),
  replaceOrderWithCoffeeCredit: (id, data) => dispatch(replaceOrderWithCoffeeCreditAction(id, data)),
  getProductsOptions: () => dispatch(getProductOptionsAction()),
  setNeedsSupport: (id, data) => dispatch(setNeedsSupportAction(id, data)),
  getCustomerNotes: (id, data) => dispatch(getCustomerNotesAction(id, data)),
  getUserLastOrder: id => dispatch(getUserLastOrderAction(id)),
  getVendorsDownTime: () => dispatch(getVendorsDownTimeAction('upcoming')),
  getTempUserByUserId: id => dispatch(getTempUserByUserIdAction(id)),
}))(OrderGenDetailsPageComponent);
