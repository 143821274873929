import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { Wifi, WifiOff, Truck, HelpCircle } from 'react-feather';

const ScaleStatus = {
  Shipped: 'shipped',
  Connected: 'connected',
  Disconnected: 'disconnected',
};

export const Scale = ({ user }) => {
  const days = user.scale_last_connected
    ? Math.floor((Date.now() - new Date(user.scale_last_connected).getTime()) / (1000 * 60 * 60 * 24))
    : null;

  const color =
    {
      [ScaleStatus.Disconnected]: 'danger',
      [ScaleStatus.Connected]: 'success',
    }[user.scale_status] || 'secondary';

  const Icon =
    {
      [ScaleStatus.Disconnected]: WifiOff,
      [ScaleStatus.Connected]: Wifi,
      [ScaleStatus.Shipped]: Truck,
    }[user.scale_status] || HelpCircle;

  return (
    <Card body className="mb-3 flex-row align-items-center justify-content-between">
      <div className={`text-${color} mr-4`}>
        <span className="mr-4">
          <Icon />
        </span>
        <span className="text-capitalize">{user.scale_status}</span>
      </div>
      <div>{days ? `${days} days` : ''}</div>
    </Card>
  );
};

Scale.propTypes = {
  user: PropTypes.shape({
    scale_status: PropTypes.string.isRequired,
    scale_last_connected: PropTypes.string.isRequired,
  }).isRequired,
};
