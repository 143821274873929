import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const Nl2Br = ({ children }) =>
  children.split('\n').map((fragment, i) => (
    <Fragment key={i}>
      {fragment}
      <br />
    </Fragment>
  ));

Nl2Br.propTypes = {
  children: PropTypes.string.isRequired,
};
