import { has } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { Address, ProductName, VariantPrice, VariantAttributes } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { CustomList } from '../../../../components/CustomList';
import { SlowLocalBadge } from '../../../../components/SlowLocalBadge';
import { History } from './History';
import './Order.scss';
import { Rotation } from './Rotation';
import { SelectSubproductForm } from './SelectSubproductForm';
import { SuggestedProducts } from './SuggestedProducts';

const USER_ONE_OFF = 'user_one_off';
const GRIND_WHOLE_BEAN = '5eaa7fc066c17ce02128fa3a';

export const Order = ({ order, onChooseSubproduct, products: rawProducts, getDisLikedProducts }) => {
  const { user_id: user } = order;
  const [dislikedProducts, setDislikedProducts] = useState([]);

  useDataEffect(getDisLikedProducts, setDislikedProducts, { user_id: user._id });

  const product = useMemo(() => (order.status === 'paid' ? order.ordered_product_id : order.user_id.product), [order]);
  const variant = useMemo(() => product.product.variants.find(v => v._id === product.variant), []);
  const disliked = useMemo(() => dislikedProducts.map(product => product._id), [dislikedProducts]);
  const isSlowLocal = useMemo(() => Boolean(user.slow_local), [user]);

  const products = useMemo(
    () =>
      rawProducts
        .filter(product => !product.rotating)
        .filter(product => !disliked.includes(product._id))
        .filter(product => {
          if (!user.vendor_id) {
            return true;
          }
          const productVendorId = product.vendor_id?._id || product.vendor_id;
          return user.vendor_id._id === productVendorId;
        })
        .map(product => {
          const variants = product.variants.filter(
            v => v.available && v.size <= variant.size * 1.2 && v.size >= variant.size * 0.8
          );

          return variants.length ? { ...product, variants } : null;
        })
        .filter(Boolean),
    [rawProducts, disliked, user, variant]
  );

  const onSubproductClick = useCallback(subproduct => () => onChooseSubproduct(order, subproduct), [
    onChooseSubproduct,
    order,
  ]);

  const onFormSubmit = useCallback(
    subproduct => {
      onChooseSubproduct(order, subproduct);
    },
    [onChooseSubproduct, order]
  );

  const levels = [
    {},
    {
      shortDes: 'Never run out',
      color: 'red',
    },
    {
      shortDes: 'Just right',
      color: 'orange',
    },
    {
      shortDes: 'As fresh as possible',
      color: 'green',
    },
  ];

  return (
    <>
      <Row className="mb-4 subproduct-selection-order">
        <Col xs="4">
          <a href={order._id} target="_blank" rel="noopener noreferrer">
            Subproduct Selection Link
          </a>
          <h3>
            <a
              href={`../users/${order.user_id._id}`}
              className="text-secondary"
              target="_blank"
              rel="noopener noreferrer"
            >
              {user.first_name} {user.last_name}
            </a>
          </h3>

          {user.vendor_id && (
            <Badge color="info" className="text-medium">
              Vendor: {user.vendor_id.name}
            </Badge>
          )}

          {order.first_order && (
            <Badge color="success" className="text-medium">
              First Order
            </Badge>
          )}
          {isSlowLocal && <SlowLocalBadge />}
        </Col>
        <Col xs="4">
          <Address address={user.verifiedAddress} />
          {user.ordering_aggression && (
            <span style={{ color: levels[user.ordering_aggression].color }}>
              {levels[user.ordering_aggression].shortDes}
            </span>
          )}
        </Col>
        <Col xs="4" className="text-right">
          {product.product.personalized_rotation && (
            <>
              <ProductName
                grind={user.grind?.name}
                product={product.product}
                personalized={user.personalized}
                isSubproduct={true}
                productVariant={product}
              />
              {variant && (
                <div className="text-secondary text-sm">
                  <span>{variant.size}oz</span>
                  <span className="mx-1">-</span>
                  {has(user, 'personalized.price_type') && (
                    <span>{user.personalized.price_type.replace(/^\w/, c => c.toUpperCase())}</span>
                  )}
                </div>
              )}
            </>
          )}
          {!product.product.personalized_rotation && (
            <>
              <div className="text-secondary">{product.product.vendor_name}</div>
              <div>{product.product.name}</div>
              {variant && (
                <div className="text-secondary text-sm">
                  <span>
                    <VariantAttributes productVariant={product} grind={user.grind} />
                  </span>
                  <span className="mx-1">-</span>
                  <span>
                    <VariantPrice user={user} variant={variant} />
                  </span>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="4">
          <h4>History</h4>
          {order.history.length <= 0 && !order.history_temp_user?._id && (
            <div className="text-secondary">No subproducts found for this order</div>
          )}
          {(order.history.length > 0 || order.history_temp_user?._id) && (
            <History history={order.history} historyTempUser={order.history_temp_user} />
          )}
        </Col>
        <Col xs="8">
          {order.custom_rotation && order.custom_rotation._id && (
            <>
              <h4>Custom list</h4>
              <Rotation orderVariant={variant} rotation={order.custom_rotation} onSubproductClick={onSubproductClick} />
            </>
          )}
          {order.source != USER_ONE_OFF ? (
            <>
              {order.custom_list?.products?.length > 0 && (
                <div className="mb-3">
                  <h4>Custom list</h4>
                  <CustomList
                    customList={order.custom_list}
                    elementsInRow={3}
                    onClick={data => onSubproductClick(data)()}
                  />
                </div>
              )}
              <h4>Suggested products</h4>
              {!order.subproducts && <div className="text-secondary">No subproducts found for this order</div>}
              {order.subproducts && (
                <>
                  <SuggestedProducts
                    subproducts={order.subproducts}
                    products={products}
                    onSubproductClick={onSubproductClick}
                    selectedVendor={order.selectedVendor?.id}
                    price={user.personalized.price_type}
                    persGround={user.grind._id !== GRIND_WHOLE_BEAN}
                  />
                </>
              )}
              <div className="text-secondary my-3">or</div>
              <SelectSubproductForm products={products} onSubmit={onFormSubmit} />
            </>
          ) : (
            <h4>This order is one off order.</h4>
          )}
        </Col>
      </Row>
    </>
  );
};

const productType = PropTypes.shape({
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    variants: PropTypes.array,
    personalized_rotation: PropTypes.bool,
  }).isRequired,
  variant: PropTypes.string.isRequired,
}).isRequired;

Order.propTypes = {
  getDisLikedProducts: PropTypes.func.isRequired,
  onChooseSubproduct: PropTypes.func.isRequired,
  order: PropTypes.shape({
    history: PropTypes.array,
    history_temp_user: PropTypes.object,
    subproducts: PropTypes.object,
    ordered_product_id: productType,
    status: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    custom_list: PropTypes.shape({
      products: PropTypes.array,
    }),
    user_id: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      personalized: PropTypes.object,
      product: productType,
      vendor_id: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      }),
      slow_local: PropTypes.bool,
      verifiedAddress: PropTypes.object,
      ordering_aggression: PropTypes.number,
      grind: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      }),
      _id: PropTypes.string,
    }).isRequired,
    _id: PropTypes.string,
    custom_rotation: PropTypes.object,
    first_order: PropTypes.bool,
    selectedVendor: PropTypes.object,
  }).isRequired,
  products: PropTypes.array.isRequired,
};
