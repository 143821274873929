import { get, has } from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { VariantPrice } from '@bottomless/common/components';

export const Product = ({ product, personalized, user }) => {
  const variant = product.product.variants.find(variant => variant._id === product.variant);

  const image = !product.product.small_image_src
    ? null
    : product.product.small_image_src.match(/^http/)
    ? product.product.small_image_src
    : process.env.REACT_APP_BACKEND_URL + product.product.small_image_src;

  return (
    <Card className="mb-3 flex-row align-items-stretch">
      <div className="card-media" style={{ backgroundImage: `url(${image})` }} />
      <CardBody className="d-flex justify-content-between align-items-center">
        <div>
          <div className="text-secondary text-sm">{product.product.name}</div>
          <div>{product.product.vendor_name}</div>
        </div>
        <div className="text-right">
          <div>{variant.size}oz</div>
          <div className="text-primary">
            {has(personalized, 'price_type') ? (
              get(personalized, 'price_type').replace(/^\w/, c => c.toUpperCase())
            ) : (
              <VariantPrice user={user} variant={variant} />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
        })
      ),
      small_image_src: PropTypes.string,
    }).isRequired,
    variant: PropTypes.string.isRequired,
  }).isRequired,
  personalized: PropTypes.shape({
    price_type: PropTypes.string.isRequired,
  }),
  user: PropTypes.any,
};
