import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash-es';
import { Loader } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';

const CHUNK_SIZE = 50;
const TIMEOUT = 5000;

export const TriggerGeneration = ({
  triggerGenerationCreateOrder,
  triggerOrdergenGeneration,
  allUserIds,
  addToast,
  getTriggerCounts,
  automationName,
}) => {
  const history = useHistory();
  const [counts, setCounts] = useState();
  const [triggered, setTriggered] = useState(false);
  const failCount = useRef(0);
  const actualCounts = useRef({});

  const onTriggerOrderGeneration = useCallback(async () => {
    setTriggered(true);

    await triggerOrdergenGeneration({
      userIds: allUserIds,
      createOrder: triggerGenerationCreateOrder,
      automation: automationName,
    });
    addToast('Automatic generation triggered!');
  }, [triggerOrdergenGeneration, allUserIds, triggerGenerationCreateOrder, addToast, setTriggered, automationName]);

  useEffect(() => {
    let timeout;

    const execute = async () => {
      if (isNil(triggerGenerationCreateOrder)) {
        return;
      }

      const { payload: counts } = await getTriggerCounts();

      setCounts(counts);

      const unprocessed = counts.active + counts.waiting;

      if (!unprocessed) {
        failCount.current += 1;
      } else {
        failCount.current = 0;
      }

      if (actualCounts.current > 0 && !unprocessed) {
        history.go(0);
      }

      actualCounts.current = unprocessed;

      if (failCount.current < 10 || unprocessed > 0) {
        timeout = setTimeout(execute, TIMEOUT);
      }
    };

    timeout = setTimeout(execute, TIMEOUT);

    return () => clearTimeout(timeout);
  }, [triggered, failCount, actualCounts, history]);

  if (isNil(triggerGenerationCreateOrder)) {
    return null;
  }

  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <DataLoading count={counts === undefined ? 0 : 1} isLoading={counts === undefined} />
      {counts && (
        <div className="d-flex align-items-center">
          <div className="text-sm">
            <div className="text-success">
              Active: {counts.active > 0 && '~'}
              {counts.active * CHUNK_SIZE}
            </div>
            <div className="text-warning">
              Waiting: {counts.waiting > 0 && '~'}
              {counts.waiting * CHUNK_SIZE}
            </div>
          </div>
          {(triggered || counts.active + counts.waiting > 0) && <Loader size="20" className="spin ml-3" />}
        </div>
      )}
      <div>
        <Button color="success" onClick={onTriggerOrderGeneration} disabled={!allUserIds.length || triggered}>
          Trigger automatic ({allUserIds.length} customers)
        </Button>
      </div>
    </div>
  );
};

TriggerGeneration.propTypes = {
  triggerGenerationCreateOrder: PropTypes.bool,
  allUserIds: PropTypes.array.isRequired,
  triggerOrdergenGeneration: PropTypes.func.isRequired,
  getTriggerCounts: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  automationName: PropTypes.string,
};
