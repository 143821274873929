import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Form, Field, SubmitButton, Checkbox, Radio } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  paused: Yup.boolean().required('This field is required'),
  pausedUntil: Yup.date().when('paused', {
    is: true,
    then: Yup.date()
      .max(new Date('9999'), 'Future date not allowed')
      .required('This field is required'),
  }),
  notifyUser: Yup.bool(),
});

export const PauseForm = ({ user, onSubmit, onSubmitSuccess }) => {
  const handleSubmit = useCallback(
    ({ paused: pausedRaw, ...data }) => {
      const paused = pausedRaw === 'true' || pausedRaw === true;
      const pausedUntil = data.pausedUntil ? moment(data.pausedUntil).format('YYYY-MM-DD') : null;

      return onSubmit({ ...data, paused, pausedUntil });
    },
    [onSubmit]
  );

  const now = new Date();

  return (
    <Form
      initialValues={{
        paused: Boolean(user.paused),
        pausedUntil: user.pausedUntil ? utcDate(user.pausedUntil) : null,
        notifyUser: false,
      }}
      validationSchema={Schema}
      onSubmit={handleSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting, values }) => (
        <>
          <div className="d-flex mb-3">
            <div className="mr-3">
              <Radio name="paused" type="radio" value={false} label="Unpaused" />
            </div>
            <Radio name="paused" type="radio" value={true} label="Paused" />
          </div>
          {(values.paused === 'true' || values.paused === true) && (
            <Field name="pausedUntil" type="date" label="Until" min={now} />
          )}
          <div className="mb-3">
            <Checkbox name="notifyUser" label="Send e-mail to user" />
          </div>

          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

PauseForm.propTypes = {
  user: PropTypes.shape({
    paused: PropTypes.bool,
    pausedUntil: PropTypes.instanceOf(Date),
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
